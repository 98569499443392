import styled from 'styled-components'

import { StyledInput } from 'components/input/style'
import StyledTabPanel from 'components/tabs/tab_panel/style'
import { StyledPanelsWrapper } from 'components/tabs/style'

export const StyledShipmentFilters = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${StyledTabPanel} {
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin: 10px 0;
  }
  ${StyledPanelsWrapper} {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`

export const StyledShipmentFiltersTabs = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const StyledShipmentFiltersTags = styled.div`
  overflow-y: auto;
  padding: 0 20px;
  margin-bottom: 10px;
  ${StyledInput} {
    margin: 10px 0;
  }
`

export const StyledShipmentFilterTemplateInput = styled.div`
  padding: 0 20px;
`

export const StyledShipmentFiltersTag = styled.div`
  margin: 5px 10px 5px 0px;
`

export const StyledShipmentFiltersCheckPictos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const StyledShipmentFiltersDays = styled.div`
  display: flex;
  justify-content: space-between;

  ${StyledInput} {
    flex: none;
    width: calc(50% - 5px);
  }
`
