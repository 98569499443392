import { useMemo } from 'react'

import {
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_CONTAINER,
  CURRENCY_EUR,
  CURRENCY_USD_SYMBOL,
  CURRENCY_USD,
  CURRENCY_EUR_SYMBOL,
} from 'constants/bookings'

import useBooking from 'views/booking/hooks/use_booking'
import useBookingStatus from 'views/booking/hooks/use_booking_status'

import { isNull } from 'services/helpers/values'

import type { ThemeColors } from 'styles/theme'

interface BookingDisplay {
  statusColor: ThemeColors<'primary' | 'warn' | 'secondary' | 'error'>
  merchandiseTypeDisplay: string | null
  commercialValueDisplay: string | null
  rateProposalDisplay: string | null
}

const useBookingDisplay = (id: string): BookingDisplay => {
  const [booking] = useBooking(id)

  const { status, merchandise, proposal } = booking || {}
  const merchandiseType = merchandise?.merchandiseType
  const commercialValue: any = null
  const amount = commercialValue?.amount
  const currencyCode = commercialValue?.currencyCode
  const rate = proposal?.rate
  const proposalCurrencyCode = proposal?.currencyCode

  const {
    isStatusAReviewStatus,
    isStatusASuccessStatus,
    isStatusCancelled,
    isStatusCompleted,
    isStatusDeclined,
  } = useBookingStatus(status)

  const currencySymbol = (currCode: string) => {
    switch (currCode) {
      case CURRENCY_USD:
        return CURRENCY_USD_SYMBOL
      case CURRENCY_EUR:
        return CURRENCY_EUR_SYMBOL
      default:
        return ''
    }
  }

  const statusColor = useMemo(() => {
    if (isNull(booking)) {
      return 'primary'
    }
    if (isStatusAReviewStatus) {
      return 'warn'
    }
    if (isStatusASuccessStatus) {
      return 'secondary'
    }
    if (isStatusCancelled || isStatusDeclined) {
      return 'error'
    }
    if (isStatusCompleted) {
      return 'primary'
    }
    return 'primary'
  }, [
    booking,
    isStatusAReviewStatus,
    isStatusASuccessStatus,
    isStatusCancelled,
    isStatusCompleted,
    isStatusDeclined,
  ])

  const merchandiseTypeDisplay = useMemo(() => {
    if (isNull(merchandiseType)) {
      return null
    }

    return merchandiseType === MERCHANDISE_TYPE_CONTAINER ? MERCHANDISE_FCL : MERCHANDISE_LCL
  }, [merchandiseType])

  const commercialValueDisplay = useMemo(() => {
    if (isNull(amount) || isNull(currencyCode)) {
      return null
    }

    return `${amount} ${currencySymbol(currencyCode)}`
  }, [amount, currencyCode])

  const rateProposalDisplay = useMemo(() => {
    if (isNull(proposalCurrencyCode) || isNull(rate)) {
      return null
    }

    return `${currencySymbol(proposalCurrencyCode)} ${rate}`
  }, [proposalCurrencyCode, rate])

  return {
    statusColor,
    merchandiseTypeDisplay,
    commercialValueDisplay,
    rateProposalDisplay,
  }
}

export default useBookingDisplay
