import { AsyncThunk, AsyncThunkAction } from '@reduxjs/toolkit'

import type { OptionProps, SelectValue } from 'components/select'
import type { IFilter } from 'services/hooks/use_filter'

export interface Value {
  value: string | number | null
}
// TODO: REFACTOR
// Correctly type "any async thunk"
// This abstraction hides too much complexity of implementation
// This is why it is hard to type it correctly
type FetchOptions = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchRequest: AsyncThunk<any, any, any>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => (val: Value) => AsyncThunkAction<any, any, any>
type ToIndexedSelectValue = (filter: IFilter<'select'>) => SelectValue | null
type FetchedOptionsFormat = (options: OptionProps[]) => SelectValue[]
interface UseSelect {
  fetchOptions: FetchOptions
  toIndexedSelectValue: ToIndexedSelectValue
  fetchedOptionsFormat: FetchedOptionsFormat
}
const useSelect = (): UseSelect => {
  const fetchOptions: FetchOptions =
    (fetchRequest) =>
    ({ value }) =>
      fetchRequest({
        searchValue: value,
      })

  const fetchedOptionsFormat: FetchedOptionsFormat = (options) =>
    options.map(({ label }) => ({ value: label, label }))

  const toIndexedSelectValue: ToIndexedSelectValue = (filter) => {
    const selectValue = filter.value
    return selectValue ? { value: selectValue.value, label: `${selectValue.value}` } : null
  }

  return {
    fetchOptions,
    toIndexedSelectValue,
    fetchedOptionsFormat,
  }
}

export default useSelect
