import React from 'react'

import { useTranslation } from 'react-i18next'

import { StyledInfoIcon, StyledShipmentReportedInfo, StyledText } from 'views/shipment/style'
import IconTooltip from 'components/icon_tooltip'
import useUserLocale from 'views/iam/hooks/use_user_locale'
import { FlagReason } from 'views/shipment/types/shipment'
import { SupportedLanguage } from 'constants/locales'

const LOCAL_MAP: Record<SupportedLanguage, keyof Omit<FlagReason, 'id'>> = {
  en: 'messageEn',
  fr: 'messageFr',
  de: 'messageDe',
  es: 'messageEs',
  it: 'messageIt',
  nl: 'messageNl',
  pt: 'messagePt',
}

interface ShipmentReportedInfoProps {
  reportedReasons: { flagReason: FlagReason }[]
}

const ShipmentReportedInfo: React.FC<ShipmentReportedInfoProps> = ({ reportedReasons }) => {
  const { t } = useTranslation()
  const userLocale = useUserLocale()

  const messageField = LOCAL_MAP[userLocale]
  const reasons = reportedReasons.map(({ flagReason }) => flagReason[messageField])

  return (
    <StyledShipmentReportedInfo>
      <StyledText>{t('shipments.reportedShipment')}</StyledText>
      <StyledInfoIcon>
        <IconTooltip
          placement='bottom'
          size='large'
          width={25}
          height={25}
          content={
            <>
              {reasons.map((reason) => (
                <div>{reason}</div>
              ))}
            </>
          }
        />
      </StyledInfoIcon>
    </StyledShipmentReportedInfo>
  )
}

export default ShipmentReportedInfo
