import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { isAnyArray, isEmptyArray, isEmptyObject } from 'services/helpers/values'

import Button from 'components/button'
import Modal from 'components/modal'
import UploadDocumentFile from 'components/upload_document_file'
import UploadDropzone from 'components/upload_dropzone'
import useUploadFiles, { CustomFile } from 'components/modal_upload_document/hooks/use_upload_files'
import {
  StyledUploadDoc,
  StyledUploadDocTitle,
  StyledUploadDocFilesButtons,
  StyledUploadDocInfo,
  StyledUploadDocFiles,
} from 'components/modal_upload_document/style'
import useAppDispatch from 'services/hooks/use_app_dispatch'

interface ModalUploadDocumentProps {
  onClear?: () => void
  /**  TODO: TYPING -
   * This interface shouldn't be strongly linked with redux.
   * Each component using the modal should describe its own action once the use click on the button to
   * launch the document uploads. This way, we can get rid of the deferUpload and asyncUpload properties as well.
   */
  onUpload: ({
    document,
    documentType,
    documentId,
  }: {
    document: File | null
    documentType: string
    documentId: string
  }) => any
  uploadProgresses: Record<string, number>
  onSuccessUpload?: (files: CustomFile[]) => void
  deferUpload?: boolean
  asyncUpload?: boolean
  selectTestId?: string
}

const ModalUploadDocument: FC<ModalUploadDocumentProps> = ({
  onClear,
  onUpload,
  uploadProgresses = {},
  onSuccessUpload,
  deferUpload = false,
  asyncUpload = false,
  selectTestId,
}) => {
  const { onDropFiles, files, setFileState, setFileError, setFileType, removeAllFiles } =
    useUploadFiles()
  const [uploaded, setUploaded] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [nbUpload, setNbUpload] = useState(0)
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const clear = () => {
    removeAllFiles()
    setUploading(false)
    setUploaded(false)

    if (!onClear) return

    onClear()
  }

  useEffect(() => {
    const noErrorFiles = files.filter((file) => isEmptyArray(file.errors))
    const noTypeFiles = noErrorFiles.filter((file) => isEmptyObject(file.fileType))
    setNbUpload(noErrorFiles.length)

    const isUploadEnabled = isAnyArray(noErrorFiles) && isEmptyArray(noTypeFiles)
    setButtonDisabled(!isUploadEnabled)
  }, [files])

  const finalizeUpload = (filesList?: CustomFile[]) => {
    setUploading(false)
    setUploaded(true)

    if (!onSuccessUpload) return

    onSuccessUpload(filesList ?? [])
  }

  const uploadDocuments = async () => {
    setUploading(true)

    if (deferUpload) {
      files.forEach((file) => {
        setFileState({ file, uploading: false, uploaded: true })
      })
      finalizeUpload(files)

      return
    }

    const uploads = []
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      setFileState({ file, uploading: true })
      const action = dispatch(
        onUpload({
          document: file.nativeFile,
          documentType: file.fileType?.value || '',
          documentId: file.id,
        })
      )
        .unwrap()
        .catch(({ message, code }: { message: string; code: string }) => {
          setFileState({ file, uploading: false, uploaded: false })
          setFileError({ file, code, message })
        })
        .then(() => {
          setFileState({ file, uploading: false, uploaded: true })
        })

      if (asyncUpload) {
        await action // eslint-disable-line no-await-in-loop
      } else {
        uploads.push(action)
      }
    }

    if (asyncUpload) {
      finalizeUpload()
      return
    }

    Promise.all(uploads).then(() => {
      finalizeUpload()
    })
  }

  return (
    <Modal modalName='uploadDocuments' onClose={clear} position='center'>
      <Modal.Header>{t('documents.upload.title')}</Modal.Header>
      <Modal.Content>
        <StyledUploadDoc>
          {isEmptyArray(files) && <UploadDropzone onDrop={onDropFiles} />}

          {isAnyArray(files) && (
            <aside>
              {!uploaded && !uploading && (
                <StyledUploadDocTitle>
                  {t('documents.upload.selectDocumentTypeTitle', { count: nbUpload })}
                </StyledUploadDocTitle>
              )}
              {uploading && (
                <StyledUploadDocTitle>
                  {t('documents.upload.uploadingInfo', { count: nbUpload })}
                </StyledUploadDocTitle>
              )}
              {uploaded && (
                <StyledUploadDocTitle>
                  {t('documents.upload.uploadedInfo', { count: nbUpload })}
                </StyledUploadDocTitle>
              )}

              <StyledUploadDocFiles>
                {files.map((file, index) => (
                  <UploadDocumentFile
                    path={file.nativeFile.name}
                    errors={file.errors}
                    uploaded={file.state.uploaded}
                    uploading={file.state.uploading}
                    uploadProgress={uploadProgresses[file.id] || 0}
                    key={`file-${file.nativeFile.name}-${file.id}`}
                    onUpdateType={({ value, label }) =>
                      setFileType({ file, value: value as string, label })
                    }
                    selectTestId={`${selectTestId}-${index}`}
                  />
                ))}
              </StyledUploadDocFiles>

              {!uploaded && !uploading && (
                <StyledUploadDocFilesButtons>
                  <Button
                    text={t('documents.upload.actions.clear')}
                    variant='clear'
                    onClick={clear}
                  />
                  <Button
                    text={t('documents.upload.actions.upload')}
                    variant='highlight'
                    onClick={() => uploadDocuments()}
                    disabled={buttonDisabled}
                  />
                </StyledUploadDocFilesButtons>
              )}
              {uploaded && (
                <StyledUploadDocInfo>
                  {t('documents.upload.errorInfo', { count: 1 })}
                </StyledUploadDocInfo>
              )}
            </aside>
          )}
        </StyledUploadDoc>
      </Modal.Content>
    </Modal>
  )
}

export default ModalUploadDocument
