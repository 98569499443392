import { HubType } from 'views/atlas/types/hub'

export const PendingStatus = 'pending'
export const RejectedStatus = 'rejected'
export const AutomaticallyResolvedStatus = 'automatically_resolved'
export const ManuallyResolvedStatus = 'manually_resolved'

export const possibleStatusTypes = [
  PendingStatus,
  RejectedStatus,
  AutomaticallyResolvedStatus,
  ManuallyResolvedStatus,
] as const

export type StatusType = typeof possibleStatusTypes[number]

export interface Address {
  token: string
  rawValue: string
  dataProvider: string
  carrier: string | null
  status: StatusType
  constrainedHubTypes: HubType[] | null
  hub: { name: string; token: string; type: HubType } | null
}
