import React, { useState } from 'react'
import { useTheme } from 'styled-components'

import { Coordinate, GeoJSONSource, Map as MapboxMap, Popup } from 'mapbox-gl'

import useCoordinates from 'components/map/hooks/use_coordinates'
import useMarkers from 'components/map/hooks/use_markers'

import PathMarker from 'components/path_marker'
import { PathModel } from 'components/map/models'
import DateHelper from 'services/helpers/date_helper'
import { isNull } from 'services/helpers/values'

const SOURCE_PATH = 'path-source'
const SOURCE_PATH_POINTS = 'path-source-points'

const usePath = ({ map }: { map?: MapboxMap }) => {
  const { createFeature, createPointsFeature } = useCoordinates()
  const [pathExtremityMarker, setPathExtremityMarker] = useState<mapboxgl.Marker>()
  const { makeMapboxMarker } = useMarkers({ map })
  const theme = useTheme()

  const addPointer = ({
    bearing,
    coordinate,
    color,
    tooltip,
    isPathExtremity,
  }: {
    bearing: number
    coordinate: Coordinate
    color: string
    tooltip: string
    isPathExtremity?: boolean
  }) => {
    const marker = makeMapboxMarker(
      <PathMarker tooltip={tooltip} bearing={bearing} color={color} />,
      coordinate
    )

    if (map) {
      const addedMarker = marker.addTo(map)
      if (isPathExtremity) {
        setPathExtremityMarker(addedMarker)
      }
    }
  }

  const isDataSourceAdded = (source: string) => !!map?.getSource(source)

  const addPath = ({ path, coordinates }: { path: PathModel[]; coordinates: Coordinate[] }) => {
    // if we already have call the addPath method to initialize the dataSources and layers we only update the data source
    if (isDataSourceAdded(SOURCE_PATH) && isDataSourceAdded(SOURCE_PATH_POINTS)) {
      const pointsSource = map?.getSource(SOURCE_PATH_POINTS) as GeoJSONSource

      const pointsSourceFormatted = createPointsFeature(path)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Type incompability between turf Properties and mapbox GeoJsonProperties
      pointsSource?.setData(pointsSourceFormatted)
      const pathSource = map?.getSource(SOURCE_PATH) as GeoJSONSource
      const formatted = createFeature(coordinates)

      pathSource?.setData(formatted)
      // we remove the old path extremity ( the arrow that indicate the vehicle position )
      const element = pathExtremityMarker?.getElement()
      element?.parentNode?.removeChild(element)
      return
    }
    // otherwise we draw all the points / layers / lines ...
    const addPathSources = () => {
      if (isDataSourceAdded(SOURCE_PATH) || isDataSourceAdded(SOURCE_PATH_POINTS)) return

      // Draw the path line
      map?.addSource(SOURCE_PATH, {
        type: 'geojson',
        data: createFeature(coordinates),
      })
      map?.addLayer({
        id: 'path-layer',
        type: 'line',
        source: SOURCE_PATH,
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': theme.primary,
          'line-width': 2,
        },
      })

      // Draw the pins
      map?.addSource(SOURCE_PATH_POINTS, {
        type: 'geojson',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Type incompability between turf Properties and mapbox GeoJsonProperties
        data: createPointsFeature(path),
      })

      map?.addLayer({
        id: 'points-path-layer',
        type: 'circle',
        source: SOURCE_PATH_POINTS,
        paint: {
          'circle-color': theme.primary,
          'circle-radius': 2,
          'circle-stroke-width': 5,
          'circle-stroke-opacity': 0,
        },
      })
    }

    let intervalCount = 0
    const tryLoad = () => {
      if (intervalCount > 5) clearInterval(interval)

      if (map?.loaded()) {
        addPathSources()
        clearInterval(interval)
      } else {
        map?.on('load', () => {
          addPathSources()
          clearInterval(interval)
        })
      }
      intervalCount += 1
    }
    const interval = setInterval(tryLoad, 200)

    // Add popup on path point hovering
    const timestampPopup = new Popup({
      closeButton: false,
      closeOnClick: false,
    })

    map?.on('mousemove', 'points-path-layer', (event) => {
      // eslint-disable-next-line  no-param-reassign
      map.getCanvas().style.cursor = 'pointer'

      const { properties } = event.features![0]
      const popupCoordinates = event.lngLat
      const { timestamp } = properties!
      if (isNull(timestamp)) {
        return
      }
      timestampPopup
        .setLngLat(popupCoordinates)
        .setText(new DateHelper(properties!.timestamp).toLocale({ hours: true }))
        .addTo(map)
    })

    map?.on('mouseleave', 'points-path-layer', () => {
      // eslint-disable-next-line  no-param-reassign
      map.getCanvas().style.cursor = ''
      timestampPopup.remove()
    })
  }

  return { addPath, addPointer }
}

export default usePath
