import styled from 'styled-components'

import { listItem13, text16Regular } from 'styles/utils/texts'
import { borderRadiusMedium } from 'styles/utils/border_radius'
import StyledIcon from 'components/icon/style'
import { StyledButton } from 'components/button/style'

export const StyledUploadDropzoneIcon = styled(StyledIcon)`
  width: 50px;
  height: 50px;
  margin: 10px auto;
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledUploadDropzoneContent = styled.div`
  text-align: center;

  ${StyledButton} {
    margin: 0 auto;
  }
`

export const StyledUploadDropzoneTitle = styled.div`
  ${text16Regular}
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 15px;
  text-align: center;
`

export const StyledUploadDropzoneDetail = styled.div`
  ${listItem13}
  color: ${({ theme }) => theme.textLight};
  margin-bottom: 13px;
`

export const StyledUploadDropzone = styled.div`
  border: 2px dashed ${({ theme }) => theme.mediumGray};
  box-sizing: border-box;
  ${borderRadiusMedium}
  padding: 40px 20px 60px 20px;
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
