import styled, { css } from 'styled-components'

const STATE_ENTERING = 'entering'
const STATE_ENTERED = 'entered'
const STATE_EXITING = 'exiting'
const STATE_EXITED = 'exited'

const StyledTransition = styled.div`
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 0;
  transform: translate3d(20px, 0, 0);

  ${({ $state, $type }) =>
    $type === 'fade-in' &&
    {
      [STATE_ENTERING]: css`
        opacity: 0;
      `,
      [STATE_ENTERED]: css`
        opacity: 1;
        transform: translate3d(0, 0, 0);
      `,
      [STATE_EXITING]: css`
        opacity: 0;
      `,
      [STATE_EXITED]: css`
        opacity: 0;
      `,
    }[$state]};
`

export default StyledTransition
