import React, { FC } from 'react'

import Icon from 'components/icon'

import {
  StyledSavedFiltersItem,
  StyledSavedFiltersSection,
  StyledSavedFiltersTitle,
  StyledSavedFiltersArrow,
  StyledSavedFiltersTrash,
} from 'components/shipment_filters_saved_item/style'

interface ShipmentFiltersSavedItemProps {
  label?: string
  editMode?: boolean
  onClickItem?: () => void
  onClickDelete?: () => void
}

const ShipmentFiltersSavedItem: FC<ShipmentFiltersSavedItemProps> = ({
  label,
  editMode = false,
  children,
  onClickItem,
  onClickDelete,
}) => (
  <StyledSavedFiltersItem as={editMode ? 'div' : 'button'} onClick={onClickItem}>
    <StyledSavedFiltersSection>
      <StyledSavedFiltersTitle>{label}</StyledSavedFiltersTitle>
      <div>{children}</div>
      {!editMode && (
        <StyledSavedFiltersArrow>
          <Icon name='arrow_bottom_outline' />
        </StyledSavedFiltersArrow>
      )}
      {editMode && (
        <StyledSavedFiltersTrash onClick={onClickDelete}>
          <Icon name='trash' />
        </StyledSavedFiltersTrash>
      )}
    </StyledSavedFiltersSection>
  </StyledSavedFiltersItem>
)

export default ShipmentFiltersSavedItem
