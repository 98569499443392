import React from 'react'

import {
  StyledUploadProgress,
  StyledUploadProgressSvg,
  StyledUploadProgressSvgBg,
  StyledUploadProgressSvgProgress,
} from 'components/upload_progress/style'

const CIRCLE_DASHARRAY = 57

interface UploadProgressProps {
  progress: number
}
const UploadProgress: React.FC<UploadProgressProps> = ({ progress }) => {
  const dashProgress = CIRCLE_DASHARRAY - (CIRCLE_DASHARRAY * progress) / 100

  return (
    <StyledUploadProgress>
      <StyledUploadProgressSvg
        width='24'
        height='24'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <StyledUploadProgressSvgBg
          r='9'
          cx='12'
          cy='12'
          fill='transparent'
          strokeDasharray={CIRCLE_DASHARRAY}
          strokeDashoffset='0'
        />
        <StyledUploadProgressSvgProgress
          r='9'
          cx='12'
          cy='12'
          fill='transparent'
          strokeDasharray={CIRCLE_DASHARRAY}
          strokeDashoffset={dashProgress}
        />
      </StyledUploadProgressSvg>
    </StyledUploadProgress>
  )
}

export default UploadProgress
