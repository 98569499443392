import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import useStaticLocales from 'views/locales/hooks/use_static_locales'

import {
  StyledFormEditGroupLabel,
  StyledFormEditGroupValue,
  StyledFormEditGroupItem,
  StyledMerchandisePackagesContainer,
  StyledMerchandisePackageItem,
  StyledMerchandisePackageProductDetails,
  StyledFormEditGroup,
  StyledFormEditGroupItems,
  StyledFormEditGroupTitle,
} from 'views/booking/components/show/style'
import {
  AnyBookingMerchandise,
  BookingMerchandisePackage,
  BookingMerchandisePackageContent,
} from 'views/booking/slices/types'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import * as IconList from 'assets/icons'
import useMerchandiseInformationDisplay from 'views/booking/hooks/use_merchandise_information_display'
import Table from 'components/table'
import { isPresent } from 'services/helpers/values'
import InformationMerchandiseContentHeader from 'views/booking/components/information_merchandise_content_header'

const PACKAGE_ICONS = {
  0: 'bag',
  1: 'barrel',
  2: 'package',
  3: 'crate',
  4: 'pallet',
  5: 'cancel',
}

type PackageType = keyof typeof PACKAGE_ICONS
type PackageIcon = keyof typeof IconList

const InformationMerchandisePackages = ({
  merchandise,
}: {
  merchandise: AnyBookingMerchandise
}) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()
  const {
    currencyDisplay,
    temperatureDisplay,
    hazardousGoodsDisplay,
    commercialValueDisplay,
    emptyDisplay,
  } = useMerchandiseInformationDisplay()

  const {
    content: packages,
    shippingMode,
    totalWeight,
    totalVolume,
  } = merchandise as BookingMerchandisePackage
  const packageTypes = s('packageTypes')

  const [packageExpansions, setPackageExpansions] = useState<boolean[]>(
    // eslint-disable-next-line
    packages?.map((_p) => false) ?? []
  )

  const getPackageTotalVolume = (p: BookingMerchandisePackageContent) =>
    ((p.length.value * p.width.value * p.height.value) / 1e6) * p.quantity
  const getPackageTotalWeight = (p: BookingMerchandisePackageContent) => p.weight.value * p.quantity
  const getPackageTotalAmout = (p: BookingMerchandisePackageContent) =>
    p.quantity * (p.commercialValue?.amount ?? 0)
  const getPackageIcon = (type: PackageType) => (PACKAGE_ICONS[type] ?? 'cancel') as PackageIcon

  const hasAnyControlledTemperatures = (p: BookingMerchandisePackageContent) =>
    isPresent(p.controlledTemperatures?.min) || isPresent(p.controlledTemperatures?.max)

  const hasAnyHazardousGoods = (p: BookingMerchandisePackageContent) =>
    isPresent(p.hazardousGoods?.hazardousClass) ||
    isPresent(p.hazardousGoods?.packingGroup) ||
    isPresent(p.hazardousGoods?.unNumber) ||
    isPresent(p.hazardousGoods?.weight?.value)

  return (
    <>
      <StyledFormEditGroup>
        <StyledMerchandisePackagesContainer>
          <StyledMerchandisePackageItem>
            <StyledFormEditGroupTitle>{t('bookings.merchandise.title')}</StyledFormEditGroupTitle>
            <StyledFormEditGroupItems>
              <StyledFormEditGroupItem>
                <StyledFormEditGroupLabel>
                  {t('bookings.merchandise.shippingMode')}
                </StyledFormEditGroupLabel>
                <StyledFormEditGroupValue>
                  <ValueWithFallback value={shippingMode} />
                </StyledFormEditGroupValue>
              </StyledFormEditGroupItem>
            </StyledFormEditGroupItems>
          </StyledMerchandisePackageItem>
          {packages?.map((p, index: number) => (
            <>
              <StyledMerchandisePackageItem>
                <StyledFormEditGroupTitle />
                <StyledFormEditGroupItems>
                  <StyledMerchandisePackageItem>
                    {index === 0 ? (
                      <StyledFormEditGroupLabel>
                        {t('bookings.merchandise.content')}
                      </StyledFormEditGroupLabel>
                    ) : (
                      <StyledFormEditGroupLabel />
                    )}
                    <InformationMerchandiseContentHeader
                      index={index}
                      icon={getPackageIcon(p.packageType as any)}
                      type={packageTypes[p.packageType as any]}
                      quantity={p.quantity}
                      weight={totalWeight ?? { value: getPackageTotalWeight(p) }}
                      volume={totalVolume ?? { value: getPackageTotalVolume(p) }}
                      amount={getPackageTotalAmout(p)}
                      currency={currencyDisplay(p.commercialValue?.currencyCode)}
                      hasAnyHazardousGoods={hasAnyHazardousGoods(p)}
                      hasAnyControlledTemperatures={hasAnyControlledTemperatures(p)}
                      withAction
                      expanded={packageExpansions[index]}
                      onClick={() =>
                        setPackageExpansions(
                          packageExpansions.map((_p, i) => {
                            if (i === index) {
                              return !packageExpansions[i]
                            }

                            return packageExpansions[i]
                          })
                        )
                      }
                    />
                  </StyledMerchandisePackageItem>
                </StyledFormEditGroupItems>
              </StyledMerchandisePackageItem>
              {packageExpansions[index] && (
                <StyledMerchandisePackageProductDetails>
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.description')}
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.weight')} (kg)
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.length')} (cm)
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.width')} (cm)
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.height')} (cm)
                      </Table.HeadCell>
                      <Table.HeadCell>{t('bookings.merchandise.product.value')}</Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.temperatures.short')}
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.hazardousGoods.title')}
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      <Table.Row key={`package-${index + 1}`}>
                        <Table.Cell>{p.productDescription ?? emptyDisplay}</Table.Cell>
                        <Table.Cell>{p.weight?.value?.toLocaleString() ?? emptyDisplay}</Table.Cell>
                        <Table.Cell>{p.length?.value?.toLocaleString() ?? emptyDisplay}</Table.Cell>
                        <Table.Cell>{p.width.value?.toLocaleString() ?? emptyDisplay}</Table.Cell>
                        <Table.Cell>{p.height.value?.toLocaleString() ?? emptyDisplay}</Table.Cell>
                        <Table.Cell>{commercialValueDisplay(p.commercialValue)}</Table.Cell>
                        <Table.Cell>
                          <div>{temperatureDisplay(p.controlledTemperatures?.min, 'Min.')}</div>
                          <div>{temperatureDisplay(p.controlledTemperatures?.max, 'Max.')}</div>
                        </Table.Cell>
                        <Table.Cell>{hazardousGoodsDisplay(p.hazardousGoods)}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </StyledMerchandisePackageProductDetails>
              )}
            </>
          ))}
        </StyledMerchandisePackagesContainer>
      </StyledFormEditGroup>
    </>
  )
}

export default InformationMerchandisePackages
