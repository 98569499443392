import React, { FC } from 'react'

import {
  StyledModalTimelineStepHeaderLocation,
  StyledModalTimelineStepHeaderType,
  StyledModalTimelineStepWrapper,
} from 'components/modal_timeline/style'

const StepHeader: FC<{ typeHeader: string; locationHeader: string }> = ({
  typeHeader,
  locationHeader,
}) => (
  <StyledModalTimelineStepWrapper>
    <StyledModalTimelineStepHeaderType>{`${typeHeader}`}</StyledModalTimelineStepHeaderType>
    <StyledModalTimelineStepHeaderLocation>{`${locationHeader}`}</StyledModalTimelineStepHeaderLocation>
  </StyledModalTimelineStepWrapper>
)

export default StepHeader
