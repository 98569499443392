import React, { useEffect, useState } from 'react'

import {
  StyledArrow,
  StyledContent,
  StyledTooltip,
  StyledTooltipText,
} from 'components/tooltip/style'
import { TooltipProps } from 'components/tooltip/types'

const Tooltip = ({
  placement = 'top',
  color = 'primary',
  size = 'nowrap',
  hasArrow = true,
  content,
  zIndex = 1,
  visible = true,
  position,
  delayed = false,
}: TooltipProps) => {
  const [shouldRender, setShouldRender] = useState(false)
  const [tooltipVisibleState, setTooltipVisibleState] = useState(false)
  const [checkShouldUnmount, setCheckShouldUnmount] = useState(false)

  useEffect(() => {
    if (visible) {
      // We need an initial state where the tooltip is mounted and $visible is false
      setShouldRender(true)
      setTimeout(() => {
        // 10ms delay is here to make sure we have the delay transition
        setTooltipVisibleState(true)
      }, 10)
    } else {
      setTooltipVisibleState(false)
      setTimeout(() => {
        setCheckShouldUnmount(true)
      }, 300)
    }
  }, [visible])

  // We need to ensure we still are in hidden state before unmounting
  // This is for when leave and come back quickly to tooltip target
  useEffect(() => {
    if (checkShouldUnmount && !visible) {
      setShouldRender(false)
      setTooltipVisibleState(false)
    }
    setCheckShouldUnmount(false)
  }, [checkShouldUnmount, visible])

  return shouldRender ? (
    <StyledTooltip $placement={placement} $zIndex={zIndex} $position={position}>
      <StyledContent
        $zIndex={zIndex}
        $visible={tooltipVisibleState}
        $delayed={delayed}
        $color={color}
        $placement={placement}
        $hasArrow={hasArrow}
        $size={size}
      >
        <StyledArrow viewBox='0 0 18 10'>
          <path d='M18 1.90735e-06L12.2 7.73333C10.6 9.86667 7.4 9.86667 5.8 7.73333L1.04907e-06 3.33739e-07L18 1.90735e-06Z' />
        </StyledArrow>
        <StyledTooltipText>{content}</StyledTooltipText>
      </StyledContent>
    </StyledTooltip>
  ) : (
    <></>
  )
}

export default Tooltip
