import React from 'react'

import Icon from 'components/icon'
import S from 'components/simple_transport_plan/style'

export interface SimpleTransportPlanProps {
  locationFrom: string
  locationTo: string
}

const SimpleTransportPlan: React.FC<SimpleTransportPlanProps> = ({ locationFrom, locationTo }) => (
  <S.Timeline>
    <span>{locationFrom}</span>
    {locationTo && <Icon name='arrow_right_outline' />}
    <span>{locationTo}</span>
  </S.Timeline>
)

export { SimpleTransportPlan as SimpleTransportPlanStorybook }

export default SimpleTransportPlan
