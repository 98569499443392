import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupTitleRate,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import useBookingDisplay from 'views/booking/hooks/use_booking_display'
import BookingContext from 'views/booking/contexts/booking_context'
import IconTooltip from 'components/icon_tooltip'
import useBooking from 'views/booking/hooks/use_booking'

const InformationRateConfirmation = () => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { rateConfirmation, legacyBooking } = booking
  const { t } = useTranslation()
  const { rateProposalDisplay } = useBookingDisplay(token)
  const orgaWithLegacyWorkflow = legacyBooking

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.rateConfirmation.title')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>
            {t('bookings.rateConfirmation.rateRequested')}
          </StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={rateConfirmation} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupTitleRate as={StyledFormEditGroupLabel}>
            {t('bookings.rateConfirmation.rate')}{' '}
            <IconTooltip
              size='nowrap'
              content={t('bookings.rateConfirmation.allCostAndTaxesIncluded')}
            />
          </StyledFormEditGroupTitleRate>

          <StyledFormEditGroupValue $valid={orgaWithLegacyWorkflow}>
            <ValueWithFallback value={rateProposalDisplay} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationRateConfirmation
