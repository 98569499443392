import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import { Tvalue } from 'components/select'
import useUrlParams from 'services/api/hooks/use_url_params'
import { selectCurrentUser } from 'views/iam/slices/iamSlice'
import { AtlasClient } from 'services/api/clients'
import onError from 'services/api/error'
import { Status, STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'
import {
  Address,
  AutomaticallyResolvedStatus,
  ManuallyResolvedStatus,
  PendingStatus,
  RejectedStatus,
  StatusType,
} from 'views/atlas/types/address'

import type { RootState } from 'services/store/store'

export type StatusTypeFilter = 'pending' | 'rejected' | 'automaticallyResolved' | 'manuallyResolved'

export const fetchDataProviders = createAsyncThunk<
  string[],
  { value: Tvalue },
  { state: RootState }
>('atlas/addresses/fetchDataProviders', async ({ value }: { value: Tvalue }, thunkAPI) => {
  const { getState } = thunkAPI
  const url = useUrlParams('/addresses/data_providers', { q: value })
  const user = selectCurrentUser(getState())

  return AtlasClient.get(url, { headers: { Authorization: `Bearer ${user.accessToken}` } })
    .then((r) => r.data)
    .catch(onError(thunkAPI))
})

export const fetchCarriers = createAsyncThunk<string[], { value: Tvalue }, { state: RootState }>(
  'atlas/addresses/fetchCarriers',
  async ({ value }: { value: Tvalue }, thunkAPI) => {
    const { getState } = thunkAPI
    const url = useUrlParams('/addresses/carriers', { q: value })
    const user = selectCurrentUser(getState())

    return AtlasClient.get(url, { headers: { Authorization: `Bearer ${user.accessToken}` } })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export type ValidStatus = typeof RejectedStatus | typeof ManuallyResolvedStatus
interface ResolveAddressParams {
  hubId: string | null
  status: ValidStatus
  token: string
}

export const resolveAddress = createAsyncThunk<Address, ResolveAddressParams, { state: RootState }>(
  'atlas/addresses/resolveAddress',
  async ({ token, ...data }, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    return AtlasClient.post(`/addresses/${token}/resolve`, data, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

interface FetchAddressesParams {
  search: string
  statusTypeFilters: Record<StatusTypeFilter, boolean>
  dataProviders: string[]
  carriers: string[]
  hubs: string[]
  page: number
}

const FETCH_ADDRESSES_PER = 50

const StatusTypeMappings: Record<StatusTypeFilter, StatusType> = {
  pending: PendingStatus,
  rejected: RejectedStatus,
  automaticallyResolved: AutomaticallyResolvedStatus,
  manuallyResolved: ManuallyResolvedStatus,
}

const buildStatusType = (statusTypeFilters: Record<StatusTypeFilter, boolean>): StatusType[] => {
  const results = Object.entries(statusTypeFilters).reduce<StatusType[]>((acc, [k, v]) => {
    if (v) acc.push(StatusTypeMappings[k as StatusTypeFilter])
    return acc
  }, [])
  if (results.length === 0) return Object.values(StatusTypeMappings)
  return results
}

interface AddressIndex {
  totalCount: number
  addresses: Address[]
}

export const fetchAddresses = createAsyncThunk<
  AddressIndex,
  FetchAddressesParams,
  { state: RootState }
>(
  'atlas/addresses/fetchAddresses',
  async (
    { search, dataProviders, page, carriers, statusTypeFilters, hubs }: FetchAddressesParams,
    thunkAPI
  ) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    const url = useUrlParams('/addresses', {
      q: search,
      dataProviders,
      carriers,
      hubs,
      statuses: buildStatusType(statusTypeFilters),
      page,
      per: FETCH_ADDRESSES_PER,
    })
    return AtlasClient.get<AddressIndex>(url, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    }).then((r) => r.data)
  }
)

export interface AddressActiveFilters {
  search: string
  statusTypeFilters: Record<StatusTypeFilter, boolean>
  dataProviders: string[]
  carriers: string[]
  hubs: string[]
}

interface AddressInitialState {
  status: Status
  totalCount: number
  activeFilters: AddressActiveFilters
}

const addressAdapter = createEntityAdapter<Address>({
  selectId: ({ token }: Address) => token,
})

const addressInitialState = addressAdapter.getInitialState<AddressInitialState>({
  status: STATUS_PENDING,
  totalCount: 0,
  activeFilters: {
    search: '',
    statusTypeFilters: {
      pending: true,
      rejected: false,
      automaticallyResolved: false,
      manuallyResolved: false,
    },
    dataProviders: [],
    carriers: [],
    hubs: [],
  },
})

const addressSlice = createSlice({
  name: 'addresses',
  initialState: addressInitialState,
  reducers: {
    removeAll: addressAdapter.removeAll,
    saveActiveFilters: (state, action) => {
      state.activeFilters = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAddresses.fulfilled, (state, action) => {
      const { addresses, totalCount } = action.payload
      addressAdapter.addMany(state, addresses)
      state.totalCount = totalCount
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchAddresses.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchAddresses.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const { removeAll: removeAllAddresses, saveActiveFilters: saveAddressesActiveFilters } =
  addressSlice.actions

export const { selectAll: selectAddresses } = addressAdapter.getSelectors(
  (state: RootState) => state.addresses
)

export const selectAddressesStatus = (state: RootState) => state.addresses.status
export const selectAddressesTotalCount = (state: RootState) => state.addresses.totalCount
export const selectAddressesActiveFilters = (state: RootState) => state.addresses.activeFilters

export default addressSlice.reducer
