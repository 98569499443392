import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Modal from 'components/modal'

import useModal from 'components/modal/hooks/use_modal'
import Button from 'components/button'
import { deleteShipment } from 'views/shipment/slice'
import ShipmentContext from 'views/shipment/context'
import { addNotification } from 'views/notifications/slice'

import StyledDeleteModalContent from 'components/modal_delete_shipment/styles'
import useAppDispatch from 'services/hooks/use_app_dispatch'

const ModalDeleteShipment = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { setOpen } = useModal('deleteShipment')
  const { id } = useContext(ShipmentContext)

  const onAccept = () => {
    if (id) {
      dispatch(deleteShipment(id))
        .unwrap()
        .then(() => {
          dispatch(
            addNotification({
              type: 'success',
              title: t('shipments.shipmentDeleted.success'),
              text: t('shipments.shipmentDeleted.deleted'),
            })
          )
        })
        .catch(() => {
          dispatch(
            addNotification({
              type: 'alert',
              title: t('shipments.shipmentDeleted.error'),
              text: t('shipments.shipmentDeleted.errorDeleting'),
            })
          )
        })
        .finally(() => {
          setOpen(false)
          history.push('/shipments')
        })
    }
  }
  return (
    <Modal modalName='deleteShipment' shouldFocusAfterRender={false} width='small'>
      <Modal.Header>{t('shipments.shipmentDeleted.title')}</Modal.Header>
      <Modal.Content>
        <StyledDeleteModalContent>
          {t('shipments.shipmentDeleteModale.content')}
        </StyledDeleteModalContent>
      </Modal.Content>
      <Modal.Footer>
        <Button
          onClick={() => setOpen(false)}
          variant='clear'
          text={t('shipments.shipmentDeleted.cancel')}
        />
        <Button
          variant='highlight'
          text={t('shipments.shipmentDeleted.delete')}
          onClick={onAccept}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDeleteShipment
