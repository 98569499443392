import { createAsyncThunk } from '@reduxjs/toolkit'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import useUrlParams from 'services/api/hooks/use_url_params'

import type { CustomKpi } from 'features/custom_kpis/types/types'

export const fetchCustomKpis = createAsyncThunk(
  'customKpis/fetchCustomKpis',
  async (_, thunkAPI) => {
    const { teams } = useThunkTeamsParam(thunkAPI)
    const url = useUrlParams('/custom_kpis', { teams })

    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)
export const createCustomKpi = createAsyncThunk(
  'customKpis/createCustomKpi',
  async ({ customKpi }: { customKpi: CustomKpi }, thunkAPI) => {
    const { teams } = useThunkTeamsParam(thunkAPI)
    return InternalClient.post('/custom_kpis', { ...customKpi, teams })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)
export const updateCustomKpi = createAsyncThunk(
  'customKpis/updateCustomKpi',
  async (
    { id, changes }: { id: number; changes: Record<string, Partial<CustomKpi>> },
    thunkAPI
  ) => {
    const { teams } = useThunkTeamsParam(thunkAPI)
    return InternalClient.patch(`/custom_kpis/${id}`, { ...changes, teams })
      .then((r) => r.data)
      .then((customKpi) => ({ id: customKpi.id, changes: customKpi }))
      .catch(onError(thunkAPI))
  }
)
export const deleteCustomKpi = createAsyncThunk(
  'customKpis/deleteCustomKpi',
  async ({ id }: { id: number }, thunkAPI) =>
    InternalClient.delete(`custom_kpis/${id}`)
      .then((r) => r.data)
      .then((customKpi) => customKpi.id)
      .catch(onError(thunkAPI))
)
