import styled from 'styled-components'

import { desktop, desktopLarge, tablet } from 'styles/utils/breakpoints'
import { gradientDarkOpacityReverse } from 'styles/utils/gradients'
import { StyledWidgets } from 'features/custom_kpis/components/widgets/style'
import { borderRadiusMedium } from 'styles/utils/border_radius'
import {
  StyledShipmentListItem,
  StyledShipmentListItemHead,
} from 'components/shipment_list_item/style'
import StyledShipmentList from 'components/shipment_list/style'

export const StyledShipmentsListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;
  padding: 40px 20px;
  @media ${tablet.down} {
    padding: 30px 20px;
  }
  background-color: ${({ theme }) => theme.white};

  ${borderRadiusMedium}
  ${StyledShipmentListItem} {
    @media ${desktopLarge.up} {
      flex-wrap: nowrap;
    }
  }

  ${StyledShipmentListItemHead} {
    @media ${desktop.up} {
      width: 60%;
    }
  }

  ${StyledShipmentList} {
    margin-bottom: 0;
  }
`
export const StyledShipments = styled.div`
  position: relative;
  display: flex;
  height: calc(50% + 100px);
  padding: 0 90px 30px 90px;
  margin-top: -100px;
  @media ${desktop.down} {
    margin-top: -205px;
    flex-direction: column;
    padding: 0 47px 30px 47px;
  }
  @media ${tablet.down} {
    margin-top: -30px;
    padding: 0 16px 30px 16px;
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media ${desktop.down} {
      top: 205px;
    }
    @media ${tablet.down} {
      top: 30px;
    }
    ${gradientDarkOpacityReverse};
  }

  pointer-events: none;

  ${StyledWidgets} {
    pointer-events: all;
  }

  ${StyledShipmentsListContainer} {
    pointer-events: all;
  }
`
