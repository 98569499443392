import React, { FC } from 'react'

import TagDiffTime from 'components/tag_diff_time'
import Icon from 'components/icon'

import DateHelper from 'services/helpers/date_helper'
import useMilestoneTimes from 'services/hooks/use_milestone_times'

import {
  StyledMilestone,
  StyledMilestoneHead,
  StyledMilestoneDetail,
  StyledMilestoneDetails,
  StyledMilestoneDetailsHead,
  StyledTagDiffTime,
  StyledMilestoneHeadIcon,
} from 'components/timeline/style'

interface TimelineBookingConfirmationMilestoneProps {
  type: string
  isCheck?: boolean
  estimatedTime?: string
  actualTime?: string
}

const TimelineBookingConfirmationMilestone: FC<TimelineBookingConfirmationMilestoneProps> = ({
  type,
  isCheck = false,
  estimatedTime,
  actualTime,
}) => {
  const { time, diffTime } = useMilestoneTimes({
    actual: actualTime,
    estimated: estimatedTime,
  })

  return (
    <StyledMilestone>
      <div>
        <StyledMilestoneHead $checked={isCheck} $open>
          <StyledMilestoneHeadIcon
            as={Icon}
            width={11}
            height={11}
            name={isCheck ? 'checkmark' : 'arrow'}
          />
          {type}
        </StyledMilestoneHead>
        <div>
          <StyledMilestoneDetails>
            <StyledMilestoneDetailsHead>
              {actualTime && (
                <StyledMilestoneDetail>
                  {new DateHelper(actualTime).toLocale({ hours: true })}
                </StyledMilestoneDetail>
              )}
              {estimatedTime && !actualTime && (
                <StyledMilestoneDetail>
                  {new DateHelper(estimatedTime).toLocale({ hours: true })}
                </StyledMilestoneDetail>
              )}
            </StyledMilestoneDetailsHead>
          </StyledMilestoneDetails>
        </div>
      </div>
      {diffTime && <StyledTagDiffTime as={TagDiffTime} time={time} timeTo={diffTime} />}
    </StyledMilestone>
  )
}

export default TimelineBookingConfirmationMilestone
