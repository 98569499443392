export interface Analytics {
  analyticsToken: string
  reportId: string
}

export enum Category {
  PRIMARY = 'primary',
  CUSTOMIZED = 'customized',
  VOLUMES = 'volumes',
  PERFORMANCE = 'performance',
  DETENTION_DEMURRAGE = 'detention_demurrage',
  EMISSIONS = 'emissions',
  BOOKING = 'booking',
  DATA_QUALITY = 'data_quality',
  BENCHMARK = 'benchmark',
  MARKET_INTEL = 'market_intel',
}

export interface CategoryParams {
  category: Category
}
