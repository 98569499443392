import useFilter from 'services/hooks/use_filter'
import useCategory from 'services/hooks/use_category'
import useForm from 'services/hooks/use_form'

const useNewMilestoneForm = () => {
  const milestoneTypeFilter = useFilter({
    name: 'milestoneType',
    type: 'select',
    required: true,
  })

  const estimatedTimeFilter = useFilter({
    name: 'estimatedTime',
    type: 'date',
  })

  const actualTimeFilter = useFilter({
    name: 'actualTime',
    type: 'date',
  })

  const newMilestoneCategory = useCategory({
    name: 'newMilestone',
    filters: [milestoneTypeFilter, estimatedTimeFilter, actualTimeFilter],
    toQueryParams: (_filters, filtersHash) => ({
      milestoneType: filtersHash.milestoneType?.value?.value,
      estimatedTime: filtersHash.estimatedTime?.value,
      actualTime: filtersHash.actualTime?.value,
    }),
  })
  const categories = [newMilestoneCategory]

  const { queryParams, isEdited, isValid } = useForm({ categories })

  return {
    milestoneTypeFilter,
    estimatedTimeFilter,
    actualTimeFilter,
    queryParams,
    isEdited,
    isValid,
  }
}

export default useNewMilestoneForm
