import React, { FC, useContext, useState } from 'react'

import { useFormContext, useFieldArray } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { TransportType } from 'types/shipments'

import Button from 'components/button'
import IconTooltip from 'components/icon_tooltip'
import { StyledInputLabelRequiredIcon } from 'components/input/style'
import useModal from 'components/modal/hooks/use_modal'
import ModalEditMilestone from 'components/modal_edit_milestone/modal_edit_milestone'

import {
  StyledModalTimelineStepTypeWrapper,
  StyledModalTimelineStepType,
  StyledModalTimelineStepEditActionsWrapper,
  StyledModalTimelineMilestoneEditActionsWrapper,
  StyledModalTimelineCreateMilestoneWrapper,
  StyledModalTimelineStepEditActionButtonWrapper,
  StyledModalTimelineStepLocationWrapper,
  StyledModalTimelineStepWrapper,
  StyledEditedByAdminTextInfo,
  StyledModalTimelineMilestoneTableRowWrapper,
} from 'components/modal_timeline/style'

import SearchSelect from 'components/search_select'
import { SelectValue } from 'components/select'
import Table from 'components/table'

import {
  STEP_TYPE_AIR_DESTINATION,
  STEP_TYPE_AIR_ORIGIN,
  STEP_TYPE_PARCEL_DESTINATION,
  STEP_TYPE_PARCEL_ORIGIN,
  STEP_TYPE_RAIL_DESTINATION,
  STEP_TYPE_RAIL_ORIGIN,
  STEP_TYPE_ROAD_DESTINATION,
  STEP_TYPE_ROAD_ORIGIN,
  STEP_TYPE_SEA_POD,
  STEP_TYPE_SEA_POL,
} from 'constants/steps'

import { isPresent } from 'services/helpers/values'

import { fetchLocationOptions } from 'views/select_options/slice'
import {
  TimelineFormMilestone,
  TimelineFormProps,
  TimelineFormStep,
} from 'views/shipment/types/segment_timelines'

import {
  addNewAlert,
  formatMilestoneDate,
  getAlertTranslationKey,
  getNextMilestoneDateInformationAfterReversion,
  milestoneHasBeenEditedByAdmin,
  milestoneHasBeenReverted,
  stepHasBeenEditedByAdmin,
} from 'components/modal_timeline/helper'

import EditTimelineModalContext, { DecomposedStep } from 'components/modal_timeline/context'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import HoverTooltip from 'components/hover_tooltip'
import DateHelper from 'services/helpers/date_helper'

const REQUIRED_STEP_TYPES = [
  STEP_TYPE_SEA_POL,
  STEP_TYPE_SEA_POD,
  STEP_TYPE_AIR_ORIGIN,
  STEP_TYPE_AIR_DESTINATION,
  STEP_TYPE_RAIL_ORIGIN,
  STEP_TYPE_RAIL_DESTINATION,
  STEP_TYPE_ROAD_ORIGIN,
  STEP_TYPE_ROAD_DESTINATION,
  STEP_TYPE_PARCEL_ORIGIN,
  STEP_TYPE_PARCEL_DESTINATION,
]

const StepDisplay: FC<{
  transportType: TransportType
  segmentIndex: number
  stepIndex: number
  step: TimelineFormStep
  steps: TimelineFormStep[]
  editStep: (index: number, step: TimelineFormStep) => void
  removeStep?: (index: number) => void
  typeDescription: string | null
  adminEditionTextInfo?: string | null
  decomposedStep: DecomposedStep
}> = ({
  step,
  segmentIndex,
  stepIndex,
  transportType,
  editStep,
  steps,
  removeStep,
  typeDescription,
  adminEditionTextInfo,
  decomposedStep,
}) => {
  // Should we keep the following string formatting since it is different from what is sent by the selects?
  const { t } = useTranslation()
  const { s } = useStaticLocales()
  const [expanded, setExpansion] = useState(false)

  const {
    currentEditedSegmentIndex,
    currentEditedStepIndex,
    currentEditedMilestoneIndex,
    setCurrentEditedSegmentIndex,
    setCurrentEditedStepIndex,
    setCurrentEditedMilestoneIndex,
    currentDecomposedStep,
    setCurrentDecomposedStep,
  } = useContext(EditTimelineModalContext)

  const { opened: isEditingMilestone, setOpen: openMilestoneEditionModal } =
    useModal('editMilestone')

  const { control, getValues, setValue } = useFormContext<TimelineFormProps>()

  const {
    update: editMilestone,
    append: addMilestone,
    fields: milestones,
  } = useFieldArray({
    control,
    name: `segments.${segmentIndex}.milestones`,
  })

  const required = REQUIRED_STEP_TYPES.includes(step.type)
  const isStopover = decomposedStep === 'stopover'

  const milestonesWithIndex = milestones.map((milestone, milestoneIndex) => ({
    milestone,
    milestoneIndex,
  }))

  const milestonesAtStep = milestonesWithIndex.filter(
    ({ milestone }) => milestone.location.id === step.location?.id
  )

  const editedByAdmin = stepHasBeenEditedByAdmin(
    step,
    milestonesAtStep.map((m) => m.milestone)
  )

  const updateStepLocation = (
    index: number,
    timelineFormStep: TimelineFormStep,
    value: SelectValue | null
  ) => {
    editStep(index, {
      ...timelineFormStep,
      location: {
        ...timelineFormStep.location,
        id: value?.value as number,
        name: value?.label,
      },
    })

    if (!isStopover) return

    setValue(`segments.${segmentIndex}.stopovers.touched`, true)

    addNewAlert({
      alert: {
        name: 'stopovers',
        value: t(getAlertTranslationKey(transportType)),
      },
      getValues,
      setValue,
    })
  }

  const removeStopover = (stopoverIndex: number) => {
    if (!removeStep) return

    if (step.isNew) {
      removeStep(stopoverIndex)
    } else {
      editStep(stopoverIndex, {
        ...step,
        active: false,
      })
    }

    setValue(`segments.${segmentIndex}.stopovers.touched`, true)

    addNewAlert({
      alert: {
        name: 'stopovers',
        value: t(getAlertTranslationKey(transportType)),
      },
      getValues,
      setValue,
    })
  }

  const unavailableLocationIds = steps
    .filter((st) => st.active && isPresent(st.location?.id))
    .map((st) => st.location!.id)

  const excludeUnavailableLocations = (locations: SelectValue[]) =>
    locations.filter((location) => !unavailableLocationIds.includes(location.value as number))

  const editedMilestone = milestonesAtStep?.find(
    (milestone) => milestone.milestoneIndex === currentEditedMilestoneIndex
  )?.milestone

  const isBeingEdited =
    currentEditedSegmentIndex === segmentIndex &&
    currentEditedStepIndex === stepIndex &&
    currentDecomposedStep === decomposedStep

  const onMilestoneChange = (milestoneIndex: number) => {
    setCurrentEditedSegmentIndex(segmentIndex)
    setCurrentEditedStepIndex(stepIndex)
    setCurrentEditedMilestoneIndex(milestoneIndex)
    setCurrentDecomposedStep(decomposedStep)
  }

  const buildRevertMilestoneTooltipText = (milestone: TimelineFormMilestone) => {
    const nextMilestoneDate = !milestone.initialState.active
      ? getNextMilestoneDateInformationAfterReversion(milestone).value
      : milestone.actualTime || milestone.estimatedTime || milestone.plannedTime

    if (!isPresent(nextMilestoneDate))
      return t('shipments.dateDeclarationModal.nextAvailableDate.none')

    const dateHelper = new DateHelper(nextMilestoneDate)

    return t('shipments.dateDeclarationModal.nextAvailableDate.one', {
      date: dateHelper.toLocale({ hours: dateHelper.hasHours() }),
    })
  }

  return step.active ? (
    <>
      {isEditingMilestone && isBeingEdited ? (
        <ModalEditMilestone
          presentMilestonesTypes={milestonesAtStep.map((field) => field.milestone.type)}
          transportType={transportType}
          stepType={step.type}
          location={step.location}
          milestone={editedMilestone}
          onApply={(m: TimelineFormMilestone) => {
            if (editedMilestone) {
              editMilestone(currentEditedMilestoneIndex, m)
            } else {
              addMilestone({
                ...m,
                active: true,
                nextDates: {},
                isMilestoneDataOverridden: {},
                location: step.location!,
                initialState: {
                  active: true,
                },
              })
            }
          }}
        />
      ) : null}
      <StyledModalTimelineStepWrapper>
        <StyledModalTimelineStepTypeWrapper>
          {isPresent(typeDescription) && (
            <StyledModalTimelineStepType>
              {typeDescription}
              {required && <StyledInputLabelRequiredIcon>*</StyledInputLabelRequiredIcon>}
              {isPresent(adminEditionTextInfo) && (
                <StyledEditedByAdminTextInfo>{adminEditionTextInfo}</StyledEditedByAdminTextInfo>
              )}
            </StyledModalTimelineStepType>
          )}
        </StyledModalTimelineStepTypeWrapper>
        <StyledModalTimelineStepLocationWrapper>
          <SearchSelect
            action={(params) =>
              fetchLocationOptions({ search: params.search, stepType: step.type })
            }
            fetchedOptionsFormat={excludeUnavailableLocations}
            value={{
              label: isPresent(step.location?.id)
                ? [step.location?.name, step.location?.countryCode]
                    .filter((str) => isPresent(str))
                    .join(', ')
                : '',
              value: step.location?.id,
            }}
            placeholder=''
            onChange={({ value }) => updateStepLocation(stepIndex, step, value)}
            isClearable={false}
          />
        </StyledModalTimelineStepLocationWrapper>
        <StyledModalTimelineStepEditActionsWrapper>
          {editedByAdmin && (
            <StyledModalTimelineStepEditActionButtonWrapper>
              <IconTooltip
                content={t('shipments.dateDeclarationModal.adminDate')}
                placement='top'
                variant='admin_icon'
                size='nowrap'
              />
            </StyledModalTimelineStepEditActionButtonWrapper>
          )}
          {isStopover && (
            <StyledModalTimelineStepEditActionButtonWrapper>
              <Button variant='smallIcon' icon='trash' onClick={() => removeStopover(stepIndex)} />
            </StyledModalTimelineStepEditActionButtonWrapper>
          )}
          {isPresent(step.location?.id) && (
            <StyledModalTimelineStepEditActionButtonWrapper>
              <Button
                variant='smallIcon'
                icon={expanded ? 'arrow_top' : 'arrow_bottom_outline'}
                onClick={() => setExpansion(!expanded)}
              />
            </StyledModalTimelineStepEditActionButtonWrapper>
          )}
        </StyledModalTimelineStepEditActionsWrapper>
      </StyledModalTimelineStepWrapper>
      {expanded && (
        <Table>
          <Table.Body shadowVariant='strong'>
            {milestonesAtStep.map(({ milestone, milestoneIndex }, listIndex) => (
              <StyledModalTimelineMilestoneTableRowWrapper $active={milestone.active}>
                <Table.Row key={milestone.id} odd={listIndex % 2 !== 0}>
                  <Table.Cell>{s('milestones')[milestone.type ?? -1]}</Table.Cell>
                  <Table.Cell fitContent>{formatMilestoneDate(milestone)}</Table.Cell>
                  <Table.Cell small>
                    <StyledModalTimelineMilestoneEditActionsWrapper>
                      {milestoneHasBeenEditedByAdmin(milestone) && (
                        <IconTooltip
                          content={t('shipments.dateDeclarationModal.adminDate')}
                          placement='top'
                          variant='admin_icon'
                          size='nowrap'
                        />
                      )}
                      <Button
                        variant='verySmallIcon'
                        icon='pencil'
                        disabled={!milestone.active || milestoneHasBeenReverted(milestone)}
                        onClick={() => {
                          onMilestoneChange(milestoneIndex)
                          openMilestoneEditionModal(true)
                        }}
                      />
                      {milestone.active ? (
                        <Button
                          variant='verySmallIcon'
                          icon='trash'
                          onClick={() => {
                            onMilestoneChange(milestoneIndex)
                            editMilestone(milestoneIndex, {
                              ...milestone,
                              active: false,
                              activeHasBeenRolledBack: false,
                            })
                          }}
                        />
                      ) : (
                        <HoverTooltip
                          content={buildRevertMilestoneTooltipText(milestone)}
                          size='large'
                          ellipsis
                          visible={!milestone.initialState.active}
                        >
                          <Button
                            variant='verySmallIcon'
                            icon='arrow_go_back'
                            onClick={() => {
                              onMilestoneChange(milestoneIndex)
                              editMilestone(milestoneIndex, {
                                ...milestone,
                                active: true,
                                activeHasBeenRolledBack: true,
                              })
                            }}
                          />
                        </HoverTooltip>
                      )}
                    </StyledModalTimelineMilestoneEditActionsWrapper>
                  </Table.Cell>
                </Table.Row>
              </StyledModalTimelineMilestoneTableRowWrapper>
            ))}
            <Table.Row odd={milestones.length % 2 !== 0}>
              <StyledModalTimelineCreateMilestoneWrapper>
                <Button
                  onClick={() => {
                    onMilestoneChange(-1)
                    openMilestoneEditionModal(true)
                  }}
                  variant='verySmallIcon'
                  icon='plus_outline'
                  text={t('shipments.actions.createNewMilestone')}
                  textRegular
                />
              </StyledModalTimelineCreateMilestoneWrapper>
            </Table.Row>
          </Table.Body>
        </Table>
      )}
    </>
  ) : null
}

export default StepDisplay
