import React, { MouseEvent, MouseEventHandler } from 'react'

import Icon from 'components/icon'

import {
  StyledSavedTemplatesItem,
  StyledSavedTemplatesSection,
  StyledSavedTemplatesTitle,
  StyledSavedTemplatesArrow,
  StyledTemplatesEditModeButton,
} from 'components/booking_templates_saved_item/style'
import Button from 'components/button'

interface BookingTemplatesSavedItemProps {
  label?: string
  editMode?: boolean
  onClickItem?: MouseEventHandler<HTMLButtonElement>
  onClickDelete?: MouseEventHandler<HTMLButtonElement>
  onClickEdit?: MouseEventHandler<HTMLButtonElement>
  testId?: string
}

const BookingTemplatesSavedItem = ({
  label = undefined,
  editMode = false,
  onClickItem,
  onClickDelete,
  onClickEdit,
  testId,
}: BookingTemplatesSavedItemProps) => (
  <StyledSavedTemplatesItem
    as={editMode ? 'div' : 'button'}
    onClick={(e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>) => {
      if (!editMode) {
        onClickItem?.(e as MouseEvent<HTMLButtonElement>)
      }
    }}
    data-testid={testId}
  >
    <StyledSavedTemplatesSection>
      <StyledSavedTemplatesTitle>{label}</StyledSavedTemplatesTitle>
      {!editMode && (
        <StyledSavedTemplatesArrow>
          <Icon name='arrow_bottom_outline' width={24} height={24} />
        </StyledSavedTemplatesArrow>
      )}
      {editMode && (
        <StyledTemplatesEditModeButton>
          <Button icon='pencil' onClick={onClickEdit} variant='smallIcon' />
          <Button icon='trash' onClick={onClickDelete} variant='smallIcon' />
        </StyledTemplatesEditModeButton>
      )}
    </StyledSavedTemplatesSection>
  </StyledSavedTemplatesItem>
)

export default BookingTemplatesSavedItem
