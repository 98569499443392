import React, { FC } from 'react'

import DateHelper from 'services/helpers/date_helper'

import { StyledShipmentListItemTime } from 'components/shipment_list_item/style'
import useArrivalTimes from 'components/shipment_list_item/hooks/use_arrival_times'

const Eta: FC = () => {
  const { actualTime, estimatedTime } = useArrivalTimes()
  return (
    <StyledShipmentListItemTime>
      {actualTime && new DateHelper(actualTime).toLocale()}
      {!actualTime && estimatedTime && new DateHelper(estimatedTime).toLocale()}
    </StyledShipmentListItemTime>
  )
}

export default Eta
