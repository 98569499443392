import hotjar from 'services/analytics/libs/hotjar'
import { isProd, isStaging } from 'services/helpers/environment'

interface Identify {
  id: number
  email: string
  firstName: string
  lastName: string
  organization: string
  company?: string
}

const useBehaviorTracker = () => {
  const canUse = isProd || isStaging
  const id = process.env.REACT_APP_HOTJAR_ID
  const sv = process.env.REACT_APP_HOTJAR_SV
  const init = () => {
    if (canUse) {
      hotjar(id, sv)
    }
  }
  const identify = ({ email, firstName, lastName, organization, company }: Identify) => {
    if (canUse) {
      const w = window as any
      w.hj('identify', id, {
        email,
        firstName,
        lastName,
        organization,
        company,
      })
    }
  }
  return { init, identify }
}

export default useBehaviorTracker
