import React, { useEffect, useState } from 'react'

import useFilter from 'services/hooks/use_filter'

interface UseMultiCheckboxFilterProps {
  name: string
  tags: string[]
}

const useMultiCheckboxFilter = ({ name, tags }: UseMultiCheckboxFilterProps) => {
  const [availableTags, setAvailableTags] = useState<string[]>([])

  const filter = useFilter({
    name,
    type: 'tags',
  })

  useEffect(() => {
    const newTags = tags.filter((tag) => !availableTags.includes(tag))
    if (newTags.length === 0) {
      return
    }

    setAvailableTags(tags)
    filter.setValue([...(filter.value || []), ...newTags])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      filter.setValue([...(filter.value || []), e.target.name])
    } else {
      filter.setValue((filter.value || []).filter((v) => v !== e.target.name))
    }
  }

  return { ...filter, onChange }
}

export default useMultiCheckboxFilter
