import styled from 'styled-components'

import { headerHeight, headerHeightMobile } from 'components/header/style'
import { StyledItem } from 'components/notif_message/style'
import { tablet } from 'styles/utils/breakpoints'
import { zIndexHeader } from 'styles/utils/z_index'

const StyledGlobalNotification = styled.div`
  position: absolute;
  pointer-events: none;
  z-index: ${zIndexHeader - 1};
  display: flex;
  flex-direction: column;
  align-items: center;
  top: calc(${headerHeightMobile} + 20px);
  right: 20px;
  ${StyledItem} {
    margin-bottom: 0.5em;
    pointer-events: all;
  }

  @media ${tablet.up} {
    top: calc(${headerHeight} + 20px);
  }
`

export default StyledGlobalNotification
