import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import PasswordComplexity from 'components/password_complexity'
import Form from 'components/form'
import Grid from 'components/grid'
import Input from 'components/input'
import {
  StyledModalProfileFormButton,
  StyledModalProfileFormError,
} from 'components/modal_profile/style'
import Button from 'components/button'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { changePasswordRequest } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import {
  PasswordData,
  passwordSchema,
} from 'components/modal_profile/components/password_form/types'

const PasswordForm = () => {
  const [apiError, setApiError] = useState<string | null>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<PasswordData>({
    resolver: yupResolver(passwordSchema),
    reValidateMode: 'onChange',
    defaultValues: { password: '', newPassword: '', confirmNewPassword: '' },
  })

  const submit = (data: PasswordData) => {
    dispatch(changePasswordRequest(data))
      .unwrap()
      .then(() => {
        setApiError(null)
        dispatch(
          addNotification({
            type: 'success',
            title: `${t('profile.tabs.password.form.updatePassword')}`,
            text: `${t('profile.tabs.password.form.passwordUpdated')}`,
          })
        )
      })
      .catch(({ message }) => setApiError(message))
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <PasswordComplexity password={watch('newPassword')} />
      <Form.Group>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Input
              label={t('profile.tabs.password.form.currentPassword')}
              type='password'
              id='password'
              {...register('password')}
              error={errors.password?.message ? t(errors.password?.message) : undefined}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Input
              label={t('profile.tabs.password.form.newPassword')}
              type='password'
              id='newPassword'
              {...register('newPassword')}
              error={errors.newPassword?.message ? t(errors.newPassword?.message) : undefined}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Input
              label={t('profile.tabs.password.form.confirmNewPassword')}
              type='password'
              id='confirmNewPassword'
              {...register('confirmNewPassword')}
              error={
                errors.confirmNewPassword?.message
                  ? t(errors.confirmNewPassword?.message)
                  : undefined
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Form.Group>
      <StyledModalProfileFormError>{apiError}</StyledModalProfileFormError>
      <StyledModalProfileFormButton>
        <Button padded text={t('actions.save')} variant='highlight' type='submit' />
      </StyledModalProfileFormButton>
    </Form>
  )
}

export default PasswordForm
