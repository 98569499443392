import styled from 'styled-components'

import { StyledInput } from 'components/input/style'
import { StyledCheckboxPicto } from 'components/checkbox_picto/style'
import { StyledSelect } from 'components/select/style'
import { StyledInputCheckbox } from 'components/input_checkbox/style'
import { StyledDatePickerContainer, StyledDatePicker } from 'components/input_datepicker/style'
import { sidebarPadding } from 'components/sidebar/style'

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Action = styled.button`
  width: 130px;
  flex: none;
  margin: 0 10px;
`
const Content = styled.div`
  overflow-y: auto;
  height: 100%;
  padding: 0 ${sidebarPadding};
`
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
`
const HeaderIcon = styled.div`
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.textLight};
  margin-right: 11px;
`
const Section = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  padding: 10px;

  ${StyledInput}, ${StyledSelect} , ${StyledDatePickerContainer} {
    margin: 10px 0;
  }

  ${StyledDatePicker}, ${StyledSelect} {
    width: 100%;
  }

  ${StyledInputCheckbox} {
    margin: 8px 0;
  }

  ${StyledCheckboxPicto} {
    width: calc(50% - 10px);
    margin-bottom: 20px;
  }
`

const S = {
  Actions,
  Action,
  Content,
  Header,
  HeaderIcon,
  Section,
}

export default S
