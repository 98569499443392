import React, { ReactElement } from 'react'

import { components, OptionProps, OptionTypeBase } from 'react-select'

import {
  StyledReactSelectOptionArrow,
  StyledReactSelectOptionLabel,
  StyledReactSelectOptionSlotBefore,
} from 'components/select/style'
import HoverTooltip from 'components/hover_tooltip'

import { isPresent } from 'services/helpers/values'
import { getTestIdForOption } from 'tests/e2e/test_ids'

const { Option } = components

interface OptionComponentProps extends OptionProps<OptionTypeBase, boolean> {
  data: {
    value: string | number | string[]
    label: string
    slot?: {
      beforeLabel: ReactElement
    }
    isDisabled?: boolean
    hasArrow?: boolean
    tooltip?: string
  }
}

const OptionComponent = ({ data, ...props }: OptionComponentProps) => {
  const testIndex = props.options
    .map(({ value: v }: OptionTypeBase) => v)
    .indexOf(data.value as number)

  const displayedLabel = (
    <>
      <StyledReactSelectOptionLabel data-testid={getTestIdForOption(testIndex)}>
        {data.label}
      </StyledReactSelectOptionLabel>
      {data.hasArrow && <StyledReactSelectOptionArrow />}
    </>
  )

  const tooltipWrappedLabel = (
    <HoverTooltip content={isPresent(data.tooltip) ? data.tooltip : 'N/A'}>
      {displayedLabel}
    </HoverTooltip>
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Option data={data} {...props}>
      {data.slot && data.slot.beforeLabel && (
        <StyledReactSelectOptionSlotBefore>
          {data.slot.beforeLabel}
        </StyledReactSelectOptionSlotBefore>
      )}
      {'tooltip' in data ? tooltipWrappedLabel : displayedLabel}
    </Option>
  )
}

export default OptionComponent
