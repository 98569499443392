import styled, { css } from 'styled-components'

import { borderRadiusRound } from 'styles/utils/border_radius'
import { h3, text14Regular, tagSmall, textSmallDescription } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledSpan = styled.span`
  color: white;
  transition: opacity 0.5s ${easeQuartOut};
`

export const StyledAvatar = styled.div<{
  $outline: boolean
  $color: string
  $size: 'small' | 'medium'
  $withLabel: boolean
  $hover: boolean
}>`
  ${borderRadiusRound};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $color, theme }) => $color || theme.primary};
  position: relative;

  ${(props) =>
    props.$hover &&
    css`
      &:hover {
        ${StyledSpan} {
          opacity: 0.7;
        }
      }
    `};

  ${(props) =>
    props.$size === 'small' &&
    css`
      width: 30px;
      height: 30px;
    `};

  ${(props) =>
    props.$size === 'medium' &&
    css`
      width: 40px;
      height: 40px;
    `};

  ${(props) =>
    props.$outline &&
    css`
      border: 2px solid white;
      box-sizing: content-box;
    `};

  ${(props) =>
    props.$withLabel &&
    css`
      ${StyledSpan} {
        color: #5f75ee;
      }
    `};

  ${(props) =>
    props.$withLabel &&
    props.$size === 'medium' &&
    css`
      ${StyledSpan} {
        ${text14Regular}
      }
    `};

  ${(props) =>
    props.$withLabel &&
    props.$size === 'small' &&
    css`
      ${StyledSpan} {
        ${tagSmall}
      }
    `};

  ${(props) =>
    !props.$withLabel &&
    props.$size === 'medium' &&
    css`
      ${StyledSpan} {
        ${h3}
      }
    `};

  ${(props) =>
    !props.$withLabel &&
    props.$size === 'small' &&
    css`
      ${StyledSpan} {
        ${textSmallDescription}
      }
    `};
`
