import styled, { css } from 'styled-components'

import { headerHeight, headerHeightMobile } from 'components/header/style'
import { POWERED_BY_HEIGHT } from 'components/powered_by/style'
import { StyledButtonIcon, StyledSpan } from 'components/button/style'
import StyledIcon from 'components/icon/style'
import { sidebarWidth } from 'components/sidebar/style'

import { desktopLarge, tablet } from 'styles/utils/breakpoints'
import { h1, textSmallDescription } from 'styles/utils/texts'
import { zIndexPageSidebar, zIndexPageSidebarCta } from 'styles/utils/z_index'
import { gradientLightOpacityReverse } from 'styles/utils/gradients'
import { easeQuartOut } from 'styles/utils/ease'

export const PagePadding = '40px'
export const PageContentMaxWidth = '1200px'

export const Page = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const PageContent = styled.div<{ $plain: boolean }>`
  position: relative;
  overflow: auto;
  flex-grow: 1;
  ${({ $plain }) => !$plain && `padding: ${PagePadding} ${PagePadding} 0 ${PagePadding};`}
  ${gradientLightOpacityReverse}
`

// We overload the page style to get the scrollbar next to the browser border
export const ScrollbarPage = styled(Page)`
  ${PageContent} {
    padding-right: 0px;
  }
`

export const PageMain = styled.main<{ $withHeader: boolean; $withPoweredBy: boolean }>`
  position: relative;
  z-index: 0;
  display: flex;
  height: 100vh;
  overflow: hidden;

  ${({ $withHeader }) =>
    $withHeader &&
    css`
      height: calc(100vh - ${headerHeightMobile});
      margin-top: ${headerHeightMobile};

      @media ${tablet.up} {
        height: calc(100vh - ${headerHeight});
        margin-top: ${headerHeight};
      }
    `}

  ${({ $withPoweredBy }) =>
    $withPoweredBy &&
    css`
      height: calc(100vh - ${POWERED_BY_HEIGHT}px);
    `}
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${PageContentMaxWidth};
  margin-bottom: 12px;
`

export const PageTitle = styled.h1`
  ${h1}
  color: ${({ theme }) => theme.textDark};
  padding-left: ${PagePadding};
  display: flex;
  flex-direction: row;
  align-items: end;

  @media ${desktopLarge.up} {
    padding: 0;
  }
`

export const PageActions = styled.div`
  align-self: flex-end;
  display: flex;
  gap: 18px;
`

export const PageSubtitle = styled.span`
  display: flex;
  padding-left: 10px;
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription}
`

export const PageSidebar = styled.div<{ $open: boolean }>`
  @media ${desktopLarge.down} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${zIndexPageSidebar};
    background-color: ${({ theme }) => theme.white};
    height: 100%;
    width: ${sidebarWidth};
    transition: transform 0.3s ${easeQuartOut};
    transform: translate3d(-100%, 0, 0);
    filter: drop-shadow(0px 2px 25px rgba(0, 0, 0, 0.15));
  }

  ${({ $open }) =>
    $open &&
    css`
      @media ${desktopLarge.down} {
        transform: translate3d(0%, 0, 0);
      }
    `}
`

export const PageSidebarCloseCta = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  ${StyledSpan} {
    ${textSmallDescription};
    color: ${({ theme }) => theme.textLight};
  }
  ${StyledIcon} {
    margin-right: 12px;
    width: 10px;
    height: 10px;
    fill: ${({ theme }) => theme.mediumGray};
  }

  &:hover {
    color: ${({ theme }) => theme.primary};

    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }

  @media ${desktopLarge.up} {
    display: none;
  }
`
export const PageSidebarOpenCta = styled.button`
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: ${zIndexPageSidebarCta};

  ${StyledButtonIcon} {
    fill: ${(props) => props.theme.textLight};
  }

  @media ${desktopLarge.up} {
    display: none;
  }
`

const S = {
  Page,
  ScrollbarPage,
  PageMain,
  PageContent,
  PageHeader,
  PageTitle,
  PageActions,
  PageSidebar,
  PageSidebarOpenCta,
  PageSidebarCloseCta,
  PageSubtitle,
}

export default S
