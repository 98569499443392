import { useRef } from 'react'

const useTimeout = ({ timeout } = { timeout: 1000 }) => {
  const ref = useRef()
  const onTimeout = (callback) => {
    if (ref) {
      clearTimeout(ref.current)
    }
    ref.current = setTimeout(() => {
      callback()
    }, timeout)
  }

  return { onTimeout }
}

export default useTimeout
