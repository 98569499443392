import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import {
  fetchUsers,
  importUsers,
  toggleActivation,
  toggleNotifications,
} from 'features/users/services/user_service'

import {
  Status,
  STATUS_FULFILLED,
  STATUS_PENDING,
  STATUS_REJECTED,
  STATUS_UNSTARTED,
} from 'constants/api'

import type { User } from 'features/users/types/user'
import type { RootState } from 'services/store/store'

const usersAdapter = createEntityAdapter<User>()
const initialState = usersAdapter.getInitialState<{
  status: Status
  totalCount: number
  importStatus: Status
  toggleActivationStatus: Status
}>({
  status: STATUS_PENDING,
  totalCount: 0,
  importStatus: STATUS_UNSTARTED,
  toggleActivationStatus: STATUS_UNSTARTED,
})

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetImportStatus: (state) => {
      state.importStatus = STATUS_UNSTARTED
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.totalCount = action.payload.totalCount
      if (action.meta.arg.page <= 1) {
        usersAdapter.setAll(state, action.payload.users)
      } else {
        usersAdapter.setMany(state, action.payload.users)
      }
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchUsers.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchUsers.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(toggleNotifications.fulfilled, usersAdapter.updateOne)
    builder.addCase(toggleActivation.fulfilled, (state, action) => {
      state.toggleActivationStatus = STATUS_FULFILLED
      usersAdapter.updateOne(state, action)
    })
    builder.addCase(toggleActivation.pending, (state) => {
      state.toggleActivationStatus = STATUS_PENDING
    })
    builder.addCase(toggleActivation.rejected, (state) => {
      state.toggleActivationStatus = STATUS_REJECTED
    })
    builder.addCase(importUsers.fulfilled, (state) => {
      state.importStatus = STATUS_FULFILLED
    })
    builder.addCase(importUsers.pending, (state) => {
      state.importStatus = STATUS_PENDING
    })
    builder.addCase(importUsers.rejected, (state) => {
      state.importStatus = STATUS_REJECTED
    })
  },
})

export const { resetImportStatus } = usersSlice.actions

export const { selectAll: selectUsers } = usersAdapter.getSelectors(
  (state: RootState) => state.users
)
export const selectUsersStatus = (state: RootState) => state.users.status
export const selectUsersTotalCount = (state: RootState) => state.users.totalCount
export const selectUsersImportStatus = (state: RootState) => state.users.importStatus
export const selectToggleActivationStatus = (state: RootState) => state.users.toggleActivationStatus

export default usersSlice.reducer
