import React, { FC } from 'react'

import StyledLogo from 'components/logo/style'

import * as LogoList from 'assets/logos'
import * as ScacLogoList from 'assets/logos/scacs'

export type ClassicalLogoName = keyof typeof LogoList
export type ScacLogoName = keyof typeof ScacLogoList

export type LogoType = ClassicalLogoName | `scacs/${ScacLogoName}`

interface LogoProps {
  name: LogoType
  width?: number
  height?: number
}

const Logo: FC<LogoProps> = ({ name, width, height }) => {
  // eslint-disable-next-line
  const path = require(`assets/logos/${name}.svg`)

  return <StyledLogo src={path} $width={width} $height={height} />
}

export default Logo
