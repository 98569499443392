import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import { fetchAssignableUsers } from 'views/booking/slices/booking_slice'
import { addNotification } from 'views/notifications/slice'
import Grid from 'components/grid'
import Form from 'components/form'
import InputTags from 'components/input_tags'
import NotifMessage from 'components/notif_message'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const KeyContacts: FC<BookingFormProps> = ({ filters }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { keyContactsFilter, forwarderFilter } = filters

  const disabled = keyContactsFilter.isDisabled

  const [assignableUsers, setAssignableUsers] = useState([])
  useEffect(() => {
    if (disabled) return
    dispatch(fetchAssignableUsers({ forwarderId: forwarderFilter.value?.value }))
      .unwrap()
      .then((users) => {
        setAssignableUsers(users)
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
    // eslint-disable-next-line
  }, [forwarderFilter.value, disabled])

  return (
    <Form.Group title={t('bookings.keyContacts.title')}>
      <Grid columns={1} columnsTablet={1}>
        <Grid.Row>
          <Grid.Column>
            <InputTags
              label={t('bookings.keyContacts.emailAddresses')}
              name={keyContactsFilter.name}
              onChange={keyContactsFilter.onChange}
              value={keyContactsFilter.value}
              options={assignableUsers.map(({ email }) => email)}
              pattern='email'
              disabled={disabled}
            />
            <NotifMessage type='tip' text={t('bookings.tooltips.keyContacts')} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default KeyContacts
