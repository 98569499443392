import React, { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

const InformationIncoterms: FC = () => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { t } = useTranslation()
  const { incoterms, incotermsLocation } = booking!

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.incoterms.title')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.incoterms.title')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>{incoterms}</StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>
            {t('bookings.incoterms.incotermsLocation')}
          </StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={incotermsLocation} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationIncoterms
