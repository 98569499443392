import styled from 'styled-components'

import { isPresent } from 'services/helpers/values'

const markerSizeToCss = (size: 'small' | 'medium' | 'large'): string => {
  switch (size) {
    case 'small':
      return '1.25rem'
    case 'medium':
      return '2rem'
    case 'large':
      return '3rem'
    default:
      return '2rem'
  }
}

export const StyledMarker = styled.div<{
  $clickable?: boolean
  $color?: string
  $size: 'small' | 'medium' | 'large'
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $size }) => markerSizeToCss($size)};
  height: ${({ $size }) => markerSizeToCss($size)};
  border-radius: 50%;
  background-color: ${({ $color, theme }) => (isPresent($color) ? $color : theme.primary)};
  ${({ $clickable }) => $clickable && 'cursor: pointer;'}

  svg {
    max-width: 50%;
    max-height: 50%;
    fill: white;
  }
`

export const StyledMarkerLabel = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  text-align: center;
  background-color: ${({ theme }) => theme.warn};
  color: ${({ theme }) => theme.white};
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
`
