import { Middleware } from '@reduxjs/toolkit'

export const PERSISTED_STATE_KEY = 'wakeo_state'

// action to persist
const actionsToPersist = [
  'sso/setSsoUrls',
  'sso/setLogoutHint',
  'sso/setIsSSOUser',
  'sso/setSSOClientId',
]

const getSliceKeyFromAction = (actionType: string) => {
  const [sliceKey] = actionType.split('/')
  return sliceKey
}

// Middleware to persist redux state in localStorage
export const persistStateMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action)

  if (actionsToPersist.includes(action.type)) {
    const sliceKey = getSliceKeyFromAction(action.type)
    const currentState = store.getState()[sliceKey]

    const actualPersistedState = localStorage.getItem(PERSISTED_STATE_KEY)
    const parsedState = actualPersistedState ? JSON.parse(actualPersistedState) : {}
    localStorage.setItem(
      PERSISTED_STATE_KEY,
      JSON.stringify({ ...parsedState, [sliceKey]: currentState })
    )
  }

  return result
}

export default persistStateMiddleware
