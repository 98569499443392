import React from 'react'
import PropTypes from 'prop-types'

import NetworkListItemSkeleton from 'components/network_list_item/skeleton'
import StyledNetworkList from 'components/network_list/style'

const NetworkListSkeleton = ({ count = 5 }: { count?: number }) => (
  <StyledNetworkList>
    {Array.from(Array(count).keys()).map((number) => (
      <NetworkListItemSkeleton key={`network-list-item-skeleton-${number}`} />
    ))}
  </StyledNetworkList>
)

NetworkListSkeleton.propTypes = {
  count: PropTypes.number,
}

NetworkListSkeleton.defaultProps = {
  count: 5,
}

export default NetworkListSkeleton
