import styled, { css } from 'styled-components'

import { text14Bold, text9SemiBold, text12SemiBold, text24SemiBold } from 'styles/utils/texts'
import { borderRadiusRound } from 'styles/utils/border_radius'

import { ReactComponent as CrossIcon } from 'assets/icons/close.svg'

export const StyledValue = styled.span``
export const StyledText = styled.p``

export const StyledCo2Icon = styled(CrossIcon)`
  width: 8px;
  height: 8px;
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledCo2 = styled.div<{ $variant: 'default' | 'condensed' | 'simple' }>`
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.white};
  flex-shrink: 0;

  ${(props) =>
    (props.$variant === 'default' || props.$variant === 'simple') &&
    css`
      padding: 7px 18px 7px 7px;
      ${StyledText} {
        ${text14Bold};
        color: ${({ theme }) => theme.success};
      }

      ${StyledCo2Icon} {
        background: ${({ theme }) => theme.success};
        ${borderRadiusRound};
        width: 30px;
        height: 30px;
        fill: ${({ theme }) => theme.white};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 6px;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    `}

  ${(props) =>
    props.$variant === 'simple' &&
    css`
      padding: 0;
      ${StyledValue} {
        ${text24SemiBold};
      }
    `}
  
  ${(props) =>
    props.$variant === 'condensed' &&
    css`
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      border: 1px solid ${({ theme }) => theme.success};
      background: ${({ theme }) => theme.success};

      ${StyledText} {
        ${text9SemiBold};
        position: relative;
        color: ${({ theme }) => theme.white};
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-left: 21px;
        height: 100%;
      }

      ${StyledValue} {
        ${text12SemiBold};
        color: ${({ theme }) => theme.success};
        padding: 2px 7px 2px 3px;
        display: block;
        background: white;
        height: 100%;
        margin-left: 4px;
      }

      ${StyledCo2Icon} {
        width: 14px;
        height: 14px;
        fill: ${({ theme }) => theme.white};
        position: absolute;
        left: 4px;
      }
    `}
`
