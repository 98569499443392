import React, { useContext, useEffect, useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import Button from 'components/button'
import Modal from 'components/modal'
import useModal from 'components/modal/hooks/use_modal'

import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { reportShipment, fetchShipment } from 'views/shipment/slice'
import ShipmentContext from 'views/shipment/context'
import { addNotification } from 'views/notifications/slice'
import { SelectValue } from 'components/select'
import useShipment from 'views/shipment/hooks/use_shipment'
import {
  StyledModalReportShipmentContainer,
  StyledModalReportShipmentFooter,
  StyledModalReportShipmentLabel,
} from 'components/modal_report_shipment/style'
import { TEST_ID_SHIPMENT_FLAG_REASON_SELECT } from 'tests/e2e/test_ids'
import fromStaticOrdersFlagTradToSelectOptions from 'components/modal_report_shipment/helper'
import MultiSelect from 'components/multiselect'

const ReportShipmentModalName = 'reportShipment'

// List of the reasons_flags ids we want to display

const ModalReportShipment = () => {
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { id } = useContext(ShipmentContext)
  const [shipment] = useShipment({ id: id! })
  const { fromStaticToSelectOptions, s } = useStaticLocales()
  const { setOpen: setReportModalOpen } = useModal(ReportShipmentModalName)
  const staticFlagReasonsTrad = s('flagReasons')

  const [flagReasons, setFlagReasons] = useState<SelectValue[]>(
    fromStaticOrdersFlagTradToSelectOptions(shipment?.orderFlags || [], staticFlagReasonsTrad)
  )

  useEffect(() => {
    setFlagReasons(
      fromStaticOrdersFlagTradToSelectOptions(shipment?.orderFlags || [], staticFlagReasonsTrad)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment?.orderFlags])

  const options = fromStaticToSelectOptions('flagReasons')

  const onSave = () => {
    setIsLoading(true)
    dispatch(
      reportShipment({
        id: id!,
        flagReasonsIds: flagReasons.map((reason) => reason.value) as string[],
      })
    )
      .unwrap()
      .then(() => {
        addNotification({
          type: 'success',
          title: t('shipments.shipmentReported.success'),
          text: t('shipments.shipmentReported.reported'),
        })
      })
      .catch(() => {
        dispatch(
          // TODO report-shipment add proper notification
          addNotification({
            type: 'alert',
            title: t('shipments.shipmentReported.error'),
            text: t('shipments.shipmentReported.notReported'),
          })
        )
      })
      .finally(() => {
        setIsLoading(false)
        dispatch(fetchShipment({ id: id! }))
        setReportModalOpen(false)
      })
  }

  return (
    <Modal modalName={ReportShipmentModalName} width='large'>
      <Modal.Header> {t('shipments.shipmentReported.title')} </Modal.Header>
      <Modal.Content>
        <StyledModalReportShipmentContainer>
          <StyledModalReportShipmentLabel>
            {t('shipments.shipmentReported.label')}
          </StyledModalReportShipmentLabel>
          <MultiSelect
            testId={TEST_ID_SHIPMENT_FLAG_REASON_SELECT}
            value={flagReasons}
            options={options}
            variant='primary'
            placeholder={t('shipments.shipmentReported.placeholder')}
            onChange={({ value }) => {
              setFlagReasons(value)
            }}
          />
        </StyledModalReportShipmentContainer>
      </Modal.Content>
      <StyledModalReportShipmentFooter>
        <Button text={t('actions.cancel')} onClick={() => setReportModalOpen(false)} />
        <Button
          text={
            isLoading ? (
              <ClipLoader loading size={20} aria-label='Loading Spinner' color={theme.primary} />
            ) : (
              t('actions.save')
            )
          }
          onClick={onSave}
          variant={isLoading ? 'default' : 'highlight'}
          disabled={isLoading}
        />
      </StyledModalReportShipmentFooter>
    </Modal>
  )
}

export default ModalReportShipment
