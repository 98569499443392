import { featureCollection, point } from '@turf/helpers'
import { useCallback } from 'react'
import { useTheme } from 'styled-components'

import useShipmentColor from 'views/shipments/hooks/use_shipment_color'

const SOURCE_VEHICLES = 'vehicles-source'
const LOW_CLUSTER_COUNT_THRESHOLD = 50
const HIGH_CLUSTER_COUNT_THRESHOLD = 100
const CLUSTER_RADIUS = 30

const useVehicleClusters = ({ map, buildMarker, onVehicleClick, onClusterClick }) => {
  const theme = useTheme()
  const { getColorFromShipmentColor } = useShipmentColor()
  const clusterizeVehicles = useCallback(
    (vehicles) => {
      const data = featureCollection(
        vehicles.map(({ id, coordinate, type, shipmentsCount, color }) =>
          point(coordinate.array, {
            id,
            type,
            shipmentsCount,
            color,
          })
        )
      )

      if (map.getSource(SOURCE_VEHICLES)) return
      map.addSource(SOURCE_VEHICLES, {
        type: 'geojson',
        data,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: CLUSTER_RADIUS,
      })

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: SOURCE_VEHICLES,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            theme.primary50,
            LOW_CLUSTER_COUNT_THRESHOLD,
            theme.primary75,
            HIGH_CLUSTER_COUNT_THRESHOLD,
            theme.primary,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            LOW_CLUSTER_COUNT_THRESHOLD,
            30,
            HIGH_CLUSTER_COUNT_THRESHOLD,
            40,
          ],
        },
      })

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: SOURCE_VEHICLES,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['Arial Unicode MS Bold', 'DIN Offc Pro Medium'],
          'text-size': 12,
        },
        paint: {
          'text-color': [
            'step',
            ['get', 'point_count'],
            '#ffffff',
            LOW_CLUSTER_COUNT_THRESHOLD,
            '#ffffff',
            HIGH_CLUSTER_COUNT_THRESHOLD,
            '#ffffff',
          ],
        },
      })

      map.on('click', 'clusters', (e) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId = features[0].properties.cluster_id
        map.getSource(SOURCE_VEHICLES).getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          if (onClusterClick) onClusterClick()
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          })
        })
      })

      const markers = {}
      let markersOnScreen = {}

      const updateMarkers = () => {
        const newMarkers = {}
        const features = map.querySourceFeatures(SOURCE_VEHICLES)

        features
          .filter((feature) => !feature.properties.cluster)
          .forEach((feature) => {
            const coordinate = feature.geometry.coordinates
            const { id, type, shipmentsCount, color } = feature.properties
            const label = shipmentsCount > 1 ? shipmentsCount : null
            const markerColor = getColorFromShipmentColor(color)
            let marker = markers[id]
            if (!marker) {
              marker = buildMarker({ type, coordinate, label, color: markerColor })
              marker.getElement().addEventListener('click', (e) => {
                e.preventDefault()
                const { x, y, width, height } = e.target.getBoundingClientRect()
                if (onVehicleClick) {
                  onVehicleClick({
                    id,
                    x: x + width / 2,
                    y: y + height / 2,
                    type,
                    label,
                    color,
                  })
                }
              })
              markers[id] = marker
            }
            newMarkers[id] = marker

            if (!markersOnScreen[id]) marker.addTo(map)
          })

        Object.keys(markersOnScreen).forEach((id) => {
          if (!newMarkers[id]) markersOnScreen[id].remove()
        })
        markersOnScreen = newMarkers
      }

      map.on('render', () => {
        if (!map.isSourceLoaded(SOURCE_VEHICLES)) return
        updateMarkers()
      })

      /* eslint-disable no-param-reassign */
      map.on('mouseenter', 'clusters', () => {
        map.getCanvas().style.cursor = 'pointer'
      })
      map.on('mouseleave', 'clusters', () => {
        map.getCanvas().style.cursor = ''
      })
    },
    [
      buildMarker,
      map,
      onClusterClick,
      onVehicleClick,
      theme.primary,
      theme.primary50,
      theme.primary75,
      getColorFromShipmentColor,
    ]
  )
  return clusterizeVehicles
}

export default useVehicleClusters
