import styled from 'styled-components'

import { StyledAvatar } from 'components/avatar/style'
import { StyledReactSelect } from 'components/select/style'

const StyledAvatarList = styled.div`
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
  cursor: pointer;

  ${StyledAvatar} {
    margin-left: -20px;
  }

  ${StyledReactSelect} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    ${StyledAvatar} {
      margin-left: 0;
    }
    .Select__menu {
      min-width: 210px;
      left: 50%;
      transform: translateX(-50%);
    }
    .Select__option {
      color: ${({ theme }) => theme.textDark};
    }
  }
`

export default StyledAvatarList
