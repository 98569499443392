import { fr, enGB, pt, es, it, nl, de } from 'date-fns/locale'

export const LOCALE_FR = 'fr'
export const LOCALE_EN = 'en'
export const LOCALE_PT = 'pt'
export const LOCALE_IT = 'it'
export const LOCALE_ES = 'es'
export const LOCALE_NL = 'nl'
export const LOCALE_DE = 'de'

export const LOCALES = [
  LOCALE_EN,
  LOCALE_FR,
  LOCALE_PT,
  LOCALE_NL,
  LOCALE_DE,
  LOCALE_IT,
  LOCALE_ES,
] as const

export type SupportedLanguage = typeof LOCALES[number]

export const LOCALES_TO_FLAGS_MAPPING = {
  [LOCALE_EN]: 'US',
  [LOCALE_FR]: 'FR',
  [LOCALE_PT]: 'PT',
  [LOCALE_NL]: 'NL',
  [LOCALE_DE]: 'DE',
  [LOCALE_IT]: 'IT',
  [LOCALE_ES]: 'ES',
} as const

export const LOCALES_TO_NAMES_MAPPING = {
  [LOCALE_EN]: 'English',
  [LOCALE_FR]: 'Français',
  [LOCALE_PT]: 'Português',
  [LOCALE_NL]: 'Nederlands',
  [LOCALE_DE]: 'Deutsch',
  [LOCALE_IT]: 'Italiano',
  [LOCALE_ES]: 'Español',
} as const

export const LOCALES_DATE_FNS_MAPPING = {
  [LOCALE_EN]: enGB,
  [LOCALE_FR]: fr,
  [LOCALE_PT]: pt,
  [LOCALE_NL]: nl,
  [LOCALE_DE]: de,
  [LOCALE_IT]: it,
  [LOCALE_ES]: es,
} as const
