import { object, string, array, InferType } from 'yup'

import { patternEmail } from 'services/helpers/form_validation'

export const sharedShipmentSchema = object().shape({
  emails: array().of(string().required().matches(patternEmail)).min(1).required(),
  subject: string().trim().required(),
  message: string().required(),
})

export type SharedShipmentData = InferType<typeof sharedShipmentSchema>
