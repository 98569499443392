import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledPoweredBy } from 'components/powered_by/style'

const PoweredBy = () => {
  const { t } = useTranslation()
  return <StyledPoweredBy>{t('powered_by')} wakeo</StyledPoweredBy>
}
export default PoweredBy
