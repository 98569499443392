import styled from 'styled-components'

import { text14Regular, text16Regular } from 'styles/utils/texts'
import { borderRadiusMedium } from 'styles/utils/border_radius'
import { StyledButton } from 'components/button/style'

export const StyledUploadDoc = styled.div``

export const StyledUploadDocTitle = styled.div`
  ${text16Regular}
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 30px;
  text-align: center;
`
export const StyledUploadDocFiles = styled.div`
  border: 2px solid #e3e6ec;
  ${borderRadiusMedium};
  padding: 4px 15px;
`

export const StyledUploadDocInfo = styled.p`
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};
  text-align: center;
  margin-top: 18px;
`
export const StyledUploadDocFilesButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  ${StyledButton} {
    margin: 0 7px;
  }
`
