import styled, { css } from 'styled-components'

import { tablet, desktop } from 'styles/utils/breakpoints'
import { StyledInputCheckbox } from 'components/input_checkbox/style'
import { StyledInput } from 'components/input/style'
import { StyledSelect } from 'components/select/style'
import { StyledDatePicker } from 'components/input_datepicker/style'
import { StyledCheckboxPicto } from 'components/checkbox_picto/style'
import { StyledTextarea } from 'components/textarea/style'
import { StyledSwitchInput } from 'components/switch_input/style'
import { StyledItem } from 'components/notif_message/style'
import { StyledBlockContainerAddBlock } from 'components/block_container/style'

export const StyledGrid = styled.div`
  ${(props) =>
    props.$columns &&
    css`
      ${StyledColumn} {
        flex: none;
        width: calc(100% / ${props.$columns});
        @media ${desktop.down} {
          width: 50%;
        }

        @media ${tablet.down} {
          width: 100%;
        }
      }
    `}
  ${(props) =>
    props.$columnsTablet &&
    css`
      ${StyledColumn} {
        @media ${desktop.down} {
          width: calc(100% / ${props.$columnsTablet});
        }
      }
    `}
  ${(props) =>
    props.$columnsMobile &&
    css`
      ${StyledColumn} {
        @media ${tablet.down} {
          width: calc(100% / ${props.$columnsMobile});
        }
      }
    `}
  ${(props) =>
    props.$condensed &&
    css`
      ${StyledInputCheckbox}, ${StyledInput}, ${StyledSelect}, ${StyledDatePicker}, ${StyledCheckboxPicto}, ${StyledTextarea}, ${StyledSwitchInput}, ${StyledItem}, ${StyledBlockContainerAddBlock} {
        margin-bottom: 0px;
      }
    `}
  ${(props) =>
    props.$condensed &&
    css`
      ${StyledRow} {
        margin-bottom: 10px;
      }
    `}
  ${(props) =>
    props.$condensed &&
    css`
      ${StyledBlockContainerAddBlock} {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    `}
`

export const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 15px;
  ${(props) =>
    props.$compact &&
    css`
      margin-bottom: 0;
    `}
  }

  ${(props) =>
    props.$itemsCentered &&
    css`
      align-items: center;
    `}
  }
`

export const StyledColumn = styled.div`
  flex: 1;

  @media ${tablet.down} {
    width: 100%;
  }
  ${(props) =>
    props.$columns &&
    props.$size &&
    css`
      min-width: calc((100% / ${props.$columns}) * ${props.$size});
    `}
  ${(props) =>
    props.$offset &&
    props.$columns &&
    css`
      margin-left: calc((100% / ${props.$columns}) * ${props.$offset});
      @media ${desktop.down} {
        margin-left: 0;
      }
    `}
  ${(props) =>
    props.$padded &&
    css`
      :not(:last-child) {
        padding-right: 18px;
      }
      @media ${tablet.down} {
        :not(:last-child) {
          padding-right: 8px;
        }
      }
    `}
  ${(props) =>
    props.$centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`
