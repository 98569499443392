import styled from 'styled-components'

import { StyledBoxShadow, StyledBoxShadowInner } from 'components/box_shadow/style'
import { StyledPanelsWrapper } from 'components/tabs/style'

export const StyledBlockList = styled(StyledBoxShadow)`
  ${StyledPanelsWrapper} {
    margin-top: 35px;
  }

  ${StyledBoxShadowInner} {
    padding: 22px 10px 0px 10px;
  }
`

export const StyledTitle = styled.h2`
  padding: 0.8em 1em;
  font-size: 18px;
`

export const StyledButton = styled.div`
  margin-left: 10px;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
`

export const StyledButtonEditWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 1;
`
