import React from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import S from 'views/trusted_routes/components/search_bar/style'
import { fetchPorts, selectActiveQueryParams } from 'views/trusted_routes/slice'
import Select from 'components/select'
import Button from 'components/button'
import DateHelper from 'services/helpers/date_helper'
import InputDatepicker from 'components/input_datepicker'
import useQueryParams from 'services/hooks/use_query_params'
import useOnce from 'services/hooks/use_once'
import { isEmptyObject } from 'services/helpers/values'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import InputCheckbox from 'components/input_checkbox'
import {
  SearchBarQueryParams,
  SubscribeTrustedRoutesData,
  SubscribeTrustedRoutesDataBeforeValidation,
  subscribeTrustedRoutesSchema,
} from 'views/trusted_routes/components/search_bar/types'
import { SUBSCRIPTION_PENDING, UNSTARTED } from 'views/trusted_routes/types/status'
import useSearchTrustedRoutes from 'views/trusted_routes/hooks/use_search_trusted_routes'

const inFuture = (value: string | Date): boolean => new DateHelper(value).isBetweenDates(new Date())
const extractNameFromSelectLabel = (value: string | undefined): string =>
  value?.match(/^(.*?) \([a-zA-Z0-9]+\)$/)?.[1] || ''

const SearchBar: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { subscribe, status } = useSearchTrustedRoutes()

  const storedQueryParams = useSelector(selectActiveQueryParams)
  const urlQueryParams = useQueryParams<SearchBarQueryParams>()

  const user = useCurrentUser()
  const withRatesSetting = !!user.trustedRouteSettings?.features.withRates

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = useForm<SubscribeTrustedRoutesDataBeforeValidation, undefined, SubscribeTrustedRoutesData>({
    resolver: yupResolver(subscribeTrustedRoutesSchema),
    // It prioritizes urlQueryParams from the URL query parameters, and if those are not available,
    // it falls back to storedQueryParams from the Redux store.
    defaultValues: {
      departure:
        urlQueryParams.departureLocode && urlQueryParams.departure
          ? {
              value: urlQueryParams.departureLocode,
              label: `${urlQueryParams.departure} (${urlQueryParams.departureLocode})`,
            }
          : storedQueryParams.departure,
      arrival:
        urlQueryParams.arrivalLocode && urlQueryParams.arrival
          ? {
              value: urlQueryParams.arrivalLocode,
              label: `${urlQueryParams.arrival} (${urlQueryParams.arrivalLocode})`,
            }
          : storedQueryParams.arrival,
      fromDate:
        urlQueryParams.fromDate && inFuture(urlQueryParams.fromDate)
          ? urlQueryParams.fromDate
          : storedQueryParams.fromDate,
      withRates: false,
    },
  })

  const submit = (data: SubscribeTrustedRoutesData) => {
    history.replace({
      pathname: location.pathname,
      search: Object.entries({
        departureLocode: data.departure.value,
        departure: extractNameFromSelectLabel(data.departure.label),
        arrivalLocode: data.arrival.value,
        arrival: extractNameFromSelectLabel(data.arrival.label),
        fromDate: data.fromDate,
      })
        .map(([k, v]) => `${k}=${v}`)
        .join('&'),
    })
    subscribe(data).then(() => reset(data, { keepValues: true }))
  }

  // Inject query params in the url if they are present in redux store
  // This occurs when the user navigates back to trusted routes through the menu
  useOnce(() => {
    if (
      storedQueryParams.departure &&
      storedQueryParams.arrival &&
      storedQueryParams.fromDate &&
      isEmptyObject(urlQueryParams)
    ) {
      history.replace({
        pathname: location.pathname,
        search: Object.entries({
          departureLocode: storedQueryParams.departure.value,
          departure: extractNameFromSelectLabel(storedQueryParams.departure.label),
          arrivalLocode: storedQueryParams.arrival.value,
          arrival: extractNameFromSelectLabel(storedQueryParams.arrival.label),
          fromDate: storedQueryParams.fromDate,
        })
          .map(([k, v]) => `${k}=${v}`)
          .join('&'),
      })
    }
  })

  return (
    <form onSubmit={handleSubmit(submit)}>
      <S.Searchbar>
        <S.Container>
          <S.FormFields>
            <S.FormField>
              <Controller
                control={control}
                name='departure'
                render={({ field }) => (
                  <Select
                    onChange={({ value }) => field.onChange(value)}
                    isClearable
                    isSearchable
                    async
                    label={t('trustedRoutes.departure.label')}
                    value={field.value}
                    placeholder={t('trustedRoutes.departure.placeholder')}
                    name={field.name}
                    fetch={({ value }) => fetchPorts({ value })}
                    fetchOnFocus={() => fetchPorts({ value: null })}
                    fetchedOptionsFormat={(options) =>
                      options.map(({ name, locode }: { name: string; locode: string }) => ({
                        value: locode,
                        label: `${name} (${locode})`,
                      }))
                    }
                  />
                )}
              />
            </S.FormField>
            <S.FormField>
              <Controller
                control={control}
                name='arrival'
                render={({ field }) => (
                  <Select
                    onChange={({ value }) => field.onChange(value)}
                    isClearable
                    isSearchable
                    async
                    label={t('trustedRoutes.arrival.label')}
                    value={field.value}
                    placeholder={t('trustedRoutes.arrival.placeholder')}
                    name={field.name}
                    fetch={({ value }) => fetchPorts({ value })}
                    fetchOnFocus={() => fetchPorts({ value: null })}
                    fetchedOptionsFormat={(options) =>
                      options.map(({ name, locode }: { name: string; locode: string }) => ({
                        value: locode,
                        label: `${name} (${locode})`,
                      }))
                    }
                  />
                )}
              />
            </S.FormField>
            <S.FormField>
              <Controller
                control={control}
                name='fromDate'
                render={({ field }) => (
                  <InputDatepicker
                    withPortal
                    label={t('trustedRoutes.fromDate.label')}
                    placeholder={t('trustedRoutes.fromDate.placeholder')}
                    name={field.name}
                    onChange={({ value }) => {
                      const newValue = value?.[0] ? value?.[0].toISOString() : null
                      field.onChange(newValue)
                    }}
                    startDate={field.value}
                    filterDate={inFuture}
                  />
                )}
              />
            </S.FormField>
            {withRatesSetting && (
              <S.RateFormField>
                <Controller
                  name='withRates'
                  control={control}
                  render={({ field }) => (
                    <InputCheckbox
                      id={field.name}
                      name={field.name}
                      text={t('trustedRoutes.includeRates.label')}
                      onChange={({ target: { checked } }) => field.onChange(checked)}
                      checked={field.value}
                    />
                  )}
                />
              </S.RateFormField>
            )}
          </S.FormFields>
          <Button
            text={t('actions.search')}
            type='submit'
            variant='highlight'
            padded
            disabled={
              !isValid || status === SUBSCRIPTION_PENDING || (!isDirty && status !== UNSTARTED)
            }
          />
        </S.Container>
      </S.Searchbar>
    </form>
  )
}

export default SearchBar
