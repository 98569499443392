import styled, { css } from 'styled-components'
import { ReactSVG } from 'react-svg'

const StyledIcon = styled(ReactSVG)<{
  $fill?: string
  $stroke?: string
  $width?: number
  $height?: number
}>`
  svg {
    display: block;
    font-size: 0;
    line-height: 0;
  }

  ${(props) =>
    css`
      fill: ${props.$fill};
    `}
  ${(props) =>
    css`
      stroke: ${props.$stroke};
    `}

  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `}
`

export default StyledIcon
