import styled from 'styled-components'

import { desktopLarge } from 'styles/utils/breakpoints'
import { PageContentMaxWidth } from 'components/page/style'
import { text16Regular } from 'styles/utils/texts'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media ${desktopLarge.up} {
    max-width: ${PageContentMaxWidth};
  }
`
const Text = styled.p`
  ${text16Regular};
`

const Loader = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  @media ${desktopLarge.up} {
    max-width: ${PageContentMaxWidth};
  }
`

const S = {
  Header,
  Text,
  Loader,
}

export default S
