import { useContext } from 'react'

import ModalContext from 'components/modal/context'

import type { ModalName } from 'components/modal/types/names'

const useModal = (name: ModalName) => {
  const {
    setOpen,
    modals,
  }: {
    setOpen: (name: ModalName, open: boolean) => void
    modals: Record<ModalName, boolean>
  } = useContext(ModalContext)
  return {
    setOpen: (open: boolean) => setOpen(name, open),
    opened: modals[name],
  }
}
export default useModal
