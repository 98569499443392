import React from 'react'
import ContentLoader from 'react-content-loader'

const TextListItemSkeleton = () => (
  <ContentLoader
    speed={2}
    width={300}
    height={200}
    viewBox='0 0 300 200'
    backgroundColor='#f7f7f7'
    foregroundColor='#f0efef'
  >
    <rect x='0' y='0' rx='0' ry='0' width='130' height='16' />
    <rect x='220' y='0' rx='0' ry='0' width='80' height='16' />
    <rect x='0' y='55' rx='0' ry='0' width='110' height='16' />
    <rect x='210' y='55' rx='0' ry='0' width='90' height='16' />
    <rect x='0' y='110' rx='0' ry='0' width='120' height='16' />
    <rect x='220' y='110' rx='0' ry='0' width='80' height='16' />
    <rect x='0' y='165' rx='0' ry='0' width='130' height='16' />
    <rect x='200' y='165' rx='0' ry='0' width='100' height='16' />
  </ContentLoader>
)

TextListItemSkeleton.propTypes = {}

TextListItemSkeleton.defaultProps = {}

export default TextListItemSkeleton
