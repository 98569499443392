import { createAsyncThunk } from '@reduxjs/toolkit'

import { State } from 'views/atlas/types/state'
import { Tvalue } from 'components/select'
import { RootState } from 'services/store/store'
import useUrlParams from 'services/api/hooks/use_url_params'
import { selectCurrentUser } from 'views/iam/slices/iamSlice'
import { AtlasClient } from 'services/api/clients'
import onError from 'services/api/error'

interface StatePayload {
  token: string
  name: string
  country: {
    id: string
    name: string
    codeAlpha2: string
  }
}

type StatesResponse = StatePayload[]

const fetchStates = createAsyncThunk<State[], { value: Tvalue }, { state: RootState }>(
  'atlas/states/fetchStates',
  async ({ value }: { value: Tvalue }, thunkAPI) => {
    const { getState } = thunkAPI
    const url = useUrlParams('/states', { q: value })
    const user = selectCurrentUser(getState())

    return AtlasClient.get<StatesResponse>(url, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
      .then((r) =>
        r.data.map(
          (state): State => ({
            token: state.token,
            name: `${state.name} (${state.country.codeAlpha2})`,
          })
        )
      )
      .catch(onError(thunkAPI))
  }
)

export default fetchStates
