import React from 'react'
import ContentLoader from 'react-content-loader'

import useBreakpoints from 'services/hooks/use_breakpoints'
import {
  StyledNetworkListItem,
  StyledNetworkListItemTitle,
} from 'components/network_list_item/style'

const NetworkListItemSkeleton = () => {
  const { isTablet, isMobile } = useBreakpoints()

  return (
    <StyledNetworkListItem>
      <StyledNetworkListItemTitle>
        {!isTablet && !isMobile && (
          <ContentLoader width={680} height={24} viewBox='0 0 680 24'>
            <rect x='0' y='8' rx='3' ry='3' width='150' height='7' />
            <rect x='190' y='8' rx='3' ry='3' width='280' height='7' />
          </ContentLoader>
        )}
        {(isTablet || isMobile) && (
          <ContentLoader width={680} height={44} viewBox='0 0 680 44'>
            <rect x='0' y='5' rx='3' ry='3' width='150' height='8' />
            <rect x='0' y='32' rx='3' ry='3' width='220' height='8' />
          </ContentLoader>
        )}
      </StyledNetworkListItemTitle>
    </StyledNetworkListItem>
  )
}

NetworkListItemSkeleton.propTypes = {}

NetworkListItemSkeleton.defaultProps = {}

export default NetworkListItemSkeleton
