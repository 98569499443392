import React, { FC, ReactNode, memo } from 'react'

import { StyledModalContent } from 'components/modal/style'

const ContentComponent: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledModalContent>{children}</StyledModalContent>
)

const Content = memo(ContentComponent)

export default Content
