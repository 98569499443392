import { isPresent } from 'services/helpers/values'
import usePrevious from 'services/hooks/use_previous'

import type { AnyValue } from 'types/common'

const usePreviousChanged = (value: AnyValue) => {
  const prevValue = usePrevious(value)
  return isPresent(prevValue) && prevValue !== value
}

export default usePreviousChanged
