import React, { useState, createContext, useMemo, Dispatch, FC } from 'react'

import { Any } from 'services/helpers/types'
import { Category } from 'services/hooks/use_category'
import { AnyFilter } from 'services/hooks/use_filter'

type Categories = Category[]
type QueryParams = Record<string, Any>

interface FormContextValue {
  filters: AnyFilter[]
  categories: Categories
  queryParams: QueryParams
  filtersHash: Record<string, AnyFilter>
  setFilters: Dispatch<AnyFilter[]>
  setCategories: Dispatch<Categories>
  setQueryParams: Dispatch<QueryParams>
}

export const FormContext = createContext<FormContextValue>({
  filters: [],
  filtersHash: {},
  categories: [],
  queryParams: {},
  setFilters: () => ({}),
  setCategories: () => [],
  setQueryParams: () => ({}),
})

export const FormProvider: FC = ({ children }) => {
  const [filters, setFilters] = useState<AnyFilter[]>([])
  const [categories, setCategories] = useState<Categories>([])
  const [queryParams, setQueryParams] = useState<QueryParams>({})

  const providerValue = useMemo(
    () => ({
      filters,
      setFilters,
      filtersHash: categories.map((c) => c.filtersHash).reduce((acc, h) => ({ ...acc, ...h }), {}),
      categories,
      setCategories,
      queryParams,
      setQueryParams,
    }),
    [categories, filters, queryParams]
  )

  return <FormContext.Provider value={providerValue}>{children}</FormContext.Provider>
}
