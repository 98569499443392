import React from 'react'

import ContentLoader from 'react-content-loader'

const TimelineFormSkeleton = () => (
  <ContentLoader
    viewBox='0 0 725 600'
    height={600}
    style={{ width: '100%' }}
    backgroundColor='#f5f5f5'
    foregroundColor='#dbdbdb'
  >
    <circle cx='57' cy='40' r='12' />
    <rect x='80' y='34' rx='3' ry='3' width='280' height='12' />
    <rect x='370' y='34' rx='3' ry='3' width='300' height='12' />

    <circle cx='57' cy='140' r='12' />
    <rect x='80' y='134' rx='3' ry='3' width='280' height='12' />
    <rect x='370' y='134' rx='3' ry='3' width='300' height='12' />

    <circle cx='57' cy='240' r='12' />
    <rect x='80' y='234' rx='3' ry='3' width='280' height='12' />
    <rect x='370' y='234' rx='3' ry='3' width='300' height='12' />

    <circle cx='57' cy='340' r='12' />
    <rect x='80' y='334' rx='3' ry='3' width='280' height='12' />
    <rect x='370' y='334' rx='3' ry='3' width='300' height='12' />

    <circle cx='57' cy='440' r='12' />
    <rect x='80' y='434' rx='3' ry='3' width='280' height='12' />
    <rect x='370' y='434' rx='3' ry='3' width='300' height='12' />
  </ContentLoader>
)

export default TimelineFormSkeleton
