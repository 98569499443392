import React, { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import { isAnyArray } from 'services/helpers/values'

const InformationReferences: FC = () => {
  const { token } = useContext(BookingContext)
  const { t } = useTranslation()
  const [booking] = useBooking(token)
  const { clientReference, clientBookingNumber, forwarderReference, customReferences } = booking!

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.references.title')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems $width='large'>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>
            {t('bookings.references.clientReference')}
          </StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>{clientReference}</StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>
            {t('bookings.references.clientBookingNumber')}
          </StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={clientBookingNumber} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>
            {t('bookings.references.forwarderReference')}
          </StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={forwarderReference} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>

        {isAnyArray(customReferences) &&
          customReferences.map(({ key, value }) => (
            <StyledFormEditGroupItem>
              <StyledFormEditGroupLabel>{key}</StyledFormEditGroupLabel>
              <StyledFormEditGroupValue>{value}</StyledFormEditGroupValue>
            </StyledFormEditGroupItem>
          ))}
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationReferences
