import React, { FC } from 'react'

import StyledTabPanel from 'components/tabs/tab_panel/style'

interface TabPanelProps {
  active: boolean
}

const TabPanel: FC<TabPanelProps> = ({ active, children }) => (
  <StyledTabPanel $active={active}>{children}</StyledTabPanel>
)

export default TabPanel
