import React from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/icon'
import { StyledCo2, StyledValue, StyledText, StyledCo2Icon } from 'components/co2/style'

interface Co2Props {
  value: number | string
  variant?: 'default' | 'condensed' | 'simple'
}

const Co2 = ({ value, variant = 'default' }: Co2Props) => {
  const { t } = useTranslation()
  return (
    <StyledCo2 $variant={variant}>
      <StyledCo2Icon as={Icon} name='ecology' />
      {variant === 'default' && (
        <StyledText>{t('shipments.emissions.tons_of_co2', { value })}</StyledText>
      )}
      {variant === 'condensed' && (
        <StyledText>
          <span>CO2e</span>
          <StyledValue>{value} t</StyledValue>
        </StyledText>
      )}
      {variant === 'simple' && (
        <StyledText>
          <StyledValue>{value}</StyledValue>
        </StyledText>
      )}
    </StyledCo2>
  )
}

export default Co2
