import { lineString, multiPoint, point, featureCollection } from '@turf/helpers'
import rhumbBearing from '@turf/rhumb-bearing'

import { CoordinateModel } from 'components/map/models'

const DISCONTINUITY_THRESHOLD = 250

const createFeature = (coordinates) => {
  if (coordinates.length < 2) return multiPoint(coordinates.map((c) => c.array))
  return lineString(coordinates.map((c) => c.array))
}

const createPointsFeature = (path) => {
  const points = path.map(({ coordinate, timestamp }) => point(coordinate.array, { timestamp }))

  return featureCollection(points)
}

const bear = (coordinates) => {
  if (coordinates.length < 2) return 0
  return rhumbBearing(
    coordinates[coordinates.length - 1].array,
    coordinates[coordinates.length - 2].array
  )
}

const middleLongitude = (longitudes) => {
  if (longitudes.length === 0) return 0.0

  const firstLng = longitudes[0]
  const lastLng = longitudes[longitudes.length - 1]
  const avgLng = (firstLng + lastLng) / 2
  return avgLng
}

const isDiscontinuityEven = (longitudes) => {
  const discontinuityInstances = longitudes
    .slice(0, -1)
    .filter((lng, idx) => Math.abs(longitudes[idx + 1] - lng) > DISCONTINUITY_THRESHOLD)
  return discontinuityInstances % 2 === 0
}
const recenterLng = ({ lng, normalizedCenterLng }) => {
  let centeredLng = lng
  if (lng > normalizedCenterLng + 180) {
    centeredLng -= 360
  } else if (lng < normalizedCenterLng - 180) {
    centeredLng += 360
  }
  return centeredLng
}
const recenterCoordinate = ({ coordinate: { lat, lng }, normalizedCenterLng }) =>
  new CoordinateModel(lat, recenterLng({ lng, normalizedCenterLng }))

const recenterLngs = ({ coordinates, normalizedCenterLng }) =>
  coordinates.map((coordinate) => recenterCoordinate({ coordinate, normalizedCenterLng }))

const normalize = (coordinates, center = 0) => {
  const longitudes = coordinates.map((p) => p.lng)
  const centerLng = middleLongitude(longitudes)
  const normalizedCenterLng =
    center || (isDiscontinuityEven(longitudes) ? centerLng : centerLng + 180)

  return {
    normalized: recenterLngs({
      coordinates,
      normalizedCenterLng,
    }),
    center: normalizedCenterLng,
  }
}

const useCoordinates = () => ({
  normalize,
  createFeature,
  createPointsFeature,
  bear,
})

export default useCoordinates
