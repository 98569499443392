import styled from 'styled-components'

import { shadow } from 'styles/utils/shadow'

export const StyledFormWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.softGray};
  width: 100%;
  padding: 20px;
  border-radius: 25px;
  ${shadow}
`
export const StyledRevertButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
  span {
    color: ${({ theme }) => theme.primary};
  }
`

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.warn};
  padding: 10px;
`
