import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { anyChildren, numberInferiorEqualTo } from 'services/helpers/prop_types'

import { StyledColumn } from 'components/grid/style'
import GridContext from 'components/grid/context'

const Column = ({ children, offset, size, padded, centered }) => {
  const gridColumns = useContext(GridContext)
  return (
    <StyledColumn
      $offset={offset}
      $columns={gridColumns}
      $size={size}
      $padded={padded}
      $centered={centered}
    >
      {children}
    </StyledColumn>
  )
}

Column.propTypes = {
  /** children */
  children: anyChildren,
  /** size */
  size: numberInferiorEqualTo(16, false),
  /** offset */
  offset: PropTypes.number,
  /** padded */
  padded: PropTypes.bool,
  /** centered */
  centered: PropTypes.bool,
}

Column.defaultProps = {
  children: undefined,
  offset: undefined,
  size: undefined,
  padded: true,
  centered: false,
}

export default Column
