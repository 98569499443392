import React from 'react'

import LoginForm from 'components/login_form'

import IAm from 'views/iam'

const SignIn: React.FC = () => (
  <IAm>
    <LoginForm />
  </IAm>
)

export default SignIn
