import useCurrentUser from 'views/iam/hooks/use_current_user'

import type { Permission } from 'views/iam/types'

const useUserCan = () => {
  const user = useCurrentUser()
  return (name: Permission): boolean =>
    user.isSignedIn ? user.permissions.some((p) => p === name) : false
}

export default useUserCan
