export const STATUS_PENDING = 'status_pending'
export const STATUS_FULFILLED = 'status_fulfilled'
export const STATUS_REJECTED = 'status_rejected'
export const STATUS_UNSTARTED = 'status_unstarted'
export const STATUSES = [
  STATUS_PENDING,
  STATUS_FULFILLED,
  STATUS_REJECTED,
  STATUS_UNSTARTED,
] as const
export type Status = typeof STATUSES[number]

export const LEGACY_TEAMS_PARAM_NAME = 'organization_codes'
export const TEAMS_PARAM_NAME = 'teams'

export const ORDER_SHORTEST = 'SHORTEST'
export const ORDER_ASCENDING = 'ASC'
