import React, { useContext, useEffect } from 'react'

import Co2 from 'components/co2'
import useEmissions from 'views/shipment/hooks/use_emissions'
import { fetchEmissions } from 'views/shipment/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import ShipmentContext from 'views/shipment/context'

const ShipmentEmissions = () => {
  const { id } = useContext(ShipmentContext)
  const dispatch = useAppDispatch()
  const [emissions, status] = useEmissions({ id })

  useEffect(() => {
    if (!status.ready) {
      dispatch(fetchEmissions({ id }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, status.ready])
  return emissions.co2E ? <Co2 value={emissions.co2E} /> : <></>
}

export default ShipmentEmissions
