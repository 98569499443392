import { useSelector } from 'react-redux'

import useStatus, { UseStatus } from 'services/api/hooks/use_status'

import {
  selectShipmentsFromSource,
  selectShipmentsStatus,
  selectShipmentsTotalCount,
} from 'views/shipments/slice'
import { SearchSource } from 'constants/shipment_source'
import { Shipment } from 'views/shipments/types/shipment'

const useShipments = (source: SearchSource): [Shipment[], UseStatus, number | undefined] => {
  const shipments = useSelector(selectShipmentsFromSource(source))
  const status = useStatus(useSelector(selectShipmentsStatus))
  const totalCount = useSelector(selectShipmentsTotalCount)
  return [shipments, status, totalCount]
}

export default useShipments
