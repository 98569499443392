import { useTranslation } from 'react-i18next'

import DateHelper from 'services/helpers/date_helper'
import { isInputPresent, isPresent } from 'services/helpers/values'
import useCategory from 'services/hooks/use_category'

import useArrivalForecast from 'components/shipment_filters_custom/hooks/use_arrival_forecast'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useQueryParamsFormatting from 'views/shipments/hooks/use_query_params_formatting'

const useCategories = ({ filters: allFilters }) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { formatDateRange } = useQueryParamsFormatting()
  const etaDiff = user.profile.settings.etaDifference
  const {
    organizationFilter,
    reportedReasonFilter,
    referenceFilter,
    etaDiffMinFilter,
    etaDiffMaxFilter,
    arrivalForecastEarlyFilter,
    arrivalForecastOnTimeFilter,
    arrivalForecastDelayedFilter,
    statusActiveFilter,
    statusPastFilter,
    statusExpiredFilter,
    transportModeSeaFilter,
    transportModeAirFilter,
    transportModeRailFilter,
    transportModeRoadFilter,
    transportModeParcelFilter,
    routingPickupFilter,
    routingPolFilter,
    routingPodFilter,
    routingDeliveryFilter,
    vesselFilter,
    alertFilter,
    partiesForwarderFilter,
    partiesShipperFilter,
    partiesConsignorFilter,
    partiesConsigneeFilter,
    partiesShippingLineFilter,
    partiesAirlineFilter,
    partiesCarrierFilter,
    transportationDatePickupFilter,
    transportationDatePolFilter,
    transportationDatePodFilter,
    transportationDateDeliveryFilter,
    bookingFilter,
    creationDateFilter,
    userVisibilityFilter,
  } = allFilters

  const multiSelectOnDelete = (value) => (filter) =>
    filter.setValue(filter.value.filter(({ value: filterValue }) => filterValue !== value))

  const multiSelectOnTags = (filters) =>
    filters
      .map(({ value: filterValue, name }) =>
        filterValue.map(({ value, label }) => ({
          value,
          label,
          name,
        }))
      )
      .flat()
      .map(({ value, name, label }) => ({
        value: label,
        names: [name],
        onDelete: multiSelectOnDelete(value),
      }))

  const selectOnTags = (filters) =>
    filters.map(({ value: filterValue, name }) => ({
      value: filterValue?.label,
      names: [name],
    }))

  const dateRangeOnTags = (filters) =>
    filters.map(({ value, name }) => ({
      value: [value.start, value.end]
        .filter((v) => v)
        .map((v) => new DateHelper(v).toLocale())
        .join(' - '),
      names: [name],
    }))

  const adminMap = {
    organization: 'organization',
    reportedReason: 'flag_reason_ids',
    createdAt: 'created_at',
    userVisibility: 'userIdVisibility',
  }

  const adminCategory = useCategory({
    name: 'admin',
    filters: [organizationFilter, reportedReasonFilter, creationDateFilter, userVisibilityFilter],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce((acc, { name, value, type }) => {
          if (type === 'dateRange') {
            const { start, end } = formatDateRange(value)

            acc[`${adminMap[name]}_gt`] = start
            acc[`${adminMap[name]}_lt`] = end
            return acc
          }

          if (type === 'multiselect') {
            acc[`${adminMap[name]}_eq`] = value.map(({ value: fieldValue }) => fieldValue)
            return acc
          }

          acc[`${adminMap[name]}`] = value
          return acc
        }, {}),
    toTags: (filters) => [
      ...selectOnTags(filters.filter((f) => f.type === 'select')),
      ...multiSelectOnTags(filters.filter((f) => f.type === 'multiselect')),
      ...dateRangeOnTags(filters.filter((f) => f.type === 'dateRange')),
    ],
  })

  const referenceCategory = useCategory({
    name: 'reference',
    filters: [referenceFilter],
    toQueryParams: (filters) => (filters[0].value ? { reference_match: filters[0].value } : {}),
    toTags: (filters) => filters.map(({ value, name }) => ({ value, names: [name] })),
  })
  const etaDiffCategory = useCategory({
    name: 'etaDiff',
    filters: [etaDiffMinFilter, etaDiffMaxFilter],
    toQueryParams: (_filters, filtersHash) => {
      const { etaDiffMin, etaDiffMax } = filtersHash
      const min = etaDiffMin?.value
      const max = etaDiffMax?.value
      const params = {}
      if (isInputPresent(min)) {
        params[`eta_diff_${etaDiff}_gt`] = min
      }
      if (isInputPresent(max)) {
        params[`eta_diff_${etaDiff}_lt`] = max
      }
      return params
    },
    toTags: (filters) => {
      const min = filters.find(({ name }) => name === 'etaDiffMin')
      const max = filters.find(({ name }) => name === 'etaDiffMax')
      let tagValue = null
      if (isInputPresent(min.value) && isInputPresent(max.value)) {
        tagValue = [min, max]
          .filter(({ value }) => isInputPresent(value))
          .map(({ value }) => `${value}${t('measures.daysInitial')}`)
          .join(` ${t('operators.to')} `)
      } else if (isInputPresent(min.value)) {
        tagValue = `≥${min.value}${t('measures.daysInitial')}`
      } else if (isInputPresent(max.value)) {
        tagValue = `≤${max.value}${t('measures.daysInitial')}`
      }

      return [
        {
          value: tagValue,
          names: [min.name, max.name].filter((n) => n),
        },
      ]
    },
  })
  const statusesMap = {
    statusPast: 'past',
    statusActive: 'active',
    statusExpired: 'expired',
  }
  const statusCategory = useCategory({
    name: 'status',
    filters: [statusPastFilter, statusActiveFilter, statusExpiredFilter],
    toQueryParams: (filters) => {
      if (filters.every((f) => !f.value)) return {}

      return {
        status_in: filters.filter((f) => f.isPresent).map(({ name }) => statusesMap[name]),
      }
    },
    toTags: (filters) =>
      filters
        .filter(({ value }) => value)
        .map(({ name }) => ({
          value: t(`shipments.filters.${statusesMap[name]}`),
          names: [name],
        })),
  })
  const arrivalForecast = useArrivalForecast()
  const forecastMap = {
    arrivalForecastEarly: 'early',
    arrivalForecastOnTime: 'onTime',
    arrivalForecastDelayed: 'delayed',
  }
  const arrivalForecastCategory = useCategory({
    name: 'arrivalForecast',
    filters: [
      arrivalForecastEarlyFilter,
      arrivalForecastOnTimeFilter,
      arrivalForecastDelayedFilter,
    ],
    toQueryParams: (filters) => {
      const { value, operator } = arrivalForecast.find(filters)

      if (isPresent(value)) {
        return { [`eta_diff_${etaDiff}_${operator}`]: value }
      }
      return {}
    },
    toTags: (filters) =>
      filters
        .filter(({ value }) => value)
        .map(({ name }) => ({
          value: t(`shipments.filters.${forecastMap[name]}`),
          names: [name],
        })),
  })

  const transportModesMap = {
    transportModeSea: 'sea',
    transportModeAir: 'air',
    transportModeRail: 'rail',
    transportModeRoad: 'road',
    transportModeParcel: 'parcel',
  }
  const transportModeCategory = useCategory({
    name: 'transportMode',
    filters: [
      transportModeSeaFilter,
      transportModeAirFilter,
      transportModeRailFilter,
      transportModeRoadFilter,
      transportModeParcelFilter,
    ],
    toQueryParams: (filters) => {
      if (filters.every((f) => !f.value)) return {}

      return {
        transport_mode_in: filters
          .filter((f) => f.isPresent)
          .map(({ name }) => transportModesMap[name]),
      }
    },
    toTags: (filters) =>
      filters
        .filter(({ value }) => value)
        .map(({ name }) => ({
          value: t(`shipments.filters.${transportModesMap[name]}`),
          names: [name],
        })),
  })

  const routingMap = {
    routingPickup: '_pickup',
    routingPol: '_pol',
    routingPod: '_pod',
    routingDelivery: '_delivery',
  }
  const routingCategory = useCategory({
    name: 'routing',
    filters: [routingPickupFilter, routingPolFilter, routingPodFilter, routingDeliveryFilter],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce((acc, { name, value }) => {
          const countryValues = value
            .filter(({ type }) => type === 'country')
            .map(({ value: fieldValue }) => fieldValue)
          if (countryValues.length !== 0) {
            acc[`routing_country${routingMap[name]}_eq`] = countryValues
          }
          const routingValues = value
            .filter(({ type }) => type === 'location')
            .map(({ value: fieldValue }) => fieldValue)
          if (routingValues.length !== 0) {
            acc[`routing${routingMap[name]}_eq`] = routingValues
          }
          return acc
        }, {}),
    toTags: multiSelectOnTags,
  })

  const partiesMap = {
    partiesForwarder: 'forwarder',
    partiesShipper: 'shipper',
    partiesConsignor: 'consignor',
    partiesConsignee: 'consignee',
    partiesShippingLine: 'shipping_line',
    partiesAirline: 'airline',
    partiesCarrier: 'carrier',
  }
  const partiesCategory = useCategory({
    name: 'parties',
    filters: [
      partiesForwarderFilter,
      partiesShipperFilter,
      partiesConsignorFilter,
      partiesConsigneeFilter,
      partiesShippingLineFilter,
      partiesAirlineFilter,
      partiesCarrierFilter,
    ],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce((acc, { name, value }) => {
          acc[`parties_${partiesMap[name]}_eq`] = value.map(({ value: fieldValue }) => fieldValue)
          return acc
        }, {}),
    toTags: multiSelectOnTags,
  })

  const transportationDateMap = {
    transportationDatePickup: 'transportation_date_pickup_etd',
    transportationDatePol: 'transportation_date_pol_etd',
    transportationDatePod: 'transportation_date_pod_eta',
    transportationDateDelivery: 'transportation_date_delivery_eta',
  }
  const transportationDateCategory = useCategory({
    name: 'transportationDate',
    filters: [
      transportationDatePickupFilter,
      transportationDatePolFilter,
      transportationDatePodFilter,
      transportationDateDeliveryFilter,
    ],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce((acc, { name, value }) => {
          const { start, end } = formatDateRange(value)

          acc[`${transportationDateMap[name]}_gt`] = start
          acc[`${transportationDateMap[name]}_lt`] = end
          return acc
        }, {}),
    toTags: (filters) =>
      filters.map(({ value, name }) => ({
        value: [value.start, value.end]
          .filter((v) => v)
          .map((v) => new DateHelper(v).toLocale())
          .join(' - '),
        names: [name],
      })),
  })

  const vesselCategory = useCategory({
    name: 'vessel',
    filters: [vesselFilter],
    toQueryParams: (filters) =>
      filters[0].isPresent
        ? { vessel_eq: filters[0].value.map(({ value: fieldValue }) => fieldValue) }
        : {},
    toTags: multiSelectOnTags,
  })

  const alertCategory = useCategory({
    name: 'alert',
    filters: [alertFilter],
    toQueryParams: (filters) => {
      const activeOptions = filters[0].value
      if (activeOptions?.length) {
        return {
          ongoing_alert_type_in: activeOptions.map(({ value }) => value),
        }
      }
      return null
    },
    toTags: multiSelectOnTags,
  })

  const bookingCategory = useCategory({
    name: 'booking',
    filters: [bookingFilter],
    toQueryParams: (filters) =>
      filters[0].value ? { booking_tokens_in: filters[0].value?.value } : {},
    toTags: (filters) =>
      filters.map(({ value, name }) => ({
        value: `Booking reference: ${value?.label}`,
        names: [name],
      })),
  })

  const categories = {
    adminCategory,
    referenceCategory,
    etaDiffCategory,
    statusCategory,
    arrivalForecastCategory,
    transportModeCategory,
    routingCategory,
    partiesCategory,
    transportationDateCategory,
    alertCategory,
    vesselCategory,
    bookingCategory,
  }

  const arrayOfCategories = Object.values(categories)

  return {
    categories,
    arrayOfCategories,
  }
}

export default useCategories
