import { useEffect, useCallback } from 'react'

import useBreakpoints from 'services/hooks/use_breakpoints'
import useWindowResize from 'services/hooks/use_window_resize'

const useSearchBarSize = ({
  searchRef,
  leftBlockRef,
  rightBlockRef,
  wrapperRef,
  searchWrapperRef,
  searchOpen,
}) => {
  const { isMobile } = useBreakpoints()

  const MARGIN_LEFT_SEARCH = isMobile ? 10 : 20

  const getSearchBarSize = useCallback(
    ({ windowWidth }) => {
      const searchBarSize =
        windowWidth -
        (windowWidth - wrapperRef.current.offsetWidth) -
        leftBlockRef.current.offsetWidth -
        rightBlockRef.current.offsetWidth +
        searchWrapperRef.current.offsetWidth -
        MARGIN_LEFT_SEARCH

      return `${Math.min(searchBarSize, 770)}px`
    },
    [MARGIN_LEFT_SEARCH, leftBlockRef, rightBlockRef, wrapperRef, searchWrapperRef]
  )

  const getSearchBarRight = useCallback(
    ({ windowWidth }) => {
      if (!searchOpen) {
        return '0px'
      }

      const searchBarSize =
        windowWidth -
        (windowWidth - wrapperRef.current.offsetWidth) -
        Math.min(leftBlockRef.current.offsetWidth, rightBlockRef.current.offsetWidth) * 2 +
        searchWrapperRef.current.offsetWidth -
        MARGIN_LEFT_SEARCH

      return `${(searchBarSize - Math.min(searchBarSize, 770)) / 2}px`
    },
    [MARGIN_LEFT_SEARCH, leftBlockRef, rightBlockRef, wrapperRef, searchWrapperRef, searchOpen]
  )

  /* eslint-disable no-param-reassign */
  useEffect(() => {
    searchRef.current.querySelector('div').style.width = getSearchBarSize({
      windowWidth: window.innerWidth,
    })
    searchWrapperRef.current.querySelector('div').style.right = getSearchBarRight({
      windowWidth: window.innerWidth,
    })
  }, [searchRef, searchWrapperRef, getSearchBarSize, getSearchBarRight])

  useWindowResize((e) => {
    searchRef.current.querySelector('div').style.width = getSearchBarSize({
      windowWidth: e.innerWidth,
    })
    searchWrapperRef.current.querySelector('div').style.right = getSearchBarRight({
      windowWidth: window.innerWidth,
    })
  })
}

export default useSearchBarSize
