import styled from 'styled-components'

import { StyledHeading } from 'components/accordion_item/style'

const Heading = styled(StyledHeading)`
  &:hover {
    color: ${({ theme }) => theme.textLight};
  }
  padding-bottom: 0px;
`

const Separator = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  padding-bottom: 15px;
`

const S = {
  Heading,
  Separator,
}

export default S
