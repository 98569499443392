import React from 'react'

import ContentLoader from 'react-content-loader'

const QuickInfoSkeleton = () => (
  <ContentLoader
    speed={2}
    width={390}
    height={110}
    viewBox='0 0 390 110'
    backgroundColor='#f7f7f7'
    foregroundColor='#f0efef'
  >
    <rect x='79' y='58' rx='5' ry='5' width='137' height='17' />
    <rect x='0' y='-34' rx='0' ry='0' width='70' height='34' />
    <rect x='79' y='87' rx='0' ry='0' width='146' height='10' />
    <rect x='14' y='39' rx='0' ry='0' width='47' height='42' />
    <rect x='78' y='24' rx='0' ry='0' width='53' height='23' />
    <rect x='309' y='43' rx='0' ry='0' width='58' height='28' />
  </ContentLoader>
)

export default QuickInfoSkeleton
