import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

import { PageContentMaxWidth } from 'components/page/style'

const UserSkeleton: FC = () => (
  <ContentLoader
    viewBox='0 0 1200 80'
    style={{ width: '100%', height: '80px', marginBottom: '15px', maxWidth: PageContentMaxWidth }}
  >
    <rect x='0' y='0' rx='25' ry='25' width='1200' height='80' />
  </ContentLoader>
)

export default UserSkeleton
