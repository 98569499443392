import React from 'react'
import { useTranslation } from 'react-i18next'

import { Rate } from 'views/trusted_routes/types/trusted_route'
import S from 'views/trusted_routes/components/item/style'
import Table from 'components/table'
import DateHelper from 'services/helpers/date_helper'
import { formatRateType } from 'views/trusted_routes/components/item/tool'

interface RatesProps {
  rates: Rate[]
}

const Rates: React.FC<RatesProps> = ({ rates }) => {
  const { t } = useTranslation()

  const formatValidity = (rate: Rate): string | null => {
    if (rate.validityEnd || rate.validityBegin) {
      return `${
        rate.validityBegin ? new DateHelper(rate.validityBegin).formattedOriginalDate() : 'n/a'
      } > ${rate.validityEnd ? new DateHelper(rate.validityEnd).formattedOriginalDate() : 'n/a'}`
    }
    if (rate.validitySpot) {
      return `ETD ${new DateHelper(rate.validitySpot).formattedOriginalDate()}`
    }
    return null
  }

  return (
    <S.UnfoldedBaseContent>
      <Table>
        <Table.Head>
          <Table.HeadCell>{t('trustedRoutes.rates.rate')}</Table.HeadCell>
          <Table.HeadCell>{t('trustedRoutes.rates.reference')}</Table.HeadCell>
          <Table.HeadCell>{t('trustedRoutes.rates.company')}</Table.HeadCell>
          <Table.HeadCell>{t('trustedRoutes.rates.20_dry')}</Table.HeadCell>
          <Table.HeadCell>{t('trustedRoutes.rates.40_dry')}</Table.HeadCell>
          <Table.HeadCell>{t('trustedRoutes.rates.40_hc')}</Table.HeadCell>
          <Table.HeadCell>{t('trustedRoutes.rates.validity')}</Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {rates.map((rate: Rate, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Table.Row odd={index % 2 === 0} alignedTop key={`rates-${index}`}>
              <Table.Cell>{formatRateType(rate)}</Table.Cell>
              <Table.Cell>{rate.reference}</Table.Cell>
              <Table.Cell>{rate.nac}</Table.Cell>
              <Table.Cell>
                {rate.rate20Dry ? `${rate.rate20Dry} ${rate.currency || 'USD'}` : null}
              </Table.Cell>
              <Table.Cell>
                {rate.rate40Dry ? `${rate.rate40Dry} ${rate.currency || 'USD'}` : null}
              </Table.Cell>
              <Table.Cell>
                {rate.rate40Hc ? `${rate.rate40Hc} ${rate.currency || 'USD'}` : null}
              </Table.Cell>
              <Table.Cell>{formatValidity(rate)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <S.GoToOkargo>
        <span>{t('trustedRoutes.rates.for_more_detail')}&nbsp;</span>
        <a href='https://app.okargo.com/' target='_blank' rel='noreferrer'>
          {t('trustedRoutes.rates.go_to_okargo')}
        </a>
      </S.GoToOkargo>
    </S.UnfoldedBaseContent>
  )
}

export default Rates
