import React, { FC, useContext } from 'react'

import FiltersSidebar from 'components/filters_sidebar'
import Select, { SelectValue } from 'components/select'
import AuditTrailFiltersContext from 'views/audit_trail/contexts/audit_trail_filters_context'
import useFilters from 'views/audit_trail/hooks/use_filters'
import useCategories from 'views/audit_trail/hooks/use_categories'
import useForm from 'services/hooks/use_form'
import {
  fetchSectionTypeOptions,
  fetchSegmentIndicesOptions,
  fetchActorOptions,
  fetchStepTypeOptions,
  fetchMilestoneOptions,
} from 'views/audit_trail/slice'
import { toCamelCase } from 'services/helpers/values'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import S from 'views/audit_trail/components/style'

const Filters: FC = () => {
  const filters = useFilters()
  const { sectionFilter, segmentFilter, actorFilter, stepFilter, milestoneFilter } = filters
  const { arrayOfCategories } = useCategories(filters)
  const { s } = useStaticLocales()

  const { apply, reset } = useForm({
    categories: arrayOfCategories,
    onApply: () => {
      const newFilters = arrayOfCategories.reduce((acc, c) => ({ ...acc, ...c.queryParams }), {})
      setFilters(newFilters)
    },
  })

  const { id, setFilters } = useContext(AuditTrailFiltersContext)

  return (
    <FiltersSidebar
      onClear={() => {
        reset()
        apply()
      }}
      onApply={apply}
    >
      <FiltersSidebar.Section title='Section' defaultOpened>
        <Select
          label='Section type'
          name={sectionFilter.name}
          fetchOnFocus={() => fetchSectionTypeOptions(id)}
          fetchedOptionsFormat={(options: SelectValue[]) =>
            options.map(({ value, label }) => ({
              value,
              label: s('shipmentVersionsSections')[toCamelCase(label)],
            }))
          }
          onChange={sectionFilter.onChange}
          value={sectionFilter.value}
          isClearable
          isSearchable={false}
          async
        />

        <S.Separator />
        <S.Heading $small>Timeline</S.Heading>

        <Select
          label='Step'
          name={stepFilter.name}
          fetchOnFocus={() => fetchStepTypeOptions(id)}
          onChange={stepFilter.onChange}
          value={stepFilter.value}
          isClearable
          isSearchable={false}
          async
        />
        <Select
          label='Milestone'
          name={milestoneFilter.name}
          fetchOnFocus={() => fetchMilestoneOptions(id)}
          onChange={milestoneFilter.onChange}
          value={milestoneFilter.value}
          isClearable
          isSearchable={false}
          async
        />
        <S.Separator />

        <Select
          label='Segment'
          name={segmentFilter.name}
          fetchOnFocus={() => fetchSegmentIndicesOptions(id)}
          onChange={segmentFilter.onChange}
          value={segmentFilter.value}
          isClearable
          isSearchable={false}
          async
        />
      </FiltersSidebar.Section>
      <FiltersSidebar.Section title='Actor' defaultOpened>
        <Select
          name={actorFilter.name}
          fetchOnFocus={() => fetchActorOptions(id)}
          fetchedOptionsFormat={(options: SelectValue[]) =>
            options.map(({ value, label }) => ({
              value,
              label: s('auditActor')[toCamelCase(label)] || label,
            }))
          }
          onChange={actorFilter.onChange}
          value={actorFilter.value}
          isClearable
          isSearchable={false}
          async
        />
      </FiltersSidebar.Section>
    </FiltersSidebar>
  )
}

export default Filters
