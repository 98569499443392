import React, { FC, useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Icon from 'components/icon'
import AccordionItem from 'components/accordion_item'
import Button from 'components/button'
import IconTooltip from 'components/icon_tooltip'

import iconsMapping from 'services/helpers/icons_mapping'
import { isPresent, isAnyArray } from 'services/helpers/values'
import useWindowResize from 'services/hooks/use_window_resize'

import { PARCEL_APP_SUBSCRIPTION, SLAPP_SUBSCRIPTION } from 'constants/shipments'
import {
  ORDER_LINKS_READ,
  SEA_TRACKER_READ,
  CONNECTIVITY_TRACKING_MANAGE,
} from 'constants/permissions'

import {
  StyledBlockTransportationItem,
  StyledBlockTransportationItemHeading,
  StyledBlockTransportationItemHeadingIcon,
  StyledBlockTransportationItemHeadingPlace,
  StyledBlockTransportationItemHeadingArrow,
  StyledBlockTransportationItemContentWrapper,
  StyledBlockTransportationItemContent,
  StyledBlockTransportationItemContentDataWrapper,
  StyledBlockTransportationItemContentDataName,
  StyledBlockTransportationItemContentDataGroupValue,
  StyledBlockTransportationItemContentDataValue,
  StyledBlockTransportationItemHeadingPlaces,
  S,
} from 'components/block_transportation/style'

import useUserCan from 'views/iam/hooks/use_user_can'
import AppContext from 'app/contexts/app_context'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_EMBED_HIDDEN_MASTER_BL_AND_BN } from 'constants/organization_features'

import type { TransportType } from 'constants/shipments'

interface Place {
  name: string
  country: {
    name: string
    code: string
  }
}

interface BlockTransportationItemsProps {
  className: string
  type: TransportType
  from: Place
  to: Place
  data: Record<string, any>
  extraData: Record<string, any>
  linksData: Record<string, any>
  defaultOpened: boolean
  isSubscriptionIntegrationOnHold: boolean
  sanityCheckFailureReason: string
  subscriptionType: string
  testId: string
}

const BlockTransportationItem: FC<BlockTransportationItemsProps> = ({
  className,
  type,
  from,
  to,
  data,
  extraData,
  linksData,
  defaultOpened = false,
  isSubscriptionIntegrationOnHold,
  sanityCheckFailureReason,
  subscriptionType,
  testId,
}) => {
  const { isEmbedded } = useContext(AppContext)
  const userCan = useUserCan()
  const { features } = useOrganizationCan()
  const { t } = useTranslation()
  const sanityCheckErrorMessage = isPresent(sanityCheckFailureReason)
    ? `${t('shipments.subscriptions.sanityCheck.hold')}: ${sanityCheckFailureReason}`
    : t('shipments.subscriptions.sanityCheck.hold')
  const Heading = (
    <StyledBlockTransportationItemHeading className={className}>
      <StyledBlockTransportationItemHeadingIcon
        as={Icon}
        name={iconsMapping(type, 'transportation')}
      />

      <StyledBlockTransportationItemHeadingPlaces>
        <StyledBlockTransportationItemHeadingPlace $isFrom>
          {from.name}, {from.country.code}
        </StyledBlockTransportationItemHeadingPlace>

        <StyledBlockTransportationItemHeadingArrow
          as={Icon}
          name='arrow_right_outline'
          width={10}
          height={10}
          fill=''
        />

        <StyledBlockTransportationItemHeadingPlace>
          {to.name}, {to.country.code}
        </StyledBlockTransportationItemHeadingPlace>
      </StyledBlockTransportationItemHeadingPlaces>
      {isSubscriptionIntegrationOnHold && userCan(SEA_TRACKER_READ) && (
        <S.HeadingSubscriptionIntegrationStatus>
          <IconTooltip content={sanityCheckErrorMessage} size='fit' />
        </S.HeadingSubscriptionIntegrationStatus>
      )}
    </StyledBlockTransportationItemHeading>
  )

  const dataValueItem = (value: unknown, link: string) => (
    <StyledBlockTransportationItemContentDataValue>
      {value}
      {userCan(ORDER_LINKS_READ) && isPresent(link) && (
        <Button isLink to={link} icon='external_link' variant='verySmallIcon' />
      )}
    </StyledBlockTransportationItemContentDataValue>
  )

  const shouldHideMasterBlAndBn = isEmbedded && features(WITH_EMBED_HIDDEN_MASTER_BL_AND_BN)
  const shouldDisplayConnectivityResource =
    userCan(CONNECTIVITY_TRACKING_MANAGE) &&
    [SLAPP_SUBSCRIPTION, PARCEL_APP_SUBSCRIPTION].includes(subscriptionType)

  const dataItems = Object.entries(data)
    .filter(([key]) => !shouldHideMasterBlAndBn || !['masterBl', 'bookingNumber'].includes(key))
    .filter(([key]) => key !== 'connectivityResource' || shouldDisplayConnectivityResource)
    .map(([key, value]) => (
      <StyledBlockTransportationItemContentDataWrapper key={`transportation-item-${key}`}>
        <StyledBlockTransportationItemContentDataName>
          {t(`shipments.transportation.${key}`)}
        </StyledBlockTransportationItemContentDataName>
        <StyledBlockTransportationItemContentDataGroupValue>
          {isAnyArray(value)
            ? value.map((v: any) => dataValueItem(v, linksData[key][v]))
            : dataValueItem(value, linksData[key])}
        </StyledBlockTransportationItemContentDataGroupValue>
      </StyledBlockTransportationItemContentDataWrapper>
    ))

  const extraDataItems = Object.entries(extraData).map(([key, value]) => (
    <StyledBlockTransportationItemContentDataWrapper key={`transportation-item-${key}`}>
      <StyledBlockTransportationItemContentDataName>
        {t(`shipments.transportation.${key}`)}
      </StyledBlockTransportationItemContentDataName>
      <StyledBlockTransportationItemContentDataValue>
        {value}
      </StyledBlockTransportationItemContentDataValue>
    </StyledBlockTransportationItemContentDataWrapper>
  ))

  const [isOpen, setIsOpen] = useState(defaultOpened)
  const dataItemsContainer = useRef<HTMLDivElement>(null)
  const dataItemContent = useRef<HTMLDivElement>(null)

  useWindowResize(() => {
    if (isPresent(dataItemContent.current) && isPresent(dataItemsContainer.current)) {
      dataItemContent.current.style.height = `${dataItemsContainer.current.offsetHeight}px`
    }
  }, true)

  return (
    <StyledBlockTransportationItem $isOpen={isOpen}>
      <AccordionItem
        heading={Heading}
        defaultOpened={defaultOpened}
        onChangeCallback={setIsOpen}
        testId={testId}
      >
        <StyledBlockTransportationItemContentWrapper>
          <StyledBlockTransportationItemContent ref={dataItemContent}>
            <div ref={dataItemsContainer}>{dataItems}</div>
            {extraDataItems}
          </StyledBlockTransportationItemContent>
        </StyledBlockTransportationItemContentWrapper>
      </AccordionItem>
    </StyledBlockTransportationItem>
  )
}

export default BlockTransportationItem
