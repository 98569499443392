import React from 'react'

import OrganizationLogo from 'components/organization_logo'

import { StyledSignIn, StyledSignInForm, StyledSignInLogo } from 'views/iam/style'

const IAm: React.FC = ({ children }) => (
  <StyledSignIn>
    <StyledSignInForm>
      <StyledSignInLogo>
        <OrganizationLogo />
      </StyledSignInLogo>
      {children}
    </StyledSignInForm>
  </StyledSignIn>
)

export default IAm
