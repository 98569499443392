import { createContext, MutableRefObject } from 'react'

interface ModalContextValues {
  modals: Record<string, boolean>
  setOpen: (modalName: string, open: boolean) => void
  closeAll: () => void
  anyOpen: boolean
  ref?: MutableRefObject<HTMLDivElement | null>
}
const ModalContext = createContext<ModalContextValues>({
  modals: {},
  setOpen: () => {},
  closeAll: () => {},
  anyOpen: false,
  ref: undefined,
})

export default ModalContext
