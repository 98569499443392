import { InferType, object, string, mixed, array, number } from 'yup'

import { HubType, possibleHubTypes } from 'views/atlas/types/hub'
import {
  allowAirportFields,
  expectClusterField,
  expectLocodeFields,
  expectPositionsField,
} from 'views/atlas/hubs/components/hub_form/helper'

const validLocodePattern = /^[A-Z]{2}[A-Z2-9]{3}$/
const validLocodeErrorMessage =
  'the field must be composed of 5 uppercase characters, 2 letters followed by 3 alphanumeric characters.'
export const saveHubSchema = object({
  name: string().required().min(2),
  type: object({
    label: string().required(),
    value: mixed<HubType>().oneOf(possibleHubTypes).required(),
  }).required(),
  locode: string()
    .optional()
    .when('type', {
      is: ({ value }: { value: HubType }) => expectLocodeFields(value),
      then: (schema) =>
        schema.required().matches(validLocodePattern, { message: validLocodeErrorMessage }),
      otherwise: (schema) =>
        schema.matches(validLocodePattern, {
          message: validLocodeErrorMessage,
          excludeEmptyString: true,
        }),
    }),
  locodeAliases: array()
    .of(string().required().matches(validLocodePattern, { message: validLocodeErrorMessage }))
    .optional()
    .when('type', {
      is: ({ value }: { value: HubType }) => expectLocodeFields(value),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
  iataCode: string()
    .optional()
    .when('type', {
      is: ({ value }: { value: HubType }) => allowAirportFields(value),
      then: (schema) =>
        schema
          .required()
          .matches(/^[A-Z]{3}$/, { message: 'the field must only contain 3 uppercase letters' }),
      otherwise: (schema) => schema,
    }),
  icaoCode: string()
    .optional()
    .when('type', {
      is: ({ value }: { value: HubType }) => allowAirportFields(value),
      then: (schema) =>
        schema.matches(/^[A-Z1-9]{4}$/, {
          message: 'the field must only contain 4 uppercase letters or digits',
          excludeEmptyString: true,
        }),
      otherwise: (schema) => schema,
    }),
  postalCode: string().optional(),
  position: object({
    longitude: number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(-180)
      .max(180),
    latitude: number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .min(-90)
      .max(90),
  })
    .when('type', {
      is: ({ value }: { value: HubType }) => expectPositionsField(value),
      then: (schema) =>
        schema.shape({
          longitude: number()
            .required()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(-180)
            .max(180),
          latitude: number()
            .required()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .min(-180)
            .max(180),
        }),
    })
    .required(),
  cluster: object({ label: string().required(), value: string().required() })
    .nullable()
    .when('type', {
      is: ({ value }: { value: HubType }) => expectClusterField(value),
      then: (schema) => schema.required(),
    }),
})

type HubFormData = InferType<typeof saveHubSchema>

type SelectValue = { label: string; value: string }

export type HubFormDataBeforeValidation = Omit<HubFormData, 'type'> & {
  type: null | SelectValue
}

export default HubFormData
