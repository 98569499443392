import styled from 'styled-components'

import { StyledInput } from 'components/input/style'

// Custom Filters

export const StyledTagCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    flex-basis: 33%;
  }
`

export const StyledBookingFiltersCheckPictos = styled.div`
  justify-content: space-between;
`

export const StyledBookingFiltersTag = styled.div`
  margin: 5px 10px 5px 0px;
`

export const StyledBookingTagsSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  padding: 10px 30px;

  ${StyledInput} {
    margin: 10px 0;
  }
`
