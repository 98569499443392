import PropTypes from 'prop-types'

export const validateChildrenOfTypes =
  (components, isRequired = true) =>
  (props, propName, componentName) => {
    const types = components.reduce((acc, curr) => {
      acc.push(curr.name)
      return acc
    }, [])
    const { [propName]: propValue } = props

    if (!isRequired && !propValue) {
      return null
    }
    if (!Array.isArray(propValue)) {
      if (types.includes(propValue.type.name)) {
        return null
      }
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Should be of types = ${types.join(
          ', '
        )}.`
      )
    }
    const isValid = propValue?.every((child) => types.includes(child.type.name))
    if (!isValid) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Should be an array of types = ${types.join(
          ', '
        )}.`
      )
    }
    return null
  }

export const anyChildren = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.element),
  PropTypes.element,
  PropTypes.node,
])

export const numberInferiorEqualTo = (max, isRequired) => (props, propName, componentName) => {
  const { [propName]: propValue } = props
  if (!isRequired && !propValue) {
    return null
  }
  if (typeof propValue !== 'number' || propValue > max) {
    return new Error(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Should be a number inferior to ${max}`
    )
  }
  return null
}
