import {
  APP_STAGE_LOCAL,
  APP_STAGE_PROD,
  APP_STAGE_SANDBOX,
  APP_STAGE_STAGING,
} from 'constants/stages'

export const isLocal = process.env.REACT_APP_STAGE === APP_STAGE_LOCAL
export const isProd = process.env.REACT_APP_STAGE === APP_STAGE_PROD
export const isSandbox = process.env.REACT_APP_STAGE === APP_STAGE_SANDBOX
export const isStaging = process.env.REACT_APP_STAGE === APP_STAGE_STAGING
