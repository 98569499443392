import React from 'react'
import ContentLoader from 'react-content-loader'

const BlockTransportationSkeleton = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={200}
    viewBox='0 0 400 200'
    backgroundColor='#f7f7f7'
    foregroundColor='#f0efef'
  >
    <rect x='0' y='-34' rx='0' ry='0' width='70' height='34' />
    <rect x='16' y='22' rx='0' ry='0' width='24' height='23' />
    <rect x='64' y='22' rx='0' ry='0' width='285' height='22' />
    <rect x='66' y='62' rx='0' ry='0' width='94' height='13' />
    <rect x='66' y='83' rx='0' ry='0' width='94' height='13' />
    <rect x='66' y='105' rx='0' ry='0' width='94' height='13' />
    <rect x='66' y='127' rx='0' ry='0' width='94' height='13' />
    <rect x='18' y='159' rx='0' ry='0' width='24' height='23' />
    <rect x='68' y='159' rx='0' ry='0' width='285' height='22' />
  </ContentLoader>
)

BlockTransportationSkeleton.propTypes = {}

BlockTransportationSkeleton.defaultProps = {}

export default BlockTransportationSkeleton
