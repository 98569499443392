import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

import { StyledFullscreenButton, StyledMap, StyledMapWrap } from 'components/map/style'
import { TEST_ID_SHIPMENT_MAP_FULLSCREEN_TOGGLE } from 'tests/e2e/test_ids'

const Map = memo(
  forwardRef(({ toggleFullscreen, className }, ref) => (
    <StyledMapWrap className={className}>
      {toggleFullscreen && (
        <StyledFullscreenButton
          icon='fullscreen'
          onClick={toggleFullscreen}
          testId={TEST_ID_SHIPMENT_MAP_FULLSCREEN_TOGGLE}
        />
      )}
      <StyledMap ref={ref} />
    </StyledMapWrap>
  ))
)

Map.propTypes = {
  toggleFullscreen: PropTypes.func,
  className: PropTypes.string,
}

Map.defaultProps = {
  toggleFullscreen: undefined,
  className: undefined,
}

export default Map
