import React from 'react'
import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import BoxShadow from 'components/box_shadow'
import Placeholder from 'components/placeholder'
import BlockTransportationItem from 'components/block_transportation/block_transportation_item'
import { StyledBlockTransportation } from 'components/block_transportation/style'

type BlockTransportationChildrens = typeof BlockTransportationItem | typeof Placeholder

const BlockTransportation = ({
  className,
  children,
  title,
}: {
  className?: string
  children?: BlockTransportationChildrens
  title: string
}) => (
  <StyledBlockTransportation className={className} as={BoxShadow} title={title}>
    {children}
  </StyledBlockTransportation>
)

BlockTransportation.propTypes = {
  /** className */
  className: PropTypes.string,
  /** children */
  children: validateChildrenOfTypes([BlockTransportationItem, Placeholder]),
  /** title */
  title: BoxShadow.propTypes.title,
}

BlockTransportation.defaultProps = {
  className: undefined,
  children: undefined,
  title: BoxShadow.defaultProps.title,
}

export default BlockTransportation
