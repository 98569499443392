import React, { ChangeEvent, FocusEvent, MouseEvent } from 'react'

import Button from 'components/button'
import Icon, { IconType } from 'components/icon'
import {
  StyledInput,
  StyledInputIcon,
  StyledInputInput,
  StyledInputButton,
  StyledInputLabel,
  StyledInputWrapper,
  StyledInputError,
  StyledInputLabelRequiredIcon,
} from 'components/input/style'

import usePasswordInput from 'components/input/hooks/use_password_input'

// TODO: REFACTOR:
// Refactor button interface, as it is necessary here to pass props one y one.
// Maybe button prop should be explicitly a button?
type Ref = HTMLInputElement

export interface InputProps {
  placeholder?: string
  label?: string
  type?: 'text' | 'email' | 'phone' | 'number' | 'password'
  name?: string
  defaultValue?: string | number | null
  value?: string | number | null
  button?: {
    text?: string
    icon: string
    onClick: (e: MouseEvent<HTMLElement>) => void
    disabled?: boolean
    testId?: string
    variant?: string
  }
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  min?: number | string
  max?: number | string
  icon?: IconType
  error?: string
  required?: boolean
  className?: string
  id?: string
  autoComplete?: string
  showCurrency?: boolean
  step?: number | string
  testId?: string
  disabled?: boolean
}
const Input = React.forwardRef<Ref, InputProps>(
  (
    {
      placeholder,
      label,
      type,
      name,
      defaultValue,
      value,
      button,
      onChange,
      onBlur,
      min,
      max,
      icon,
      error,
      required,
      className,
      id,
      autoComplete,
      showCurrency,
      step,
      testId,
      disabled = false,
    }: InputProps,
    ref
  ) => {
    const { RevealPasswordButton, passwordInputType } = usePasswordInput(type === 'password')

    return (
      <StyledInput
        $hasButton={!!button}
        className={className}
        $hasError={!!error}
        $hasIcon={!!icon}
      >
        {label && (
          <StyledInputLabel htmlFor={id || name}>
            {label} {required && <StyledInputLabelRequiredIcon>*</StyledInputLabelRequiredIcon>}
          </StyledInputLabel>
        )}
        {icon && <StyledInputIcon as={Icon} name={icon} />}
        <StyledInputWrapper>
          {showCurrency && <StyledInputIcon>$</StyledInputIcon>}
          <StyledInputInput
            type={passwordInputType || type}
            placeholder={placeholder}
            name={name}
            required={required}
            id={id || name}
            onChange={onChange}
            ref={ref}
            value={value === null ? '' : value}
            defaultValue={defaultValue === null ? '' : defaultValue}
            onBlur={onBlur}
            min={min}
            max={max}
            autoComplete={autoComplete}
            step={step}
            disabled={disabled}
            data-testid={testId}
          />
          <RevealPasswordButton />
          {button && (
            <StyledInputButton
              as={Button}
              text={button.text}
              icon={button.icon}
              onClick={button.onClick}
              disabled={button.disabled}
              variant={button.variant || 'highlight'}
              testId={button.testId}
            />
          )}
        </StyledInputWrapper>
        {error && (
          <StyledInputError>
            <Icon name='warning' /> {error}
          </StyledInputError>
        )}
      </StyledInput>
    )
  }
)

export { Input as InputStorybook }

export default Input
