import React, { FC } from 'react'

import { StyledLabelRequiredIcon } from 'components/input_datepicker/style'
import {
  StyledFormInsert,
  StyledFormInsertHead,
  StyledFormInsertTitle,
  StyledFormInsertSubtitle,
  StyledFormInsertTrashButton,
} from 'components/form/style'
import IconTooltip from 'components/icon_tooltip'
import Button from 'components/button'

interface FormInsertProps {
  title?: string
  subtitle?: string
  row?: boolean
  testId?: string
  required?: boolean
  tooltip?: string
  onDelete?: () => void
}

const Insert: FC<FormInsertProps> = ({
  title,
  subtitle,
  children,
  row = false,
  testId,
  required = false,
  tooltip,
  onDelete,
}) => (
  <StyledFormInsert $row={row} data-testid={testId}>
    {onDelete && (
      <StyledFormInsertTrashButton>
        <Button variant='smallIcon' icon='trash' onClick={onDelete} />
      </StyledFormInsertTrashButton>
    )}
    <StyledFormInsertHead>
      {title && (
        <StyledFormInsertTitle>
          {title}
          {required && <StyledLabelRequiredIcon>*</StyledLabelRequiredIcon>}
        </StyledFormInsertTitle>
      )}
      {subtitle && (
        <StyledFormInsertSubtitle>
          {subtitle}
          {required && <StyledLabelRequiredIcon>*</StyledLabelRequiredIcon>}
        </StyledFormInsertSubtitle>
      )}
      {tooltip && <IconTooltip content={tooltip} size='nowrap' inline />}
    </StyledFormInsertHead>
    {children}
  </StyledFormInsert>
)

export default Insert
