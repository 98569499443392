import { useSelector } from 'react-redux'

import { MilestoneType } from 'types/milestones'

import { selectAvailableMilestonesByAddress } from 'views/shipment/slice'

const useAvailableMilestonesByAddress = ({
  id,
  addressId,
}: {
  id: string
  addressId: number
}): { milestoneTypes?: MilestoneType[] } => {
  const milestoneTypes = useSelector(selectAvailableMilestonesByAddress({ id, addressId }))
  return { milestoneTypes }
}

export default useAvailableMilestonesByAddress
