import styled from 'styled-components'

import { StyledInput } from 'components/input/style'
import { StyledButton } from 'components/button/style'
import { desktop } from 'styles/utils/breakpoints'

const HEIGHT_SEARCH = '48px'
const HEIGHT_SEARCH_MOBILE = '107px'
const MARGIN_BOTTOM_SEARCH = '18px'

export const StyledModalNetworkContent = styled.div`
  position: relative;
  height: 100%;

  @media ${desktop.down} {
    max-height: 70vh;
  }
`

export const StyledModalNetworkTopBar = styled.div`
  display: flex;
  margin-bottom: ${MARGIN_BOTTOM_SEARCH};

  ${StyledInput} {
    width: 100%;
  }
  ${StyledButton} {
    flex: none;
  }

  @media ${desktop.down} {
    flex-direction: column-reverse;
    align-items: flex-end;
    height: ${HEIGHT_SEARCH_MOBILE};

    ${StyledButton} {
      margin-bottom: 15px;
    }
  }

  @media ${desktop.up} {
    display: flex;
    height: ${HEIGHT_SEARCH};

    ${StyledInput} {
      margin-right: 22px;
    }
  }
`

export const StyledModalNetworkList = styled.div`
  overflow-y: auto;
  max-height: calc(100% - ${HEIGHT_SEARCH_MOBILE} - ${MARGIN_BOTTOM_SEARCH});

  @media ${desktop.up} {
    max-height: calc(100% - ${HEIGHT_SEARCH} - ${MARGIN_BOTTOM_SEARCH});
  }
`

export const StyledLoadMore = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
`
