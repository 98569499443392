import styled, { css } from 'styled-components'

import { buttonSmall, text14Regular, buttonSmallRegular } from 'styles/utils/texts'
import { shadow } from 'styles/utils/shadow'
import { easeQuartOut } from 'styles/utils/ease'
import { borderRadiusSmall, borderRadiusRound } from 'styles/utils/border_radius'
import StyledIcon from 'components/icon/style'

export const StyledSpan = styled.span`
  flex: none;
  ${buttonSmall}
  transition: color 0.4s ${easeQuartOut};

  ${(props) => (props.$textRegular ? buttonSmallRegular : buttonSmall)}
`

export const StyledButtonIcon = styled(StyledIcon)`
  width: 20px;
  flex: none;
  transition: fill 0.4s ${easeQuartOut};
`

export const StyledButton = styled.button`
  border-radius: 12px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  transition: background 0.4s ${easeQuartOut}, border 0.4s ${easeQuartOut};

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
    `}

  ${(props) =>
    props.$hasLabel &&
    css`
      ${StyledIcon} {
        margin-right: 10px;
      }
    `}

  ${(props) =>
    !(props.$hasLabel || props.$variant === 'verySmallIcon') &&
    css`
      width: 44px;
      height: 44px;
    `}


  ${(props) =>
    !props.$rounded &&
    css`
      ${borderRadiusSmall}
    `}

  ${(props) =>
    props.$rounded &&
    !props.$hasLabel &&
    css`
      ${borderRadiusRound}
    `}


  ${(props) =>
    props.$variant === 'default' &&
    css`
      background-color: white;
      border-color: transparent;
      ${shadow}

      ${StyledSpan} {
        color: ${props.theme.primary};
      }
      ${StyledIcon} {
        fill: ${props.theme.primary};
      }

      &:hover,
      &:focus {
        border-color: ${props.theme.primary};
        box-shadow: none;
      }

      &:active {
        background-color: ${props.theme.primary};
        border-color: ${props.theme.primary};

        ${StyledSpan} {
          color: white;
        }
        ${StyledIcon} {
          fill: white;
        }
      }
    `}

  ${(props) =>
    props.$variant === 'highlight' &&
    css`
      background-color: ${props.theme.primary};
      border-color: ${props.theme.primary};

      ${StyledSpan} {
        color: white;
      }
      ${StyledIcon} {
        fill: white;
      }

      &:hover,
      &:focus {
        background-color: ${props.theme.white};
        border-color: ${props.theme.white};
        ${shadow}

        ${StyledSpan} {
          color: ${props.theme.primary};
        }
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }

      &:active {
        background-color: ${props.theme.white};
        border-color: ${props.theme.primary};

        ${StyledSpan} {
          color: ${props.theme.primary};
        }
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }
    `}

    ${(props) =>
    props.$variant === 'clear' &&
    css`
      background-color: ${props.theme.clearGray};
      border-color: ${props.theme.clearGray};

      ${StyledSpan} {
        color: ${props.theme.textLight};
      }
      ${StyledIcon} {
        fill: ${props.theme.textLight};
      }

      &:hover,
      &:focus {
        ${shadow}
      }

      &:active {
        box-shadow: none;
        background-color: ${props.theme.white};
        border-color: ${props.theme.textLight};
      }
    `}

    ${(props) =>
    props.$variant === 'dashed' &&
    css`
      background-color: transparent;
      border: 1px dashed ${props.theme.mediumGray};

      ${StyledSpan} {
        color: ${props.theme.textLight};
      }
      ${StyledIcon} {
        fill: ${props.theme.mediumGray};
      }

      &:hover {
        border: 1px dashed ${props.theme.primary};

        ${StyledSpan} {
          color: ${props.theme.primary};
        }
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }
    `}

    ${(props) =>
    props.$variant === 'transparent' &&
    css`
      ${StyledSpan} {
        color: ${props.theme.textLight};
      }
      ${StyledIcon} {
        fill: ${props.theme.primary};
      }

      &:hover,
      &:focus,
      &:active {
        ${StyledSpan} {
          color: ${props.theme.primary};
        }
      }
    `}

    ${(props) =>
    props.$variant === 'outline' &&
    css`
      border-color: ${props.theme.primary};
      ${StyledSpan} {
        color: ${props.theme.primary};
      }
      ${StyledIcon} {
        fill: ${props.theme.primary};
      }

      &:hover,
      &:focus,
      &:active {
        background-color: ${props.theme.primary};
        ${StyledSpan} {
          color: white;
        }
        ${StyledIcon} {
          fill: white;
        }
      }
    `}

    ${(props) =>
    props.$variant === 'redOutline' &&
    css`
        border-color: ${props.theme.error};
        ${StyledSpan} {
          color: ${props.theme.error};
        }
        ${StyledIcon} {
          fill: ${props.theme.error};
        }

        &:hover,
        &:focus,
        &:active {
          background-color: ${props.theme.error};
          ${StyledSpan} {
            color: white};
          }
        }
      `}


    ${(props) =>
    props.$variant === 'icon' &&
    css`
      padding: 0;

      ${StyledIcon} {
        transition: fill 0.3s;
        width: 29px;
        height: 29px;
      }

      &:hover,
      &:active {
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }
    `}

    ${(props) =>
    props.$variant === 'smallIcon' &&
    css`
      padding: 0;

      ${StyledIcon} {
        transition: fill 0.3s;
        width: 23px;
        height: 23px;
      }

      &:hover,
      &:active {
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }
    `}

  ${(props) =>
    props.$variant === 'verySmallIcon' &&
    css`
      padding: 0;

      ${StyledIcon} {
        transition: fill 0.3s;
        width: 16px;
        height: 16px;
      }

      &:hover,
      &:active {
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }
    `}
  ${(props) =>
    props.$variant === 'minimalIcon' &&
    css`
      padding: 0;
      ${StyledIcon} {
        transition: fill 0.3s;
        width: 12px;
        height: 12px;
      }

      &:hover,
      &:active {
        ${StyledIcon} {
          fill: ${props.theme.primary};
        }
      }
    `}

      ${(props) =>
    props.$variant === 'link' &&
    css`
      ${StyledSpan} {
        color: ${({ theme }) => theme.textLight};
        ${text14Regular}
        display: inline-block;
        transition: color 0.5s ${easeQuartOut};

        &:hover {
          color: ${({ theme }) => theme.textDark};
        }
      }
      padding: 0;
      border: 0;
    `}

    ${(props) =>
    (props.$variant === 'icon' ||
      props.$variant === 'smallIcon' ||
      props.$variant === 'verySmallIcon' ||
      props.$variant === 'minimalIcon') &&
    css`
      ${StyledIcon} {
        fill: ${props.$iconHighlighted ? props.theme.primary : props.theme.mediumGray};
      }
    `}


  ${(props) =>
    props.$padded &&
    css`
      padding-left: 55px;
      padding-right: 55px;
    `}
`

export const StyledButtonIconTooltip = styled(StyledButton)`
  width: 20px;
  height: 20px;
  svg {
    fill: ${({ theme }) => theme.textDark};
  }
`
