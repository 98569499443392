import styled from 'styled-components'

import { PageContentMaxWidth } from 'components/page/style'

import { gradientLightOpacityReverse } from 'styles/utils/gradients'

export const StyledBookingEdit = styled.div`
  display: flex;
  justify-content: center;
  ${gradientLightOpacityReverse};
  padding: 0 60px 0;
  height: 100%;
`

export const StyledBookingEditMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: ${PageContentMaxWidth};
`
