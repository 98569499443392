import styled from 'styled-components'

import { buttonSmall, listItem13 } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'
import { easeQuartOut } from 'styles/utils/ease'
import { mobile } from 'styles/utils/breakpoints'

export const StyledSearchList = styled.div``

export const StyledSearchListTitle = styled.div`
  background: ${({ theme }) => theme.primary10};
  color: ${({ theme }) => theme.primary};
  ${buttonSmall};
  padding: 5px 10px;
  display: flex;
  align-items: center;

  ${StyledIcon} {
    fill: ${({ theme }) => theme.primary};
    width: 17px;
    height: 17px;
    margin-right: 12px;
  }
`

export const StyledSearchListItems = styled.div`
  padding: 0 30px;

  @media ${mobile.down} {
    padding: 0;
  }
`

export const StyledSearchListMore = styled.button`
  width: 100%;
  text-align: center;
  padding: 14px;
  color: ${({ theme }) => theme.textLight};
  ${listItem13};
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`
