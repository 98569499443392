import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { SHIPMENT_VIEW_LARGE } from 'constants/shipments'

import Page from 'components/page'
import Button from 'components/button'
import ShipmentFilters from 'components/shipment_filters'

import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'
import ShipmentList from 'views/shipments/components/list'
import ShipmentBar from 'views/shipments/components/bar'
import {
  SORT_BY_DEPARTURE_AT,
  SORT_BY_ARRIVED_AT,
  SORT_BY_CREATED_AT,
  SORT_BY_ETA_DIFFERENCE,
  fetchShipments,
  selectActiveFilters,
  selectDirection,
  selectFilterByFavorites,
  selectSortBy,
} from 'views/shipments/slice'
import {
  StyledShipments,
  StyledShipmentsBar,
  StyledShipmentsList,
  StyledShipmentsMainHeader,
} from 'views/shipments/style'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useShipments from 'views/shipments/hooks/use_shipments'

import usePagination from 'services/api/hooks/use_pagination'
import S from 'components/page/style'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { INDEX_SOURCE } from 'constants/shipment_source'

const PER = 20

const Shipments = React.memo(() => {
  const [view, setView] = useState(SHIPMENT_VIEW_LARGE)
  const { page, next, reset } = usePagination()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [shipments, status, totalCount] = useShipments(INDEX_SOURCE)
  const activeFilters = useSelector((state) => selectActiveFilters(state))
  const sortBy = useSelector((state) => selectSortBy(state))
  const direction = useSelector((state) => selectDirection(state))
  const filterByFavorites = useSelector((state) => selectFilterByFavorites(state))

  const user = useCurrentUser()
  const etaDiff = user.profile.settings.etaDifference

  useEffect(() => {
    reset()
  }, [activeFilters, sortBy, etaDiff, direction, reset])

  const sortByMap = {
    [SORT_BY_CREATED_AT]: 'created_at',
    [SORT_BY_ARRIVED_AT]: `transportation_date_${etaDiff}_eta`,
    [SORT_BY_DEPARTURE_AT]: `transportation_date_pol_etd`,
    [SORT_BY_ETA_DIFFERENCE]: `eta_diff_${etaDiff}`,
  }
  const queryParamSortBy = sortByMap[sortBy]

  const extractUserVisibilityFilter = (shipmentFilters) => {
    if (!shipmentFilters?.userIdVisibility?.value) {
      return { filters: shipmentFilters }
    }

    const { userIdVisibility, ...otherFilters } = shipmentFilters
    return { filters: otherFilters, user_id_visibility: userIdVisibility.value }
  }

  useEffect(() => {
    const params = extractUserVisibilityFilter(activeFilters)

    dispatch(
      fetchShipments({
        ...params,
        sortBy: queryParamSortBy,
        direction,
        page,
        per: PER,
        source: INDEX_SOURCE,
        favoriteUserId: filterByFavorites ? user.id : null,
      })
    )
  }, [activeFilters, dispatch, queryParamSortBy, direction, page, filterByFavorites, user.id])

  const shipmentViewContextValue = useMemo(() => ({ view, setView }), [view, setView])

  return (
    <S.ScrollbarPage sidebar={<ShipmentFilters />} as={Page}>
      <StyledShipments>
        <ShipmentViewContext.Provider value={shipmentViewContextValue}>
          <StyledShipmentsMainHeader>
            <S.PageTitle>{t('shipments.indexTitle')}</S.PageTitle>

            {/* List options */}
            <StyledShipmentsBar>
              <ShipmentBar totalCount={totalCount} />
            </StyledShipmentsBar>
          </StyledShipmentsMainHeader>

          {/* List */}
          <StyledShipmentsList>
            <ShipmentList source={INDEX_SOURCE} />
            {status.fulfilled && shipments.length > 0 && shipments.length < totalCount && (
              <Button text={t('actions.loadMore')} onClick={() => next()} />
            )}
          </StyledShipmentsList>
        </ShipmentViewContext.Provider>
      </StyledShipments>
    </S.ScrollbarPage>
  )
})

Shipments.propTypes = {}

Shipments.defaultProps = {}

export default Shipments
