import React, { useState } from 'react'

import Button from 'components/button'
import { StyledInputPasswordButton } from 'components/input/style'

const usePasswordInput = (isPassword) => {
  const [passwordShow, setPasswordShow] = useState(false)

  const onClickPasswordInput = (e) => {
    e.preventDefault()
    setPasswordShow(!passwordShow)
  }

  const RevealPasswordButton = () =>
    isPassword && (
      <StyledInputPasswordButton
        variant='transparent'
        as={Button}
        icon={passwordShow ? 'eye' : 'eye_off'}
        onClick={onClickPasswordInput}
      />
    )

  const passwordInputType = isPassword && (passwordShow ? 'text' : 'password')

  return { RevealPasswordButton, passwordInputType }
}

export default usePasswordInput
