import React, { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import { TRANSPORT_MODES } from 'constants/bookings'

import { compactArray, isPresent } from 'services/helpers/values'

import {
  TEST_ID_BOOKING_SHOW_MASTER_BL,
  TEST_ID_BOOKING_SHOW_TRANSPORT_DETAILS_SECTION,
} from 'tests/e2e/test_ids'

const InformationTransportDetails: FC = () => {
  const { token } = useContext(BookingContext)
  const { t } = useTranslation()
  const [booking] = useBooking(token)
  const {
    carrier,
    transportType,
    flightNumbers,
    bookingNumber,
    masterBl,
    vessels,
    voyageNumbers,
    shipmentAttributes,
  } = booking!

  return (
    <StyledFormEditGroup data-testid={TEST_ID_BOOKING_SHOW_TRANSPORT_DETAILS_SECTION}>
      <StyledFormEditGroupTitle>{t('bookings.transportDetails.title')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.transportType')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={t(`bookings.transportMode.${transportType}`)} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>
            {t('bookings.transportDetails.carrier')}
          </StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback
              value={
                carrier?.id === -1
                  ? `${t('bookings.select.options.unknownCarrier')} (${carrier?.name})`
                  : carrier?.name
              }
            />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        {transportType === TRANSPORT_MODES.AIR && (
          <>
            <StyledFormEditGroupItem>
              <StyledFormEditGroupLabel>
                {t('bookings.transportDetails.flightNumbers')}
              </StyledFormEditGroupLabel>
              <StyledFormEditGroupValue>
                <ValueWithFallback value={flightNumbers.join(', ')} />
              </StyledFormEditGroupValue>
            </StyledFormEditGroupItem>
            {shipmentAttributes.map((sa, idx) => (
              <StyledFormEditGroupItem>
                <StyledFormEditGroupLabel>
                  {t('shipments.shipmentTitle')} {idx + 1}
                </StyledFormEditGroupLabel>
                <StyledFormEditGroupValue>
                  {compactArray([
                    sa.masterAwb,
                    sa.shipmentReference,
                    isPresent(sa.weight?.value) ? `${sa?.weight?.value} kg` : null,
                  ]).join(' - ')}
                </StyledFormEditGroupValue>
              </StyledFormEditGroupItem>
            ))}
          </>
        )}

        {transportType === TRANSPORT_MODES.SEA && (
          <>
            <StyledFormEditGroupItem>
              <StyledFormEditGroupLabel>
                {t('bookings.transportDetails.bookingNumber')}
              </StyledFormEditGroupLabel>
              <StyledFormEditGroupValue>
                <ValueWithFallback value={bookingNumber} />
              </StyledFormEditGroupValue>
            </StyledFormEditGroupItem>
            <StyledFormEditGroupItem>
              <StyledFormEditGroupLabel>
                {t('bookings.transportDetails.blNumber')}
              </StyledFormEditGroupLabel>
              <StyledFormEditGroupValue>
                <ValueWithFallback value={masterBl} testId={TEST_ID_BOOKING_SHOW_MASTER_BL} />
              </StyledFormEditGroupValue>
            </StyledFormEditGroupItem>
            <StyledFormEditGroupItem>
              <StyledFormEditGroupLabel>
                {t('bookings.transportDetails.vessels')}
              </StyledFormEditGroupLabel>
              <StyledFormEditGroupValue>
                <ValueWithFallback
                  value={vessels
                    .map((vessel) =>
                      isPresent(vessel.id)
                        ? vessel.name
                        : `${t('bookings.select.options.unknownVessel')} (${vessel.name})`
                    )
                    .join(', ')}
                />
              </StyledFormEditGroupValue>
            </StyledFormEditGroupItem>
            <StyledFormEditGroupItem>
              <StyledFormEditGroupLabel>
                {t('bookings.transportDetails.voyageNumbers')}
              </StyledFormEditGroupLabel>
              <StyledFormEditGroupValue>
                <ValueWithFallback value={voyageNumbers.join(', ')} />
              </StyledFormEditGroupValue>
            </StyledFormEditGroupItem>
            {shipmentAttributes.map((sa, idx) => (
              <StyledFormEditGroupItem>
                <StyledFormEditGroupLabel>
                  {t('shipments.shipmentTitle')} {idx + 1}
                </StyledFormEditGroupLabel>
                <StyledFormEditGroupValue>
                  {compactArray([
                    sa.containerNumber,
                    sa.shipmentReference,
                    isPresent(sa.weight?.value) ? `${sa?.weight?.value} kg` : null,
                    sa.containerType,
                  ]).join(' - ')}
                </StyledFormEditGroupValue>
              </StyledFormEditGroupItem>
            ))}
          </>
        )}
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationTransportDetails
