import styled from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { desktop, tablet } from 'styles/utils/breakpoints'
import StyledIcon from 'components/icon/style'

export const StyledWidgetEdition = styled.div`
  position: absolute;
  display: flex;
  right: 6px;
  top: 6px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.15s, transform 0.15s;
  transform: translate3d(5px, 0, 0);

  @media ${desktop.down} {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`

export const StyledWidgetArrow = styled(StyledIcon)`
  fill: ${({ theme }) => theme.mediumGray};
  width: 25px;
  height: 25px;
  align-self: center;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.15s;
`

export const StyledWidgetWrapper = styled.div`
  overflow: hidden;
  position: relative;
  ${borderRadiusMedium};
  margin-bottom: 10px;
  cursor: pointer;

  @media ${desktop.down} {
    margin-right: 20px;
    min-width: 280px;
    margin-bottom: 0;
  }
  @media ${tablet.down} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  &:hover {
    ${StyledWidgetEdition} {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    ${StyledWidgetArrow} {
      transform: translate3d(5px, 0, 0);
    }
  }
`

export const StyledWidgetEdit = styled.div`
  width: 18px;
  height: 16px;

  ${StyledIcon} {
    fill: ${({ theme }) => theme.mediumGray};
  }
`
export const StyledWidgetDelete = styled.div`
  margin-right: 5px;
  width: 18px;
  height: 16px;

  ${StyledIcon} {
    fill: ${({ theme }) => theme.mediumGray};
  }
`
