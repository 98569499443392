import styled, { css } from 'styled-components'

import { text15Regular, textSmallDescription } from 'styles/utils/texts'

import { AlertListItemVariant } from 'components/alert_list_item/type'

export const StyledItemDate = styled.span`
  ${textSmallDescription};
  color: ${({ theme }) => theme.textLight};
  min-width: 95px;
  margin-right: 10px;
`

export const StyledItemTitle = styled.span`
  ${text15Regular};
  color: ${({ theme }) => theme.textDark};
`

export const StyledItemText = styled.p`
  ${text15Regular};
  color: ${({ theme }) => theme.textLight};
`

export const StyledItem = styled.li<{ $variant: AlertListItemVariant }>`
  display: flex;
  align-items: flex-start;
  padding: 25px 30px 25px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.softGrayLight};
  margin-bottom: 1px;

  ${(props) =>
    props.$variant === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.warn10};
    `}
`
