import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/modal'
import Button from 'components/button'

import useModal from 'components/modal/hooks/use_modal'

export interface ValidateTemplateModalProps {
  onValidate: () => void
  onClose: () => void
}

const ValidateTemplateModal: FC<ValidateTemplateModalProps> = ({
  onValidate,
  onClose,
}: ValidateTemplateModalProps) => {
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingTemplateValidateApply')

  return (
    <Modal position='center' modalName='bookingTemplateValidateApply' onClose={onClose}>
      <Modal.Header>{t('bookings.templates.warningTitle')}</Modal.Header>
      <Modal.Content>{t('bookings.templates.warning')}</Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} rounded variant='clear' onClick={() => setOpen(false)} />
        <Button
          text={t('bookings.templates.apply')}
          rounded
          variant='highlight'
          onClick={() => {
            onValidate?.()
            setOpen(false)
          }}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ValidateTemplateModal)
