import { fetchFilters } from 'views/shipments/slice'

// TODO: REFACTOR:
// Naming:
// @queryParams - The extra query params sent to fetchFilters request. Should be an object.
const useShipmentSelect = ({ queryParams } = { queryParams: {} }) => {
  const removeActiveFilter = (name) => {
    const anySuffixRegex = '_[a-zA-Z]+'
    const nameRegex = new RegExp(`^${name}${anySuffixRegex}$`)
    const result = Object.keys(queryParams)
      .filter((k) => !nameRegex.test(k))
      .reduce((acc, key) => {
        acc[key] = queryParams[key]
        return acc
      }, {})
    return result
  }

  const fetchOptions =
    (name) =>
    ({ value }) => {
      const filterName = `${name}_match`
      return fetchFilters({
        value,
        name: filterName,
        filters: removeActiveFilter(name),
      })
    }
  const fetchAllOptions = (name) => () => {
    const filterName = `${name}_all`
    return fetchFilters({
      value: null,
      name: filterName,
      filters: removeActiveFilter(name),
    })
  }
  const fetchedOptionsFormat = (options) => options.map((name) => ({ value: name, label: name }))
  const toIndexedSelectValue = ({ value }) => value

  return {
    fetchOptions,
    fetchAllOptions,
    toIndexedSelectValue,
    fetchedOptionsFormat,
  }
}

export default useShipmentSelect
