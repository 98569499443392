import { toCamelCaseKeys } from 'services/helpers/values'
import { CONTENT_TYPE_JSON } from 'services/api/constants'

const toCamelCaseResponseMiddleware = (response) => {
  if (response?.data && response.headers['content-type'].includes(CONTENT_TYPE_JSON)) {
    // eslint-disable-next-line no-param-reassign
    response.data = toCamelCaseKeys(response.data)
  }
  return response
}
export default toCamelCaseResponseMiddleware
