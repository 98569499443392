import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Placeholder from 'components/placeholder'
import ShipmentListComponent, { ShipmentListSkeleton } from 'components/shipment_list'
import ShipmentListItem from 'components/shipment_list_item'
import NotifMessage from 'components/notif_message'

import ErrorNotification from 'views/errors/error_notification'
import useShipments from 'views/shipments/hooks/use_shipments'
import { getTestIdForShipmentItem } from 'tests/e2e/test_ids'

const ShipmentList = ({ source }) => {
  const [shipments, status] = useShipments(source)

  const { t } = useTranslation()
  return (
    <>
      {shipments.length > 0 && (
        <ShipmentListComponent>
          {shipments.map((s, index) => (
            <ShipmentListItem
              shipment={s}
              key={`shipment-${s.id}`}
              testId={getTestIdForShipmentItem(index)}
            />
          ))}
        </ShipmentListComponent>
      )}
      <Placeholder ready={status.ready} customPlaceholder={<ShipmentListSkeleton count={8} />}>
        {status.fulfilled && shipments.length === 0 && (
          <NotifMessage
            type='info'
            title={t('shipments.list.noResults.title')}
            text={t('shipments.list.noResults.text')}
          />
        )}
        {status.rejected && <ErrorNotification />}
      </Placeholder>
    </>
  )
}

ShipmentList.propTypes = {
  source: PropTypes.string,
}
ShipmentList.defaultProps = {
  source: undefined,
}

export default ShipmentList
