import React, { ReactNode } from 'react'
import ReactDOM from 'react-dom'

import StyledAboveModal from 'components/above_modal/style'

const AboveModal = ({ children }: { children: ReactNode }) =>
  ReactDOM.createPortal(
    <StyledAboveModal>{children}</StyledAboveModal>,

    document.getElementById('above-modal-root')!
  )

export default AboveModal
