import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { StyledModalExchangeContent } from 'views/booking/components/show/style'
import { anyChildren } from 'services/helpers/prop_types'
import { isPresent } from 'services/helpers/values'

const ActionModalContent = ({ children, actionKey, role }) => {
  const { t } = useTranslation()
  return (
    <StyledModalExchangeContent>
      {t(`bookings.exchangeModal.${actionKey}.content`, { ...(isPresent(role) ? { role } : {}) })}
      {children}
    </StyledModalExchangeContent>
  )
}

ActionModalContent.propTypes = {
  /** actionKey */
  actionKey: PropTypes.string,
  children: anyChildren,
  role: PropTypes.string,
}

ActionModalContent.defaultProps = {
  actionKey: undefined,
  children: undefined,
  role: undefined,
}

export default ActionModalContent
