import { createContext } from 'react'

import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'

import type { Proposal } from 'views/booking/slices/types'

interface BookingContextValues {
  token: string
  proposal?: Proposal
  exchangeActionsParams?: {
    [BOOKING_EXCHANGE_ACTION_KEYS.VALIDATE_PROPOSAL]: string
    [BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING]: string
    [BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING]: string
    [BOOKING_EXCHANGE_ACTION_KEYS.CANCEL_BOOKING]: string
    [BOOKING_EXCHANGE_ACTION_KEYS.DECLINE_BOOKING]: string
  }
}

const stubbedToken = null as unknown as string
const BookingContext = createContext<BookingContextValues>({
  // token is always set when using this context
  token: stubbedToken,
  proposal: {},
  exchangeActionsParams: {
    [BOOKING_EXCHANGE_ACTION_KEYS.VALIDATE_PROPOSAL]: stubbedToken,
    [BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING]: stubbedToken,
    [BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING]: stubbedToken,
    [BOOKING_EXCHANGE_ACTION_KEYS.CANCEL_BOOKING]: stubbedToken,
    [BOOKING_EXCHANGE_ACTION_KEYS.DECLINE_BOOKING]: stubbedToken,
  },
})

export default BookingContext
