import styled, { css } from 'styled-components'

import 'rc-slider/assets/index.css'

const Slider = styled.div`
  width: 100%;
  display: flex;
  height: 20px;
`

const Value = styled.div<{ $position: 'left' | 'right' }>`
  color: ${(props) => props.theme.mediumGray};
  ${(props) =>
    props.$position === 'left' &&
    css`
      padding-right: 15px;
    `};

  ${(props) =>
    props.$position === 'right' &&
    css`
      padding-left: 15px;
    `};
`

const S = {
  Slider,
  Value,
}

export default S
