import styled, { css } from 'styled-components'

import { StyledBoxShadow } from 'components/box_shadow/style'
import StyledIcon from 'components/icon/style'

import { h3, text14Regular, textSmallDescription } from 'styles/utils/texts'
import { desktop, tablet } from 'styles/utils/breakpoints'
import { easeQuartOut } from 'styles/utils/ease'

/**
 *
 * Item
 *
 */
export const StyledBlockDocumentsItem = styled.div<{ $deleteTransition: boolean }>`
  padding: 25px 25px 25px 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.softGrayLight};

  ${({ $deleteTransition }) =>
    $deleteTransition &&
    css`
      transition: opacity 0.5s;
      opacity: 0;

      ${StyledBlockDocumentsItemContentWrapper} {
        transition: transform 0.5s ${easeQuartOut};
        transform: translateX(10px);
      }
    `}
`

// Content wrapper
export const StyledBlockDocumentsItemContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${tablet.up} {
    flex-direction: row;
    flex: 0 0 calc(100% - 88px);
  }
`

// Date
export const StyledBlockDocumentsItemDateWrapper = styled.div`
  @media ${tablet.up} {
    flex: 0 0 37%;
  }

  @media ${desktop.up} {
    flex: 0 0 25%;
  }
`

export const StyledBlockDocumentsItemDate = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textLight};
`

// Type + Name Wrapper
export const StyledBlockDocumentsItemTypeNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${desktop.up} {
    flex: 0 0 67%;
    flex-direction: row;
  }
`

// Type
export const StyledBlockDocumentsItemTypeWrapper = styled.div`
  flex: 0 0 46%;
`

export const StyledBlockDocumentsItemType = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textDark};
`

// Name
export const StyledBlockDocumentsItemNameWrapper = styled.div`
  word-break: break-word;
  flex: 0 0 54%;
`

export const StyledBlockDocumentsItemName = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textLight};
`

// Actions
export const StyledBlockDocumentsItemActions = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
`

export const StyledBlockDocumentsItemTrashIcon = styled(StyledIcon)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
`

export const StyledBlockDocumentsItemDownloadIcon = styled(StyledIcon)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
  margin-left: 20px;
`

/**
 *
 * Main
 *
 */
export const StyledBlockDocuments = styled(StyledBoxShadow)`
  ${StyledBlockDocumentsItem}:first-child {
    border-top: 1px solid ${(props) => props.theme.softGrayLight};
  }
`

// Content
export const StyledBlockDocumentsContent = styled.div`
  padding: 0 21px 40px 27px;

  @media ${desktop.down} {
    margin-top: 15px;
  }
`

// Top
export const StyledBlockDocumentsTop = styled.div`
  padding: 15px 15px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledBlockDocumentsTopTitle = styled.span`
  ${h3};
  color: ${(props) => props.theme.textDark};
`

export const StyledBlockDocumentsTopButton = styled.div`
  flex-shrink: 0;
  margin-left: 15px;
`

// Heading
export const StyledBlockDocumentsHeading = styled.div`
  display: flex;
  padding: 21px 113px 21px 10px;
`

export const StyledBlockDocumentsHeadingDateWrapper = styled.div`
  flex: 0 0 25%;
`

export const StyledBlockDocumentsHeadingTypeWrapper = styled.div`
  flex: 0 0 31%;
`

export const StyledBlockDocumentsHeadingNameWrapper = styled.div``

export const StyledBlockDocumentsHeadingText = styled.span`
  ${textSmallDescription};
  color: ${(props) => props.theme.textLight};
`
