import { createAsyncThunk } from '@reduxjs/toolkit'

import { Capacity } from 'types/companies'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import { isPresent } from 'services/helpers/values'
import useUrlParams from 'services/api/hooks/use_url_params'

import { Tvalue } from 'components/select'

import { StepType } from 'constants/steps'

import { SearchCompanyProps, SearchPartyProps } from 'views/select_options/types'

import type { TSearchType } from 'components/search_select'

export interface ISearch<T> {
  search: Tvalue
  type?: TSearchType
  stepType?: StepType
  withTooltip?: boolean
  extraActionParams?: T
}

export interface ISelectOption {
  value: Tvalue
  label: string
}

const filterNaParty = ({ value }: ISelectOption) => isPresent(value)

export const fetchCountryOptions = createAsyncThunk(
  'selectOptions/fetchCountryOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const url = useUrlParams('/selects/countries', { search })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((countries: ISelectOption[]) => countries.filter(({ value }) => isPresent(value)))
      .catch(onError(thunkAPI))
  }
)

export const fetchForwarderOptions = createAsyncThunk(
  'selectOptions/fetchForwarderOptions',
  async ({ search, extraActionParams }: ISearch<SearchPartyProps>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/forwarders', { search, ...extraActionParams, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((forwarders: ISelectOption[]) => forwarders.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchShipperOptions = createAsyncThunk(
  'selectOptions/fetchShipperOptions',
  async ({ search, withTooltip, extraActionParams }: ISearch<SearchPartyProps>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/shippers', {
      search,
      withTooltip,
      ...extraActionParams,
      ...teams,
    })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((shippers: ISelectOption[]) => shippers.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchConsignorOptions = createAsyncThunk(
  'selectOptions/fetchConsignorOptions',
  async ({ search, withTooltip, extraActionParams }: ISearch<SearchPartyProps>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/consignors', {
      search,
      withTooltip,
      ...extraActionParams,
      ...teams,
    })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((consignors: ISelectOption[]) => consignors.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchConsigneeOptions = createAsyncThunk(
  'selectOptions/fetchConsigneeOptions',
  async ({ search, withTooltip, extraActionParams }: ISearch<SearchPartyProps>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/consignees', {
      search,
      withTooltip,
      ...extraActionParams,
      ...teams,
    })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((consignees: ISelectOption[]) => consignees.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchLocationOptions = createAsyncThunk(
  'selectOptions/fetchLocationOptions',
  async ({ search, type, stepType, withTooltip }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/locations', {
      search,
      type,
      stepType,
      withTooltip,
      ...teams,
    })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchCompanyAddressOptions = createAsyncThunk(
  'selectOptions/fetchCompanyAddressOptions',
  async ({ params, capacities }: { params: ISearch<any>; capacities: Capacity[] }, thunkAPI) => {
    const { search, withTooltip } = params
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/company_addresses', {
      capacities,
      search,
      withTooltip,
      ...teams,
    })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchIncotermOptions = createAsyncThunk(
  'selectOptions/fetchIncotermOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const url = useUrlParams('/selects/incoterms', { search })
    const removeNaIncoterm = ({ value }: ISelectOption) => value !== 'NA'
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((incoterms: ISelectOption[]) => incoterms.filter(removeNaIncoterm))
      .catch(onError(thunkAPI))
  }
)

export const fetchShippingLinesOptions = createAsyncThunk(
  'selectOptions/fetchShippingLinesOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/shipping_lines', { search, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((shippingLines: ISelectOption[]) => shippingLines.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchAirlinesOptions = createAsyncThunk(
  'selectOptions/fetchAirlinesOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/airlines', { search, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((airlines: ISelectOption[]) => airlines.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchVesselsOptions = createAsyncThunk(
  'selectOptions/fetchVesselsOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/vessels', { search, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((vessels: ISelectOption[]) => vessels.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchUsersOptions = createAsyncThunk(
  'selectOptions/fetchUsersOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/users', { search, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .then((users: ISelectOption[]) => users.filter(filterNaParty))
      .catch(onError(thunkAPI))
  }
)

export const fetchOrganizationOptions = createAsyncThunk(
  'selectOptions/fetchOrganizationOptions',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/organizations', { search, ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchCompanyOptions = createAsyncThunk(
  'selectOptions/fetchCompanyOptions',
  async ({ search, extraActionParams }: ISearch<SearchCompanyProps>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/companies', {
      search,
      ...extraActionParams,
      ...teams,
    })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchMainRoles = createAsyncThunk(
  'selectOptions/fetchMainRoles',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/roles', { search: search ?? '', ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export const fetchOptionalRoles = createAsyncThunk(
  'selectOptions/fetchOptionalRoles',
  async ({ search }: ISearch<any>, thunkAPI) => {
    const teams = useThunkTeamsParam(thunkAPI, { legacyNaming: true })
    const url = useUrlParams('/selects/optional_roles', { search: search ?? '', ...teams })
    return InternalClient.get(url)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)
