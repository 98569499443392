import { useMemo } from 'react'

import useBooking from 'views/booking/hooks/use_booking'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import useBookingStatus from 'views/booking/hooks/use_booking_status'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import {
  WITH_BOOKING_CANCEL_ON_CONFIRMED,
  WITH_BOOKING_EDIT_ON_CONFIRMED,
  WITH_BOOKING_RATE_CONFIRMATION,
} from 'constants/organization_features'
import useCurrentOrganization from 'views/iam/hooks/use_current_organization'

interface BookingWorkflow {
  isForwarderActionTurn: boolean
  isShipperActionTurn: boolean
  isUserActionTurn: boolean
  noActionRequired: boolean
  canSeeRate: boolean
  canEditBooking: boolean
  canCancelBooking: boolean
  canAcceptBooking: boolean
  canConfirmBooking: boolean
  canValidateProposal: boolean
  canGoToShipment: boolean
  canDeclineBooking: boolean
}

const useBookingWorkflow = (id: string): BookingWorkflow => {
  const [booking] = useBooking(id)

  const { isShipper, isForwarder } = useBookingRole()

  const { features } = useOrganizationCan()
  const { name: userOrganizationName } = useCurrentOrganization()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { status, organizationName: bookingOrganizationName, legacyBooking } = booking

  const {
    isStatusAReviewStatus,
    isStatusProposalSent,
    isStatusCompleted,
    isStatusAccepted,
    isStatusNoActionRequired,
    isStatusForwarderActionRequired,
    isStatusShipperActionRequired,
    doesStatusAllowEditOrCancel,
    doesStatusAllowDecline,
    isStatusConfirmed,
  } = useBookingStatus(status)

  const isForwarderActionTurn = useMemo(
    () => isStatusForwarderActionRequired,
    [isStatusForwarderActionRequired]
  )
  const isShipperActionTurn = useMemo(
    () => isStatusShipperActionRequired,
    [isStatusShipperActionRequired]
  )

  const isUserActionTurn =
    (isForwarder && isForwarderActionTurn) || (isShipper && isShipperActionTurn)

  const noActionRequired = useMemo(() => isStatusNoActionRequired, [isStatusNoActionRequired])

  const canSeeRate = useMemo(() => features(WITH_BOOKING_RATE_CONFIRMATION), [features])

  const canEditBooking = useMemo(
    () =>
      (doesStatusAllowEditOrCancel ||
        (isStatusConfirmed && features(WITH_BOOKING_EDIT_ON_CONFIRMED))) &&
      bookingOrganizationName === userOrganizationName,
    [
      doesStatusAllowEditOrCancel,
      isStatusConfirmed,
      features,
      bookingOrganizationName,
      userOrganizationName,
    ]
  )

  const canCancelBooking = useMemo(
    () =>
      isShipper &&
      (doesStatusAllowEditOrCancel ||
        (isStatusConfirmed && features(WITH_BOOKING_CANCEL_ON_CONFIRMED))),
    [isShipper, doesStatusAllowEditOrCancel, isStatusConfirmed, features]
  )

  const canDeclineBooking = useMemo(
    () => isForwarder && doesStatusAllowDecline && !legacyBooking,
    [isForwarder, doesStatusAllowDecline, legacyBooking]
  )

  const canValidateProposal = useMemo(
    () => isShipper && isStatusProposalSent,
    [isShipper, isStatusProposalSent]
  )

  const canAcceptBooking = useMemo(
    () => isForwarder && isStatusAReviewStatus,
    [isForwarder, isStatusAReviewStatus]
  )

  const canConfirmBooking = useMemo(
    () => isForwarder && isStatusAccepted,
    [isForwarder, isStatusAccepted]
  )

  const canGoToShipment = useMemo(() => isStatusCompleted, [isStatusCompleted])

  return {
    isForwarderActionTurn,
    isShipperActionTurn,
    isUserActionTurn,
    noActionRequired,
    canSeeRate,
    canEditBooking,
    canCancelBooking,
    canAcceptBooking,
    canConfirmBooking,
    canValidateProposal,
    canGoToShipment,
    canDeclineBooking,
  }
}

export default useBookingWorkflow
