import styled, { css } from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { tablet, desktopLarge } from 'styles/utils/breakpoints'

import { PageContentMaxWidth } from 'components/page/style'

const StyledShipmentList = styled.div<{ $condensed?: boolean }>`
  position: relative;
  z-index: 1;
  ${(props) =>
    props.$condensed &&
    css`
      background: ${props.theme.white};
      padding: 10px 15px;
      ${borderRadiusMedium};

      @media ${tablet.up} {
        padding: 10px 18px;
      }

      @media ${desktopLarge.up} {
        max-width: ${PageContentMaxWidth};
      }

      margin-bottom: 30px;
    `}
`

export default StyledShipmentList
