import { ShipmentPath } from 'views/shipment/types/shipment'
import { isAValidLatitude, isAValidLongitude } from 'services/helpers/values'

export enum PathValidationErrors {
  INVALID_PATH_FORMAT = 'INVALID_PATH_FORMAT',
  INVALID_COORDINATE_VALUE = 'INVALID_COORDINATE_VALUE',
}

const checkFormat = (path: ShipmentPath) =>
  path.every((step) => {
    if (!Array.isArray(step)) {
      return false
    }
    const [lat, long, date] = step
    return isNumber(lat) && isNumber(long) && isAValidDate(date)
  })

const checkCoordinates = (path: ShipmentPath) =>
  path.every((step) => {
    const [lat, long] = step
    return isAValidLatitude(lat) && isAValidLongitude(long)
  })

export type EditPathEndpointParams = {
  latitude: number
  longitude: number
  date: string
}[]

export const transformShipmentPathToEndpointParams = (path: ShipmentPath): EditPathEndpointParams =>
  path.map((shipment) => {
    const [lat, lng, date] = shipment
    return {
      latitude: lat,
      longitude: lng,
      date,
    }
  })

const isNumber = (input: unknown) => {
  try {
    return !Number.isNaN(parseFloat(input as string))
  } catch (error) {
    return false
  }
}

// we convert the date to unix time , if it return NaN this is not a valid js date
export const isAValidDate = (input: string) => {
  const parsedDate = Date.parse(input)
  return !Number.isNaN(parsedDate)
}
const checkPath = (path: ShipmentPath) => {
  if (!Array.isArray(path) || !checkFormat(path)) {
    return PathValidationErrors.INVALID_PATH_FORMAT
  }
  if (!checkCoordinates(path)) {
    return PathValidationErrors.INVALID_COORDINATE_VALUE
  }
  return ''
}

export default checkPath
