import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useModal from 'components/modal/hooks/use_modal'
import ActionModalContent from 'views/booking/components/action_modal_content'
import Modal from 'components/modal'

import { editBooking } from 'views/booking/slices/booking_slice'
import { addNotification } from 'views/notifications/slice'

import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'

import Button from 'components/button'
import useBookingRole from 'views/booking/hooks/use_booking_role'

const ValidateEditModal = ({ token, editedQueryParams, wontTriggerReview }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingValidateEdit')
  const history = useHistory()
  const { isShipper, isForwarder } = useBookingRole()
  const [isSendDisabled, setIsSendDisabled] = useState(false)

  const onCloseModal = () => setOpen(false)

  const modalActionKey =
    wontTriggerReview || isForwarder ? BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING : 'createBooking'

  const onClickSend = () => {
    setIsSendDisabled(true)
    dispatch(editBooking({ token, changes: editedQueryParams }))
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.title`),
            text: t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.successMessage`),
          })
        )
        history.push(`/booking/${token}`)
      })
      .catch((error) => {
        const errorDetails =
          error.message?.errorType === 'validation' && error.message?.details.length > 0
            ? error.message?.details[0].message
            : null
        dispatch(
          addNotification({
            type: 'alert',
            title: t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.title`),
            text:
              errorDetails ||
              t(`bookings.alerts.${BOOKING_EXCHANGE_ACTION_KEYS.EDIT_BOOKING}.errorMessage`),
          })
        )
      })
      .finally(() => {
        setIsSendDisabled(false)
      })
  }

  return (
    <Modal position='center' onClose={onCloseModal} modalName='bookingValidateEdit'>
      <Modal.Header>{t(`bookings.exchangeModal.${modalActionKey}.title`)}</Modal.Header>
      <Modal.Content>
        <ActionModalContent actionKey={modalActionKey} role={isShipper ? 'forwarder' : 'shipper'} />
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} onClick={onCloseModal} rounded variant='clear' />
        <Button
          text={t('actions.send')}
          onClick={onClickSend}
          rounded
          variant='highlight'
          isLoading={isSendDisabled}
        />
      </Modal.Footer>
    </Modal>
  )
}

ValidateEditModal.propTypes = {
  /** editedQueryParams */
  // eslint-disable-next-line
  editedQueryParams: PropTypes.any,
  token: PropTypes.string,
  wontTriggerReview: PropTypes.bool,
}

ValidateEditModal.defaultProps = {
  editedQueryParams: undefined,
  token: undefined,
  wontTriggerReview: false,
}

export default ValidateEditModal
