import styled, { css } from 'styled-components'

import { borderRadiusXSmall } from 'styles/utils/border_radius'
import { text14Regular, tagSmall } from 'styles/utils/texts'
import { tablet, desktop } from 'styles/utils/breakpoints'
import { easeQuartOut } from 'styles/utils/ease'

/**
 *
 * Infos
 *
 */

export const StyledBookingListItemForwarderLogo = styled.div`
  width: 40px;
  height: 40px;
  @media ${tablet.down} {
    width: 24px;
    height: 24px;
  }
`

export const StyledImage = styled.img`
  max-width: 40px;
  width: auto;
  max-height: 40px;
  height: auto;
  @media ${tablet.down} {
    max-width: 24px;
    max-height: 24px;
  }
`

export const StyledBookingListItemInfoReferences = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  max-width: 200px;
  @media ${desktop.down} {
    max-width: 100px;
  }
  @media ${tablet.down} {
    max-width: 100px;
  }
`

export const StyledBookingListItemReference = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textDark};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.5s ${easeQuartOut};
`

export const StyledBookingListItemClientBookingNumber = styled.span`
  ${text14Regular}
  color: ${(props) => props.theme.textLight};
  margin-top: 8px;
  @media ${tablet.down} {
    margin-top: 0;
  }
`

export const StyledBookingListItemInfo = styled.div`
  display: flex;
  align-items: center;
`

/**
 *
 * Content
 *
 */
export const StyledBookingListItemTransportTypeLogo = styled.div`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.primary};
`

export const StyledBookingListItemDepartureAdress = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textDark};
  margin-left: 5px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.5s ${easeQuartOut};
  @media ${desktop.down} {
    max-width: 100px;
  }
`

export const StyledBookingListItemArrow = styled.div`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.primary};
  margin-left: 5px;
`

export const StyledBookingListItemDate = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textLight};
`

export const StyledBookingListItemContentTravel = styled.div`
  display: flex;
  align-items: center;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledBookingListItemContentDetails = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const StyledBookingListItemCalendarLogo = styled.div`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
  margin-right: 5px;
`

export const StyledBookingListItemSeparator = styled.div`
  width: 2px;
  height: 21px;
  background-color: ${(props) => props.theme.lightGray};
  margin: 0 15px;
`

export const StyledBookingListItemMerchandiseIcon = styled.div`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
`

export const StyledBookingListItemMerchandiseValue = styled.span`
  ${text14Regular};
  color: ${(props) => props.theme.textLight};
  margin-left: 5px;
`

export const StyledBookingListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
`

/**
 *
 * Details
 *
 */
export const StyledBookingListItemState = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledBookingListItemStateIcon = styled.div`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};

  &:nth-child(n + 2) {
    margin-top: 8px;
  }
`

export const StyledBookingListItemStatusIcon = styled.div`
  width: 24px;
  height: 24px;
`

export const StyledBookingListItemStatusText = styled.span`
  ${tagSmall};
`

export const StyledBookingListItemStatusButton = styled.div`
  ${tagSmall};
  ${borderRadiusXSmall};
  padding: 3px 7px;
  margin-left: 5px;
`

export const StyledBookingListItemStatus = styled.div`
  display: flex;
  align-items: center;

  ${(props) => {
    let color = props.theme.primary

    if (props.$isStatusCompleted) {
      color = props.theme.primary
    } else if (props.$isStatusAReviewStatus) {
      color = props.theme.warn
    } else if (props.$isStatusASuccessStatus) {
      color = props.theme.secondary
    } else if (props.$isStatusCancelled || props.$isStatusDeclined) {
      color = props.theme.error
    }

    return css`
      ${StyledBookingListItemStatusIcon} {
        fill: ${color};
      }

      ${StyledBookingListItemStatusText} {
        color: ${color};
      }
    `
  }}
`

export const StyledBookingListItemDetailsRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const StyledBookingListItemDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

/**
 *
 * Main
 *
 */
export const StyledBookingListItem = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 25px;
  @media ${tablet.down} {
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.softGrayLight};
  }
  &:hover {
    ${StyledBookingListItemDepartureAdress} {
      color: ${(props) => props.theme.primary};
    }
    ${StyledBookingListItemReference} {
      color: ${(props) => props.theme.primary};
    }
  }
`

export const StyledBookingListItemInfoWrapper = styled.div`
  flex: 0 0 170px;
  @media ${tablet.down} {
    flex: 0;
    margin-bottom: 15px;
  }
  @media ${desktop.up} {
    flex: 0 0 270px;
    margin-right: 0;
  }
`

export const StyledBookingListItemContentWrapper = styled.div`
  flex-grow: 1;
  min-width: 0;
`

export const StyledBookingListItemDetailsWrapper = styled.div`
  flex: 0 0 150px;
  @media ${tablet.down} {
    flex: 0;
    position: absolute;
    right: 0;
  }
`
