import { useTranslation } from 'react-i18next'

import useCurrentOrganization from 'views/iam/hooks/use_current_organization'
import { routeSharedShipment } from 'services/helpers/routes'
import { fetchSharedOrderToken } from 'views/shipment/slice'
import { addNotification } from 'views/notifications/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTracker from 'services/analytics/hooks/use_tracker'

export type UseShareableLinkResponse = { getShareableLink: (id: string) => void }

const useShareableLink = (): UseShareableLinkResponse => {
  const organization = useCurrentOrganization()
  const dispatch = useAppDispatch()
  const { track } = useTracker()
  const { t } = useTranslation()

  const copyToClipboard = (content: string) => {
    const basePath = organization.customDomain ?? process.env.REACT_APP_APPLICATION_ENDPOINT

    navigator.clipboard.writeText(
      `${basePath}${routeSharedShipment(content, {
        isLegacy: true,
      })}`
    )
  }

  const getShareableLink = (id: string) => {
    dispatch(fetchSharedOrderToken({ token: id }))
      .unwrap()
      .then(({ sharedOrderToken }) => {
        copyToClipboard(sharedOrderToken)
        track('Shipment / Share', { action: 'share', detail: sharedOrderToken })
      })
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('shipments.shareableLink.title'),
            text: t('shipments.shareableLink.modal.successMessageLinkCopied'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  return { getShareableLink }
}

export default useShareableLink
