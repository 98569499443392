import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useShipment from 'views/shipment/hooks/use_shipment'
import { LegFactory } from 'views/shipment/models'
import ShipmentContext from 'views/shipment/context'

import BlockTransportationPlaceholder from 'components/block_transportation/skeleton'
import BlockTransportation from 'components/block_transportation'
import BlockTransportationItem from 'components/block_transportation/block_transportation_item'
import Placeholder from 'components/placeholder'

import { SUBSCRIPTION_INTEGRATION_STATUSES, SUBSCRIPTION_STATUSES } from 'constants/shipments'
import { getTestIdForShipmentSegment } from 'tests/e2e/test_ids'
import { isPresent } from 'services/helpers/values'

// TODO: REFACTOR: this leg implementation reflects the obsolete API interface with separated segments/segment_infos.
// It forces to merge both manually
// It should be centered around a new Leg concept
const ShipmentTransportation = () => {
  const { t } = useTranslation()
  const { id } = useContext(ShipmentContext)
  const [shipment, status] = useShipment({ id })

  return (
    <BlockTransportation title={t('shipments.transportation.title')}>
      <Placeholder ready={status.ready} customPlaceholder={<BlockTransportationPlaceholder />}>
        {shipment?.segments?.map((segment, index) => {
          const leg = new LegFactory(segment).make()
          const { origin, departure, arrival, destination, current, subscriptions } =
            leg.findSegmentInfo(shipment?.segmentInfos)
          const subscriptionOnHold = subscriptions.find(
            (s) => s.integrationStatus === SUBSCRIPTION_INTEGRATION_STATUSES.HOLD
          )
          const subscribedSubscription = subscriptions.find(
            ({ subscriptionStatus }) => subscriptionStatus === SUBSCRIPTION_STATUSES.SUBSCRIBED
          )
          const originLocation = origin || departure
          const destinationLocation = destination || arrival
          return (
            <BlockTransportationItem
              key={`block-transportation-item-${leg.id}`}
              type={leg.transportType}
              from={{
                name: originLocation.name,
                country: {
                  name: originLocation.country,
                  code: originLocation.countryCode,
                },
              }}
              to={{
                name: destinationLocation.name,
                country: {
                  name: destinationLocation.country,
                  code: destinationLocation.countryCode,
                },
              }}
              data={leg.data}
              extraData={leg.extraData}
              linksData={leg.links}
              defaultOpened={current}
              isSubscriptionIntegrationOnHold={isPresent(subscriptionOnHold)}
              sanityCheckFailureReason={
                isPresent(subscriptionOnHold) && subscriptionOnHold.lastFailureReason
              }
              subscriptionType={subscribedSubscription?.subscriptionType}
              testId={getTestIdForShipmentSegment(index)}
            />
          )
        })}
      </Placeholder>
    </BlockTransportation>
  )
}

ShipmentTransportation.propTypes = {}

ShipmentTransportation.defaultProps = {}

export default ShipmentTransportation
