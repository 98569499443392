import React, { FC, PropsWithChildren, ReactElement } from 'react'

import { NavigationItemProps } from 'components/navigation_item'
import { StyledNavigation, StyledList } from 'components/navigation/style'
import { TEST_ID_NAV_BAR } from 'tests/e2e/test_ids'

type NavigationItemType =
  | ReactElement<PropsWithChildren<NavigationItemProps>>
  | undefined
  | boolean
  | null

interface NavigationProps {
  isOpenMobile?: boolean
  className?: string
  testId?: string
  children: NavigationItemType | NavigationItemType[]
}

const Navigation: FC<NavigationProps> = ({
  isOpenMobile = false,
  className,
  testId = TEST_ID_NAV_BAR,
  children,
}) => (
  <StyledNavigation className={className} $open={isOpenMobile} data-testid={testId}>
    <StyledList testId={TEST_ID_NAV_BAR}>{children}</StyledList>
  </StyledNavigation>
)

export default Navigation
