import styled, { css } from 'styled-components'

import Input from 'components/input'

import { listItem13, textSmallDescription } from 'styles/utils/texts'

export const StyledItemTitle = styled.span`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
  width: 35%;
`

export const StyledItemText = styled.p`
  word-break: break-word;
  max-width: 165px;
`

export const StyledTextListItem = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  ${(props) => {
    if (props.$variant === 'description') {
      return css`
        flex-direction: column;
        padding-bottom: 25px;
        align-items: flex-start;

        ${StyledItemText} {
          ${textSmallDescription};
          max-width: 400px;
          margin-top: 10px;
          width: 100%;
        }
        ${StyledItemTitle} {
          width: 100%;
        }
      `
    }
    return css`
      ${StyledItemText} {
        ${listItem13};
        margin-left: 10px;
      }
    `
  }}
`

export const StyledTextList = styled.div`
  ${StyledTextListItem}:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.softGray};
  }
`

export const StyledInput = styled(Input)`
  ${listItem13};
`

export const StyledTextArea = styled.div`
  border: none;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
`
