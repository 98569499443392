import styled from 'styled-components'

const TagCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;
  > div {
    white-space: nowrap;
  }
`

const S = { TagCheckboxContainer }

export default S
