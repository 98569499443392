import useCurrentUser from 'views/iam/hooks/use_current_user'

import { Role } from 'views/iam/types'

const useUserIs = () => {
  const user = useCurrentUser()
  return (role: Role): boolean => (user.isSignedIn ? user.roles.some((r) => r === role) : false)
}

export default useUserIs
