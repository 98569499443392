import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Controller, useForm } from 'react-hook-form'

import ShipmentContext from 'views/shipment/context'

import { References } from 'views/shipment/models'

import useUserCan from 'views/iam/hooks/use_user_can'
import useShipment from 'views/shipment/hooks/use_shipment'
import { Parties } from 'views/shipment/components/references/blocks/types'
import {
  fetchConsigneeOptions,
  fetchConsignorOptions,
  fetchForwarderOptions,
  fetchShipperOptions,
} from 'views/select_options/slice'
import TextList from 'components/text_list'
import { ORDER_ORGANIZATION_READ, SHIPMENT_PARTIES_READ } from 'constants/permissions'
import TextListItem from 'components/text_list/text_list_item'
import { StyledItemText, StyledItemTitle, StyledTextListItem } from 'components/text_list/style'
import Button from 'components/button'
import { StyledButtonsWrapper, StyledSelectItem } from 'views/shipment/components/references/style'
import { generatePartiesPayload } from 'views/shipment/components/references/helpers'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import { updateParties, fetchShipment } from 'views/shipment/slice'
import {
  TEST_ID_SHIPMENT_PARTIES_CONSIGNEE_SELECT,
  TEST_ID_SHIPMENT_PARTIES_CONSIGNEE_TEXT,
  TEST_ID_SHIPMENT_PARTIES_CONSIGNOR_SELECT,
  TEST_ID_SHIPMENT_PARTIES_CONSIGNOR_TEXT,
  TEST_ID_SHIPMENT_PARTIES_FORWARDER_SELECT,
  TEST_ID_SHIPMENT_PARTIES_FORWARDER_TEXT,
  TEST_ID_SHIPMENT_PARTIES_SAVE,
  TEST_ID_SHIPMENT_PARTIES_SHIPPER_SELECT,
  TEST_ID_SHIPMENT_PARTIES_SHIPPER_TEXT,
} from 'tests/e2e/test_ids'

const PartiesBlock = ({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const userCan = useUserCan()
  const dispatch = useAppDispatch()
  const { id } = useContext(ShipmentContext)
  const [shipment] = useShipment({ id: id! })
  const { parties } = new References(shipment)

  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm<Parties>({
    defaultValues: {
      shipper: {
        name: parties.shipper,
      },
      forwarder: {
        name: parties.forwarder,
      },
      consignor: {
        name: parties.consignor,
      },
      consignee: {
        name: parties.consignee,
      },
    },
  })

  const onSubmit = (formData: Parties) => {
    const payload = generatePartiesPayload(formData, dirtyFields)

    dispatch(updateParties({ parties: payload, token: id! }))
      .unwrap()
      .then(() => {
        addNotification({
          type: 'success',
          title: t('shipments.notifications.shipmentUpdated.title'),
          text: t('shipments.notifications.shipmentUpdated.content'),
        })
      })
      .then(() => {
        dispatch(fetchShipment({ id: id! }))
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
      .finally(() => {
        setIsEditing(false)
      })
  }

  return (
    <>
      <TextList>
        {userCan(SHIPMENT_PARTIES_READ) && (
          <>
            <StyledTextListItem>
              <StyledItemTitle>{t('shipments.parties.consignor')}</StyledItemTitle>
              {isEditing ? (
                <Controller
                  control={control}
                  name='consignor'
                  render={({ field: { onChange } }) => (
                    <StyledSelectItem
                      testId={TEST_ID_SHIPMENT_PARTIES_CONSIGNOR_SELECT}
                      action={(params) =>
                        fetchConsignorOptions({
                          search: params.search,
                          extraActionParams: { organizationName: shipment?.owner },
                        })
                      }
                      defaultValue={{
                        label: parties.consignor,
                        value: null,
                      }}
                      onChange={({ value: selectValue }) =>
                        onChange({ name: selectValue?.label, id: selectValue?.value })
                      }
                      isClearable
                    />
                  )}
                />
              ) : (
                <StyledItemText data-testid={TEST_ID_SHIPMENT_PARTIES_CONSIGNOR_TEXT}>
                  {parties.consignor}
                </StyledItemText>
              )}
            </StyledTextListItem>
            <StyledTextListItem>
              <StyledItemTitle>{t('shipments.parties.consignee')}</StyledItemTitle>
              {isEditing ? (
                <Controller
                  control={control}
                  name='consignee'
                  render={({ field: { onChange } }) => (
                    <StyledSelectItem
                      testId={TEST_ID_SHIPMENT_PARTIES_CONSIGNEE_SELECT}
                      action={(params) =>
                        fetchConsigneeOptions({
                          search: params.search,
                          extraActionParams: { organizationName: shipment?.owner },
                        })
                      }
                      defaultValue={{
                        label: parties.consignee,
                        value: null,
                      }}
                      onChange={({ value: selectValue }) =>
                        onChange({ name: selectValue?.label, id: selectValue?.value })
                      }
                      isClearable
                    />
                  )}
                />
              ) : (
                <StyledItemText data-testid={TEST_ID_SHIPMENT_PARTIES_CONSIGNEE_TEXT}>
                  {parties.consignee}
                </StyledItemText>
              )}
            </StyledTextListItem>
            <StyledTextListItem>
              <StyledItemTitle>{t('shipments.parties.forwarder')}</StyledItemTitle>
              {isEditing ? (
                <Controller
                  control={control}
                  name='forwarder'
                  render={({ field: { onChange } }) => (
                    <StyledSelectItem
                      testId={TEST_ID_SHIPMENT_PARTIES_FORWARDER_SELECT}
                      action={(params) =>
                        fetchForwarderOptions({
                          search: params.search,
                          extraActionParams: { organizationName: shipment?.owner },
                        })
                      }
                      defaultValue={{
                        label: parties.forwarder,
                        value: null,
                      }}
                      onChange={({ value: selectValue }) =>
                        onChange({ name: selectValue?.label, id: selectValue?.value })
                      }
                      isClearable
                    />
                  )}
                />
              ) : (
                <StyledItemText data-testid={TEST_ID_SHIPMENT_PARTIES_FORWARDER_TEXT}>
                  {parties.forwarder}
                </StyledItemText>
              )}
            </StyledTextListItem>
            <StyledTextListItem>
              <StyledItemTitle>{t('shipments.parties.shipper')}</StyledItemTitle>
              {isEditing ? (
                <Controller
                  control={control}
                  name='shipper'
                  render={({ field: { onChange } }) => (
                    <StyledSelectItem
                      testId={TEST_ID_SHIPMENT_PARTIES_SHIPPER_SELECT}
                      action={(params) =>
                        fetchShipperOptions({
                          search: params.search,
                          extraActionParams: { organizationName: shipment?.owner },
                        })
                      }
                      defaultValue={{
                        label: parties.shipper,
                        value: null,
                      }}
                      onChange={({ value: selectValue }) =>
                        onChange({ name: selectValue?.label, id: selectValue?.value })
                      }
                      isClearable
                    />
                  )}
                />
              ) : (
                <StyledItemText data-testid={TEST_ID_SHIPMENT_PARTIES_SHIPPER_TEXT}>
                  {parties.shipper}
                </StyledItemText>
              )}
            </StyledTextListItem>
          </>
        )}
        {userCan(ORDER_ORGANIZATION_READ) && (
          <TextListItem
            title={t('shipments.parties.owner')}
            text={parties.owner}
            key='text-list-item-owner'
          />
        )}
      </TextList>
      {isEditing && (
        <StyledButtonsWrapper>
          <Button
            type='button'
            text={t('actions.cancel')}
            onClick={() => {
              reset()
              setIsEditing(false)
            }}
          />
          <Button
            type='button'
            text={t('actions.save')}
            disabled={!isDirty}
            onClick={handleSubmit(onSubmit)}
            variant='highlight'
            testId={TEST_ID_SHIPMENT_PARTIES_SAVE}
          />
        </StyledButtonsWrapper>
      )}
    </>
  )
}

export default PartiesBlock
