import { createContext } from 'react'

import type { AppTypes } from 'constants/app_type'

interface AppContextValues {
  type: AppTypes
  isStandalone: boolean
  isClasquin: boolean
  isShared: boolean
  isEmbedded: boolean
}

const AppContext = createContext<AppContextValues>({
  type: null,
  isStandalone: true,
  isClasquin: false,
  isShared: false,
  isEmbedded: false,
})

export default AppContext
