import React, { useState } from 'react'

import Icon from 'components/icon'
import Select, { Tvalue } from 'components/select'
import Tag from 'components/tag'
import UploadProgress from 'components/upload_progress'
import HoverTooltip from 'components/hover_tooltip'
import {
  StyledUploadDocFileDetails,
  StyledUploadDocFile,
  StyledUploadDocFileName,
  StyledUploadDocFileIcon,
  StyledUploadDocFileProgress,
  StyledUploadDocFileWarning,
  StyledUploadDocFileValidate,
} from 'components/upload_document_file/style'

import useStaticLocales from 'views/locales/hooks/use_static_locales'

import { isAnyArray } from 'services/helpers/values'

import { TEST_ID_SELECT_TRANSPORT_DOCUMENT_TYPE } from 'tests/e2e/test_ids'

interface UploadDocumentFileProps {
  onUpdateType: (value: { value: Tvalue; label: string }) => void
  uploading: boolean
  uploaded: boolean
  uploadProgress: number
  errors: { message: string }[]
  path: string
  selectTestId?: string
}

const UploadDocumentFile: React.FC<UploadDocumentFileProps> = ({
  onUpdateType,
  uploading,
  uploaded,
  uploadProgress,
  errors,
  path,
  selectTestId,
}) => {
  const [fileType, setFileType] = useState<{ label: string; value: Tvalue } | null>(null)

  const updateType = ({ value }: { value: { label: string; value: Tvalue } }) => {
    setFileType({ ...fileType, ...value })
    if (onUpdateType) onUpdateType(value)
  }
  const { s } = useStaticLocales()
  const documentTypes = s('documentTypes')
  const documentOptions = Object.entries(documentTypes).map(([value, label]) => ({ label, value }))
  const hasErrors = isAnyArray(errors)
  return (
    <StyledUploadDocFile $uploaded={uploaded || uploading} data-testid={selectTestId}>
      <StyledUploadDocFileIcon as={Icon} name='file' />
      <HoverTooltip delayed content={path}>
        <StyledUploadDocFileName>{path}</StyledUploadDocFileName>
      </HoverTooltip>

      {!uploaded && !uploading && !hasErrors && (
        <Select
          options={documentOptions}
          onChange={updateType}
          testId={TEST_ID_SELECT_TRANSPORT_DOCUMENT_TYPE}
        />
      )}

      {(uploaded || uploading) && !hasErrors && (
        <StyledUploadDocFileDetails>
          <Tag text={fileType?.label || ''} />
          {uploading && (
            <StyledUploadDocFileProgress as={UploadProgress} progress={uploadProgress} />
          )}
          {uploaded && <StyledUploadDocFileValidate as={Icon} name='check_plain' />}
        </StyledUploadDocFileDetails>
      )}

      {hasErrors && (
        <StyledUploadDocFileDetails>
          <StyledUploadDocFileWarning>
            <HoverTooltip
              placement='left'
              size='large'
              content={
                <ul>
                  {errors.map((e) => (
                    <li key={`error-${e.message}`}>{e.message}</li>
                  ))}
                </ul>
              }
            >
              <Icon name='warning' />
            </HoverTooltip>
          </StyledUploadDocFileWarning>
        </StyledUploadDocFileDetails>
      )}
    </StyledUploadDocFile>
  )
}

export default UploadDocumentFile
