import React, { FC } from 'react'

import S from 'views/audit_trail/style'

import { isArray, isBoolean, isObject, isPresent } from 'services/helpers/values'

interface VersionStateProps {
  state: Record<string, unknown>
}

const VersionState: FC<VersionStateProps> = ({ state }) => (
  <S.VersionState>{isPresent(state) && <VersionStateHash state={state} />}</S.VersionState>
)

const isSubValue = (value: unknown): boolean => isObject(value) || isArray(value)

const VersionStateKeyValue: FC<{ label: string; value: unknown }> = ({ label, value }) => (
  <div>
    <S.VersionStateKey>{label}</S.VersionStateKey>
    {isSubValue(value) ? (
      <>
        <br />
        <S.VersionStateSubValue>
          <VersionStateValue value={value} />
        </S.VersionStateSubValue>
      </>
    ) : (
      <VersionStateValue value={value} />
    )}
  </div>
)

const VersionStateValue: FC<{ value: unknown }> = ({ value }) => {
  let valueComponent
  if (isObject(value)) {
    valueComponent = <VersionStateHash state={value} />
  } else if (isArray(value)) {
    valueComponent = <VersionStateArray state={value} />
  } else if (isBoolean(value)) {
    valueComponent = <>{value.toString()}</>
  } else {
    valueComponent = <>{value}</>
  }
  return valueComponent
}

const VersionStateHash: FC<{ state: Record<string, unknown> }> = ({ state }) => (
  <>
    {Object.entries(state).map(([key, value]) => (
      <VersionStateKeyValue label={`${key}: `} value={value} />
    ))}
  </>
)

const VersionStateArray: FC<{ state: unknown[] }> = ({ state }) => (
  <>
    {state.map((value, index) => (
      <VersionStateKeyValue label={`${index + 1} - `} value={value} />
    ))}
  </>
)

export default VersionState
