import React, { FC, useCallback, useRef } from 'react'
import '@yaireo/dragsort/dist/dragsort.css'

import Tags from '@yaireo/tagify/dist/react.tagify'

import '@yaireo/tagify/dist/tagify.css'
import { patternEmail } from 'services/helpers/form_validation'

import {
  GlobalStyle,
  StyledInput,
  StyledInputTags,
  StyledInputTagsError,
} from 'components/input_tags/style'
import { StyledInputLabel } from 'components/input/style'
import Icon from 'components/icon'
import { isPresent } from 'services/helpers/values'

interface Settings {
  maxTags: number
  delimiters: string
  keepInvalidTags: boolean
  dropdown: {
    enabled: number
    closeOnSelect: boolean
  }
}

const defaultSettings: Partial<Settings> = {
  maxTags: Infinity,
  keepInvalidTags: true,
  dropdown: {
    enabled: 0,
    closeOnSelect: false,
  },
}

const patternMap = {
  email: patternEmail,
}

type InputTagPattern = 'email'

type TagifyEvent = {
  detail: {
    tagify: {
      value: { value: string }[]
    }
  }
}

interface InputTagsProps {
  label?: string
  placeholder?: string
  hidePlaceholderWithTag?: boolean
  name?: string
  error?: string
  onChange?: ({ tags }: { tags: string[] | null }) => void
  value?: string[] | null
  defaultValue?: string[]
  options?: string[]
  pattern?: InputTagPattern
  disabled?: boolean
  settings?: Partial<Settings>
}

const InputTags: FC<InputTagsProps> = ({
  label,
  placeholder,
  hidePlaceholderWithTag = false,
  name,
  error,
  onChange,
  value,
  defaultValue,
  options,
  pattern,
  disabled = false,
  settings,
}) => {
  const tagifyRef = useRef()
  const onChangeInternal = useCallback(
    (event: TagifyEvent) => {
      if (onChange) onChange({ tags: event.detail.tagify.value.map((v) => v.value) })
    },
    [onChange]
  )

  return (
    <StyledInput $hidePlaceholderWithTag={hidePlaceholderWithTag}>
      <GlobalStyle />
      <StyledInputLabel>{label}</StyledInputLabel>
      <StyledInputTags
        $hasError={error}
        as={Tags}
        settings={{
          ...defaultSettings,
          ...settings,
          ...(isPresent(pattern) ? { pattern: patternMap[pattern] } : {}),
        }}
        tagifyRef={tagifyRef}
        placeholder={placeholder}
        name={name}
        onChange={onChangeInternal}
        value={value}
        defaultValue={defaultValue}
        whitelist={options}
        disabled={disabled}
      />
      {error && (
        <StyledInputTagsError>
          <Icon name='warning' /> {error}
        </StyledInputTagsError>
      )}
    </StyledInput>
  )
}

export default InputTags
