import React, { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

const InformationComments: FC = () => {
  const { token } = useContext(BookingContext)
  const { t } = useTranslation()
  const [booking] = useBooking(token)
  const { comments, forwarderComment } = booking!

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.comments')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.shipperComment')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={comments} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
        <StyledFormEditGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.forwarderComment')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>
            <ValueWithFallback value={forwarderComment} />
          </StyledFormEditGroupValue>
        </StyledFormEditGroupItem>
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationComments
