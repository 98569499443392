import { InferType, object, string } from 'yup'

import { patternEmail } from 'services/helpers/form_validation'

export const informationSchema = object({
  email: string()
    .required('errors.validation.required')
    .matches(patternEmail, { message: 'profile.tabs.information.form.invalidEmail' }),
  lastName: string().required('errors.validation.required'),
  firstName: string().required('errors.validation.required'),
}).required()
export type InformationData = InferType<typeof informationSchema>
