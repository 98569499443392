import styled from 'styled-components'

import { text14Regular } from 'styles/utils/texts'

export const StyledShipmentTransit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`

export const StyledShipmentTransitIcon = styled.div`
  fill: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
  flex: none;
  margin-right: 10px;
`
export const StyledShipmentTransitText = styled.p`
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};
`

export const StyledShipmentTransitDestination = styled.span`
  color: ${({ theme }) => theme.textDark};
`
