/* eslint import/no-cycle: 0 */
import { isLocal } from 'services/helpers/environment'

import { APP_TYPE_SHARED } from 'constants/app_type'

import { Error, ErrorModel } from 'views/errors/models'
import { signOut } from 'views/iam/slices/iamSlice'

import type { AxiosError } from 'axios'
import type { AppTypes } from 'constants/app_type'
import type { Dispatch } from 'react'

export const APIError = (
  axiosError: AxiosError<string | { error: string; code?: number }> | ErrorModel
): ErrorModel => {
  // TODO: This condition no longer seems to be used
  if (ErrorModel.isErrorModel(axiosError)) {
    const { name, message, code, stack } = axiosError
    return new ErrorModel({ name, message, code, stack })
  }

  if (!axiosError.response) {
    // Axios errors may lack a response due to network issues, timeouts, configuration errors, CORS blocks,
    // or failures occurring before the request reaches the server.
    return new ErrorModel({
      name: axiosError.message,
      message: axiosError.message,
      code: 500,
      stack: axiosError.stack,
    })
  }
  if (typeof axiosError.response.data === 'object') {
    // Handle errors formatted, which return an object containing error and code
    return new ErrorModel({
      name: axiosError.response?.statusText,
      message: axiosError.response?.data?.error,
      code: axiosError.response?.data?.code,
      stack: axiosError.stack,
    })
  }
  // Errors not handled in the backend
  return new ErrorModel({
    name: axiosError.response?.statusText,
    message: axiosError.response?.data,
    code: axiosError.response?.status,
    stack: axiosError.stack,
  })
}

interface HandleErrorProps {
  axiosError: AxiosError<any>
  dispatch: Dispatch<any>
  type?: AppTypes
}
const handleError = ({ axiosError, dispatch, type = undefined }: HandleErrorProps): Error => {
  const error = APIError(axiosError)
  const { code } = error

  switch (code) {
    case 498:
      if (type !== APP_TYPE_SHARED) dispatch(signOut())
      break
    default:
      break
  }
  return error.toPayload()
}

// TODO: REFACTOR
// Type async thunk entities
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onError =
  ({ dispatch, rejectWithValue, getState }: any) =>
  (error: AxiosError) => {
    if (isLocal) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
    return rejectWithValue(
      handleError({ axiosError: error, dispatch, type: getState().application.type as AppTypes })
    )
  }

export const onExternalError = handleError

export default onError
