import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentContext from 'components/shipment_list_item/context'
import useReferences from 'components/shipment_list_item/hooks/use_references'
import { StyledShipmentListItemRef } from 'components/shipment_list_item/style'

import { isPresent } from 'services/helpers/values'
import DateHelper from 'services/helpers/date_helper'

import useUserCan from 'views/iam/hooks/use_user_can'

import { ORDER_ADMIN } from 'constants/permissions'

import type { Reference } from 'components/shipment_list_item/hooks/use_references'

const ImportDate: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { createdAt } = shipment
  const { t } = useTranslation()
  const userCan = useUserCan()
  const defaultValue = 'n/a'
  const references: Reference[] = [
    {
      Component: StyledShipmentListItemRef,
      value: isPresent(createdAt)
        ? `${new DateHelper(createdAt).toLocale({ hours: true })}`
        : defaultValue,
      show: userCan(ORDER_ADMIN),
      name: 'import',
      tooltipName: t('shipments.createdTime'),
    },
  ]

  const { References } = useReferences({ references })
  return <References />
}

export default ImportDate
