import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Form from 'components/form'

import { IFilter } from 'services/hooks/use_filter'
import { CommonMerchandiseDetails } from 'views/booking/slices/types'
import {
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'
import Button from 'components/button'
import Grid from 'components/grid'
import Input from 'components/input'
import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'
import {
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandiseProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'
import Select from 'components/select'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

const MerchandisePackageTotalDetails: FC<{
  merchandiseFilter: IFilter<'custom'>
  withMultipleDetails: boolean
}> = ({
  merchandiseFilter: filter,
  withMultipleDetails,
}: {
  merchandiseFilter: IFilter<'custom'>
  withMultipleDetails: boolean
}) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return filter.value.packageTotal.content.map(
    (_merchandiseDetails: CommonMerchandiseDetails, index: number) => (
      <Form.Insert
        subtitle={`${t('bookings.merchandise.merchandiseDetails')} ${
          withMultipleDetails ? `#${index + 1}` : ''
        }`.trim()}
      >
        {index !== 0 && (
          <StyledRemoveMerchandiseItemWrapper>
            <Button
              variant='icon'
              icon='trash'
              disabled={filter.isDisabled}
              onClick={() => {
                filter.onChange((state: any) => {
                  state.packageTotal.content.splice(index, 1)
                })
              }}
            />
          </StyledRemoveMerchandiseItemWrapper>
        )}
        <Grid columns={3}>
          <Grid.Row testId={getTestIdForMerchandiseProduct(index)}>
            <Grid.Column>
              <Textarea
                label={t('bookings.merchandise.productDescription')}
                placeholder={t('bookings.merchandise.yourDescription')}
                name={`merchandiseDescription-${index}`}
                rows={4}
                compact
                value={filter.value.packageTotal.content[index].productDescription}
                onChange={({ target: { value } }) =>
                  filter.onChange((state: any) => {
                    state.packageTotal.content[index].productDescription = value
                  })
                }
                disabled={filter.isDisabled}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                type='number'
                step='0.01'
                min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                label={t('bookings.merchandise.commercialValue')}
                placeholder={t('bookings.merchandise.amount')}
                name={`merchandiseAmount-${index}`}
                value={filter.value.packageTotal.content[index].commercialValue?.amount}
                error={filter.error?.packageTotal?.content[index]?.commercialValueAmount}
                onChange={({ target: { value } }) =>
                  filter.onChange((state: any) => {
                    if (!state.packageTotal.content[index].commercialValue)
                      state.packageTotal.content[index].commercialValue = {}
                    state.packageTotal.content[index].commercialValue.amount = value
                  })
                }
                disabled={filter.isDisabled}
              />
              <Select
                placeholder={t('bookings.merchandise.currency')}
                options={fromStaticToSelectOptions('currencies')}
                name={`merchandiseCurrencyCode-${index}`}
                value={filter.value.packageTotal.content[index].commercialValue?.currencyCode}
                error={filter.error?.packageTotal.content[index]?.commercialValueCurrencyCode}
                onChange={({ value }: { value: any }) =>
                  filter.onChange((state: any) => {
                    if (!state.packageTotal.content[index].commercialValue)
                      state.packageTotal.content[index].commercialValue = {}
                    state.packageTotal.content[index].commercialValue.currencyCode = value
                  })
                }
                testId={getTestIdForMerchandiseProductCurrencyCode(index)}
                isDisabled={filter.isDisabled}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                label={t('bookings.merchandise.controlledTemperatures.title')}
                placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                type='number'
                step='0.01'
                testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
                name={`merchandiseTemperatureMinimal-${index}`}
                value={filter.value.packageTotal.content[index].controlledTemperatures?.min}
                error={filter.error?.packageTotal?.content[index]?.controlledTemperatures?.min}
                disabled={filter.isDisabled}
                onChange={({ target: { value } }) =>
                  filter.onChange((state: any) => {
                    if (!state.packageTotal.content[index].controlledTemperatures)
                      state.packageTotal.content[index].controlledTemperatures = {}
                    state.packageTotal.content[index].controlledTemperatures.min = value
                  })
                }
              />
              <Input
                placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                type='number'
                step='0.01'
                testId={getTestIdForMerchandiseProductMaximalTemperature(index)}
                name={`merchandiseTemperatureMaximal-${index}`}
                value={filter.value.packageTotal.content[index].controlledTemperatures?.max}
                error={filter.error?.packageTotal?.content[index]?.controlledTemperatures?.max}
                disabled={filter.isDisabled}
                onChange={({ target: { value } }) =>
                  filter.onChange((state: any) => {
                    if (!state.packageTotal.content[index].controlledTemperatures)
                      state.packageTotal.content[index].controlledTemperatures = {}
                    state.packageTotal.content[index].controlledTemperatures.max = value
                  })
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Select
                label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                placeholder={t('actions.select')}
                options={fromStaticToSelectOptions('hazardousGoods')}
                name={`merchandiseHazardousClass-${index}`}
                value={filter.value.packageTotal.content[index].hazardousGoods?.hazardousClass}
                onChange={({ value }: { value: any }) =>
                  filter.onChange((state: any) => {
                    if (!state.packageTotal.content[index].hazardousGoods)
                      state.packageTotal.content[index].hazardousGoods = {}
                    state.packageTotal.content[index].hazardousGoods.hazardousClass = value
                  })
                }
                isDisabled={filter.isDisabled}
                isClearable
              />
            </Grid.Column>
            <Grid.Column>
              <Select
                label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                placeholder={t('actions.select')}
                options={fromStaticToSelectOptions('packingGroups')}
                name={`merchandiseHazardousPackingGroup-${index}`}
                value={filter.value.packageTotal.content[index].hazardousGoods?.packingGroup}
                onChange={({ value }: { value: any }) =>
                  filter.onChange((state: any) => {
                    if (!state.packageTotal.content[index].hazardousGoods)
                      state.packageTotal.content[index].hazardousGoods = {}
                    state.packageTotal.content[index].hazardousGoods.packingGroup = value
                  })
                }
                isDisabled={filter.isDisabled}
                isClearable
              />
            </Grid.Column>
            <Grid.Column>
              <StyledHazardousGoodsWrapper>
                <StyledHazardousGoodsItemInput
                  label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                  placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                  name={`merchandiseHazardousUnNumber-${index}`}
                  value={filter.value.packageTotal.content[index].hazardousGoods?.unNumber}
                  disabled={filter.isDisabled}
                  onChange={({ target: { value } }) =>
                    filter.onChange((state: any) => {
                      if (!state.packageTotal.content[index].hazardousGoods)
                        state.packageTotal.content[index].hazardousGoods = {}
                      state.packageTotal.content[index].hazardousGoods.unNumber = value
                    })
                  }
                />
                <StyledHazardousGoodsItemInput
                  label={t('bookings.merchandise.product.hazardousGoods.weight')}
                  placeholder='kg'
                  type='number'
                  step='0.01'
                  min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                  name={`merchandiseHazardousWeight-${index}`}
                  value={filter.value.packageTotal.content[index].hazardousGoods?.weight?.value}
                  disabled={filter.isDisabled}
                  error={filter.error?.packageTotal?.content[index]?.hazardousWeight}
                  onChange={({ target: { value } }) =>
                    filter.onChange((state: any) => {
                      if (!state.packageTotal.content[index].hazardousGoods)
                        state.packageTotal.content[index].hazardousGoods = {}

                      if (!state.packageTotal.content[index].hazardousGoods.weight)
                        state.packageTotal.content[index].hazardousGoods.weight = {}

                      state.packageTotal.content[index].hazardousGoods.weight.value = value
                    })
                  }
                />
              </StyledHazardousGoodsWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Insert>
    )
  )
}

export default MerchandisePackageTotalDetails
