import useFilter from 'services/hooks/use_filter'

const useFilters = () => {
  const referenceFilter = useFilter({ name: 'reference', type: 'text' })
  const shipmentReferenceFilter = useFilter({ name: 'shipmentReference', type: 'text' })

  const partiesShipperFilter = useFilter({ name: 'partiesShipper', type: 'select' })
  const partiesForwarderFilter = useFilter({ name: 'partiesForwarder', type: 'select' })
  const partiesConsignorFilter = useFilter({ name: 'partiesConsignor', type: 'select' })
  const partiesConsigneeFilter = useFilter({ name: 'partiesConsignee', type: 'select' })

  const incotermsFilter = useFilter({ name: 'incoterms', type: 'select' })

  const routingOriginFilter = useFilter({ name: 'origin', type: 'select' })
  const routingDestinationFilter = useFilter({ name: 'destination', type: 'select' })

  const transportModeSeaFilter = useFilter({ name: 'transportModeSea', type: 'checkbox' })
  const transportModeAirFilter = useFilter({ name: 'transportModeAir', type: 'checkbox' })

  const statusInReviewFilter = useFilter({ name: 'statusInReview', type: 'checkbox' })
  const statusAcceptedFilter = useFilter({ name: 'statusAccepted', type: 'checkbox' })
  const statusConfirmedFilter = useFilter({ name: 'statusConfirmed', type: 'checkbox' })
  const statusCompletedFilter = useFilter({ name: 'statusCompleted', type: 'checkbox' })
  const statusCancelledFilter = useFilter({ name: 'statusCancelled', type: 'checkbox' })
  const statusDeclinedFilter = useFilter({ name: 'statusDeclined', type: 'checkbox' })

  const shippingModeFCLFilter = useFilter({ name: 'shippingModeFCL', type: 'checkbox' })
  const shippingModeLCLFilter = useFilter({ name: 'shippingModeLCL', type: 'checkbox' })

  const originDateFilter = useFilter({
    name: 'originDate',
    type: 'dateRange',
  })
  const destinationDateFilter = useFilter({
    name: 'destinationDate',
    type: 'dateRange',
  })

  const specifitiyControlledTemperatureFilter = useFilter({
    name: 'controlledTemperature',
    type: 'checkbox',
  })
  const specifityHazardousGoodsFilter = useFilter({
    name: 'hazardousGoods',
    type: 'checkbox',
  })

  const notificationMyBookingsFilter = useFilter({
    name: 'myBookings',
    type: 'checkbox',
  })
  const notificationActionRequiredFilter = useFilter({
    name: 'actionRequired',
    type: 'checkbox',
  })

  return {
    shipmentReferenceFilter,
    referenceFilter,
    partiesShipperFilter,
    partiesForwarderFilter,
    partiesConsignorFilter,
    partiesConsigneeFilter,
    transportModeSeaFilter,
    transportModeAirFilter,
    notificationActionRequiredFilter,
    notificationMyBookingsFilter,
    shippingModeFCLFilter,
    shippingModeLCLFilter,
    specifitiyControlledTemperatureFilter,
    specifityHazardousGoodsFilter,
    statusInReviewFilter,
    statusAcceptedFilter,
    statusCancelledFilter,
    statusDeclinedFilter,
    statusConfirmedFilter,
    statusCompletedFilter,
    routingOriginFilter,
    routingDestinationFilter,
    incotermsFilter,
    originDateFilter,
    destinationDateFilter,
  }
}

export default useFilters
