import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { Controller, useForm, useWatch } from 'react-hook-form'

import useModal from 'components/modal/hooks/use_modal'
import Modal from 'components/modal'
import { TDates } from 'components/input_datepicker'
import Button from 'components/button'
import DateHelper from 'services/helpers/date_helper'

import { StyledModalWrapper } from 'views/shipment/style'
import buildMilestoneParams from 'views/shipment/helpers/milestone'
import MilestoneDatepicker from 'views/shipment/components/milestone_datepicker'

import { TimelineFormMilestone } from 'views/shipment/types/segment_timelines'
import { Location, TransportType } from 'views/shipments/types/shipment'
import { compactArray, isPresent } from 'services/helpers/values'
import { fetchAvailableMilestonesForStep } from 'views/shipment/slice'
import SearchSelect from 'components/search_select'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import {
  MILESTONE_TYPE_AIR_BOOKED,
  MILESTONE_TYPE_CONSIGNMENT_MANIFESTED_ON_FLIGHT,
  MILESTONE_TYPE_GOODS_AT_ORIGIN_CUSTOMS,
  MILESTONE_TYPE_GOODS_BLOCKED_AT_DESTINATION,
  MILESTONE_TYPE_GOODS_BLOCKED_AT_ORIGIN,
  MILESTONE_TYPE_GOODS_CLEARED_AT_DESTINATION_CUSTOMS,
  MILESTONE_TYPE_GOODS_CLEARED_AT_ORIGIN_CUSTOMS,
  MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_DESTINATION,
  MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_ORIGIN,
  MILESTONE_TYPE_PARCEL_CUSTOM_CLEARANCE_COMPLETED,
  MILESTONE_TYPE_PARCEL_CUSTOM_CLEARANCE_STARTED,
  MILESTONE_TYPE_PARCEL_OUT_FOR_DELIVERY,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_ARRIVAL,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_DEPARTURE,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_PROCESSING,
  MILESTONE_TYPE_READY_FOR_PICKUP_DESTINATION,
  TIMELINE_AIR_MILESTONES_WITHOUT_FLIGHT_NUMBER,
  TIMELINE_MILESTONES_WITH_METADATA,
} from 'constants/milestones'
import {
  StyledErrorMessage,
  StyledFormWrapper,
  StyledRevertButtonWrapper,
} from 'components/modal_edit_milestone/style'
import Input from 'components/input'
import IconTooltip from 'components/icon_tooltip'
import { StyledButtonIconTooltip } from 'components/button/style'

const FormBlock = ({
  children,
  title,
  action,
}: {
  children: ReactNode | ReactNode[]
  title: ReactNode
  action?: ReactNode
}) => (
  <StyledFormWrapper>
    <StyledRevertButtonWrapper>
      {title} {action}
    </StyledRevertButtonWrapper>
    <div> {children} </div>
  </StyledFormWrapper>
)

const RevertButton = ({
  onRollback,
  tooltipContent,
}: {
  onRollback: () => void
  tooltipContent: ReactNode[] | ReactNode
}) => {
  const { t } = useTranslation()
  return (
    <StyledRevertButtonWrapper>
      <span> - {t('shipments.dateDeclarationModal.adminDate')}</span>
      <StyledButtonIconTooltip
        as={Button}
        iconType={IconTooltip}
        icon='arrow_go_back'
        iconText={tooltipContent}
        iconSize='nowrap'
        tooltipColor='white'
        onClick={onRollback}
      />
    </StyledRevertButtonWrapper>
  )
}

interface ModalEditMilestoneProps {
  presentMilestonesTypes: (number | null)[]

  location: Location | undefined
  milestone?: TimelineFormMilestone
  transportType: TransportType
  stepType: string
  onApply: (milestone: TimelineFormMilestone) => void
}

const DATE_LIMIT_IN_YEARS = 2

const MILESTONES_THAT_CAN_BE_DUPLICATED = [
  MILESTONE_TYPE_CONSIGNMENT_MANIFESTED_ON_FLIGHT,
  MILESTONE_TYPE_AIR_BOOKED,
  MILESTONE_TYPE_READY_FOR_PICKUP_DESTINATION,

  MILESTONE_TYPE_GOODS_AT_ORIGIN_CUSTOMS,
  MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_ORIGIN,
  MILESTONE_TYPE_GOODS_UNDER_INSPECTION_AT_DESTINATION,
  MILESTONE_TYPE_GOODS_BLOCKED_AT_ORIGIN,
  MILESTONE_TYPE_GOODS_BLOCKED_AT_DESTINATION,
  MILESTONE_TYPE_GOODS_CLEARED_AT_ORIGIN_CUSTOMS,
  MILESTONE_TYPE_GOODS_CLEARED_AT_DESTINATION_CUSTOMS,

  MILESTONE_TYPE_PARCEL_SORTING_CENTER_PROCESSING,
  MILESTONE_TYPE_PARCEL_OUT_FOR_DELIVERY,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_DEPARTURE,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_ARRIVAL,
  MILESTONE_TYPE_PARCEL_CUSTOM_CLEARANCE_STARTED,
  MILESTONE_TYPE_PARCEL_CUSTOM_CLEARANCE_COMPLETED,
]

const MILESTONE_WITH_REQUIRED_ACTUAL_TIME = [
  MILESTONE_TYPE_AIR_BOOKED,
  MILESTONE_TYPE_CONSIGNMENT_MANIFESTED_ON_FLIGHT,
  MILESTONE_TYPE_PARCEL_SORTING_CENTER_PROCESSING,
]

const isWithinLimitation = (date: TDates, timeframe: 'past' | 'future') => {
  const now = new Date()

  const validTimes = {
    past: new DateHelper(date).isAfterOrEqual(
      new Date(now.getFullYear() - DATE_LIMIT_IN_YEARS, now.getMonth(), now.getDate())
    ),
    future: new DateHelper(date).isBeforeOrEqual(
      new Date(now.getFullYear() + DATE_LIMIT_IN_YEARS, now.getMonth(), now.getDate())
    ),
  }

  return validTimes[timeframe]
}

const ModalEditMilestone: FC<ModalEditMilestoneProps> = ({
  milestone,
  onApply,
  location,
  transportType,
  stepType,
  presentMilestonesTypes,
}) => {
  const { t } = useTranslation()
  const { s } = useStaticLocales()
  const { setOpen } = useModal('editMilestone')

  const { milestoneType } = buildMilestoneParams(milestone?.type)
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    setValue,
    getValues,
  } = useForm<TimelineFormMilestone>({
    defaultValues: milestone,
  })
  useWatch({ name: 'type', control })
  const onSubmit = (m: TimelineFormMilestone) => {
    onApply(m)
    setOpen(false)
  }

  const shouldDisplayAdminLabelForMetadata =
    milestone?.isMilestoneDataOverridden.metadata && !getValues('metadataHasBeenRolledBack')

  const milestoneInformation = t(`static.milestones.${milestone?.type}`)
  const stepInformation = compactArray([location?.name, location?.countryCode]).join(', ')
  const header = milestone
    ? compactArray([stepInformation, milestoneInformation]).join(' - ')
    : t('editTimeLineModale.createTitle')

  const onMetadataRollback = () => {
    setValue('metadataHasBeenRolledBack', true, { shouldDirty: true })
    setValue('metadata.flightNumber', milestone?.nextMetadata?.flightNumber || null)
    setValue('metadata.weight', milestone?.nextMetadata?.weight || null)
    setValue('metadata.packageNumber', milestone?.nextMetadata?.packageNumber || null)
  }

  const milestoneNextMetadataToolTipText = (
    <div>
      <ul>
        <li>
          {t('timeline.metadata.flightNumber')} {' : '}
          {milestone?.nextMetadata?.flightNumber ||
            t('shipments.dateDeclarationModal.nextAvailableDate.none')}
        </li>
        <li>
          {t('timeline.metadata.weight')} {' : '}
          {milestone?.nextMetadata?.weight ||
            t('shipments.dateDeclarationModal.nextAvailableDate.none')}
        </li>
        <li>
          {t('timeline.metadata.packageNumber')} {' : '}
          {milestone?.nextMetadata?.packageNumber ||
            t('shipments.dateDeclarationModal.nextAvailableDate.none')}
        </li>
      </ul>
    </div>
  )

  const isActualTimeRequired = MILESTONE_WITH_REQUIRED_ACTUAL_TIME.includes(
    milestone?.type || getValues('type')
  )

  return (
    <Modal position='center' onClose={() => setOpen(false)} modalName='editMilestone'>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <StyledModalWrapper>
          {milestone ? null : (
            <StyledFormWrapper>
              <Controller
                rules={{ required: true }}
                control={control}
                name='type'
                render={({ field: { onChange, value } }) => (
                  <SearchSelect
                    label='Type'
                    action={() =>
                      fetchAvailableMilestonesForStep({
                        transportType,
                        stepType,
                      })
                    }
                    value={{ label: s('milestones')[value ?? -1], value }}
                    onChange={(event) => {
                      onChange(event?.value?.value)
                      setValue('type', event?.value?.value as number)
                    }}
                    fetchedOptionsFormat={(response) =>
                      response.availableMilestones
                        .filter(
                          (m: number) =>
                            !presentMilestonesTypes.includes(m) ||
                            MILESTONES_THAT_CAN_BE_DUPLICATED.includes(m)
                        )
                        .map((m: number) => ({
                          label: s('milestones')[m ?? -1],
                          value: m,
                        }))
                    }
                  />
                )}
              />
            </StyledFormWrapper>
          )}

          <StyledFormWrapper>
            <Controller
              control={control}
              name='plannedTime'
              render={({ field: { onChange, value } }) => (
                <MilestoneDatepicker
                  label={t(`shipments.dateDeclarationModal.${milestoneType}.plannedLabel`)}
                  startDate={value}
                  onChange={(event) => {
                    onChange(event.value[0]?.toString() ?? null)
                    setValue('plannedTimeHasBeenRolledBack', false)
                  }}
                  filterDate={(date: TDates) =>
                    isWithinLimitation(date, 'past') && isWithinLimitation(date, 'future')
                  }
                  withPortal
                  showTimeSelect
                  isOverridden={milestone?.isMilestoneDataOverridden.plannedTime}
                  nextMilestoneDate={milestone?.nextDates.plannedTime}
                  dateRolledBack={
                    isPresent(milestone?.isMilestoneDataOverridden.plannedTime) &&
                    milestone?.nextDates.plannedTime === value
                  }
                  onAdminDateRolledBack={() => {
                    onChange(milestone?.nextDates.plannedTime)
                    setValue('plannedTimeHasBeenRolledBack', true)
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name='estimatedTime'
              render={({ field: { onChange, value } }) => (
                <MilestoneDatepicker
                  label={t(`shipments.dateDeclarationModal.${milestoneType}.estimatedLabel`)}
                  startDate={value}
                  onChange={(event) => {
                    onChange(event.value[0]?.toString() ?? null)
                    setValue('estimatedTimeHasBeenRolledBack', false)
                  }}
                  withPortal
                  filterDate={(date: TDates) =>
                    isWithinLimitation(date, 'past') && isWithinLimitation(date, 'future')
                  }
                  showTimeSelect
                  isOverridden={milestone?.isMilestoneDataOverridden.estimatedTime}
                  nextMilestoneDate={milestone?.nextDates.estimatedTime}
                  dateRolledBack={
                    isPresent(milestone?.isMilestoneDataOverridden.estimatedTime) &&
                    milestone?.nextDates.estimatedTime === value
                  }
                  onAdminDateRolledBack={() => {
                    onChange(milestone?.nextDates.estimatedTime)
                    setValue('estimatedTimeHasBeenRolledBack', true)
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name='actualTime'
              rules={{ required: isActualTimeRequired }}
              render={({ field: { onChange, value } }) => (
                <MilestoneDatepicker
                  label={t(`shipments.dateDeclarationModal.${milestoneType}.actualLabel`)}
                  startDate={value}
                  onChange={(event) => {
                    onChange(event.value[0]?.toString() ?? null)
                    setValue('actualTimeHasBeenRolledBack', false)
                  }}
                  withPortal
                  filterDate={(date: TDates) =>
                    isWithinLimitation(date, 'past') &&
                    new DateHelper(date).isBeforeOrEqual(new Date())
                  }
                  showTimeSelect
                  isOverridden={milestone?.isMilestoneDataOverridden.actualTime}
                  nextMilestoneDate={milestone?.nextDates.actualTime}
                  dateRolledBack={
                    isPresent(milestone?.isMilestoneDataOverridden.actualTime) &&
                    milestone?.nextDates.actualTime === value
                  }
                  onAdminDateRolledBack={() => {
                    onChange(milestone?.nextDates.actualTime)
                    setValue('actualTimeHasBeenRolledBack', true)
                  }}
                />
              )}
            />
            {isActualTimeRequired && !milestone?.actualTime && !getValues('actualTime') && (
              <StyledErrorMessage>{t('timeline.actualTimeRequired')}</StyledErrorMessage>
            )}
          </StyledFormWrapper>
          {TIMELINE_MILESTONES_WITH_METADATA.includes(milestone?.type || getValues('type')) ? (
            <FormBlock
              title='Metadata'
              action={
                shouldDisplayAdminLabelForMetadata && (
                  <RevertButton
                    onRollback={onMetadataRollback}
                    tooltipContent={milestoneNextMetadataToolTipText}
                  />
                )
              }
            >
              {!TIMELINE_AIR_MILESTONES_WITHOUT_FLIGHT_NUMBER.includes(
                milestone?.type || getValues('type')
              ) && (
                <Controller
                  control={control}
                  name='metadata.flightNumber'
                  render={({ field: { onChange, value } }) => (
                    <Input
                      label={t('timeline.metadata.flightNumber')}
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value)
                        setValue('metadata.flightNumber', e.target.value)
                      }}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name='metadata.weight'
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={`${t('timeline.metadata.weight')} (kg)`}
                    type='number'
                    value={value}
                    onChange={(e) => {
                      const parsedValue = parseFloat(e.target.value)
                      onChange(parsedValue)
                      setValue('metadata.weight', parsedValue)
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name='metadata.packageNumber'
                render={({ field: { onChange, value } }) => (
                  <Input
                    label={t('timeline.metadata.packageNumber')}
                    type='number'
                    value={value}
                    onChange={(e) => {
                      const parsedValue = parseInt(e.target.value, 10)
                      onChange(parsedValue)
                      setValue('metadata.packageNumber', parsedValue)
                    }}
                  />
                )}
              />
            </FormBlock>
          ) : null}
        </StyledModalWrapper>
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} onClick={() => setOpen(false)} rounded variant='clear' />
        <Button
          disabled={!isDirty || !isValid}
          text={t('actions.apply')}
          onClick={handleSubmit(onSubmit)}
          rounded
          variant='highlight'
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalEditMilestone
