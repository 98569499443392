import styled from 'styled-components'

import { text16SemiBold, textSmallDescription } from 'styles/utils/texts'
import { MILESTONE_TIME_TYPE_ACTUAL, MilestoneTimeType } from 'constants/shipments'
import StyledFlag from 'components/flag/style'

export const StyledQuickInfoIcon = styled.div`
  flex: none;
  fill: ${({ theme }) => theme.mediumGray};
  margin-right: 20px;
  width: 35px;
  height: 35px;
`

export const StyledQuickInfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const StyledQuickInfoItemLocation = styled.div`
  display: flex;
  align-items: center;

  ${StyledFlag} {
    flex-shrink: 0;
  }
`
export const StyledQuickInfoItemLocationIcon = styled.div<{ $type: MilestoneTimeType }>`
  flex-shrink: 0;
  fill: ${({ $type, theme }) =>
    $type === MILESTONE_TIME_TYPE_ACTUAL ? theme.primary : theme.mediumGray};
`
export const StyledQuickInfoItemName = styled.div`
  ${text16SemiBold};
  color: ${({ theme }) => theme.textDark};
  margin-left: 6px;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StyledQuickInfoItemTime = styled.div`
  ${textSmallDescription};
  color: ${({ theme }) => theme.textLight};
  margin-top: 3px;
`

export const StyledQuickInfoItemDiffTime = styled.div`
  margin-top: -10px;
  margin-left: 10px;
`

// Quick info
export const StyledQuickInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 20px;
  box-shadow: inset 0px -1px 0px #e4e4e4;
  width: 100%;
`

export const StyledQuickInfoContent = styled.div`
  width: 100%;
  min-width: 0;
`

export const StyledQuickInfoWrap = styled.div`
  min-width: 0;
`
