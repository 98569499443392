import useFilter, { TFilterValue } from 'services/hooks/use_filter'
import useCategory from 'services/hooks/use_category'
import useForm from 'services/hooks/use_form'
import { isPresent } from 'services/helpers/values'

const useExportForm = () => {
  const fromFilter = useFilter({
    name: 'from',
    type: 'date',
    customValidation: (value: TFilterValue<'date'>) => isPresent(value),
  })

  const toFilter = useFilter({
    name: 'to',
    type: 'date',
    customValidation: (value: TFilterValue<'date'>) => isPresent(value),
  })

  const exportCategory = useCategory({
    name: 'export',
    filters: [fromFilter, toFilter],
    toQueryParams: (_filters, filtersHash) => ({
      from: filtersHash.from?.value,
      to: filtersHash.to?.value,
    }),
  })
  const categories = [exportCategory]

  const { queryParams, isEdited, isValid } = useForm({ categories })

  return {
    fromFilter,
    toFilter,
    queryParams,
    isEdited,
    isValid,
  }
}

export default useExportForm
