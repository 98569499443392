import styled from 'styled-components'

import { StyledModalFooter } from 'components/modal/style'

export const StyledModalReportShipmentLabel = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.textDark};
`

export const StyledModalReportShipmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const StyledModalReportShipmentFooter = styled(StyledModalFooter)`
  padding-top: 0px;
`
