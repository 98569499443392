import { Map as MapboxMap } from 'mapbox-gl'
import { Geometry } from 'geojson'
import { useTheme } from 'styled-components'

const useGeometry = ({ map }: { map?: MapboxMap }) => {
  const theme = useTheme()
  const addGeometry = (id: string, geojson: Geometry) => {
    if (map?.getSource(id)) return

    map?.addSource(id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: geojson,
        properties: {},
      },
    })

    map?.addLayer({
      id,
      type: 'fill',
      source: id,
      layout: {},
      paint: {
        'fill-color': theme.primary,
        'fill-opacity': 0.5,
      },
    })
  }

  return { addGeometry }
}

export default useGeometry
