import { useState } from 'react'

const useDatepicker = ({ range, defaultStartDate, defaultEndDate }) => {
  const [startDate, setStartDate] = useState(defaultStartDate)
  const [endDate, setEndDate] = useState(defaultEndDate)

  const onChangeHook = (dates) => {
    if (!range) {
      const start = dates
      setStartDate(start)
    } else {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)
    }
  }

  return { endDate, startDate, onChangeHook }
}

export default useDatepicker
