import React from 'react'
import { useTranslation } from 'react-i18next'

import { TransportType } from 'constants/shipments'

import iconsMapping from 'services/helpers/icons_mapping'

import Icon from 'components/icon'
import {
  StyledShipmentTransit,
  StyledShipmentTransitIcon,
  StyledShipmentTransitText,
  StyledShipmentTransitDestination,
} from 'features/shipments/components/modal_shipments/shipment_transit/style'

interface ShipmentTransitProps {
  transportType: TransportType
  destination: string
}

const ShipmentTransit: React.FC<ShipmentTransitProps> = ({ transportType, destination }) => {
  const { t } = useTranslation()

  return (
    <StyledShipmentTransit>
      <StyledShipmentTransitIcon as={Icon} name={iconsMapping(transportType, 'transportation')} />
      <StyledShipmentTransitText>
        {t('dashboard.map.modal.inTransitTo')}{' '}
        <StyledShipmentTransitDestination>{destination}</StyledShipmentTransitDestination>
      </StyledShipmentTransitText>
    </StyledShipmentTransit>
  )
}

export default ShipmentTransit
