import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { anyChildren } from 'services/helpers/prop_types'

import BoxShadow from 'components/box_shadow'
import {
  StyledBlockList,
  StyledButtonsWrapper,
  StyledButton,
  StyledButtonEditWrapper,
} from 'components/block_list/style'

import Title from 'components/block_list/title'
import Button from 'components/button'

const BlockList = ({
  className,
  children,
  hasEdit = false,
  isEditing = false,
  setIsEditing,
  onSave,
}: {
  className?: string
  children: ReactNode
  hasEdit: boolean
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  onSave: [() => void]
}) => {
  const { t } = useTranslation()

  return (
    <StyledBlockList className={className} as={BoxShadow}>
      {hasEdit && (
        <StyledButtonEditWrapper>
          <Button
            type='button'
            icon='pencil'
            variant='smallIcon'
            onClick={() => setIsEditing(!isEditing)}
            iconHighlighted={isEditing}
            ariaLabel={t('actions.edit')}
          />
        </StyledButtonEditWrapper>
      )}
      {children}

      {isEditing && (
        <StyledButtonsWrapper>
          <StyledButton
            as={Button}
            type='button'
            text={t('actions.cancel')}
            onClick={() => setIsEditing(false)}
          />
          <StyledButton
            as={Button}
            type='button'
            text={t('actions.save')}
            onClick={() => {
              onSave.forEach((f) => f())
              setIsEditing(false)
            }}
            variant='highlight'
          />
        </StyledButtonsWrapper>
      )}
    </StyledBlockList>
  )
}
BlockList.Title = Title
BlockList.propTypes = {
  className: PropTypes.string,
  setIsEditing: PropTypes.func,
  children: anyChildren,
  isEditing: PropTypes.bool,
  hasEdit: PropTypes.bool,
  onSave: PropTypes.arrayOf(PropTypes.func),
}

BlockList.defaultProps = {
  className: undefined,
  setIsEditing: Function.prototype,
  children: undefined,
  isEditing: false,
  hasEdit: false,
  onSave: [],
}

export default BlockList
