import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import { selectDocuments, selectBookingDocumentsStatus } from 'views/booking/slices/documents_slice'

const useBookingDocuments = () => {
  const documents = useSelector((state) => selectDocuments(state))
  const status = useStatus(useSelector((state) => selectBookingDocumentsStatus(state)))
  return [documents, status]
}

export default useBookingDocuments
