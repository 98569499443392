import { RefObject, useEffect, useRef } from 'react'

/*
 * useTriggerOnViewport provides a way to trigger a callback function when a target element enters the viewport.
 *
 * @example
 * const MyComponent = () => {
 *   const callback = () => {
 *     console.log('Element is visible!')
 *   }
 *
 *   const triggerElementRef = useTriggerOnViewport(callback)
 *
 *   return (
 *     <div>
 *       <div style={{ height: '150vh' }}>Scroll down to see the element</div>
 *       <div ref={triggerElementRef}>
 *         This element will trigger the callback when it enters the viewport.
 *       </div>
 *     </div>
 *   );
};
 */
const useTriggerOnViewport = <T extends HTMLElement>(callback: () => void): RefObject<T> => {
  const observerTarget = useRef<T | null>(null)

  useEffect(() => {
    let observerRefValue: null | T = null
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          callback()
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
      observerRefValue = observerTarget.current
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue)
      }
    }
  }, [callback, observerTarget])
  return observerTarget
}

export default useTriggerOnViewport
