import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StyledFooterButton } from 'views/booking/components/show/style'
import useBookingWorkflow from 'views/booking/hooks/use_booking_workflow'
import BookingContext from 'views/booking/contexts/booking_context'
import useBookingExchangeAction from 'views/booking/hooks/use_booking_exchange_action'
import useBooking from 'views/booking/hooks/use_booking'
import useBookingGoToShipments from 'views/booking/hooks/use_booking_go_to_shipments'
import useBookingStatus from 'views/booking/hooks/use_booking_status'

import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'

import Modal from 'components/modal'
import Button from 'components/button'
import useModal from 'components/modal/hooks/use_modal'

import { isEmptyArray } from 'services/helpers/values'

import { routeShipment } from 'services/helpers/routes'
import {
  TEST_ID_BOOKING_ACCEPT_BUTTON,
  TEST_ID_BOOKING_CANCEL_BUTTON,
  TEST_ID_BOOKING_CONFIRM_BUTTON,
  TEST_ID_BOOKING_EDIT_BUTTON,
  TEST_ID_BOOKING_VALIDATE_PROPOSAL_BUTTON,
  TEST_ID_BOOKING_DECLINE_BUTTON,
} from 'tests/e2e/test_ids'

const BookingActionButtons = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const [modalAction, setModalAction] = useState(null)
  const { setOpen } = useModal('bookingExchange')

  const onSuccessAction = () => {
    history.push('/bookings')
  }

  const { onClickAction, modalHeader, ModalContent } = useBookingExchangeAction(
    modalAction,
    onSuccessAction
  )

  const { token } = useContext(BookingContext)

  const {
    canEditBooking,
    canCancelBooking,
    canAcceptBooking,
    canConfirmBooking,
    canValidateProposal,
    canGoToShipment,
    canDeclineBooking,
  } = useBookingWorkflow(token)

  const [booking] = useBooking(token)

  const { status, orderTokens, clientReference } = booking

  const { isStatusRateConfirmationRequested, isStatusProposalSent } = useBookingStatus(status)

  const shouldDisplayCancelButton = canCancelBooking
  const shouldDisplayConfirmBookingButton = canConfirmBooking
  const shouldDisplayEditBookingButton = canEditBooking
  const shouldDisplayValidateProposalButton = canValidateProposal
  const shouldDisplayGoToShipmentButton = canGoToShipment
  const shouldDisplayAcceptBookingButton = canAcceptBooking
  const shouldDisplayDeclineBookingButton = canDeclineBooking

  const onCloseModal = () => {
    setOpen(false)
    setModalAction(null)
  }

  const { goToShipments } = useBookingGoToShipments()

  const onClickGoToShipment = () => {
    if (orderTokens.length > 1) {
      goToShipments(token, clientReference)
      return
    }
    history.push(routeShipment(orderTokens[0]))
  }

  return (
    <>
      {modalAction && (
        <Modal position='center' onClose={onCloseModal} modalName='bookingExchange'>
          <Modal.Header>{modalHeader}</Modal.Header>
          <Modal.Content>
            <ModalContent />
          </Modal.Content>
          <Modal.Footer>
            <Button text={t('actions.cancel')} onClick={onCloseModal} rounded variant='clear' />
            <Button text={t('actions.send')} onClick={onClickAction} rounded variant='highlight' />
          </Modal.Footer>
        </Modal>
      )}

      {shouldDisplayCancelButton && (
        <StyledFooterButton
          testId={TEST_ID_BOOKING_CANCEL_BUTTON}
          as={Button}
          text={t('bookings.actions.cancel')}
          onClick={() => {
            setOpen(true)
            setModalAction(BOOKING_EXCHANGE_ACTION_KEYS.CANCEL_BOOKING)
          }}
          rounded
          variant='redOutline'
        />
      )}

      {shouldDisplayDeclineBookingButton && (
        <StyledFooterButton
          testId={TEST_ID_BOOKING_DECLINE_BUTTON}
          as={Button}
          text={t('bookings.actions.decline')}
          onClick={() => {
            setOpen(true)
            setModalAction(BOOKING_EXCHANGE_ACTION_KEYS.DECLINE_BOOKING)
          }}
          rounded
          variant='redOutline'
        />
      )}

      {shouldDisplayEditBookingButton && (
        <StyledFooterButton
          testId={TEST_ID_BOOKING_EDIT_BUTTON}
          as={Button}
          text={t('bookings.actions.edit')}
          onClick={() => history.push(`/booking/${token}/edit`)}
          rounded
          variant='outline'
        />
      )}

      {shouldDisplayValidateProposalButton && (
        <StyledFooterButton
          testId={TEST_ID_BOOKING_VALIDATE_PROPOSAL_BUTTON}
          as={Button}
          text={t('bookings.actions.validateProposal')}
          onClick={() => {
            setOpen(true)
            setModalAction(BOOKING_EXCHANGE_ACTION_KEYS.VALIDATE_PROPOSAL)
          }}
          rounded
          variant='highlight'
        />
      )}
      {shouldDisplayConfirmBookingButton && (
        <StyledFooterButton
          testId={TEST_ID_BOOKING_CONFIRM_BUTTON}
          as={Button}
          text={t('bookings.actions.confirm')}
          onClick={() => {
            setOpen(true)
            setModalAction(BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING)
          }}
          rounded
          variant='highlight'
        />
      )}
      {shouldDisplayAcceptBookingButton && (
        <StyledFooterButton
          testId={TEST_ID_BOOKING_ACCEPT_BUTTON}
          as={Button}
          text={t('bookings.actions.accept')}
          onClick={() => {
            setOpen(true)
            setModalAction(BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING)
          }}
          rounded
          variant='highlight'
          disabled={isStatusRateConfirmationRequested || isStatusProposalSent}
        />
      )}
      {shouldDisplayGoToShipmentButton && (
        <StyledFooterButton
          as={Button}
          text={t('bookings.actions.goToShipment')}
          onClick={onClickGoToShipment}
          rounded
          icon='arrow_right_circle'
          variant='highlight'
          disabled={isEmptyArray(orderTokens)}
        />
      )}
    </>
  )
}

export default BookingActionButtons
