import React, { FC, useContext } from 'react'

import DateHelper from 'services/helpers/date_helper'
import SearchContext from 'components/search/context'

import Icon from 'components/icon'
import {
  StyledSearchListItem,
  StyledSearchListItemRef,
  StyledSearchListItemLocation,
  StyledSearchListItemContent,
  StyledSearchListItemTime,
} from 'components/search_list_item/style'

import { isPresent } from 'services/helpers/values'
import highlightSearch from 'components/search_list_item/helpers/highlight_search'

interface SearchItemProps {
  clientReference?: string
  shipmentReference?: string
  locationFrom?: string
  locationTo?: string
  time?: string
  testId?: string
}

const SearchItem: FC<SearchItemProps> = ({
  clientReference,
  shipmentReference,
  locationFrom,
  locationTo,
  time,
  testId,
}) => {
  const { searchValue: search } = useContext(SearchContext)

  return (
    <StyledSearchListItem data-testid={testId}>
      <StyledSearchListItemContent>
        <StyledSearchListItemRef>
          <span>{highlightSearch(clientReference, search)}</span>
          <span>
            {isPresent(clientReference) && isPresent(shipmentReference) && <span> / </span>}
            {highlightSearch(shipmentReference, search)}
          </span>
        </StyledSearchListItemRef>
        <StyledSearchListItemLocation>
          <span>{locationFrom}</span>
          {locationTo && <Icon name='arrow_right_outline' />}
          <span>{locationTo}</span>
        </StyledSearchListItemLocation>
        <StyledSearchListItemTime>
          {isPresent(time) ? new DateHelper(time).toLocale() : ''}
        </StyledSearchListItemTime>
      </StyledSearchListItemContent>
    </StyledSearchListItem>
  )
}

export default SearchItem
