import { FieldNamesMarkedBoolean } from 'react-hook-form'

import { BookingCustomReference } from 'views/booking/slices/types'
import { Parties, UpdatePartiesPayload } from 'views/shipment/components/references/blocks/types'
import { BookingReferences } from 'views/shipment/types/shipment'

type Reference = { [key: string]: string }

const formatSingleReference = (bookingReference: Reference) => {
  const [key, value] = Object.entries(bookingReference)[0]

  return { key, value } as BookingCustomReference
}

export const formatReferences = (data?: {
  shipperReferences?: Reference[]
  forwarderReferences?: Reference[]
}) => {
  const formattedShipperReferences =
    data?.shipperReferences?.map((ref) => formatSingleReference(ref)) || []
  const formattedForwarderReferences =
    data?.forwarderReferences?.map((ref) => formatSingleReference(ref)) || []
  return {
    shipperReferences: formattedShipperReferences,
    forwarderReferences: formattedForwarderReferences,
  } as BookingReferences
}

export const generatePartiesPayload = (
  parties: Parties,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<Parties>>>
) => {
  const payload: UpdatePartiesPayload = {}

  if (dirtyFields.consignee) payload.consigneeId = parties.consignee?.id ?? null
  if (dirtyFields.consignor) payload.consignorId = parties.consignor?.id ?? null
  if (dirtyFields.shipper) payload.shipperId = parties.shipper?.id ?? null
  if (dirtyFields.forwarder) payload.forwarderId = parties.forwarder?.id ?? null

  return payload
}
