import { WITH_BOOKING, WITH_BOOKING_RATE_CONFIRMATION } from 'constants/organization_features'
import {
  FORWARDER as ROLE_FORWARDER,
  SHIPPER as ROLE_SHIPPER,
  ADMIN,
  SUPER_ADMIN,
} from 'constants/roles'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import useUserIs from 'views/iam/hooks/use_user_is'

enum Role {
  SHIPPER = 'shipper',
  FORWARDER = 'forwarder',
  NO_BOOKING_ROLE = 'no_booking_role',
}

interface BookingRole {
  hasAnyBookingRole: boolean
  isShipper: boolean
  isForwarder: boolean
  role: Role
  hasRateConfirmationFeature: boolean
}

const useBookingRole = (): BookingRole => {
  const userIs = useUserIs()
  const { features: organizationHasFeature } = useOrganizationCan()

  const isShipper = userIs(ROLE_SHIPPER) || userIs(ADMIN) || userIs(SUPER_ADMIN)

  const isForwarder = userIs(ROLE_FORWARDER)

  const hasAnyBookingRole = (isShipper || isForwarder) && organizationHasFeature(WITH_BOOKING)

  // eslint-disable-next-line no-nested-ternary
  const role = hasAnyBookingRole
    ? isShipper
      ? Role.SHIPPER
      : Role.FORWARDER
    : Role.NO_BOOKING_ROLE

  const hasRateConfirmationFeature = organizationHasFeature(WITH_BOOKING_RATE_CONFIRMATION)

  return { isShipper, isForwarder, hasAnyBookingRole, role, hasRateConfirmationFeature }
}

export default useBookingRole
