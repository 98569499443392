import { useCallback, useState } from 'react'

const usePagination = () => {
  const INITIAL_PAGE = 1
  const [page, setPage] = useState(INITIAL_PAGE)
  const next = useCallback(() => setPage((prevPage) => prevPage + 1), [])
  const reset = useCallback(() => setPage(INITIAL_PAGE), [])

  return {
    page,
    next,
    reset,
  }
}

export default usePagination
