import React from 'react'

import { useTheme } from 'styled-components'

import HoverTooltip from 'components/hover_tooltip'
import DateHelper from 'services/helpers/date_helper'
import StyledPointer from 'components/path_marker/style'

const PathMarker = ({
  tooltip,
  bearing,
  color,
}: {
  tooltip: string
  bearing: number
  color: string
}) => {
  const theme = useTheme()
  const pointerColor = color || theme.secondary

  return (
    <HoverTooltip content={`${new DateHelper(tooltip).toLocale({ hours: true })}`}>
      <StyledPointer $bearing={bearing} $color={pointerColor} />{' '}
    </HoverTooltip>
  )
}

export default PathMarker
