import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledTextListItem,
  StyledItemTitle,
  StyledInput,
  StyledItemText,
  StyledTextArea,
} from 'components/text_list/style'
import Textarea from 'components/textarea'

const TextListItem = ({
  variant,
  title,
  text,
  isEditing,
  onChange,
  value,
  inputType,
  testId,
  isTextArea,
}) => {
  const EditComponent = isTextArea ? (
    <StyledTextArea>
      <Textarea value={value} onChange={onChange} disableFocusStyle testId={testId} />
    </StyledTextArea>
  ) : (
    <StyledInput value={value} onChange={onChange} type={inputType} testId={testId} />
  )

  return (
    <StyledTextListItem $variant={variant}>
      <StyledItemTitle>{title}</StyledItemTitle>
      {isEditing && EditComponent}
      {!isEditing && <StyledItemText data-testid={testId}>{text}</StyledItemText>}
    </StyledTextListItem>
  )
}

TextListItem.propTypes = {
  /** Variant */
  variant: PropTypes.oneOf(['description']),
  isEditing: PropTypes.bool,
  /** Title */
  title: PropTypes.string,
  /** Text */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Set text */
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputType: PropTypes.oneOfType(['text', 'number']),
  testId: PropTypes.string,
  isTextArea: PropTypes.bool,
}

TextListItem.defaultProps = {
  variant: undefined,
  isEditing: false,
  title: undefined,
  text: undefined,
  onChange: Function.prototype,
  value: undefined,
  inputType: 'text',
  testId: undefined,
  isTextArea: false,
}

export default TextListItem
