import styled, { css } from 'styled-components'

import { PageContentMaxWidth, PagePadding } from 'components/page/style'
import { text14Regular } from 'styles/utils/texts'

export const columnSize = css<{ $small?: boolean }>`
  flex-basis: ${({ $small }) => ($small ? '10%' : '15%')};
`

const Header = styled.div`
  max-width: ${PageContentMaxWidth};
  margin-right: ${PagePadding};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Users = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  height: 100%;
  margin-bottom: 40px;
  padding-right: 20px;
`

const Legend = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px 10px;
  gap: 20px;
`

const LegendItem = styled.div<{ $small?: boolean; $centered?: boolean }>`
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};
  ${columnSize}
  ${({ $centered }) => $centered && 'text-align:center;'}
  flex-grow: 1
`

const S = {
  Header,
  Content,
  Legend,
  LegendItem,
  Users,
}

export default S
