import styled, { css } from 'styled-components'

import { text14Regular, listItem13, textSmallDescription } from 'styles/utils/texts'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledTextareaWrapper = styled.div`
  display: flex;
`

export const StyledTextareaLabel = styled.label`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
  margin-bottom: 5px;
`

export const StyledTextareaButton = styled.div``

export const StyledTextareaInput = styled.textarea<{
  $compact: boolean | undefined
  $disableFocusStyle?: boolean
}>`
  flex: 1;
  padding: 13px 20px;
  background: ${({ theme }) => theme.bgInput30};
  color: ${({ theme }) => theme.textLight};
  border: 1px solid ${({ theme }) => theme.bgInput30};
  transition: border 0.5s ${easeQuartOut}, background 0.5s ${easeQuartOut};
  ${borderRadiusSmall};
  ${text14Regular};
  width: 100%;
  min-height: ${(props) => (props.$compact ? 'unset' : '160px')};
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.mediumGray};
  }

  ${(props) =>
    !props.$disableFocusStyle &&
    css`
      &:focus {
        border-color: ${({ theme }) => theme.primary};
        background: ${({ theme }) => theme.white};
      }
    `}
`
export const StyledTextarea = styled.div<{ $hasError: boolean; $disabled: boolean }>`
  position: relative;

  ${(props) =>
    props.$hasError &&
    css`
      ${StyledTextareaInput} {
        border-color: ${({ theme }) => theme.error};
      }
    `}

  ${(props) =>
    props.$disabled &&
    css`
      ${StyledTextareaInput} {
        background-color: ${({ theme }) => theme.disabled};
        color: ${({ theme }) => theme.textLight};
      }
    `}
`

export const StyledTextareaError = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.error};
  ${listItem13}
  position: relative;
  top: 7px;
  height: 0;
  width: 100%;

  svg {
    fill: ${({ theme }) => theme.error};
    margin-right: 9px;
    flex: none;
    width: 15px;
    height: 14px;
  }
`
