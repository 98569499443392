import styled from 'styled-components'

import { borderRadiusSmall } from 'styles/utils/border_radius'
import { shadow } from 'styles/utils/shadow'
import { tablet, desktopLarge } from 'styles/utils/breakpoints'

import {
  TrustedRoutesPagePadding,
  TrustedRoutesMaxWidth,
  TrustedRoutesGap,
} from 'views/trusted_routes/style'
import { StyledInputCheckboxLabel } from 'components/input_checkbox/style'
import { textSmallDescription } from 'styles/utils/texts'

const Searchbar = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  ${shadow};
  ${borderRadiusSmall};
  background-color: ${({ theme }) => theme.white};
  max-width: ${TrustedRoutesMaxWidth}px;

  @media ${tablet.up} {
    margin-right: ${TrustedRoutesPagePadding}px;
  }
  @media ${tablet.down} {
    margin-right: ${TrustedRoutesGap}px;
  }
`

const Container = styled.div`
  display: flex;
  flex-basis: 1400px;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  gap: 20px 50px;

  @media ${desktopLarge.down} {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`

const FormFields = styled.div`
  display: flex;
  gap: 25px;

  @media ${desktopLarge.down} {
    flex-direction: column;
    gap: 15px;
  }
`

const FormField = styled.div`
  width: 300px;
`

const RateFormField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 11px 0 11px 0;
  ${StyledInputCheckboxLabel} {
    white-space: nowrap;
    ${textSmallDescription};
    color: ${({ theme }) => theme.textLight};
  }
`

const S = {
  Searchbar,
  FormField,
  FormFields,
  Container,
  RateFormField,
}

export default S
