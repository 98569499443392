import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { IFilter } from 'services/hooks/use_filter'

import Form from 'components/form'
import Grid from 'components/grid'

import { BookingMerchandiseContainerContent } from 'views/booking/slices/types'

import Input from 'components/input'

import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import {
  StyledAddMerchandiseDetailsWrapper,
  StyledAddMerchandiseItemWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'

import { getTestIdForMerchandiseContainer } from 'tests/e2e/test_ids'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

import MerchandisePackageTotalDetails from 'views/booking/components/form/merchandise/merchandise_package_total_details'

interface MerchandisePackageTotalProps {
  merchandiseFilter: IFilter<'custom'>
}

const MerchandisePackageTotal: FC<MerchandisePackageTotalProps> = ({
  merchandiseFilter,
}: MerchandisePackageTotalProps) => {
  const { features } = useOrganizationCan()
  const { t } = useTranslation()

  return (
    <Form.Group>
      <Form.Insert subtitle={t('bookings.merchandise.packageRequest')}>
        <Grid columns={3}>
          {merchandiseFilter.value.packageTotal.content.map(
            (_container: BookingMerchandiseContainerContent, index: number) => (
              <Grid.Row compact testId={getTestIdForMerchandiseContainer(index)}>
                <Grid.Column>
                  <Input
                    type='number'
                    label={t('bookings.merchandise.volume')}
                    placeholder='cbm'
                    step='0.01'
                    required
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME}
                    name={`volume-${index}`}
                    value={merchandiseFilter.value.packageTotal.volume?.value}
                    error={merchandiseFilter.error?.packageTotal.volume}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.packageTotal.volume) state.packageTotal.volume = {}

                        state.packageTotal.volume.value = value
                      })
                    }
                    disabled={merchandiseFilter.isDisabled}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    type='number'
                    label={t('bookings.merchandise.weight')}
                    placeholder='kg'
                    step='0.01'
                    required
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                    name={`weight-${index}`}
                    value={merchandiseFilter.value.packageTotal.weight?.value}
                    error={merchandiseFilter.error?.packageTotal.weight}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.packageTotal.weight) state.packageTotal.weight = {}

                        state.packageTotal.weight.value = value
                      })
                    }
                    disabled={merchandiseFilter.isDisabled}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.numberOfPackages')}
                    placeholder={t('bookings.merchandise.numberOfPackages')}
                    type='number'
                    step='1'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES}
                    name={`packageNumber-${index}`}
                    value={merchandiseFilter.value.packageTotal.packageNumber}
                    error={merchandiseFilter.error?.packageTotal.packageNumber}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        state.packageTotal.packageNumber = value
                      })
                    }
                    disabled={merchandiseFilter.isDisabled}
                  />
                </Grid.Column>
              </Grid.Row>
            )
          )}
        </Grid>
      </Form.Insert>
      {features(WITH_BOOKING_OLD_WORKFLOW) ? (
        <MerchandisePackageTotalDetails
          merchandiseFilter={merchandiseFilter}
          withMultipleDetails={false}
        />
      ) : (
        <Form.Insert subtitle={t('bookings.merchandise.merchandiseDetails')}>
          <MerchandisePackageTotalDetails
            merchandiseFilter={merchandiseFilter}
            withMultipleDetails
          />
          <StyledAddMerchandiseDetailsWrapper>
            <StyledAddMerchandiseItemWrapper>
              <Button
                disabled={merchandiseFilter.isDisabled}
                onClick={() =>
                  merchandiseFilter.onChange((state: any) => {
                    state.packageTotal.content[state.packageTotal.content.length] = {}
                  })
                }
                icon='plus_outline'
                variant='dashed'
                text={`${t('actions.addNew', { context: 'plural' })} ${t(
                  'bookings.merchandise.merchandiseDetails'
                ).toLowerCase()}`}
              />
            </StyledAddMerchandiseItemWrapper>
          </StyledAddMerchandiseDetailsWrapper>
        </Form.Insert>
      )}
    </Form.Group>
  )
}

export default MerchandisePackageTotal
