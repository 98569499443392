import React, { FC } from 'react'

import ShipmentFiltersSavedItem from 'components/shipment_filters_saved_item/skeleton'

const ShipmentListSkeleton: FC<{ count: number }> = ({ count = 5 }) => (
  <>
    {Array.from(Array(count).keys()).map((number) => (
      <ShipmentFiltersSavedItem key={`shipment-skeleton-item-${number}`} />
    ))}
  </>
)

export default ShipmentListSkeleton
