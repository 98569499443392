export const WITH_EMBED_ACCESS_TO_SHIPMENT_INDEX = 'withEmbedAccessToShipmentIndex'
export const WITH_BOOKING = 'withBooking'
export const WITH_BOOKING_CANCEL_ON_CONFIRMED = 'withBookingCancelOnConfirmed'
export const WITH_BOOKING_EDIT_ON_CONFIRMED = 'withBookingEditOnConfirmed'
export const WITH_BOOKING_RATE_CONFIRMATION = 'withBookingRateConfirmation'
export const WITH_CARBON_FOOTPRINT = 'withCarbonFootprint'
export const WITH_FRONT_BOOKING_CUT_OFF_DATES = 'withFrontBookingCutOffDates'
export const WITH_FRONT_BOOKING_ROUTING_DETAILS = 'withFrontBookingRoutingDetails'
export const WITH_FRONT_BOOKING_TRANSPORT_DETAILS = 'withFrontBookingTransportDetails'
export const WITH_BOOKING_FORWARDER_CAN_PATCH_PROPOSAL_DATES =
  'withBookingForwarderCanPatchProposalDates'
export const WITH_SHAREABLE_LINK = 'withShareableLink'
export const WITH_EMBED_HIDDEN_MASTER_BL_AND_BN = 'withEmbedHiddenMasterBlAndBn'
export const WITH_BOOKING_OLD_WORKFLOW = 'withBookingOldWorkflow'
export const WITH_SHIPMENTS_CSV_EXPORT = 'withShipmentsCsvExport'
export const WITH_PARCEL_TRACKING = 'withParcelTracking'
export const WITH_BOOKING_IN_TRUSTED_ROUTES = 'withBookingInTrustedRoutes'
