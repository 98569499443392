import React, { FC } from 'react'

import Modal, { ModalProps } from 'components/modal'
import StyledModalHorizontal from 'components/modal_horizontal/style'
import { TEST_ID_MODAL_TITLE } from 'tests/e2e/test_ids'
import { ModalWidth } from 'components/modal/types/types'

interface ModalHorizontalProps extends ModalProps {
  title: string
  size?: ModalWidth
}

const ModalHorizontal: FC<ModalHorizontalProps> = ({
  title,
  size = 'normal',
  height = 'normal',
  modalName,
  className,
  shouldFocusAfterRender = true,
  width = 'normal',
  noPadding = false,
  onClose,
  position = 'top',
  children,
}) => (
  <StyledModalHorizontal
    as={Modal}
    $size={size}
    $position={position}
    position={position}
    height={height}
    modalName={modalName}
    className={className}
    shouldFocusAfterRender={shouldFocusAfterRender}
    width={width}
    noPadding={noPadding}
    onClose={onClose}
  >
    <Modal.Header testId={TEST_ID_MODAL_TITLE}>{title}</Modal.Header>
    <Modal.Content>{children}</Modal.Content>
  </StyledModalHorizontal>
)

export default ModalHorizontal
