import { createContext } from 'react'

interface SearchActiveContextValues {
  searchValue: string
  setSearchValue: (value: string) => void
}

const SearchActive = createContext<SearchActiveContextValues>({
  searchValue: '',
  setSearchValue: () => {},
})

export default SearchActive
