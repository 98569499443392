import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'

import S from 'views/trusted_routes/components/loader/style'
import { sampleValue } from 'services/helpers/values'

const Loader: React.FC = () => {
  const { t } = useTranslation()
  const gifNameRef = useRef<string>(
    sampleValue(['beacon_anchor', 'vessel', 'container', 'harbor_crane']) || 'container'
  )

  let path = null
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
    path = require(`assets/loader/${gifNameRef.current}.gif`)
    // eslint-disable-next-line no-empty
  } catch (_error) {}

  return (
    <S.Loader>
      <img src={path} alt='loading...' width={50} height={50} />
      <S.TextContainer>
        <S.Text>{t('trustedRoutes.loading.collectingData')}</S.Text>
      </S.TextContainer>
    </S.Loader>
  )
}

export default Loader
