/* eslint-disable */
import { LOCATION_TYPES, VEHICLE_TYPES } from 'constants/shipments'
import { LngLat } from 'mapbox-gl'
import { isPresent } from 'services/helpers/values'
import { ColorType } from 'views/shipments/types/shipment'

// Mapbox implementation reverses coordinates order: lng if returned first
export interface Coordinate {
  lat: number | null
  lng: number | null
  toArray: () => number[]
}
export class CoordinateModel {
  coordinate: Coordinate
  constructor(lat: string, lng: string) {
    this.coordinate = { lat: null, lng: null, toArray: () => [] }
    try {
      if (isPresent(lat) && isPresent(lng)) {
        this.coordinate = new LngLat(parseFloat(lng), parseFloat(lat))
      }
    } catch (e) {
      console.error(e)
    }
  }

  get lng() {
    return this.coordinate.lng
  }

  get lat() {
    return this.coordinate.lat
  }

  get array() {
    return this.coordinate.toArray()
  }
}

export class StepModel {
  coord
  t

  constructor({ lat, lng, type }: { lat: string; lng: string; type: typeof LOCATION_TYPES }) {
    this.coord = new CoordinateModel(lat, lng)
    this.t = type
  }

  isValid = () => this.coord.lat && this.coord.lng

  get coordinate() {
    return this.coord
  }

  get type() {
    return this.t
  }
}

export class VehicleModel {
  id
  coord
  t
  sc
  color
  constructor({
    id,
    lat,
    lng,
    type,
    shipmentsCount,
    color,
  }: {
    id: string
    lat: string
    lng: string
    type: typeof VEHICLE_TYPES
    shipmentsCount: number
    color: ColorType
  }) {
    this.id = id
    this.coord = new CoordinateModel(lat, lng)
    this.t = type
    this.sc = shipmentsCount
    this.color = color
  }

  isValid = () => this.coord.lat && this.coord.lng

  get coordinate() {
    return this.coord
  }

  get type() {
    return this.t
  }

  get shipmentsCount() {
    return this.sc
  }
}

export class PathModel {
  coord
  ts
  constructor({ lat, lng, timestamp }: { lat: string; lng: string; timestamp: string }) {
    this.coord = new CoordinateModel(lat, lng)
    this.ts = timestamp
  }

  isValid = () => this.coord.lat && this.coord.lng

  get coordinate() {
    return this.coord
  }

  get timestamp() {
    return this.ts
  }
}
