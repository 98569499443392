import React, { FC } from 'react'

import Tag from 'components/tag'
import useDiffTime from 'services/hooks/use_diff_time'
import { isPresent } from 'services/helpers/values'
import DateHelper from 'services/helpers/date_helper'

interface TagDiffTimeProps {
  time?: string
  timeTo?: string
  highlighted?: boolean
  testId?: string
  className?: string
}

const TagDiffTime: FC<TagDiffTimeProps> = ({
  time,
  timeTo,
  highlighted = false,
  className,
  testId,
}) => {
  const { diff, isNegative } = useDiffTime(
    new DateHelper(time).toDate(),
    new DateHelper(timeTo).toDate()
  )

  const tagColor = isNegative ? 'success' : 'warn'

  return isPresent(diff) ? (
    <Tag
      testId={testId}
      text={diff}
      color={tagColor}
      size='large'
      highlighted={highlighted}
      className={className}
    />
  ) : null
}

export default TagDiffTime
