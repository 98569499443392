import React from 'react'

import * as IconList from 'assets/icons'

import StyledIcon from 'components/icon/style'

export type IconType = keyof typeof IconList

interface IconProps {
  name: IconType
  width?: number
  height?: number
  fill?: string
  stroke?: string
  className?: string
  ariaLabel?: string
  testId?: string
}

const Icon: React.FC<IconProps> = ({
  name,
  width,
  height,
  fill = '#000',
  stroke,
  className,
  ariaLabel,
  testId,
}) => {
  // eslint-disable-next-line
  const path = require(`assets/icons/${name || 'warning'}.svg`)

  return (
    <StyledIcon
      className={className}
      src={path}
      $fill={fill}
      $stroke={stroke}
      $width={width}
      $height={height}
      aria-label={ariaLabel}
      data-testid={testId}
    />
  )
}

export default Icon
