import React, { FC } from 'react'

import { StyledList, StyledTabNavigation } from 'components/tabs/tab_navigation/style'

const TabNavigation: FC = ({ children }) => (
  <StyledTabNavigation>
    <StyledList>{children}</StyledList>
  </StyledTabNavigation>
)

export default TabNavigation
