import styled from 'styled-components'

import { StyledSelectWrapper } from 'components/tabs/style'
import { StyledInputCheckbox, StyledInputCheckboxLabel } from 'components/input_checkbox/style'

import { text14Regular } from 'styles/utils/texts'
import { zIndexModal } from 'styles/utils/z_index'
import { StyledTabNavigation } from 'components/tabs/tab_navigation/style'

export const StyledModalFormLabel = styled.label`
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};
  margin-bottom: 15px;
  display: flex;
  gap: 0.5em;
}
`
export const StyledModalWidget = styled.div`
  ${StyledSelectWrapper} {
    margin-bottom: 25px;
    z-index: ${zIndexModal};
    position: relative;
  }
`
export const StyledModalForm = styled.div`
  ${StyledTabNavigation} {
    margin-bottom: 25px;
  }
`

export const StyledModalFormSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const StyledModalFormCheckboxesContainer = styled.div`
  margin-bottom: 25px;
`

export const StyledModalFormKpiTypeSelectContainer = styled.div`
  margin-bottom: 25px;
`

export const StyledModalFormCheckboxes = styled.div`
  display: flex;

  ${StyledInputCheckbox} {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }
  ${StyledInputCheckboxLabel} {
    ${text14Regular}
  }
`
