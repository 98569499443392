import styled from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { h3 } from 'styles/utils/texts'

export const StyledBoxShadowInner = styled.div`
  background-color: #fff;
  ${borderRadiusMedium};
`

export const StyledBoxShadow = styled.div<{ $hasTitle?: boolean }>`
  position: relative;
  padding: 8px;
  background: ${(props) => props.theme.softGrayLight};
  ${borderRadiusMedium};

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 5.33%;
    right: 5.33%;
    top: 15.32%;
    bottom: 0%;
    background: #e3e6ec;
    mix-blend-mode: multiply;
    opacity: 0.91;
    filter: blur(80px);
    ${borderRadiusMedium};
  }
`

export const StyledBoxShadowTitle = styled.p`
  ${h3};
  color: ${(props) => props.theme.textDark};
  padding: 30px 35px;
`
