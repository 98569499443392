import { toSnakeCase } from 'services/helpers/values'

const useFormData = (data) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => formData.append(toSnakeCase(key), value))

  return formData
}

export default useFormData
