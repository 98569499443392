import { css } from 'styled-components'

export const shadow = css`
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
`

export const shadowMedium = css`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
`

export const shadowStrong = css`
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
`
