import { useMemo } from 'react'

import { isPresent } from 'services/helpers/values'
import useShallowSelector from 'services/hooks/use_shallow_selector'

import { selectCurrentUser } from 'views/iam/slices/iamSlice'

import type { User } from 'views/iam/types'

export interface DecoratedUser extends User {
  isSignedIn: boolean
}
const decorate = (user: User): DecoratedUser => ({
  ...user,
  isSignedIn: isPresent(user.profile.email),
})
const useCurrentUser = (): DecoratedUser => {
  const user = useShallowSelector(selectCurrentUser)
  const decoratedUser = useMemo(() => decorate(user), [user])
  return decoratedUser
}
export default useCurrentUser
