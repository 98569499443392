import styled, { css } from 'styled-components'

import { PageContentMaxWidth } from 'components/page/style'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { shadowMedium } from 'styles/utils/shadow'
import { text14Regular } from 'styles/utils/texts'
import { columnSize } from 'features/users/components/user_list/style'

const User = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${borderRadiusMedium};
  ${shadowMedium};
  padding: 15px 30px;
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  background-color: ${({ theme }) => theme.white};
  max-width: ${PageContentMaxWidth};
  gap: 20px;
`

const Column = styled.div<{ $small?: boolean; $centered?: boolean }>`
  ${columnSize};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  flex-grow: 1;
  ${({ $centered }) =>
    $centered &&
    css`
      display: flex;
      justify-content: center;
      align-content: center;
      padding-right: 0;
    `}
`

const S = {
  User,
  Column,
}

export default S
