import React, { useContext } from 'react'

import { StyledForm } from 'views/booking/components/show/style'
import InformationRateConfirmation from 'views/booking/components/information_rate_confirmation'
import InformationRouting from 'views/booking/components/information_routing'
import InformationTransportDetails from 'views/booking/components/information_transport_details'
import InformationMerchandise from 'views/booking/components/information_merchandise'
import useBookingWorkflow from 'views/booking/hooks/use_booking_workflow'
import BookingContext from 'views/booking/contexts/booking_context'
import InformationReferences from 'views/booking/components/information_references'
import InformationParties from 'views/booking/components/information_parties'
import InformationMiscellaneous from 'views/booking/components/information_miscellaneous'
import InformationIncoterms from 'views/booking/components/information_incoterms'
import InformationKeyContacts from 'views/booking/components/information_key_contacts'
import InformationComments from 'views/booking/components/information_comments'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import { WITH_FRONT_BOOKING_TRANSPORT_DETAILS } from 'constants/organization_features'

const InformationBookingShow = () => {
  const { token } = useContext(BookingContext)
  const { canSeeRate } = useBookingWorkflow(token)
  const { features } = useOrganizationCan()

  return (
    <StyledForm>
      <InformationReferences />
      <InformationParties />
      <InformationRouting />
      {features(WITH_FRONT_BOOKING_TRANSPORT_DETAILS) && <InformationTransportDetails />}
      {canSeeRate && <InformationRateConfirmation />}
      <InformationMiscellaneous />
      <InformationIncoterms />
      <InformationMerchandise />
      <InformationKeyContacts />
      <InformationComments />
    </StyledForm>
  )
}

export default InformationBookingShow
