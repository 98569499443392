import React, { FC } from 'react'

import useArrivalTimes from 'components/shipment_list_item/hooks/use_arrival_times'
import TagDiffTime from 'components/tag_diff_time'

import useMilestoneTimes from 'services/hooks/use_milestone_times'

const Delay: FC = () => {
  const { actualTime, estimatedTime, plannedTime } = useArrivalTimes()
  const { time, diffTime } = useMilestoneTimes({
    actual: actualTime,
    estimated: estimatedTime,
    planned: plannedTime,
  })
  return time && diffTime ? <TagDiffTime time={time} timeTo={diffTime} /> : null
}

export default Delay
