import styled from 'styled-components'

import { StyledButton } from 'components/button/style'

import { desktop } from 'styles/utils/breakpoints'
import { listItem13 } from 'styles/utils/texts'
import { StyledTabNavigation } from 'components/tabs/tab_navigation/style'

export const StyledModalProfileForm = styled.div`
  ${StyledTabNavigation} {
    margin-bottom: 25px;
  }
`

export const StyledModalProfileFormButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${StyledButton} {
    margin-top: 10px;

    @media ${desktop.up} {
      margin-top: 30px;
    }
  }
`

export const StyledModalProfileFormError = styled.p`
  color: ${({ theme }) => theme.error};
  ${listItem13};
  margin-top: 40px;

  @media ${desktop.down} {
    text-align: center;
  }
`
