import styled, { css } from 'styled-components'

import {
  h1,
  h2,
  text14Regular,
  text14Regular24,
  buttonSmall,
  tagSmall,
  text14SemiBold,
} from 'styles/utils/texts'
import { tablet, desktop, desktopLarge } from 'styles/utils/breakpoints'

import BoxShadow from 'components/box_shadow'
import { StyledBoxShadowInner } from 'components/box_shadow/style'
import Button from 'components/button'
import StyledIcon from 'components/icon/style'
import { StyledItem } from 'components/notif_message/style'
import { StyledDatePicker } from 'components/input_datepicker/style'
import { StyledSpan as StyledButtonSpan, StyledButtonIcon } from 'components/button/style'
import Tag from 'components/tag'
import { PageContentMaxWidth } from 'components/page/style'

import { gradientLightOpacityReverse } from 'styles/utils/gradients'
import { borderRadiusMedium } from 'styles/utils/border_radius'

export const StyledPageBookingDetail = styled.div`
  position: relative;
  display: flex;
  @media ${tablet.up} {
    height: 100%;
  }
  ${gradientLightOpacityReverse}
`

export const StyledPageBookingDetailsMain = styled.div`
  flex-grow: 1;
  padding: 20px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: ${PageContentMaxWidth};
  margin-left: auto;
  margin-right: auto;

  @media ${tablet.up} {
    padding: 20px 20px;
  }

  @media ${desktop.up} {
    padding: 20px 50px;
  }

  @media ${desktopLarge.up} {
    padding: 20px 60px;
  }
`
export const StyledPageBookingDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @media ${tablet.down} {
    flex-direction: column;
  }
`

export const StyledPageBookingDetailButtonBack = styled(Button)`
  margin-bottom: 12px;
  ${StyledButtonSpan} {
    color: ${({ theme }) => theme.textDark};
  }
  ${StyledButtonIcon} {
    fill: ${({ theme }) => theme.mediumGray};
  }

  &:hover {
    ${StyledButtonIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }
`

export const StyledPageBookingDetailsTitle = styled.h1`
  ${h1}
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 12px;
  padding-left: 60px;

  @media ${tablet.up} {
    display: flex;
  }
  @media ${desktop.up} {
    padding-left: 40px;
  }
  @media ${desktopLarge.up} {
    padding-left: 20px;
  }
  @media ${tablet.down} {
    margin-top: 4px;
    padding-left: 5px;
    flex-direction: column;
  }
`

export const StyledPageBookingDetailsClientReference = styled.div``

export const StyledPageBookingDetailBoxShadow = styled(BoxShadow)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  background-color: ${({ theme }) => theme.white};
  padding: 0;
  margin-bottom: 15px;

  ${StyledBoxShadowInner} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`
export const StyledPageBookingDetailInner = styled.div`
  padding: 15px 45px 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;

  @media ${tablet.down} {
    padding: 25px 15px;
  }
`

export const StyledPageBookingDetailTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledPageBookingDetailTitle = styled.h2`
  ${h2}
  color: ${({ theme }) => theme.textDark};
`

export const StyledGroupInsert = styled.div`
  ${borderRadiusMedium}
  border: 1px solid ${({ theme }) => theme.lightGray};
  padding: 35px 35px 15px 35px;
  position: relative;
  margin-top: 10px;
  @media ${tablet.down} {
    margin-top: 10px;
    padding: 0;
    border: none;
  }

  ${StyledItem} {
    width: 100%;
    max-width: 100%;
  }
  ${StyledDatePicker} {
    width: 100%;
  }
`

export const StyledGroupInsertHead = styled.div`
  position: absolute;
  left: 25px;
  top: 0px;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.white};
  padding: 0 10px;
  @media ${tablet.down} {
    left: 0px;
  }
`
export const StyledFormInsertTitle = styled.p`
  ${tagSmall};
  color: ${({ theme }) => theme.textLight};
  margin-left: 15px;
`
export const StyledFormInsertTitleMobile = styled.p`
  ${tagSmall};
  color: ${({ theme }) => theme.textLight};
  float: right;
  margin-bottom: 15px;
`
export const StyledFormInsertTitleMobileStatus = styled.span`
  ${buttonSmall};
  margin-left: 10px;
`

export const StyledFormGroupTitle = styled.p`
  ${text14Regular24}
  margin-bottom:10px;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
    bottom: 10px;
    margin-left: 15px;
  }
`

export const StyledPageBookingDetailStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
  @media ${tablet.down} {
    padding-top: 25px;
    width: 100%;
    padding: 25px 10px 0 10px;
  }
`

export const StyledPageBookingDetailStatusTitle = styled.div<{ $current?: boolean }>`
  display: flex;
  align-items: center;
  ${text14Regular}
  color: ${({ theme }) => theme.textLight};
  ${StyledIcon} {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.mediumGray};
    margin-right: 15px;
    @media ${tablet.down} {
      flex-basis: 20%;
    }
  }
  ${(props) =>
    props.$current &&
    css`
      ${text14SemiBold}
      color: ${({ theme }) => theme.primary};
      ${StyledIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
  @media ${tablet.down} {
    flex-basis: 45%;
  }
`

export const StyledPageBookingDetailStatusName = styled.div`
  @media ${tablet.down} {
    flex-basis: 80%;
    word-break: break-word;
  }
`
export const StyledPageBookingDetailStatusArrow = styled(StyledIcon)`
  width: 35px;
  fill: ${({ theme }) => theme.mediumGray};
  margin: 0 15px;
  @media ${tablet.down} {
    flex-basis: 10%;
  }
`

export const StyledGroupTab = styled.div<{ $active?: boolean }>`
  display: none;
  ${(props) =>
    props.$active &&
    css`
      display: block;
    `}
`
export const StyledFormFooter = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.lightGray};
  padding: 10px 0;
  @media ${tablet.down} {
    margin-top: 0px;
  }
`

export const StyledPageBookingTag = styled(Tag)`
  padding: 10px;
  margin-left: 25px;
  @media ${tablet.down} {
    margin-top: 20px;
    margin-left: 0;
  }
`
export const StyledPageBookingDetailEditButtonBack = styled.div``
export const StyledBookingShowSkeleton = styled.div``
