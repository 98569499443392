import React, { FC, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { TRANSPORT_TYPE_SEA } from 'constants/shipments'

import {
  MERCHANDISE_FCL,
  MERCHANDISE_LCL,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'

import {
  TEST_ID_BOOKING_FORM_MERCHANDISE_TYPE_LCL,
  TEST_ID_BOOKING_FORM_SHIPPING_MODE_FCL,
  TEST_ID_BOOKING_FORM_SHIPPING_MODE_LCL,
} from 'tests/e2e/test_ids'

import { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

import {
  StyledCheckboxContainer,
  StyledMerchandiseTypeSwitchWrapper,
  StyledMerchandiseTypeSwitchInformationSpan,
} from 'views/booking/components/form/style'

import Form from 'components/form'
import SwitchInput from 'components/switch_input'
import CheckboxPicto from 'components/checkbox_picto'

import { IFilter } from 'services/hooks/use_filter'

import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

import MerchandisePackageTotal from 'views/booking/components/form/merchandise/merchandise_package_total'
import MerchandiseTotal from 'views/booking/components/form/merchandise/merchandise_total'
import MerchandiseContainer from 'views/booking/components/form/merchandise/merchandise_container'
import MerchandisePackage from 'views/booking/components/form/merchandise/merchandise_package'
import MerchandisePackageLegacy from 'views/booking/components/form/merchandise/merchandise_package_legacy'
import { isPresent } from 'services/helpers/values'

const insertFirstMerchandiseContentItems = (merchandiseFilter: IFilter<'custom'>) => {
  if (merchandiseFilter.value.total.containers.length === 0)
    merchandiseFilter.value.total.containers.push({})

  if (merchandiseFilter.value.total.products.length === 0)
    merchandiseFilter.value.total.products.push({})

  if (merchandiseFilter.value.container.length === 0)
    merchandiseFilter.value.container.push({ products: [{}] })

  if (merchandiseFilter.value.packageTotal.content.length === 0)
    merchandiseFilter.value.packageTotal.content.push({})

  merchandiseFilter.value.container.forEach((container: any) => {
    if (!isPresent(container.products) || container.products?.length < 1) container.products = [{}] // eslint-disable-line no-param-reassign
  })

  if (merchandiseFilter.value.package.length === 0) merchandiseFilter.value.package.push({})
}

const Merchandise: FC<BookingFormProps> = ({ filters }) => {
  const { t } = useTranslation()
  const { transportTypeFilter, merchandiseFilter } = filters

  insertFirstMerchandiseContentItems(merchandiseFilter)

  const { isCurrentTransportTypeSea } = useMemo(() => {
    const isSea = transportTypeFilter.value === TRANSPORT_TYPE_SEA
    return { isCurrentTransportTypeSea: isSea, isCurrentTransportTypeAir: !isSea }
  }, [transportTypeFilter])

  const { features } = useOrganizationCan()

  return (
    <>
      <Form.Group title={t('bookings.merchandise.title')}>
        {isCurrentTransportTypeSea && (
          <StyledCheckboxContainer>
            <CheckboxPicto
              icon='container'
              onChange={() => {
                merchandiseFilter.onChange((state: any) => {
                  state.shippingMode = MERCHANDISE_FCL
                  state.merchandiseType = MERCHANDISE_TYPE_TOTAL
                })
              }}
              text='FCL'
              type='radio'
              name='merchandise'
              checked={merchandiseFilter.value.shippingMode === MERCHANDISE_FCL}
              condensed
              disabled={merchandiseFilter.isDisabled}
              testId={TEST_ID_BOOKING_FORM_SHIPPING_MODE_FCL}
            />
            <CheckboxPicto
              icon='shipment_box_outline'
              onChange={() => {
                merchandiseFilter.onChange((state: any) => {
                  state.merchandiseType = MERCHANDISE_TYPE_PACKAGE_TOTAL
                  state.shippingMode = MERCHANDISE_LCL
                })
              }}
              name='merchandise'
              type='radio'
              text='LCL'
              checked={merchandiseFilter.value.shippingMode === MERCHANDISE_LCL}
              condensed
              disabled={merchandiseFilter.isDisabled}
              testId={TEST_ID_BOOKING_FORM_SHIPPING_MODE_LCL}
            />
          </StyledCheckboxContainer>
        )}
        {isCurrentTransportTypeSea &&
          merchandiseFilter.value.shippingMode === MERCHANDISE_FCL &&
          !features(WITH_BOOKING_OLD_WORKFLOW) && (
            <StyledMerchandiseTypeSwitchWrapper>
              <StyledMerchandiseTypeSwitchInformationSpan>
                {t('bookings.merchandise.specifyMerchandiseDetails')}:
              </StyledMerchandiseTypeSwitchInformationSpan>
              <SwitchInput
                leftLabel={t('bookings.merchandise.perContainer')}
                onClick={() => {
                  merchandiseFilter.onChange((state: any) => {
                    state.merchandiseType =
                      merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_TOTAL
                        ? MERCHANDISE_TYPE_CONTAINER
                        : MERCHANDISE_TYPE_TOTAL
                  })
                }}
                rightLabel={t('bookings.merchandise.globally')}
                rightOptionChecked={
                  merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_TOTAL
                }
                disabled={merchandiseFilter.isDisabled}
              />
            </StyledMerchandiseTypeSwitchWrapper>
          )}
        {merchandiseFilter.value.shippingMode === MERCHANDISE_LCL && (
          <StyledMerchandiseTypeSwitchWrapper>
            <StyledMerchandiseTypeSwitchInformationSpan>
              {t('bookings.merchandise.specifyMerchandiseDetails')}:
            </StyledMerchandiseTypeSwitchInformationSpan>
            <SwitchInput
              leftLabel={t('bookings.merchandise.perPackage')}
              onClick={() => {
                merchandiseFilter.onChange((state: any) => {
                  state.merchandiseType =
                    merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL
                      ? MERCHANDISE_TYPE_PACKAGE
                      : MERCHANDISE_TYPE_PACKAGE_TOTAL
                })
              }}
              rightLabel={t('bookings.merchandise.globally')}
              rightOptionChecked={
                merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL
              }
              testId={TEST_ID_BOOKING_FORM_MERCHANDISE_TYPE_LCL}
              disabled={merchandiseFilter.isDisabled}
            />
          </StyledMerchandiseTypeSwitchWrapper>
        )}
        {isCurrentTransportTypeSea &&
          merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_TOTAL && (
            <MerchandiseTotal merchandiseFilter={merchandiseFilter} />
          )}
        {isCurrentTransportTypeSea &&
          merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_CONTAINER && (
            <MerchandiseContainer merchandiseFilter={merchandiseFilter} />
          )}
        {merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_PACKAGE && (
          <>
            {features(WITH_BOOKING_OLD_WORKFLOW) ? (
              <MerchandisePackageLegacy merchandiseFilter={merchandiseFilter} />
            ) : (
              <MerchandisePackage merchandiseFilter={merchandiseFilter} />
            )}
          </>
        )}
        {merchandiseFilter.value.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL && (
          <MerchandisePackageTotal merchandiseFilter={merchandiseFilter} />
        )}
      </Form.Group>
    </>
  )
}

export default Merchandise
