import styled, { css } from 'styled-components'

import { text14Regular } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { tablet } from 'styles/utils/breakpoints'
import { SearchBarVariant } from 'components/search/types'

export const StyledIconSearch = styled.div`
  position: absolute;
  top: 12px;
  left: 14px;
  width: 24px;
  height: 24px;
  fill: ${({ theme }) => theme.mediumGray};
  transition: fill 0.5s ${easeQuartOut};
`

export const StyledIconClose = styled.button`
  position: absolute;
  top: 16px;
  right: 14px;
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.mediumGray};
  transition: fill 0.5s ${easeQuartOut};
  z-index: 10;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.primary};
  }
`
export const StyledInput = styled.input`
  padding: 12px 12px 12px 48px;
  width: 100%;
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};

  &::placeholder {
    ${text14Regular};
    color: inherit;
  }
`

export const StyledSearchInner = styled.div`
  position: relative;
  ${borderRadiusSmall};
  transform: translate3d(0px, 0px, 0px);
  transition: transform 0.5s ${easeQuartOut}, background 0.5s ${easeQuartOut};

  @media ${tablet.up} {
    transform: translate3d(calc(100% - 48px), 0px, 0px);
  }
`

export const StyledSearch = styled.div<{
  $compact: boolean
  $open: boolean
  $variant: SearchBarVariant
  $highlighted: boolean
}>`
  position: relative;
  display: inline-block;
  ${borderRadiusSmall};

  &:hover,
  &:focus,
  &:focus-within {
    ${StyledIconSearch} {
      fill: ${({ theme }) => theme.primary};
    }
  }

  ${(props) =>
    props.$compact &&
    !props.$open &&
    css`
      overflow: hidden;
      pointer-events: none;

      ${StyledIconSearch} {
        pointer-events: auto;
      }

      ${StyledSearchInner} {
        transform: translate3d(calc(100% - 48px), 0px, 0px);
      }

      ${StyledIconSearch} {
        cursor: pointer;
      }
    `}

  ${(props) =>
    props.$compact &&
    props.$open &&
    css`
      pointer-events: auto;
      ${StyledSearchInner} {
        transform: translate3d(0px, 0px, 0px);
      }
    `}

  ${(props) =>
    props.$variant === 'light' &&
    css`
      ${StyledSearchInner} {
        background-color: ${({ theme }) => theme.primary};
      }

      ${StyledIconSearch} {
        fill: ${({ theme }) => theme.white};
      }

      ${StyledInput} {
        color: ${({ theme }) => theme.white};
      }

      &:hover,
      &:focus,
      &:focus-within {
        ${StyledIconSearch} {
          fill: ${({ theme }) => theme.primary50};
        }
      }
    `}

    ${(props) =>
    props.$highlighted &&
    css`
      ${StyledInput} {
        color: ${({ theme }) => theme.textLight};
      }

      ${StyledIconSearch} {
        fill: ${({ theme }) => theme.mediumGray};

        &:hover {
          fill: ${({ theme }) => theme.primary};
        }
      }
    `}


  ${(props) =>
    props.$variant === 'dark' &&
    props.$highlighted &&
    css`
      ${StyledSearchInner} {
        background-color: ${({ theme }) => theme.clearGray};
      }
    `}

  ${(props) =>
    props.$variant === 'light' &&
    props.$highlighted &&
    css`
      ${StyledSearchInner} {
        background-color: ${({ theme }) => theme.white};
      }
    `}
`
