import { css, keyframes } from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'

const translateCenterToTopKeyframe = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-50%, calc(-50% + 20px),0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%,0);
  }
`

export const translateCenterToTopAnimation = css`
  animation: 0.3s ${translateCenterToTopKeyframe} ${easeQuartOut} forwards;
`

const translateTopKeyframe = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(-50%, 20px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
`

export const translateTopAnimation = css`
  animation: 0.3s ${translateTopKeyframe} ${easeQuartOut} forwards;
`
