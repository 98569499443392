import styled from 'styled-components'

import { text14Regular, listItem13 } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'
import { tablet } from 'styles/utils/breakpoints'

export const StyledSearchListItem = styled.div`
  padding: 14px;
  box-shadow: inset 0px -1px 0px #efefef;
  position: relative;

  .higlight-search {
    color: ${({ theme }) => theme.primary};
  }
`

export const StyledSearchListItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media ${tablet.down} {
    flex-wrap: wrap;
  }
`

export const StyledSearchListItemRef = styled.div`
  ${text14Regular}
  color: ${({ theme }) => theme.textDark};
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 40%;

  @media ${tablet.down} {
    min-width: auto;
    width: 100%;
    padding-right: 120px;
  }

  span:nth-child(2) {
    color: ${({ theme }) => theme.textLight};
  }
`

export const StyledSearchListItemLocation = styled.div`
  ${text14Regular}
  color: ${({ theme }) => theme.textDark};
  display: flex;
  align-items: center;
  flex-basis: 40%;

  white-space: nowrap;
  overflow: hidden;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ${StyledIcon} {
    flex: none;
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.primary};
  }
`

export const StyledSearchListItemTime = styled.div`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
  white-space: nowrap;
  margin-right: 0;
  margin-left: auto;
  padding-left: 20px;

  @media ${tablet.down} {
    position: absolute;
    top: 15px;
    right: 14px;
  }
`

export const StyledSearchListItemSearchIn = styled.div`
  ${listItem13}
  color: ${({ theme }) => theme.mediumGray};
  margin-top: 5px;
`

export const StyledSearchListItemSearchInValue = styled.div`
  display: inline-block;
  margin-left: 7px;
  color: ${({ theme }) => theme.textLight};
`
