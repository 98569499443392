import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Tag from 'components/tag'
import Page from 'components/page'
import Placeholder from 'components/placeholder'

import useBooking from 'views/booking/hooks/use_booking'
import BookingShow from 'views/booking/components/show'

import {
  StyledPageBookingDetail,
  StyledPageBookingDetailEditButtonBack,
  StyledPageBookingDetailsMain,
  StyledPageBookingDetailsTitle,
  StyledBookingShowSkeleton,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailsHeader,
  StyledPageBookingTag,
  StyledPageBookingDetailsClientReference,
  StyledPageBookingDetailButtonBack,
} from 'views/booking/style'
import { fetchBooking } from 'views/booking/slices/booking_slice'
import ErrorNotification from 'views/errors/error_notification'
import BookingShowSkeleton from 'views/booking/components/show/skeleton'
import useBookingDisplay from 'views/booking/hooks/use_booking_display'
import BookingContext from 'views/booking/contexts/booking_context'

import { toCamelCase } from 'services/helpers/values'
import useOnce from 'services/hooks/use_once'

import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'

import { TEST_ID_BOOKING_STATUS_TAG, TEST_ID_BOOKING_TITLE } from 'tests/e2e/test_ids'

const PageBookingDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const { id: token } = useParams()

  const [booking, status] = useBooking(token)

  const { statusColor } = useBookingDisplay(token)

  useOnce(() => {
    dispatch(fetchBooking(token))
  })

  const contextValue = useMemo(
    () => ({
      token,
      exchangeActionsParams: {
        [BOOKING_EXCHANGE_ACTION_KEYS.VALIDATE_PROPOSAL]: token,
        [BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING]: token,
        [BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING]: token,
        [BOOKING_EXCHANGE_ACTION_KEYS.CANCEL_BOOKING]: token,
        [BOOKING_EXCHANGE_ACTION_KEYS.DECLINE_BOOKING]: token,
      },
    }),
    [token]
  )

  return (
    <Page>
      <BookingContext.Provider value={contextValue}>
        <StyledPageBookingDetail>
          <StyledPageBookingDetailsMain>
            <StyledPageBookingDetailsHeader>
              <StyledPageBookingDetailEditButtonBack
                as={StyledPageBookingDetailButtonBack}
                text={t('actions.backToList')}
                variant='transparent'
                icon='arrow_left'
                onClick={() => history.push('/bookings')}
              />

              {booking && status.ready && (
                <StyledPageBookingDetailsTitle>
                  <StyledPageBookingDetailsClientReference data-testid={TEST_ID_BOOKING_TITLE}>
                    {booking.clientReference}
                  </StyledPageBookingDetailsClientReference>
                  <StyledPageBookingTag
                    testId={TEST_ID_BOOKING_STATUS_TAG}
                    as={Tag}
                    text={t(`bookings.status.${toCamelCase(booking.status)}`)}
                    color={statusColor}
                  />
                </StyledPageBookingDetailsTitle>
              )}
              {!status.ready && (
                <StyledPageBookingDetailsTitle>Loading...</StyledPageBookingDetailsTitle>
              )}
            </StyledPageBookingDetailsHeader>

            {booking && status.ready && <BookingShow />}

            {!status.ready && (
              <StyledPageBookingDetailBoxShadow>
                <Placeholder
                  ready={status.ready}
                  customPlaceholder={<StyledBookingShowSkeleton as={BookingShowSkeleton} />}
                />
              </StyledPageBookingDetailBoxShadow>
            )}
            {status.rejected && <ErrorNotification />}
          </StyledPageBookingDetailsMain>
        </StyledPageBookingDetail>
      </BookingContext.Provider>
    </Page>
  )
}

PageBookingDetail.propTypes = {}

PageBookingDetail.defaultProps = {}

export default PageBookingDetail
