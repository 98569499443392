import useCurrentOrganization from 'views/iam/hooks/use_current_organization'
import { OrganizationDisplaySettings, OrganizationFeatures } from 'views/iam/types'

export type UseOrganizationCanResponse = {
  display: (name: keyof OrganizationDisplaySettings) => boolean
  features: (name: keyof OrganizationFeatures) => boolean
}

const useOrganizationCan = (): UseOrganizationCanResponse => {
  const organization = useCurrentOrganization()
  const display = (name: keyof OrganizationDisplaySettings) =>
    organization.displaySettings ? organization.displaySettings[name] : false
  const features = (name: keyof OrganizationFeatures) =>
    organization.features ? organization.features[name] : false
  return {
    display,
    features,
  }
}

export default useOrganizationCan
