import styled from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'

export const StyledUploadProgress = styled.div`
  width: 24px;
  height: 24px;
`

export const StyledUploadProgressSvg = styled.svg`
  fill: transparent;
  width: 100%;

  circle {
    fill: none;
    stroke-width: 5px;
  }
`

export const StyledUploadProgressSvgBg = styled.circle`
  stroke: ${({ theme }) => theme.clearGray};
`

export const StyledUploadProgressSvgProgress = styled.circle`
  stroke: ${({ theme }) => theme.primary};
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.5s ${easeQuartOut};
`
