import produce, { Draft } from 'immer'

import { ChangeEvent } from 'react'

import DateHelper from 'services/helpers/date_helper'
import { Converter, extractProperties, isPresent } from 'services/helpers/values'

import type { OnChangeFnProps, SelectValue } from 'components/select'

import type { TFilterType, TFilterValue, TDateRange, TTags } from 'services/hooks/use_filter'

const useFilterEvents = <T extends TFilterType>({
  type,
  value: internalValue,
}: {
  type: T
  value: TFilterValue<T>
}) => {
  // For select options, value can have additional properties which should be ignored by the filter.
  // Only properties from the interface should be extracted.
  const onSelect = ({ value }: OnChangeFnProps): SelectValue | null =>
    isPresent(value)
      ? extractProperties<SelectValue, SelectValue>({
          properties: ['value', 'label', 'createValue', 'type'],
          value,
        })
      : null

  // For select options, value can have additional properties which should be ignored by the filter.
  // Only properties from the interface should be extracted.
  const onMultiSelect = ({ value }: OnChangeFnProps<true>): SelectValue[] =>
    value.map((selectValue) =>
      extractProperties<SelectValue, SelectValue>({
        properties: ['value', 'label', 'createValue', 'type'],
        value: selectValue,
      })
    )

  const onCheck = ({ target: { checked } }: { target: { checked: boolean } }): boolean => checked
  const onRadio = ({ target: { value } }: { target: { value: string } }): string => value
  // react-datepicker returns Date instances, we store and manipulate iso strings
  const onDateRange = ({ value }: { value: [string, string] }): TDateRange => ({
    start: isPresent(value[0]) ? new DateHelper(value[0]).toISOString() : null,
    end: isPresent(value[1]) ? new DateHelper(value[1]).toISOString() : null,
  })
  const onDate = ({ value }: { value: string }): string | null =>
    isPresent(value[0]) ? new DateHelper(value[0]).toISOString() : null
  const onInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): string | number | null => e.target.value
  const onInputNumber = (e: ChangeEvent<HTMLInputElement>): number | null =>
    new Converter(e.target.value).toFloat()

  const onInputTags = ({ tags }: { tags: TTags }): TTags => tags
  const onCustom = (draft: Draft<TFilterValue<T>>) => produce(internalValue, draft)
  const onRange = (value: [number, number]) => value

  const onTypeChange = {
    select: onSelect,
    multiselect: onMultiSelect,
    checkbox: onCheck,
    radio: onRadio,
    dateRange: onDateRange,
    date: onDate,
    text: onInput,
    number: onInputNumber,
    tags: onInputTags,
    custom: onCustom,
    range: onRange,
  }[type]

  return {
    onSelect,
    onCheck,
    onDateRange,
    onInput,
    onTypeChange,
  }
}

export default useFilterEvents
