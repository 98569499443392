import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useUsers from 'features/users/hooks/use_users'

import UserSkeleton from 'features/users/components/user_list/skeleton'

import { User } from 'features/users/types/user'

import {
  DEFAULT_USERS_PER_PAGE,
  fetchUsers,
  fetchUserSettings,
} from 'features/users/services/user_service'

import S from 'features/users/components/user_list/style'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import usePagination from 'services/api/hooks/use_pagination'

import ErrorNotification from 'views/errors/error_notification'
import useUserCan from 'views/iam/hooks/use_user_can'

import PS, { PageActions } from 'components/page/style'
import Button from 'components/button'
import NotifMessage from 'components/notif_message'
import {
  TEST_ID_USER_INDEX_LEGEND_ACTIONS,
  TEST_ID_USER_INDEX_LEGEND_ACTIVATED,
  TEST_ID_USER_INDEX_LEGEND_COMPANY,
  TEST_ID_USER_INDEX_LEGEND_EMAIL,
  TEST_ID_USER_INDEX_LEGEND_FULLNAME,
  TEST_ID_USER_INDEX_LEGEND_ORGANIZATION,
  TEST_ID_USER_INDEX_LEGEND_ROLES,
  TEST_ID_USERS_LIST,
  TEST_ID_USERS_NEW_USER_BUTTON,
} from 'tests/e2e/test_ids'
import {
  ROLE_READ,
  USER_COMPANY_READ,
  USER_READ,
  USER_CREATE,
  USER_IMPORT,
} from 'constants/permissions'
import useBreakpoints from 'services/hooks/use_breakpoints'
import UserActivation from 'features/users/components/user_activation'
import { isPresent } from 'services/helpers/values'
import UserSettings from 'features/users/components/user_settings'
import useModal from 'components/modal/hooks/use_modal'
import CreateUser from 'features/users/components/create_user'
import UserItem from 'features/users/components/user_list/item'
import { addNotification } from 'views/notifications/slice'

interface UsersProps {
  search: string
  organizationIdFilter: number | undefined
}

const Users: FC<UsersProps> = ({ search, organizationIdFilter }) => {
  const dispatch = useAppDispatch()
  const { isLowerThanDesktop } = useBreakpoints()
  const { users, status, totalCount } = useUsers()
  const userCan = useUserCan()
  const { t } = useTranslation()
  const { setOpen: setOpenImportUsers } = useModal('importUsers')
  const { setOpen: setOpenNewUser } = useModal('newUser')
  const { setOpen: setOpenUserActivation } = useModal('userActivation')
  const { setOpen: setOpenUserIndexSettings } = useModal('userIndexSettings')
  const { page, next, reset } = usePagination()
  const [userSelected, setUserSelected] = useState<User | undefined>()

  const toggleUserActivation = (user: User) => {
    setUserSelected(user)
    setOpenUserActivation(true)
  }
  const openUserSettings = (user: User) => {
    dispatch(fetchUserSettings({ id: user.id }))
      .unwrap()
      .then(({ settings }) => {
        setUserSelected({ ...user, settings })
        setOpenUserIndexSettings(true)
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  useEffect(() => {
    reset()
  }, [reset, search, organizationIdFilter])

  useEffect(() => {
    dispatch(fetchUsers({ page, filtered: search, organizationId: organizationIdFilter }))
  }, [dispatch, page, search, organizationIdFilter])

  return (
    <>
      {isPresent(userSelected) && (
        <>
          <UserActivation
            id={userSelected.id}
            email={userSelected.email}
            hasSso={userSelected.hasSso || false}
            active={userSelected.active}
          />
          {isPresent(userSelected.settings) && (
            <UserSettings
              modalName='userIndexSettings'
              key={`user-settings-${userSelected.id}`}
              user={userSelected}
              settings={userSelected.settings}
              onClose={() => setUserSelected(undefined)}
              onSave={() => {
                dispatch(
                  fetchUsers({ page, filtered: search, organizationId: organizationIdFilter })
                )
              }}
            />
          )}
        </>
      )}
      <CreateUser
        onCreate={() =>
          dispatch(fetchUsers({ page, filtered: search, organizationId: organizationIdFilter }))
        }
      />

      <S.Content>
        <S.Header>
          <PS.PageHeader>
            <PS.PageTitle>
              {t('users.title')}{' '}
              {status.fulfilled && (
                <PS.PageSubtitle>
                  {t('users.count', {
                    count:
                      totalCount < page * DEFAULT_USERS_PER_PAGE
                        ? totalCount
                        : page * DEFAULT_USERS_PER_PAGE,
                    total: totalCount,
                  })}
                </PS.PageSubtitle>
              )}
            </PS.PageTitle>
            <PageActions>
              {userCan(USER_IMPORT) && (
                <Button
                  icon='upload'
                  rounded={isLowerThanDesktop}
                  variant='default'
                  text={isLowerThanDesktop ? null : t('users.actions.importUsers')}
                  onClick={() => {
                    setOpenImportUsers(true)
                  }}
                />
              )}
              {userCan(USER_CREATE) && (
                <Button
                  icon='plus_outline'
                  rounded={isLowerThanDesktop}
                  variant='highlight'
                  text={isLowerThanDesktop ? null : t('users.actions.newUser')}
                  onClick={() => setOpenNewUser(true)}
                  testId={TEST_ID_USERS_NEW_USER_BUTTON}
                />
              )}
            </PageActions>
          </PS.PageHeader>
          <S.Legend>
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_FULLNAME}>
              {t('users.legend.name')}
            </S.LegendItem>
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_EMAIL}>
              {t('users.legend.email')}
            </S.LegendItem>
            {userCan(USER_READ) && (
              <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ORGANIZATION}>
                {t('users.legend.organization')}
              </S.LegendItem>
            )}
            {userCan(USER_COMPANY_READ) && (
              <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_COMPANY}>
                {t('users.legend.company')}
              </S.LegendItem>
            )}
            {userCan(ROLE_READ) && (
              <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ROLES}>
                {t('users.legend.roles')}
              </S.LegendItem>
            )}
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ACTIVATED} $centered>
              {t('users.legend.activated')}
            </S.LegendItem>
            <S.LegendItem data-testid={TEST_ID_USER_INDEX_LEGEND_ACTIONS} $small $centered>
              {t('users.legend.actions')}
            </S.LegendItem>
          </S.Legend>
          {status.fulfilled && totalCount === 0 ? (
            <NotifMessage type='info' text={t('users.noUsers')} />
          ) : null}
        </S.Header>
        <S.Users data-testid={TEST_ID_USERS_LIST}>
          {users.map((user, index) => (
            <UserItem
              user={user}
              index={index}
              toggleUserActivation={toggleUserActivation}
              openUserSettings={openUserSettings}
              key={user.id}
            />
          ))}
          {status.pending && (
            <li>
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
              <UserSkeleton />
            </li>
          )}
          {status.rejected && <ErrorNotification />}
          {status.fulfilled &&
          totalCount &&
          users &&
          users.length > 0 &&
          users.length < totalCount ? (
            <li>
              <Button text={t('actions.loadMore')} onClick={() => next()} />
            </li>
          ) : null}
        </S.Users>
      </S.Content>
    </>
  )
}

export default Users
