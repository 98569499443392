import { useCallback } from 'react'

import {
  EDITABLE_BY_FORWARDER_FIELD_NAMES,
  EDITABLE_BY_SHIPPER_FIELD_NAMES,
  INFORMATIVE_FIELD_NAMES,
} from 'constants/bookings'
import {
  WITH_BOOKING_FORWARDER_CAN_PATCH_PROPOSAL_DATES,
  WITH_BOOKING_OLD_WORKFLOW,
} from 'constants/organization_features'
import { isPresent } from 'services/helpers/values'

import useBookingRole from 'views/booking/hooks/use_booking_role'
import useBookingStatus from 'views/booking/hooks/use_booking_status'
import useCurrentCompany from 'views/iam/hooks/use_current_company'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import type { Booking, BookingFieldNames } from 'views/booking/slices/types'

interface IDisabledFieldProps {
  booking?: Partial<Booking>
  isEdit: boolean
  routingWithoutPreCarriage: boolean
  routingWithoutOnCarriage: boolean
  rateConfirmation: boolean
  defaultForwarderId?: number
}

interface IDisabledField {
  isFieldDisabled: (fieldName: BookingFieldNames) => boolean
}

const useDisabledField = ({
  booking,
  isEdit,
  routingWithoutPreCarriage,
  routingWithoutOnCarriage,
  rateConfirmation,
  defaultForwarderId,
}: IDisabledFieldProps): IDisabledField => {
  const { company: currentCompany, ownsCapacity } = useCurrentCompany()
  const {
    organization: { forwarderId },
  } = useCurrentUser()

  const { features } = useOrganizationCan()

  const { status } = booking || {}
  const { doesStatusAllowEditOrCancel, isStatusConfirmed } = useBookingStatus(status)
  const { isForwarder, isShipper } = useBookingRole()

  const orgaWithLegacyWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)

  const editableByShipper = useCallback(
    (fieldName: string) =>
      (doesStatusAllowEditOrCancel &&
        EDITABLE_BY_SHIPPER_FIELD_NAMES.some((name) => name === fieldName)) ||
      (isStatusConfirmed && INFORMATIVE_FIELD_NAMES.some((name) => name === fieldName)),
    [doesStatusAllowEditOrCancel, isStatusConfirmed]
  )
  const editableByForwarder = useCallback(
    (fieldName: string) => EDITABLE_BY_FORWARDER_FIELD_NAMES.some((name) => name === fieldName),
    []
  )
  const isFieldDisabledOnEdit = useCallback(
    (fieldName: string) =>
      (isShipper && !editableByShipper(fieldName)) ||
      (isForwarder && !editableByForwarder(fieldName)),
    [isShipper, editableByShipper, isForwarder, editableByForwarder]
  )

  // Routing particular cases for old workflow

  const forwarderCanEditDatesOnConfirmed = features(WITH_BOOKING_FORWARDER_CAN_PATCH_PROPOSAL_DATES)

  const routingDatesDisabled =
    orgaWithLegacyWorkflow &&
    ((isShipper && isStatusConfirmed) ||
      (isForwarder && isStatusConfirmed && !forwarderCanEditDatesOnConfirmed))

  const ptdDisabled = routingDatesDisabled || routingWithoutPreCarriage
  const ptaDisabled = routingDatesDisabled || routingWithoutOnCarriage

  const preCarriageDisabled = orgaWithLegacyWorkflow && (isStatusConfirmed || isForwarder)
  const onCarriageDisabled = orgaWithLegacyWorkflow && (isStatusConfirmed || isForwarder)

  const polDisabled =
    orgaWithLegacyWorkflow && routingWithoutPreCarriage && (isStatusConfirmed || isForwarder)
  const podDisabled =
    orgaWithLegacyWorkflow && routingWithoutOnCarriage && (isStatusConfirmed || isForwarder)

  const polDatesDisabled = routingDatesDisabled && routingWithoutPreCarriage
  const podDatesDisabled = routingDatesDisabled && routingWithoutOnCarriage

  const rateDisabled = orgaWithLegacyWorkflow && (!rateConfirmation || isStatusConfirmed)

  const isFieldDisabled = useCallback(
    (fieldName: BookingFieldNames) =>
      ({
        clientReference: isEdit && isFieldDisabledOnEdit(fieldName),
        clientBookingNumber: isEdit && isFieldDisabledOnEdit(fieldName),
        forwarderReference: !isEdit || isFieldDisabledOnEdit(fieldName),
        customReferences: isEdit && isFieldDisabledOnEdit(fieldName),
        shipper: (!isEdit && isPresent(currentCompany?.id) && ownsCapacity('shipper')) || isEdit,
        forwarder:
          (!isEdit && isPresent(forwarderId) && defaultForwarderId === forwarderId) || isEdit,
        consignor: isEdit && isFieldDisabledOnEdit(fieldName),
        consignee: isEdit && isFieldDisabledOnEdit(fieldName),
        incoterms: isEdit && isFieldDisabledOnEdit(fieldName),
        incotermsLocation: isEdit && isFieldDisabledOnEdit(fieldName),
        transportType: isEdit,
        preCarriage: isEdit && (preCarriageDisabled || isFieldDisabledOnEdit(fieldName)),
        ptd: isEdit && (ptdDisabled || isFieldDisabledOnEdit(fieldName)),
        pol: isEdit && (polDisabled || isFieldDisabledOnEdit(fieldName)),
        polPta: isEdit && (polDatesDisabled || isFieldDisabledOnEdit(fieldName)),
        polPtd: isEdit && (polDatesDisabled || isFieldDisabledOnEdit(fieldName)),
        transshipments: isEdit && isFieldDisabledOnEdit(fieldName),
        pod: isEdit && (podDisabled || isFieldDisabledOnEdit(fieldName)),
        podPta: isEdit && (podDatesDisabled || isFieldDisabledOnEdit(fieldName)),
        podPtd: isEdit && (podDatesDisabled || isFieldDisabledOnEdit(fieldName)),
        onCarriage: isEdit && (onCarriageDisabled || isFieldDisabledOnEdit(fieldName)),
        pta: isEdit && (ptaDisabled || isFieldDisabledOnEdit(fieldName)),
        carrier: isEdit && isFieldDisabledOnEdit(fieldName),
        bookingNumber: isEdit && isFieldDisabledOnEdit(fieldName),
        masterBl: isEdit && isFieldDisabledOnEdit(fieldName),
        vessels: isEdit && isFieldDisabledOnEdit(fieldName),
        voyageNumbers: isEdit && isFieldDisabledOnEdit(fieldName),
        flightNumbers: isEdit && isFieldDisabledOnEdit(fieldName),
        shipmentAttributes: isEdit && isFieldDisabledOnEdit(fieldName),
        vgmCutOffDate: isEdit && isFieldDisabledOnEdit(fieldName),
        vesselCutOffDate: isEdit && isFieldDisabledOnEdit(fieldName),
        customFields: isEdit && isFieldDisabledOnEdit(fieldName),
        rateConfirmation: isEdit && isFieldDisabledOnEdit(fieldName),
        keyContacts: isEdit && isFieldDisabledOnEdit(fieldName),
        comments: isEdit && isFieldDisabledOnEdit(fieldName),
        forwarderComment: !isEdit || isFieldDisabledOnEdit(fieldName),
        rateCurrency: !isEdit || rateDisabled || isFieldDisabledOnEdit(fieldName),
        rateAmount: !isEdit || rateDisabled || isFieldDisabledOnEdit(fieldName),
        merchandise: isEdit && isFieldDisabledOnEdit(fieldName),
      }[fieldName]),
    [
      isEdit,
      isFieldDisabledOnEdit,
      currentCompany,
      ownsCapacity,
      forwarderId,
      defaultForwarderId,
      polDatesDisabled,
      podDatesDisabled,
      polDisabled,
      podDisabled,
      preCarriageDisabled,
      onCarriageDisabled,
      ptdDisabled,
      ptaDisabled,
      rateDisabled,
    ]
  )

  return { isFieldDisabled }
}

export default useDisabledField
