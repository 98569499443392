import React, { FC, useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { WITH_FRONT_BOOKING_CUT_OFF_DATES } from 'constants/organization_features'
import { TRANSPORT_MODES } from 'constants/bookings'

import { isAnyArray, isPresent } from 'services/helpers/values'
import DateHelper from 'services/helpers/date_helper'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import ValueWithFallback from 'views/booking/components/value_with_fallback'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import {
  TEST_ID_BOOKING_SHOW_VESSEL_CUT_OFF_DATE,
  TEST_ID_BOOKING_SHOW_VGM_CUT_OFF_DATE,
} from 'tests/e2e/test_ids'

const InformationMiscellaneous: FC = () => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { t } = useTranslation()
  const { features } = useOrganizationCan()
  const { vgmCutOffDate, vesselCutOffDate, customFields, transportType } = booking || {
    customFields: [],
  }

  const isCurrentTransportTypeSea = useMemo(
    () => transportType === TRANSPORT_MODES.SEA,
    [transportType]
  )
  const displayCutOffDates = useMemo(
    () => features(WITH_FRONT_BOOKING_CUT_OFF_DATES) && isCurrentTransportTypeSea,
    [features, isCurrentTransportTypeSea]
  )
  const displayCustomFields = isAnyArray(customFields)
  const displayMiscellaneous = displayCutOffDates || displayCustomFields

  const formatDate = (date: string | undefined) =>
    isPresent(date) ? new DateHelper(date).toLocale() : null

  return (
    <>
      {displayMiscellaneous && (
        <StyledFormEditGroup data-testid='custom-fields'>
          <StyledFormEditGroupTitle>{t('bookings.miscellaneous.title')}</StyledFormEditGroupTitle>
          <StyledFormEditGroupItems>
            {displayCutOffDates && (
              <>
                <StyledFormEditGroupItem>
                  <StyledFormEditGroupLabel>
                    {t('bookings.miscellaneous.vgmCutOffDate')}
                  </StyledFormEditGroupLabel>
                  <StyledFormEditGroupValue>
                    <ValueWithFallback
                      value={formatDate(vgmCutOffDate)}
                      testId={TEST_ID_BOOKING_SHOW_VGM_CUT_OFF_DATE}
                    />
                  </StyledFormEditGroupValue>
                </StyledFormEditGroupItem>
                <StyledFormEditGroupItem>
                  <StyledFormEditGroupLabel>
                    {t('bookings.miscellaneous.vesselCutOffDate')}
                  </StyledFormEditGroupLabel>
                  <StyledFormEditGroupValue>
                    <ValueWithFallback
                      value={formatDate(vesselCutOffDate)}
                      testId={TEST_ID_BOOKING_SHOW_VESSEL_CUT_OFF_DATE}
                    />
                  </StyledFormEditGroupValue>
                </StyledFormEditGroupItem>
              </>
            )}
            {displayCustomFields &&
              customFields.map(({ key, value }) => (
                <StyledFormEditGroupItem>
                  <StyledFormEditGroupLabel>{key}</StyledFormEditGroupLabel>
                  <StyledFormEditGroupValue>{value}</StyledFormEditGroupValue>
                </StyledFormEditGroupItem>
              ))}
          </StyledFormEditGroupItems>
        </StyledFormEditGroup>
      )}
    </>
  )
}

export default InformationMiscellaneous
