import React, { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSelector } from 'react-redux'

import { SHIPMENT_VIEW_CONDENSED, SHIPMENT_VIEW_LARGE } from 'constants/shipments'

import useBreakpoints from 'services/hooks/use_breakpoints'
import useTracker from 'services/analytics/hooks/use_tracker'

import Icon from 'components/icon'
import Select, { OnChangeFnProps, Tvalue } from 'components/select'
import Button from 'components/button'
import SwitchInput from 'components/switch_input'

import {
  StyledShipmentsView,
  StyledShipmentsViewButton,
  StyledShipmentsSelect,
  StyledShipmentsSwitch,
  StyledShipmentsBarSection,
  StyledShipmentsExport,
  StyledShipmentsBarIcons,
  StyledShipmentsSortButton,
  StyledShipmentsBarTotalCount,
} from 'views/shipments/style'
import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'
import useUserCan from 'views/iam/hooks/use_user_can'
import { ORDER_EXPORT } from 'constants/permissions'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_SHIPMENTS_CSV_EXPORT } from 'constants/organization_features'
import ExportModal from 'views/shipments/components/export_modal'
import {
  DIRECTION_ASCENDING,
  DIRECTION_DESCENDING,
  SORT_BY_ARRIVED_AT,
  SORT_BY_DEPARTURE_AT,
  SORT_BY_CREATED_AT,
  SORT_BY_ETA_DIFFERENCE,
  saveDirection,
  saveSortBy,
  saveFilterByFavorites,
  selectSortBy,
  selectDirection,
  selectFilterByFavorites,
  selectShipmentsTotalCount,
  Directions,
} from 'views/shipments/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { RootState } from 'services/store/store'
import useModal from 'components/modal/hooks/use_modal'

const ShipmentBar: FC = () => {
  const { isMobile } = useBreakpoints()
  const { view, setView } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])
  const userCan = useUserCan()
  const { track } = useTracker()
  const { setOpen: setOpenShipmentsExport } = useModal('shipmentsExport')
  const { setOpen: setOpenSettings } = useModal('settings')
  const dispatch = useAppDispatch()
  const sortBy = useSelector((state: RootState) => selectSortBy(state))
  const direction = useSelector((state: RootState) => selectDirection(state))
  const filterByFavorites = useSelector((state: RootState) => selectFilterByFavorites(state))
  const totalCount = useSelector((state: RootState) => selectShipmentsTotalCount(state))

  const setDirection = (value: Directions) => {
    dispatch(saveDirection(value))
  }
  const setSortBy = (value: Tvalue) => {
    dispatch(saveSortBy(value))
  }
  const setFilterByFavorites = (value: boolean) => {
    dispatch(saveFilterByFavorites(value))
  }

  const { features } = useOrganizationCan()
  const { t } = useTranslation()
  const SORT_BY_OPTIONS = [
    {
      value: SORT_BY_CREATED_AT,
      label: t('shipments.sort.createdAt'),
    },
    {
      value: SORT_BY_DEPARTURE_AT,
      label: t('shipments.sort.departureAt'),
    },
    {
      value: SORT_BY_ARRIVED_AT,
      label: t('shipments.sort.arrivedAt'),
    },
    {
      value: SORT_BY_ETA_DIFFERENCE,
      label: t('shipments.sort.etaDiff'),
    },
  ]

  return (
    <>
      <ExportModal />
      <StyledShipmentsBarSection>
        <StyledShipmentsSwitch
          as={SwitchInput}
          leftLabel={t('shipments.all')}
          name='switch_favorite'
          rightLabel={isMobile ? 'Fav.' : t('shipments.favorite')}
          onClick={(checked: boolean) => {
            setFilterByFavorites(checked)
            if (checked) {
              track('Shipment list / filter by favourites')
            }
          }}
          variant='outline'
          rightOptionChecked={filterByFavorites}
        />

        {userCan(ORDER_EXPORT) && features(WITH_SHIPMENTS_CSV_EXPORT) && (
          <StyledShipmentsExport
            as={Button}
            text='Export .csv'
            icon='filesheet'
            variant='transparent'
            onClick={() => {
              setOpenShipmentsExport(true)
            }}
          />
        )}
      </StyledShipmentsBarSection>

      <StyledShipmentsBarTotalCount>
        {totalCount} {t('shipments.shipment', { count: totalCount })}
      </StyledShipmentsBarTotalCount>

      <StyledShipmentsBarSection>
        <Button
          variant='smallIcon'
          icon='cog'
          onClick={() => {
            setOpenSettings(true)
          }}
        />
        <StyledShipmentsSelect
          as={Select}
          label={`${t('actions.sortBy')} :`}
          variant='text-control'
          options={SORT_BY_OPTIONS}
          onChange={({ value }: OnChangeFnProps) => {
            let type = 'unknown'
            if (value.value === 'createdAt') {
              type = 'Creation time'
            } else if (value.value === 'transportationDate') {
              type = 'Arrival time'
            } else if (value.value === 'etaDiff') {
              type = 'ETA difference'
            }
            setSortBy(value.value)
            track('Shipment list / sort', { type })
          }}
          value={SORT_BY_OPTIONS.find((s) => s.value === sortBy)}
          isClearable={false}
          isSearchable={false}
        />
        <StyledShipmentsBarIcons>
          <StyledShipmentsSortButton
            onClick={() => {
              setDirection(
                direction === DIRECTION_ASCENDING ? DIRECTION_DESCENDING : DIRECTION_ASCENDING
              )
              track('Shipment list / direction')
            }}
          >
            <Icon
              name={direction === DIRECTION_ASCENDING ? 'sort_amount_down' : 'sort_amount_up'}
            />
          </StyledShipmentsSortButton>

          <StyledShipmentsView>
            <StyledShipmentsViewButton
              $active={isCondensed}
              onClick={() => {
                setView(SHIPMENT_VIEW_CONDENSED)
                track('Shipment list / display', { view: SHIPMENT_VIEW_CONDENSED })
              }}
            >
              <Icon name='view_condensed' />
            </StyledShipmentsViewButton>
            <StyledShipmentsViewButton
              $active={!isCondensed}
              onClick={() => {
                setView(SHIPMENT_VIEW_LARGE)
                track('Shipment list / display', { view: SHIPMENT_VIEW_LARGE })
              }}
            >
              <Icon name='view_bloc' />
            </StyledShipmentsViewButton>
          </StyledShipmentsView>
        </StyledShipmentsBarIcons>
      </StyledShipmentsBarSection>
    </>
  )
}

export default ShipmentBar
