import React, { MouseEvent } from 'react'

import Button from 'components/button'
import {
  StyledWidgetEdition,
  StyledWidgetEdit,
  StyledWidgetDelete,
  StyledWidgetWrapper,
} from 'features/custom_kpis/components/widget/style'

import { TEST_ID_EDIT_WIDGET_BUTTON, TEST_ID_DELETE_WIDGET_BUTTON } from 'tests/e2e/test_ids'

export interface WidgetProps {
  children: React.ReactNode
  onEdit: (e: MouseEvent<HTMLElement>) => void
  onDelete: (e: MouseEvent<HTMLElement>) => void
  onClick: (e: MouseEvent<HTMLElement>) => void
}

const Widget: React.FC<WidgetProps> = ({ children, onEdit, onDelete, onClick }) => (
  <StyledWidgetWrapper onClick={onClick}>
    <StyledWidgetEdition>
      <StyledWidgetEdit
        as={Button}
        testId={TEST_ID_EDIT_WIDGET_BUTTON}
        icon='pencil'
        variant='transparent'
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          onEdit(e)
        }}
      />

      <StyledWidgetDelete
        as={Button}
        testId={TEST_ID_DELETE_WIDGET_BUTTON}
        icon='trash'
        variant='transparent'
        onClick={(e: MouseEvent<HTMLElement>) => {
          e.stopPropagation()
          onDelete(e)
        }}
      />
    </StyledWidgetEdition>
    {children}
  </StyledWidgetWrapper>
)

export default Widget
