import React from 'react'
import PropTypes from 'prop-types'

import { anyChildren } from 'services/helpers/prop_types'

const Placeholder = ({ ready, customPlaceholder, children }) =>
  ready ? children || <></> : customPlaceholder

Placeholder.propTypes = {
  /** Ready */
  ready: PropTypes.bool,
  /** CustomPlaceholder */
  customPlaceholder: anyChildren,
  /** Children */
  children: anyChildren,
}

Placeholder.defaultProps = {
  ready: false,
  customPlaceholder: undefined,
  children: undefined,
}
export default Placeholder
