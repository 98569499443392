import React, { FC, useMemo, useState } from 'react'

import { CoordinateModel } from 'components/map/models'

import useOnce from 'services/hooks/use_once'
import { ExternalClient } from 'services/api/clients'
import { onExternalError } from 'services/api/error'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import TrackerContext from 'services/analytics/contexts/tracker'
import PageTracker from 'services/analytics/page_tracker'

export const TrackerProvider: FC = ({ children }) => {
  const [isInit, setIsInit] = useState(false)
  const [userCoordinate, setUserCoordinate] = useState<CoordinateModel | undefined>()
  const dispatch = useAppDispatch()

  useOnce(() => {
    ExternalClient.get('https://api.ipregistry.co/?key=tryout')
      .then((d) => d.data)
      .then(({ location: { latitude, longitude } }) =>
        setUserCoordinate(new CoordinateModel(latitude, longitude))
      )
      .catch((error) => onExternalError({ axiosError: error, dispatch }))
  })

  const contextValue = useMemo(
    () => ({ isInit, setIsInit, userCoordinate }),
    [isInit, setIsInit, userCoordinate]
  )

  return (
    <TrackerContext.Provider value={contextValue}>
      <PageTracker>{children}</PageTracker>
    </TrackerContext.Provider>
  )
}

export default TrackerProvider
