import styled, { css } from 'styled-components'

import { ViewSwitcherVariant } from 'components/view_switcher/types'

import { textSmallDescription } from 'styles/utils/texts'
import { ReactComponent as ArrowsSwitch } from 'assets/icons/arrows-switch.svg'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledText = styled.p`
  ${textSmallDescription};
`

export const StyledSwitchIcon = styled(ArrowsSwitch)`
  width: 18px;
  height: 16px;
  fill: ${({ theme }) => theme.primary};
`

export const StyledViewSwitcher = styled.button<{ $variant: ViewSwitcherVariant }>`
  width: 100%;
  padding: 12px 18px 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.primary10};
  color: ${({ theme }) => theme.primary};
  transition: background 0.5s ${easeQuartOut};

  ${(props) =>
    props.$variant === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.warn};
      color: ${({ theme }) => theme.warn5};
      ${StyledSwitchIcon} {
        fill: white;
      }
    `}

  &:hover {
    background-color: ${({ theme }) => theme.primary};
    color: white;

    ${StyledSwitchIcon} {
      fill: white;
    }

    ${(props) =>
      props.$variant === 'warning' &&
      css`
        background-color: ${({ theme }) => theme.warn5};
        color: ${({ theme }) => theme.warn};
        ${StyledSwitchIcon} {
          fill: ${({ theme }) => theme.warn};
        }
      `}
  }
`
