import { isInputEmpty } from 'services/helpers/values'

const useBookingValidations = () => {
  const validateFieldRegex = (regex: RegExp) => (value?: string) =>
    !isInputEmpty(value) && regex.test(value)
  const validateContainerNumber = validateFieldRegex(/^[a-zA-Z]{4}[0-9]{7}$/)
  const validateMasterAwb = validateFieldRegex(/^[0-9]{3}-[0-9]{8}$/)

  return {
    validateContainerNumber,
    validateMasterAwb,
  }
}

export default useBookingValidations
