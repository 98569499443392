import React, { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'

import Form from 'components/form'
import Grid from 'components/grid'
import Select from 'components/select'
import Flag from 'components/flag'
import {
  StyledModalProfileFormButton,
  StyledModalProfileFormError,
} from 'components/modal_profile/style'
import Button from 'components/button'
import { DecoratedUser } from 'views/iam/hooks/use_current_user'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { updateSettings } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import {
  LanguageData,
  languageSchema,
} from 'components/modal_profile/components/language_form/types'
import { LOCALES, LOCALES_TO_FLAGS_MAPPING, LOCALES_TO_NAMES_MAPPING } from 'constants/locales'
import { sortBy } from 'services/helpers/values'

interface LanguageFormProps {
  user: DecoratedUser
}

const LanguageForm: React.FC<LanguageFormProps> = ({ user }) => {
  const [apiError, setApiError] = useState<string | null>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<LanguageData>({
    resolver: yupResolver(languageSchema),
    defaultValues: {
      language: {
        value: user.profile.settings.locale,
        label: LOCALES_TO_NAMES_MAPPING[user.profile.settings.locale],
        slot: {
          beforeLabel: (
            <Flag countryCode={LOCALES_TO_FLAGS_MAPPING[user.profile.settings.locale]} />
          ),
        },
      },
    },
  })

  const submit = (data: LanguageData) => {
    const newSettings = { ...user.profile.settings, locale: data.language.value }
    dispatch(updateSettings({ id: user.profile.id, settings: newSettings }))
      .unwrap()
      .then(() => {
        setApiError(null)
        dispatch(
          addNotification({
            type: 'success',
            title: `${t('profile.tabs.locale.form.updateLanguage', {
              lng: data.language.value,
            })}`,
            text: `${t('profile.tabs.locale.form.languageUpdated', {
              lng: data.language.value,
            })}`,
          })
        )
      })
      .catch(({ message }) => setApiError(message))
  }

  const languageField = watch('language')

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Form.Group>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Controller
              control={control}
              name='language'
              render={({ field }) => (
                <Select
                  options={sortBy(
                    LOCALES.map((locale) => ({
                      value: locale,
                      label: LOCALES_TO_NAMES_MAPPING[locale],
                      slot: {
                        beforeLabel: <Flag countryCode={LOCALES_TO_FLAGS_MAPPING[locale]} />,
                      },
                    })).filter((option) => option.value !== languageField.value),
                    [(option) => option.label]
                  )}
                  isClearable={false}
                  isSearchable={false}
                  name={field.name}
                  value={field.value}
                  error={errors.language?.message ? t(errors.language?.message) : undefined}
                  onChange={({ value }) => field.onChange(value)}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Form.Group>
      <StyledModalProfileFormError>{apiError}</StyledModalProfileFormError>
      <StyledModalProfileFormButton>
        <Button padded text={t('actions.save')} variant='highlight' type='submit' />
      </StyledModalProfileFormButton>
    </Form>
  )
}

export default LanguageForm
