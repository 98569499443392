import { useState } from 'react'

import { BREAKPOINTS } from 'styles/utils/breakpoints'
import useWindowResize from 'services/hooks/use_window_resize'

const useBreakpoints = () => {
  const [breakpoint, setBreakPoint] = useState('')

  useWindowResize((window) => {
    if (window.innerWidth < BREAKPOINTS.tablet) {
      setBreakPoint('mobile')
    }
    if (window.innerWidth >= BREAKPOINTS.tablet && window.innerWidth < BREAKPOINTS.desktop) {
      setBreakPoint('tablet')
    }
    if (window.innerWidth >= BREAKPOINTS.desktop && window.innerWidth < BREAKPOINTS.desktopLarge) {
      setBreakPoint('desktop')
    }
    if (window.innerWidth >= BREAKPOINTS.desktopLarge) {
      setBreakPoint('desktopLarge')
    }
  }, true)

  const isMobile = breakpoint === 'mobile'
  const isTablet = breakpoint === 'tablet'
  const isDesktop = breakpoint === 'desktop'
  const isDesktopLarge = breakpoint === 'desktopLarge'

  return {
    breakpoint,
    isMobile,
    isTablet,
    isDesktop,
    isDesktopLarge,
    isLowerThanDesktop: isMobile || isTablet,
    isDesktopOrUpper: isDesktop || isDesktopLarge,
  }
}

export default useBreakpoints
