import styled, { css } from 'styled-components'

import { Int2Range, Int4Range, Int5Range } from 'components/block_container/types'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { buttonSmall } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'
import { tablet, desktop } from 'styles/utils/breakpoints'
import { StyledFormInsert } from 'components/form/style'
import { easeQuartOut } from 'styles/utils/ease'
import { StyledInputCheckbox } from 'components/input_checkbox/style'
import { StyledInput } from 'components/input/style'
import { StyledTextarea } from 'components/textarea/style'
import { StyledSelect } from 'components/select/style'
import { StyledItem } from 'components/notif_message/style'
import { StyledSwitchInput } from 'components/switch_input/style'
import { StyledCheckboxPicto } from 'components/checkbox_picto/style'
import { StyledDatePicker } from 'components/input_datepicker/style'

export const StyledBlockContainerWrapper = styled.div<{ $centered: boolean }>`
  display: flex;
  flex-wrap: wrap;
  ${StyledFormInsert} {
    padding: 15px 10px;
    ${StyledInputCheckbox}, ${StyledInput}, ${StyledSelect}, ${StyledDatePicker}, ${StyledCheckboxPicto}, ${StyledTextarea}, ${StyledSwitchInput}, ${StyledItem} {
      margin-bottom: 0;
    }
    ${(props) =>
      props.$centered &&
      css`
        width: 55%;
      `}
  }
  ${(props) =>
    props.$centered &&
    css`
      margin-top: 20px;
      margin-bottom: 20px;
      justify-content: center;
    `}
`

export const StyledDeleteButton = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
  display: none;
  &:hover {
    cursor: pointer;
  }

  @media ${desktop.down} {
    display: block;
  }
  ${StyledIcon} {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.mediumGray};
  }
`

export const StyledBlockContainerItem = styled.div<{
  $columns: Int5Range
  $size: Int4Range
  $centered: boolean
}>`
  padding-right: 18px;
  &:hover {
    ${StyledDeleteButton} {
      display: block;
    }
  }
  @media ${tablet.down} {
    padding-right: 0;
    width: 100%;
  }

  ${(props) =>
    props.$columns &&
    props.$size &&
    css`
      width: calc((100% / ${props.$columns}) * ${props.$size});
    `}

  ${(props) =>
    props.$centered &&
    css`
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    `}
`
export const StyledBlockContainerAddBlock = styled.div<{
  $columns: Int5Range
  $btnSize: Int2Range
}>`
  position: relative;
  margin-bottom: 15px;
  margin-top: 7px;
  padding-right: 18px;
  height: 60px;
  @media ${tablet.down} {
    padding-right: 0;
    width: 100%;
  }

  ${(props) =>
    props.$columns &&
    props.$btnSize &&
    css`
      width: calc((100% / ${props.$columns}) * ${props.$btnSize});
    `}
  ${StyledIcon} {
    width: 18px;
    height: 18px;
    transition: fill 0.4s ${easeQuartOut};
    fill: ${({ theme }) => theme.mediumGray};
    margin-right: 7px;
  }
`
export const StyledButtonText = styled.span`
  ${buttonSmall}
  color: ${({ theme }) => theme.textLight};
  transition: color 0.4s ${easeQuartOut};
`

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 10px 50px;
  ${borderRadiusMedium}
  border: 1px dashed ${({ theme }) => theme.mediumGray};
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.primary};
    box-shadow: none;
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
    ${StyledButtonText} {
      color: ${({ theme }) => theme.primary};
    }
  }
`
export const StyledEmptyIcon = styled.div`
  width: 12px;
  height: 12px;
`
