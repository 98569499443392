import React from 'react'

import PropTypes from 'prop-types'

import { StyledTitle } from 'components/block_list/style'

const Title = ({ children }: { children: string }) => <StyledTitle>{children}</StyledTitle>

Title.propTypes = {
  children: PropTypes.string,
}
Title.defaultProps = {
  children: undefined,
}
export default Title
