/* eslint-disable import/prefer-default-export */

import { uuid4 } from 'services/helpers/uuid'

export interface Error {
  name?: string
  message?: string
  code?: number
  stack?: string
}
export class ErrorModel {
  error: Error

  internalIsErrorModel: boolean

  constructor({ name, message, code, stack }: Error) {
    this.error = { name, message, code, stack }
    this.internalIsErrorModel = true
  }

  get name() {
    return this.error.name
  }

  get message() {
    return this.error.message
  }

  get code() {
    return this.error.code
  }

  get stack() {
    return this.error.stack
  }

  get isClient() {
    return this.error.code !== 500
  }

  static isErrorModel(error: unknown): error is ErrorModel {
    if (typeof error === 'object' && error !== null && 'internalIsErrorModel' in error) {
      return error.internalIsErrorModel === true
    }
    return false
  }

  toRecord = () => ({
    id: uuid4(),
    ...this.error,
  })

  toPayload = () => this.error
}
