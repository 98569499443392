import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  sendNewCredentials,
  sendSsoWelcomeEmail,
  toggleActivation,
} from 'features/users/services/user_service'

import { selectToggleActivationStatus } from 'features/users/store/slice'

import Modal from 'components/modal'
import Button from 'components/button'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import InputCheckbox from 'components/input_checkbox'
import S from 'features/users/components/user_activation/style'
import useStatus from 'services/api/hooks/use_status'
import useShallowSelector from 'services/hooks/use_shallow_selector'
import useModal from 'components/modal/hooks/use_modal'

interface ModalUserActivationProps {
  id: number
  email: string
  hasSso: boolean
  active: boolean
}

type ActivationMailType = 'none' | 'welcome' | 'sso'

const UserActivation: React.FC<ModalUserActivationProps> = ({
  id,
  email,
  hasSso = false,
  active = false,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setOpen } = useModal('userActivation')
  const activeKey = active ? 'deactivation' : 'activation'
  const [mailType, setMailType] = useState<ActivationMailType>('none')
  const toggleActivationStatus = useStatus(useShallowSelector(selectToggleActivationStatus))

  const deactivateUser = () => {
    dispatch(toggleActivation({ id }))
      .unwrap()
      .then(() => {
        setOpen(false)
        dispatch(
          addNotification({
            type: 'success',
            title: t('users.deactivation.title'),
            text: t('users.deactivation.message.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('users.deactivation.title'),
            text: t('users.deactivation.message.failure'),
          })
        )
      })
  }
  const activateUser = () => {
    dispatch(toggleActivation({ id }))
      .unwrap()
      .then(() => {
        if ((mailType === 'welcome' && !hasSso) || mailType === 'sso') {
          dispatch(sendNewCredentials({ id }))
        }

        if (mailType === 'welcome' && hasSso) {
          dispatch(sendSsoWelcomeEmail({ id }))
        }

        close()

        dispatch(
          addNotification({
            type: 'success',
            title: t('users.activation.title'),
            text: t('users.activation.message.success'),
          })
        )
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('users.activation.title'),
            text: t('users.activation.message.failure'),
          })
        )
      })
  }

  const close = () => {
    setMailType('none') // Modals state are not reset on opening so we need to make sure the default value is set on close
    setOpen(false)
  }

  return (
    <Modal modalName='userActivation' position='center'>
      <Modal.Header>{t(`users.${activeKey}.title`)}</Modal.Header>
      <Modal.Content>
        <S.UserActivationWarning>
          <p>{t(`users.${activeKey}.warning`)}</p>
          <p>{email} ?</p>
        </S.UserActivationWarning>
        {!active && (
          <div>
            <InputCheckbox
              id='sendMail'
              name='sendMailCheckBox'
              text={
                hasSso ? t('users.activation.ssoWelcome') : t('users.activation.newCredentials')
              }
              onChange={(value) => setMailType(value.target.checked ? 'welcome' : 'none')}
              checked={mailType === 'welcome'}
            />
            {hasSso && (
              <>
                <S.UserSsoNoCredentialsWarning
                  type='alert'
                  text={t('users.activation.ssoNoCredentials')}
                />
                <InputCheckbox
                  id='ignoreSso'
                  name='ignoreSsoCheckBox'
                  text={t('users.activation.ignoreSso')}
                  onChange={(value) => setMailType(value.target.checked ? 'sso' : 'none')}
                  checked={mailType === 'sso'}
                />
              </>
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={() => setOpen(false)} variant='clear' text={t('users.confirmation.no')} />
        <Button
          variant='highlight'
          text={t('users.confirmation.yes')}
          onClick={active ? deactivateUser : activateUser}
          disabled={toggleActivationStatus.pending}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default UserActivation
