import React from 'react'

import { useSelector } from 'react-redux'

import { StyledWrapper, StyledSpan, StyledImage } from 'components/organization_logo/style'
import Logo from 'components/logo'

import useCurrentOrganization from 'views/iam/hooks/use_current_organization'
import { selectCurrentUserOrganizationLogo } from 'views/iam/slices/iamSlice'
import { isUndefined } from 'services/helpers/values'

export interface OrganizationLogoProps {
  condensed?: boolean
  className?: string
}
const OrganizationLogo: React.FC<OrganizationLogoProps> = ({ condensed = false, className }) => {
  const { isWhiteLabel } = useCurrentOrganization()
  const organizationLogoUrl = useSelector(selectCurrentUserOrganizationLogo)
  const isReady = !isUndefined(organizationLogoUrl)

  return (
    <StyledWrapper $condensed={condensed} className={className}>
      {isWhiteLabel && isReady && (
        <StyledImage src={organizationLogoUrl} alt='company logo' $condensed={condensed} />
      )}
      {!isWhiteLabel && isReady && (
        <>
          <Logo name='logo' height={35} width={40} />
          <StyledSpan>wakeo</StyledSpan>
        </>
      )}
    </StyledWrapper>
  )
}

export default OrganizationLogo
