import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useModal from 'components/modal/hooks/use_modal'

import StyledActionMenuContainer from 'views/shipment/components/action_menu/style'
import Menu, { MenuItem } from 'components/menu'
import Button from 'components/button'
import { routeShipment } from 'services/helpers/routes'
import {
  TEST_ID_SHIPMENT_ACTION_MENU,
  TEST_ID_SHIPMENT_DELETE_SHIPMENT_BUTTON,
  TEST_ID_SHIPMENT_REPORT_SHIPMENT_BUTTON,
  TEST_ID_SHIPMENT_EDIT_PATH_BUTTON,
  TEST_ID_SHIPMENT_SWITCH_TO_V2_BUTTON,
} from 'tests/e2e/test_ids'

import ShipmentContext from 'views/shipment/context'

import { WITH_SHAREABLE_LINK } from 'constants/organization_features'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import useUserCan from 'views/iam/hooks/use_user_can'
import { ORDER_DELETE, ORDER_UPDATE, ORDER_FLAG } from 'constants/permissions'
import useUserIs from 'views/iam/hooks/use_user_is'
import { ADMIN, OUTSOURCING, SUPER_ADMIN } from 'constants/roles'
import useTracker from 'services/analytics/hooks/use_tracker'

const ActionMenu = () => {
  const { id: orderId } = useContext(ShipmentContext)
  const { t } = useTranslation()
  const { track } = useTracker()
  const userCan = useUserCan()
  const userIs = useUserIs()
  const organizationCan = useOrganizationCan()
  const { setOpen: setDeleteModalOpen } = useModal('deleteShipment')
  const { setOpen: setShareModalOpen } = useModal('share')
  const { setOpen: setReportModalOpen } = useModal('reportShipment')
  const { setOpen: setEditPathModalOpen } = useModal('editPath')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onBlur = () => {
    setIsMenuOpen(false)
  }
  return (
    <StyledActionMenuContainer>
      <Menu
        control={
          <Button
            testId={TEST_ID_SHIPMENT_ACTION_MENU}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            text='Actions'
            icon={isMenuOpen ? 'arrow_top' : 'arrow_bottom_outline'}
          />
        }
        onBlur={onBlur}
      >
        {organizationCan.features(WITH_SHAREABLE_LINK) && (
          <MenuItem
            text={t('actions.share')}
            onClick={() => {
              track('Shipment / Share', { action: 'pre-share' })
              setShareModalOpen(true)
            }}
          />
        )}
        {userCan(ORDER_DELETE) && (
          <MenuItem
            testId={TEST_ID_SHIPMENT_DELETE_SHIPMENT_BUTTON}
            text={t('shipments.shipmentDeleted.title')}
            onClick={() => {
              setDeleteModalOpen(true)
            }}
          />
        )}
        {(userIs(ADMIN) || userIs(SUPER_ADMIN) || userIs(OUTSOURCING)) && (
          <MenuItem
            testId={TEST_ID_SHIPMENT_SWITCH_TO_V2_BUTTON}
            text={t('actions.goToV2')}
            to={`${process.env.REACT_APP_APPLICATION_ENDPOINT_V2}${routeShipment(orderId!, {
              isLegacy: true,
            })}`}
          />
        )}
        {userCan(ORDER_FLAG) && (
          <MenuItem
            text={t('actions.reportShipment')}
            onClick={() => setReportModalOpen(true)}
            testId={TEST_ID_SHIPMENT_REPORT_SHIPMENT_BUTTON}
          />
        )}
        {userCan(ORDER_UPDATE) && (
          <MenuItem
            testId={TEST_ID_SHIPMENT_EDIT_PATH_BUTTON}
            text={t('shipments.editPath.title')}
            onClick={() => setEditPathModalOpen(true)}
          />
        )}
      </Menu>
    </StyledActionMenuContainer>
  )
}

export default ActionMenu
