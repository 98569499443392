import useUserIs from 'views/iam/hooks/use_user_is'
import { ADMIN, SUPER_ADMIN } from 'constants/roles'

const useCanAccessAtlas = (): boolean => {
  const userIs = useUserIs()

  return userIs(ADMIN) || userIs(SUPER_ADMIN)
}

export default useCanAccessAtlas
