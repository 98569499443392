import styled from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { h3 } from 'styles/utils/texts'
import { desktop, tablet } from 'styles/utils/breakpoints'
import StyledIcon from 'components/icon/style'

export const StyledWidgets = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 300px;
  max-width: 300px;
  padding: 25px 10px;
  margin-left: 20px;
  background-color: ${({ theme }) => theme.primary};
  ${borderRadiusMedium};
  @media ${desktop.down} {
    margin-left: 0;
    margin-top: 22px;
    padding: 25px 10px 15px 10px;
  }
  @media ${tablet.down} {
    min-width: 100%;
  }
`
export const StyledWidgetsHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 10px;
  @media ${desktop.down} {
    height: 44px;
    margin-bottom: 13px;
  }
`
export const StyledWidgetsChildren = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  @media ${desktop.down} {
    display: flex;
    padding-bottom: 10px;
  }

  @media ${tablet.down} {
    flex-direction: column;
  }
`
export const StyledWidgetsTitle = styled.span`
  ${h3};
  color: ${({ theme }) => theme.white};
`
export const StyledWidgetsButton = styled.div`
  border: 1px dashed rgba(255, 255, 255, 0.48);
  margin-top: 20px;
  @media ${desktop.down} {
    position: absolute;
    top: 0;
    right: 30px;
  }

  @media ${tablet.down} {
    position: relative;
    right: 0;
  }
`
export const StyledWidgetsIcon = styled(StyledIcon)`
  flex: none;
  fill: ${({ theme }) => theme.white};
  margin-right: 10px;
  width: 40px;
  height: 40px;
`
