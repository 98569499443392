import { Map as MapboxMap } from 'mapbox-gl'
import { LineString } from 'geojson'
import { useTheme } from 'styled-components'

const useLineString = ({ map }: { map?: MapboxMap }) => {
  const theme = useTheme()
  const addLineString = (id: string, geojson: LineString, dashed = false) => {
    if (map?.getSource(id)) return

    map?.addSource(id, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: geojson,
        properties: {},
      },
    })

    map?.addLayer({
      id,
      type: 'line',
      source: id,
      layout: {},
      paint: {
        'line-dasharray': dashed ? [2, 2] : [1, 0],
        'line-color': theme.primary,
        'line-width': 2,
      },
    })
  }

  return { addLineString }
}

export default useLineString
