import React, { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledAssignedUsersColumn,
  StyledFormEditGroup,
  StyledFormEditGroupIcon,
  StyledFormEditGroupItem,
  StyledFormEditGroupItems,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
} from 'views/booking/components/show/style'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'
import ValueWithFallback from 'views/booking/components/value_with_fallback'

import Icon from 'components/icon'

const InformationKeyContacts: FC = () => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { t } = useTranslation()
  const { assignedUsers } = booking!

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.keyContacts.title')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems>
        {assignedUsers.map((u) => (
          <StyledFormEditGroupItem>
            <StyledAssignedUsersColumn>
              <StyledFormEditGroupIcon as={Icon} name='address_book' />
              <ValueWithFallback value={`${u.firstName} ${u.lastName}`} />
            </StyledAssignedUsersColumn>

            <StyledFormEditGroupValue>
              <ValueWithFallback value={u.email} />
            </StyledFormEditGroupValue>
          </StyledFormEditGroupItem>
        ))}
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationKeyContacts
