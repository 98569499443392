import styled from 'styled-components'

import { gradientLightOpacityReverse } from 'styles/utils/gradients'

export const StyledPowerBIWrapper = styled.div<{ isMobile: boolean }>`
  height: 100%;
  padding: 15px 0 0 0;
  ${gradientLightOpacityReverse}

  .report-style-class {
    margin-right: auto;
    margin-left: ${(props) => (props.isMobile ? '5%' : 'auto')};
    max-width: 100%;
    padding: 0 90px 30px 90px;
    height: 100%;
  }
`

export const StyledNotifMessageWrapper = styled.div`
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
`
