import React from 'react'
import { useTranslation } from 'react-i18next'

import { AnyValue } from 'types/common'

import { StyledNaValue } from 'views/booking/components/show/style'

import { isPresent } from 'services/helpers/values'

interface ValueWithFallbackProps {
  value?: AnyValue
  suffix?: string
  testId?: string
}

const ValueWithFallback = ({ value, suffix = '', testId }: ValueWithFallbackProps) => {
  const { t } = useTranslation()
  let valueToDisplay = value

  if (typeof value === 'boolean') {
    valueToDisplay = t(value ? 'yes' : 'no')
  }

  return isPresent(value) ? (
    <span data-testid={testId}>
      {valueToDisplay}
      {isPresent(suffix) && ` ${suffix}`}
    </span>
  ) : (
    <StyledNaValue data-testid={testId}>N/A</StyledNaValue>
  )
}

export default ValueWithFallback
