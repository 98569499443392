import React, { useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'

import Page from 'components/page'
import AddressFilters from 'views/atlas/addresses/components/filters'
import StyledPage from 'components/page/style'
import S from 'views/atlas/style'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import usePagination from 'services/api/hooks/use_pagination'
import {
  fetchAddresses,
  removeAllAddresses,
  selectAddresses,
  selectAddressesActiveFilters,
  selectAddressesStatus,
  selectAddressesTotalCount,
} from 'views/atlas/slices/address'
import useStatus from 'services/api/hooks/use_status'
import useTriggerOnViewport from 'services/hooks/use_trigger_on_viewport'
import NotifMessage from 'components/notif_message'
import Table from 'components/table'
import Item from 'views/atlas/addresses/components/item'

const AddressIndex: React.FC = () => {
  const dispatch = useAppDispatch()
  const { page, next, reset } = usePagination()
  const { t } = useTranslation()
  const addresses = useSelector(selectAddresses)
  const totalCount = useSelector(selectAddressesTotalCount)
  const activeFilters = useSelector(selectAddressesActiveFilters)
  const fetchingStatus = useStatus(useSelector(selectAddressesStatus))
  const theme = useTheme()

  useEffect(() => {
    resetAllAddresses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeFilters)])

  const resetAllAddresses = () => {
    dispatch(removeAllAddresses())
    triggerFetchAddresses(1)
    reset()
  }

  const triggerFetchAddresses = (p: number) => {
    dispatch(
      fetchAddresses({
        search: activeFilters.search,
        hubs: activeFilters.hubs,
        carriers: activeFilters.carriers,
        dataProviders: activeFilters.dataProviders,
        statusTypeFilters: activeFilters.statusTypeFilters,
        page: p,
      })
    )
  }

  const nextPage = () => {
    triggerFetchAddresses(page + 1)
    next()
  }

  const ref = useTriggerOnViewport<HTMLDivElement>(nextPage)

  return (
    <Page sidebar={<AddressFilters />}>
      <S.Header>
        <StyledPage.PageTitle>Addresses</StyledPage.PageTitle>
        <S.Text>{t('atlas.addressCounter', { count: totalCount })}</S.Text>
      </S.Header>
      <div>
        {addresses.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>Raw address</Table.HeadCell>
              <Table.HeadCell>Data Provider</Table.HeadCell>
              <Table.HeadCell>Carrier</Table.HeadCell>
              <Table.HeadCell>Linked hub</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell />
            </Table.Head>
            <Table.Body>
              {addresses.map((address, index) => (
                <Item
                  address={address}
                  variant={index % 2 === 1 ? 'dark' : 'light'}
                  key={address.token}
                />
              ))}
            </Table.Body>
          </Table>
        )}
        {fetchingStatus.ready && addresses.length < totalCount && <div ref={ref} />}
        {fetchingStatus.pending && (
          <S.Loader>
            <ClipLoader loading size={20} aria-label='Loading Spinner' color={theme.primary} />
          </S.Loader>
        )}
        {fetchingStatus.fulfilled && addresses.length === 0 && (
          <NotifMessage
            type='info'
            title={t('atlas.noResults.title')}
            text={t('atlas.noResults.text')}
          />
        )}
      </div>
    </Page>
  )
}

export default AddressIndex
