import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { IFilter } from 'services/hooks/use_filter'

import {
  StyledAddMerchandiseItemWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'

import Form from 'components/form'

import { BookingMerchandisePackageContent } from 'views/booking/slices/types'

import Button from 'components/button'
import Grid from 'components/grid'
import Select from 'components/select'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import Input from 'components/input'

import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import Textarea from 'components/textarea'

import {
  TEST_ID_BOOKING_FORM_COMMERCIAL_VALUE_AMOUNT,
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandisePackage,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'

interface MerchandisePackageProps {
  merchandiseFilter: IFilter<'custom'>
}

const MerchandisePackage: FC<MerchandisePackageProps> = ({
  merchandiseFilter,
}: MerchandisePackageProps) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return (
    <Form.Group>
      {merchandiseFilter.value.package.map(
        (_package: BookingMerchandisePackageContent, index: number) => (
          <Form.Insert
            key={`packageRequest-${index + 1}`}
            subtitle={`${t('bookings.merchandise.packageRequest')} #${index + 1}`}
          >
            {index !== 0 && (
              <StyledRemoveMerchandiseItemWrapper>
                <Button
                  variant='icon'
                  icon='trash'
                  disabled={merchandiseFilter.isDisabled}
                  onClick={() => {
                    merchandiseFilter.onChange((state: any) => {
                      state.package.splice(index, 1)
                    })
                  }}
                />
              </StyledRemoveMerchandiseItemWrapper>
            )}
            <Grid columns={3}>
              <Grid.Row testId={getTestIdForMerchandisePackage(index)}>
                <Grid.Column>
                  <Select
                    label={t('bookings.merchandise.type')}
                    placeholder={t('bookings.merchandise.packageType')}
                    options={fromStaticToSelectOptions('packageTypes').map(({ label, value }) => ({
                      value,
                      label,
                    }))}
                    name={`packageType-${index}`}
                    value={merchandiseFilter.value.package[index].packageType}
                    onChange={({ value }) => {
                      merchandiseFilter.onChange((state: any) => {
                        state.package[index].packageType = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    isDisabled={merchandiseFilter.isDisabled}
                    inputId={`${merchandiseFilter.name}-type-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.quantity')}
                    type='number'
                    placeholder={t('bookings.merchandise.quantity')}
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                    name={`packageQuantity-${index}`}
                    value={merchandiseFilter.value.package[index].quantity}
                    error={merchandiseFilter.error?.package?.[index]?.quantity}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        state.package[index].quantity = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-quantity-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.packageWeight')}
                    placeholder='kg'
                    step='0.01'
                    type='number'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                    name={`packageWeight-${index}`}
                    value={merchandiseFilter.value.package[index]?.weight?.value}
                    error={merchandiseFilter.error?.package?.[index]?.weight}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].weight) state.package[index].weight = {}
                        state.package[index].weight.value = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-weight-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.packageLength')}
                    type='number'
                    step='0.01'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.LENGTH}
                    placeholder='cm'
                    name={`packageLength-${index}`}
                    value={merchandiseFilter.value.package[index]?.length?.value}
                    error={merchandiseFilter.error?.package?.[index]?.length}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].length) state.package[index].length = {}
                        state.package[index].length.value = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-length-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.packageWidth')}
                    type='number'
                    step='0.01'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WIDTH}
                    placeholder='cm'
                    name={`packageWidth-${index}`}
                    value={merchandiseFilter.value.package[index]?.width?.value}
                    error={merchandiseFilter.error?.package?.[index]?.width}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].width) state.package[index].width = {}
                        state.package[index].width.value = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-width-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.packageHeight')}
                    type='number'
                    step='0.01'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.HEIGHT}
                    placeholder='cm'
                    name={`packageHeight-${index}`}
                    value={merchandiseFilter.value.package[index]?.height?.value}
                    error={merchandiseFilter.error?.package?.[index]?.height}
                    onChange={({ target: { value } }) => {
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].height) state.package[index].height = {}
                        state.package[index].height.value = value
                      })
                    }}
                    required={!merchandiseFilter.isDisabled}
                    disabled={merchandiseFilter.isDisabled}
                    id={`${merchandiseFilter.name}-height-${index}`}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Textarea
                    label={t('bookings.merchandise.productDescription')}
                    placeholder={t('bookings.merchandise.yourDescription')}
                    name={`merchandiseDescription-${index}`}
                    rows={4}
                    compact
                    value={merchandiseFilter.value.package[index].productDescription}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        state.package[index].productDescription = value
                      })
                    }
                    disabled={merchandiseFilter.isDisabled}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    type='number'
                    step='0.01'
                    min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                    label={t('bookings.merchandise.totalCommercialValue')}
                    placeholder={t('bookings.merchandise.amount')}
                    name={`merchandiseAmount-${index}`}
                    value={merchandiseFilter.value.package[index].commercialValue?.amount}
                    error={merchandiseFilter.error?.package?.[index]?.commercialValueAmount}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].commercialValue)
                          state.package[index].commercialValue = {}
                        state.package[index].commercialValue.amount = value
                      })
                    }
                    disabled={merchandiseFilter.isDisabled}
                    testId={TEST_ID_BOOKING_FORM_COMMERCIAL_VALUE_AMOUNT}
                  />
                  <Select
                    placeholder={t('bookings.merchandise.currency')}
                    options={fromStaticToSelectOptions('currencies')}
                    name={`merchandiseCurrencyCode-${index}`}
                    isDisabled={merchandiseFilter.isDisabled}
                    value={merchandiseFilter.value.package[index].commercialValue?.currencyCode}
                    error={merchandiseFilter.error?.package?.[index]?.commercialValueCurrencyCode}
                    onChange={({ value }: { value: any }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].commercialValue)
                          state.package[index].commercialValue = {}
                        state.package[index].commercialValue.currencyCode = value
                      })
                    }
                    testId={getTestIdForMerchandiseProductCurrencyCode(index)}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    label={t('bookings.merchandise.controlledTemperatures.title')}
                    placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                    type='number'
                    step='0.01'
                    disabled={merchandiseFilter.isDisabled}
                    testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
                    name={`merchandiseTemperatureMinimal-${index}`}
                    value={merchandiseFilter.value.package[index].controlledTemperatures?.min}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].controlledTemperatures)
                          state.package[index].controlledTemperatures = {}
                        state.package[index].controlledTemperatures.min = value
                      })
                    }
                  />
                  <Input
                    placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                    type='number'
                    step='0.01'
                    disabled={merchandiseFilter.isDisabled}
                    testId={getTestIdForMerchandiseProductMaximalTemperature(index)}
                    name={`merchandiseTemperatureMaximal-${index}`}
                    value={merchandiseFilter.value.package[index].controlledTemperatures?.max}
                    onChange={({ target: { value } }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].controlledTemperatures)
                          state.package[index].controlledTemperatures = {}
                        state.package[index].controlledTemperatures.max = value
                      })
                    }
                  />
                </Grid.Column>
                <Grid.Column>
                  <Select
                    label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                    placeholder={t('actions.select')}
                    isDisabled={merchandiseFilter.isDisabled}
                    options={fromStaticToSelectOptions('hazardousGoods')}
                    name={`merchandiseHazardousClass-${index}`}
                    value={merchandiseFilter.value.package[index].hazardousGoods?.hazardousClass}
                    onChange={({ value }: { value: any }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].hazardousGoods)
                          state.package[index].hazardousGoods = {}
                        state.package[index].hazardousGoods.hazardousClass = value
                      })
                    }
                    isClearable
                  />
                </Grid.Column>
                <Grid.Column>
                  <Select
                    label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                    placeholder={t('actions.select')}
                    isDisabled={merchandiseFilter.isDisabled}
                    options={fromStaticToSelectOptions('packingGroups')}
                    name={`merchandiseHazardousPackingGroup-${index}`}
                    value={merchandiseFilter.value.package[index].hazardousGoods?.packingGroup}
                    onChange={({ value }: { value: any }) =>
                      merchandiseFilter.onChange((state: any) => {
                        if (!state.package[index].hazardousGoods)
                          state.package[index].hazardousGoods = {}
                        state.package[index].hazardousGoods.packingGroup = value
                      })
                    }
                    isClearable
                  />
                </Grid.Column>
                <Grid.Column>
                  <StyledHazardousGoodsWrapper>
                    <StyledHazardousGoodsItemInput
                      label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                      placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                      disabled={merchandiseFilter.isDisabled}
                      name={`merchandiseHazardousUnNumber-${index}`}
                      value={merchandiseFilter.value.package[index].hazardousGoods?.unNumber}
                      onChange={({ target: { value } }) =>
                        merchandiseFilter.onChange((state: any) => {
                          if (!state.package[index].hazardousGoods)
                            state.package[index].hazardousGoods = {}
                          state.package[index].hazardousGoods.unNumber = value
                        })
                      }
                    />
                    <StyledHazardousGoodsItemInput
                      label={t('bookings.merchandise.product.hazardousGoods.weight')}
                      placeholder='kg'
                      type='number'
                      step='0.01'
                      disabled={merchandiseFilter.isDisabled}
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                      name={`merchandiseHazardousWeight-${index}`}
                      value={merchandiseFilter.value.package[index].hazardousGoods?.weight?.value}
                      error={merchandiseFilter.error?.package?.[index]?.hazardousWeight}
                      onChange={({ target: { value } }) =>
                        merchandiseFilter.onChange((state: any) => {
                          if (!state.package[index].hazardousGoods)
                            state.package[index].hazardousGoods = {}

                          if (!state.package[index].hazardousGoods.weight)
                            state.package[index].hazardousGoods.weight = {}

                          state.package[index].hazardousGoods.weight.value = value
                        })
                      }
                    />
                  </StyledHazardousGoodsWrapper>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form.Insert>
        )
      )}
      <StyledAddMerchandiseItemWrapper>
        <Button
          disabled={merchandiseFilter.isDisabled}
          onClick={() =>
            merchandiseFilter.onChange((state: any) => {
              state.package[state.package.length] = {}
            })
          }
          icon='plus_outline'
          variant='dashed'
          text={`${t('actions.addNew', { context: 'female' })} ${t(
            'bookings.merchandise.packageRequest'
          ).toLowerCase()}`}
        />
      </StyledAddMerchandiseItemWrapper>
    </Form.Group>
  )
}

export default MerchandisePackage
