import { useEffect, useRef } from 'react'

const useInterval = (callback: () => void, delay: number | null = 1000) => {
  const ref = useRef(callback)

  useEffect(() => {
    ref.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => ref.current(), delay)
    // eslint-disable-next-line
    return () => clearInterval(id)
  }, [delay])
}

export default useInterval
