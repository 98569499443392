import styled from 'styled-components'

import { StyledButton } from 'components/button/style'
import StyledIcon from 'components/icon/style'
import { text14Regular24, h3 } from 'styles/utils/texts'
import { desktop } from 'styles/utils/breakpoints'

export const StyledCompanyForm = styled.div``

export const StyledCompanyFormButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledButton} {
    margin: 20px 15px 0 15px;
  }

  @media ${desktop.down} {
    border-top: 1px solid ${(props) => props.theme.softGrayLight};

    ${StyledButton} {
      margin: 30px 15px 10px 15px;
    }
  }
`

export const StyledCompanyFormSubtitle = styled.div`
  ${text14Regular24}
  color: ${(props) => props.theme.textDark};
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  ${StyledIcon} {
    width: 16px;
    height: 16px;
    margin-left: 9px;
    fill: ${(props) => props.theme.primary};
  }
`

export const StyledCompanyFormTitle = styled.div`
  ${h3}
  color: ${(props) => props.theme.textDark};
  margin-bottom: 20px;
`
