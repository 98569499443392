import React, { Children, FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import BoxShadow from 'components/box_shadow'
import Button from 'components/button'

import useBreakpoint from 'services/hooks/use_breakpoints'

import useUserCan from 'views/iam/hooks/use_user_can'
import DocumentContext from 'views/documents/contexts/document_context'

import { DOCUMENT_CREATE } from 'constants/permissions'

import {
  StyledBlockDocuments,
  StyledBlockDocumentsTop,
  StyledBlockDocumentsTopTitle,
  StyledBlockDocumentsContent,
  StyledBlockDocumentsHeading,
  StyledBlockDocumentsTopButton,
  StyledBlockDocumentsHeadingDateWrapper,
  StyledBlockDocumentsHeadingTypeWrapper,
  StyledBlockDocumentsHeadingNameWrapper,
  StyledBlockDocumentsHeadingText,
} from 'components/block_documents/style'
import { TEST_ID_BOOKING_FORM_ADD_DOCUMENTS } from 'tests/e2e/test_ids'
import useModal from 'components/modal/hooks/use_modal'

const BlockDocuments: FC<{ className: string }> = ({ className, children }) => {
  const { isLowerThanDesktop } = useBreakpoint()
  const userCan = useUserCan()
  const { t } = useTranslation()
  const { setOpen } = useModal('uploadDocuments')
  const { canUpload: canUploadDocument } = useContext(DocumentContext)

  return (
    <StyledBlockDocuments className={className} as={BoxShadow}>
      <StyledBlockDocumentsTop>
        <StyledBlockDocumentsTopTitle>
          {Children.count(children)} {t('documents.document', { count: Children.count(children) })}
        </StyledBlockDocumentsTopTitle>

        {userCan(DOCUMENT_CREATE) && canUploadDocument && (
          <StyledBlockDocumentsTopButton
            as={Button}
            text={!isLowerThanDesktop ? t('actions.add') : null}
            icon='plus_plain'
            rounded
            onClick={() => setOpen(true)}
            testId={TEST_ID_BOOKING_FORM_ADD_DOCUMENTS}
          />
        )}
      </StyledBlockDocumentsTop>

      <StyledBlockDocumentsContent>
        {!isLowerThanDesktop && (
          <StyledBlockDocumentsHeading>
            <StyledBlockDocumentsHeadingDateWrapper>
              <StyledBlockDocumentsHeadingText>
                {t('documents.date')}
              </StyledBlockDocumentsHeadingText>
            </StyledBlockDocumentsHeadingDateWrapper>

            <StyledBlockDocumentsHeadingTypeWrapper>
              <StyledBlockDocumentsHeadingText>
                {t('documents.type')}
              </StyledBlockDocumentsHeadingText>
            </StyledBlockDocumentsHeadingTypeWrapper>

            <StyledBlockDocumentsHeadingNameWrapper>
              <StyledBlockDocumentsHeadingText>
                {t('documents.filename')}
              </StyledBlockDocumentsHeadingText>
            </StyledBlockDocumentsHeadingNameWrapper>
          </StyledBlockDocumentsHeading>
        )}

        <div>{children}</div>
      </StyledBlockDocumentsContent>
    </StyledBlockDocuments>
  )
}

export default BlockDocuments
