import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ClipLoader } from 'react-spinners'
import { useTheme } from 'styled-components'

import {
  getClientId,
  getCustomDomain,
  getLoginUrl,
  getLogoutHint,
  getLogoutInitiatedByUser,
  getLogoutRedirectUrl,
  isSSOUser,
} from 'views/iam/slices/ssoSlice'
import { SSOSignInContainer } from 'views/iam/style'

const { REACT_APP_APPLICATION_ENDPOINT } = process.env

const LOGIN_URL_QUERY_PARAM = 'loginUrl'

const formatUrl = (
  logoutUrl: string,
  loginUrl: string,
  logoutHint: string,
  clientId: string,
  customDomain?: string
) => {
  const url = new URL(logoutUrl)
  const params = new URLSearchParams(url.search)
  params.set(
    'post_logout_redirect_uri',
    `${
      customDomain || REACT_APP_APPLICATION_ENDPOINT
    }/sso/logout?${LOGIN_URL_QUERY_PARAM}=${loginUrl}`
  )
  params.set('logout_hint', logoutHint)
  params.set('client_id', clientId)
  url.search = params.toString()

  return url.toString()
}

const SSORedirect: React.FC = () => {
  const logoutRedirectUrl = useSelector(getLogoutRedirectUrl)
  const loginUrl = useSelector(getLoginUrl)
  const isAnSSOUser = useSelector(isSSOUser)
  const logoutHint = useSelector(getLogoutHint)
  const clientId = useSelector(getClientId)
  const customDomain = useSelector(getCustomDomain)
  const isLogoutTriggeredByUser = useSelector(getLogoutInitiatedByUser)
  const theme = useTheme()
  const history = useHistory()

  useEffect(() => {
    // If the user is disconnected by a back channel request from client idp,
    // we do not want to trigger the oidc logout flow as he is already logged out from idp
    if (isAnSSOUser && !isLogoutTriggeredByUser) {
      window.location.replace(loginUrl)
    } else if (logoutRedirectUrl && isAnSSOUser) {
      window.location.replace(
        formatUrl(logoutRedirectUrl, loginUrl, logoutHint, clientId, customDomain)
      )
    }
  }, [
    logoutRedirectUrl,
    isAnSSOUser,
    loginUrl,
    logoutHint,
    clientId,
    customDomain,
    isLogoutTriggeredByUser,
    history,
  ])

  return (
    <SSOSignInContainer>
      <ClipLoader color={theme.primary} />
    </SSOSignInContainer>
  )
}

export default SSORedirect
