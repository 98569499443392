// Cubic
export const easeCubicIn = 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'
export const easeCubicOut = 'cubic-bezier(0.215, 0.61, 0.355, 1)'
export const easeCubicInOut = 'cubic-bezier(0.645, 0.045, 0.355, 1)'

// Circ
export const easeCircIn = 'cubic-bezier(0.6, 0.04, 0.98, 0.335)'
export const easeCircOut = 'cubic-bezier(0.075, 0.82, 0.165, 1)'
export const easeCircInOut = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)'

// Expo
export const easeExpoIn = 'cubic-bezier(0.95, 0.05, 0.795, 0.035)'
export const easeExpoOut = 'cubic-bezier(0.19, 1, 0.22, 1)'
export const easeExpoInOut = 'cubic-bezier(1, 0, 0, 1)'

// Quad
export const easeQuadIn = 'cubic-bezier(0.55, 0.085, 0.68, 0.53)'
export const easeQuadOut = 'cubic-bezier(0.25, 0.46, 0.45, 0.94)'
export const easeQuadInOut = 'cubic-bezier(0.455, 0.03, 0.515, 0.955)'

// Quart
export const easeQuartIn = 'cubic-bezier(0.895, 0.03, 0.685, 0.22)'
export const easeQuartOut = 'cubic-bezier(0.165, 0.84, 0.44, 1)'
export const easeQuartInOut = 'cubic-bezier(0.77, 0, 0.175, 1)'

// Quint
export const easeQuintIn = 'cubic-bezier(0.755, 0.05, 0.855, 0.06)'
export const easeQuintOut = 'cubic-bezier(0.23, 1, 0.32, 1)'
export const easeQuintInOut = 'cubic-bezier(0.86, 0, 0.07, 1)'

// Sine
export const easeSineIn = 'cubic-bezier(0.47, 0, 0.745, 0.715)'
export const easeSineOut = 'cubic-bezier(0.39, 0.575, 0.565, 1)'
export const easeSineInOut = 'cubic-bezier(0.445, 0.05, 0.55, 0.95)'

// Back
export const easeBackIn = 'cubic-bezier(0.6, -0.28, 0.735, 0.045)'
export const easeBackOut = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)'
export const easeBackInOut = 'cubic-bezier(0.68, -0.55, 0.265, 1.55)'
