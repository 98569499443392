import ReactModal from 'react-modal'

import styled, { css } from 'styled-components'

import { Coordinates } from 'types/common'

import { h3, h2, text15Regular } from 'styles/utils/texts'
import { borderRadiusMedium } from 'styles/utils/border_radius'
import { easeQuartOut } from 'styles/utils/ease'
import { tablet } from 'styles/utils/breakpoints'
import { translateCenterToTopAnimation, translateTopAnimation } from 'styles/utils/animations'

import StyledIcon from 'components/icon/style'
import { headerHeight, headerHeightMobile } from 'components/header/style'

import { ModalHeight, ModalPosition, ModalWidth } from 'components/modal/types/types'

const heightHead = '63px'

export const StyledOverlay = {
  position: 'fixed',
  left: '0px',
  top: '0px',
  width: '100%',
  height: '100%',
  background: 'rgba(0,0,17,0.75)',
}
export const StyledModalHead = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`
export const StyledModalHeader = styled.div`
  background: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.white};
  height: ${heightHead};
  width: 100%;
  padding: 0 25px;
  display: flex;
  align-items: center;
  ${h3};
`
export const StyledModalClose = styled.button<{ $dark?: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;

  ${StyledIcon} {
    width: 12px;
    height: 12px;
    fill: ${({ theme }) => theme.white};
    transition: fill 0.5s ${easeQuartOut};
  }

  ${(props) =>
    props.$dark &&
    css`
      ${StyledIcon} {
        fill: ${({ theme }) => theme.mediumGray};
      }
    `}

  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.textLight};
    }
  }
`

export const StyledModalContent = styled.div`
  ${text15Regular};
  color: ${({ theme }) => theme.textLight};
  padding: 25px 20px;
  overflow-y: auto;

  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.primary};
    ${h2};
    margin-bottom: 10px;
  }

  a {
    color: ${({ theme }) => theme.primary};
    transition: color 0.5s ${easeQuartOut};

    &:hover {
      color: ${({ theme }) => theme.textDark};
    }
  }

  @media ${tablet.up} {
    padding: 30px 45px;
  }
`

export const StyledModal = styled(ReactModal)<{
  $width?: ModalWidth
  $height?: ModalHeight
  $noPadding?: boolean
  $coordinates?: Coordinates
  $position: ModalPosition
}>`
  position: absolute;
  ${({ $position }) => {
    const isCentered = $position === 'center'
    return css`
      top: ${isCentered ? '50%' : headerHeightMobile};

      @media ${tablet.up} {
        top: ${isCentered ? '50%' : headerHeight};
      }
    `
  }}

  left: 50%;
  right: auto;
  bottom: auto;
  margin-top: 20px;
  margin-right: -50%;
  transform: ${({ $position }) =>
    $position === 'center' ? 'translate(-50%, -50%)' : 'translate(-50%, 0)'};

  ${({ $position }) =>
    $position === 'center' ? translateCenterToTopAnimation : translateTopAnimation}
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
  width: calc(100% - 16px);
  background-color: white;
  ${borderRadiusMedium};
  outline: none !important;
  max-width: 510px;
  padding: 0;
  overflow: hidden;

  @media ${tablet.up} {
    width: 80%;
  }

  ${StyledModalContent} {
    max-height: calc(90vh - ${heightHead});

    @media ${tablet.up} {
      max-height: calc(60vh - ${heightHead});
    }
  }

  ${(props) =>
    props.$width === 'small' &&
    css`
      max-width: 380px;

      @media ${tablet.up} {
        width: 80%;
      }
    `}

  ${(props) =>
    props.$width === 'normal' &&
    css`
      max-width: 510px;

      @media ${tablet.up} {
        width: 80%;
      }
    `}

  ${(props) =>
    props.$width === 'large' &&
    css`
      max-width: 771px;
      @media ${tablet.up} {
        width: 90%;
      }
    `}
    ${(props) =>
    props.$width === 'full' &&
    css`
      @media ${tablet.up} {
        width: 100%;
      }
    `}

    ${(props) =>
    props.$height === 'full' &&
    css`
      max-width: 90%;
      height: 85%;
      ${StyledModalContent} {
        height: 80%;
        max-height: unset;
        padding: 10px;
        overflow-y: hidden;
      }
    `}
    

  ${(props) =>
    props.$noPadding &&
    css`
      ${StyledModalContent} {
        padding: 0;
      }
    `}

  @media ${tablet.up} {
    ${({ $coordinates }) => {
      if ($coordinates) {
        const { x, y } = $coordinates
        return css`
          top: ${
            y + 10 // This 10px offset is due to dashboard modal displaying an icon above the modal
          }px;
          left: ${x}px;
        `
      }
      return null
    }}
  }
`

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 45px;
`
