import { isPresent } from 'services/helpers/values'

const useUploadProgress = () => {
  const getProgress = (e) => {
    const totalLength = e.lengthComputable
      ? e.total
      : e.target.getResponseHeader('content-length') ||
        e.target.getResponseHeader('x-decompressed-content-length')
    return isPresent(totalLength) ? Math.round((e.loaded * 100) / totalLength) : 0
  }
  return { getProgress }
}

export default useUploadProgress
