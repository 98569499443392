import styled from 'styled-components'

import { text16Regular } from 'styles/utils/texts'

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TextContainer = styled.p`
  text-align: center;
`

const Text = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.textDark};
  padding: 0 5px;
  ${text16Regular};
`

const S = {
  Loader,
  TextContainer,
  Text,
}

export default S
