import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import NotifMessage from 'components/notif_message'

const StyledWrap = styled.div`
  padding: 1em;
`
const ErrorNotification = () => {
  const { t } = useTranslation()
  return (
    <StyledWrap>
      <NotifMessage
        type='alert'
        title={t('errors.notification.title')}
        text={t('errors.notification.content')}
      />
    </StyledWrap>
  )
}

export default ErrorNotification
