import { useCallback, useEffect, useState } from 'react'

import type { Category } from 'services/hooks/use_category'
import type { TFilterValues } from 'services/hooks/use_filter'

export interface Tag {
  onDelete: () => void
  value: TFilterValues
}
interface UseTagsValues {
  categories: Category[]
}
const useTags = ({ categories }: UseTagsValues) => {
  const tags = categories.map((c) => c.tags).flat()
  const [activeTags, setActiveTags] = useState<Tag[]>([])
  const [willApply, setWillApply] = useState(false)
  const toggleWillApply = useCallback(() => setWillApply(!willApply), [willApply])

  useEffect(() => {
    if (willApply) {
      setActiveTags(tags)
      toggleWillApply()
    }
  }, [willApply, toggleWillApply, setActiveTags, tags])

  return {
    tags,
    activeTags,
    setActiveTags: toggleWillApply,
  }
}
export default useTags
