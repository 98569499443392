import { InferType, mixed, object, string } from 'yup'

import { ManuallyResolvedStatus, RejectedStatus, StatusType } from 'views/atlas/types/address'
import { isPresent } from 'services/helpers/values'
import { ValidStatus } from 'views/atlas/slices/address'

type SelectValue = { label: string; value: StatusType }

export const resolveAddressSchema = object({
  status: object({
    label: string().required(),
    value: mixed<ValidStatus>()
      .oneOf([RejectedStatus, ManuallyResolvedStatus])
      .required()
      .when('hub', {
        is: (hub: { label: string; value: string } | null) => isPresent(hub),
        then: (schema) => schema.oneOf([ManuallyResolvedStatus]),
        otherwise: (schema) => schema.oneOf([RejectedStatus]),
      }),
  }).required(),
  hub: object({ label: string().required(), value: string().required() })
    .nullable()
    .when('status', {
      is: (status: { label: string; value: ValidStatus }) =>
        status.value === ManuallyResolvedStatus,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.oneOf([null]),
    }),
}).required()

type AddressFormData = InferType<typeof resolveAddressSchema>

export type AddressFormDataBeforeValidation = Omit<AddressFormData, 'status'> & {
  status: null | SelectValue
}

export default AddressFormData
