import React from 'react'
import ContentLoader from 'react-content-loader'

import useBreakpoints from 'services/hooks/use_breakpoints'

import { StyledBookingListItem } from 'components/booking_list_item/style'

const BookingListItemSkeleton = () => {
  const { isMobile } = useBreakpoints()

  return (
    <StyledBookingListItem>
      {!isMobile && (
        <ContentLoader viewBox='0 0 400 20' style={{ width: '100%' }}>
          <rect x='18' y='4' rx='3' ry='3' width='30' height='3' />
          <rect x='18' y='14' rx='3' ry='3' width='30' height='3' />
          <circle cx='7' cy='11' r='7' />
          <rect x='90' y='4' rx='3' ry='3' width='80' height='3' />
          <rect x='90' y='14' rx='3' ry='3' width='120' height='3' />
          <rect x='365' y='4' rx='3' ry='3' width='30' height='3' />
          <rect x='355' y='14' rx='3' ry='3' width='40' height='3' />
        </ContentLoader>
      )}
      {isMobile && (
        <ContentLoader viewBox='0 0 200 65' style={{ width: '100%' }}>
          <rect x='18' y='4' rx='3' ry='3' width='120' height='4' />
          <rect x='18' y='20' rx='5' ry='3' width='100' height='4' />
          <circle cx='7' cy='14' r='7' />
          <rect x='8' y='42' rx='3' ry='3' width='185' height='17' />
        </ContentLoader>
      )}
    </StyledBookingListItem>
  )
}

BookingListItemSkeleton.propTypes = {}

BookingListItemSkeleton.defaultProps = {}

export default BookingListItemSkeleton
