import React, { FC, SyntheticEvent } from 'react'

import { StyledTag, StyledSpan, StyledCrossButton, StyledCrossIcon } from 'components/tag/style'

import type { ThemeColors } from 'styles/theme'

export type TagColor = ThemeColors<'white' | 'primary' | 'secondary' | 'success' | 'warn' | 'error'>
export type TagSize = 'small' | 'medium' | 'large'

interface TagProps {
  text: string
  highlighted?: boolean
  color?: TagColor
  size?: TagSize
  deletable?: boolean
  onDelete?: (e: SyntheticEvent) => void
  className?: string
  testId?: string
}
const Tag: FC<TagProps> = ({
  text,
  highlighted = false,
  color = 'primary',
  size = 'medium',
  deletable = false,
  onDelete,
  className,
  testId,
}) => (
  <StyledTag
    $highlighted={highlighted}
    $color={color}
    $size={size}
    className={className}
    data-testid={testId}
  >
    <StyledSpan>{text}</StyledSpan>
    {deletable && onDelete && (
      <StyledCrossButton onClick={onDelete}>
        <StyledCrossIcon />
      </StyledCrossButton>
    )}
  </StyledTag>
)

export default Tag
