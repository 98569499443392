import React, { memo, useMemo, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TRANSPORT_TYPE_ICONS } from 'constants/shipments'

import {
  StyledDashboardMap,
  StyledPin,
} from 'features/shipments/components/shipments_tracking_map/style'
import {
  selectOngoingShipments,
  selectOngoingShipmentsStatus,
} from 'features/shipments/store/vehicle_slice'

import useOnce from 'services/hooks/use_once'
import useStatus from 'services/api/hooks/use_status'
import useTracker from 'services/analytics/hooks/use_tracker'
import useShallowSelector from 'services/hooks/use_shallow_selector'

import useMap from 'components/map/hooks/use_map'
import { VehicleModel } from 'components/map/models'
import ModalShipments from 'features/shipments/components/modal_shipments'
import AboveModal from 'components/above_modal'
import Marker from 'components/marker'
import useModal from 'components/modal/hooks/use_modal'
import useShipmentColor from 'views/shipments/hooks/use_shipment_color'
import { fetchOngoingShipments } from 'features/shipments/services/vehicle_service'
import { fetchModalShipmentsByVehicleId } from 'features/shipments/services/shipment_service'

const ShipmentsTrackingMap = memo(() => {
  const dispatch = useDispatch()
  const ongoingShipments = useShallowSelector(selectOngoingShipments)
  const status = useStatus(useSelector(selectOngoingShipmentsStatus))
  const { userCoordinate } = useTracker()
  const { setOpen, opened } = useModal('dashboardMap')
  const [modalShipmentsVehicleId, setModalShipmentsVehicleId] = useState()
  const vehicles = useMemo(
    () =>
      ongoingShipments.map(
        ({ id, orders, shipmentsCount }) =>
          new VehicleModel({
            id,
            type: TRANSPORT_TYPE_ICONS[orders[0].transportType],
            lng: orders[0].lng,
            lat: orders[0].lat,
            shipmentsCount,
            color: orders[0].color,
          })
      ),
    [ongoingShipments]
  )

  const [modalCoords, setModalCoords] = useState({})
  const [markerType, setMarkerType] = useState()
  const [markerLabel, setMarkerLabel] = useState()
  const [shipmentColor, setShipmentColor] = useState(0)

  const { track } = useTracker()

  const { getColorFromShipmentColor } = useShipmentColor()

  const onVehicleClick = useCallback(
    ({ x, y, label, type, color, id }) => {
      dispatch(
        fetchModalShipmentsByVehicleId({
          vehicleId: id,
        })
      )
        .unwrap()
        .then(() => {
          setModalCoords({ x, y })
          setMarkerType(type)
          setMarkerLabel(label)
          setShipmentColor(color)
          setModalShipmentsVehicleId(id)
          setOpen(true)
          track('Dashboard / Map', { detail: 'pin' })
        })
    },
    [setOpen, track, dispatch]
  )

  const onClusterClick = useCallback(() => {
    track('Dashboard / Map', { detail: 'cluster' })
  }, [track])

  const onToggleFullscreen = (isFullscreen) => {
    if (isFullscreen) track('Dashboard / Map', { detail: 'enlarge' })
  }

  const { Map, mapProps, loaded, clusterizeVehicles, isFullscreen, setCenter } = useMap({
    bounds: vehicles.map((v) => v.coordinate),
    onVehicleClick,
    onClusterClick,
    onToggleFullscreen,
  })

  useOnce(() => dispatch(fetchOngoingShipments()))

  useEffect(() => {
    if (loaded && status.fulfilled) {
      clusterizeVehicles(vehicles)
    }
  }, [loaded, status, vehicles, clusterizeVehicles])

  useEffect(() => {
    if (loaded && status.fulfilled && userCoordinate) {
      setCenter(userCoordinate)
    }
  }, [loaded, status, userCoordinate, setCenter])

  const markerColor = getColorFromShipmentColor(shipmentColor)

  return (
    <>
      <StyledDashboardMap $fullscreen={isFullscreen}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Map {...mapProps} />
      </StyledDashboardMap>
      <AboveModal>
        {opened && (
          <StyledPin $position={modalCoords}>
            <Marker icon={markerType} label={markerLabel} color={markerColor} />
          </StyledPin>
        )}
      </AboveModal>
      {opened && <ModalShipments position={modalCoords} vehicleId={modalShipmentsVehicleId} />}
    </>
  )
})

export default ShipmentsTrackingMap
