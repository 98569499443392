import styled from 'styled-components'

import { textSmallDescription } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'

// Saved filters

export const StyledSavedFilters = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const StyledSavedFiltersInner = styled.div`
  height: 100%;
  overflow-y: auto;
`

export const StyledSavedFiltersEdit = styled.button`
  padding: 0 20px;
  ${textSmallDescription}
  color:  ${({ theme }) => theme.textLight};
  margin: 11px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`
