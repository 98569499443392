import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { routeSharedShipment, routeSignIn } from 'services/helpers/routes'
import SharedShipment from 'views/shipment/shared_shipment'

const SharedApp = () => (
  <Switch>
    <Redirect from='/shared/order/:token' to={routeSharedShipment(':token')} />
    <Route exact path={routeSharedShipment(':token')}>
      <SharedShipment />
    </Route>
    <Route>
      <Redirect to={routeSignIn()} />
    </Route>
  </Switch>
)

export default SharedApp
