import styled from 'styled-components'

export const POWERED_BY_HEIGHT = 40

export const StyledPoweredBy = styled.p`
  text-align: center;
  padding: 0.5em 0;
  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.softGrayLight};
  height: ${POWERED_BY_HEIGHT}px;
`
