const AVAILABLE_SCAC_LIST = [
  'CMDU',
  'COSU',
  'EGLV',
  'HLCU',
  'HDMU',
  'MAEU',
  'MSCU',
  'ONEY',
  'OOLU',
  'PCIU',
  '12PD',
  'YMLU',
  'ZIMU',
  'WHLC',
  'SMLM',
  'CMCU',
  'GSLU',
  'ANNU',
  'MATS',
]

export const ShippingLineMapping = new Map([
  ['CMDU', 'CMA CGM'],
  ['COSU', 'COSCO'],
  ['EGLV', 'Evergreen'],
  ['HLCU', 'Hapag LLoyd'],
  ['HDMU', 'Hyundai Merchant Marine'],
  ['MAEU', 'Maersk'],
  ['MSCU', 'Mediterranean Shipping Company'],
  ['ONEY', 'Ocean Network Express'],
  ['OOLU', 'OOCL'],
  ['PCIU', 'Pacific international lines'],
  ['12PD', 'SITC'],
  ['YMLU', 'Yang ming'],
  ['ZIMU', 'ZIM'],
  ['WHLC', 'Wan Hai'],
  ['SMLM', 'SM Line Corporation'],
  ['CMCU', 'Crowley'],
  ['GSLU', 'Gold Star Line'],
  ['ANNU', 'Australia National Line'],
  ['MATS', 'Matson'],
])

export default AVAILABLE_SCAC_LIST
