import React from 'react'
import ContentLoader from 'react-content-loader'

const ModalShipmentSkeleton = () => (
  <ContentLoader
    speed={2}
    width={400}
    height={100}
    viewBox='0 0 400 100'
    backgroundColor='#f7f7f7'
    foregroundColor='#f0efef'
  >
    <rect x='0' y='2' rx='0' ry='0' width='212' height='16' />
    <rect x='1' y='25' rx='0' ry='0' width='227' height='16' />
    <rect x='5' y='89' rx='0' ry='0' width='92' height='16' />
    <rect x='1' y='66' rx='0' ry='0' width='223' height='2' />
  </ContentLoader>
)

export default ModalShipmentSkeleton
