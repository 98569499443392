import {
  MILESTONE_TIME_TYPES,
  MILESTONE_TIME_TYPE_ACTUAL,
  MILESTONE_TIME_TYPE_ESTIMATED,
  MILESTONE_TIME_TYPE_PLANNED,
} from 'constants/shipments'

import { isPresent } from 'services/helpers/values'

interface UseMilestoneTimesValues {
  actual?: string
  planned?: string
  estimated?: string
}
interface UseMilestoneTimes {
  time?: string
  diffTime: string | null
  type?: typeof MILESTONE_TIME_TYPES[number]
}
const useMilestoneTimes = ({
  actual,
  planned,
  estimated,
}: UseMilestoneTimesValues): UseMilestoneTimes => {
  const time = actual || estimated || planned

  let type
  if (isPresent(actual)) {
    type = MILESTONE_TIME_TYPE_ACTUAL
  } else if (isPresent(estimated)) {
    type = MILESTONE_TIME_TYPE_ESTIMATED
  } else if (isPresent(planned)) {
    type = MILESTONE_TIME_TYPE_PLANNED
  }

  let diffTime = null
  if (actual && planned) {
    diffTime = planned
  } else if (planned && estimated) {
    diffTime = planned
  } else if (actual && estimated) {
    diffTime = estimated
  }

  return { time, diffTime, type }
}
export default useMilestoneTimes
