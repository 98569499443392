import React, { FC } from 'react'

import Icon from 'components/icon'

import { TEST_ID_SEARCH_LIST_BUTTON } from 'tests/e2e/test_ids'

import {
  StyledSearchList,
  StyledSearchListTitle,
  StyledSearchListMore,
  StyledSearchListItems,
} from 'components/search_list/style'

interface SearchListProps {
  category?: string
  count?: number
  viewMore?: boolean
  testId?: string
  onClickMore?: () => void
}

const SearchList: FC<SearchListProps> = ({
  category,
  onClickMore,
  viewMore = true,
  count = 0,
  children,
  testId,
}) => (
  <StyledSearchList data-testid={testId}>
    <StyledSearchListTitle>
      <Icon name='tag' />
      {category} ({count})
    </StyledSearchListTitle>
    <StyledSearchListItems data-testid={TEST_ID_SEARCH_LIST_BUTTON}>
      {children}{' '}
    </StyledSearchListItems>
    {viewMore && (
      <StyledSearchListMore onClick={onClickMore}>View more results</StyledSearchListMore>
    )}
  </StyledSearchList>
)
export default SearchList
