import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Modal from 'components/modal'
import Button from 'components/button'

import useModal from 'components/modal/hooks/use_modal'

export interface ValidateEditTemplateModalProps {
  onValidate: () => void
  onClose: () => void
  templateName: string | undefined
}

const ValidateEditTemplateModal: FC<ValidateEditTemplateModalProps> = ({
  onValidate,
  onClose,
  templateName,
}: ValidateEditTemplateModalProps) => {
  const { t } = useTranslation()
  const { setOpen } = useModal('bookingTemplateValidateEdit')

  return (
    <Modal position='center' modalName='bookingTemplateValidateEdit' onClose={onClose}>
      <Modal.Header>{t('bookings.templates.editModalTitle')}</Modal.Header>
      <Modal.Content>
        {t('bookings.templates.editModalContent', { name: templateName })}
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.cancel')} rounded variant='clear' onClick={() => setOpen(false)} />
        <Button
          text={t('bookings.templates.apply')}
          rounded
          variant='highlight'
          onClick={() => {
            onValidate?.()
            setOpen(false)
          }}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default React.memo(ValidateEditTemplateModal)
