import styled, { css } from 'styled-components'

import { text14Regular24 } from 'styles/utils/texts'

import { tablet } from 'styles/utils/breakpoints'

export const StyledBookingLastEvent = styled.li`
  display: flex;
  align-items: center;
  padding: 15px 25px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.softGrayLight};
  }
  @media ${tablet.down} {
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }
`

export const StyledBookingLastEventTitle = styled.p`
  ${text14Regular24};
  color: ${({ theme }) => theme.textLight};
  @media ${tablet.up} {
    flex-direction: column;
    flex-basis: 50%;
  }
`

export const StyledBookingLastEventDate = styled.p`
  ${text14Regular24};
  color: ${({ theme }) => theme.textLight};
  @media ${tablet.up} {
    flex-direction: column;
    flex-basis: 20%;
    text-align: left;
  }
`

export const StyledBookingLastEventName = styled.p`
  ${text14Regular24};
  color: ${({ theme }) => theme.textLight};
  @media ${tablet.up} {
    flex-direction: column;
    flex-basis: 30%;
    text-align: right;
  }
`

export const StyledBookingLastEventChangesContainer = styled.div`
  padding: 0 10px 10px 10px;
`

export const StyledBookingLastEventItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.softGrayLight};
`

export const StyledBookingLastEventCellItem = styled.span<{ $noWrap?: boolean }>`
  display: block;
  ${({ $noWrap }) =>
    $noWrap &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`

export const StyledBookingLastEventIndentedCellItem = styled(StyledBookingLastEventCellItem)`
  padding-left: 12px;
`

export const StyledBookingLastEventIndentedContainer = styled.div`
  padding-left: 12px;
`
