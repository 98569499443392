import React, { FC } from 'react'

import { StyledModalFooter } from 'components/modal/style'

import { TEST_ID_MODAL_ACTIONS } from 'tests/e2e/test_ids'

const Footer: FC = ({ children }) => (
  <StyledModalFooter data-testid={TEST_ID_MODAL_ACTIONS}>{children}</StyledModalFooter>
)

export default Footer
