import { css } from 'styled-components'

export const h1 = css`
  font-family: 'Sora';
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  font-style: normal;
`

export const h2 = css`
  font-family: 'Sora';
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
`

export const h3 = css`
  font-family: 'Sora';
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
`

export const text9SemiBold = css`
  font-family: 'Sora';
  font-size: 9px;
  line-height: 18px;
  font-weight: 600;
  font-style: normal;
`

export const text12SemiBold = css`
  font-family: 'Sora';
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  font-style: normal;
`

export const text16SemiBold = css`
  font-family: 'Sora';
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
`

export const text16Regular = css`
  font-family: 'Sora';
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
`

export const text15SemiBold = css`
  font-family: 'Sora';
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  font-style: normal;
`

export const text14SemiBold = css`
  font-family: 'Sora';
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
`

export const text15Regular = css`
  font-family: 'Sora';
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
`

export const text14Bold = css`
  font-family: 'Sora';
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
`
export const text13Bold = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
`

export const text14Regular = css`
  font-family: 'Sora';
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
`

export const text14Regular24 = css`
  font-family: 'Sora';
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
`

export const text12Regular = css`
  font-family: 'Sora';
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
`

export const text8Bold = css`
  font-family: 'Sora';
  font-size: 8px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
`

export const text24SemiBold = css`
  font-family: 'Sora';
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  font-style: normal;
`

export const text24Regular = css`
  font-family: 'Sora';
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  font-style: normal;
`

export const listItem13 = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
`

export const textSmallDescription = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
`

export const textItalicDescription = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
  font-style: italic;
`

export const buttonSmall = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  font-style: normal;
`

export const buttonSmallRegular = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
`

export const tagSmall = css`
  font-family: 'Sora';
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
`

export const tagMedium = css`
  font-family: 'Sora';
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  font-style: normal;
`
