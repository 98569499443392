import 'styles/global/fonts.css'
import { createGlobalStyle } from 'styled-components'

import resets from 'styles/global/resets'
import texts from 'styles/global/texts'
import scrollbar from 'styles/global/scrollbar'
import datePicker from 'styles/global//datePicker'

const GlobalStyle = createGlobalStyle`
  ${resets}
  ${texts}
  ${scrollbar}
  ${datePicker}
  html, body{
    font-family: 'Sora';   

    // Avoid overflow of above-modal-content with an absolute position
    overflow: hidden;
    position: relative;
  }
`

export default GlobalStyle
