import { InferType, object, string, array } from 'yup'

const validNamePattern = /^[\s'a-zA-Z0-9_-]*$/
const validNameErrorMessage =
  'the field must only contain alphanumeric characters or the following special characters: - _'

export const createClusterSchema = object({
  name: string().required().min(2).matches(validNamePattern, {
    message: validNameErrorMessage,
  }),
  nameAliases: array()
    .of(
      string().required().min(2).matches(validNamePattern, {
        message: validNameErrorMessage,
      })
    )
    .required(),
  county: string().defined().nullable(),
  timezone: object({ label: string().required(), value: string().required() }).required(),
  country: object({ label: string().required(), value: string().required() }).required(),
  state: object({ label: string().required(), value: string().required() }).defined().nullable(),
}).required()

type ClusterFormData = InferType<typeof createClusterSchema>

type SelectValue = { label: string; value: string }

export type ClusterFormDataBeforeValidation = Omit<
  ClusterFormData,
  'country' | 'state' | 'timezone'
> & {
  country: null | SelectValue
  state: null | SelectValue
  timezone: null | SelectValue
}

export default ClusterFormData
