export const patternEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const patternPasswordDigit = /\d+/
export const patternPasswordLowerCase = /[a-z]+/
export const patternPasswordUpperCase = /[A-Z]+/
export const patternPasswordSymbol = /[\W_]+/
export const patternPassword =
  /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*$/

export const MIN_PASSWORD_LENGTH = 12
export const MIN_PASSWORD_COMPLEXITY_RULES = 3
export const validPasswordLength = (value) => value.length >= MIN_PASSWORD_LENGTH
export const validPasswordComplexity = (value) => value >= MIN_PASSWORD_COMPLEXITY_RULES
export const nbRuleValidated = (password) => {
  let value = 0
  if (patternPasswordDigit.test(password)) value += 1
  if (patternPasswordLowerCase.test(password)) value += 1
  if (patternPasswordSymbol.test(password)) value += 1
  if (patternPasswordUpperCase.test(password)) value += 1
  return value
}

export const validateEmail = (email) => patternEmail.test(String(email).toLowerCase())
