import React from 'react'
import ContentLoader from 'react-content-loader'

const WidgetListSkeleton: React.FC = () => (
  <ContentLoader
    speed={2}
    viewBox='0 0 100 139'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
  >
    <rect x='0' y='0' rx='10' ry='10' width='100' height='43' />
    <rect x='0' y='47' rx='10' ry='10' width='100' height='43' />
    <rect x='0' y='94' rx='10' ry='10' width='100' height='43' />
  </ContentLoader>
)

export default WidgetListSkeleton
