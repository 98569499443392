import { css } from 'styled-components'

export const gradientLight = css`
  background: linear-gradient(
    212deg,
    ${({ theme }) => theme.gradients.light.from} 12%,
    ${({ theme }) => theme.gradients.light.to} 84%
  );
`

export const gradientLightOpacity = css`
  background: linear-gradient(
    212deg,
    ${({ theme }) => theme.gradients.light.fromOpacity} 12%,
    ${({ theme }) => theme.gradients.light.toOpacity} 84%
  );
`

export const gradientLightOpacityReverse = css`
  background: linear-gradient(
    212deg,
    ${({ theme }) => theme.gradients.light.toOpacity} 0%,
    ${({ theme }) => theme.gradients.light.fromOpacity} 90%
  );
`

export const gradientMedium = css`
  background: linear-gradient(
    52deg,
    ${({ theme }) => theme.gradients.medium.from} 6%,
    ${({ theme }) => theme.gradients.medium.to} 97%
  );
`

export const gradientMediumOpacity = css`
  background: linear-gradient(
    52deg,
    ${({ theme }) => theme.gradients.medium.fromOpacity} 6%,
    ${({ theme }) => theme.gradients.medium.toOpacity} 97%
  );
`

export const gradientDark = css`
  background: linear-gradient(
    52deg,
    ${({ theme }) => theme.gradients.dark.from} 6%,
    ${({ theme }) => theme.gradients.dark.to} 97%
  );
`

export const gradientDarkOpacity = css`
  background: linear-gradient(
    52deg,
    ${({ theme }) => theme.gradients.dark.fromOpacity} 6%,
    ${({ theme }) => theme.gradients.dark.toOpacity} 97%
  );
`

export const gradientDarkOpacityReverse = css`
  background: linear-gradient(
    52deg,
    ${({ theme }) => theme.gradients.dark.toOpacity} 6%,
    ${({ theme }) => theme.gradients.dark.fromOpacity} 97%
  );
`
