import styled from 'styled-components'

import { desktop } from 'styles/utils/breakpoints'
import { listItem13, h1 } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'

export const StyledWidgetsCarbonfootprint = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 12px 12px 12px 25px;
  background-color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
`
export const StyledWidgetTotal = styled.span`
  ${h1};
  font-size: 36px;
  color: ${({ theme }) => theme.textDark};
  margin-top: 5px;
  transform: translateX(-20px);

  @media ${desktop.down} {
    margin: 10px 0;
  }
`

export const StyledWidgetTotalIcon = styled(StyledIcon)`
  fill: ${({ theme }) => theme.primary};
  margin-right: 10px;
  width: 35px;
  height: 35px;
  float: left;
`
export const StyledWidgetTotalLabel = styled.span`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
`
export const StyledWidgetDetails = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`
export const StyledWidgetDetail = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 3px 0;
`
export const StyledWidgetIcon = styled(StyledIcon)`
  fill: ${({ theme }) => theme.primary};
  margin-right: 10px;
  width: 14px;
  height: 16px;
  flex-shrink: 0;
`

export const StyledWidgetsIteDetailText = styled.span`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
`
