import React, { useCallback, useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import FiltersSidebar from 'components/filters_sidebar'
import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import S from 'views/trusted_routes/components/filters/style'
import InputCheckbox from 'components/input_checkbox'
import { ShippingLineMapping } from 'constants/trusted_routes'
import InputDatepicker from 'components/input_datepicker'
import DateHelper from 'services/helpers/date_helper'
import {
  compactArray,
  datesMax,
  datesMin,
  isPresent,
  uniqArray,
  sortBy,
} from 'services/helpers/values'
import InputSlider from 'components/input_slider'
import useTimeout from 'services/hooks/use_timeout'
import Sidebar from 'components/sidebar'
import Icon from 'components/icon'
import StyledSidebar from 'components/filters_sidebar/style'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { trustedRouteUpdates } from 'views/trusted_routes/slice'
import useFilters from 'views/trusted_routes/hooks/use_filters'
import FiltersContext from 'views/trusted_routes/contexts/filters'
import Button from 'components/button'
import MultiSelect from 'components/multiselect'

interface FiltersProps {
  trustedRoutes: TrustedRoute[]
}

const Filters: React.FC<FiltersProps> = ({ trustedRoutes }: FiltersProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const shippingLineNames = sortBy(
    trustedRoutes
      .map((trustedRoute) => trustedRoute.scac)
      .filter((value, index, array) => array.indexOf(value) === index),
    [(scac) => ShippingLineMapping.get(scac)]
  )
  const cutOffDates = trustedRoutes
    .filter((trustedRoute) => isPresent(trustedRoute.cutOffDate))
    .map((trustedRoute) => new DateHelper(trustedRoute.cutOffDate).toDate())
  const etaList = trustedRoutes.map((trustedRoute) =>
    new DateHelper(trustedRoute.legs.slice(-1)[0].arrival.date).toDate()
  )
  const etdList = trustedRoutes.map((trustedRoute) =>
    new DateHelper(trustedRoute.legs[0].departure.date).toDate()
  )
  const vesselNames: string[] = uniqArray(
    compactArray(
      trustedRoutes.map((trustedRoute) => trustedRoute.legs.map((leg) => leg.vesselName)).flat()
    )
  ).sort()
  const nbTransshipments = trustedRoutes
    .map((trustedRoute) => (trustedRoute.legs.length - 1).toString())
    .filter((value, index, array) => array.indexOf(value) === index)
    .sort()
  const transitTimes = trustedRoutes.map((trustedRoute) => trustedRoute.transitTime)
  const minTransitTime = Math.min(...transitTimes)
  const maxTransitTime = Math.max(...transitTimes)
  const {
    filters: {
      etaFilter,
      transshipmentFilter,
      scacFilter,
      etdFilter,
      cutOffDateFilter,
      transitTimeFilter,
      vesselFilter,
    },
    isVisible,
  } = useFilters({ shippingLineNames, nbTransshipments })
  const [transitTimeTouched, setTransitTimeTouched] = useState(false)

  const { onTimeout } = useTimeout({ timeout: 250 })

  useEffect(() => {
    onTimeout(() => {
      dispatch(
        trustedRouteUpdates(
          trustedRoutes.map((trustedRoute) => ({
            id: trustedRoute.token,
            changes: { visible: isVisible(trustedRoute) },
          }))
        )
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    transitTimeFilter.value,
    scacFilter.value,
    transshipmentFilter.value,
    cutOffDateFilter.value,
    etdFilter.value,
    etaFilter.value,
    vesselFilter.value,
  ])

  useEffect(() => {
    dispatch(
      trustedRouteUpdates(
        trustedRoutes.map((trustedRoute) => ({
          id: trustedRoute.token,
          changes: { visible: isVisible(trustedRoute) },
        }))
      )
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustedRoutes.length])

  useEffect(() => {
    if (transitTimeTouched) return

    transitTimeFilter.setValue([minTransitTime, maxTransitTime])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minTransitTime, maxTransitTime])

  const { visible, setVisible } = useContext(FiltersContext)

  const onChangeTransitTime = useCallback(
    (value: [number, number]) => {
      if (!transitTimeTouched) setTransitTimeTouched(true)
      transitTimeFilter.onChange(value)
    },
    [transitTimeTouched, transitTimeFilter]
  )

  return (
    <S.Sidebar $visible={visible}>
      <Sidebar.Header>
        <StyledSidebar.Header>
          <StyledSidebar.HeaderIcon as={Icon} name='settings' />
          <Sidebar.Title>{t('filtersSidebar.title')}</Sidebar.Title>
          <S.Close
            as={Button}
            text={t('actions.close')}
            icon='close'
            variant='transparent'
            onClick={() => setVisible(false)}
          />
        </StyledSidebar.Header>
      </Sidebar.Header>
      <Sidebar.Content>
        <StyledSidebar.Content>
          <FiltersSidebar.Section title={t('trustedRoutes.filters.transitTime')} defaultOpened>
            <InputSlider
              range
              allowCross={false}
              min={minTransitTime}
              max={maxTransitTime}
              value={transitTimeFilter.value}
              onChange={onChangeTransitTime}
            />
          </FiltersSidebar.Section>
          <FiltersSidebar.Section
            title={t('trustedRoutes.filters.nbOfTransshipment')}
            defaultOpened
          >
            {nbTransshipments.map((nbTransshipment: string) => (
              <InputCheckbox
                id={nbTransshipment}
                key={nbTransshipment}
                name={nbTransshipment}
                text={t('trustedRoutes.routing.transshipment', {
                  count: parseInt(nbTransshipment, 10),
                })}
                checked={!!transshipmentFilter.value?.find((i) => i === nbTransshipment)}
                onChange={transshipmentFilter.onChange}
              />
            ))}
          </FiltersSidebar.Section>
          <FiltersSidebar.Section title={t('trustedRoutes.filters.dates')} defaultOpened>
            <InputDatepicker
              withPortal
              label={t('trustedRoutes.filters.cutOffDate')}
              name={cutOffDateFilter.name}
              range
              onChange={cutOffDateFilter.onChange}
              startDate={cutOffDateFilter.value?.start}
              endDate={cutOffDateFilter.value?.end}
              onToggleOpen={(opened) => {
                if (!opened && !cutOffDateFilter.isPresent) {
                  cutOffDateFilter.reset()
                }
              }}
              filterDate={(date) =>
                new DateHelper(date).isBetweenDates(
                  datesMin(...cutOffDates),
                  datesMax(...cutOffDates)
                )
              }
            />
            <InputDatepicker
              withPortal
              label='ETD'
              name={etdFilter.name}
              range
              onChange={etdFilter.onChange}
              startDate={etdFilter.value?.start}
              endDate={etdFilter.value?.end}
              onToggleOpen={(opened) => {
                if (!opened && !etdFilter.isPresent) {
                  etdFilter.reset()
                }
              }}
              filterDate={(date) =>
                new DateHelper(date).isBetweenDates(datesMin(...etdList), datesMax(...etdList))
              }
            />
            <InputDatepicker
              withPortal
              label='ETA'
              name={etaFilter.name}
              range
              onChange={etaFilter.onChange}
              startDate={etaFilter.value?.start}
              endDate={etaFilter.value?.end}
              onToggleOpen={(opened) => {
                if (!opened && !etaFilter.isPresent) {
                  etaFilter.reset()
                }
              }}
              filterDate={(date) =>
                new DateHelper(date).isBetweenDates(datesMin(...etaList), datesMax(...etaList))
              }
            />
          </FiltersSidebar.Section>
          <FiltersSidebar.Section title={t('trustedRoutes.filters.shippingLines')} defaultOpened>
            {shippingLineNames.map((scac: string) => (
              <InputCheckbox
                id={scac}
                key={scac}
                name={scac}
                text={ShippingLineMapping.get(scac) || ''}
                checked={
                  !!scacFilter.value?.find((selectedShippingLine) => selectedShippingLine === scac)
                }
                onChange={scacFilter.onChange}
              />
            ))}
          </FiltersSidebar.Section>
          <FiltersSidebar.Section title={t('trustedRoutes.filters.vessel')} defaultOpened>
            <MultiSelect
              name={vesselFilter.name}
              isSearchable
              value={vesselFilter.value}
              onChange={({ value }) => vesselFilter.onChange({ value, name: 'vessel' })}
              options={vesselNames.map((vesselName) => ({ label: vesselName, value: vesselName }))}
            />
          </FiltersSidebar.Section>
        </StyledSidebar.Content>
      </Sidebar.Content>
    </S.Sidebar>
  )
}

export default Filters
