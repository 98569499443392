import styled, { css } from 'styled-components'

import { text14Regular, text14Regular24, tagSmall, textSmallDescription } from 'styles/utils/texts'
import { tablet } from 'styles/utils/breakpoints'

import { StyledInput } from 'components/input/style'
import StyledIcon from 'components/icon/style'
import { StyledItem } from 'components/notif_message/style'
import { StyledFormInsert } from 'components/form/style'
import { StyledUploadDropzoneIcon } from 'components/upload_dropzone/style'
import Icon from 'components/icon'

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50px;
`

export const StyledBookingDocumentsContent = styled.div`
  padding: 10px 21px 40px 27px;

  @media ${tablet.down} {
    margin-top: 15px;
  }
`

export const StyledBookingDocumentsTop = styled.div`
  padding: 35px 15px 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${tablet.down} {
    padding: 0 10px 0 10px;
  }
`

export const StyledBookingDetailEdit = styled.div`
  margin-top: 10px;
`

export const StyledGroupInsertWrapper = styled.div`
  display: flex;
  width: 100%;
  @media ${tablet.down} {
    flex-direction: column;
    align-items: center;
  }
  ${StyledFormInsert} {
    min-width: 260px;
    margin-right: 20px;
    @media ${tablet.down} {
      margin-right: 0;
      width: 100%;
    }
  }
  ${StyledInput} {
    margin-bottom: 25px;
  }
  ${StyledWrapper} {
    margin-right: 20px;
    @media ${tablet.down} {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
`

export const StyledForm = styled.div``

export const StyledFormEditGroup = styled.div`
  padding: 15px 0px;
  display: flex;
  position: relative;
  @media ${tablet.down} {
    flex-direction: column;
  }
  &:not(:last-child):after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
    bottom: 0;
  }
  ${StyledItem} {
    width: 100%;
    margin-top: 20px;
  }
  ${StyledBookingDocumentsTop} {
    padding-top: 0;
    padding-left: 0;
  }
  ${StyledBookingDocumentsContent} {
    padding-left: 0;
  }
`

export const StyledFormEditGroupItems = styled.div<{ $width?: 'large' }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${(props) =>
    props.$width === 'large' &&
    css`
      ${StyledFormEditGroupLabel} {
        min-width: 200px;
      }
    `}
`
export const StyledFormEditGroupIcon = styled(StyledIcon)`
  height: 21px;
  width: 18px;
  min-width: 18px;
  fill: ${({ theme }) => theme.mediumGray};
  margin-right: 10px;
`
export const StyledFormEditGroupTitle = styled.p`
  ${text14Regular24};
  position: relative;
  overflow: hidden;
  min-width: 170px;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledFormEditGroupTitleRate = styled(StyledFormEditGroupTitle)`
  display: flex;
  gap: 5px;
`

export const StyledFormEditGroupItem = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 12px;
  }
`

export const StyledSpecificityRow = styled.div`
  display: flex;
  flex-direction: row;
`
export const StyledFormEditGroupLabel = styled.span`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
  flex-basis: 30%;
  min-width: 130px;
  @media ${tablet.down} {
    margin-bottom: 15px;
  }
`

export const StyledMerchandiseTotalContentLabel = styled(StyledFormEditGroupLabel)`
  display: flex;
  align-items: flex-start;
`

export const StyledFormEditGroupSubIcon = styled(StyledIcon)`
  height: 20px;
  width: 20px;
  fill: ${({ theme }) => theme.mediumGray};
  margin-right: 20px;
`
export const StyledFormEditGroupSubLabel = styled.span`
  color: ${({ theme }) => theme.textLight};
  ${tagSmall};
  margin-right: 20px;
  min-width: 80px;
`
export const StyledFormEditGroupSubValue = styled.div`
  color: ${({ theme }) => theme.textLight};
  ${text14Regular};
  display: flex;
`
export const StyledFormEditGroupItemText = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  ${StyledFormEditGroupLabel} {
    margin-bottom: 10px;
  }
`

export const StyledFormEditGroupValue = styled.div<{ $valid?: boolean }>`
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  align-items: center;
  display: flex;
  flex-basis: 70%;
  white-space: pre-wrap;
  ${(props) =>
    props.$valid &&
    css`
      color: ${({ theme }) => theme.primary};
    `}
`

export const StyledSpecifitySubGroup = styled.div`
  margin-bottom: 10px;
`
export const StyledFormEditGroupValues = styled.div`
  ${StyledSpecifitySubGroup}:last-child {
    margin-bottom: 20px;
  }

  @media ${tablet.down} {
    margin-top: 10px;
  }
`
export const StyledFormEditGroupItemDate = styled.div<{ $old?: boolean; $new: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  ${(props) =>
    props.$old &&
    css`
      ${StyledFormEditGroupValue} {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 9px;
          left: -30px;
          width: calc(100% + 40px);
          height: 2px;
          background: ${({ theme }) => theme.primary};
        }
      }
    `}
  ${(props) =>
    props.$new &&
    css`
      ${StyledFormEditGroupValue} {
        color: ${({ theme }) => theme.primary};
      }
      ${StyledFormEditGroupIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
`

export const StyledFormUploadDoc = styled.div`
  ${StyledUploadDropzoneIcon} {
    display: none;
  }
`
export const StyledBlockDocuments = styled.div`
  margin: 25px 0;
`

export const StyledMerchandiseItemGroup = styled.div`
  display: flex;
  flex-basis: 70%;
  align-items: center;
  justify-content: space-between;
`

export const StyledMerchandiseContentHeaderItem = styled.div<{ $flexBasis?: number }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.$flexBasis &&
    css`
      flex-basis: ${props.$flexBasis}%;
    `}
  div {
    white-space: nowrap;
  }
`

export const StyledMerchandiseProductDetails = styled.div`
  padding-bottom: 35px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.softGrayLight};
  }
`

export const StyledMerchandisePackageProductDetails = styled(StyledMerchandiseProductDetails)`
  border-top: 1px solid ${(props) => props.theme.softGrayLight};
`

export const StyledMerchandisePackageDetails = styled.div`
  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const StyledMerchandisePackagesContainer = styled.div`
  width: 100%;
`

export const StyledMerchandiseContentItem = styled.div`
  ${text14Regular};
`

export const StyledMerchandiseContentHeader = styled.div`
  flex: 1;
`

export const StyledMerchandisePackageItem = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 4px 0;
`

export const StyledMerchandiseContentInfoContainer = styled.div`
  align-self: flex-start;
`

export const StyledMerchandiseContentIconsContainer = styled(StyledMerchandiseContentItem)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-basis: 5%;
  max-height: 24px;
`

export const StyledMerchandiseItem = styled(StyledFormEditGroupValue)`
  ${StyledFormEditGroupValue} {
    flex-basis: 100%;
  }
`

export const StyleMerchandiseItemIcon = styled(Icon)`
  margin-right: 5px;
`

export const StyledTransportItem = styled.div`
  flex-grow: 1;
`

export const StyledFormPartiesGroupItem = styled.div`
  display: flex;
  :not(:last-child) {
    margin-bottom: 12px;
  }
`

export const StyledNaValue = styled.span`
  color: ${({ theme }) => theme.textLight};
`

export const StyledFooterButton = styled.button`
  margin: 0 10px;
`

export const StyledModalExchangeContent = styled.p`
  white-space: pre-line;
`
export const StyledAcceptCheckboxWrapper = styled.div`
  margin-top: 20px;
`

export const StyledAssignedUsersColumn = styled(StyledFormEditGroupValue)`
  flex-basis: 30%;
  min-width: 150px;
  padding-right: 10px;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const ProsalDatePicker = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 50px);
  min-width: 250px;
`
export const S = {
  ProsalDatePicker,
}
