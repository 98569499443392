import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentContext from 'components/shipment_list_item/context'
import { StyledShipmentListItemRef } from 'components/shipment_list_item/style'

import { isPresent } from 'services/helpers/values'

import useCurrentUser from 'views/iam/hooks/use_current_user'
import useUserCan from 'views/iam/hooks/use_user_can'

import { ORDER_ADMIN } from 'constants/permissions'

import type { Reference } from 'components/shipment_list_item/hooks/use_references'

const useComputedShipmentOrganization = (): Reference => {
  const { shipment } = useContext(ShipmentContext)
  const { organization } = shipment
  const { t } = useTranslation()
  const {
    profile: { settings },
  } = useCurrentUser()
  const userCan = useUserCan()
  const defaultValue = 'n/a'
  const reference = {
    Component: StyledShipmentListItemRef,
    value: isPresent(organization) ? organization : defaultValue,
    show: userCan(ORDER_ADMIN) && settings.shipmentIndexDisplayOrganization,
    name: 'organization',
    tooltipName: t('shipments.organization'),
  }
  return reference
}
export default useComputedShipmentOrganization
