import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { text14Regular, text16Regular } from 'styles/utils/texts'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { easeQuartOut } from 'styles/utils/ease'
import { tablet } from 'styles/utils/breakpoints'

export const StyledItemLink = styled(Link)`
  user-select: none;

  @media ${tablet.down} {
    list-style: none;
    ${text16Regular};
    padding-left: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media ${tablet.up} {
    ${text14Regular};
    padding: 14px;
    display: flex;
    align-items: center;
    ${borderRadiusSmall};
    transition: color 0.5s ${easeQuartOut};

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.primary};
    }
  }
`

export const StyledItem = styled.li<{ $active: boolean; $variant: 'light' | 'dark' }>`
  position: relative;

  @media ${tablet.down} {
    box-shadow: inset 0px -1px 0px #efefef;
    height: 88px;
    width: 100%;
    color: ${({ theme }) => theme.textLight};

    ${(props) =>
      props.$active &&
      css`
        color: ${({ theme }) => theme.textDark};
      `}
  }

  @media ${tablet.up} {
    display: flex;

    ${(props) =>
      props.$variant === 'dark' &&
      props.$active &&
      css`
        ${StyledItemLink} {
          background-color: ${props.theme.primary};
          color: white;
        }
      `}

    ${(props) =>
      props.$variant === 'dark' &&
      !props.$active &&
      css`
        ${StyledItemLink} {
          color: ${({ theme }) => theme.textLight};

          &:hover,
          &:focus {
            color: ${({ theme }) => theme.primary};
          }
        }
      `}

    ${(props) =>
      props.$variant === 'light' &&
      !props.$active &&
      css`
        ${StyledItemLink} {
          color: ${props.theme.white};

          &:hover,
          &:focus {
            color: ${({ theme }) => theme.primary50};
          }
        }
      `}

    ${(props) =>
      props.$variant === 'light' &&
      props.$active &&
      css`
        ${StyledItemLink} {
          background-color: ${props.theme.white};
          color: ${props.theme.primary};
        }
      `}
  }
`
