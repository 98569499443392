import React, { useContext, useEffect, useRef, useState } from 'react'

import Icon from 'components/icon'
import { TEST_ID_SEARCH_BUTTON } from 'tests/e2e/test_ids'

import SearchActive from 'components/search/context'
import {
  StyledIconClose,
  StyledIconSearch,
  StyledInput,
  StyledSearch,
  StyledSearchInner,
} from 'components/search/style'
import useModal from 'components/modal/hooks/use_modal'

import { SearchProps } from 'components/search/types'

export const MINIMUM_SEARCH_LENGTH = 3

const Search = React.forwardRef<HTMLDivElement | null, SearchProps>(
  ({ text, variant = 'dark', compact = false, onToggleOpen, highlighted = false }, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const { searchValue, setSearchValue } = useContext(SearchActive)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { opened, setOpen } = useModal('search')

    useEffect(() => {
      const onModalOpen = opened && !isModalOpen
      const onModalClose = !opened && isModalOpen

      if (onModalOpen) {
        setIsModalOpen(true)
      } else if (onModalClose) {
        setIsModalOpen(false)
        setIsOpen(false)
      }
    }, [opened, isModalOpen])

    useEffect(() => {
      if (searchValue && searchValue.length >= MINIMUM_SEARCH_LENGTH && !opened) {
        setOpen(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, setOpen])

    useEffect(() => {
      if (onToggleOpen) onToggleOpen(isOpen)
      if (!isOpen && inputRef.current) {
        inputRef.current.value = ''
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const keepSearchModalOpen = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation()
    return (
      <StyledSearch
        $variant={variant}
        $compact={compact}
        $open={isOpen}
        $highlighted={highlighted || (compact && isOpen)}
        ref={ref}
      >
        <StyledSearchInner>
          <button
            aria-label='search'
            name='search'
            type='button'
            onClick={(e) => {
              if (inputRef.current) inputRef.current.focus()
              setIsOpen(true)
              keepSearchModalOpen(e)
            }}
          >
            <StyledIconSearch as={Icon} name='search' testId={TEST_ID_SEARCH_BUTTON} />
          </button>
          <StyledInput
            ref={inputRef}
            placeholder={text}
            onInput={() => {
              if (inputRef.current) setSearchValue(inputRef.current.value)
            }}
            onClick={keepSearchModalOpen}
          />
          {compact && (
            <button
              aria-label='close'
              name='close'
              type='button'
              onClick={() => {
                setIsOpen(false)
                setSearchValue('')
              }}
            >
              <StyledIconClose as={Icon} name='close' />
            </button>
          )}
        </StyledSearchInner>
      </StyledSearch>
    )
  }
)
export default Search
