import React, { useContext } from 'react'

import { useTranslation } from 'react-i18next'

import { Controller, useForm } from 'react-hook-form'

import TextListItem from 'components/text_list/text_list_item'
import TextList from 'components/text_list'

import { References } from 'views/shipment/models'

import { StyledButtonsWrapper } from 'views/shipment/components/references/style'
import Button from 'components/button'
import {
  UpdateProductInformationsPayload,
  fetchShipment,
  updateProductDetails,
} from 'views/shipment/slice'
import ShipmentContext from 'views/shipment/context'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { addNotification } from 'views/notifications/slice'
import useShipment from 'views/shipment/hooks/use_shipment'
import {
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_SAVE,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_WEIGHT,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_VOLUME,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_PACKAGE_NUMBER,
  TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_DESCRIPTION,
} from 'tests/e2e/test_ids'
import { ProductInformationState } from 'views/shipment/components/references/blocks/types'

const ProductInformationBlock = ({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const { id } = useContext(ShipmentContext)
  const [shipment] = useShipment({ id: id! })
  const { product } = new References(shipment)

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<ProductInformationState>({
    defaultValues: product,
  })

  const onSubmit = (formData: ProductInformationState) => {
    const payload: UpdateProductInformationsPayload = {
      loadWeight: formData.grossWeight,
      loadVolume: formData.volume,
      productDescription: formData.description,
      packageNumber: formData.packageNumber,
      token: id!,
    }

    dispatch(updateProductDetails(payload))
      .unwrap()
      .then(() => {
        addNotification({
          type: 'success',
          title: t('shipments.notifications.shipmentUpdated.title'),
          text: t('shipments.notifications.shipmentUpdated.content'),
        })
      })
      .then(() => {
        dispatch(fetchShipment({ id: id! }))
      })
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
      .finally(() => setIsEditing(false))
  }

  return (
    <>
      <TextList>
        <Controller
          control={control}
          name='grossWeight'
          render={({ field }) => (
            <TextListItem
              testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_WEIGHT}`}
              title={t('shipments.product.grossWeight')}
              text={product.grossWeight}
              key='text-list-item-weight'
              isEditing={isEditing}
              inputType='number'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(e.target.value)}
              value={field.value}
            />
          )}
        />
        <Controller
          control={control}
          name='volume'
          render={({ field }) => (
            <TextListItem
              testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_VOLUME}`}
              title={t('shipments.product.volume')}
              text={product.volume}
              key='text-list-item-volume'
              isEditing={isEditing}
              inputType='number'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(e.target.value)}
              value={field.value}
            />
          )}
        />
        <Controller
          control={control}
          name='packageNumber'
          render={({ field }) => (
            <TextListItem
              testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_PACKAGE_NUMBER}`}
              title={t('shipments.product.packageNumber')}
              text={product.packageNumber}
              key='text-list-item-package-number'
              inputType='number'
              isEditing={isEditing}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => field.onChange(e.target.value)}
              value={field.value}
            />
          )}
        />
        <Controller
          control={control}
          name='description'
          render={({ field }) => (
            <TextListItem
              testId={`${TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_DESCRIPTION}`}
              title={t('shipments.product.description')}
              text={product.description}
              variant='description'
              key='text-list-item-description'
              isEditing={isEditing}
              isTextArea
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                field.onChange(e.target.value)
              }
              value={field.value}
            />
          )}
        />
      </TextList>
      {isEditing && (
        <StyledButtonsWrapper>
          <Button type='button' text={t('actions.cancel')} onClick={() => setIsEditing(false)} />
          <Button
            testId={TEST_ID_SHIPMENT_REFERENCES_PRODUCT_INFORMATION_SAVE}
            type='button'
            disabled={!isDirty}
            text={t('actions.save')}
            onClick={handleSubmit(onSubmit)}
            variant='highlight'
          />
        </StyledButtonsWrapper>
      )}
    </>
  )
}

export default ProductInformationBlock
