import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Grid from 'components/grid'
import Form from 'components/form'
import Textarea from 'components/textarea'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const Comments: FC<BookingFormProps> = ({ filters }) => {
  const { commentsFilter, forwarderCommentFilter } = filters
  const { t } = useTranslation()

  return (
    <>
      <Form.Group title={t('bookings.shipperComment')}>
        <Grid columns={1} columnsTablet={1}>
          <Grid.Row>
            <Grid.Column>
              <Textarea
                placeholder={t('bookings.yourComment')}
                value={commentsFilter.value}
                onChange={commentsFilter.onChange}
                name={commentsFilter.name}
                disabled={commentsFilter.isDisabled}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Group>
      <Form.Group title={t('bookings.forwarderComment')}>
        <Grid columns={1} columnsTablet={1}>
          <Grid.Row>
            <Grid.Column>
              <Textarea
                placeholder={t('bookings.yourComment')}
                value={forwarderCommentFilter.value}
                onChange={forwarderCommentFilter.onChange}
                name={forwarderCommentFilter.name}
                disabled={forwarderCommentFilter.isDisabled}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Group>
    </>
  )
}

export default Comments
