import { InferType, object, string } from 'yup'

import { patternEmail } from 'services/helpers/form_validation'

export const recoveryPasswordSchema = object({
  email: string()
    .required('iam.signIn.form.validation.required')
    .matches(patternEmail, { message: 'iam.signIn.form.validation.email' }),
}).required()

export type RecoveryPasswordData = InferType<typeof recoveryPasswordSchema>
