import React, { FC, useState } from 'react'

import Icon from 'components/icon'

import DateHelper from 'services/helpers/date_helper'

import {
  StyledBlockDocumentsItem,
  StyledBlockDocumentsItemContentWrapper,
  StyledBlockDocumentsItemDateWrapper,
  StyledBlockDocumentsItemDate,
  StyledBlockDocumentsItemTypeNameWrapper,
  StyledBlockDocumentsItemTypeWrapper,
  StyledBlockDocumentsItemType,
  StyledBlockDocumentsItemNameWrapper,
  StyledBlockDocumentsItemName,
  StyledBlockDocumentsItemActions,
  StyledBlockDocumentsItemTrashIcon,
  StyledBlockDocumentsItemDownloadIcon,
} from 'components/block_documents/style'
import { TEST_ID_BOOKING_BLOCK_DOCUMENT_ITEM_NAME } from 'tests/e2e/test_ids'

interface BlockDocumentsItemProps {
  className?: string
  name?: string
  type?: string
  createdAt?: string
  canDelete: boolean
  onDelete?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  link?: string
  testId?: string
  deleteButtonTestId?: string
  downloadLinkTestId?: string
}

const BlockDocumentsItem: FC<BlockDocumentsItemProps> = ({
  className,
  name,
  type,
  createdAt,
  canDelete = false,
  onDelete,
  link,
  testId,
  deleteButtonTestId,
  downloadLinkTestId,
}: BlockDocumentsItemProps) => {
  const [deleteTransition, setDeleteTransition] = useState(false)
  return (
    <StyledBlockDocumentsItem
      className={className}
      $deleteTransition={deleteTransition}
      data-testid={testId}
    >
      <StyledBlockDocumentsItemContentWrapper>
        <StyledBlockDocumentsItemDateWrapper>
          <StyledBlockDocumentsItemDate>
            {new DateHelper(createdAt).toLocale()}
          </StyledBlockDocumentsItemDate>
        </StyledBlockDocumentsItemDateWrapper>

        <StyledBlockDocumentsItemTypeNameWrapper>
          <StyledBlockDocumentsItemTypeWrapper>
            <StyledBlockDocumentsItemType>{type}</StyledBlockDocumentsItemType>
          </StyledBlockDocumentsItemTypeWrapper>

          <StyledBlockDocumentsItemNameWrapper>
            <StyledBlockDocumentsItemName data-testid={TEST_ID_BOOKING_BLOCK_DOCUMENT_ITEM_NAME}>
              {name}
            </StyledBlockDocumentsItemName>
          </StyledBlockDocumentsItemNameWrapper>
        </StyledBlockDocumentsItemTypeNameWrapper>
      </StyledBlockDocumentsItemContentWrapper>

      <StyledBlockDocumentsItemActions>
        {canDelete && (
          <button
            aria-label='Delete document'
            type='button'
            onClick={(e) => {
              setDeleteTransition(true)
              if (onDelete) onDelete(e)
            }}
            data-testid={deleteButtonTestId}
          >
            <StyledBlockDocumentsItemTrashIcon as={Icon} name='trash' />
          </button>
        )}

        <a
          href={link}
          target='_blank'
          rel='noreferrer'
          data-testid={downloadLinkTestId}
          aria-label='download'
        >
          <StyledBlockDocumentsItemDownloadIcon as={Icon} name='download' />
        </a>
      </StyledBlockDocumentsItemActions>
    </StyledBlockDocumentsItem>
  )
}

export default BlockDocumentsItem
