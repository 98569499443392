import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import type { AppTypes } from 'constants/app_type'

import type { RootState } from 'services/store/store'

export interface ApplicationState {
  type: AppTypes
}

const applicationAdapter = createEntityAdapter({})
const initialState = applicationAdapter.getInitialState({
  type: null,
} as ApplicationState)

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationType: (state, action) => {
      state.type = action.payload.type
    },
  },
})

export const { setApplicationType } = applicationSlice.actions
export const selectApplicationType = (state: RootState) => state.application.type

export default applicationSlice.reducer
