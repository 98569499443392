/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo } from 'react'
import { LngLatBounds, Map as MapboxMap } from 'mapbox-gl'

import { isEmptyArray, isNull } from 'services/helpers/values'

import { CoordinateModel } from 'components/map/models'

const BOUND_OPTIONS = {
  padding: 50,
  linear: true,
}

const useBounds = ({
  map,
  coordinates,
}: {
  map: MapboxMap | undefined
  coordinates: Array<CoordinateModel>
}) => {
  const bounds = useMemo(() => {
    if (isEmptyArray(coordinates)) return null

    const boundsCoords = coordinates
      .map(({ lat, lng }: CoordinateModel) => ({ lat, lng }))
      .reduce(
        // eslint-disable-next-line
        ({ min, max }: { min: any; max: any }, coord: any) => ({
          min: {
            lat: min.lat > coord.lat || min.lat === 0 ? coord.lat : min.lat,
            lng: min.lng > coord.lng || min.lng === 0 ? coord.lng : min.lng,
          },
          max: {
            lat: max.lat < coord.lat || max.lat === 0 ? coord.lat : max.lat,
            lng: max.lng < coord.lng || max.lng === 0 ? coord.lng : max.lng,
          },
        }),
        { min: { lat: 0, lng: 0 }, max: { lat: 0, lng: 0 } }
      )
    const b = new LngLatBounds(boundsCoords.min, boundsCoords.max)
    coordinates.forEach((bp: any) => b.extend(bp))
    return b
  }, [coordinates])

  const fitBounds = useCallback(() => {
    if (isNull(bounds)) {
      return
    }
    map?.fitBounds(bounds, BOUND_OPTIONS)
  }, [bounds, map])

  return {
    bounds,
    fitBounds,
    fitBoundsOptions: BOUND_OPTIONS,
  }
}

export default useBounds
