import React from 'react'

import { DefaultTheme, useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'

import S from 'components/password_complexity/style'
import {
  MIN_PASSWORD_COMPLEXITY_RULES,
  MIN_PASSWORD_LENGTH,
  patternPasswordDigit,
  patternPasswordLowerCase,
  patternPasswordSymbol,
  patternPasswordUpperCase,
  validPasswordComplexity,
  validPasswordLength,
  nbRuleValidated,
} from 'services/helpers/form_validation'
import Icon from 'components/icon'

const getColor = (
  value: string,
  matcher: RegExp,
  highlightComplexity: boolean,
  theme: DefaultTheme
): string => {
  if (!highlightComplexity) return theme.textDark

  return matcher.test(value) ? theme.success : theme.error
}

interface PasswordComplexityProps {
  password: string
}

const PasswordComplexity: React.FC<PasswordComplexityProps> = ({ password }) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const nbRuleChecked = nbRuleValidated(password)
  const passwordEnoughSecure = validPasswordComplexity(nbRuleChecked)
  const passwordLength = password.length
  const highlightComplexity = !(passwordLength === 0 || passwordEnoughSecure)

  return (
    <S.Container>
      <S.Rule>
        {validPasswordLength(password) ? (
          <S.CheckMark as={Icon} width={14} height={14} name='checkmark' />
        ) : (
          <S.Close as={Icon} width={14} height={14} name='close' />
        )}
        {t('iam.signIn.form.passwordMinLength', {
          count: passwordLength,
          total: MIN_PASSWORD_LENGTH,
        })}
      </S.Rule>
      <S.Rule>
        {passwordEnoughSecure ? (
          <S.CheckMark as={Icon} width={14} height={14} name='checkmark' />
        ) : (
          <S.Close as={Icon} width={14} height={14} name='close' />
        )}
        {t('iam.signIn.form.passwordComplexity', {
          count: nbRuleChecked,
          total: MIN_PASSWORD_COMPLEXITY_RULES,
        })}
        <S.SubRuleContainer>
          <S.SubRule color={getColor(password, patternPasswordDigit, highlightComplexity, theme)}>
            <S.SubRuleIcon as={Icon} width={8} height={8} name='arrow' />
            {t('iam.signIn.form.validation.passwordDigit')}
          </S.SubRule>
          <S.SubRule
            color={getColor(password, patternPasswordUpperCase, highlightComplexity, theme)}
          >
            <S.SubRuleIcon as={Icon} width={8} height={8} name='arrow' />
            {t('iam.signIn.form.validation.passwordUpperCase')}
          </S.SubRule>
          <S.SubRule
            color={getColor(password, patternPasswordLowerCase, highlightComplexity, theme)}
          >
            <S.SubRuleIcon as={Icon} width={8} height={8} name='arrow' />
            {t('iam.signIn.form.validation.passwordLowerCase')}
          </S.SubRule>
          <S.SubRule color={getColor(password, patternPasswordSymbol, highlightComplexity, theme)}>
            <S.SubRuleIcon as={Icon} width={8} height={8} name='arrow' />
            {t('iam.signIn.form.validation.passwordSymbol')}
          </S.SubRule>
        </S.SubRuleContainer>
      </S.Rule>
    </S.Container>
  )
}

export default PasswordComplexity
