import styled from 'styled-components'

import css from 'mapbox-gl/dist/mapbox-gl.css'

import Button from 'components/button'
import { tagMedium } from 'styles/utils/texts'
import { borderRadiusXSmall } from 'styles/utils/border_radius'

export const StyledMap = styled.div`
  ${css}
  position: relative;
  overflow: hidden;
  height: 100%;
  background-color: ${({ theme }) => theme.blueSea};

  // Hide whitelabel
  .mapboxgl-ctrl-logo {
    display: none !important;
  }

  .mapboxgl-popup-content {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.05));
    padding: 15px;
    ${tagMedium}
    ${borderRadiusXSmall};
    background-color: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.white};
  }

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
    border-bottom-color: ${({ theme }) => theme.primary};
  }
  .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
  .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
    border-top-color: ${({ theme }) => theme.primary};
  }
  .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
    border-right-color: ${({ theme }) => theme.primary};
  }
  .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
    border-left-color: ${({ theme }) => theme.primary};
  }
`

export const StyledFullscreenButton = styled(Button)`
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
`

export const StyledMapWrap = styled.div`
  height: 100%;
  position: relative;
  flex-shrink: 0;
`
