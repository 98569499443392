import { useCallback, useMemo } from 'react'

import {
  BOOKING_FORWARDER_ACTION_REQUIRED_STATUSES,
  BOOKING_NO_ACTION_REQUIRED_STATUSES,
  BOOKING_REVIEW_STATUSES,
  BOOKING_SHIPPER_ACTION_REQUIRED_STATUSES,
  BOOKING_EDITABLE_CANCELABLE_STATUSES,
  BOOKING_STATUSES,
  BOOKING_SUCCESS_STATUSES,
  BOOKING_PAST_PROPOSAL_EXCHANGE_STATUSES,
  BOOKING_DECLINABLE_STATUSES,
} from 'constants/bookings'

import type { BookingStatus } from 'types/bookings'

interface BookingStatusParams {
  isStatusRateConfirmationRequested: boolean
  isStatusProposalSent: boolean
  isStatusCompleted: boolean
  isStatusAReviewStatus: boolean
  isStatusASuccessStatus: boolean
  isStatusCancelled: boolean
  isStatusAccepted: boolean
  isStatusNoActionRequired: boolean
  isStatusForwarderActionRequired: boolean
  isStatusShipperActionRequired: boolean
  isStatusPastProposalExchange: boolean
  doesStatusAllowEditOrCancel: boolean
  isStatusConfirmed: boolean
  doesStatusAllowDecline: boolean
  isStatusDeclined: boolean
}

const useBookingStatus = (status?: BookingStatus): BookingStatusParams => {
  const isStatusRateConfirmationRequested = useMemo(
    () => status === BOOKING_STATUSES.RATE_CONFIRMATION_REQUESTED,
    [status]
  )

  const isStatusProposalSent = useMemo(() => status === BOOKING_STATUSES.PROPOSAL_SENT, [status])

  const isStatusCompleted = useMemo(() => status === BOOKING_STATUSES.COMPLETED, [status])

  const isStatusCancelled = useMemo(() => status === BOOKING_STATUSES.CANCELLED, [status])

  const isStatusAccepted = useMemo(() => status === BOOKING_STATUSES.ACCEPTED, [status])

  const isStatusConfirmed = useMemo(() => status === BOOKING_STATUSES.CONFIRMED, [status])

  const isStatusDeclined = useMemo(() => status === BOOKING_STATUSES.DECLINED, [status])

  const statusIn = useCallback(
    (statuses: readonly BookingStatus[]) => (status ? statuses.includes(status) : false),
    [status]
  )

  const isStatusAReviewStatus = useMemo(() => statusIn(BOOKING_REVIEW_STATUSES), [statusIn])

  const isStatusASuccessStatus = useMemo(() => statusIn(BOOKING_SUCCESS_STATUSES), [statusIn])

  const isStatusNoActionRequired = useMemo(
    () => statusIn(BOOKING_NO_ACTION_REQUIRED_STATUSES),
    [statusIn]
  )

  const isStatusForwarderActionRequired = useMemo(
    () => statusIn(BOOKING_FORWARDER_ACTION_REQUIRED_STATUSES),
    [statusIn]
  )
  const isStatusShipperActionRequired = useMemo(
    () => statusIn(BOOKING_SHIPPER_ACTION_REQUIRED_STATUSES),
    [statusIn]
  )

  const isStatusPastProposalExchange = useMemo(
    () => statusIn(BOOKING_PAST_PROPOSAL_EXCHANGE_STATUSES),
    [statusIn]
  )

  const doesStatusAllowEditOrCancel = useMemo(
    () => statusIn(BOOKING_EDITABLE_CANCELABLE_STATUSES),
    [statusIn]
  )

  const doesStatusAllowDecline = useMemo(() => statusIn(BOOKING_DECLINABLE_STATUSES), [statusIn])

  return {
    isStatusRateConfirmationRequested,
    isStatusProposalSent,
    isStatusCompleted,
    isStatusCancelled,
    isStatusAReviewStatus,
    isStatusASuccessStatus,
    isStatusAccepted,
    isStatusConfirmed,
    isStatusNoActionRequired,
    isStatusForwarderActionRequired,
    isStatusShipperActionRequired,
    isStatusPastProposalExchange,
    doesStatusAllowEditOrCancel,
    doesStatusAllowDecline,
    isStatusDeclined,
  }
}

export default useBookingStatus
