import { configureStore } from '@reduxjs/toolkit'
import { routerMiddleware } from 'connected-react-router'

import { isProd } from 'services/helpers/environment'
import history from 'services/store/history'
import rootReducer from 'services/store/root_reducer'
import localStoragePersistence, {
  PERSISTED_STATE_KEY,
} from 'services/store/middlewares/persistState'

const preloadedState = () => {
  const serializedState = localStorage.getItem(PERSISTED_STATE_KEY)
  if (serializedState === null) return {}
  return JSON.parse(serializedState)
}

const store = configureStore({
  reducer: rootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)).concat(localStoragePersistence),
  devTools: !isProd,
  preloadedState: preloadedState(),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
