import React from 'react'

import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import Button from 'components/button'
import Icon from 'components/icon'
import { getTestIdBookingShowMerchandiseContent } from 'tests/e2e/test_ids'
import {
  StyleMerchandiseItemIcon,
  StyledMerchandiseContentHeaderItem,
  StyledMerchandiseContentIconsContainer,
  StyledMerchandiseContentInfoContainer,
  StyledMerchandiseContentItem,
  StyledMerchandiseItemGroup,
} from 'views/booking/components/show/style'

import * as IconList from 'assets/icons'
import { isPresent } from 'services/helpers/values'

const InformationMerchandiseContentHeader = ({
  icon,
  type,
  quantity,
  weight,
  volume,
  packageNumber,
  amount,
  currency,
  hasAnyHazardousGoods,
  hasAnyControlledTemperatures,
  index,
  withAction,
  expanded,
  onClick,
}: {
  icon?: keyof typeof IconList
  type?: string
  quantity?: number
  weight:
    | {
        value: number | undefined
        unit?: string
      }
    | undefined
  volume:
    | {
        value: number | undefined
        unit?: string
      }
    | undefined
  packageNumber?: number
  amount: number | undefined
  currency: string
  hasAnyHazardousGoods: boolean
  hasAnyControlledTemperatures: boolean
  index: number
  withAction: boolean
  expanded: boolean | undefined
  onClick: () => void
}) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <StyledMerchandiseItemGroup>
      <StyledMerchandiseContentInfoContainer>
        {isPresent(type) && isPresent(icon) && (
          <StyledMerchandiseContentHeaderItem>
            <StyleMerchandiseItemIcon fill={theme.mediumGray} height={24} width={24} name={icon} />
            <StyledMerchandiseContentItem>{`${type} x ${quantity}`}</StyledMerchandiseContentItem>
          </StyledMerchandiseContentHeaderItem>
        )}
        <StyledMerchandiseContentHeaderItem>
          <StyleMerchandiseItemIcon fill={theme.mediumGray} width={24} height={24} name='weight' />
          <StyledMerchandiseContentItem
            data-testid={getTestIdBookingShowMerchandiseContent(index, 'summary')}
          >{`${weight?.value?.toLocaleString() ?? 0} ${weight?.unit ?? 'kg'} | ${
            volume?.value?.toLocaleString() ?? 0
          } ${volume?.unit ?? 'm³'}${
            isPresent(packageNumber)
              ? ` | ${packageNumber?.toLocaleString()} ${t(
                  'bookings.merchandise.package(s)'
                ).toLowerCase()}`
              : ''
          } | ${amount ?? 0} ${currency}`}</StyledMerchandiseContentItem>
        </StyledMerchandiseContentHeaderItem>
      </StyledMerchandiseContentInfoContainer>
      <StyledMerchandiseContentIconsContainer>
        <Icon
          testId={getTestIdBookingShowMerchandiseContent(index, 'controlledTemperaturesIcon')}
          width={24}
          height={18}
          fill={hasAnyControlledTemperatures ? theme.warn : theme.lightGray}
          name='temperature'
        />
        <Icon
          testId={getTestIdBookingShowMerchandiseContent(index, 'hazardousGoodsIcon')}
          width={24}
          height={24}
          fill={hasAnyHazardousGoods ? theme.warn : theme.lightGray}
          name='fire'
        />
        {withAction && (
          <Button
            variant='icon'
            icon={expanded ? 'arrow_top' : 'arrow_bottom'}
            onClick={() => onClick()}
          />
        )}
      </StyledMerchandiseContentIconsContainer>
    </StyledMerchandiseItemGroup>
  )
}

export default InformationMerchandiseContentHeader
