import React, { FC, useContext } from 'react'

import ShipmentTimeline from 'components/shipment_timeline'
import ShipmentContext from 'components/shipment_list_item/context'

import { isNull, isPresent } from 'services/helpers/values'

import Step from 'views/shipments/components/step'

import {
  SUMMARY_STEP_TYPES,
  STEP_TYPE_PICKUP,
  STEP_TYPE_POL,
  STEP_TYPE_POD,
  STEP_TYPE_DELIVERY,
  STEP_STATUS_CURRENT,
  STEP_STATUS_INACTIVE,
  STEP_STATUS_PAST,
  STEP_STATUS_FUTURE,
} from 'constants/shipments'

import type { StepType, Summary as SummaryType } from 'views/shipments/types/shipment'
import type { StepStatus } from 'types/shipments'

const computeStatus = (summary: SummaryType, type: StepType): StepStatus => {
  if (isNull(summary[type])) return STEP_STATUS_INACTIVE
  if (type === summary.current.stepType) return STEP_STATUS_CURRENT
  if (SUMMARY_STEP_TYPES.indexOf(summary.current.stepType) < SUMMARY_STEP_TYPES.indexOf(type))
    return STEP_STATUS_FUTURE
  return STEP_STATUS_PAST
}

const Summary: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { summary } = shipment
  const nextStep = (type: StepType) =>
    summary[SUMMARY_STEP_TYPES[SUMMARY_STEP_TYPES.indexOf(type) + 1]]
  const inTransit = summary.current.inTransit && isPresent(nextStep(summary.current.stepType))
  const currentVehicleType = summary.current.vehicleType
  return (
    <ShipmentTimeline>
      <Step
        step={summary.pickup}
        status={computeStatus(summary, STEP_TYPE_PICKUP)}
        inTransit={inTransit}
        currentVehicleType={currentVehicleType}
      />
      <Step
        step={summary.pol}
        status={computeStatus(summary, STEP_TYPE_POL)}
        inTransit={inTransit}
        currentVehicleType={currentVehicleType}
      />
      <Step
        step={summary.pod}
        status={computeStatus(summary, STEP_TYPE_POD)}
        inTransit={inTransit}
        currentVehicleType={currentVehicleType}
      />
      <Step
        step={summary.delivery}
        status={computeStatus(summary, STEP_TYPE_DELIVERY)}
        inTransit={inTransit}
        currentVehicleType={currentVehicleType}
      />
    </ShipmentTimeline>
  )
}

export default Summary
