import styled from 'styled-components'

import { listItem13, text16Regular } from 'styles/utils/texts'

export const SubRuleIcon = styled.div``

const CheckMark = styled.div`
  fill: ${({ theme }) => theme.success};
  display: inline-block;
  margin-right: 5px;
`

const Close = styled.div`
  fill: ${({ theme }) => theme.error};
  display: inline-block;
  margin-right: 5px;
`

const SubRule = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  ${listItem13};
  ${SubRuleIcon} {
    display: inline-block;
    fill: ${(props) => props.color};
    margin-right: 5px;
  }
`

const Container = styled.div`
  padding: 10px;
`

const Rule = styled.div`
  padding: 5px 0;
  ${text16Regular};
`

const SubRuleContainer = styled.div`
  margin: 5px 10px;
`

const S = {
  Close,
  CheckMark,
  Container,
  Rule,
  SubRule,
  SubRuleContainer,
  SubRuleIcon,
}

export default S
