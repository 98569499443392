import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { isLocal } from 'services/helpers/environment'
import { anyChildren } from 'services/helpers/prop_types'
import { APP_STAGES } from 'constants/stages'

const NullErrorBounday = ({ children }) => children
NullErrorBounday.propTypes = {
  children: anyChildren,
}
NullErrorBounday.defaultProps = {
  children: undefined,
}

const ErrorLogger = {
  initialize: () => {
    if (isLocal) return

    Bugsnag.start({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
      appVersion: process.env.REACT_APP_VERSION,
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.REACT_APP_STAGE,
      enabledReleaseStages: APP_STAGES,
    })
  },
  registerUserData: ({ id, firstName, email, company, roles }) => {
    if (isLocal) return

    Bugsnag.setUser({
      id,
      name: firstName,
      email,
      company,
      roles,
    })
  },
  createErrorBoundary: () =>
    isLocal ? NullErrorBounday : Bugsnag.getPlugin('react').createErrorBoundary(React),
}

export default ErrorLogger
