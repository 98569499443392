import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Icon from 'components/icon'
import { StyledWidgetArrow } from 'features/custom_kpis/components/widget/style'

import {
  StyledWidgetsShipment,
  StyledWidgetTotalContainer,
  StyledWidgetTotal,
  StyledWidgetTotalLabel,
  StyledWidgetDetails,
  StyledWidgetDetail,
  StyledWidgetIcon,
  StyledWidgetsIteDetailText,
} from 'features/custom_kpis/components/widget_shipment/style'
import { isPresent } from 'services/helpers/values'

import type { VehicleIcon } from 'constants/shipments'

export interface WidgetShipmentProps {
  total: string | number
  date: string
  place: string
  transport: string
  icon: VehicleIcon
  arrivalForecast: string
}

const WidgetShipment: FC<WidgetShipmentProps> = ({
  total,
  icon,
  transport,
  date,
  place,
  arrivalForecast,
}) => {
  const { t } = useTranslation()

  return (
    <StyledWidgetsShipment>
      <StyledWidgetTotalContainer>
        <StyledWidgetTotal>{total}</StyledWidgetTotal>
        <StyledWidgetTotalLabel>{t('customKpis.shipments')}</StyledWidgetTotalLabel>
      </StyledWidgetTotalContainer>
      <StyledWidgetDetails>
        <StyledWidgetDetail>
          <StyledWidgetIcon as={Icon} name='calendar' />
          <StyledWidgetsIteDetailText>{date}</StyledWidgetsIteDetailText>
        </StyledWidgetDetail>
        {isPresent(place) && (
          <StyledWidgetDetail>
            <StyledWidgetIcon as={Icon} name='pin_location' />
            <StyledWidgetsIteDetailText>{place}</StyledWidgetsIteDetailText>
          </StyledWidgetDetail>
        )}
        {isPresent(icon) && (
          <StyledWidgetDetail>
            <StyledWidgetIcon as={Icon} name={icon} />
            <StyledWidgetsIteDetailText>{transport}</StyledWidgetsIteDetailText>
          </StyledWidgetDetail>
        )}
        {isPresent(arrivalForecast) && (
          <StyledWidgetDetail>
            <StyledWidgetIcon as={Icon} name='optimization_timer' />
            <StyledWidgetsIteDetailText>{arrivalForecast}</StyledWidgetsIteDetailText>
          </StyledWidgetDetail>
        )}
      </StyledWidgetDetails>
      <StyledWidgetArrow as={Icon} name='arrow_right' />
    </StyledWidgetsShipment>
  )
}

export default WidgetShipment
