import styled, { css } from 'styled-components'

import { buttonSmall } from 'styles/utils/texts'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { easeQuartOut } from 'styles/utils/ease'
import StyledIcon from 'components/icon/style'

export const StyledLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.softGrayLight};
  ${borderRadiusSmall};
  transition: background-color 0.4s ${easeQuartOut}, border 0.4s ${easeQuartOut};
`

export const StyledText = styled.span`
  ${buttonSmall};
  color: ${({ theme }) => theme.textLight};
  transition: color 0.4s ${easeQuartOut};
`

export const StyledPicto = styled(StyledIcon)`
  width: 40px;
  fill: ${({ theme }) => theme.mediumGray};
  margin-bottom: 8px;
  transition: fill 0.4s ${easeQuartOut};
`

export const StyledInput = styled.input`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
  cursor: pointer;

  &:checked {
    & + ${StyledLabel} {
      background-color: ${({ theme }) => theme.primary};
      border-color: ${({ theme }) => theme.primary};
      ${StyledText} {
        color: ${({ theme }) => theme.white};
      }
      ${StyledPicto} {
        fill: ${({ theme }) => theme.white};
      }
    }
  }

  &:disabled {
    & + ${StyledLabel} {
      background-color: ${({ theme, checked }) => !checked && theme.disabled};
    }
    pointer-events: none;
  }
`

export const StyledCheckboxPicto = styled.div<{ $condensed: boolean }>`
  position: relative;
  height: 88px;
  width: 160px;

  &:hover:enabled {
    ${StyledLabel} {
      border-color: ${({ theme }) => theme.primary};
    }
  }

  ${(props) =>
    props.$condensed &&
    css`
      height: 50px;
      ${StyledLabel} {
        flex-direction: row;
      }
      ${StyledPicto} {
        width: 30px;
        margin-right: 7px;
        margin-bottom: 0;
      }
      ${StyledText} {
        margin-top: 2px;
      }
    `}
`
