import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { isAnyArray } from 'services/helpers/values'
import CustomReferences from 'views/booking/components/form/custom_references'
import { StyledGridReferences, StyledInput } from 'views/booking/components/form/style'
import useCurrentCompany from 'views/iam/hooks/use_current_company'
import Grid from 'components/grid'
import Form from 'components/form'
import Input from 'components/input'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const References: FC<BookingFormProps> = ({ filters }) => {
  const { company } = useCurrentCompany()
  const { customReferences } = company
  const { t } = useTranslation()

  const {
    clientReferenceFilter,
    clientBookingNumberFilter,
    forwarderReferenceFilter,
    customReferencesFilter,
  } = filters

  const shipperCompanyHasCustomReferences = isAnyArray(customReferences)
  // Allows forwarder to see them
  const bookingHasCustomReferences = isAnyArray(customReferencesFilter.value)

  return (
    <Form.Group title={t('bookings.references.title')}>
      <StyledGridReferences as={Grid} columns={3}>
        <Grid.Row>
          <Grid.Column>
            <StyledInput
              as={Input}
              label={t('bookings.references.clientReference')}
              placeholder={t('bookings.reference')}
              name={clientReferenceFilter.name}
              value={clientReferenceFilter.value}
              onChange={clientReferenceFilter.onChange}
              required={!clientReferenceFilter.isDisabled}
              disabled={clientReferenceFilter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              as={Input}
              label={t('bookings.references.clientBookingNumber')}
              placeholder={t('bookings.reference')}
              name={clientBookingNumberFilter.name}
              value={clientBookingNumberFilter.value}
              onChange={clientBookingNumberFilter.onChange}
              disabled={clientBookingNumberFilter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              as={Input}
              label={t('bookings.references.forwarderReference')}
              placeholder={t('bookings.reference')}
              name={forwarderReferenceFilter.name}
              value={forwarderReferenceFilter.value}
              onChange={forwarderReferenceFilter.onChange}
              disabled={forwarderReferenceFilter.isDisabled}
            />
          </Grid.Column>
        </Grid.Row>

        {(shipperCompanyHasCustomReferences || bookingHasCustomReferences) && (
          <CustomReferences customReferencesFilter={customReferencesFilter} />
        )}
      </StyledGridReferences>
    </Form.Group>
  )
}

export default References
