import React from 'react'
import ContentLoader from 'react-content-loader'

const BookingShowSkeleton = () => (
  <ContentLoader speed={2} viewBox='0 0 100 60' backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
    <rect x='3' y='3' rx='3' ry='3' width='93' height='20' />
    <rect x='3' y='26' rx='3' ry='3' width='93' height='30' />
  </ContentLoader>
)

export default BookingShowSkeleton
