import React, { useState } from 'react'

import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import Form from 'components/form'
import Grid from 'components/grid'
import Input from 'components/input'
import {
  StyledModalProfileFormButton,
  StyledModalProfileFormError,
} from 'components/modal_profile/style'
import Button from 'components/button'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { DecoratedUser } from 'views/iam/hooks/use_current_user'
import { updateUserRequest } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import {
  InformationData,
  informationSchema,
} from 'components/modal_profile/components/information_form/types'

interface InformationFormProps {
  user: DecoratedUser
}

const InformationForm: React.FC<InformationFormProps> = ({ user }) => {
  const [apiError, setApiError] = useState<string | null>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InformationData>({
    resolver: yupResolver(informationSchema),
    defaultValues: {
      lastName: user.profile.lastName,
      firstName: user.profile.firstName,
      email: user.profile.email,
    },
  })

  const submit = (data: InformationData) => {
    dispatch(updateUserRequest(data))
      .unwrap()
      .then(() => {
        setApiError(null)
        dispatch(
          addNotification({
            type: 'success',
            title: `${t('profile.tabs.information.form.updateInformation')}`,
            text: `${t('profile.tabs.information.form.informationUpdated')}`,
          })
        )
      })
      .catch(({ message }) => setApiError(message))
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Form.Group>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Input
              label={t('profile.tabs.information.form.lastName')}
              type='text'
              id='lastName'
              error={errors.lastName?.message ? t(errors.lastName?.message) : undefined}
              {...register('lastName')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Input
              label={t('profile.tabs.information.form.firstName')}
              type='text'
              id='firstName'
              error={errors.firstName?.message ? t(errors.firstName?.message) : undefined}
              {...register('firstName')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column padded={false}>
            <Input
              label={t('profile.tabs.information.form.email')}
              type='text'
              id='email'
              error={errors.email?.message ? t(errors.email?.message) : undefined}
              {...register('email')}
            />
          </Grid.Column>
        </Grid.Row>
      </Form.Group>
      <StyledModalProfileFormError>{apiError}</StyledModalProfileFormError>
      <StyledModalProfileFormButton>
        <Button padded text={t('actions.save')} variant='highlight' type='submit' />
      </StyledModalProfileFormButton>
    </Form>
  )
}

export default InformationForm
