import styled, { css } from 'styled-components'

import { tablet } from 'styles/utils/breakpoints'
import { easeQuartOut } from 'styles/utils/ease'
import { zIndexHeaderNavMobile } from 'styles/utils/z_index'

export const StyledList = styled.ul<{ testId: string }>`
  @media ${tablet.down} {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 67px;
    display: flex;
    flex-direction: column;
    z-index: ${zIndexHeaderNavMobile};
    background: ${({ theme }) => theme.white};
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.5s ${easeQuartOut};
  }

  @media ${tablet.up} {
    display: flex;
  }
`
export const StyledNavigation = styled.nav<{ $open: boolean }>`
  ${(props) =>
    props.$open &&
    css`
      transition: transform 0.8s ${easeQuartOut};

      ${StyledList} {
        transform: translate3d(0, 0, 0);
      }
    `}
`
