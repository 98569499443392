/* eslint-disable no-param-reassign */

import { toSnakeCaseKeys } from 'services/helpers/values'

const toSnakeCaseRequestMiddleware = (config) => {
  if (config.data instanceof FormData) return config
  if (config.params) {
    config.params = toSnakeCaseKeys(config.params)
  }
  if (config.data) {
    config.data = toSnakeCaseKeys(config.data)
  }
  return config
}
export default toSnakeCaseRequestMiddleware
