import styled, { css } from 'styled-components'

import { borderRadiusSmall } from 'styles/utils/border_radius'

export const StyledWrapper = styled.div``

export const StyledMenuDropdown = styled.ul<{
  $open: boolean
  $dropdownPosition: 'default' | 'right'
}>`
  ${borderRadiusSmall}
  padding: 12px 6px;
  display: inline-block;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  visibility: hidden;
  position: absolute;
  ${({ $dropdownPosition }) =>
    $dropdownPosition === 'right' ? 'left: auto; right: 0;' : 'left:0;'}
  min-width: 150px;
  top: 100%;
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.softGray};
  box-shadow: 5px 5px 5px ${({ theme }) => theme.softGray};
  margin-top: 5px;
  z-index: 10;
  border-radius: 20px;
  ${(props) =>
    props.$open &&
    css`
      visibility: visible;
    `};
`

export const StyledMenu = styled.div`
  position: relative;
`
