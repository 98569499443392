import React, { FC, useContext } from 'react'

import FavoriteComponent from 'components/favorite'
import ShipmentContext from 'components/shipment_list_item/context'

import useToggleFavorite from 'components/shipment_list_item/hooks/use_toggle_favorite'

const Favorite: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { id } = shipment
  const isActive = shipment.favorite
  const toggleFavorite = useToggleFavorite({ id, isActive })

  return <FavoriteComponent active={isActive} onClick={toggleFavorite} />
}

export default Favorite
