import styled, { css } from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'
import StyledIcon from 'components/icon/style'

const StyledFavorite = styled.button<{ $active: boolean; $big: boolean }>`
  stroke-width: 1.5px;
  width: 18px;
  height: 18px;
  cursor: pointer;

  ${StyledIcon} {
    fill: ${({ theme }) => theme.white};
    stroke: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut}, stroke 0.5s ${easeQuartOut};
  }

  &:hover {
    ${StyledIcon} {
      stroke: ${({ theme }) => theme.yellow};
    }
  }

  ${(props) =>
    props.$active &&
    css`
      ${StyledIcon} {
        fill: ${({ theme }) => theme.yellow};
        stroke: ${({ theme }) => theme.yellow};
      }
    `}

  ${(props) =>
    props.$big &&
    css`
      width: 25px;
      height: 25px;
    `}
`

export default StyledFavorite
