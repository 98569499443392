import styled from 'styled-components'

import { shadow } from 'styles/utils/shadow'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { desktopLarge } from 'styles/utils/breakpoints'
import { StyledSpan } from 'components/button/style'
import { textSmallDescription } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'

export const TrustedRoutesSidebarWidth = 370

const Sidebar = styled.div<{ $visible: boolean }>`
  width: ${TrustedRoutesSidebarWidth}px;
  flex-shrink: 0;
  ${shadow};
  ${borderRadiusSmall};
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;

  @media ${desktopLarge.down} {
    ${({ $visible }) =>
      !$visible &&
      `
      opacity: 0;
      pointer-events: none;
      transform: translate3d(0, -20px, 0);
   `}
    ${({ $visible }) =>
      $visible &&
      `
      opacity: 1;
      pointer-events: all;
      transform: translate3d(0, 0, 0);
    `}

    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 2px 25px);
  }
`

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  ${StyledSpan} {
    ${textSmallDescription};
    color: ${({ theme }) => theme.textLight};
  }
  ${StyledIcon} {
    margin-right: 12px;
    width: 10px;
    height: 10px;
    fill: ${({ theme }) => theme.mediumGray};
  }

  &:hover {
    color: ${({ theme }) => theme.primary};

    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }

  @media ${desktopLarge.up} {
    display: none;
  }
`

const S = {
  Sidebar,
  Close,
}

export default S
