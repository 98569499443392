import React, { FC } from 'react'

import { StyledItem, StyledItemLink } from 'components/navigation_item/style'
import { HeaderVariant } from 'components/header/types'

export interface NavigationItemProps {
  active?: boolean
  to: string | { pathname: string }
  text?: string
  variant?: HeaderVariant
  target?: string
  testId?: string
}

const NavigationItem: FC<NavigationItemProps> = ({
  active = false,
  to,
  target,
  text,
  variant = 'dark',
  testId,
}) => (
  <StyledItem $active={active} $variant={variant} data-testid={testId}>
    <StyledItemLink target={target} to={to}>
      {text}
    </StyledItemLink>
  </StyledItem>
)

export default NavigationItem
