import styled from 'styled-components'

const SaveContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0 5px;
`

const S = { SaveContainer }

export default S
