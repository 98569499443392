import styled from 'styled-components'

import { text15Regular } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'
import StyledIcon from 'components/icon/style'

// Saved filters

export const StyledSavedFiltersItem = styled.div`
  text-align: left;
  display: block;
  width: 100%;
`

export const StyledSavedFiltersTitle = styled.div`
  ${text15Regular}
  color: ${({ theme }) => theme.textDark};
  margin-bottom: 5px;
`

export const StyledSavedFiltersArrow = styled.div`
  transform: rotate(-90deg);
`

export const StyledSavedFiltersTrash = styled.button``

export const StyledSavedFiltersSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  padding: 15px 50px 15px 20px;
  position: relative;

  ${StyledSavedFiltersArrow} , ${StyledSavedFiltersTrash} {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -12px;

    ${StyledIcon} {
      width: 24px;
      height: 24px;
      fill: ${({ theme }) => theme.mediumGray};
      transition: fill 0.5s ${easeQuartOut};
    }

    &:hover {
      ${StyledIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    }
  }
`
