import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'
import {
  selectBookings,
  selectBookingsStatus,
  selectBookingsTotalCount,
} from 'views/bookings/slice'

const useBookings = () => {
  const bookings = useSelector((state) => selectBookings(state))
  const status = useStatus(useSelector((state) => selectBookingsStatus(state)))
  const totalCount = useSelector((state) => selectBookingsTotalCount(state))
  return [bookings, status, totalCount]
}

export default useBookings
