import styled from 'styled-components'

import Input from 'components/input'
import Button from 'components/button'

import { textSmallDescription, h1 } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'
import { desktop } from 'styles/utils/breakpoints'
import NotifMessage from 'components/notif_message'

export const StyledForm = styled.form`
  width: 100%;
`

export const StyledLoginFormFormTitle = styled.h1`
  ${h1};
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 35px;
  }
`

export const StyledLoginFormInput = styled(Input)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`

export const StyledLoginFormError = styled(NotifMessage)`
  margin-bottom: 25px;
`

export const StyledLoginFormActions = styled.div`
  margin-top: 30px;

  @media ${desktop.down} {
    text-align: center;
  }

  @media ${desktop.up} {
    margin-top: 34px;
    display: flex;
    align-items: center;
  }
`
export const StyledLoginFormButton = styled(Button)`
  width: 100%;
`

export const StyledLoginFormLink = styled.span`
  cursor: pointer;
  ${textSmallDescription};
  font-weight: 600;
  color: ${({ theme }) => theme.primary};
  margin-top: 20px;
  display: block;
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.primary50};
  }
`
