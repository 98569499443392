import React, { FC, useContext, useMemo } from 'react'

import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'

import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'

import StyledShipmentList from 'components/shipment_list/style'

export { default as ShipmentListSkeleton } from 'components/shipment_list/skeleton'

export interface ShipmentListProps {
  children: any
}

const ShipmentList: FC<ShipmentListProps> = ({ children }) => {
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])
  return <StyledShipmentList $condensed={isCondensed}>{children}</StyledShipmentList>
}

export default ShipmentList
