import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  validateProposal,
  acceptBooking,
  confirmBooking,
  cancelBooking,
  declineBooking,
} from 'views/booking/slices/booking_slice'
import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'
import { addNotification } from 'views/notifications/slice'

import BookingContext from 'views/booking/contexts/booking_context'
import ActionModalContentComponent from 'views/booking/components/action_modal_content'
import AcceptingModalContent from 'views/booking/components/accepting_modal_content'

const actionsMap = {
  [BOOKING_EXCHANGE_ACTION_KEYS.VALIDATE_PROPOSAL]: validateProposal,
  [BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING]: acceptBooking,
  [BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING]: confirmBooking,
  [BOOKING_EXCHANGE_ACTION_KEYS.CANCEL_BOOKING]: cancelBooking,
  [BOOKING_EXCHANGE_ACTION_KEYS.DECLINE_BOOKING]: declineBooking,
}

const useBookingExchangeAction = (actionKey, onSuccessAction) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { exchangeActionsParams } = useContext(BookingContext)
  const [action, setAction] = useState()

  useEffect(() => {
    setAction(() => actionsMap[actionKey])
  }, [actionKey])

  const onClickAction = () => {
    let notifActionKey = actionKey
    if (action === confirmBooking) {
      notifActionKey = BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING
    }
    dispatch(action(exchangeActionsParams[actionKey]))
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t(`bookings.alerts.${notifActionKey}.title`),
            text: t(`bookings.alerts.${notifActionKey}.successMessage`),
          })
        )
        onSuccessAction()
      })
      .catch((error) => {
        const errorDetails =
          error.message?.errorType === 'validation' && error.message?.details.length > 0
            ? error.message?.details[0].message
            : null
        dispatch(
          addNotification({
            type: 'alert',
            title: t(`bookings.alerts.${notifActionKey}.title`),
            text: errorDetails || t(`bookings.alerts.${notifActionKey}.errorMessage`),
          })
        )
      })
  }

  const modalHeader = t(`bookings.exchangeModal.${actionKey}.title`)

  const modalContentMap = useMemo(
    () => ({
      [BOOKING_EXCHANGE_ACTION_KEYS.VALIDATE_PROPOSAL]: () => (
        <ActionModalContentComponent actionKey={actionKey} />
      ),
      [BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING]: () => (
        <AcceptingModalContent actionKey={actionKey} setAction={setAction} />
      ),
      [BOOKING_EXCHANGE_ACTION_KEYS.CONFIRM_BOOKING]: () => (
        <ActionModalContentComponent actionKey={actionKey} />
      ),
      [BOOKING_EXCHANGE_ACTION_KEYS.CANCEL_BOOKING]: () => (
        <ActionModalContentComponent actionKey={actionKey} />
      ),
      [BOOKING_EXCHANGE_ACTION_KEYS.DECLINE_BOOKING]: () => (
        <ActionModalContentComponent actionKey={actionKey} />
      ),
    }),
    [actionKey]
  )

  const ModalContent = modalContentMap[actionKey]

  return { onClickAction, modalHeader, ModalContent }
}

export default useBookingExchangeAction
