import React from 'react'

import { useTranslation } from 'react-i18next'

import DateHelper from 'services/helpers/date_helper'

import {
  StyledBookingLastEvent,
  StyledBookingLastEventChangesContainer,
  StyledBookingLastEventDate,
  StyledBookingLastEventItem,
  StyledBookingLastEventName,
  StyledBookingLastEventTitle,
} from 'components/booking_last_event/style'

import AccordionItem from 'components/accordion_item'

import { BOOKING_ACTIONS } from 'constants/bookings'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import { toCamelCase } from 'services/helpers/values'

import Table from 'components/table'
import useLastEventFormattedChanges, {
  LastEventFormattedChange,
} from 'components/booking_last_event/hooks/use_last_event_formatted_changes'

import { LastEvent } from 'components/booking_last_event/types'

import LastEventFormattedValuesCellContent from 'components/booking_last_event/last_event_formatted_values_cell_content'

const generateLastEventTitle = (
  eventAction: string,
  eventMeta: string | null | undefined,
  documentTypes: Record<string, any>
) =>
  ({
    [BOOKING_ACTIONS.CREATION]: 'Booking request sent',
    [BOOKING_ACTIONS.EDIT]: 'Booking edited',
    [BOOKING_ACTIONS.ACCEPT]: 'Booking Accepted',
    [BOOKING_ACTIONS.CONFIRM]: 'Booking Confirmed',
    [BOOKING_ACTIONS.CANCEL]: 'Booking Cancelled',
    [BOOKING_ACTIONS.DECLINE]: 'Booking Declined',
    [BOOKING_ACTIONS.REQUEST_RATE_CONFIRMATION]: 'Rate confirmation requested',
    [BOOKING_ACTIONS.SEND_PROPOSAL]: 'New proposal sent',
    [BOOKING_ACTIONS.VALIDATE_BOOKING_PROPOSAL]: 'Proposal validated',
    [BOOKING_ACTIONS.DOCUMENT_CREATE]: `Document added: ${documentTypes[toCamelCase(eventMeta)]}`,
    [BOOKING_ACTIONS.DOCUMENT_DELETE]: `Document removed: ${documentTypes[toCamelCase(eventMeta)]}`,
  }[eventAction])

const BookingLastEvent: React.FC<{ event: LastEvent }> = ({ event }) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const { action, meta, createdAt, source } = event
  const { formattedChanges } = useLastEventFormattedChanges(event)

  const title = generateLastEventTitle(action, meta, s('documentTypes'))

  return (
    <StyledBookingLastEventItem>
      <AccordionItem
        small
        disabled={action !== BOOKING_ACTIONS.EDIT || formattedChanges.length === 0}
        heading={
          <StyledBookingLastEvent>
            <StyledBookingLastEventTitle>{title}</StyledBookingLastEventTitle>
            <StyledBookingLastEventDate>
              {new DateHelper(createdAt).toSlashAndHour()}
            </StyledBookingLastEventDate>
            <StyledBookingLastEventName>{source}</StyledBookingLastEventName>
          </StyledBookingLastEvent>
        }
      >
        <StyledBookingLastEventChangesContainer>
          <Table>
            <Table.Head>
              <Table.HeadCell>{t('bookings.lastEvents.category')}</Table.HeadCell>
              <Table.HeadCell>{t('bookings.lastEvents.oldValue')}</Table.HeadCell>
              <Table.HeadCell>{t('bookings.lastEvents.newValue')}</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {formattedChanges.map(
                (
                  { category, oldValues, newValues, withTooltip }: LastEventFormattedChange,
                  index: number
                ) => (
                  <Table.Row
                    odd={index % 2 === 1}
                    alignedTop
                    key={`last-event-${createdAt}-${category}`}
                  >
                    <Table.Cell>{t(`bookings.lastEvents.categories.${category}`)}</Table.Cell>
                    <Table.Cell>
                      <LastEventFormattedValuesCellContent
                        values={oldValues}
                        withTooltip={withTooltip}
                        category={category}
                        createdAt={createdAt}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <LastEventFormattedValuesCellContent
                        values={newValues}
                        withTooltip={withTooltip}
                        category={category}
                        createdAt={createdAt}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </StyledBookingLastEventChangesContainer>
      </AccordionItem>
    </StyledBookingLastEventItem>
  )
}

export default BookingLastEvent
