import styled, { css } from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'
import { text14Regular } from 'styles/utils/texts'

export const StyledTabNavItemButton = styled.button<{ $disabled: boolean }>`
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};
  padding: 14px;
  display: inline-block;
  position: relative;
  transition: color 0.5s ${easeQuartOut};

  &:hover,
  &:focus {
    color: ${({ theme, $disabled }) => !$disabled && theme.textDark};
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: ${({ theme }) => theme.primary};
    position: absolute;
    left: 0;
    bottom: -1px;
    transform: scaleX(0);
    transition: transform 0.5s ${easeQuartOut};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`

export const StyledTabNavItem = styled.li<{ active: boolean }>`
  list-style: none;

  ${(props) =>
    props.active &&
    css`
      ${StyledTabNavItemButton} {
        color: ${props.theme.textDark};
        &:after {
          transform: scaleX(1);
        }
      }
    `}
`
