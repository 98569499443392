import tinycolor from 'tinycolor2'

/**
 *
 * @param {string} color - Color value (https://github.com/bgrins/TinyColor#accepted-string-input)
 * @param {number} alpha - Normalized alpha value
 * @returns - A RGB color string that is color parameter modified with alpha
 */
const createAlphaColor = (color: string, alpha = 1): string => {
  const tinyColor = tinycolor(color)
  const rgb = tinyColor.setAlpha(alpha).toRgb()

  return tinycolor({
    r: rgb.r * alpha + 255 * (1 - alpha),
    g: rgb.g * alpha + 255 * (1 - alpha),
    b: rgb.b * alpha + 255 * (1 - alpha),
  }).toString()
}

/**
 *
 * @param {string} name - Color name
 * @param {string} color - Color value (https://github.com/bgrins/TinyColor#accepted-string-input)
 * @param {array} alphas - List of normalized alphas value
 * @returns - An object which, for each alphas passed, has a property named <color name><alpha> which is equal to the color with opacity
 */
type CreateAlphaColors = Record<string, string>
const createAlphaColors = (
  name: string,
  color: string,
  alphas: number[] = [0.05, 0.1, 0.5, 0.75]
): CreateAlphaColors =>
  alphas.reduce<CreateAlphaColors>((acc, alpha) => {
    const colorName = `${name}${alpha * 100}`
    acc[colorName] = createAlphaColor(color, alpha)

    return acc
  }, {})

/**
 *
 * @param {string} color - Color value (https://github.com/bgrins/TinyColor#accepted-string-input)
 * @param {object} param1 - An object with h, s and l porperties (h property range is 0-360, s and l properties ranges are 0-1)
 * @returns - A RGBA color that is color parameter modified with param1
 */
const addHsl = (
  color: string,
  { h = 0, s = 0, l = 0 }: { h: number; s: number; l: number }
): string => {
  const hsl = tinycolor(color).toHsl()

  return tinycolor({
    h: hsl.h + h,
    s: hsl.s + s,
    l: hsl.l + l,
  }).toRgbString()
}

export interface DefaultTheme {
  primary: string
  secondary: string
  warn: string
  error: string
  success: string
  tip: string
}
export const defaultTheme: DefaultTheme = {
  primary: '#52B4BD',
  secondary: '#0049C6',
  warn: '#FF754C',
  error: '#F94040',
  success: '#7FBA7A',
  tip: '#FFBE16',
}
interface Gradient {
  from: string
  fromOpacity: string
  to: string
  toOpacity: string
}
export type ThemeColors<T extends keyof FinalTheme> = keyof Pick<FinalTheme, T>

export interface FinalTheme extends DefaultTheme {
  textDark: string
  textLight: string
  mediumGray: string
  mediumGray25: string
  mediumGray50: string
  lightGray: string
  lightWarmGray: string
  softGray: string
  softGrayLight: string
  white: string
  clearGray: string
  bgInput: string
  bgInput30: string
  bgInput75: string
  yellow: string
  blueSea: string
  darkBlue: string
  gradients: {
    light: Gradient
    medium: Gradient
    dark: Gradient
  }
  primary5: string
  primary10: string
  primary25: string
  primary50: string
  primary75: string
  secondary5: string
  secondary10: string
  secondary50: string
  secondary75: string
  warn5: string
  warn10: string
  warn50: string
  warn75: string
  error5: string
  error10: string
  error50: string
  error75: string
  success5: string
  success10: string
  success50: string
  success75: string
  tip5: string
  tip10: string
  tip50: string
  tip75: string
  disabled: string
}

export const computeTheme = ({ theme }: { theme: DefaultTheme }): FinalTheme => {
  const finalTheme = {
    ...theme,
    ...createAlphaColors('primary', theme.primary, [0.05, 0.1, 0.25, 0.5, 0.75]),
    ...createAlphaColors('secondary', theme.secondary, [0.05, 0.1, 0.5, 0.75]),
    ...createAlphaColors('warn', theme.warn, [0.05, 0.1, 0.5, 0.75]),
    ...createAlphaColors('error', theme.error, [0.05, 0.1, 0.5, 0.75]),
    ...createAlphaColors('success', theme.success, [0.05, 0.1, 0.5, 0.75]),
    ...createAlphaColors('tip', theme.tip, [0.05, 0.1, 0.5, 0.75]),
    textDark: '#3E3F48',
    textLight: '#808191',
    mediumGray: '#B2B3BD',
    ...createAlphaColors('mediumGray', '#B2B3BD', [0.25, 0.5]),
    lightGray: '#E3E6EC',
    disabled: '#E3E6EC',
    lightWarmGray: '#FEFBFA',
    softGray: '#E4E4E4',
    softGrayLight: '#EFEFEF',
    white: '#fff',
    clearGray: '#F5F6F8',
    bgInput: '#E4E4E4',
    ...createAlphaColors('bgInput', '#E4E4E4', [0.3, 0.75]),
    yellow: '#FFBE16',
    blueSea: '#DFEDFA',
    darkBlue: '#0D4B5C',
    gradients: {
      light: {
        from: addHsl(theme.primary, { h: 0, s: 0.35, l: 0.2 }),
        to: addHsl(theme.warn, { h: 29, s: 0, l: -0.02 }),
      } as Gradient,
      medium: {
        from: addHsl(theme.warn, { h: 15, s: 0, l: -0.15 }),
        to: addHsl(theme.primary, { h: -2, s: 0.45, l: -0.02 }),
      } as Gradient,
      dark: {
        from: theme.warn,
        to: theme.primary,
      } as Gradient,
    },
  }

  finalTheme.gradients.light.fromOpacity = createAlphaColor(finalTheme.gradients.light.from, 0.1)
  finalTheme.gradients.light.toOpacity = createAlphaColor(finalTheme.gradients.light.to, 0.1)

  finalTheme.gradients.medium.fromOpacity = createAlphaColor(finalTheme.gradients.medium.from, 0.1)
  finalTheme.gradients.medium.toOpacity = createAlphaColor(finalTheme.gradients.medium.to, 0.1)

  finalTheme.gradients.dark.fromOpacity = createAlphaColor(finalTheme.gradients.dark.from, 0.1)
  finalTheme.gradients.dark.toOpacity = createAlphaColor(finalTheme.gradients.dark.to, 0.1)

  return finalTheme as FinalTheme
}
