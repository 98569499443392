import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from 'components/button'
import Select from 'components/select'
import Tabs, { Tab } from 'components/tabs'
import InputCheckbox from 'components/input_checkbox'
import Modal from 'components/modal'
import IconTooltip from 'components/icon_tooltip'
import {
  StyledModalWidget,
  StyledModalForm,
  StyledModalFormSelect,
  StyledModalFormCheckboxesContainer,
  StyledModalFormCheckboxes,
  StyledModalFormLabel,
  StyledModalFormKpiTypeSelectContainer,
} from 'components/modal_widget/style'
import { selectCustomKpi, unsetSelectedCustomKpiId } from 'features/custom_kpis/store/slice'
import { addNotification } from 'views/notifications/slice'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { isPresent, readonlyArrayIncludes, toCamelCase, toSnakeCase } from 'services/helpers/values'
import useShipmentSelect from 'services/hooks/use_shipment_select'
import useFilter from 'services/hooks/use_filter'
import useCategory from 'services/hooks/use_category'
import useForm from 'services/hooks/use_form'
import useTracker from 'services/analytics/hooks/use_tracker'

import {
  STEP_TYPE_DELIVERY,
  STEP_TYPE_PICKUP,
  STEP_TYPE_POD,
  STEP_TYPE_POL,
  SummaryStepType,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_PARCEL,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_SEA,
} from 'constants/shipments'
import { WITH_CARBON_FOOTPRINT, WITH_PARCEL_TRACKING } from 'constants/organization_features'
import useModal from 'components/modal/hooks/use_modal'
import { TEST_ID_SAVE_CREATE_WIDGET_BUTTON } from 'tests/e2e/test_ids'
import { RootState } from 'services/store/store'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { createCustomKpi, updateCustomKpi } from 'features/custom_kpis/services/custom_kpi_service'
import {
  ARRIVAL_FORECAST_DELAYED,
  ARRIVAL_FORECAST_EARLY,
  ARRIVAL_FORECAST_ON_TIME,
  CARBON_FOOTPRINT_TIME_RANGES,
  CustomKpiType,
  SHIPMENT_TIME_RANGES,
  TIME_RANGE_ALL_ACTIVE,
  TIME_RANGE_THIS_MONTH,
  TYPE_CARBON_FOOTPRINT,
  TYPE_SHIPMENT,
} from 'features/custom_kpis/types/types'

interface ModalWidgetProps {
  id?: number
}

const ModalWidget: React.FC<ModalWidgetProps> = ({ id }) => {
  const { features } = useOrganizationCan()
  const hasCarbonFootprintFeature = features(WITH_CARBON_FOOTPRINT)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { setOpen } = useModal('widget')
  const { s } = useStaticLocales()
  const timeRanges = s('timeRanges')
  const { track } = useTracker()

  const kpiTypesMapping = {
    [TYPE_SHIPMENT]: t('customKpis.form.kpiType.shipment'),
    [TYPE_CARBON_FOOTPRINT]: t('customKpis.form.kpiType.carbonFootprint'),
  }

  const kipTypes = [
    { label: kpiTypesMapping[TYPE_SHIPMENT], value: TYPE_SHIPMENT },
    {
      label: `${kpiTypesMapping[TYPE_CARBON_FOOTPRINT]}${
        !hasCarbonFootprintFeature ? ` (${t('customKpis.premiumMembers')})` : ''
      }`,
      value: TYPE_CARBON_FOOTPRINT,
      isDisabled: !hasCarbonFootprintFeature,
    },
  ]

  const shipmentTimeRangeOptions = Object.entries(timeRanges)
    .filter(([key]) => readonlyArrayIncludes(SHIPMENT_TIME_RANGES, toSnakeCase(key)))
    .map(([key, value]) => ({
      value: toSnakeCase(key),
      label: value,
    }))
  const carbonFootprintTimeRangeOptions = Object.entries(timeRanges)
    .filter(([key]) => readonlyArrayIncludes(CARBON_FOOTPRINT_TIME_RANGES, toSnakeCase(key)))
    .map(([key, value]) => ({
      value: toSnakeCase(key),
      label: value,
    }))
  const isEdit = isPresent(id)
  const customKpi = useSelector((state: RootState) => selectCustomKpi(state, id))

  const { fetchOptions, fetchAllOptions, fetchedOptionsFormat, toIndexedSelectValue } =
    useShipmentSelect()

  const onWidgetTypeChange = ({ id: value }: { id: CustomKpiType }) => {
    reset()
    kpiTypeFilter.setValue({ label: kpiTypesMapping[value], value })
    transportationDateFilter.setValue(
      value === TYPE_SHIPMENT
        ? { label: TIME_RANGE_ALL_ACTIVE, value: TIME_RANGE_ALL_ACTIVE }
        : { label: TIME_RANGE_THIS_MONTH, value: TIME_RANGE_THIS_MONTH }
    )
  }
  const onStepTypeChange = ({ id: value }: { id: SummaryStepType }) => {
    stepTypeFilter.setValue({ label: value, value })
    addressFilter.reset()
    transportationDateFilter.reset()
  }

  const kpiTypeFilter = useFilter({
    name: 'kpiType',
    type: 'select',
    defaultValue: { value: TYPE_SHIPMENT, label: t('customKpis.form.kpiType.shipment') },
  })

  const kpiTypeCategory = useCategory({
    name: 'kpiType',
    filters: [kpiTypeFilter],
    toQueryParams: (_filters, filtersHash) =>
      isPresent(filtersHash.kpiType.value)
        ? { kpi_type: filtersHash.kpiType.value.value }
        : { kpi_type: null },
  })

  const stepTypeFilter = useFilter({
    name: 'stepType',
    type: 'select',
    defaultValue: { label: STEP_TYPE_PICKUP, value: STEP_TYPE_PICKUP },
  })
  const stepTypeCategory = useCategory({
    name: 'stepType',
    filters: [stepTypeFilter],
    toQueryParams: (_filters, filtersHash) =>
      isPresent(filtersHash.stepType.value)
        ? { step_type: filtersHash.stepType.value.value }
        : { step_type: null },
  })

  const transportModeSeaFilter = useFilter({ name: 'transportModeSea', type: 'checkbox' })
  const transportModeAirFilter = useFilter({ name: 'transportModeAir', type: 'checkbox' })
  const transportModeRailFilter = useFilter({ name: 'transportModeRail', type: 'checkbox' })
  const transportModeRoadFilter = useFilter({ name: 'transportModeRoad', type: 'checkbox' })
  const transportModeParcelFilter = useFilter({ name: 'transportModeParcel', type: 'checkbox' })

  const transportModesMap = {
    transportModeSea: TRANSPORT_TYPE_SEA,
    transportModeAir: TRANSPORT_TYPE_AIR,
    transportModeRail: TRANSPORT_TYPE_RAIL,
    transportModeRoad: TRANSPORT_TYPE_ROAD,
    transportModeParcel: TRANSPORT_TYPE_PARCEL,
  }
  const transportModeCategory = useCategory({
    name: 'transportMode',
    filters: [
      transportModeSeaFilter,
      transportModeAirFilter,
      transportModeRailFilter,
      transportModeRoadFilter,
      transportModeParcelFilter,
    ],
    toQueryParams: (filters) => ({
      transport_modes: filters
        .filter((f) => f.isPresent)
        .map(({ name }) => transportModesMap[name as keyof typeof transportModesMap]),
    }),
  })

  const arrivalForecastEarlyFilter = useFilter({ name: 'arrivalForecastEarly', type: 'checkbox' })
  const arrivalForecastOnTimeFilter = useFilter({ name: 'arrivalForecastOnTime', type: 'checkbox' })
  const arrivalForecastDelayedFilter = useFilter({
    name: 'arrivalForecastDelayed',
    type: 'checkbox',
  })
  const arrivalForecastMap = {
    arrivalForecastEarly: ARRIVAL_FORECAST_EARLY,
    arrivalForecastOnTime: ARRIVAL_FORECAST_ON_TIME,
    arrivalForecastDelayed: ARRIVAL_FORECAST_DELAYED,
  }
  const arrivalForecastCategory = useCategory({
    name: 'arrivalForecast',
    filters: [
      arrivalForecastEarlyFilter,
      arrivalForecastOnTimeFilter,
      arrivalForecastDelayedFilter,
    ],
    toQueryParams: (filters) => ({
      arrival_forecasts: filters
        .filter((f) => f.isPresent)
        .map(({ name }) => arrivalForecastMap[name as keyof typeof arrivalForecastMap]),
    }),
  })

  const addressFilter = useFilter({
    name: 'addressName',
    type: 'select',
    defaultValue: customKpi?.addressName
      ? { label: customKpi?.addressName, value: customKpi.addressName }
      : undefined,
  })

  const addressCategory = useCategory({
    name: 'addressName',
    filters: [addressFilter],
    toQueryParams: (_filters, filtersHash) =>
      isPresent(filtersHash.addressName.value)
        ? { address_name: filtersHash.addressName.value.value }
        : { address_name: null },
  })

  const transportationDateFilter = useFilter({
    name: 'transportationDate',
    type: 'select',
    defaultValue: { label: TIME_RANGE_ALL_ACTIVE, value: TIME_RANGE_ALL_ACTIVE },
  })
  const transportationDateCategory = useCategory({
    name: 'transportationDate',
    filters: [transportationDateFilter],
    toQueryParams: (_filters, filtersHash) =>
      isPresent(filtersHash.transportationDate.value)
        ? { transportation_date: filtersHash.transportationDate.value.value }
        : { transportation_date: null },
  })

  const categories = [
    transportModeCategory,
    arrivalForecastCategory,
    kpiTypeCategory,
    stepTypeCategory,
    addressCategory,
    transportationDateCategory,
  ]
  const { apply, reset, isEdited, queryParams, editedQueryParams } = useForm({
    categories,
    onApply: () => {
      // TODO: The typing is incomplete and will be rework when you switch to react-hook-form
      const queryParamsToTrackerTag = (params: any) =>
        Object.fromEntries(Object.entries(params).filter(([, value]: any) => isPresent(value)))
      track('Dashboard / Widget', {
        action: isEdit ? 'edit' : 'create',
        type: kpiTypeFilter.value?.value,
        categories: isEdit
          ? queryParamsToTrackerTag(queryParams)
          : queryParamsToTrackerTag(editedQueryParams),
      })
      if (isEdit) {
        update(id)
      } else {
        create()
      }
    },
  })

  const create = () => {
    dispatch(createCustomKpi({ customKpi: queryParams }))
      .unwrap()
      .then(() => {
        const type = t(`customKpis.form.kpiType.${toCamelCase(kpiTypeFilter.value?.value)}`)
        dispatch(
          addNotification({
            type: 'success',
            title: t('customKpis.actions.createWidget'),
            text: t('customKpis.actions.widgetCreated', {
              type,
            }),
          })
        )
      })
      .then(() => setOpen(false))
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const update = (customKpiId: number) => {
    dispatch(updateCustomKpi({ id: customKpiId, changes: editedQueryParams }))
      .unwrap()
      .then(() => {
        const type = t(`customKpis.form.kpiType.${toCamelCase(kpiTypeFilter.value?.value)}`)
        dispatch(
          addNotification({
            type: 'success',
            title: t('customKpis.actions.editWidget'),
            text: t('customKpis.actions.widgetEdited', { type }),
          })
        )
      })
      .then(() => setOpen(false))
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  }

  const transportationDateValue = useMemo(
    () =>
      transportationDateFilter.isPresent
        ? {
            value: transportationDateFilter.value?.value,
            label: timeRanges[toCamelCase(transportationDateFilter.value?.value)],
          }
        : null,
    [timeRanges, transportationDateFilter]
  )

  useEffect(() => {
    if (isEdit) {
      if (isPresent(customKpi)) {
        kpiTypeFilter.setValue({ label: customKpi.kpiType, value: customKpi.kpiType })
        stepTypeFilter.setValue({ label: customKpi.stepType, value: customKpi.stepType })
        transportationDateFilter.setValue({
          label: customKpi?.transportationDate,
          value: customKpi?.transportationDate,
        })
        if (isPresent(customKpi.addressName)) {
          addressFilter.setValue({ label: customKpi.addressName, value: customKpi.addressName })
        }
        customKpi.transportModes.forEach((transportMode) =>
          ({
            [TRANSPORT_TYPE_SEA]: transportModeSeaFilter,
            [TRANSPORT_TYPE_AIR]: transportModeAirFilter,
            [TRANSPORT_TYPE_RAIL]: transportModeRailFilter,
            [TRANSPORT_TYPE_ROAD]: transportModeRoadFilter,
            [TRANSPORT_TYPE_PARCEL]: transportModeParcelFilter,
          }[transportMode].setValue(true))
        )
        customKpi.arrivalForecasts.forEach((forecast) =>
          ({
            [ARRIVAL_FORECAST_EARLY]: arrivalForecastEarlyFilter,
            [ARRIVAL_FORECAST_ON_TIME]: arrivalForecastOnTimeFilter,
            [ARRIVAL_FORECAST_DELAYED]: arrivalForecastDelayedFilter,
          }[forecast].setValue(true))
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, id])

  const onSubmit = useCallback(() => apply(), [apply])
  const onModalClose = useCallback(() => {
    dispatch(unsetSelectedCustomKpiId())
    reset()
  }, [reset, dispatch])
  const onReset = useCallback(() => {
    reset()
  }, [reset])

  return (
    <Modal onClose={onModalClose} modalName='widget' width='large'>
      <Modal.Header>{t(`customKpis.${isEdit ? 'editWidget' : 'newWidget'}`)}</Modal.Header>
      <Modal.Content>
        <StyledModalWidget>
          <StyledModalForm>
            <StyledModalFormLabel>{t('customKpis.form.kpiType.title')}</StyledModalFormLabel>
            <StyledModalFormKpiTypeSelectContainer>
              <Select
                options={kipTypes}
                value={{
                  value: kpiTypeFilter.value?.value,
                  label: kpiTypeFilter.value?.value
                    ? kpiTypesMapping[kpiTypeFilter.value.value as CustomKpiType]
                    : '',
                }}
                onChange={(e) => onWidgetTypeChange({ id: e.value.value as CustomKpiType })}
              />
            </StyledModalFormKpiTypeSelectContainer>
            {kpiTypeFilter.value?.value === TYPE_SHIPMENT && (
              <>
                <StyledModalFormCheckboxesContainer>
                  <StyledModalFormLabel>
                    {t('customKpis.form.transportModes.title')}
                  </StyledModalFormLabel>
                  <StyledModalFormCheckboxes>
                    <InputCheckbox
                      id={TRANSPORT_TYPE_SEA}
                      name={transportModeSeaFilter.name}
                      text={t('customKpis.form.transportModes.sea')}
                      onChange={transportModeSeaFilter.onChange}
                      checked={transportModeSeaFilter.value}
                    />
                    <InputCheckbox
                      id={TRANSPORT_TYPE_ROAD}
                      name={transportModeRoadFilter.name}
                      text={t('customKpis.form.transportModes.road')}
                      onChange={transportModeRoadFilter.onChange}
                      checked={transportModeRoadFilter.value}
                    />
                    <InputCheckbox
                      id={TRANSPORT_TYPE_RAIL}
                      name={transportModeRailFilter.name}
                      text={t('customKpis.form.transportModes.rail')}
                      onChange={transportModeRailFilter.onChange}
                      checked={transportModeRailFilter.value}
                    />
                    <InputCheckbox
                      id={TRANSPORT_TYPE_AIR}
                      name={transportModeAirFilter.name}
                      text={t('customKpis.form.transportModes.air')}
                      onChange={transportModeAirFilter.onChange}
                      checked={transportModeAirFilter.value}
                    />
                    {features(WITH_PARCEL_TRACKING) && (
                      <InputCheckbox
                        id={TRANSPORT_TYPE_PARCEL}
                        name={transportModeParcelFilter.name}
                        text={t('customKpis.form.transportModes.parcel')}
                        onChange={transportModeParcelFilter.onChange}
                        checked={transportModeParcelFilter.value}
                      />
                    )}
                  </StyledModalFormCheckboxes>
                </StyledModalFormCheckboxesContainer>

                <StyledModalFormCheckboxesContainer>
                  <StyledModalFormLabel>
                    {t('customKpis.form.arrivalForecast.title')}
                    <IconTooltip
                      placement='right'
                      size='large'
                      content={t('customKpis.form.arrivalForecast.info')}
                    />
                  </StyledModalFormLabel>
                  <StyledModalFormCheckboxes>
                    <InputCheckbox
                      id={ARRIVAL_FORECAST_EARLY}
                      text={t('customKpis.form.arrivalForecast.early')}
                      onChange={arrivalForecastEarlyFilter.onChange}
                      name={arrivalForecastEarlyFilter.name}
                      checked={arrivalForecastEarlyFilter.value}
                    />
                    <InputCheckbox
                      id={ARRIVAL_FORECAST_ON_TIME}
                      text={t('customKpis.form.arrivalForecast.onTime')}
                      onChange={arrivalForecastOnTimeFilter.onChange}
                      name={arrivalForecastOnTimeFilter.name}
                      checked={arrivalForecastOnTimeFilter.value}
                    />
                    <InputCheckbox
                      id={ARRIVAL_FORECAST_DELAYED}
                      text={t('customKpis.form.arrivalForecast.delayed')}
                      onChange={arrivalForecastDelayedFilter.onChange}
                      name={arrivalForecastDelayedFilter.name}
                      checked={arrivalForecastDelayedFilter.value}
                    />
                  </StyledModalFormCheckboxes>
                </StyledModalFormCheckboxesContainer>

                <Tabs
                  onTabChange={({ id: tabId }) =>
                    onStepTypeChange({ id: tabId as SummaryStepType })
                  }
                  defaultTabId={(stepTypeFilter.value?.value as SummaryStepType) ?? undefined}
                >
                  <Tab
                    id={STEP_TYPE_PICKUP}
                    label={t('customKpis.form.pickup')}
                    key='custom-kpi-tab-pickup'
                  >
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.address')}
                      name={addressFilter.name}
                      onChange={addressFilter.onChange}
                      value={toIndexedSelectValue(addressFilter)}
                      isClearable
                      isSearchable
                      fetch={fetchOptions('routing_pickup')}
                      fetchOnFocus={fetchAllOptions('routing_pickup')}
                      fetchedOptionsFormat={fetchedOptionsFormat}
                      async
                    />
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.expected')}
                      options={shipmentTimeRangeOptions}
                      name={transportationDateFilter.name}
                      onChange={transportationDateFilter.onChange}
                      value={transportationDateValue}
                    />
                  </Tab>
                  <Tab id={STEP_TYPE_POL} label={t('customKpis.form.pol')} key='custom-kpi-tab-pol'>
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.address')}
                      name={addressFilter.name}
                      onChange={addressFilter.onChange}
                      value={toIndexedSelectValue(addressFilter)}
                      isClearable
                      isSearchable
                      fetch={fetchOptions('routing_pol')}
                      fetchOnFocus={fetchAllOptions('routing_pol')}
                      fetchedOptionsFormat={fetchedOptionsFormat}
                      async
                    />
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.expected')}
                      options={shipmentTimeRangeOptions}
                      name={transportationDateFilter.name}
                      onChange={transportationDateFilter.onChange}
                      value={transportationDateValue}
                    />
                  </Tab>
                  <Tab id={STEP_TYPE_POD} label={t('customKpis.form.pod')} key='custom-kpi-tab-pod'>
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.address')}
                      name={addressFilter.name}
                      onChange={addressFilter.onChange}
                      value={toIndexedSelectValue(addressFilter)}
                      isClearable
                      isSearchable
                      fetch={fetchOptions('routing_pod')}
                      fetchOnFocus={fetchAllOptions('routing_pod')}
                      fetchedOptionsFormat={fetchedOptionsFormat}
                      async
                    />
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.expected')}
                      options={shipmentTimeRangeOptions}
                      name={transportationDateFilter.name}
                      onChange={transportationDateFilter.onChange}
                      value={transportationDateValue}
                    />
                  </Tab>
                  <Tab
                    id={STEP_TYPE_DELIVERY}
                    label={t('customKpis.form.delivery')}
                    key='custom-kpi-tab-delivery'
                  >
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.address')}
                      name={addressFilter.name}
                      onChange={addressFilter.onChange}
                      value={toIndexedSelectValue(addressFilter)}
                      isClearable
                      isSearchable
                      fetch={fetchOptions('routing_delivery')}
                      fetchOnFocus={fetchAllOptions('routing_delivery')}
                      fetchedOptionsFormat={fetchedOptionsFormat}
                      async
                    />
                    <StyledModalFormSelect
                      as={Select}
                      label={t('customKpis.form.expected')}
                      options={shipmentTimeRangeOptions}
                      name={transportationDateFilter.name}
                      onChange={transportationDateFilter.onChange}
                      value={transportationDateValue}
                    />
                  </Tab>
                </Tabs>
              </>
            )}
            {kpiTypeFilter.value?.value === TYPE_CARBON_FOOTPRINT && (
              <>
                <StyledModalFormSelect
                  as={Select}
                  label={t('customKpis.form.timeRange')}
                  options={carbonFootprintTimeRangeOptions}
                  name={transportationDateFilter.name}
                  onChange={transportationDateFilter.onChange}
                  value={transportationDateValue}
                />
              </>
            )}
          </StyledModalForm>
        </StyledModalWidget>
      </Modal.Content>
      <Modal.Footer>
        <Button text={t('actions.reset')} onClick={onReset} rounded variant='clear' />
        <Button
          text={t(`actions.${isEdit ? 'save' : 'create'}`)}
          testId={TEST_ID_SAVE_CREATE_WIDGET_BUTTON}
          disabled={isEdit && !isEdited}
          onClick={onSubmit}
          rounded
          variant='highlight'
        />
      </Modal.Footer>
    </Modal>
  )
}
export default ModalWidget
