import useAppSelector from 'services/hooks/use_app_selector'
import { selectCurrentUserLocale } from 'views/iam/slices/iamSlice'
import { SupportedLanguage } from 'constants/locales'

const useUserLocale = (): SupportedLanguage => {
  const currentUserLocale = useAppSelector(selectCurrentUserLocale)
  return currentUserLocale || 'en'
}

export default useUserLocale
