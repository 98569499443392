import React from 'react'

import { escapeRegexp } from 'services/helpers/values'

const highlightSearch = (str: string | undefined, search: string) => {
  const searchMatch = new RegExp(escapeRegexp(search), 'i')
  const tags = (str || '')
    .split(searchMatch)
    .map((match) => [
      <span key={`highlight-${str}`}>{match}</span>,
      <strong className='higlight-search'>{str?.match(searchMatch)?.[0]}</strong>,
    ])
    .flat()

  tags.pop()

  return tags
}

export default highlightSearch
