import React, { FC, SyntheticEvent } from 'react'

import Insert from 'components/form/form_insert'
import Group from 'components/form/form_group'
import Accordion from 'components/form/form_accordion'
import { StyledForm } from 'components/form/style'

interface FormProps {
  onSubmit?: (event: SyntheticEvent) => void
  className?: string
}

interface FormSubComponents {
  Insert: typeof Insert
  Group: typeof Group
  Accordion: typeof Accordion
}

const Form: FC<FormProps> & FormSubComponents = ({ onSubmit, className, children }) => (
  <StyledForm className={className} onSubmit={onSubmit}>
    {children}
  </StyledForm>
)

Form.Insert = Insert
Form.Group = Group
Form.Accordion = Accordion

export default Form
