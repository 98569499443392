import { shallowEqual, TypedUseSelectorHook } from 'react-redux'

import useAppSelector from 'services/hooks/use_app_selector'

import type { RootState } from 'services/store/store'

const useShallowSelector: TypedUseSelectorHook<RootState> = (selector: any) =>
  useAppSelector(selector, shallowEqual)

export default useShallowSelector
