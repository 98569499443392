import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  StyledPageBookingListMain,
  StyledPageBookingHeader,
  StyledPageBookingHeaderRight,
  StyledBookingsList,
  StyledBookingListWrapper,
  StyledBookingsBarTotalCount,
} from 'views/bookings/style'
import {
  fetchBookings,
  selectBookingsActiveFilters,
  selectBookingsPreloadFilters,
} from 'views/bookings/slice'
import BookingsList from 'views/bookings/components/list'
import BookingListHeaderBar from 'views/bookings/components/header_bar'
import useBookings from 'views/bookings/hooks/use_bookings'
import FiltersContext from 'views/bookings/contexts/filters_context'

import { isAnyObject } from 'services/helpers/values'
import usePagination from 'services/api/hooks/use_pagination'

import Button from 'components/button'
import BookingFilters from 'components/booking_filters'
import Page from 'components/page'
import S from 'components/page/style'

import { TEST_ID_BOOKING_TOTAL } from 'tests/e2e/test_ids'

const BookingsPage = () => {
  const { t } = useTranslation()
  const { page, next, reset } = usePagination()
  const dispatch = useDispatch()
  const [bookings, fetchingStatus, totalCount] = useBookings()
  const preloadFilters = useSelector(selectBookingsPreloadFilters)
  const activeFilters = useSelector((state) => selectBookingsActiveFilters(state))

  const PER = 20

  useEffect(() => {
    if (Object.entries(preloadFilters).length === 0) {
      dispatch(fetchBookings({ page, per: PER, filters: activeFilters })).unwrap()
    }
  }, [dispatch, preloadFilters, page, activeFilters])

  useEffect(() => {
    reset()
  }, [activeFilters, reset])

  return (
    <FiltersContext.Provider
      value={{
        page,
        hasFiltersActive: isAnyObject(activeFilters),
      }}
    >
      <Page sidebar={<BookingFilters />}>
        <StyledPageBookingListMain>
          <StyledPageBookingHeader>
            <S.PageTitle>{t('bookings.title')}</S.PageTitle>

            <StyledBookingsBarTotalCount data-testid={TEST_ID_BOOKING_TOTAL}>
              {totalCount} {t('bookings.booking', { count: totalCount })}
            </StyledBookingsBarTotalCount>

            <StyledPageBookingHeaderRight>
              {/* Header bar */}
              <BookingListHeaderBar />
            </StyledPageBookingHeaderRight>
          </StyledPageBookingHeader>
          {/* List */}
          <StyledBookingListWrapper>
            <StyledBookingsList>
              <BookingsList page={page} />
              {fetchingStatus.ready && bookings.length < totalCount && (
                <Button text={t('actions.loadMore')} onClick={() => next()} />
              )}
            </StyledBookingsList>
          </StyledBookingListWrapper>
        </StyledPageBookingListMain>
      </Page>
    </FiltersContext.Provider>
  )
}

BookingsPage.propTypes = {}

BookingsPage.defaultProps = {}

export default BookingsPage
