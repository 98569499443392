import { object, string, number, array, InferType } from 'yup'

export const clusterSchema = object({
  token: string().required(),
  name: string().required(),
  nameAliases: array().of(string().required()).required(),
  timezone: string().nullable().defined(),
  country: object({ name: string().required(), token: string().required() }).required(),
  state: object({ name: string().required(), token: string().required() }).nullable().defined(),
  county: string().nullable().defined(),
})

export const fetchClustersSchema = object({
  totalCount: number().required(),
  clusters: array().of(clusterSchema.required()).required(),
})

export type Cluster = InferType<typeof clusterSchema>
export type ClusterIndex = InferType<typeof fetchClustersSchema>
