import styled from 'styled-components'

export const StyledIconTooltip = styled.div<{ $ellipsis?: boolean; $inline?: boolean }>`
  position: relative;
  display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
  cursor: pointer;

  // TODO: REFACTOR
  // Those properties are too broad and may cause regressions
  // They should be use where needed only
  ${({ $ellipsis }) =>
    $ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`
export const StyledIconWrap = styled.div`
  pointer-events: none;
`
