import React from 'react'

import { useTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'

import S from 'views/trusted_routes/components/item/style'
import Icon from 'components/icon'
import DateHelper from 'services/helpers/date_helper'
import Timeline, { TimelineMilestone, TimelineStep } from 'components/timeline'
import { FACILITY_TYPE_PORT } from 'constants/shipments'
import { MILESTONE_TYPE_SEA_ARRIVAL, MILESTONE_TYPE_SEA_DEPARTURE } from 'constants/milestones'
import { Leg, TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import {
  formatDateForMilestone,
  formatMetadata,
  hasMetadata,
} from 'views/trusted_routes/components/item/tool'

interface TransportDetailsProps {
  trustedRoute: TrustedRoute
}

interface AlertProps {
  leg: Leg
}

const Alert: React.FC<AlertProps> = ({ leg }) => {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language

  return (
    <>
      {leg.alerts &&
        leg.alerts.length > 0 &&
        leg.alerts.map((v, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <S.AlertTimelineContainer key={`alert${i}`}>
            <Icon name='warning' width={25} height={25} fill={theme.warn} />

            <S.AlertTimelineMessage>
              {currentLanguage === 'fr' ? v.messageFr : v.messageEn}
            </S.AlertTimelineMessage>
          </S.AlertTimelineContainer>
        ))}
    </>
  )
}

const TransportDetails: React.FC<TransportDetailsProps> = ({ trustedRoute }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <S.UnfoldedContent>
      {trustedRoute.cutOffDate && (
        <S.UnfoldedContentTopBlock>
          <S.CutOffDate>
            <Icon name='time_clock' width={26} height={26} fill={theme.mediumGray} />
            {t('trustedRoutes.cutOffDate')}:&nbsp;
            {new DateHelper(trustedRoute.cutOffDate).formattedOriginalDate()}
          </S.CutOffDate>
        </S.UnfoldedContentTopBlock>
      )}

      <S.TimelineWrapper>
        <Timeline>
          <TimelineStep
            type={FACILITY_TYPE_PORT}
            inTransit={false}
            location={{
              name: trustedRoute.legs[0].departure.port.name,
              countryCode: trustedRoute.legs[0].departure.port.country || undefined,
            }}
            status='past'
            key='departure'
          >
            <TimelineMilestone
              type={MILESTONE_TYPE_SEA_DEPARTURE}
              estimatedTime={formatDateForMilestone(trustedRoute.legs[0].departure.date)}
              milestoneName={t(`trustedRoutes.milestones.departure.${trustedRoute.legs[0].type}`)}
              defaultMetadataVisible
              metadata={
                hasMetadata(trustedRoute.legs[0]) ? formatMetadata(trustedRoute.legs[0]) : undefined
              }
            />
            <Alert leg={trustedRoute.legs[0]} />
          </TimelineStep>
          {trustedRoute.legs.slice(1).map((leg, i) => (
            <TimelineStep
              type={FACILITY_TYPE_PORT}
              inTransit={false}
              location={{
                name: leg.departure.port.name,
                countryCode: leg.departure.port.country || undefined,
              }}
              status='past'
              key={`transshipment${leg.departure.port.name}${leg.arrival.port.name}${leg.vesselImo}`}
            >
              <TimelineMilestone
                type={MILESTONE_TYPE_SEA_ARRIVAL}
                estimatedTime={formatDateForMilestone(trustedRoute.legs[i].arrival.date)}
                milestoneName={t(`trustedRoutes.milestones.arrival.${trustedRoute.legs[i].type}`)}
              />
              <TimelineMilestone
                type={MILESTONE_TYPE_SEA_DEPARTURE}
                estimatedTime={formatDateForMilestone(leg.departure.date)}
                milestoneName={t(`trustedRoutes.milestones.departure.${leg.type}`)}
                defaultMetadataVisible
                metadata={hasMetadata(leg) ? formatMetadata(leg) : undefined}
              />
              <Alert leg={leg} />
            </TimelineStep>
          ))}
          <TimelineStep
            type={FACILITY_TYPE_PORT}
            inTransit={false}
            location={{
              name: trustedRoute.legs.slice(-1)[0].arrival.port.name,
              countryCode: trustedRoute.legs.slice(-1)[0].arrival.port.country || undefined,
            }}
            status='past'
            key='arrival'
          >
            <TimelineMilestone
              type={MILESTONE_TYPE_SEA_ARRIVAL}
              estimatedTime={formatDateForMilestone(trustedRoute.legs.slice(-1)[0].arrival.date)}
              milestoneName={t(
                `trustedRoutes.milestones.arrival.${trustedRoute.legs.slice(-1)[0].type}`
              )}
            />
          </TimelineStep>
        </Timeline>
      </S.TimelineWrapper>
    </S.UnfoldedContent>
  )
}

export default TransportDetails
