import styled from 'styled-components'

import { tablet } from 'styles/utils/breakpoints'
import { listItem13, h1 } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'

export const StyledWidgetsShipment = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 12px 12px 12px 25px;
  background-color: ${({ theme }) => theme.white};
  min-height: 106px;
  @media ${tablet.down} {
    min-height: 150px;
  }
`
export const StyledWidgetTotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
`

export const StyledWidgetTotal = styled.span`
  ${h1};
  font-size: 36px;
  color: ${({ theme }) => theme.textDark};
`

export const StyledWidgetTotalLabel = styled.span`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
`
export const StyledWidgetDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  overflow: hidden;
`
export const StyledWidgetDetail = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 0;
`
export const StyledWidgetIcon = styled(StyledIcon)`
  fill: ${({ theme }) => theme.primary};
  margin-right: 10px;
  width: 14px;
  height: 16px;
  flex-shrink: 0;
`

export const StyledWidgetsIteDetailText = styled.span`
  ${listItem13};
  color: ${({ theme }) => theme.textLight};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
