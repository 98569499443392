import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTheme } from 'styled-components'

import useShipmentColor from 'views/shipments/hooks/use_shipment_color'
import ShipmentContext from 'views/shipment/context'
import { selectLegs, selectPath, selectIsShipmentActive } from 'views/shipment/slice'
import useShipment from 'views/shipment/hooks/use_shipment'
import { StyledMap } from 'views/shipment/style'

import useTracker from 'services/analytics/hooks/use_tracker'
import usePreviousChanged from 'services/hooks/use_previous_changed'

import useCoordinates from 'components/map/hooks/use_coordinates'
import { CoordinateModel, PathModel, StepModel } from 'components/map/models'
import useMap from 'components/map/hooks/use_map'
import AppContext from 'app/contexts/app_context'
import { isAnyObject } from 'services/helpers/values'
import { CONTAINER_MARKER_TYPE } from 'components/marker'
import DateHelper from 'services/helpers/date_helper'
import { TEST_ID_SHIPMENT_SHOW_IOT_POSITON_MARKER } from 'tests/e2e/test_ids'

const ShipmentMap = () => {
  const theme = useTheme()
  const { id } = useContext(ShipmentContext)
  const [shipment, status] = useShipment({ id })
  const { track } = useTracker()
  const { getPointerColorFromShipmentColor } = useShipmentColor()
  const history = useHistory()
  const isIdChanged = usePreviousChanged(id)
  const { isShared } = useContext(AppContext)

  // Translate API data
  const path = useSelector(selectPath({ id })).map(
    (coords) => new PathModel({ lat: coords[0], lng: coords[1], timestamp: coords[2] })
  )
  const pathCoordinates = path.map(({ coordinate }) => coordinate)
  const legs = useSelector(selectLegs({ id }))

  const steps = [
    legs.map((si) => new StepModel(si.departure)),
    legs.map((si) => new StepModel(si.arrival)),
  ]
    .flat()
    .filter((step) => step.isValid())
  const stepCoordinates = steps.map((s) => s.coordinate)

  const { normalize, bear } = useCoordinates()

  // Normalize coordinates
  const { normalized: normalizedPathCoordinates, center: pathCenter } = normalize(pathCoordinates)
  const { normalized: normalizedStepsCoordinates } = normalize(stepCoordinates, pathCenter)

  const centerOnLastKnownCoord = () => {
    const lastPathCoord = normalizedPathCoordinates[normalizedPathCoordinates.length - 1]
    const lastStepCoord = normalizedStepsCoordinates[normalizedStepsCoordinates.length - 1]
    setCenter(lastPathCoord || lastStepCoord)
  }
  // Set map inputs
  const bounds = [...normalizedPathCoordinates, ...normalizedStepsCoordinates]
  const isShipmentActive = useSelector(selectIsShipmentActive({ id }))
  const {
    Map,
    mapProps,
    addMarker,
    addPath,
    addPointer,
    setCenter,
    loaded,
    fitBounds,
    isFullscreen,
  } = useMap({
    bounds,
  })
  const isMapReady = loaded && status.fulfilled

  const normalizedPath = path.map(
    ({ timestamp }, index) =>
      new PathModel({ ...normalizedPathCoordinates[index].coordinate, timestamp })
  )

  // Reset map when shipment changes
  // i.e. when user is on a shipment page, searches for a shipment, and clicks on it
  // map is not refreshed because the shipment page component does not reload
  useEffect(() => {
    if (isIdChanged) {
      history.go(0)
    }
  }, [isIdChanged, history])

  useEffect(() => {
    if (!isMapReady) return

    addPath({ path: normalizedPath, coordinates: normalizedPathCoordinates })

    const lastCoordinate = path[path.length - 1]
    if (lastCoordinate && isShipmentActive) {
      const pointerColor = getPointerColorFromShipmentColor(shipment.color)
      addPointer({
        bearing: bear(normalizedPathCoordinates),
        coordinate: lastCoordinate.coordinate,
        color: pointerColor,
        tooltip: lastCoordinate.timestamp,
        isPathExtremity: true,
      })
    }

    steps.forEach(({ type, coordinate }) => {
      addMarker({
        type,
        coordinate,
      })
    })

    if (isAnyObject(shipment?.iotLastPosition) && !isShared) {
      const { latitude, longitude, time } = shipment.iotLastPosition
      addMarker({
        type: CONTAINER_MARKER_TYPE,
        coordinate: new CoordinateModel(latitude, longitude),
        color: theme.secondary,
        tooltipText: new DateHelper(time).toLocale({ hours: true }),
        testId: TEST_ID_SHIPMENT_SHOW_IOT_POSITON_MARKER,
      })
    }
    // eslint-disable-next-line
  }, [isMapReady])

  useEffect(() => {
    if (!isMapReady) return
    if (isFullscreen) {
      fitBounds()
      track('Shipment / map')
    } else {
      centerOnLastKnownCoord()
    }
    // eslint-disable-next-line
  }, [isMapReady, isFullscreen])

  return <StyledMap as={Map} {...mapProps} $fullscreen={isFullscreen} /> // eslint-disable-line react/jsx-props-no-spreading
}

ShipmentMap.propTypes = {}

ShipmentMap.defaultProps = {}

export default ShipmentMap
