import { useEffect, useRef } from 'react'

import type { AnyValue } from 'types/common'

const usePrevious = (value: AnyValue) => {
  const ref = useRef<AnyValue>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default usePrevious
