import styled from 'styled-components'

import { StyledInput } from 'components/input/style'
import StyledTabPanel from 'components/tabs/tab_panel/style'
import { StyledPanelsWrapper } from 'components/tabs/style'

export const StyledBookingFilters = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${StyledTabPanel} {
    display: flex;
    flex-direction: column;
    min-height: 0;
    margin-top: 10px;
  }
  ${StyledPanelsWrapper} {
    display: flex;
    flex-direction: column;
    min-height: 0;
  }
`

export const StyledBookingFiltersTabs = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const StyledBookingFiltersTags = styled.div`
  ${StyledInput} {
    margin: 10px 0;
  }
`

export const StyledBookingFiltersTag = styled.div`
  margin: 5px 10px 5px 0px;
`

export const StyledBookingFiltersCheckPictos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const StyledBookingFiltersDays = styled.div`
  display: flex;
  justify-content: space-between;

  ${StyledInput} {
    flex: none;
    width: calc(50% - 5px);
  }
`
