import styled, { css } from 'styled-components'

import { listItem13 } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'
import { StyledSelect } from 'components/select/style'

export const StyledUploadDocFile = styled.div<{ $uploaded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 11px 0;
  position: relative;

  ${StyledSelect} {
    width: 100%;
  }

  ${(props) =>
    props.$uploaded &&
    css`
      padding-right: 30px;
      ${StyledUploadDocFileName} {
        width: auto;
        max-width: 200px;
        flex-shrink: 1;
      }
    `}
`

export const StyledUploadDocFileName = styled.p`
  flex: none;
  width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${listItem13};
  padding-right: 10px;
  color: ${({ theme }) => theme.textDark};
`

export const StyledUploadDocFileIcon = styled.div`
  flex: none;
  width: 17px;
  height: 19px;
  margin-right: 12px;
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledUploadDocFileValidate = styled.div`
  width: 16px;
  height: 16px;
  fill: ${({ theme }) => theme.success};
  position: absolute;
  right: 0;
`

export const StyledUploadDocFileWarning = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;

  ${StyledIcon} {
    fill: ${({ theme }) => theme.error};
    width: 100%;
  }
`

export const StyledUploadDocFileDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
`

export const StyledUploadDocFileProgress = styled.div`
  position: absolute;
  right: 0;
`
