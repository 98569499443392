import React, { FC, useContext } from 'react'

import ShipmentContext from 'components/shipment_list_item/context'
import Co2 from 'components/co2'

const Co2e: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const co2e = shipment.emissions?.co2E
  return co2e ? <Co2 value={co2e} variant='condensed' /> : null
}

export default Co2e
