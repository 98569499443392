import React from 'react'

import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import Column from 'components/grid/grid_column'
import { StyledRow } from 'components/grid/style'

function Row({ itemsCentered, compact, children, testId }) {
  return (
    <StyledRow data-testid={testId} $itemsCentered={itemsCentered} $compact={compact}>
      {children}
    </StyledRow>
  )
}

Row.propTypes = {
  /** children */
  children: validateChildrenOfTypes([Column]),
  itemsCentered: PropTypes.bool,
  compact: PropTypes.bool,
  testId: PropTypes.string,
}

Row.defaultProps = {
  children: undefined,
  itemsCentered: false,
  compact: false,
  testId: undefined,
}

export default Row
