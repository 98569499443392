import styled, { css } from 'styled-components'

import { text14Regular, text12Regular } from 'styles/utils/texts'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { shadowMedium, shadow, shadowStrong } from 'styles/utils/shadow'

const Body = styled.div<{ $shadowVariant: 'normal' | 'medium' | 'strong' }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ theme }) => theme.white};
  ${borderRadiusMedium}

  ${({ $shadowVariant }) => $shadowVariant === 'normal' && shadow}

  ${({ $shadowVariant }) => $shadowVariant === 'medium' && shadowMedium}

  ${({ $shadowVariant }) => $shadowVariant === 'strong' && shadowStrong}
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  gap: 20px;
`

const Row = styled.div<{ $odd?: boolean; $alignedTop?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  ${({ theme, $odd }) => $odd && `background-color: ${theme.clearGray};`}
  gap: 20px;
  ${({ $alignedTop }) =>
    $alignedTop &&
    css`
      align-items: flex-start;
    `}
`

// Percentages may change depending on the number of columns. Values are arbitrary, this should be eventually changed
const columnSize = css<{ $small?: boolean }>`
  flex-basis: ${({ $small }) => ($small ? '5%' : '20%')};
`

const Cell = styled.div<{
  $small?: boolean
  $centered?: boolean
  $alignedTop?: boolean
  $fitContent?: boolean
}>`
  ${columnSize}
  ${text12Regular}
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  flex-grow: 1;
  ${({ $centered }) =>
    $centered &&
    css`
      display: flex;
      justify-content: center;
      align-content: center;
      padding-right: 0;
    `}
  ${({ $alignedTop }) =>
    $alignedTop &&
    css`
      display: flex;
      justify-content: flex-start;
      align-content: center;
    `}
    ${({ $fitContent }) =>
    $fitContent &&
    css`
      flex-grow: 0;
      flex-basis: auto;
    `}
`

const HeadCell = styled.div<{ $small?: boolean; $centered?: boolean; $fitContent?: boolean }>`
  ${text14Regular};
  color: ${({ theme }) => theme.textLight};
  ${columnSize}
  ${({ $centered }) => $centered && 'text-align:center;'}
  flex-grow: 1
`

const S = {
  Body,
  Head,
  Row,
  Cell,
  HeadCell,
}

export default S
