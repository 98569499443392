import React from 'react'

import {
  FormattedValue,
  FormattedValues,
} from 'components/booking_last_event/hooks/use_last_event_formatted_changes'

import HoverTooltip from 'components/hover_tooltip'

import {
  StyledBookingLastEventCellItem,
  StyledBookingLastEventIndentedCellItem,
  StyledBookingLastEventIndentedContainer,
} from 'components/booking_last_event/style'

const LastEventCellItem: React.FC<{
  createdAt: string
  category: string | null | undefined
  value: FormattedValue | null
}> = ({ createdAt, value }) => (
  <div>
    <StyledBookingLastEventCellItem>{value?.category}</StyledBookingLastEventCellItem>
    {value?.nestedValues?.map((nestedValue) =>
      (nestedValue?.nestedValues?.length ?? 0) > 0 ? (
        <StyledBookingLastEventIndentedContainer>
          <LastEventCellItem
            value={nestedValue}
            category={nestedValue?.category}
            createdAt={createdAt}
          />
        </StyledBookingLastEventIndentedContainer>
      ) : (
        <StyledBookingLastEventIndentedCellItem>
          {nestedValue?.category}
        </StyledBookingLastEventIndentedCellItem>
      )
    )}
  </div>
)

const LastEventFormattedValuesCellContent: React.FC<{
  createdAt: string
  category: string
  values: FormattedValues
  withTooltip: boolean
}> = ({
  createdAt,
  category,
  values,
  withTooltip,
}: {
  createdAt: string
  category: string
  values: FormattedValues
  withTooltip: boolean
}) => (
  <>
    {values?.map((value) =>
      withTooltip && value?.category && values.length > 1 ? (
        <HoverTooltip
          key={`last-event-${createdAt}-${category}-value-${value.category}`}
          delayed
          content={value.category}
        >
          <StyledBookingLastEventCellItem $noWrap>{value.category}</StyledBookingLastEventCellItem>
        </HoverTooltip>
      ) : (
        <LastEventCellItem createdAt={createdAt} category={value?.category} value={value} />
      )
    )}
  </>
)

export default LastEventFormattedValuesCellContent
