import React, { FC, useContext, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import AlertListItem from 'components/alert_list_item'
import ShipmentContext from 'views/shipment/context'
import { fetchAlerts } from 'views/shipment/slice'
import ErrorNotification from 'views/errors/error_notification'
import { ALERT_TYPE_ET_CHANGES, ALERT_TYPE_LOCATION_CHANGES } from 'constants/alerts'
import DateHelper from 'services/helpers/date_helper'
import { isEmptyArray, readonlyArrayIncludes } from 'services/helpers/values'
import NotifMessage from 'components/notif_message'
import ShipmentReportedInfos from 'views/shipment/components/reported_info'
import useShipment from 'views/shipment/hooks/use_shipment'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { Alert, AlertETChange, AlertLocationChange } from 'views/shipment/types/shipment'

import { UseStatus } from 'services/api/hooks/use_status'

const isTimeAlert = (alert: Alert): alert is AlertETChange =>
  readonlyArrayIncludes(ALERT_TYPE_ET_CHANGES, alert.type)

const isLocationAlert = (alert: Alert): alert is AlertLocationChange =>
  readonlyArrayIncludes(ALERT_TYPE_LOCATION_CHANGES, alert.type)

export interface ShipmentAlertListProps {
  alerts: Alert[]
  status: UseStatus
}

const ShipmentAlertList: FC<ShipmentAlertListProps> = ({ alerts, status }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { id } = useContext(ShipmentContext)
  const [shipment, , isFlagged] = useShipment({ id })

  useEffect(() => {
    if (!status.ready) {
      dispatch(fetchAlerts({ id }))
    }
  }, [dispatch, id, status.ready])

  return (
    <>
      {shipment && isFlagged && <ShipmentReportedInfos reportedReasons={shipment.orderFlags} />}
      {status?.rejected && <ErrorNotification />}
      {isEmptyArray(alerts) && (
        <NotifMessage
          type='success'
          text={t('alerts.noResults.text')}
          title={t('alerts.noResults.title')}
          padded
        />
      )}
      <div>
        <ul>
          {alerts.map((a) => {
            let title = t(`static.alerts.${a.type}`)
            let details: null | string = null
            if (isTimeAlert(a)) {
              title += ` ${new DateHelper(a.occurredAt).toLocale({ hours: true })}`
              details = a.details.location.name
            } else if (isLocationAlert(a) && a.details) {
              details = `${a.details.from} => ${a.details.to}`
            } else if (typeof a.details === 'string') {
              details = a.details
            }
            return (
              <AlertListItem
                title={title}
                variant={a.isOngoing ? 'warning' : 'default'}
                declaredAt={a.declaredAt}
                details={details}
                location={a.location}
                key={`alert-${a.type}-${a.occurredAt}-${a.declaredAt}`}
              />
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default ShipmentAlertList
