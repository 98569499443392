import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Icon from 'components/icon'
import Button from 'components/button'
import ModalWidget from 'components/modal_widget'
import {
  StyledWidgets,
  StyledWidgetsHeader,
  StyledWidgetsIcon,
  StyledWidgetsTitle,
  StyledWidgetsButton,
  StyledWidgetsChildren,
} from 'features/custom_kpis/components/widgets/style'
import { selectSelectedCustomKpiId } from 'features/custom_kpis/store/slice'
import useTracker from 'services/analytics/hooks/use_tracker'
import useModal from 'components/modal/hooks/use_modal'

import { TEST_ID_ADD_WIDGET_BUTTON, TEST_ID_WIDGET_LIST_QUICK_ACCESS } from 'tests/e2e/test_ids'

const Widgets: React.FC = ({ children }) => {
  const { setOpen } = useModal('widget')
  const { t } = useTranslation()
  const id = useSelector(selectSelectedCustomKpiId)
  const { track } = useTracker()

  const onAddWidget = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      track('Dashboard / Widget', { action: 'add', type: 'pre-share' })
      setOpen(true)
      e.stopPropagation()
    },
    [setOpen, track]
  )

  return (
    <StyledWidgets>
      <StyledWidgetsHeader>
        <StyledWidgetsIcon as={Icon} name='world_map' />
        <StyledWidgetsTitle>{t('customKpis.blockTitle')}</StyledWidgetsTitle>
      </StyledWidgetsHeader>
      <StyledWidgetsChildren data-testid={TEST_ID_WIDGET_LIST_QUICK_ACCESS}>
        {children}
      </StyledWidgetsChildren>
      <StyledWidgetsButton
        as={Button}
        text={t('customKpis.addWidget')}
        testId={TEST_ID_ADD_WIDGET_BUTTON}
        icon='plus_plain'
        rounded
        variant='highlight'
        onClick={onAddWidget}
      />
      <ModalWidget id={id} />
    </StyledWidgets>
  )
}

export default Widgets
