import React, { FC, memo, useMemo, useState } from 'react'

import { CUSTOM_TAB, TEMPLATES_TAB } from 'types/filters'

import { FilterTabContext, TemplateFiltersContext } from 'contexts/filters'

import BookingFiltersCustom from 'components/booking_filters_custom'
import SavedFilters from 'components/booking_filters_saved'

import type { TAnyFilters } from 'services/hooks/use_filter'

export interface TemplateFilter {
  name: string
  value: TAnyFilters
  category: string
}

const BookingFilters: FC = () => {
  const [activeId, setActiveId] = useState<string>(CUSTOM_TAB)
  const [templateFilters, setTemplateFilters] = useState<TemplateFilter[] | null>([])
  const filtersContextValue = useMemo(
    () => ({
      templateFilters,
      setTemplateFilters,
    }),
    [templateFilters, setTemplateFilters]
  )
  const filterTabContextValue = useMemo(
    () => ({
      currentTab: activeId,
      switchToCustomTab: () => {
        setActiveId(CUSTOM_TAB)
      },
      switchToTemplatesTab: () => {
        setActiveId(TEMPLATES_TAB)
      },
    }),
    [activeId]
  )

  return (
    <TemplateFiltersContext.Provider value={filtersContextValue}>
      <FilterTabContext.Provider value={filterTabContextValue}>
        {activeId === CUSTOM_TAB && <BookingFiltersCustom />}
        {activeId === TEMPLATES_TAB && <SavedFilters />}
      </FilterTabContext.Provider>
    </TemplateFiltersContext.Provider>
  )
}

export default memo(BookingFilters)
