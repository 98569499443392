import React, { memo, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { FilterTabContext, TemplateFiltersContext } from 'contexts/filters'

import Tag from 'components/tag'
import SavedFiltersItem from 'components/shipment_filters_saved_item'
import {
  StyledShipmentFiltersTag,
  StyledShipmentFilters,
  StyledShipmentFiltersTabs,
} from 'components/shipment_filters/style'
import Placeholder from 'components/placeholder'
import NotifMessage from 'components/notif_message'
import ShipmentFiltersSavedSkeleton from 'components/shipment_filters_saved/skeleton'
import Sidebar from 'components/sidebar'
import Tabs, { Tab } from 'components/tabs'
import Icon from 'components/icon'
import S from 'components/filters_sidebar/style'

import {
  deleteFilterTemplate,
  fetchFilterTemplates,
  selectShipmentFilters,
  selectShipmentFiltersStatus,
} from 'views/shipments/slices/filter_templates_slice'
import { addNotification } from 'views/notifications/slice'
import ErrorNotification from 'views/errors/error_notification'

import useStatus from 'services/api/hooks/use_status'
import { isAnyArray } from 'services/helpers/values'
import useTracker from 'services/analytics/hooks/use_tracker'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import {
  StyledSavedFilters,
  StyledSavedFiltersInner,
  StyledSavedFiltersEdit,
} from 'components/shipment_filters_saved/style'

import type { TemplateFilter } from 'components/shipment_filters'

const SavedFilters = React.memo(() => {
  const [editMode, setEditMode] = useState(false)
  const { track } = useTracker()

  const dispatch = useAppDispatch()
  const filterTemplates = useSelector(selectShipmentFilters)
  const anyTemplate = isAnyArray(filterTemplates)
  const status = useStatus(useSelector(selectShipmentFiltersStatus))
  const { t } = useTranslation()
  const { currentTab, switchToCustomTab } = useContext(FilterTabContext)
  const { setTemplateFilters } = useContext(TemplateFiltersContext)

  const applyFilters = (filters: TemplateFilter[]) => {
    setTemplateFilters(filters)
    switchToCustomTab()
  }
  useEffect(() => {
    dispatch(fetchFilterTemplates())
  }, [dispatch])

  const onTabChange = () => switchToCustomTab()

  return (
    <Sidebar>
      <Sidebar.Header>
        <S.Header>
          <S.HeaderIcon as={Icon} name='settings' />
          <Sidebar.Title>{t('filtersSidebar.title')}</Sidebar.Title>
        </S.Header>
      </Sidebar.Header>
      <Sidebar.Content>
        <StyledShipmentFilters>
          <StyledShipmentFiltersTabs as={Tabs} defaultTabId={currentTab} onTabChange={onTabChange}>
            <Tab
              id='custom-tab'
              label={t('shipments.filters.custom')}
              key='shipment-filters-custom'
            />
            <Tab
              id='templates-tab'
              label={t('shipments.filters.saved')}
              key='shipment-filters-templates'
            >
              <StyledSavedFilters>
                <StyledSavedFiltersEdit onClick={() => setEditMode(!editMode)}>
                  {anyTemplate && !editMode && <span>{t('actions.edit')}</span>}
                  {anyTemplate && editMode && <span>{t('actions.close')}</span>}
                </StyledSavedFiltersEdit>
                <StyledSavedFiltersInner>
                  <Placeholder
                    ready={status.ready}
                    customPlaceholder={<ShipmentFiltersSavedSkeleton count={8} />}
                  >
                    {status.rejected && <ErrorNotification />}
                    {!status.rejected && !anyTemplate && (
                      <NotifMessage
                        type='info'
                        text={t('shipments.filterTemplates.noResults.text')}
                      />
                    )}
                    {filterTemplates.map(({ name, filters, tags, id }) => (
                      <SavedFiltersItem
                        key={`template-${id}`}
                        label={name}
                        editMode={editMode}
                        onClickDelete={() =>
                          dispatch(deleteFilterTemplate(id))
                            .unwrap()
                            .then(() =>
                              dispatch(
                                addNotification({
                                  type: 'success',
                                  title: t('shipments.filterTemplates.deletion.request'),
                                  text: t('shipments.filterTemplates.creation.success'),
                                })
                              )
                            )
                            .catch(() =>
                              dispatch(
                                addNotification({
                                  type: 'alert',
                                  title: t('shipments.filterTemplates.deletion.request'),
                                  text: t('shipments.filterTemplates.creation.fail'),
                                })
                              )
                            )
                        }
                        onClickItem={() => {
                          if (!editMode) {
                            applyFilters(filters)
                            track('Shipment list / apply filter template', {
                              name,
                              categories: filters.map(({ category }) => category),
                            })
                          }
                        }}
                      >
                        {tags.map(({ value }) => (
                          <StyledShipmentFiltersTag
                            as={Tag}
                            text={value}
                            key={`template-${id}-tag-${value}`}
                          />
                        ))}
                      </SavedFiltersItem>
                    ))}
                  </Placeholder>
                </StyledSavedFiltersInner>
              </StyledSavedFilters>
            </Tab>
          </StyledShipmentFiltersTabs>
        </StyledShipmentFilters>
      </Sidebar.Content>
    </Sidebar>
  )
})

export default memo(SavedFilters)
