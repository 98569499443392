import React, { useContext, useMemo } from 'react'

import { SHIPMENT_VIEW_LARGE } from 'constants/shipments'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'
import Step from 'views/shipments/components/step'

import { StyledTimeline } from 'components/shipment_timeline/style'

export { default as ShipmentTimelineStep } from 'components/shipment_timeline/shipment_timeline_step'

const ShipmentTimeline = ({ children }) => {
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view !== SHIPMENT_VIEW_LARGE, [view])

  return <StyledTimeline $condensed={isCondensed}>{children}</StyledTimeline>
}

ShipmentTimeline.propTypes = {
  children: validateChildrenOfTypes([Step]),
}

ShipmentTimeline.defaultProps = {
  children: undefined,
}

export default ShipmentTimeline
