import React, { useState } from 'react'

import FilterSidebar from 'features/users/components/filter_sidebar'
import Users from 'features/users/components/user_list'

import PS from 'components/page/style'
import Page from 'components/page'

const UsersPage: React.FC = () => {
  const [search, setSearch] = useState<string>('')
  const [organizationIdFilter, setOrganizationIdFilter] = useState<number | undefined>()

  return (
    <PS.ScrollbarPage
      sidebar={
        <FilterSidebar setSearch={setSearch} setOrganizationIdFilter={setOrganizationIdFilter} />
      }
      as={Page}
    >
      <Users organizationIdFilter={organizationIdFilter} search={search} />
    </PS.ScrollbarPage>
  )
}

export default UsersPage
