import { useSelector } from 'react-redux'

import useStatus from 'services/api/hooks/use_status'

import {
  selectLastEvents,
  selectBookingLastEventsStatus,
} from 'views/booking/slices/last_events_slice'

const useBookingLastEvents = () => {
  const lastEvents = useSelector((state) => selectLastEvents(state))
  const status = useStatus(useSelector((state) => selectBookingLastEventsStatus(state)))
  return [lastEvents, status]
}

export default useBookingLastEvents
