import React, { FC, useContext } from 'react'

import { useTranslation } from 'react-i18next'

import {
  StyledFormEditGroup,
  StyledFormEditGroupItems,
  StyledFormEditGroupLabel,
  StyledFormEditGroupTitle,
  StyledFormEditGroupValue,
  StyledFormEditGroupValues,
  StyledFormPartiesGroupItem,
} from 'views/booking/components/show/style'
import useBooking from 'views/booking/hooks/use_booking'
import BookingContext from 'views/booking/contexts/booking_context'

import { isPresent } from 'services/helpers/values'

const InformationParties: FC = () => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { t } = useTranslation()
  const { shipper, forwarder, consignor, consignee } = booking!

  return (
    <StyledFormEditGroup>
      <StyledFormEditGroupTitle>{t('bookings.parties.title')}</StyledFormEditGroupTitle>
      <StyledFormEditGroupItems>
        <StyledFormPartiesGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.parties.shipper')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValues>
            <StyledFormEditGroupValue>{shipper!.name}</StyledFormEditGroupValue>
            {isPresent(shipper!.address) && (
              <StyledFormEditGroupValue>{shipper!.address}</StyledFormEditGroupValue>
            )}
          </StyledFormEditGroupValues>
        </StyledFormPartiesGroupItem>
        <StyledFormPartiesGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.parties.forwarder')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValue>{forwarder!.name}</StyledFormEditGroupValue>
        </StyledFormPartiesGroupItem>
        <StyledFormPartiesGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.parties.consignor')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValues>
            <StyledFormEditGroupValue>{consignor!.name}</StyledFormEditGroupValue>
            {isPresent(consignor!.address) && (
              <StyledFormEditGroupValue>{consignor!.address}</StyledFormEditGroupValue>
            )}
          </StyledFormEditGroupValues>
        </StyledFormPartiesGroupItem>
        <StyledFormPartiesGroupItem>
          <StyledFormEditGroupLabel>{t('bookings.parties.consignee')}</StyledFormEditGroupLabel>
          <StyledFormEditGroupValues>
            <StyledFormEditGroupValue>{consignee!.name}</StyledFormEditGroupValue>
            {isPresent(consignee!.address) && (
              <StyledFormEditGroupValue>{consignee!.address}</StyledFormEditGroupValue>
            )}
          </StyledFormEditGroupValues>
        </StyledFormPartiesGroupItem>
      </StyledFormEditGroupItems>
    </StyledFormEditGroup>
  )
}

export default InformationParties
