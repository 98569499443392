import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import { Category } from 'views/analytics/types'

import type { Analytics } from 'views/analytics/types'
import type { RootState } from 'services/store/store'
import type { Status } from 'constants/api'

export const fetchTokenAndReportId = createAsyncThunk<Analytics, Category, { state: RootState }>(
  'analytics/fetchTokenAndReportId',
  async (category: string, thunkAPI) =>
    InternalClient.get(`/analytics/${category}`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)
const analyticsAdapter = createEntityAdapter<Analytics>()
const initialState = analyticsAdapter.getInitialState<{ status: Status }>({
  status: STATUS_PENDING,
})

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTokenAndReportId.fulfilled, (state) => {
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchTokenAndReportId.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchTokenAndReportId.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const selectAnalyticsStatus = (state: RootState) => state.analytics.status

export default analyticsSlice.reducer
