import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { IFilter } from 'services/hooks/use_filter'

import Form from 'components/form'
import Grid from 'components/grid'

import { BookingMerchandisePackageContent } from 'views/booking/slices/types'

import Select from 'components/select'
import Input from 'components/input'

import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'

import {
  StyledAddMerchandisePackageWrapper,
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'

import Button from 'components/button'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

import {
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandisePackage,
  getTestIdForMerchandiseProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'

interface MerchandisePackageLegacyProps {
  merchandiseFilter: IFilter<'custom'>
}

const MerchandisePackageLegacy: FC<MerchandisePackageLegacyProps> = ({
  merchandiseFilter,
}: MerchandisePackageLegacyProps) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return (
    <Form.Group>
      <Form.Insert subtitle={t('bookings.merchandise.packagesRequest')}>
        {merchandiseFilter.value.package.map(
          (_package: BookingMerchandisePackageContent, index: number) => (
            <Form.Insert subtitle={`${t('bookings.merchandise.packageRequest')} #${index + 1}`}>
              {index !== 0 && (
                <StyledRemoveMerchandiseItemWrapper>
                  <Button
                    disabled={merchandiseFilter.isDisabled}
                    variant='icon'
                    icon='trash'
                    onClick={() => {
                      merchandiseFilter.onChange((state: any) => {
                        state.package.splice(index, 1)
                      })
                    }}
                  />
                </StyledRemoveMerchandiseItemWrapper>
              )}
              <Grid columns={3}>
                <Grid.Row compact testId={getTestIdForMerchandisePackage(index)}>
                  <Grid.Column>
                    <Select
                      label={t('bookings.merchandise.type')}
                      placeholder={t('bookings.merchandise.packageType')}
                      options={fromStaticToSelectOptions('packageTypes').map(
                        ({ label, value }) => ({
                          value,
                          label,
                        })
                      )}
                      name={`packageType-${index}`}
                      value={merchandiseFilter.value.package[index].packageType}
                      onChange={({ value }) => {
                        merchandiseFilter.onChange((state: any) => {
                          state.package[index].packageType = value
                        })
                      }}
                      required={!merchandiseFilter.isDisabled}
                      isDisabled={merchandiseFilter.isDisabled}
                      inputId={`${merchandiseFilter.name}-type-${index}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={t('bookings.merchandise.quantity')}
                      type='number'
                      placeholder={t('bookings.merchandise.quantity')}
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY}
                      name={`packageQuantity-${index}`}
                      value={merchandiseFilter.value.package[index].quantity}
                      error={merchandiseFilter.error?.package?.[index]?.quantity}
                      onChange={({ target: { value } }) => {
                        merchandiseFilter.onChange((state: any) => {
                          state.package[index].quantity = value
                        })
                      }}
                      required={!merchandiseFilter.isDisabled}
                      disabled={merchandiseFilter.isDisabled}
                      id={`${merchandiseFilter.name}-quantity-${index}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={t('bookings.merchandise.packageWeight')}
                      placeholder='kg'
                      step='0.01'
                      type='number'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                      name={`packageWeight-${index}`}
                      value={merchandiseFilter.value.package[index]?.weight?.value}
                      error={merchandiseFilter.error?.package?.[index]?.weight}
                      onChange={({ target: { value } }) => {
                        merchandiseFilter.onChange((state: any) => {
                          if (!state.package[index].weight) state.package[index].weight = {}
                          state.package[index].weight.value = value
                        })
                      }}
                      required={!merchandiseFilter.isDisabled}
                      disabled={merchandiseFilter.isDisabled}
                      id={`${merchandiseFilter.name}-weight-${index}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={t('bookings.merchandise.packageLength')}
                      type='number'
                      step='0.01'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.LENGTH}
                      placeholder='cm'
                      name={`packageLength-${index}`}
                      value={merchandiseFilter.value.package[index]?.length?.value}
                      error={merchandiseFilter.error?.package?.[index]?.length}
                      onChange={({ target: { value } }) => {
                        merchandiseFilter.onChange((state: any) => {
                          if (!state.package[index].length) state.package[index].length = {}
                          state.package[index].length.value = value
                        })
                      }}
                      required={!merchandiseFilter.isDisabled}
                      disabled={merchandiseFilter.isDisabled}
                      id={`${merchandiseFilter.name}-length-${index}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={t('bookings.merchandise.packageWidth')}
                      type='number'
                      step='0.01'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WIDTH}
                      placeholder='cm'
                      name={`packageWidth-${index}`}
                      value={merchandiseFilter.value.package[index]?.width?.value}
                      error={merchandiseFilter.error?.package?.[index]?.width}
                      onChange={({ target: { value } }) => {
                        merchandiseFilter.onChange((state: any) => {
                          if (!state.package[index].width) state.package[index].width = {}
                          state.package[index].width.value = value
                        })
                      }}
                      required={!merchandiseFilter.isDisabled}
                      disabled={merchandiseFilter.isDisabled}
                      id={`${merchandiseFilter.name}-width-${index}`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label={t('bookings.merchandise.packageHeight')}
                      type='number'
                      step='0.01'
                      min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.HEIGHT}
                      placeholder='cm'
                      name={`packageHeight-${index}`}
                      value={merchandiseFilter.value.package[index]?.height?.value}
                      error={merchandiseFilter.error?.package?.[index]?.height}
                      onChange={({ target: { value } }) => {
                        merchandiseFilter.onChange((state: any) => {
                          if (!state.package[index].height) state.package[index].height = {}
                          state.package[index].height.value = value
                        })
                      }}
                      required={!merchandiseFilter.isDisabled}
                      disabled={merchandiseFilter.isDisabled}
                      id={`${merchandiseFilter.name}-height-${index}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form.Insert>
          )
        )}
        <StyledAddMerchandisePackageWrapper>
          <Button
            onClick={() =>
              merchandiseFilter.onChange((state: any) => {
                state.package[state.package.length] = {}
              })
            }
            disabled={merchandiseFilter.isDisabled}
            icon='plus_outline'
            variant='dashed'
            text={`${t('actions.addNew', { context: 'female' })} ${t(
              'bookings.merchandise.packageRequest'
            ).toLowerCase()}`}
          />
        </StyledAddMerchandisePackageWrapper>
      </Form.Insert>
      <Form.Insert subtitle={t('bookings.merchandise.merchandiseDetails')}>
        <Grid columns={3}>
          <Grid.Row testId={getTestIdForMerchandiseProduct(0)}>
            <Grid.Column>
              <Textarea
                label={t('bookings.merchandise.productDescription')}
                placeholder={t('bookings.merchandise.yourDescription')}
                name={`merchandiseDescription-${0}`}
                rows={4}
                compact
                value={merchandiseFilter.value.package[0].productDescription}
                onChange={({ target: { value } }) =>
                  merchandiseFilter.onChange((state: any) => {
                    state.package[0].productDescription = value
                  })
                }
                disabled={merchandiseFilter.isDisabled}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                type='number'
                step='0.01'
                min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
                label={t('bookings.merchandise.commercialValue')}
                placeholder={t('bookings.merchandise.amount')}
                name={`merchandiseAmount-${0}`}
                value={merchandiseFilter.value.package[0].commercialValue?.amount}
                error={merchandiseFilter.error?.package[0]?.commercialValueAmount}
                onChange={({ target: { value } }) =>
                  merchandiseFilter.onChange((state: any) => {
                    if (!state.package[0].commercialValue) state.package[0].commercialValue = {}
                    state.package[0].commercialValue.amount = value
                  })
                }
                disabled={merchandiseFilter.isDisabled}
              />
              <Select
                placeholder={t('bookings.merchandise.currency')}
                options={fromStaticToSelectOptions('currencies')}
                name={`merchandiseCurrencyCode-${0}`}
                value={merchandiseFilter.value.package[0].commercialValue?.currencyCode}
                error={merchandiseFilter.error?.package[0]?.commercialValueCurrencyCode}
                onChange={({ value }: { value: any }) =>
                  merchandiseFilter.onChange((state: any) => {
                    if (!state.package[0].commercialValue) state.package[0].commercialValue = {}
                    state.package[0].commercialValue.currencyCode = value
                  })
                }
                testId={getTestIdForMerchandiseProductCurrencyCode(0)}
                isDisabled={merchandiseFilter.isDisabled}
              />
            </Grid.Column>
            <Grid.Column>
              <Input
                label={t('bookings.merchandise.controlledTemperatures.title')}
                placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
                type='number'
                step='0.01'
                testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
                name={`merchandiseTemperatureMinimal-${0}`}
                value={merchandiseFilter.value.package[0].controlledTemperatures?.min}
                disabled={merchandiseFilter.isDisabled}
                onChange={({ target: { value } }) =>
                  merchandiseFilter.onChange((state: any) => {
                    if (!state.package[0].controlledTemperatures)
                      state.package[0].controlledTemperatures = {}
                    state.package[0].controlledTemperatures.min = value
                  })
                }
              />
              <Input
                placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
                type='number'
                step='0.01'
                testId={getTestIdForMerchandiseProductMaximalTemperature(0)}
                name={`merchandiseTemperatureMaximal-${0}`}
                value={merchandiseFilter.value.package[0].controlledTemperatures?.max}
                disabled={merchandiseFilter.isDisabled}
                onChange={({ target: { value } }) =>
                  merchandiseFilter.onChange((state: any) => {
                    if (!state.package[0].controlledTemperatures)
                      state.package[0].controlledTemperatures = {}
                    state.package[0].controlledTemperatures.max = value
                  })
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Select
                label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
                placeholder={t('actions.select')}
                options={fromStaticToSelectOptions('hazardousGoods')}
                name={`merchandiseHazardousClass-${0}`}
                value={merchandiseFilter.value.package[0].hazardousGoods?.hazardousClass}
                onChange={({ value }: { value: any }) =>
                  merchandiseFilter.onChange((state: any) => {
                    if (!state.package[0].hazardousGoods) state.package[0].hazardousGoods = {}
                    state.package[0].hazardousGoods.hazardousClass = value
                  })
                }
                isDisabled={merchandiseFilter.isDisabled}
                isClearable
              />
            </Grid.Column>
            <Grid.Column>
              <Select
                label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
                placeholder={t('actions.select')}
                options={fromStaticToSelectOptions('packingGroups')}
                name={`merchandiseHazardousPackingGroup-${0}`}
                value={merchandiseFilter.value.package[0].hazardousGoods?.packingGroup}
                onChange={({ value }: { value: any }) =>
                  merchandiseFilter.onChange((state: any) => {
                    if (!state.package[0].hazardousGoods) state.package[0].hazardousGoods = {}
                    state.package[0].hazardousGoods.packingGroup = value
                  })
                }
                isDisabled={merchandiseFilter.isDisabled}
                isClearable
              />
            </Grid.Column>
            <Grid.Column>
              <StyledHazardousGoodsWrapper>
                <StyledHazardousGoodsItemInput
                  label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                  placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                  name={`merchandiseHazardousUnNumber-${0}`}
                  value={merchandiseFilter.value.package[0].hazardousGoods?.unNumber}
                  disabled={merchandiseFilter.isDisabled}
                  onChange={({ target: { value } }) =>
                    merchandiseFilter.onChange((state: any) => {
                      if (!state.package[0].hazardousGoods) state.package[0].hazardousGoods = {}
                      state.package[0].hazardousGoods.unNumber = value
                    })
                  }
                />
                <StyledHazardousGoodsItemInput
                  label={t('bookings.merchandise.product.hazardousGoods.weight')}
                  placeholder='kg'
                  type='number'
                  step='0.01'
                  min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                  name={`merchandiseHazardousWeight-${0}`}
                  value={merchandiseFilter.value.package[0].hazardousGoods?.weight?.value}
                  disabled={merchandiseFilter.isDisabled}
                  error={merchandiseFilter.error?.package[0]?.hazardousWeight}
                  onChange={({ target: { value } }) =>
                    merchandiseFilter.onChange((state: any) => {
                      if (!state.package[0].hazardousGoods) state.package[0].hazardousGoods = {}

                      if (!state.package[0].hazardousGoods.weight)
                        state.package[0].hazardousGoods.weight = {}

                      state.package[0].hazardousGoods.weight.value = value
                    })
                  }
                />
              </StyledHazardousGoodsWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form.Insert>
    </Form.Group>
  )
}

export default MerchandisePackageLegacy
