import React, { FC, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'

import ShipmentContext from 'components/shipment_list_item/context'
import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'

import { StyledCondensedCard, StyledShipmentListItem } from 'components/shipment_list_item/style'
import { routeShipment } from 'services/helpers/routes'
import useModal from 'components/modal/hooks/use_modal'

interface CardProps {
  testId?: string
}

const Card: FC<CardProps> = ({ children, testId }) => {
  const { setOpen } = useModal('dashboardMap')
  const { shipment } = useContext(ShipmentContext)
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])
  const onClick = () => {
    setOpen(false)
  }
  return isCondensed ? (
    <StyledCondensedCard
      as={Link}
      to={routeShipment(shipment.id)}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </StyledCondensedCard>
  ) : (
    <StyledShipmentListItem
      as={Link}
      to={routeShipment(shipment.id)}
      onClick={onClick}
      data-testid={testId}
    >
      {children}
    </StyledShipmentListItem>
  )
}

export default Card
