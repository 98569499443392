import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

import Icon from 'components/icon'
import { anyChildren, numberInferiorEqualTo } from 'services/helpers/prop_types'
import Form from 'components/form'

import {
  StyledBlockContainerItem,
  StyledDeleteButton,
  StyledEmptyIcon,
} from 'components/block_container/style'
import { BlockContainerProps } from 'components/block_container/types'

export type BlockContainerDuplicateProps = Pick<
  BlockContainerProps,
  'columns' | 'title' | 'testId' | 'disabled' | 'size'
>

type AdditionnalProps = {
  children: ReactNode
  index: number
  length?: number
  minLength: number
  removeBlock: () => void
}

type BlockContainerItemProps = BlockContainerDuplicateProps & AdditionnalProps

const BlockContainerItem = ({
  title,
  index,
  removeBlock,
  children,
  columns,
  size,
  length,
  minLength,
  disabled = false,
  testId,
}: BlockContainerItemProps) => {
  const fullTitle = `${title} #${index + 1}`
  return (
    <StyledBlockContainerItem
      $columns={columns}
      $size={size}
      $centered={columns === 1}
      data-testid={testId}
    >
      <Form.Insert subtitle={fullTitle}>
        <>
          {!disabled && (
            <StyledDeleteButton onClick={removeBlock}>
              {index >= minLength || length !== 1 ? <Icon name='trash' /> : <StyledEmptyIcon />}
            </StyledDeleteButton>
          )}
          {children}
        </>
      </Form.Insert>
    </StyledBlockContainerItem>
  )
}
BlockContainerItem.propTypes = {
  /** title */
  title: PropTypes.string,
  /** index */
  index: PropTypes.number,
  /** length */
  length: PropTypes.number,
  /** minLength */
  minLength: PropTypes.number,
  /** removeBlock */
  removeBlock: PropTypes.func,
  /** children */
  children: anyChildren,
  /** columns */
  columns: numberInferiorEqualTo(4, false),
  /** size */
  size: numberInferiorEqualTo(4, false),
  disabled: PropTypes.bool,
  testId: PropTypes.string,
}

BlockContainerItem.defaultProps = {
  title: undefined,
  index: undefined,
  length: undefined,
  minLength: undefined,
  removeBlock: undefined,
  children: undefined,
  size: undefined,
  columns: undefined,
  disabled: false,
  testId: undefined,
}

export default BlockContainerItem
