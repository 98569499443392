import { CustomReference } from 'types/bookings'

import { AnyBookingMerchandise, CommonMerchandiseDetails } from 'views/booking/slices/types'

import {
  AirTransportAttributes,
  LastEventChanges,
  SeaTransportAttributes,
  ShipmentAttributes,
  Vessel,
  LastEvent,
  Rate,
} from 'components/booking_last_event/types'

import DateHelper from 'services/helpers/date_helper'
import { capitalize, isArray, isObject, isPresent, toStartCase } from 'services/helpers/values'
import i18n from 'views/locales/i18n'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

export interface LastEventFormattedChange {
  category: string
  withTooltip: boolean
  oldValues?: FormattedValues
  newValues?: FormattedValues
}

export type FormattedValues = (FormattedValue | null)[] | undefined | null

export interface FormattedValue {
  category: string | null
  nestedValues?: FormattedValues
}

interface LastEventChangeFormatter {
  withTooltip: boolean
  formatValue: (value: any) => FormattedValues
}

const useLastEventFormattedChanges = (
  lastEvent: LastEvent
): { formattedChanges: LastEventFormattedChange[] } => {
  const { s } = useStaticLocales()
  const { features } = useOrganizationCan()

  if (!lastEvent.changes)
    return {
      formattedChanges: [],
    }

  const notApplicable = 'N/A'

  const formatString = (value: string): FormattedValues => [{ category: value ?? notApplicable }]

  const formatRateConfirmation = (value: boolean) => [
    { category: value ? i18n.t('alerts.yes') : i18n.t('alerts.no') },
  ]

  const formatDate = (value: Date): FormattedValues => [
    { category: value ? new DateHelper(value).toSlash() : notApplicable },
  ]

  const formatRate = (rate: Rate): FormattedValues => [
    {
      category: isPresent(rate.amount)
        ? `${isPresent(rate.currencyCode) ? `${rate.amount} ${rate.currencyCode}` : rate.amount}`
        : notApplicable,
    },
  ]

  const formatStrings = (value: string[]): FormattedValues => [{ category: value.join(', ') }]

  const formatVoyageNumbers = (value: string[]): FormattedValues =>
    value.length === 0 ? [{ category: notApplicable }] : value.map((v) => ({ category: v }))

  const formatReference = (value: CustomReference[]): FormattedValues =>
    value.length === 0
      ? [{ category: notApplicable }]
      : value.map((ref) => ({ category: `${capitalize(ref.key)}: ${ref.value}` }))

  const formatVessels = (value: Vessel[]): FormattedValues =>
    value.length === 0
      ? [{ category: notApplicable }]
      : value.map((vessel) => ({ category: `${vessel.name} (${vessel.imoNumber})` }))

  const formatShipmentAttributes = (value: ShipmentAttributes): FormattedValues => {
    if (value.transportAttributes.length === 0) return [{ category: notApplicable }]

    return value.transportType === 'sea'
      ? value.transportAttributes.map((transportAttributes, index: number) => {
          const seaAttributes = transportAttributes as SeaTransportAttributes
          return {
            category: `${i18n.t('bookings.lastEvents.shipment')} #${index + 1} - ${
              seaAttributes.containerNumber
            } - ${seaAttributes.shipmentReference} - ${seaAttributes.weight} kg - ${
              seaAttributes.containerType
            }`,
          }
        })
      : value.transportAttributes.map((transportAttributes, index: number) => {
          const airAttributes = transportAttributes as AirTransportAttributes
          return {
            category: `${i18n.t('bookings.lastEvents.shipment')} #${index + 1} - ${
              airAttributes.masterAwb
            } - ${airAttributes.shipmentReference} - ${airAttributes.weight} kg`,
          }
        })
  }

  const formatRouting = (
    value: { addressName: string; date: Date; routeType: string }[]
  ): FormattedValues =>
    value.map((location) => ({
      category: `${location.addressName} - ${location.routeType} - ${
        location.date ? new DateHelper(location.date).toSlash() : notApplicable
      }`,
    }))

  const formatCommercialValue = (p: CommonMerchandiseDetails) =>
    `${i18n.t('bookings.lastEvents.commercialValue')}: ${
      p.commercialValue?.amount
        ? `${p.commercialValue.amount} ${p.commercialValue.currencyCode}`
        : notApplicable
    }`

  const formatControlledTemperatures = (p: CommonMerchandiseDetails) =>
    `${i18n.t('bookings.lastEvents.controlledTemperatures')}: ${`Min: ${
      p.controlledTemperatures?.min ?? notApplicable
    } - Max: ${p.controlledTemperatures?.max ?? notApplicable}`}`

  const formatNumberWithUnit = (prefix: string, unitNumber: { value: number; unit: string }) =>
    `${prefix}: ${unitNumber?.value ? `${unitNumber.value} ${unitNumber.unit}` : notApplicable}`

  const formatValueWithDefault = (prefix: string, value: any) =>
    `${prefix}: ${value ?? notApplicable}`

  const formattedMerchandiseDetails = (p: CommonMerchandiseDetails) =>
    [
      isPresent(p.productDescription)
        ? formatValueWithDefault(
            i18n.t('bookings.lastEvents.productDescription'),
            p.productDescription
          )
        : null,
      isPresent(p.commercialValue?.amount) ? formatCommercialValue(p) : null,
      isPresent(p.controlledTemperatures?.min) || isPresent(p.controlledTemperatures?.max)
        ? formatControlledTemperatures(p)
        : null,
      isPresent(p.hazardousGoods?.hazardousClass) && isPresent(p.hazardousGoods)
        ? formatValueWithDefault(
            i18n.t('bookings.lastEvents.hazardousClass'),
            s('hazardousGoods')[p.hazardousGoods?.hazardousClass]
          )
        : null,
      isPresent(p.hazardousGoods?.packingGroup) && isPresent(p.hazardousGoods)
        ? formatValueWithDefault(
            i18n.t('bookings.lastEvents.packingGroup'),
            s('packingGroups')[p.hazardousGoods?.packingGroup]
          )
        : null,
      isPresent(p.hazardousGoods?.weight) && isPresent(p.hazardousGoods)
        ? formatNumberWithUnit(
            i18n.t('bookings.lastEvents.hazardousWeight'),
            p.hazardousGoods?.weight
          )
        : null,
      isPresent(p.hazardousGoods?.unNumber)
        ? formatValueWithDefault(i18n.t('bookings.lastEvents.unNumber'), p.hazardousGoods?.unNumber)
        : null,
    ].filter((v) => isPresent(v))

  const formatMerchandise = (value: AnyBookingMerchandise): FormattedValues => {
    switch (value.merchandiseType) {
      case 'total':
        return [
          { category: `${i18n.t('bookings.lastEvents.type')}: ${value.shippingMode}` },
          ...(value.content?.containers.map((c) => ({
            category: `${c.quantity} x ${s('containerTypes')[c.containerType]}`,
          })) ?? []),
          ...(value.content?.products?.map((p, index) => ({
            category: `${i18n.t('bookings.lastEvents.merchandiseDetails')} #${index + 1}`,
            nestedValues: [
              isPresent(p.weight)
                ? {
                    category: formatNumberWithUnit(i18n.t('bookings.lastEvents.weight'), p.weight),
                  }
                : null,
              isPresent(p.volume)
                ? {
                    category: formatNumberWithUnit(i18n.t('bookings.lastEvents.volume'), p.volume),
                  }
                : null,
              isPresent(p.packageNumber)
                ? {
                    category: formatValueWithDefault(
                      i18n.t('bookings.lastEvents.numberOfPackages'),
                      p.packageNumber
                    ),
                  }
                : null,
              ...formattedMerchandiseDetails(p).map((d) => ({
                category: d,
              })),
            ].filter((d) => isPresent(d)),
          })) ?? []),
        ]
      case 'package_total':
        return [
          { category: `${i18n.t('bookings.lastEvents.type')}: ${value.shippingMode}` },
          {
            category: `${i18n.t('bookings.lastEvents.weight')}: ${value.weight.value} ${
              value.weight.unit
            }`,
          },
          {
            category: `${i18n.t('bookings.lastEvents.volume')}: ${value.volume.value} ${
              value.volume.unit
            }`,
          },
          {
            category: `${i18n.t('bookings.lastEvents.numberOfPackages')}: ${
              value.packageNumber ?? notApplicable
            }`,
          },
          ...value.content.map((m, index) => ({
            category: `${i18n.t('bookings.lastEvents.merchandiseDetails')} #${index + 1}`,
            nestedValues: [
              ...formattedMerchandiseDetails(m).map((p) => ({
                category: p,
              })),
            ],
          })),
        ]
      case 'container':
        return [
          { category: `${i18n.t('bookings.lastEvents.type')}: ${value.shippingMode}` },
          ...(value.content?.map((c) => ({
            category: `${c.quantity} x ${s('containerTypes')[c.containerType]}`,
            nestedValues: c.products?.map((p, index) => ({
              category: `${i18n.t('bookings.lastEvents.merchandiseDetails')} #${index + 1}`,
              nestedValues: [
                isPresent(p.weight)
                  ? {
                      category: formatNumberWithUnit(
                        i18n.t('bookings.lastEvents.weight'),
                        p.weight
                      ),
                    }
                  : null,
                isPresent(p.volume)
                  ? {
                      category: formatNumberWithUnit(
                        i18n.t('bookings.lastEvents.volume'),
                        p.volume
                      ),
                    }
                  : null,
                isPresent(p.packageNumber)
                  ? {
                      category: formatValueWithDefault(
                        i18n.t('bookings.lastEvents.numberOfPackages'),
                        p.packageNumber
                      ),
                    }
                  : null,
                ...formattedMerchandiseDetails(p).map((d) => ({
                  category: d,
                })),
              ].filter((v) => isPresent(v)),
            })),
          })) ?? []),
        ]
      case 'package':
        return [
          { category: `${i18n.t('bookings.lastEvents.type')}: ${value.shippingMode}` },
          ...(value.content?.map((p) => ({
            category: `${p.quantity} x ${s('packageTypes')[p.packageType]}`,
            nestedValues: [
              isPresent(p.weight?.value)
                ? {
                    category: formatNumberWithUnit(i18n.t('bookings.lastEvents.weight'), p.weight),
                  }
                : null,
              isPresent(p.width?.value)
                ? {
                    category: formatNumberWithUnit(i18n.t('bookings.lastEvents.width'), p.width),
                  }
                : null,
              isPresent(p.length?.value)
                ? {
                    category: formatNumberWithUnit(i18n.t('bookings.lastEvents.length'), p.length),
                  }
                : null,
              isPresent(p.height?.value)
                ? {
                    category: formatNumberWithUnit(i18n.t('bookings.lastEvents.height'), p.height),
                  }
                : null,
              ...(features(WITH_BOOKING_OLD_WORKFLOW)
                ? []
                : formattedMerchandiseDetails(p).map((d) => ({
                    category: d,
                  }))),
            ].filter((d) => isPresent(d)),
          })) ?? []),
          ...(features(WITH_BOOKING_OLD_WORKFLOW) && value.content?.[0]
            ? formattedMerchandiseDetails(value.content[0]).map((d) => ({
                category: d,
              }))
            : []),
        ]
      default:
        return [{ category: notApplicable }]
    }
  }

  const formatKey = (key: string) => {
    try {
      const index = parseInt(key, 10)

      if (Number.isNaN(index)) return key

      return `Item ${index + 1}`
    } catch {
      return key
    }
  }

  const formatOrDefault = (
    value: any,
    formatValue?: (v: typeof value) => FormattedValues
  ): FormattedValues => {
    const formatDefault = (dv: any) =>
      Object.entries(dv).map(([k, v]) => {
        if (isObject(v) || isArray(v)) {
          return { category: formatKey(toStartCase(k)), nestedValues: formatOrDefault(v) }
        }

        return { category: `${formatKey(toStartCase(k))}: ${v ?? notApplicable}` }
      })

    if (!formatValue) return formatDefault(value)

    try {
      return formatValue(value)
    } catch {
      return formatDefault(value)
    }
  }

  const getFormatter = (key: keyof LastEventChanges): LastEventChangeFormatter =>
    ({
      incoterms: {
        withTooltip: false,
        formatValue: formatString,
      },
      incotermsLocation: {
        withTooltip: false,
        formatValue: formatString,
      },
      clientReference: {
        withTooltip: false,
        formatValue: formatString,
      },
      clientBookingNumber: {
        withTooltip: false,
        formatValue: formatString,
      },
      forwarderReference: {
        withTooltip: false,
        formatValue: formatString,
      },
      bookingNumber: {
        withTooltip: false,
        formatValue: formatString,
      },
      masterBl: {
        withTooltip: false,
        formatValue: formatString,
      },
      vgmCutOffDate: {
        withTooltip: false,
        formatValue: formatDate,
      },
      vesselCutOffDate: {
        withTooltip: false,
        formatValue: formatDate,
      },
      comments: {
        withTooltip: false,
        formatValue: formatString,
      },
      forwarderComment: {
        withTooltip: false,
        formatValue: formatString,
      },
      voyageNumbers: {
        withTooltip: false,
        formatValue: formatVoyageNumbers,
      },
      flightNumbers: {
        withTooltip: false,
        formatValue: formatVoyageNumbers,
      },
      shipper: {
        withTooltip: false,
        formatValue: formatString,
      },
      forwarder: {
        withTooltip: false,
        formatValue: formatString,
      },
      consignor: {
        withTooltip: false,
        formatValue: formatString,
      },
      consignee: {
        withTooltip: false,
        formatValue: formatString,
      },
      carrier: {
        withTooltip: false,
        formatValue: formatString,
      },
      keyContacts: {
        withTooltip: false,
        formatValue: formatStrings,
      },
      customReferences: {
        withTooltip: true,
        formatValue: formatReference,
      },
      customFields: {
        withTooltip: true,
        formatValue: formatReference,
      },
      vessels: {
        withTooltip: false,
        formatValue: formatVessels,
      },
      shipmentAttributes: {
        withTooltip: true,
        formatValue: formatShipmentAttributes,
      },
      merchandise: {
        withTooltip: false,
        formatValue: (value: AnyBookingMerchandise) => formatMerchandise(value),
      },
      routing: {
        withTooltip: true,
        formatValue: formatRouting,
      },
      rateConfirmation: {
        withTooltip: false,
        formatValue: formatRateConfirmation,
      },
      rate: {
        withTooltip: false,
        formatValue: formatRate,
      },
    }[key])

  return {
    formattedChanges: Object.entries(lastEvent.changes).map(([key, value]) => {
      const formatter = getFormatter(key as keyof LastEventChanges)

      return {
        category: key,
        withTooltip: formatter.withTooltip,
        oldValues: formatOrDefault(value[0], formatter.formatValue),
        newValues: formatOrDefault(value[1], formatter.formatValue),
      }
    }),
  }
}

export default useLastEventFormattedChanges
