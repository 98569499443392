import { createAsyncThunk, createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DIRECTION_ASCENDING, DIRECTIONS } from 'views/shipments/slice'

import useUrlParams from 'services/api/hooks/use_url_params'
import { Tvalue } from 'components/select'

import { Rate, TrustedRoute, trustedRouteSchema } from 'views/trusted_routes/types/trusted_route'

import { InternalClient, TrustedRouteClient } from 'services/api/clients'
import onError from 'services/api/error'

import { selectCurrentUser } from 'views/iam/slices/iamSlice'

import { Port } from 'views/trusted_routes/types/port'
import { MapInfo, mapInfoSchema } from 'views/trusted_routes/types/map_info'
import {
  SUBSCRIPTION_FULFILLED,
  SUBSCRIPTION_PENDING,
  SUBSCRIPTION_REJECTED,
  TRUSTED_ROUTES_POOLING_FULFILLED,
  TRUSTED_ROUTES_POOLING_RUNNING,
  TRUSTED_ROUTES_POOLING_TIMEOUT,
  TrustedRoutesStatus,
  UNSTARTED,
} from 'views/trusted_routes/types/status'

import type { RootState } from 'services/store/store'

export const SORT_BY_DEPARTURE_AT = 'departure_at'
export const SORT_BY_ARRIVAL_AT = 'arrival_at'
export const SORT_BY_TRANSIT_TIME = 'transit_time'
export const SORT_BY_RELIABILITY = 'reliability'
export const SORT_BY_SUSTAINABILITY = 'sustainability'
export const SORT_BY_RATE = 'rate'

export const RATE_20_DRY = '20_dry'
export const RATE_40_DRY = '40_dry'
export const RATE_40_HC = '40_hc'

export type RateValue = typeof RATE_20_DRY | typeof RATE_40_DRY | typeof RATE_40_HC
export const RATE_VALUES = [RATE_20_DRY, RATE_40_DRY, RATE_40_HC] as const

export const SORT_BY_VALUES = [
  SORT_BY_DEPARTURE_AT,
  SORT_BY_ARRIVAL_AT,
  SORT_BY_TRANSIT_TIME,
  SORT_BY_RELIABILITY,
  SORT_BY_SUSTAINABILITY,
  SORT_BY_RATE,
] as const

type SortByValue = typeof SORT_BY_VALUES[number]
type Direction = typeof DIRECTIONS[number]

export const fetchPorts = createAsyncThunk<Port[], { value: Tvalue }, { state: RootState }>(
  'trustedRoutes/fetchPorts',
  async ({ value }: { value: Tvalue }, thunkAPI) => {
    const { getState } = thunkAPI
    const url = useUrlParams('/search_ports', { search: value })
    const user = selectCurrentUser(getState())

    return TrustedRouteClient.get(url, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    }).then((r) => r.data)
  }
)

export type SelectValue = { label: string; value: string }

interface ActiveQueryParams {
  departure: SelectValue | null
  arrival: SelectValue | null
  fromDate: string | null
  withRates: boolean
}

interface FetchTrustedRoutesParams {
  departure: string
  arrival: string
  fromDate: string
  withRates: boolean
  weeksAhead?: number
}

function findBestRate(
  rates: Rate[],
  property: 'rate20Dry' | 'rate40Hc' | 'rate40Dry'
): null | Rate {
  const defaultRate: null | Rate = null

  return rates.reduce((acc: null | Rate, rate: Rate) => {
    const value = rate[property]
    const accValue = acc?.[property]
    if (!value) return acc
    if (!accValue) return rate

    return value > accValue ? acc : rate
  }, defaultRate)
}

export const subscribeTrustedRoutes = createAsyncThunk<
  { subscriptionID: string },
  FetchTrustedRoutesParams,
  { state: RootState }
>(
  'trustedRoutes/subscribe',
  async ({ departure, arrival, fromDate, withRates, weeksAhead = 3 }, thunkAPI) => {
    const { getState } = thunkAPI
    const user = selectCurrentUser(getState())
    return TrustedRouteClient.post(
      '/routes/subscribe',
      { departure, arrival, fromDate, withRates, weeksAhead },
      {
        headers: { Authorization: `Bearer ${user.accessToken}` },
      }
    ).then((r) => ({
      subscriptionID: r.data.subscriptionId,
    }))
  }
)

export const fetchTrustedRoutes = createAsyncThunk<
  {
    trustedRoutes: TrustedRoute[]
    status: 'completed' | 'processing'
    missingOnlineRateScacs: string[]
  },
  { subscriptionID: string; signal?: AbortSignal },
  { state: RootState }
>('trustedRoutes/routes', async ({ subscriptionID, signal }, thunkAPI) => {
  const { getState } = thunkAPI
  const user = selectCurrentUser(getState())
  return TrustedRouteClient.get(`/routes/${subscriptionID}`, {
    headers: { Authorization: `Bearer ${user.accessToken}` },
    signal,
  }).then((r) => {
    const trustedRoutes = r.data.routes.map((route: any) => {
      const trustedRoute = trustedRouteSchema.cast(route, { stripUnknown: true })
      if (trustedRoute.rates && trustedRoute.rates.length > 0) {
        trustedRoute.bestRate20Dry = findBestRate(trustedRoute.rates, 'rate20Dry')
        trustedRoute.bestRate40HC = findBestRate(trustedRoute.rates, 'rate40Hc')
        trustedRoute.bestRate40Dry = findBestRate(trustedRoute.rates, 'rate40Dry')
      }
      return trustedRoute
    })
    return {
      trustedRoutes,
      status: r.data.status,
      missingOnlineRateScacs: r.data.rateInformation?.missingOnlineRateScacs || [],
    }
  })
})

export const fetchMapInfo = createAsyncThunk<
  MapInfo & { token: string },
  { token: string },
  { state: RootState }
>('trustedRoutes/routes/mapInfo', async ({ token }, thunkAPI) => {
  const { getState } = thunkAPI
  const user = selectCurrentUser(getState())
  return TrustedRouteClient.get(`/routes/${token}/map-info`, {
    headers: { Authorization: `Bearer ${user.accessToken}` },
  }).then((r) => ({
    token,
    ...mapInfoSchema.cast(r.data, { stripUnknown: true }),
  }))
})

const trustedRoutesAdapter = createEntityAdapter<TrustedRoute>({
  selectId: ({ token }: TrustedRoute) => token,
})

interface TranslateToBookingParams {
  addressPortLocodes: string[]
  vesselImos: number[]
  carrierScacs: string[]
}

export interface VesselTranslationData {
  name: string
  vesselImo: number
}

export interface CarrierTranslationData {
  name: string
  id: number
  scac: string
}

export interface AddressTranslationData {
  name: string
  id: number
  locode: string
  countryCode: string
}

export interface TranslateToBookingResponse {
  addresses: AddressTranslationData[]
  vessels: VesselTranslationData[]
  carriers: CarrierTranslationData[]
}

export const translateTrustedRouteToBooking = createAsyncThunk<
  TranslateToBookingResponse,
  TranslateToBookingParams,
  { state: RootState }
>('trustedRoutes/translator/tr_to_booking', async (params, thunkAPI) =>
  InternalClient.post<TranslateToBookingResponse>('/translator/tr_to_booking', params)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

interface TrustedRoutesInitialState {
  status: TrustedRoutesStatus
  missingOnlineRateScacs: string[]
  subscriptionID: string | null
  activeQueryParams: ActiveQueryParams
  sortBy: SortByValue
  direction: Direction
  displayRateType: RateValue
}

const initialState = trustedRoutesAdapter.getInitialState<TrustedRoutesInitialState>({
  status: UNSTARTED,
  missingOnlineRateScacs: [],
  subscriptionID: null,
  activeQueryParams: {
    departure: null,
    arrival: null,
    fromDate: null,
    withRates: false,
  },
  sortBy: SORT_BY_DEPARTURE_AT,
  direction: DIRECTION_ASCENDING,
  displayRateType: RATE_20_DRY,
})

const trustedRoutesSlice = createSlice({
  name: 'trustedRoutes',
  initialState,
  reducers: {
    startNewSubscription: (state, action: PayloadAction<ActiveQueryParams>) => {
      state.subscriptionID = null
      state.status = SUBSCRIPTION_PENDING
      trustedRoutesAdapter.removeAll(state)
      state.missingOnlineRateScacs = []
      state.activeQueryParams = action.payload
    },
    setStatusTimeout: (state) => {
      state.status = TRUSTED_ROUTES_POOLING_TIMEOUT
    },
    setStatusPoolingRunning: (state) => {
      state.status = TRUSTED_ROUTES_POOLING_RUNNING
    },
    saveDirection: (state, action) => {
      state.direction = action.payload
    },
    saveSortBy: (state, action) => {
      state.sortBy = action.payload
    },
    saveDisplayRateType: (state, action) => {
      state.displayRateType = action.payload
    },
    trustedRouteUpdates: trustedRoutesAdapter.updateMany,
  },
  extraReducers: (builder) => {
    builder.addCase(subscribeTrustedRoutes.fulfilled, (state, action) => {
      state.subscriptionID = action.payload.subscriptionID
      state.status = SUBSCRIPTION_FULFILLED
    })
    builder.addCase(subscribeTrustedRoutes.rejected, (state) => {
      state.status = SUBSCRIPTION_REJECTED
    })
    builder.addCase(fetchTrustedRoutes.fulfilled, (state, action) => {
      if (action.payload.status === 'completed') {
        state.status = TRUSTED_ROUTES_POOLING_FULFILLED
      }
      if (action.payload.missingOnlineRateScacs.length > 0) {
        state.missingOnlineRateScacs = action.payload.missingOnlineRateScacs
      }
      const newTrustedRoutes = action.payload.trustedRoutes.filter(
        (trustedRoute) => !state.ids.includes(trustedRoute.token)
      )
      trustedRoutesAdapter.setMany(state, newTrustedRoutes)
    })
    builder.addCase(fetchMapInfo.fulfilled, (state, action) => {
      const { token, ...payload } = action.payload
      trustedRoutesAdapter.updateOne(state, {
        id: token,
        changes: { mapInfo: payload },
      })
    })
  },
})

export const {
  setStatusTimeout,
  startNewSubscription,
  setStatusPoolingRunning,
  trustedRouteUpdates,
  saveDirection,
  saveSortBy,
  saveDisplayRateType,
} = trustedRoutesSlice.actions
export const { selectAll: selectTrustedRoutes } = trustedRoutesAdapter.getSelectors(
  (state: RootState) => state.trustedRoutes
)
export const selectTrustedRoutesStatus = (state: RootState) => state.trustedRoutes.status
export const selectMissingRateScacs = (state: RootState) =>
  state.trustedRoutes.missingOnlineRateScacs
export const selectSubscriptionID = (state: RootState) => state.trustedRoutes.subscriptionID
export const selectActiveQueryParams = (state: RootState) => state.trustedRoutes.activeQueryParams
export const selectSortBy = (state: RootState) => state.trustedRoutes.sortBy
export const selectDirection = (state: RootState) => state.trustedRoutes.direction
export const selectDisplayRateType = (state: RootState) => state.trustedRoutes.displayRateType

export default trustedRoutesSlice.reducer
