import useFilter, { TDateRange, TTags } from 'services/hooks/use_filter'
import useMultiCheckboxFilter from 'views/trusted_routes/hooks/useMultiCheckboxFilter'
import DateHelper from 'services/helpers/date_helper'
import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import { compactArray, isNull, isPresent } from 'services/helpers/values'
import { SelectValue } from 'components/select'

const numberBetween = (value: number, filterValue: [number, number] | undefined): boolean =>
  !filterValue || (value >= filterValue[0] && value <= filterValue[1])

const dateBetween = (value: string | null, filterValue: TDateRange | null): boolean =>
  !filterValue?.start ||
  !filterValue?.end ||
  (isPresent(value) && new DateHelper(value).isBetweenDates(filterValue.start, filterValue.end))

const isInclude = (value: string, filterValue: TTags | null): boolean =>
  !filterValue || filterValue.includes(value)

const includeSome = (value: string[], filterValue: SelectValue[] | null): boolean => {
  if (isNull(filterValue) || filterValue.length === 0) return true

  return filterValue.some((fv) => value.includes(fv.value as string))
}

const validation =
  <V, F>(
    callback: (value: V, filterValue: F) => boolean,
    extractValue: (trustedRoute: TrustedRoute) => V,
    filterValue: F
  ) =>
  (trustedRoute: TrustedRoute) =>
    callback(extractValue(trustedRoute), filterValue)

interface UseFiltersProps {
  shippingLineNames: string[]
  nbTransshipments: string[]
}

const useFilters = ({ shippingLineNames, nbTransshipments }: UseFiltersProps) => {
  const cutOffDateFilter = useFilter({
    name: 'cutOffDate',
    type: 'dateRange',
  })
  const etdFilter = useFilter({
    name: 'etd',
    type: 'dateRange',
  })
  const etaFilter = useFilter({
    name: 'eta',
    type: 'dateRange',
  })
  const scacFilter = useMultiCheckboxFilter({ name: 'scac', tags: shippingLineNames })
  const transshipmentFilter = useMultiCheckboxFilter({
    name: 'transshipment',
    tags: nbTransshipments,
  })
  const transitTimeFilter = useFilter({
    name: 'transitTime',
    type: 'range',
  })

  const vesselFilter = useFilter({
    name: 'vessel',
    type: 'multiselect',
  })

  const validationFilters = [
    validation(
      numberBetween,
      (trustedRoute: TrustedRoute): number => trustedRoute.transitTime,
      transitTimeFilter.value
    ),
    validation(
      dateBetween,
      (trustedRoute: TrustedRoute): string | null => trustedRoute.cutOffDate,
      cutOffDateFilter.value
    ),
    validation(
      dateBetween,
      (trustedRoute: TrustedRoute): string => trustedRoute.legs[0].departure.date,
      etdFilter.value
    ),
    validation(
      dateBetween,
      (trustedRoute: TrustedRoute): string => trustedRoute.legs.slice(-1)[0].arrival.date,
      etaFilter.value
    ),
    validation(
      isInclude,
      (trustedRoute: TrustedRoute): string => (trustedRoute.legs.length - 1).toString(),
      transshipmentFilter.value
    ),
    validation(
      isInclude,
      (trustedRoute: TrustedRoute): string => trustedRoute.scac,
      scacFilter.value
    ),
    validation(
      includeSome,
      (trustedRoute: TrustedRoute): string[] =>
        compactArray(trustedRoute.legs.map((leg) => leg.vesselName)),
      vesselFilter.value
    ),
  ]

  const isVisible = (trustedRoute: TrustedRoute): boolean =>
    validationFilters.every((validationFilter) => validationFilter(trustedRoute))

  return {
    filters: {
      cutOffDateFilter,
      etdFilter,
      etaFilter,
      scacFilter,
      transshipmentFilter,
      transitTimeFilter,
      vesselFilter,
    },
    isVisible,
  }
}

export default useFilters
