import React, { FC, PropsWithChildren, ReactNode } from 'react'

import S from 'components/sidebar/style'

interface SidebarComp {
  Header: typeof Header
  Footer: typeof Footer
  Content: typeof Content
  Title: typeof Title
}
const Sidebar: FC<PropsWithChildren<ReactNode>> & SidebarComp = ({ children }) => (
  <S.Sidebar>{children}</S.Sidebar>
)
const Header: FC = ({ children }) => <S.Header>{children}</S.Header>
const Content: FC = ({ children }) => <S.Content>{children}</S.Content>
const Footer: FC = ({ children }) => <S.Footer>{children}</S.Footer>
const Title: FC = ({ children }) => <S.Title>{children}</S.Title>
Sidebar.Header = Header
Sidebar.Content = Content
Sidebar.Footer = Footer
Sidebar.Title = Title

export default Sidebar
