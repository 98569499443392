import { InferType, object, string } from 'yup'

import { patternEmail } from 'services/helpers/form_validation'

export const loginSchema = object({
  email: string()
    .required('iam.signIn.form.validation.required')
    .matches(patternEmail, { message: 'iam.signIn.form.validation.email' }),
  password: string().required('iam.signIn.form.validation.required'),
}).required()

export type LoginData = InferType<typeof loginSchema>
