import React, { useState, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { isPresent } from 'services/helpers/values'

import Select, { OnChangeFnProps } from 'components/select'
import { IFilter } from 'services/hooks/use_filter'
import useCurrentCompany from 'views/iam/hooks/use_current_company'
import BlockContainer, { BlockContext } from 'components/block_container'
import {
  StyledInputCustomRef,
  StyledSelectCustomRef,
  StyledCustomRefWrapper,
} from 'views/booking/components/form/style'
import { BookingCustomReference } from 'views/booking/slices/types'
import {
  getTestIdForCustomReferenceName,
  getTestIdForCustomReferenceReference,
} from 'tests/e2e/test_ids'

interface CustomReferencesProps {
  customReferencesFilter: IFilter<'custom'>
}

const CustomReferences: FC<CustomReferencesProps> = ({ customReferencesFilter }) => {
  const [addedCustomReferences, setAddedCustomReferences] = useState<string[]>([])

  const { company } = useCurrentCompany()
  const { customReferences } = company
  const { t } = useTranslation()

  useEffect(() => {
    const blocksWithName = customReferencesFilter.value.filter(({ key }: BookingCustomReference) =>
      isPresent(key)
    )

    if (blocksWithName.length !== addedCustomReferences.length) {
      setAddedCustomReferences(blocksWithName.map(({ key }: BookingCustomReference) => key))
    }
  }, [customReferencesFilter.value, addedCustomReferences.length])

  const onRemoveCustomRef = (index: number) => {
    // TODO: REFACTOR: find a way to define state type upper in parent
    // for custom useFilers so that we don't have to define it every time
    customReferencesFilter.onChange((state: Array<BookingCustomReference>) => {
      const customRefToRemove = state[index].key

      state.splice(index, 1)

      const newArray = addedCustomReferences.filter((c) => c !== customRefToRemove)

      setAddedCustomReferences(newArray)
    })
  }

  const onAddBlock = (index: number) => {
    customReferencesFilter.onChange((state: Array<BookingCustomReference>) => {
      state[index] = {} as BookingCustomReference
    })
  }

  return (
    <BlockContainer
      onAdd={onAddBlock}
      onRemove={onRemoveCustomRef}
      title={t('bookings.references.customReference', { count: 1 })}
      itemGender='female'
      columns={3}
      size={1}
      btnSize={1}
      initialBlocksCount={customReferencesFilter.value.length}
      maxBlocks={customReferences.length}
      disabled={customReferencesFilter.isDisabled}
    >
      <BlockContext.Consumer>
        {({ index }) => (
          <StyledCustomRefWrapper>
            <StyledSelectCustomRef
              as={Select}
              value={
                isPresent(customReferencesFilter.value[index]?.key)
                  ? {
                      label: customReferencesFilter.value[index]?.key,
                      value: customReferencesFilter.value[index]?.name,
                    }
                  : null
              }
              placeholder='Select'
              options={customReferences
                .filter(
                  (r: string) => !addedCustomReferences.map((customRef) => customRef).includes(r)
                )
                .map((c: string) => ({
                  value: c,
                  label: c,
                }))}
              onChange={({ value }: OnChangeFnProps) => {
                customReferencesFilter.onChange((state: Array<BookingCustomReference>) => {
                  if (!state[index]) {
                    state[index] = {} as BookingCustomReference
                  }

                  const customRefToRemove = state[index].key

                  state[index].key = value.value as string

                  const newArray = [
                    ...addedCustomReferences.filter((c) => c !== customRefToRemove),
                    `${value.value}`,
                  ]

                  setAddedCustomReferences(newArray)
                })
              }}
              required
              isDisabled={customReferencesFilter.isDisabled}
              testId={getTestIdForCustomReferenceName(index)}
            />

            <StyledInputCustomRef
              placeholder={t('bookings.references.reference')}
              name={customReferencesFilter.name}
              value={customReferencesFilter.value[index]?.value}
              onChange={({ target: { value } }) => {
                customReferencesFilter.onChange((state: any) => {
                  if (!state[index]) state[index] = {}
                  state[index].value = value
                })
              }}
              disabled={customReferencesFilter.isDisabled}
              testId={getTestIdForCustomReferenceReference(index)}
            />
          </StyledCustomRefWrapper>
        )}
      </BlockContext.Consumer>
    </BlockContainer>
  )
}

export default CustomReferences
