import React, { useState } from 'react'

import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  StyledLoginForm,
  StyledLoginFormInput,
  StyledLoginFormButton,
  StyledLoginFormText,
  StyledLoginFormTextLink,
  StyledLoginFormTextWrapper,
  StyledLoginFormTextDot,
  StyledLoginFormFormTitle,
  StyledLoginFormError,
} from 'components/login_form/style'
import { signIn } from 'views/iam/slices/iamSlice'
import { routePasswordRecovery } from 'services/helpers/routes'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { LoginData, loginSchema } from 'components/login_form/types'
import { TEST_ID_SIGN_IN_BUTTON, TEST_ID_FORGOTTEN_PASSWORD } from 'tests/e2e/test_ids'

interface LoginFormData {
  email: string
  password: string
}

const LoginForm = () => {
  const [apiError, setApiError] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const submit = (data: LoginData): void => {
    dispatch(
      signIn({
        email: data.email,
        password: data.password,
      })
    )
      .unwrap()
      .catch(({ message }) => setApiError(message))
  }

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    defaultValues: { email: '', password: '' },
  })

  return (
    <StyledLoginForm onSubmit={handleSubmit(submit)} noValidate>
      <StyledLoginFormFormTitle>{t('iam.signIn.title')}</StyledLoginFormFormTitle>
      <StyledLoginFormInput
        label={t('iam.signIn.form.email')}
        type='text'
        id='email'
        required
        autoComplete='email'
        error={errors.email?.message ? t(errors.email?.message) : undefined}
        {...register('email')}
      />
      <StyledLoginFormInput
        label={t('iam.signIn.form.password')}
        type='password'
        id='password'
        required
        autoComplete='current-password'
        error={errors.password?.message ? t(errors.password?.message) : undefined}
        {...register('password')}
      />
      <StyledLoginFormError>{apiError}</StyledLoginFormError>

      <StyledLoginFormButton
        text={t('iam.signIn.form.login')}
        variant='highlight'
        type='submit'
        testId={TEST_ID_SIGN_IN_BUTTON}
      />
      <StyledLoginFormTextWrapper>
        <StyledLoginFormText>
          {t('iam.signIn.form.footer.needSomething')}{' '}
          <StyledLoginFormTextLink
            as={Link}
            to={{ pathname: 'https://wakeo.co/contact' }}
            target='_blank'
          >
            {t('iam.signIn.form.footer.contactUsLink')}
          </StyledLoginFormTextLink>
        </StyledLoginFormText>
        <StyledLoginFormTextDot />
        <StyledLoginFormText>
          <StyledLoginFormTextLink
            as={Link}
            to={routePasswordRecovery()}
            data-testid={TEST_ID_FORGOTTEN_PASSWORD}
          >
            {t('iam.signIn.form.footer.forgotPasswordLink')}
          </StyledLoginFormTextLink>
        </StyledLoginFormText>
      </StyledLoginFormTextWrapper>
    </StyledLoginForm>
  )
}

export default LoginForm
