import type { Category } from 'services/hooks/use_category'
import type { TFilterValues } from 'services/hooks/use_filter'

export interface Tag {
  onDelete: () => void
  value: TFilterValues
}
interface UseTagsValues {
  categories: Category[]
}

interface UseTags {
  tags: Tag[]
}
const useTags = ({ categories }: UseTagsValues): UseTags => {
  const tags = categories.map((c) => c.tags).flat()

  return {
    tags,
  }
}
export default useTags
