import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import iconsMapping from 'services/helpers/icons_mapping'
import DateHelper from 'services/helpers/date_helper'

import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'

import {
  STEP_STATUSES,
  STEP_STATUS_INACTIVE,
  SHIPMENT_VIEW_LARGE,
  FACILITY_TYPES,
  VEHICLE_TYPES,
} from 'constants/shipments'

import Icon from 'components/icon'
import Flag from 'components/flag'
import {
  StyledStepHead,
  StyledStep,
  StyledStepName,
  StyledStepTitle,
  StyledStepFlag,
  StyledStepIcon,
  StyledStepTime,
  StyledStepProgress,
  StyledStepInner,
} from 'components/shipment_timeline/style'

const ShipmentTimelineStep = ({
  locationName,
  locationCode,
  inTransit,
  status,
  facilityType,
  currentVehicleType,
  estimatedTime,
  actualTime,
}) => {
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view !== SHIPMENT_VIEW_LARGE, [view])
  return (
    <StyledStep $status={status} $inTransit={inTransit} $condensed={isCondensed}>
      <StyledStepInner>
        <StyledStepIcon>
          {facilityType && !isCondensed && <Icon name={iconsMapping(facilityType, 'timeline')} />}
        </StyledStepIcon>
        {!isCondensed && (
          <StyledStepHead>
            <StyledStepTitle>
              <StyledStepName>{locationName}</StyledStepName>
              {locationCode && <StyledStepFlag as={Flag} countryCode={locationCode} />}
            </StyledStepTitle>
            <StyledStepTime>
              {(actualTime || estimatedTime) &&
                new DateHelper(actualTime || estimatedTime).toLocale()}
            </StyledStepTime>
          </StyledStepHead>
        )}
        <StyledStepProgress>
          {currentVehicleType && (
            <Icon
              name={!isCondensed ? iconsMapping(currentVehicleType, 'transportation') : 'arrow'}
            />
          )}
        </StyledStepProgress>
      </StyledStepInner>
    </StyledStep>
  )
}

ShipmentTimelineStep.propTypes = {
  /** Location name */
  locationName: PropTypes.string,
  /** Country code */
  locationCode: PropTypes.string,
  /** Status */
  status: PropTypes.oneOf(STEP_STATUSES),
  /** In transit */
  inTransit: PropTypes.bool,
  /** Current step facility type */
  facilityType: PropTypes.oneOf(FACILITY_TYPES),
  /** Estimated time */
  estimatedTime: PropTypes.string,
  /** Actual time */
  actualTime: PropTypes.string,
  /** Current vehicle type */
  currentVehicleType: PropTypes.oneOf(VEHICLE_TYPES),
}

ShipmentTimelineStep.defaultProps = {
  locationName: undefined,
  locationCode: undefined,
  status: STEP_STATUS_INACTIVE,
  inTransit: false,
  facilityType: undefined,
  estimatedTime: undefined,
  actualTime: undefined,
  currentVehicleType: undefined,
}

export default ShipmentTimelineStep
