import React, { FC, memo, ReactNode, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Header from 'components/header'
import PoweredBy from 'components/powered_by'
import S from 'components/page/style'
import SharedHeader from 'components/shared_header'
import Button from 'components/button'

import AppContext from 'app/contexts/app_context'

import { isPresent } from 'services/helpers/values'

import { TEST_ID_SIDE_BAR_BUTTON, TEST_ID_SIDE_BAR_CLOSE_BUTTON } from 'tests/e2e/test_ids'

export interface PageProps {
  children: ReactNode
  sidebar?: ReactNode
  plain?: boolean
  className?: string
}
const Page: FC<PageProps> = ({ children, sidebar, plain = false, className }) => {
  const { isStandalone, isShared, isEmbedded } = useContext(AppContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const withSidebar = isPresent(sidebar)
  const { t } = useTranslation()
  return (
    <S.Page className={className}>
      {isStandalone && !isEmbedded && <Header />}
      {isShared && <SharedHeader />}
      <S.PageMain $withHeader={!isEmbedded} $withPoweredBy={isEmbedded}>
        {withSidebar && (
          <S.PageSidebar $open={sidebarOpen}>
            <S.PageSidebarCloseCta
              testId={TEST_ID_SIDE_BAR_CLOSE_BUTTON}
              as={Button}
              text={t('actions.close')}
              icon='close'
              variant='transparent'
              onClick={() => setSidebarOpen(false)}
            />
            {sidebar}
          </S.PageSidebar>
        )}
        <S.PageContent $plain={plain}>
          {withSidebar && (
            <S.PageSidebarOpenCta
              as={Button}
              onClick={() => setSidebarOpen(true)}
              icon='settings'
              rounded
              testId={TEST_ID_SIDE_BAR_BUTTON}
            />
          )}
          {children}
        </S.PageContent>
      </S.PageMain>
      {isEmbedded && <PoweredBy />}
    </S.Page>
  )
}

export default memo(Page)
