import DateHelper from 'services/helpers/date_helper'

interface TimeDifference {
  diff: string | null
  isNegative: boolean | null
}

const useDiffTime = (time: Date, timeTo: Date): TimeDifference => {
  const date = new DateHelper(time)
  const dateTo = new DateHelper(timeTo)
  const dayMeasure = 'day'
  const hoursMeasure = 'hours'
  const minuteMeasure = 'minutes'
  const dateDiffValue = date.diff(timeTo, dayMeasure)
  const dateOnlyDiffValue = new DateHelper(date.toDateOnly()).diff(dateTo.toDateOnly(), dayMeasure)
  const hoursDiffValue = date.diff(timeTo, hoursMeasure)
  const minutesDiffValue = date.diff(timeTo, minuteMeasure)
  const isDaysDiffValue = Math.abs(hoursDiffValue) >= 24
  const bothDatesHaveHours = date.hasHours() && dateTo.hasHours()
  let diffValue
  let measure

  // Here we have 3 different cases
  // 1. Difference is more than 24 hours => display diff in days
  // 2. Difference is less than 24 hours and both dates have hours => display diff in hours
  // 3. Difference is less than 24 hours and on of the dates is missing hours => display diff in days without looking at hours
  if (isDaysDiffValue) {
    diffValue = dateDiffValue
    measure = dayMeasure
  } else if (bothDatesHaveHours) {
    const diffDecimalValue = minutesDiffValue / 60
    const hoursDiff = Math.floor(diffDecimalValue)
    const minutesDiff = (diffDecimalValue - hoursDiff) * 60

    diffValue = minutesDiff > 30 ? hoursDiff + 1 : hoursDiff
    measure = hoursMeasure
  } else {
    diffValue = dateOnlyDiffValue
    measure = dayMeasure
  }

  if (diffValue === 0) {
    return { diff: null, isNegative: null }
  }

  const isNegative = diffValue < 0

  const diff = `${isNegative ? '' : '+'}${diffValue}${measure.split('')[0]}`

  return { diff, isNegative }
}

export default useDiffTime
