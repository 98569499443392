import React, { FC } from 'react'

import { StyledSwitchIcon, StyledText, StyledViewSwitcher } from 'components/view_switcher/style'
import { ViewSwitcherVariant } from 'components/view_switcher/types'

export interface ViewSwitcherProps {
  label: string
  onSwitch: () => void
  variant: ViewSwitcherVariant
}

const ViewSwitcher: FC<ViewSwitcherProps> = ({ label, onSwitch, variant }) => (
  <StyledViewSwitcher onClick={onSwitch} $variant={variant}>
    <StyledText>{label}</StyledText>
    <StyledSwitchIcon />
  </StyledViewSwitcher>
)

export default ViewSwitcher
