import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import DateHelper from 'services/helpers/date_helper'

import type { RootState } from 'services/store/store'
import type { FilterTemplate } from 'views/bookings/types/filter_template'
import type { Status } from 'constants/api'

export const fetchFilterTemplates = createAsyncThunk<FilterTemplate[], void, { state: RootState }>(
  'bookingFilterTemplates/fetchFilterTemplates',
  async (_, thunkAPI) =>
    InternalClient.get('/bookings/filter_templates')
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const createFilterTemplate = createAsyncThunk<
  FilterTemplate,
  Omit<FilterTemplate, 'createdAt' | 'id'>,
  { state: RootState }
>('bookingFilterTemplates/createFilterTemplate', async (filterTemplate, thunkAPI) =>
  InternalClient.post('/bookings/filter_templates', filterTemplate)
    .then((r) => r.data)
    .catch(onError(thunkAPI))
)

export const deleteFilterTemplate = createAsyncThunk<
  string | number,
  string | number,
  { state: RootState }
>('bookingFilterTemplates/deleteFilterTemplate', async (id, thunkAPI) =>
  InternalClient.delete(`/bookings/filter_templates/${id}`)
    .then((r) => r.data)
    .then(() => id)
    .catch(onError(thunkAPI))
)

const sortByCreatedAtDesc = (a: FilterTemplate, b: FilterTemplate) =>
  new DateHelper(a.createdAt).isBefore(b.createdAt) ? 1 : -1
const bookingFilterTemplatesAdapter = createEntityAdapter<FilterTemplate>({
  sortComparer: sortByCreatedAtDesc,
})
const initialState = bookingFilterTemplatesAdapter.getInitialState<{
  status: Status
}>({
  status: STATUS_PENDING,
})

const bookingFilterTemplatesSlice = createSlice({
  name: 'bookingFilterTemplates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFilterTemplates.fulfilled, (state, action) => {
      bookingFilterTemplatesAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchFilterTemplates.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchFilterTemplates.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(deleteFilterTemplate.fulfilled, bookingFilterTemplatesAdapter.removeOne)
    builder.addCase(createFilterTemplate.fulfilled, bookingFilterTemplatesAdapter.addOne)
  },
})
export const { selectAll: selectBookingFilters } = bookingFilterTemplatesAdapter.getSelectors(
  (state: RootState) => state.bookingFilterTemplates
)
export const selectbookingFiltersStatus = createSelector(
  (state: RootState) => state.bookingFilterTemplates.status,
  (status) => status
)
export default bookingFilterTemplatesSlice.reducer
