import { fetchRoutingFilters } from 'views/shipments/slice'
import { SelectValue } from 'components/select'

// TODO: REFACTOR:
// Naming:
// @queryParams - The extra query params sent to fetchFilters request. Should be an object.
const useRoutingSelect = ({ queryParams }: any = { queryParams: {} }) => {
  const removeActiveFilter = (name: string) => {
    const anySuffixRegex = '_[a-zA-Z]+'
    const nameRegex = new RegExp(`^routing(_country)?_${name}${anySuffixRegex}$`)
    return Object.keys(queryParams)
      .filter((k) => !nameRegex.test(k))
      .reduce((acc: any, key: any) => {
        acc[key] = queryParams[key]
        return acc
      }, {})
  }

  const fetchRoutingOptions =
    (suffix: string) =>
    ({ value }: { value: any }) => {
      const filterName = `${suffix}_match`
      return fetchRoutingFilters({
        value,
        suffix: filterName,
        filters: removeActiveFilter(suffix),
      })
    }
  const fetchAllRoutingOptions = (suffix: string) => () => {
    const filterName = `${suffix}_all`
    return fetchRoutingFilters({
      value: null,
      suffix: filterName,
      filters: removeActiveFilter(suffix),
    })
  }
  const fetchedRoutingOptionsFormat = (value: SelectValue[] | null | undefined) => value
  const toIndexedSelectRoutingValue = ({ value }: { value: SelectValue[] | null | undefined }) =>
    value

  return {
    fetchRoutingOptions,
    fetchAllRoutingOptions,
    toIndexedSelectRoutingValue,
    fetchedRoutingOptionsFormat,
  }
}

export default useRoutingSelect
