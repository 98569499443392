import { useDispatch } from 'react-redux'

import useActiveValues from 'views/bookings/hooks/use_active_values'

import { removeAll, saveBookingsActiveFilters } from 'views/bookings/slice'

const useActiveFilters = ({ categories }) => {
  const dispatch = useDispatch()
  const { saveActiveValues } = useActiveValues({ categories })

  const activeFilters = categories.reduce((acc, c) => {
    Object.assign(acc, c.queryParams)
    return acc
  }, {})

  const save = () => {
    dispatch(removeAll())
    dispatch(saveBookingsActiveFilters({ activeFilters }))
    saveActiveValues()
  }

  return {
    save,
  }
}

export default useActiveFilters
