import React from 'react'
import PropTypes from 'prop-types'

import { anyChildren } from 'services/helpers/prop_types'

import {
  StyledBoxShadow,
  StyledBoxShadowInner,
  StyledBoxShadowTitle,
} from 'components/box_shadow/style'

const BoxShadow = ({
  children,
  className,
  title,
}: {
  children?: React.ReactNode
  className?: string
  title?: string
}) => {
  const hasTitle = typeof title === 'string' && title.length > 0

  return (
    <StyledBoxShadow className={className} $hasTitle={hasTitle}>
      {hasTitle && <StyledBoxShadowTitle>{title}</StyledBoxShadowTitle>}
      <StyledBoxShadowInner>{children}</StyledBoxShadowInner>
    </StyledBoxShadow>
  )
}

BoxShadow.propTypes = {
  children: anyChildren,
  className: PropTypes.string,
  title: PropTypes.string,
}

BoxShadow.defaultProps = {
  children: undefined,
  className: undefined,
  title: null,
}

export default BoxShadow
