import styled, { css } from 'styled-components'

import { desktop, desktopLarge, tablet } from 'styles/utils/breakpoints'
import { text14Regular } from 'styles/utils/texts'

import { easeQuartOut } from 'styles/utils/ease'
import StyledIcon from 'components/icon/style'
import { StyledSelectLabel } from 'components/select/style'
import { StyledSwitchInputOption, StyledSwitchInput } from 'components/switch_input/style'
import { PageContentMaxWidth } from 'components/page/style'

export const StyledPageBookingListMain = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledPageBookingListBarIcons = styled.div`
  display: flex;
  align-items: center;
`

// Bar

export const StyledPageBookingListBar = styled.div`
  position: relative;
  z-index: 2;
  padding-left: 0;
  padding-right: 20px;

  @media ${desktop.up} {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media ${desktopLarge.up} {
    padding-right: 60;
    display: flex;
    justify-content: space-between;
  }
`

export const StyledPageBookingListExport = styled.button`
  @media ${tablet.down} {
    padding: 0;
  }
`

export const StyledPageBookingListBarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  &:first-child {
    z-index: 2;
  }
  &:last-child {
    z-index: 1;
    justify-content: space-between;
  }

  @media ${tablet.down} {
    flex-wrap: wrap;
  }
`

export const StyledPageBookingListSortButton = styled.button`
  margin-right: 12px;

  ${StyledIcon} {
    height: 23px;
    width: 25px;
    fill: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut};
  }

  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }
`

export const StyledPageBookingListViewButton = styled.button`
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${StyledIcon} {
    flex: none;
    height: 20px;
    width: 20px;
    fill: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut};
  }

  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }

  ${(props) =>
    props.$active &&
    css`
      ${StyledIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
`

export const StyledPageBookingListView = styled.div`
  display: flex;

  ${StyledPageBookingListViewButton} {
    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.mediumGray50};
    }
  }
`

export const StyledPageBookingListSwitch = styled(StyledSwitchInput)`
  margin: 0 15px;

  ${StyledSwitchInputOption} {
    ${text14Regular}
  }

  @media ${desktop.up} {
    margin: 0 20px;
  }
`

export const StyledPageBookingHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${desktopLarge.up} {
    max-width: ${PageContentMaxWidth};
  }

  @media ${tablet.down} {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 30px;
  }
`

export const StyledBookingsBarTotalCount = styled.div`
  display: flex;
  align-items: center;
  ${text14Regular};
`

export const StyledPageBookingHeaderRight = styled.div`
  display: flex;
  align-items: center;
  @media ${tablet.down} {
    width: 100%;
    justify-content: space-between;
  }
`

export const StyledPageBookingListSelect = styled.div`
  display: flex;
  align-items: center;
  z-index: 20;
  position: relative;
  white-space: nowrap;

  ${StyledSelectLabel} {
    ${text14Regular}
    margin: 0;
  }
`
export const StyledBookingListWrapper = styled.div`
  @media ${desktopLarge.up} {
    max-width: ${PageContentMaxWidth};
  }
  overflow-y: auto;
  height: 100%;
`
export const StyledBookingsList = styled.div`
  @media ${tablet.up} {
    padding: 0 20px;
  }
  @media ${desktop.up} {
    padding: 0 40px;
  }
  @media ${desktopLarge.up} {
    padding: 0 60px;
  }
`
