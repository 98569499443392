import styled, { css } from 'styled-components'

import { StyledInput } from 'components/input/style'
import { StyledItem } from 'components/notif_message/style'
import { StyledDatePicker } from 'components/input_datepicker/style'

import { text14Regular24, tagSmall } from 'styles/utils/texts'
import { tablet } from 'styles/utils/breakpoints'
import { borderRadiusMedium } from 'styles/utils/border_radius'

import { Any } from 'services/helpers/types'

export const StyledBookingCreate = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StyledBookingCreateList = styled.div`
  overflow: auto;
`

export const StyledGroupInsert = styled.div<Any>`
  ${borderRadiusMedium}
  border: 1px solid ${({ theme }) => theme.lightGray};
  padding: 35px 35px 15px 35px;
  position: relative;
  margin-top: 35px;
  margin-bottom: 30px;
  @media ${tablet.down} {
    margin-top: 10px;
    padding: 0;
    border: none;
  }

  ${StyledItem} {
    width: 100%;
  }
  ${StyledDatePicker} {
    width: 100%;
  }
  ${(props) =>
    props.$row &&
    css`
      display: flex;
      flex-direction: row;
      ${StyledInput} {
        margin-right: 20px;
      }
    `}
`

export const StyledGroupInsertHead = styled.div`
  position: absolute;
  left: 25px;
  top: 0px;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.white};
  padding: 0 10px;

  @media ${tablet.down} {
    display: none;
  }
`
export const StyledFormInsertTitle = styled.p`
  ${tagSmall};
  color: ${({ theme }) => theme.textLight};
  margin-left: 15px;
`

export const StyledFormGroupTitle = styled.p`
  ${text14Regular24}
  margin-bottom:10px;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
    bottom: 10px;
    margin-left: 15px;
  }
`

export const StyledFormFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  border-top: 1px solid ${({ theme }) => theme.lightGray};
  padding: 25px 0;
  @media ${tablet.down} {
    margin-top: 0px;
  }
`
