import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit'

import { LastEvent } from 'components/booking_last_event/types'

import { STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'

import type { RootState } from 'services/store/store'

export const fetchBookingLastEvents = createAsyncThunk(
  'booking/fetchBookingLastEvents',
  async (token, thunkAPI) =>
    InternalClient.get(`/bookings/${token}/last_events`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

const lastEventsAdapter = createEntityAdapter<LastEvent>()

const initialState = lastEventsAdapter.getInitialState({
  status: STATUS_PENDING,
})

const lastEventsSlice = createSlice({
  name: 'lastEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookingLastEvents.fulfilled, (state, action) => {
      lastEventsAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchBookingLastEvents.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchBookingLastEvents.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const { selectAll: selectLastEvents } = lastEventsAdapter.getSelectors(
  (state: RootState) => state.booking.lastEvents
)

export const selectBookingLastEventsStatus = (state: RootState) => state.booking.lastEvents.status

export default lastEventsSlice.reducer
