import _ from 'lodash'
import { useTranslation } from 'react-i18next'

type TPath = string[] | string
type TS = (path: TPath) => Record<string, any>
interface IStaticLocale {
  s: TS
  // TODO: SelectValue instead of any
  fromStaticToSelectOptions: (path: string) => { value: string; label: string }[]
}
const useStaticLocales = (): IStaticLocale => {
  const { t } = useTranslation()
  const s: TS = (path) => _.get(t('static', { returnObjects: true }), path, {})

  // TODO: use this method in booking form.
  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))
  return { s, fromStaticToSelectOptions }
}

export default useStaticLocales
