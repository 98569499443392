import { CommercialValue } from 'types/bookings'

import { CURRENCY_USD } from 'constants/bookings'

import { isPresent } from 'services/helpers/values'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

const useMerchandiseInformationDisplay = () => {
  const { s } = useStaticLocales()
  const emptyDisplay = '-'

  const currencyDisplay = (currencyCode: string | undefined): string =>
    currencyCode === CURRENCY_USD ? '$' : '€'

  const hazardousGoodsDisplay = (
    hazardousGoods:
      | {
          hazardousClass: number
          packingGroup: number
          weight?: {
            value: number
            unit: string
          }
          unNumber: string
        }
      | undefined
  ): string => {
    let display = ''

    if (isPresent(hazardousGoods?.hazardousClass) && hazardousGoods) {
      display += s('hazardousGoods')[hazardousGoods.hazardousClass]
    }

    if (hazardousGoods?.unNumber) {
      display += display ? ` | ${hazardousGoods.unNumber}` : hazardousGoods.unNumber
    }

    if (hazardousGoods?.weight?.value) {
      display += display
        ? ` | ${hazardousGoods.weight.value.toLocaleString()} ${hazardousGoods.weight.unit}`
        : `${hazardousGoods.weight.value.toLocaleString()} ${hazardousGoods.weight.unit}`
    }

    if (hazardousGoods?.packingGroup) {
      display += display
        ? ` | ${s('packingGroups')[hazardousGoods.packingGroup]}`
        : s('packingGroups')[hazardousGoods.packingGroup]
    }

    return display.length > 0 ? display : emptyDisplay
  }

  const temperatureDisplay = (temperature: number | undefined, indicator: string) =>
    temperature ? `${indicator}: ${`${temperature}°`}` : `${indicator}: ${emptyDisplay}`

  const commercialValueDisplay = (commercialValue: CommercialValue | undefined) =>
    commercialValue?.amount
      ? `${currencyDisplay(commercialValue?.currencyCode)} ${commercialValue?.amount}`
      : emptyDisplay

  return {
    currencyDisplay,
    hazardousGoodsDisplay,
    temperatureDisplay,
    commercialValueDisplay,
    emptyDisplay,
  }
}

export default useMerchandiseInformationDisplay
