import { createContext } from 'react'

import { CoordinateModel } from 'components/map/models'

interface TrackerContextValues {
  isInit: boolean
  setIsInit: (isInit: boolean) => void
  userCoordinate?: CoordinateModel
}
const TrackerContext = createContext<TrackerContextValues>({
  isInit: false,
  setIsInit: () => {},
  userCoordinate: undefined,
})

export default TrackerContext
