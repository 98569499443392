import { Dispatch, createContext } from 'react'

interface AuditTrailFiltersContextValues {
  id: string
  setFilters: Dispatch<Record<string, unknown>>
}

const AuditTrailFiltersContext = createContext<AuditTrailFiltersContextValues>({
  id: '',
  setFilters: () => {},
})

export default AuditTrailFiltersContext
