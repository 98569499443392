import styled from 'styled-components'

import { StyledSelect } from 'components/select/style'
import { tablet } from 'styles/utils/breakpoints'

export const StyledPanelsWrapper = styled.div`
  @media ${tablet.up} {
    overflow: auto;
  }
`

export const StyledSelectWrapper = styled(StyledSelect)`
  margin-bottom: 25px;
`
