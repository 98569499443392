import styled from 'styled-components'

import SearchSelect from 'components/search_select'

export const StyledBlocksContainer = styled.div`
  border-radius: 25px;
  border: 10px solid ${({ theme }) => theme.softGrayLight};
  padding: 5px;
  background-color: ${({ theme }) => theme.white};
  width: 100%;
`

export const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 20px;
`

export const StyledBlockHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const StyledMenuControl = styled.div`
  padding: 20px 0 0 20px;
  gap: 15px;
  display: flex;
`

export const StyledArrowWrapper = styled.div`
  position: relative;
  bottom: 3px;
`

export const StyledSelectItem = styled(SearchSelect)`
  width: 100%;
`
