import React from 'react'
import { useParams } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import StatusBookingDetails from 'views/booking/components/status'
import InformationBookingShow from 'views/booking/components/information'
import BookingDocuments from 'views/booking/components/documents'
import BookingLastEvents from 'views/booking/components/last_events'
import Tabs, { Tab } from 'components/tabs'

import {
  StyledFormFooter,
  StyledPageBookingDetailBoxShadow,
  StyledPageBookingDetailInner,
} from 'views/booking/style'
import { StyledBookingDetailEdit } from 'views/booking/components/show/style'
import useBookingWorkflow from 'views/booking/hooks/use_booking_workflow'
import BookingActionButtons from 'views/booking/components/action_buttons'
import { TEST_ID_BOOKING_SHOW_DOCUMENTS_TAB } from 'tests/e2e/test_ids'

const BookingShow = () => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { noActionRequired } = useBookingWorkflow(id)

  return (
    <StyledPageBookingDetailBoxShadow>
      <StyledPageBookingDetailInner>
        {/* Status  */}
        {!noActionRequired && <StatusBookingDetails />}

        {/* Tabs */}
        <StyledBookingDetailEdit as={Tabs}>
          <Tab id='informations' label={t('bookings.information', { context: 'plural' })} key={0}>
            <InformationBookingShow />
          </Tab>

          <Tab
            testId={TEST_ID_BOOKING_SHOW_DOCUMENTS_TAB}
            id='documents'
            label={t('bookings.documents')}
            key={1}
          >
            <BookingDocuments />
          </Tab>

          <Tab id='events' label={t('bookings.lastEvents.title')} key={2}>
            <BookingLastEvents />
          </Tab>
        </StyledBookingDetailEdit>
      </StyledPageBookingDetailInner>

      {/* Footer buttons */}
      <StyledFormFooter>
        <BookingActionButtons />
      </StyledFormFooter>
    </StyledPageBookingDetailBoxShadow>
  )
}

export default BookingShow
