import React, { FC } from 'react'

import useComputedShipmentOrganization from 'components/shipment_list_item/hooks/use_computed_shipment_organization'
import useComputedUserReferences from 'components/shipment_list_item/hooks/use_computed_user_references'

import useReferences from 'components/shipment_list_item/hooks/use_references'

const CondensedViewAttributes: FC = () => {
  const references = [useComputedUserReferences(), useComputedShipmentOrganization()].flat()

  const { References: RefsComponent } = useReferences({ references })
  return <RefsComponent />
}
export default CondensedViewAttributes
