import React, { FC } from 'react'

import S from 'components/table/style'

interface TableComp {
  Head: typeof Head
  Body: typeof Body
  Row: typeof Row
  Cell: typeof Cell
  HeadCell: typeof HeadCell
}

interface BodyProps {
  shadowVariant?: 'normal' | 'medium' | 'strong'
}

interface RowProps {
  odd?: boolean
  alignedTop?: boolean // eslint-disable-line react/no-unused-prop-types
}

interface CellProps {
  small?: boolean
  fitContent?: boolean
}

// eslint-disable-next-line react/prop-types
const Table: FC & TableComp = ({ children }) => <>{children}</>
const Head: FC = ({ children }) => <S.Head>{children}</S.Head>
const Body: FC<BodyProps> = ({ shadowVariant, children }) => (
  <S.Body $shadowVariant={shadowVariant ?? 'medium'}>{children}</S.Body>
)
const Row: FC<RowProps> = ({ odd, children, alignedTop }) => (
  <S.Row $odd={odd} $alignedTop={alignedTop}>
    {children}
  </S.Row>
)
const Cell: FC<CellProps> = ({ small, fitContent, children }) => (
  <S.Cell $small={small} $fitContent={fitContent}>
    {children}
  </S.Cell>
)
const HeadCell: FC<CellProps> = ({ small, fitContent, children }) => (
  <S.HeadCell $small={small} $fitContent={fitContent}>
    {children}
  </S.HeadCell>
)

Table.Head = Head
Table.Body = Body
Table.Row = Row
Table.Cell = Cell
Table.HeadCell = HeadCell

export default Table
