import { Coordinates, NullableValues } from 'types/common'

import { ModalPosition } from 'components/modal/types/types'

const isInstanceOfCoordinates = (object: ModalPosition): object is Coordinates =>
  typeof object === 'object' &&
  object &&
  typeof object.x === 'number' &&
  typeof object.y === 'number'

const buildInWindowCoordinates = (position: ModalPosition, contentRef: HTMLDivElement | null) => {
  const isPositionByCoordinates = isInstanceOfCoordinates(position)

  let coordinates: NullableValues<Coordinates> = {
    x: null,
    y: null,
  }

  if (isPositionByCoordinates && contentRef) {
    coordinates = { ...position }

    const rect = contentRef.getBoundingClientRect()
    if (rect.height + position.y > window.innerHeight) {
      coordinates.y = window.innerHeight - rect.height - 90
    }
    if (rect.width / 2 + position.x > window.innerWidth) {
      coordinates.x = window.innerWidth - rect.width / 2 - 30
    } else if (position.x - rect.width / 2 < 0) {
      coordinates.x = rect.width / 2 + 30
    }
  }

  return isPositionByCoordinates ? coordinates : null
}

export default buildInWindowCoordinates
