import React, { FC } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import Icon from 'components/icon'
import Button from 'components/button'

import {
  StyledUploadDropzone,
  StyledUploadDropzoneContent,
  StyledUploadDropzoneTitle,
  StyledUploadDropzoneIcon,
  StyledUploadDropzoneDetail,
} from 'components/upload_dropzone/style'

import { FILE_MAX_SIZE_MB, FILE_MAX_SIZE_B } from 'constants/files'

type UploadDropzoneVariant = 'importUsers' | 'default'

const UploadDropzone: FC<{
  accept?: string[]
  severalFiles?: boolean
  maxSize?: number
  variant?: UploadDropzoneVariant
  onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void
}> = ({
  onDrop,
  accept = [
    'application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpg, image/jpeg, image/png, image/gif, image/bmp, text/csv, application/vnd.ms-excel, application/octet-stream, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain',
  ],
  maxSize = FILE_MAX_SIZE_B,
  severalFiles = true,
  variant = 'default',
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept.join(', '),
    maxSize,
    onDrop,
    maxFiles: severalFiles ? undefined : 1,
  })
  const { t } = useTranslation()

  return (
    <StyledUploadDropzone {...getRootProps()}>
      <input {...getInputProps()} />
      <StyledUploadDropzoneIcon as={Icon} name='file_upload' />
      <StyledUploadDropzoneContent>
        {isDragActive ? (
          <StyledUploadDropzoneTitle>
            {t('documents.upload.dropzone.droppingTitle')}
          </StyledUploadDropzoneTitle>
        ) : (
          <div>
            <StyledUploadDropzoneDetail>
              {t(`documents.upload.dropzone.details.${variant}`, { mbSize: FILE_MAX_SIZE_MB })}
            </StyledUploadDropzoneDetail>
            <StyledUploadDropzoneTitle>
              {t('documents.upload.dropzone.title', { count: severalFiles ? 2 : 1 })}
            </StyledUploadDropzoneTitle>
            <Button text='Choose file' icon='upload' variant='highlight' />
          </div>
        )}
      </StyledUploadDropzoneContent>
    </StyledUploadDropzone>
  )
}

export { UploadDropzone as UploadDropzoneStoryBook }

export default UploadDropzone
