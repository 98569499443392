import React, { FC } from 'react'

import * as FlagList from 'assets/flags'

import StyledFlag from 'components/flag/style'

export type FlagType = keyof typeof FlagList

interface FlagProps {
  width?: number
  height?: number
  className?: string
  testId?: string
  countryCode: FlagType
}

const Flag: FC<FlagProps> = ({ countryCode, width = 20, height = 20, className, testId }) => {
  let path = null
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require, @typescript-eslint/no-var-requires
    path = require(`assets/flags/${countryCode}.svg`)
    // eslint-disable-next-line no-empty
  } catch (_error) {}

  return (
    path && (
      <StyledFlag
        src={path}
        $width={width}
        $height={height}
        wrapper='div'
        className={className}
        data-testid={testId}
      />
    )
  )
}

export default Flag
