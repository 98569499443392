import React from 'react'

import { useTranslation } from 'react-i18next'

import { StyledPageBookingListBar, StyledPageBookingListBarSection } from 'views/bookings/style'

// import Select from 'components/select'
import Button from 'components/button'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import { TEST_ID_BOOKING_NEW_BOOKING_BUTTON } from 'tests/e2e/test_ids'
// import useBreakpoints from 'services/hooks/use_breakpoints'

const BookingListHeaderBar = () => {
  const { isShipper } = useBookingRole()
  const { t } = useTranslation()

  return (
    <>
      <StyledPageBookingListBar>
        <StyledPageBookingListBarSection>
          {/* <StyledPageBookingListSelect
            as={Select}
            label={isLowerThanDesktop ? '' : t('bookings.select.label')}
            variant='text-control'
            options={[
              {
                value: 'ascending',
                label: t('bookings.select.options.asc'),
              },
              {
                value: 'descending',
                label: t('bookings.select.options.desc'),
              },
              {
                value: 'date_arrival',
                label: t('bookings.select.options.dateOfArrival'),
              },
              {
                value: 'eta_diff',
                label: t('bookings.select.options.etaDiff'),
              },
            ]}
            onChange={() => console.log('onChange sort')}
            isClearable={false}
            isSearchable={false}
            defaultValue={{
              value: 'descending',
              label: t('bookings.select.options.desc'),
            }}
            noPadding={isMobile}
          /> */}
        </StyledPageBookingListBarSection>
      </StyledPageBookingListBar>

      {isShipper && (
        <Button
          isLink
          to='/booking/create'
          text={t('bookings.actions.newBooking')}
          icon='plus_outline'
          variant='highlight'
          testId={TEST_ID_BOOKING_NEW_BOOKING_BUTTON}
        />
      )}
    </>
  )
}

export default BookingListHeaderBar
