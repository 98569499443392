import { createAsyncThunk } from '@reduxjs/toolkit'

import useThunkTeamsParam from 'services/api/hooks/use_thunk_teams_param'
import useUrlParams from 'services/api/hooks/use_url_params'
import { InternalClient } from 'services/api/clients'

import onError from 'services/api/error'

import type { Vehicle } from 'features/shipments/types/vehicle'

// ongoing means shipments active / with path / not flagged
// eslint-disable-next-line import/prefer-default-export
export const fetchOngoingShipments = createAsyncThunk<Vehicle[]>(
  'dashboard/fetchOngoingShipments',
  async (_args, thunkAPI) => {
    const { teams: organizationCodes } = useThunkTeamsParam(thunkAPI)
    // TODO: old team naming
    const url = useUrlParams('/orders_map', { organizationCodes })

    return InternalClient.get(url)
      .then((r) => r.data)
      .then((d) => d.vehicles || [])
      .catch(onError(thunkAPI))
  }
)
