import styled from 'styled-components'

import { gradientLightOpacityReverse } from 'styles/utils/gradients'

const StyledPageLoading = styled.div`
  ${gradientLightOpacityReverse};
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`

export default StyledPageLoading
