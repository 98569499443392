import { useSelector } from 'react-redux'

import useAppDispatch from 'services/hooks/use_app_dispatch'

import { saveBookingsActiveValues, selectBookingsActiveValues } from 'views/bookings/slice'
import { ActiveValue } from 'views/shipments/types/filters'

import type { Category } from 'services/hooks/use_category'
import type { TAnyFilter, TAnyFilters } from 'services/hooks/use_filter'
import type { RootState } from 'services/store/store'

interface UseActiveValues {
  setActiveValues: () => void
  saveActiveValues: () => void
  activeValuesComputed: ActiveValue[]
  activeValuesStored: ActiveValue[]
}
const useActiveValues = ({ categories }: { categories: Category[] }): UseActiveValues => {
  const dispatch = useAppDispatch()
  const activeValuesStored = useSelector((state: RootState) => selectBookingsActiveValues(state))

  const arrayOfAllFilters = categories.reduce(
    (acc: TAnyFilters, category) => [...acc, ...category.filters],
    []
  )

  const setActiveValues = () =>
    activeValuesStored.forEach(({ name: filterName, value }: TAnyFilter) => {
      const filter = arrayOfAllFilters.find(({ name }) => filterName === name)
      filter?.setValue(value)
    })

  const activeValuesComputed = arrayOfAllFilters.map((f: TAnyFilter) => ({
    name: f.name,
    value: f.value,
  }))

  const save = () => {
    dispatch(saveBookingsActiveValues({ activeValues: activeValuesComputed }))
  }

  return {
    setActiveValues,
    saveActiveValues: save,
    activeValuesStored,
    activeValuesComputed,
  }
}
export default useActiveValues
