import { useState } from 'react'

import { FileError, FileRejection } from 'react-dropzone'

import {
  REACT_DROPZONE_FILE_TOO_LARGE_ERROR,
  FILE_MAX_SIZE_B,
  FILE_MAX_SIZE_MB,
} from 'constants/files'
import { uuid4 } from 'services/helpers/uuid'

export interface CustomFile {
  id: string
  nativeFile: File
  state: {
    uploaded: boolean
    uploading: boolean
  }
  errors: FileError[]
  fileType?: {
    label: string
    value: string
  }
}

const buildCustomFile = ({
  nativeFile,
  errors,
}: {
  nativeFile: File
  errors?: FileError[]
}): CustomFile => ({
  id: uuid4(),
  nativeFile,
  state: {
    uploaded: false,
    uploading: false,
  },
  errors: errors || [],
})

const useUploadFiles = () => {
  const [files, setFiles] = useState<CustomFile[]>([])

  const onDropFiles = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const newFiles = acceptedFiles.map((file: File) => buildCustomFile({ nativeFile: file }))

    const rejectedFiles = fileRejections.map(({ file, errors }) => {
      const formattedErrors = errors.map(({ code, message }) => {
        let formattedMessage = message
        if (code === REACT_DROPZONE_FILE_TOO_LARGE_ERROR) {
          formattedMessage = formattedMessage.replace(
            FILE_MAX_SIZE_B.toString(),
            FILE_MAX_SIZE_MB.toString()
          )
          formattedMessage = formattedMessage.replace('bytes', 'MB')
        }
        return { code, message: formattedMessage }
      })

      return buildCustomFile({ nativeFile: file, errors: formattedErrors })
    })

    setFiles([...files, ...newFiles, ...rejectedFiles])
  }

  const removeAllFiles = () => setFiles([])

  const getFile = (filesArray: CustomFile[], file: CustomFile) => filesArray.find((f) => f === file)

  const removeFile = (file: File) => () => {
    const newFiles = [...files]
    newFiles.splice(
      newFiles.findIndex(({ nativeFile }) => nativeFile === file),
      1
    )
    setFiles(newFiles)
  }

  const setFileState = ({
    file,
    uploading,
    uploaded,
  }: {
    file: CustomFile
    uploading?: boolean
    uploaded?: boolean
  }) => {
    const newFiles = [...files]
    const newFilesFile = getFile(newFiles, file)

    if (!newFilesFile) return

    newFilesFile.state = {
      uploading: uploading ?? file.state.uploading,
      uploaded: uploaded ?? file.state.uploaded,
    }

    setFiles(newFiles)
  }

  const setFileType = ({
    file,
    label,
    value,
  }: {
    file: CustomFile
    label: string
    value: string
  }) => {
    const newFiles = [...files]
    const newFilesFile = getFile(newFiles, file)

    if (!newFilesFile) return

    newFilesFile.fileType = {
      label,
      value,
    }

    setFiles(newFiles)
  }

  const setFileError = ({
    file,
    code,
    message,
  }: {
    file: CustomFile
    code: string
    message: string
  }) => {
    const newFiles = [...files]
    const newFilesFile = getFile(newFiles, file)

    if (!newFilesFile) return

    newFilesFile.errors.push({
      code,
      message,
    })

    setFiles(newFiles)
  }

  return {
    onDropFiles,
    files,
    removeAllFiles,
    removeFile,
    setFileType,
    setFileState,
    setFileError,
  }
}

export default useUploadFiles
