import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'

import { isAnyArray } from 'services/helpers/values'

import { selectCurrentUser } from 'views/iam/slices/iamSlice'

import { LEGACY_TEAMS_PARAM_NAME, TEAMS_PARAM_NAME } from 'constants/api'

import type { AppDispatch, RootState } from 'services/store/store'
import type { Teams } from 'views/iam/types'

interface IThunkTeamsOptions {
  legacyNaming: boolean
}
type TeamsParam = { [TEAMS_PARAM_NAME]?: Teams } | { [LEGACY_TEAMS_PARAM_NAME]?: Teams }

const useThunkTeamsParam: any = (
  thunkAPI: BaseThunkAPI<RootState, never, AppDispatch>,
  options: IThunkTeamsOptions | undefined = { legacyNaming: false }
): TeamsParam => {
  const { legacyNaming } = options
  const { getState } = thunkAPI
  const { teams } = selectCurrentUser(getState())
  return isAnyArray(teams)
    ? { [legacyNaming ? LEGACY_TEAMS_PARAM_NAME : TEAMS_PARAM_NAME]: teams }
    : {}
}
export default useThunkTeamsParam
