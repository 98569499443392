import React, { FC } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

import { useTheme } from 'styled-components'

import StyledPageLoading from 'views/loading/style'
import OrganizationLogo from 'components/organization_logo'

const LoadingView: FC = () => {
  const theme = useTheme()

  return (
    <StyledPageLoading>
      <OrganizationLogo />
      <ClipLoader color={theme.primary} loading size={70} cssOverride={{ borderWidth: '5px' }} />
    </StyledPageLoading>
  )
}

export default LoadingView
