import React from 'react'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'
import BookingListItem from 'components/booking_list_item'

import StyledBookingList from 'components/booking_list/style'

export { default as BookingListSkeleton } from 'components/booking_list/skeleton'

const BookingList = ({ children }: { children: typeof BookingListItem }) => (
  <StyledBookingList>{children}</StyledBookingList>
)

BookingList.propTypes = {
  /** BookingListItem */
  children: validateChildrenOfTypes([BookingListItem]),
}

BookingList.defaultProps = {
  children: undefined,
}

export default BookingList
