import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useFilter, { IFilter, TFilterValue } from 'services/hooks/use_filter'
import useCategory from 'services/hooks/use_category'
import useForm from 'services/hooks/use_form'
import useBookingValidations from 'views/booking/components/form/hooks/use_booking_validations'
import useDisabledField from 'views/booking/components/form/hooks/use_disabled_field'

import {
  BOOKING_FIELD_NAMES,
  INFORMATIVE_FIELD_NAMES,
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_TOTAL,
  BOOKING_FIELD_VALIDATIONS,
  trackingRefFormatExampleMap,
  MERCHANDISE_FCL,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
} from 'constants/bookings'
import {
  WITH_BOOKING_OLD_WORKFLOW,
  WITH_FRONT_BOOKING_CUT_OFF_DATES,
} from 'constants/organization_features'
import { TRANSPORT_TYPE_AIR, TRANSPORT_TYPE_SEA } from 'constants/shipments'

import {
  isAnyArray,
  isAnyObject,
  isPresent,
  compactObject,
  isInputPresent,
  isNull,
} from 'services/helpers/values'

import useCurrentCompany from 'views/iam/hooks/use_current_company'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import useBookingStatus from 'views/booking/hooks/use_booking_status'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import { uuid4 } from 'services/helpers/uuid'

import type { QueryParams } from 'services/hooks/use_category'
import type {
  Booking,
  BookingAddress,
  BookingCustomReference,
  ShipmentAttribute,
  BookingMerchandiseTotalContent,
  BookingMerchandisePackageContent,
  BookingMerchandiseContainerContent,
  BookingMerchandiseProduct,
  BookingMerchandiseTotalPackage,
  CommonMerchandiseDetails,
} from 'views/booking/slices/types'
import type { SelectValue, Tvalue } from 'components/select'

export interface IBookingFormFilters {
  clientReferenceFilter: IFilter<'text'>
  clientBookingNumberFilter: IFilter<'text'>
  forwarderReferenceFilter: IFilter<'text'>
  shipperFilter: IFilter<'select'>
  forwarderFilter: IFilter<'select'>
  consignorFilter: IFilter<'select'>
  consigneeFilter: IFilter<'select'>
  incotermsFilter: IFilter<'select'>
  incotermsLocationFilter: IFilter<'text'>
  transportTypeFilter: IFilter<'radio'>
  preCarriageFilter: IFilter<'select'>
  polFilter: IFilter<'select'>
  polPtdFilter: IFilter<'date'>
  polPtaFilter: IFilter<'date'>
  podFilter: IFilter<'select'>
  podPtaFilter: IFilter<'date'>
  podPtdFilter: IFilter<'date'>
  onCarriageFilter: IFilter<'select'>
  ptdFilter: IFilter<'date'>
  ptaFilter: IFilter<'date'>
  vgmCutOffDateFilter: IFilter<'date'>
  vesselCutOffDateFilter: IFilter<'date'>
  customFieldsFilter: IFilter<'custom'>
  merchandiseFilter: IFilter<'custom'>
  rateConfirmationFilter: IFilter<'checkbox'>
  commentsFilter: IFilter<'text'>
  forwarderCommentFilter: IFilter<'text'>
  keyContactsFilter: IFilter<'tags'>
  customReferencesFilter: IFilter<'custom'>
  carrierFilter: IFilter<'select'>
  bookingNumberFilter: IFilter<'text'>
  masterBlFilter: IFilter<'text'>
  vesselsFilter: IFilter<'multiselect'>
  voyageNumbersFilter: IFilter<'tags'>
  flightNumbersFilter: IFilter<'tags'>
  shipmentAttributesFilter: IFilter<'custom'>
  transshipmentsFilter: IFilter<'custom'>
  transportPlanFilter: IFilter<'custom'>
  rateAmountFilter: IFilter<'number'>
  rateCurrencyFilter: IFilter<'select'>
}
export interface BookingFormProps {
  filters: IBookingFormFilters
  isCreate?: boolean
  routingWarning?: { message: string; addresses: string[] }
}
export type AnyBookingFormFilter = IBookingFormFilters[keyof IBookingFormFilters]
export interface IBookingForm {
  filters: IBookingFormFilters
  isValid: boolean
  isEdited: boolean
  queryParams: QueryParams
  editedQueryParams: QueryParams
  wontTriggerReview: boolean
}

export type TLCLMerchandiseType = typeof MERCHANDISE_TYPE_PACKAGE | typeof MERCHANDISE_TYPE_TOTAL

const useBookingForm = ({
  booking,
  isEdit = false,
}: {
  booking?: Partial<Booking>
  isEdit?: boolean
}): IBookingForm => {
  const { company: currentCompany, ownsCapacity } = useCurrentCompany()
  const { features } = useOrganizationCan()
  const orgaWithLegacyWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)
  const {
    clientReference,
    clientBookingNumber,
    forwarderReference,
    customReferences,
    forwarder,
    shipper,
    consignor,
    consignee,
    incoterms,
    incotermsLocation,
    transportType,
    preCarriageAddress,
    pol,
    pod,
    onCarriageAddress,
    comments,
    forwarderComment,
    shipperPtd,
    polPtd,
    polPta,
    podPtd,
    podPta,
    shipperPta,
    proposal,
    status,
    transshipments,
    merchandise,
    assignedUsers,
    rateConfirmation,
    carrier,
    bookingNumber,
    vessels,
    masterBl,
    voyageNumbers,
    flightNumbers,
    customFields,
    shipmentAttributes,
    vgmCutOffDate,
    vesselCutOffDate,
    revisedPta,
    revisedPtd,
    transportPlan,
  } = booking || {}

  const { isStatusPastProposalExchange, isStatusConfirmed } = useBookingStatus(status)
  const { isForwarder } = useBookingRole()

  const proposalOrCurrentPtd = isStatusPastProposalExchange
    ? proposal?.ptd || revisedPtd
    : shipperPtd
  const proposalOrCurrentPta = isStatusPastProposalExchange
    ? proposal?.pta || revisedPta
    : shipperPta

  const defaultPtd = isForwarder ? proposal?.ptd || revisedPtd : proposalOrCurrentPtd
  const defaultPta = isForwarder ? proposal?.pta || revisedPta : proposalOrCurrentPta

  const { s, fromStaticToSelectOptions } = useStaticLocales()
  const { t } = useTranslation()

  const formatAddressSelectValue = (address: BookingAddress) => ({
    label: `${address.name}${isPresent(address.countryCode) ? `, ${address.countryCode}` : ''} ${
      isPresent(address.locode) ? `(${address.locode})` : ''
    }`,
    value: address.id,
  })

  const { validateContainerNumber, validateMasterAwb } = useBookingValidations()

  // This filter is the source of truth of what the user wants to have as transport plan

  const transportPlanFilter = useFilter({
    name: 'transportPlan',
    type: 'custom',
    defaultValue: {
      withPreCarriage: transportPlan?.withPreCarriage,
      withPol: isEdit ? booking?.transportPlan?.withPol : true,
      withPod: isEdit ? booking?.transportPlan?.withPod : true,
      withOnCarriage: transportPlan?.withOnCarriage,
    },
    disabled: orgaWithLegacyWorkflow && (isStatusConfirmed || isForwarder),
  })

  const routingShouldHavePreCarriage = transportPlanFilter.value.withPreCarriage
  const routingShouldHaveOnCarriage = transportPlanFilter.value.withOnCarriage

  const routingWithoutPreCarriage = !routingShouldHavePreCarriage
  const routingWithoutOnCarriage = !routingShouldHaveOnCarriage

  const defaultPolPtd = routingWithoutPreCarriage && orgaWithLegacyWorkflow ? defaultPtd : polPtd
  const defaultPodPta = routingWithoutOnCarriage && orgaWithLegacyWorkflow ? defaultPta : podPta

  const { isFieldDisabled } = useDisabledField({
    booking,
    isEdit,
    routingWithoutPreCarriage,
    routingWithoutOnCarriage,
    rateConfirmation: !!rateConfirmation,
    defaultForwarderId: forwarder?.id,
  })

  const clientReferenceFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CLIENT_REFERENCE,
    type: 'text',
    required: true,
    defaultValue: clientReference,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CLIENT_REFERENCE),
  })
  const clientBookingNumberFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CLIENT_BOOKING_NUMBER,
    type: 'text',
    defaultValue: clientBookingNumber,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CLIENT_BOOKING_NUMBER),
  })
  const forwarderReferenceFilter = useFilter({
    name: BOOKING_FIELD_NAMES.FORWARDER_REFERENCE,
    type: 'text',
    defaultValue: forwarderReference,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.FORWARDER_REFERENCE),
  })
  const referencesCategory = useCategory({
    name: 'references',
    filters: [clientReferenceFilter, clientBookingNumberFilter, forwarderReferenceFilter],
    toQueryParams: (filters) =>
      filters.reduce((acc, filter) => ({ ...acc, [filter.name]: filter.value }), {}),
  })
  const customReferencesFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CUSTOM_REFERENCES,
    type: 'custom',
    defaultValue: isAnyArray(customReferences) ? customReferences : [],
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_REFERENCES),
  })
  const customReferencesCategory = useCategory({
    name: 'customReferences',
    filters: [customReferencesFilter],
    toQueryParams: (_filters, filtersHash) => ({
      customReferences: filtersHash.customReferences?.value.filter(
        ({ key, value }: BookingCustomReference) => isPresent(key) && isPresent(value)
      ),
    }),
  })

  const defaultShipper = useMemo(() => {
    if (isPresent(shipper)) return shipper

    if (ownsCapacity('shipper')) return currentCompany

    return null
  }, [currentCompany, shipper, ownsCapacity])
  const consignorFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CONSIGNOR,
    type: 'select',
    required: true,
    defaultValue: consignor
      ? {
          value: consignor.id,
          label: consignor.name,
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CONSIGNOR),
  })
  const consigneeFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CONSIGNEE,
    type: 'select',
    required: true,
    defaultValue: consignee
      ? {
          value: consignee.id,
          label: consignee.name,
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CONSIGNEE),
  })
  const forwarderFilter = useFilter({
    name: BOOKING_FIELD_NAMES.FORWARDER,
    type: 'select',
    required: true,
    defaultValue: isPresent(forwarder)
      ? {
          value: forwarder.id,
          label: forwarder.name,
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.FORWARDER),
  })
  const shipperFilter = useFilter({
    name: BOOKING_FIELD_NAMES.SHIPPER,
    type: 'select',
    required: true,
    defaultValue: defaultShipper?.id
      ? {
          value: defaultShipper.id,
          label: defaultShipper.name,
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.SHIPPER),
  })
  const partiesCategory = useCategory({
    name: 'parties',
    filters: [forwarderFilter, consignorFilter, consigneeFilter, shipperFilter],
    toQueryParams: (filters, filtersHash) => {
      const formatParamsMapping: any = {
        consignor: () => ({ consignor_id: filtersHash.consignor?.value?.value }),
        consignee: () => ({ consignee_id: filtersHash.consignee?.value?.value }),
        forwarder: () => ({ forwarder_id: filtersHash.forwarder?.value?.value }),
        shipper: () => ({ shipper_id: filtersHash.shipper?.value?.value }),
      }
      return filters.reduce(
        (acc, filter) => ({ ...acc, ...formatParamsMapping[filter.name]() }),
        {}
      )
    },
  })

  const incotermsFilter = useFilter({
    name: BOOKING_FIELD_NAMES.INCOTERMS,
    type: 'select',
    required: true,
    defaultValue: incoterms
      ? {
          value: incoterms,
          label: incoterms,
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.INCOTERMS),
  })
  const incotermsLocationFilter = useFilter({
    name: BOOKING_FIELD_NAMES.INCOTERMS_LOCATION,
    type: 'text',
    defaultValue: incotermsLocation,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.INCOTERMS_LOCATION),
  })
  const incotermsCategory = useCategory({
    name: 'incoterms',
    filters: [incotermsFilter, incotermsLocationFilter],
    toQueryParams: (_filters, filtersHash) =>
      compactObject({
        incoterms: filtersHash.incoterms?.value?.value,
        incotermsLocation: filtersHash.incotermsLocation?.value,
      }),
  })

  const transportTypeFilter = useFilter({
    name: BOOKING_FIELD_NAMES.TRANSPORT_TYPE,
    type: 'radio',
    defaultValue: transportType || TRANSPORT_TYPE_SEA,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.TRANSPORT_TYPE),
  })

  const polFilter = useFilter({
    name: BOOKING_FIELD_NAMES.POL,
    type: 'select',
    defaultValue: isPresent(pol) ? formatAddressSelectValue(pol) : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.POL),
    customValidation: (value: any) => {
      if (routingWithoutPreCarriage) {
        return isPresent(value)
      }
      return true
    },
  })
  const polPtdFilter = useFilter({
    name: BOOKING_FIELD_NAMES.POL_PTD,
    type: 'date',
    required: false,
    defaultValue: defaultPolPtd,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.POL_PTD),
  })
  const polPtaFilter = useFilter({
    name: BOOKING_FIELD_NAMES.POL_PTA,
    type: 'date',
    required: false,
    defaultValue: polPta,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.POL_PTA),
  })
  const podFilter = useFilter({
    name: BOOKING_FIELD_NAMES.POD,
    type: 'select',
    defaultValue: isPresent(pod) ? formatAddressSelectValue(pod) : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.POD),
    customValidation: (value: any) => {
      if (routingWithoutOnCarriage) {
        return isPresent(value)
      }
      return true
    },
  })
  const podPtdFilter = useFilter({
    name: BOOKING_FIELD_NAMES.POD_PTD,
    type: 'date',
    required: false,
    defaultValue: podPtd,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.POD_PTD),
  })
  const podPtaFilter = useFilter({
    name: BOOKING_FIELD_NAMES.POD_PTA,
    type: 'date',
    required: false,
    defaultValue: defaultPodPta,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.POD_PTA),
  })
  const preCarriageFilter = useFilter({
    name: BOOKING_FIELD_NAMES.PRE_CARRIAGE,
    type: 'select',
    required: routingShouldHavePreCarriage,
    defaultValue: isPresent(preCarriageAddress)
      ? formatAddressSelectValue(preCarriageAddress!)
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.PRE_CARRIAGE),
    customValidation: (value: any) => {
      if (routingShouldHavePreCarriage) {
        return isPresent(value)
      }
      return true
    },
  })
  const onCarriageFilter = useFilter({
    name: BOOKING_FIELD_NAMES.ON_CARRIAGE,
    type: 'select',
    required: routingShouldHaveOnCarriage,
    defaultValue: isPresent(onCarriageAddress)
      ? formatAddressSelectValue(onCarriageAddress!)
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.ON_CARRIAGE),
    customValidation: (value: any) => {
      if (routingShouldHaveOnCarriage) {
        return isPresent(value)
      }
      return true
    },
  })

  const ptdFilter = useFilter({
    name: BOOKING_FIELD_NAMES.PTD,
    type: 'date',
    required: false,
    defaultValue: isAnyObject(booking) ? defaultPtd : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.PTD),
  })
  const ptaFilter = useFilter({
    name: BOOKING_FIELD_NAMES.PTA,
    type: 'date',
    required: false,
    defaultValue: isAnyObject(booking) ? defaultPta : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.PTA),
  })

  const transshipmentsFilter = useFilter({
    name: 'transshipments',
    type: 'custom',
    defaultValue:
      transshipments?.map(({ address, ...dates }: any) => ({
        address: isPresent(address)
          ? formatAddressSelectValue(address as BookingAddress)
          : undefined,
        ...dates,
      })) || [],
    customValidation: (value) => value.every((ts: any) => isPresent(ts.address)),
    disabled: false,
  })

  const routingCategory = useCategory({
    name: 'routing',
    filters: [
      preCarriageFilter,
      polFilter,
      podFilter,
      onCarriageFilter,
      transportTypeFilter,
      ptdFilter,
      polPtdFilter,
      podPtdFilter,
      podPtaFilter,
      polPtaFilter,
      ptaFilter,
      transshipmentsFilter,
      transportPlanFilter,
    ],
    toQueryParams: (_filters, filtersHash) => {
      const formatParamsMapping: any = {
        transportPlan: transportPlanFilter.value,
        transport_type: filtersHash.transportType?.value,
        pre_carriage_address_id: filtersHash.preCarriage?.value?.value,
        ptd: filtersHash.ptd?.value,
        pol_id: filtersHash.pol?.value?.value,
        pol_ptd: filtersHash.polPtd?.value,
        pol_pta: filtersHash.polPta?.value,

        pod_id: filtersHash.pod?.value?.value,
        pod_ptd: filtersHash.podPtd?.value,
        pod_pta: filtersHash.podPta?.value,
        on_carriage_address_id: filtersHash.onCarriage?.value?.value,
        pta: filtersHash.pta?.value,
        transshipments: filtersHash.transshipments?.value.map(
          ({ address, pta: tsPta, ptd: tsPtd }: any) => ({
            address_id: address?.value,
            pta: tsPta,
            ptd: tsPtd,
          })
        ),
      }
      return formatParamsMapping
    },
  })

  const vgmCutOffDateFilter = useFilter({
    name: BOOKING_FIELD_NAMES.VGM_CUT_OFF_DATE,
    type: 'date',
    defaultValue: features(WITH_FRONT_BOOKING_CUT_OFF_DATES) ? vgmCutOffDate : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.VGM_CUT_OFF_DATE),
  })
  const vesselCutOffDateFilter = useFilter({
    name: BOOKING_FIELD_NAMES.VESSEL_CUT_OFF_DATE,
    type: 'date',
    defaultValue: features(WITH_FRONT_BOOKING_CUT_OFF_DATES) ? vesselCutOffDate : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.VESSEL_CUT_OFF_DATE),
  })
  const customFieldsFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CUSTOM_FIELDS,
    type: 'custom',
    defaultValue: isAnyArray(customFields) ? customFields : [],
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CUSTOM_FIELDS),
  })

  const miscellaneousCategory = useCategory({
    name: 'miscellaneous',
    filters: [vgmCutOffDateFilter, vesselCutOffDateFilter, customFieldsFilter],
    toQueryParams: (filters) => {
      const isTransportTypeSea = transportTypeFilter.value === TRANSPORT_TYPE_SEA
      const formatParamsMapping: any = {
        vgmCutOffDate: (filterValue: TFilterValue<'date'>) =>
          isTransportTypeSea && {
            vgm_cut_off_date: filterValue,
          },
        vesselCutOffDate: (filterValue: TFilterValue<'date'>) =>
          isTransportTypeSea && {
            vessel_cut_off_date: filterValue,
          },
        customFields: (filterValue: TFilterValue<'custom'>) => ({
          custom_fields: filterValue.filter(
            ({ key, value }: BookingCustomReference) => isPresent(key) && isPresent(value)
          ),
        }),
      }
      return filters.reduce(
        (acc, filter) => ({
          ...acc,
          ...formatParamsMapping[filter.name](filter.value),
        }),
        {}
      )
    },
  })

  const isMerchandiseDetailsPresent = (merchandiseDetails: CommonMerchandiseDetails) =>
    [
      merchandiseDetails.productDescription,
      merchandiseDetails.commercialValue?.amount,
      merchandiseDetails.hazardousGoods?.hazardousClass,
      merchandiseDetails.hazardousGoods?.unNumber,
      merchandiseDetails.hazardousGoods?.packingGroup,
      merchandiseDetails.hazardousGoods?.weight?.value,
      merchandiseDetails.controlledTemperatures?.min,
      merchandiseDetails.controlledTemperatures?.max,
    ].some((att) => isPresent(att))

  const isMerchandiseProductPresent = (product: BookingMerchandiseProduct) =>
    [product.weight?.value, product.volume?.value, product.packageNumber].some((att) =>
      isPresent(att)
    ) || isMerchandiseDetailsPresent(product)

  const merchandiseFilter = useFilter({
    name: BOOKING_FIELD_NAMES.MERCHANDISE,
    type: 'custom',
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.MERCHANDISE),
    defaultValue: {
      shippingMode: merchandise?.shippingMode ?? MERCHANDISE_FCL,
      merchandiseType: merchandise?.merchandiseType ?? MERCHANDISE_TYPE_TOTAL,
      total:
        merchandise?.merchandiseType === MERCHANDISE_TYPE_TOTAL
          ? {
              containers:
                merchandise.content?.containers.map((container) => ({
                  ...container,
                  containerType: {
                    label: s('containerTypes')[(container as any).containerType],
                    value: container.containerType,
                  },
                })) ?? [],
              products:
                merchandise.content?.products?.map((product) => ({
                  ...product,
                  commercialValue: {
                    amount: product.commercialValue?.amount,
                    currencyCode: product.commercialValue?.currencyCode
                      ? {
                          label: product.commercialValue.currencyCode,
                          value: product.commercialValue.currencyCode,
                        }
                      : null,
                  },
                  hazardousGoods: {
                    ...product.hazardousGoods,
                    hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                      ({ value }) => value === `${product.hazardousGoods?.hazardousClass}`
                    ),
                    packingGroup: fromStaticToSelectOptions('packingGroups').find(
                      ({ value }) => value === `${product.hazardousGoods?.packingGroup}`
                    ),
                  },
                })) ?? [],
            }
          : { containers: [], products: [] },
      packageTotal:
        merchandise?.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL
          ? {
              weight: {
                value: merchandise?.weight?.value,
                unit: merchandise?.weight?.unit,
              },
              volume: {
                value: merchandise?.volume?.value,
                unit: merchandise?.volume?.unit,
              },
              packageNumber: merchandise?.packageNumber,
              content: merchandise?.content?.map((merchandiseDetails) => ({
                ...merchandiseDetails,
                commercialValue: {
                  amount: merchandiseDetails.commercialValue?.amount,
                  currencyCode: merchandiseDetails.commercialValue?.currencyCode
                    ? {
                        label: merchandiseDetails.commercialValue?.currencyCode,
                        value: merchandiseDetails.commercialValue?.currencyCode,
                      }
                    : null,
                },
                hazardousGoods: {
                  ...merchandiseDetails.hazardousGoods,
                  hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                    ({ value }) => value === `${merchandiseDetails.hazardousGoods?.hazardousClass}`
                  ),
                  packingGroup: fromStaticToSelectOptions('packingGroups').find(
                    ({ value }) => value === `${merchandiseDetails.hazardousGoods?.packingGroup}`
                  ),
                },
              })),
            }
          : { content: [] },
      container:
        merchandise?.merchandiseType === MERCHANDISE_TYPE_CONTAINER
          ? merchandise.content?.map((container) => ({
              ...container,
              containerType: {
                label: s('containerTypes')[(container as any).containerType],
                value: container.containerType,
              },
              products: container.products?.map((product) => ({
                ...product,
                commercialValue: {
                  amount: product.commercialValue?.amount,
                  currencyCode: product.commercialValue?.currencyCode
                    ? {
                        label: product.commercialValue?.currencyCode,
                        value: product.commercialValue?.currencyCode,
                      }
                    : null,
                },
                hazardousGoods: {
                  ...product.hazardousGoods,
                  hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                    ({ value }) => value === `${product.hazardousGoods?.hazardousClass}`
                  ),
                  packingGroup: fromStaticToSelectOptions('packingGroups').find(
                    ({ value }) => value === `${product.hazardousGoods?.packingGroup}`
                  ),
                },
              })),
            }))
          : [],
      package:
        merchandise?.merchandiseType === MERCHANDISE_TYPE_PACKAGE
          ? merchandise.content?.map((p) => ({
              ...p,
              packageType: {
                label: s('packageTypes')[(p as any).packageType],
                value: p.packageType,
              },
              commercialValue: {
                amount: p.commercialValue?.amount,
                currencyCode: p.commercialValue?.currencyCode
                  ? {
                      label: p.commercialValue?.currencyCode,
                      value: p.commercialValue?.currencyCode,
                    }
                  : null,
              },
              hazardousGoods: {
                ...p.hazardousGoods,
                hazardousClass: fromStaticToSelectOptions('hazardousGoods').find(
                  ({ value }) => value === `${p.hazardousGoods?.hazardousClass}`
                ),
                packingGroup: fromStaticToSelectOptions('packingGroups').find(
                  ({ value }) => value === `${p.hazardousGoods?.packingGroup}`
                ),
              },
            }))
          : [],
    },
    customValidation: (value, setError) => {
      let isValid = true
      const errors: any = {}
      switch (value.merchandiseType) {
        case MERCHANDISE_TYPE_CONTAINER: {
          const merchandiseContainer = value.container as BookingMerchandiseContainerContent[]

          errors.container = []

          merchandiseContainer?.forEach((container) => {
            const containerErrors: any = {}

            if (!isPresent(container.containerType)) isValid = false

            if (!container.quantity) {
              isValid = false
            } else if (container.quantity < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY) {
              isValid = false
              containerErrors.quantity = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY
              }`
            }

            containerErrors.products = []

            container.products?.forEach((product) => {
              const productErrors: any = {}

              if (
                product.weight?.value &&
                product.weight.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
              ) {
                isValid = false
                productErrors.weight = `${t('errors.validation.greaterOrEqual')} ${
                  BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
                }`
              }

              if (
                product.volume?.value &&
                product.volume.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME
              ) {
                isValid = false
                productErrors.volume = `${t('errors.validation.greaterOrEqual')} ${
                  BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME
                }`
              }

              if (
                product.packageNumber &&
                product.packageNumber < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES
              ) {
                isValid = false
                productErrors.packageNumber = `${t('errors.validation.greaterOrEqual')} ${
                  BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES
                }`
              }

              if (
                isPresent(product.commercialValue?.amount) &&
                isPresent(product.commercialValue) &&
                product.commercialValue.amount < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
              ) {
                isValid = false
                productErrors.commercialValueAmount = `${t('errors.validation.greaterOrEqual')} ${
                  BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
                }`
              }

              if (
                isPresent(product.commercialValue?.amount) &&
                !isPresent(product.commercialValue?.currencyCode)
              ) {
                isValid = false
                productErrors.commercialValueCurrencyCode = t('errors.validation.required')
              }

              if (
                product.hazardousGoods?.weight?.value &&
                product.hazardousGoods.weight.value <
                  BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
              ) {
                isValid = false
                productErrors.hazardousWeight = `${t('errors.validation.greaterOrEqual')} ${
                  BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
                }`
              }

              containerErrors.products.push(productErrors)
            })

            errors.container.push(containerErrors)
          })

          break
        }
        case MERCHANDISE_TYPE_PACKAGE: {
          const merchandisePackage = value.package as BookingMerchandisePackageContent[]

          errors.package = []

          merchandisePackage.forEach((p) => {
            const packageErrors: any = {}

            if (!isPresent(p.packageType)) isValid = false

            if (!p.quantity) {
              isValid = false
            } else if (p.quantity < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY) {
              isValid = false
              packageErrors.quantity = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY
              }`
            }

            if (!p.width?.value) {
              isValid = false
            } else if (p.width.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WIDTH) {
              isValid = false
              packageErrors.width = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WIDTH
              }`
            }

            if (!p.length?.value) {
              isValid = false
            } else if (p.length.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.LENGTH) {
              isValid = false
              packageErrors.length = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.LENGTH
              }`
            }

            if (!p.weight?.value) {
              isValid = false
            } else if (p.weight.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT) {
              isValid = false
              packageErrors.weight = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
              }`
            }

            if (!p.height?.value) {
              isValid = false
            } else if (p.height.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.HEIGHT) {
              isValid = false
              packageErrors.height = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.HEIGHT
              }`
            }

            if (
              isPresent(p.commercialValue?.amount) &&
              isPresent(p.commercialValue) &&
              p.commercialValue.amount < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
            ) {
              isValid = false
              packageErrors.commercialValueAmount = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
              }`
            }

            if (
              isPresent(p.commercialValue?.amount) &&
              !isPresent(p.commercialValue?.currencyCode)
            ) {
              isValid = false
              packageErrors.commercialValueCurrencyCode = t('errors.validation.required')
            }

            if (
              p.hazardousGoods?.weight?.value &&
              p.hazardousGoods.weight.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
            ) {
              isValid = false
              packageErrors.hazardousWeight = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
              }`
            }

            errors.package.push(packageErrors)
          })

          break
        }
        case MERCHANDISE_TYPE_PACKAGE_TOTAL: {
          const merchandiseTotalPackage = value.packageTotal as BookingMerchandiseTotalPackage

          errors.packageTotal = { content: [] }

          if (!isPresent(merchandiseTotalPackage.weight?.value)) isValid = false

          if (
            merchandiseTotalPackage.weight?.value &&
            merchandiseTotalPackage.weight.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
          ) {
            isValid = false
            errors.packageTotal.weight = `${t('errors.validation.greaterOrEqual')} ${
              BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
            }`
          }

          if (!isPresent(merchandiseTotalPackage.volume?.value)) isValid = false

          if (
            merchandiseTotalPackage.volume?.value &&
            merchandiseTotalPackage.volume.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME
          ) {
            isValid = false
            errors.packageTotal.volume = `${t('errors.validation.greaterOrEqual')} ${
              BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME
            }`
          }

          merchandiseTotalPackage.content.forEach((merchandiseDetails) => {
            const merchandiseDetailsErrors: any = {}

            if (
              isPresent(merchandiseDetails.commercialValue?.amount) &&
              isPresent(merchandiseDetails.commercialValue) &&
              merchandiseDetails.commercialValue.amount <
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
            ) {
              isValid = false
              merchandiseDetailsErrors.commercialValueAmount = `${t(
                'errors.validation.greaterOrEqual'
              )} ${BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}`
            }

            if (
              isPresent(merchandiseDetails.commercialValue?.amount) &&
              !isPresent(merchandiseDetails.commercialValue?.currencyCode)
            ) {
              isValid = false
              merchandiseDetailsErrors.commercialValueCurrencyCode = t('errors.validation.required')
            }

            errors.packageTotal.content.push(merchandiseDetailsErrors)
          })

          break
        }
        case MERCHANDISE_TYPE_TOTAL: {
          const merchandiseTotal = value.total as BookingMerchandiseTotalContent

          errors.total = { containers: [], products: [] }

          merchandiseTotal?.containers.forEach((container) => {
            const containerErrors: any = {}

            if (!isPresent(container.containerType)) isValid = false

            if (!container.quantity) {
              isValid = false
            } else if (container.quantity < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY) {
              isValid = false
              containerErrors.quantity = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.QUANTITY
              }`
            }

            errors.total.containers.push(containerErrors)
          })

          merchandiseTotal?.products?.forEach((product) => {
            const productErrors: any = {}

            if (
              product.weight?.value &&
              product.weight.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
            ) {
              isValid = false
              productErrors.weight = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
              }`
            }

            if (
              product.volume?.value &&
              product.volume.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME
            ) {
              isValid = false
              productErrors.volume = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME
              }`
            }

            if (
              product.packageNumber &&
              product.packageNumber < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES
            ) {
              isValid = false
              productErrors.packageNumber = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES
              }`
            }

            if (
              isPresent(product.commercialValue?.amount) &&
              isPresent(product.commercialValue) &&
              product.commercialValue.amount < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
            ) {
              isValid = false
              productErrors.commercialValueAmount = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT
              }`
            }

            if (
              isPresent(product.commercialValue?.amount) &&
              !isPresent(product.commercialValue?.currencyCode)
            ) {
              isValid = false
              productErrors.commercialValueCurrencyCode = t('errors.validation.required')
            }

            if (
              product.hazardousGoods?.weight?.value &&
              product.hazardousGoods.weight.value < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
            ) {
              isValid = false
              productErrors.hazardousWeight = `${t('errors.validation.greaterOrEqual')} ${
                BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT
              }`
            }

            errors.total.products.push(productErrors)
          })

          break
        }
        default:
          break
      }

      setError(errors)
      return isValid
    },
  })

  const merchandiseCategory = useCategory({
    name: 'merchandise',
    filters: [merchandiseFilter],
    toQueryParams: (_filters, filtersHash) => {
      let merchandiseContent = null
      let merchandiseAttributes = null

      switch (filtersHash.merchandise.value.merchandiseType) {
        case MERCHANDISE_TYPE_TOTAL:
        default:
          merchandiseContent = {
            containers: filtersHash.merchandise?.value.total.containers.map((c: any) => ({
              quantity: c.quantity,
              containerType: c.containerType ? parseInt(c.containerType.value, 10) : null,
            })),
            products: filtersHash.merchandise?.value.total.products
              ?.filter((p: BookingMerchandiseProduct) => isMerchandiseProductPresent(p))
              .map((p: any) => ({
                ...p,
                commercialValue: p.commercialValue?.amount
                  ? {
                      ...p.commercialValue,
                      currencyCode: p.commercialValue?.currencyCode?.label,
                    }
                  : null,
                weight: p.weight?.value
                  ? {
                      value: p.weight?.value,
                      unit: 'kg',
                    }
                  : null,
                volume: p.volume?.value
                  ? {
                      value: p.volume?.value,
                      unit: 'cbm',
                    }
                  : null,
                hazardousGoods: {
                  ...p.hazardousGoods,
                  hazardousClass: p.hazardousGoods?.hazardousClass
                    ? parseInt(p.hazardousGoods.hazardousClass.value, 10)
                    : null,
                  packingGroup: p.hazardousGoods?.packingGroup
                    ? parseInt(p.hazardousGoods?.packingGroup.value, 10)
                    : null,
                  weight: p.hazardousGoods?.weight?.value
                    ? {
                        value: p.hazardousGoods?.weight.value,
                        unit: 'kg',
                      }
                    : null,
                },
              })),
          }
          break
        case MERCHANDISE_TYPE_PACKAGE_TOTAL:
          merchandiseAttributes = {
            volume: filtersHash.merchandise?.value.packageTotal.volume?.value
              ? {
                  value: filtersHash.merchandise.value.packageTotal.volume.value,
                  unit: 'cbm',
                }
              : null,
            weight: filtersHash.merchandise?.value.packageTotal.weight?.value
              ? {
                  value: filtersHash.merchandise.value.packageTotal.weight.value,
                  unit: 'kg',
                }
              : null,
            packageNumber: filtersHash.merchandise?.value.packageTotal.packageNumber,
          }
          merchandiseContent = filtersHash.merchandise?.value.packageTotal.content
            .filter((m: CommonMerchandiseDetails) => isMerchandiseDetailsPresent(m))
            .map((m: any) => ({
              ...m,
              commercialValue: m.commercialValue?.amount
                ? {
                    ...m.commercialValue,
                    currencyCode: m.commercialValue?.currencyCode?.label,
                  }
                : null,

              hazardousGoods: {
                ...m.hazardousGoods,
                hazardousClass: m.hazardousGoods?.hazardousClass
                  ? parseInt(m.hazardousGoods.hazardousClass.value, 10)
                  : null,
                packingGroup: m.hazardousGoods?.packingGroup
                  ? parseInt(m.hazardousGoods?.packingGroup.value, 10)
                  : null,
                weight: m.hazardousGoods?.weight?.value
                  ? {
                      value: m.hazardousGoods?.weight.value,
                      unit: 'kg',
                    }
                  : null,
              },
            }))
          break
        case MERCHANDISE_TYPE_CONTAINER:
          merchandiseContent = filtersHash.merchandise?.value.container.map((c: any) => ({
            ...c,
            containerType: c.containerType ? parseInt(c.containerType.value, 10) : null,
            products: c.products
              ?.filter((p: BookingMerchandiseProduct) => isMerchandiseProductPresent(p))
              .map((p: any) => ({
                ...p,
                commercialValue: p.commercialValue?.amount
                  ? {
                      ...p.commercialValue,
                      currencyCode: p.commercialValue?.currencyCode?.label,
                    }
                  : null,
                weight: p.weight?.value
                  ? {
                      value: p.weight?.value,
                      unit: 'kg',
                    }
                  : null,
                volume: p.volume?.value
                  ? {
                      value: p.volume?.value,
                      unit: 'cbm',
                    }
                  : null,
                hazardousGoods: {
                  ...p.hazardousGoods,
                  hazardousClass: p.hazardousGoods?.hazardousClass
                    ? parseInt(p.hazardousGoods.hazardousClass.value, 10)
                    : null,
                  packingGroup: p.hazardousGoods?.packingGroup
                    ? parseInt(p.hazardousGoods?.packingGroup.value, 10)
                    : null,
                  weight: p.hazardousGoods?.weight?.value
                    ? {
                        value: p.hazardousGoods?.weight.value,
                        unit: 'kg',
                      }
                    : null,
                },
              })),
          }))

          break
        case MERCHANDISE_TYPE_PACKAGE:
          merchandiseContent = filtersHash.merchandise?.value.package.map((p: any) => ({
            ...p,
            packageType: p.packageType ? parseInt(p.packageType.value, 10) : null,
            commercialValue: p.commercialValue?.amount
              ? {
                  ...p.commercialValue,
                  currencyCode: p.commercialValue?.currencyCode?.label,
                }
              : null,
            weight: p.weight?.value
              ? {
                  value: p.weight?.value,
                  unit: 'kg',
                }
              : null,
            width: p.width?.value
              ? {
                  value: p.width?.value,
                  unit: 'cm',
                }
              : null,
            length: p.length?.value
              ? {
                  value: p.length?.value,
                  unit: 'cm',
                }
              : null,
            height: p.height?.value
              ? {
                  value: p.height?.value,
                  unit: 'cm',
                }
              : null,
            hazardousGoods: {
              ...p.hazardousGoods,
              hazardousClass: p.hazardousGoods?.hazardousClass
                ? parseInt(p.hazardousGoods.hazardousClass.value, 10)
                : null,
              packingGroup: p.hazardousGoods?.packingGroup
                ? parseInt(p.hazardousGoods?.packingGroup.value, 10)
                : null,
              weight: p.hazardousGoods?.weight?.value
                ? {
                    value: p.hazardousGoods?.weight.value,
                    unit: 'kg',
                  }
                : null,
            },
          }))
          break
      }

      return {
        merchandise: {
          ...merchandiseAttributes,
          merchandiseType: filtersHash.merchandise?.value.merchandiseType,
          shippingMode: filtersHash.merchandise?.value.shippingMode,
          content: merchandiseContent,
        },
      }
    },
  })

  const rateConfirmationFilter = useFilter({
    name: BOOKING_FIELD_NAMES.RATE_CONFIRMATION,
    type: 'checkbox',
    defaultValue: rateConfirmation,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.RATE_CONFIRMATION),
  })
  const rateConfirmationCategory = useCategory({
    name: 'rateConfirmation',
    filters: [rateConfirmationFilter],
    toQueryParams: (_filters, filtersHash) => ({
      rateConfirmation: filtersHash.rateConfirmation.value,
    }),
  })

  const user = useCurrentUser()
  const defaultKeyContact = !isEdit ? [user.profile.email] : null
  const keyContactsFilter = useFilter({
    name: BOOKING_FIELD_NAMES.KEY_CONTACTS,
    type: 'tags',
    defaultValue: assignedUsers
      ? assignedUsers.map(({ email }: { email: string }) => email)
      : defaultKeyContact,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.KEY_CONTACTS),
  })
  const keyContactsCategory = useCategory({
    name: 'keyContacts',
    filters: [keyContactsFilter],
    toQueryParams: (_filters, filtersHash) => ({
      assignedUsers: filtersHash.keyContacts?.value.map((email: string) => ({ email })),
    }),
  })

  const commentsFilter = useFilter({
    name: BOOKING_FIELD_NAMES.COMMENTS,
    type: 'text',
    defaultValue: comments,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.COMMENTS),
  })

  const forwarderCommentFilter = useFilter({
    name: BOOKING_FIELD_NAMES.FORWARDER_COMMENT,
    type: 'text',
    defaultValue: forwarderComment,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.FORWARDER_COMMENT),
  })

  const commentsCategory = useCategory({
    name: 'comments',
    filters: [commentsFilter, forwarderCommentFilter],
    toQueryParams: (filters) =>
      filters.reduce((acc, filter) => ({ ...acc, [filter.name]: filter.value }), {}),
  })

  const carrierFilter = useFilter({
    name: BOOKING_FIELD_NAMES.CARRIER,
    type: 'select',
    defaultValue: carrier
      ? {
          value: carrier.id,
          createValue: carrier.id !== -1 ? undefined : carrier.name,
          label:
            carrier.id !== -1
              ? carrier.name
              : `${t('bookings.select.options.unknownCarrier')} (${carrier.name})`,
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.CARRIER),
  })

  const vesselsFilter = useFilter({
    name: BOOKING_FIELD_NAMES.VESSELS,
    type: 'multiselect',
    defaultValue: isAnyArray(vessels)
      ? vessels?.map((vessel) => ({
          value: isPresent(vessel.id) ? vessel.id : uuid4(),
          createValue: isPresent(vessel.id) ? undefined : vessel.name,
          label: isPresent(vessel.id)
            ? vessel.name
            : `${t('bookings.select.options.unknownVessel')} (${vessel.name})`,
        }))
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.VESSELS),
  })

  const bookingNumberFilter = useFilter({
    name: BOOKING_FIELD_NAMES.BOOKING_NUMBER,
    type: 'text',
    defaultValue: bookingNumber,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.BOOKING_NUMBER),
  })

  const masterBlFilter = useFilter({
    name: BOOKING_FIELD_NAMES.MASTER_BL,
    type: 'text',
    defaultValue: masterBl,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.MASTER_BL),
  })
  const voyageNumbersFilter = useFilter({
    name: BOOKING_FIELD_NAMES.VOYAGE_NUMBERS,
    type: 'tags',
    defaultValue: isAnyArray(voyageNumbers) ? voyageNumbers : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.VOYAGE_NUMBERS),
  })
  const flightNumbersFilter = useFilter({
    name: BOOKING_FIELD_NAMES.FLIGHT_NUMBERS,
    type: 'tags',
    defaultValue: isAnyArray(flightNumbers) ? flightNumbers : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.FLIGHT_NUMBERS),
  })
  const shipmentAttributesFilter = useFilter({
    name: BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES,
    type: 'custom',
    defaultValue: isAnyArray(shipmentAttributes)
      ? shipmentAttributes?.map(({ containerType, ...other }: ShipmentAttribute) => ({
          ...other,
          containerType: {
            label: containerType,
            value: Object.keys(s('containerTypes')).find(
              (key) => s('containerTypes')[key] === containerType
            ),
          },
        }))
      : [],
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.SHIPMENT_ATTRIBUTES),
    customValidation: (value: ShipmentAttribute[], setError) => {
      let isValid = true
      const errors: any = value.map(() => ({}))
      if (transportTypeFilter.value === TRANSPORT_TYPE_SEA) {
        value.forEach((v: ShipmentAttribute, index: number) => {
          if (!isInputPresent(v.containerNumber)) {
            isValid = false
            return
          }
          if (!validateContainerNumber(v.containerNumber)) {
            isValid = false
            errors[index].trackingRef = `${t('errors.validation.trackingRef')} ${
              trackingRefFormatExampleMap[TRANSPORT_TYPE_SEA]
            }`
          }
        })
      }

      if (transportTypeFilter.value === TRANSPORT_TYPE_AIR) {
        value.forEach((v: ShipmentAttribute, index: number) => {
          if (!isInputPresent(v.masterAwb)) {
            isValid = false
            return
          }
          if (!validateMasterAwb(v.masterAwb)) {
            isValid = false
            errors[index].trackingRef = `${t('errors.validation.trackingRef')} ${
              trackingRefFormatExampleMap[TRANSPORT_TYPE_AIR]
            }`
          }
        })
      }

      value.forEach((v: ShipmentAttribute, index: number) => {
        if (!isInputPresent(v.weight?.value)) {
          return
        }

        if ((v.weight?.value as any) < BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.SHIPMENT_WEIGHT) {
          isValid = false
          errors[index].weight = `${t('errors.validation.greaterOrEqual')} ${
            BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.SHIPMENT_WEIGHT
          }`
        }

        if ((v.weight?.value as any) > BOOKING_FIELD_VALIDATIONS.MAXIMAL_VALUES.SHIPMENT_WEIGHT) {
          isValid = false
          errors[index].weight = `${t('errors.validation.smallerOrEqual')} ${
            BOOKING_FIELD_VALIDATIONS.MAXIMAL_VALUES.SHIPMENT_WEIGHT
          }`
        }
      })

      setError(errors)
      return isValid
    },
  })

  const transportDetailsCategory = useCategory({
    name: 'transportDetails',
    filters: [
      carrierFilter,
      bookingNumberFilter,
      masterBlFilter,
      voyageNumbersFilter,
      flightNumbersFilter,
      vesselsFilter,
      shipmentAttributesFilter,
    ],
    toQueryParams: (filters) => {
      const isTransportTypeSea = transportTypeFilter.value === TRANSPORT_TYPE_SEA
      const formatParamsMapping: any = {
        carrier: (filterValue: TFilterValue<'select'>) => ({
          carrier: {
            id: filterValue?.value || null,
            info: filterValue?.createValue,
          },
        }),
        bookingNumber: (filterValue: TFilterValue<'text'>) => ({
          bookingNumber: filterValue,
        }),
        masterBl: (filterValue: TFilterValue<'text'>) => ({
          masterBl: filterValue,
        }),
        voyageNumbers: (filterValue: TFilterValue<'text'>) =>
          isTransportTypeSea && {
            voyageNumbers: filterValue,
          },
        vessels: (filterValue: TFilterValue<'multiselect'>) => ({
          vesselImos: (filterValue || [])
            .filter(({ createValue }) => !isPresent(createValue))
            .map(({ value }: { value: Tvalue }) => value),
          vesselInfo: (filterValue || [])
            .filter(({ createValue }) => isPresent(createValue))
            .map(({ createValue }: { createValue?: string }) => createValue),
        }),
        flightNumbers: (filterValue: TFilterValue<'text'>) => ({
          flightNumbers: filterValue,
        }),
        shipmentAttributes: (filterValue: TFilterValue<'custom'>) => ({
          shipmentAttributes: filterValue.map(({ containerType, ...other }: ShipmentAttribute) => ({
            ...other,
            containerType: (containerType as SelectValue)?.label,
          })),
        }),
      }
      return filters.reduce(
        (acc, filter) => ({
          ...acc,
          ...formatParamsMapping[filter.name](filter.value),
        }),
        {}
      )
    },
  })

  const rateCurrencyFilter = useFilter({
    name: BOOKING_FIELD_NAMES.RATE_CURRENCY,
    type: 'select',
    defaultValue: proposal?.currencyCode
      ? {
          value: proposal.currencyCode,
          label: proposal.currencyCode || '',
        }
      : null,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.RATE_CURRENCY),
  })

  const rateAmountFilter = useFilter({
    name: BOOKING_FIELD_NAMES.RATE_AMOUNT,
    type: 'number',
    defaultValue: proposal?.rate,
    disabled: isFieldDisabled(BOOKING_FIELD_NAMES.RATE_AMOUNT),
  })

  // The rate is only editable by the forwarder, and the queryParams are computed only if one of the filters is edited
  const rateCategory = useCategory({
    name: 'rate',
    filters: [rateAmountFilter, rateCurrencyFilter],
    toQueryParams: (_filters, filtersHash) => ({
      // Here we make sure that in case of an edit of either amount or currencyCode,
      // both values are set. Otherwise, the unedited filter is sent in the params as undefined
      rate: {
        amount: filtersHash.rateAmount?.value || rateAmountFilter.value,
        currencyCode: filtersHash.rateCurrency?.value?.label || rateCurrencyFilter.value?.label,
      },
    }),
    isSemanticValid: (filtersHash) =>
      (isPresent(filtersHash.rateAmount.value) && isPresent(filtersHash.rateCurrency.value)) ||
      (isNull(filtersHash.rateAmount.value) && isNull(filtersHash.rateCurrency.value)),
  })

  const filters = {
    clientReferenceFilter,
    clientBookingNumberFilter,
    forwarderReferenceFilter,
    customReferencesFilter,
    shipperFilter,
    forwarderFilter,
    consignorFilter,
    consigneeFilter,
    incotermsFilter,
    incotermsLocationFilter,
    transportTypeFilter,
    preCarriageFilter,
    polFilter,
    polPtdFilter,
    polPtaFilter,
    podFilter,
    podPtaFilter,
    podPtdFilter,
    onCarriageFilter,
    ptdFilter,
    ptaFilter,
    vgmCutOffDateFilter,
    vesselCutOffDateFilter,
    customFieldsFilter,
    merchandiseFilter,
    rateConfirmationFilter,
    commentsFilter,
    forwarderCommentFilter,
    keyContactsFilter,
    carrierFilter,
    bookingNumberFilter,
    masterBlFilter,
    voyageNumbersFilter,
    flightNumbersFilter,
    vesselsFilter,
    shipmentAttributesFilter,
    transportPlanFilter,
    transshipmentsFilter,
    rateAmountFilter,
    rateCurrencyFilter,
  }
  const categories = [
    referencesCategory,
    customReferencesCategory,
    partiesCategory,
    incotermsCategory,
    merchandiseCategory,
    rateConfirmationCategory,
    commentsCategory,
    keyContactsCategory,
    routingCategory,
    transportDetailsCategory,
    miscellaneousCategory,
    rateCategory,
  ]

  const { isValid, isEdited, queryParams, editedQueryParams } = useForm({ categories })

  const wontTriggerReview = Object.values(filters)
    .filter((f) => f.isEdited)
    .every(({ name }) => INFORMATIVE_FIELD_NAMES.some((fieldName) => fieldName === name))

  return {
    filters,
    isValid,
    isEdited,
    queryParams,
    editedQueryParams,
    wontTriggerReview,
  }
}

export default useBookingForm
