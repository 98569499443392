import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Tabs, { Tab } from 'components/tabs'
import ModalHorizontal from 'components/modal_horizontal'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { StyledModalProfileForm } from 'components/modal_profile/style'
import InformationForm from 'components/modal_profile/components/information_form'
import PasswordForm from 'components/modal_profile/components/password_form'
import LanguageForm from 'components/modal_profile/components/language_form'
import { LANGUAGE_TAB } from 'constants/product_fruit_anchors'

import { isSSOUser } from 'views/iam/slices/ssoSlice'
import { TEST_ID_USERS_SETTINGS_PASSWORD_TAB } from 'tests/e2e/test_ids'

const ModalProfile = memo(() => {
  const user = useCurrentUser()
  const { t } = useTranslation()
  const isAnSSOUser = useSelector(isSSOUser)

  return (
    <ModalHorizontal title={t('profile.title')} size='large' modalName='profile'>
      <StyledModalProfileForm>
        <Tabs>
          <Tab
            id='information'
            label={t('profile.tabs.information.title')}
            key='modal-profile-tab-information'
          >
            <InformationForm user={user} />
          </Tab>
          {isAnSSOUser ? null : (
            <Tab
              testId={TEST_ID_USERS_SETTINGS_PASSWORD_TAB}
              id='password'
              label={t('profile.tabs.password.title')}
              key='modal-profile-tab-password'
            >
              <PasswordForm />
            </Tab>
          )}
          <Tab
            dataComponent={LANGUAGE_TAB}
            id='language'
            label={t('profile.tabs.locale.title')}
            key='modal-profile-tab-language'
          >
            <LanguageForm user={user} />
          </Tab>
        </Tabs>
      </StyledModalProfileForm>
    </ModalHorizontal>
  )
})

export default ModalProfile
