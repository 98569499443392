import { InferType, object, ref, string } from 'yup'

import { patternPassword } from 'services/helpers/form_validation'

export const passwordSchema = object({
  password: string().required('profile.tabs.password.form.validation.required'),
  newPassword: string()
    .required('profile.tabs.password.form.validation.required')
    .min(12, 'profile.tabs.password.form.validation.passwordMinLength')
    .matches(patternPassword, {
      message: 'profile.tabs.password.form.validation.passwordComplexity',
    }),
  confirmNewPassword: string()
    .required('profile.tabs.password.form.validation.required')
    .oneOf([ref('newPassword')], 'profile.tabs.password.form.validation.passwordsDontMatch'),
}).required()
export type PasswordData = InferType<typeof passwordSchema>
