export const UNSTARTED = 'unstarted'
export const SUBSCRIPTION_PENDING = 'subscription_pending'
export const SUBSCRIPTION_REJECTED = 'subscription_rejected'
export const SUBSCRIPTION_FULFILLED = 'subscription_fulfilled'
export const TRUSTED_ROUTES_POOLING_RUNNING = 'trusted_routes_pooling_running'
export const TRUSTED_ROUTES_POOLING_FULFILLED = 'trusted_routes_pooling_fulfilled'
export const TRUSTED_ROUTES_POOLING_TIMEOUT = 'trusted_routes_pooling_timeout'

export const TRUSTED_ROUTES_STATUSES = [
  UNSTARTED,
  SUBSCRIPTION_PENDING,
  SUBSCRIPTION_REJECTED,
  SUBSCRIPTION_FULFILLED,
  TRUSTED_ROUTES_POOLING_RUNNING,
  TRUSTED_ROUTES_POOLING_FULFILLED,
  TRUSTED_ROUTES_POOLING_TIMEOUT,
] as const

export type TrustedRoutesStatus = typeof TRUSTED_ROUTES_STATUSES[number]
