import { useEffect } from 'react'

import useCurrentUser from 'views/iam/hooks/use_current_user'
import { refreshAccessToken } from 'views/iam/slices/iamSlice'
import useAppDispatch from 'services/hooks/use_app_dispatch'

const DELAY_BETWEEN_REFRESH_IN_MILLISECONDS = 1000 * 20 * 60

const useRefreshTokenInterval = () => {
  const user = useCurrentUser()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user.isSignedIn) return

    const interval = setInterval(async () => {
      dispatch(refreshAccessToken())
    }, DELAY_BETWEEN_REFRESH_IN_MILLISECONDS)

    // eslint-disable-next-line
    return () => clearInterval(interval)
  }, [user.isSignedIn, dispatch])
}

export default useRefreshTokenInterval
