import React, { useState, useMemo, FC } from 'react'
import { useTranslation } from 'react-i18next'

import DateHelper from 'services/helpers/date_helper'
import useMilestoneTimes from 'services/hooks/use_milestone_times'
import useTracker from 'services/analytics/hooks/use_tracker'
import { isPresent } from 'services/helpers/values'

import { Metadata } from 'views/shipment/models'
import buildMilestoneParams from 'views/shipment/helpers/milestone'
import useUserCan from 'views/iam/hooks/use_user_can'

import { TIMELINE_MAIN_MILESTONES } from 'constants/milestones'
import { EVENT_UPDATE, SEGMENT_TIMELINE_MILESTONES_UPDATE } from 'constants/permissions'

import {
  StyledMilestone,
  StyledMilestoneHead,
  StyledMilestoneDetail,
  StyledMilestoneHiddenDetails,
  StyledMilestoneDetails,
  StyledMilestoneMetadata,
  StyledMilestoneDetailsHead,
  StyledTagDiffTime,
  StyledMilestoneHeadArrow,
  StyledMilestoneHeadIcon,
  StyledUpdateTimeButton,
} from 'components/timeline/style'
import Button from 'components/button'
import TagDiffTime from 'components/tag_diff_time'
import Icon from 'components/icon'
import IconTooltip from 'components/icon_tooltip'

import type { DatesMilestoneType } from 'types/milestones'
import type { Milestone } from 'views/shipment/types/shipment'

interface TimelineMilestoneDateProps {
  time?: string
  editedByAdmin: boolean
  newTime?: string
  canEditMilestone: boolean
  proposalDateTestId?: string
  dateTestId?: string
}

const TimelineMilestoneTime: FC<TimelineMilestoneDateProps> = ({
  time,
  editedByAdmin,
  newTime,
  canEditMilestone,
  proposalDateTestId,
  dateTestId,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {isPresent(time) && (
        <StyledMilestoneDetail $withNewTime={isPresent(newTime)} data-testid={dateTestId}>
          {new DateHelper(time).toLocale({ hours: true })}
          {editedByAdmin && canEditMilestone && (
            <IconTooltip
              content={t('shipments.dateDeclarationModal.adminDate')}
              placement='right'
              variant='admin_icon'
              size='nowrap'
            />
          )}
        </StyledMilestoneDetail>
      )}
      {!isPresent(time) && editedByAdmin && canEditMilestone && (
        <StyledMilestoneDetail>
          <IconTooltip
            content={t('shipments.dateDeclarationModal.adminRemovedDate')}
            placement='right'
            variant='admin_icon'
            size='nowrap'
          />
        </StyledMilestoneDetail>
      )}
      {newTime && (
        <StyledMilestoneDetail $primary data-testid={proposalDateTestId}>
          {new DateHelper(newTime).toLocale({ hours: true })}
        </StyledMilestoneDetail>
      )}
    </>
  )
}

export interface TimelineMilestoneProps {
  milestoneName?: string
  type: DatesMilestoneType
  plannedTime?: string
  estimatedTime?: string
  actualTime?: string
  newTime?: string
  metadata?: {
    vessel?: string | null
    flight?: string | null
    service?: string | null
    voyage?: string | null
    vesselImo?: string | null
    vesselName?: string | null
  }
  isMilestoneDataOverridden?: Milestone['isMilestoneDataOverridden']
  openModal?: () => void
  editMode?: boolean
  dateTestId?: string
  proposalDateTestId?: string
  testId?: string
  withDiffTime?: boolean
  defaultMetadataVisible?: boolean
}
const TimelineMilestone: FC<TimelineMilestoneProps> = ({
  type,
  plannedTime,
  estimatedTime,
  actualTime,
  newTime,
  metadata,
  isMilestoneDataOverridden,
  openModal,
  editMode,
  dateTestId,
  proposalDateTestId,
  testId,
  withDiffTime,
  milestoneName,
  defaultMetadataVisible = false,
}) => {
  const userCan = useUserCan()
  const canEditDepartureArrivalMilestone = userCan(SEGMENT_TIMELINE_MILESTONES_UPDATE)
  const canEditMilestone = userCan(EVENT_UPDATE)

  const { isDepartureOrArrival } = buildMilestoneParams(type)

  const shouldDisplayUpdateTimeButton = useMemo(() => {
    if (!editMode) return false
    if (canEditDepartureArrivalMilestone && isDepartureOrArrival) return true
    return canEditMilestone
  }, [editMode, canEditDepartureArrivalMilestone, canEditMilestone, isDepartureOrArrival])

  const hasHiddenDetails =
    isPresent(plannedTime) || isPresent(metadata) || shouldDisplayUpdateTimeButton

  const [metadataVisible, setMetadataVisible] = useState(defaultMetadataVisible)

  const { track } = useTracker()
  const isCheck = isPresent(actualTime)
  const { t } = useTranslation()
  const { time, diffTime } = useMilestoneTimes({
    actual: actualTime,
    estimated: estimatedTime,
    planned: plannedTime,
  })

  const times = ['actualTime', 'estimatedTime'] as const

  const dateEditedByAdmin = times.some(
    (dateType) => isMilestoneDataOverridden && isMilestoneDataOverridden[dateType]
  )

  return (
    <StyledMilestone>
      <div>
        <StyledMilestoneHead
          $checked={isCheck}
          onClick={() => {
            if (hasHiddenDetails) {
              setMetadataVisible(!metadataVisible)
              if (!metadataVisible) {
                track('Shipment / milestone', {
                  type: milestoneName || t(`static.milestones.${type}`),
                })
              }
            }
          }}
          $hasHiddenDetails={hasHiddenDetails}
          $open={metadataVisible}
        >
          <StyledMilestoneHeadIcon
            as={Icon}
            width={11}
            height={11}
            name={isCheck ? 'checkmark' : 'arrow'}
            testId={`${testId}-head-icon`}
          />
          {milestoneName || t(`static.milestones.${type}`)}
          {hasHiddenDetails && <StyledMilestoneHeadArrow as={Icon} name='arrow_bottom_outline' />}
        </StyledMilestoneHead>
        <div>
          <StyledMilestoneDetails>
            <StyledMilestoneDetailsHead>
              <TimelineMilestoneTime
                time={actualTime || estimatedTime}
                editedByAdmin={dateEditedByAdmin}
                newTime={newTime}
                canEditMilestone={canEditMilestone}
                proposalDateTestId={proposalDateTestId}
                dateTestId={dateTestId}
              />
            </StyledMilestoneDetailsHead>

            <StyledMilestoneHiddenDetails $visible={metadataVisible}>
              {(metadata || plannedTime) &&
                new Metadata({ planned: plannedTime, ...metadata }).translation.map(
                  ({ attribute, text, icon }) => (
                    <StyledMilestoneMetadata key={`milestone-detail-${attribute}`}>
                      <Icon width={16} height={16} name={icon} />
                      {text}
                    </StyledMilestoneMetadata>
                  )
                )}
              {shouldDisplayUpdateTimeButton && (
                <StyledUpdateTimeButton
                  as={Button}
                  variant='outline'
                  icon='calendar'
                  text={t('shipments.dateDeclarationModal.openButtonText')}
                  onClick={openModal}
                />
              )}
            </StyledMilestoneHiddenDetails>
          </StyledMilestoneDetails>
        </div>
      </div>
      {withDiffTime && TIMELINE_MAIN_MILESTONES.includes(type) && isPresent(diffTime) && (
        <StyledTagDiffTime
          as={TagDiffTime}
          time={time}
          timeTo={diffTime}
          testId={`${testId}-diff-time`}
        />
      )}
    </StyledMilestone>
  )
}

export default TimelineMilestone
