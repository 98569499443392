import React, { useEffect, useRef } from 'react'

import { Controller, useForm } from 'react-hook-form'

import FiltersSidebar from 'components/filters_sidebar'
import Input from 'components/input'
import InputCheckbox from 'components/input_checkbox'
import S from 'views/atlas/addresses/components/filters/style'
import {
  StatusTypeFilter,
  fetchDataProviders,
  fetchCarriers,
  saveAddressesActiveFilters,
} from 'views/atlas/slices/address'
import MultiSelect from 'components/multiselect'
import { fetchHubsSummary } from 'views/atlas/slices/hub'
import useTimeout from 'services/hooks/use_timeout'
import useAppDispatch from 'services/hooks/use_app_dispatch'

export interface AddressesForm {
  search: string
  statusTypeFilters: Record<StatusTypeFilter, boolean>
  dataProviders: { value: string; label: string }[]
  carriers: { value: string; label: string }[]
  hubs: { value: string; label: string }[]
}

const AddressFilters: React.FC = () => {
  const dispatch = useAppDispatch()
  const { onTimeout } = useTimeout({ timeout: 500 })

  const { handleSubmit, reset, register, control, watch } = useForm<AddressesForm>({
    defaultValues: {
      search: '',
      statusTypeFilters: {
        pending: true,
        rejected: false,
        automaticallyResolved: false,
        manuallyResolved: false,
      },
      dataProviders: [],
      carriers: [],
      hubs: [],
    },
  })

  const onSubmit = (data: AddressesForm) => {
    dispatch(
      saveAddressesActiveFilters({
        search: data.search,
        statusTypeFilters: data.statusTypeFilters,
        dataProviders: data.dataProviders.map(({ value }) => value),
        carriers: data.carriers.map(({ value }) => value),
        hubs: data.hubs.map(({ value }) => value),
      })
    )
  }

  const values = watch()
  const firstUpdate = useRef<boolean>(true)
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    onTimeout(handleSubmit(onSubmit))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(values)])

  const apply = () => {
    handleSubmit(onSubmit)()
  }

  const clear = () => {
    reset()
    apply()
  }

  return (
    <FiltersSidebar onClear={clear} onApply={apply}>
      <FiltersSidebar.Section title='Search' defaultOpened>
        <Input type='text' placeholder='Raw address' {...register('search')} />
      </FiltersSidebar.Section>

      <FiltersSidebar.Section title='Status'>
        <S.TagCheckboxContainer>
          <Controller
            name='statusTypeFilters.pending'
            control={control}
            render={({ field }) => (
              <InputCheckbox
                id={field.name}
                name={field.name}
                text='pending'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
                color='warn'
                asTag
              />
            )}
          />
          <Controller
            name='statusTypeFilters.automaticallyResolved'
            control={control}
            render={({ field }) => (
              <InputCheckbox
                id={field.name}
                name={field.name}
                text='automatically resolved'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
                color='primary'
                asTag
              />
            )}
          />
          <Controller
            name='statusTypeFilters.manuallyResolved'
            control={control}
            render={({ field }) => (
              <InputCheckbox
                id={field.name}
                name={field.name}
                text='manually resolved'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
                color='success'
                asTag
              />
            )}
          />
          <Controller
            name='statusTypeFilters.rejected'
            control={control}
            render={({ field }) => (
              <InputCheckbox
                id={field.name}
                name={field.name}
                text='rejected'
                checked={field.value}
                onChange={({ target: { checked } }) => field.onChange(checked)}
                color='error'
                asTag
              />
            )}
          />
        </S.TagCheckboxContainer>
      </FiltersSidebar.Section>
      <FiltersSidebar.Section title='Data provider'>
        <Controller
          name='dataProviders'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchDataProviders({ value })}
              fetchOnFocus={() => fetchDataProviders({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map((option: string) => ({
                  value: option,
                  label: option,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>
      <FiltersSidebar.Section title='Carrier'>
        <Controller
          name='carriers'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchCarriers({ value })}
              fetchOnFocus={() => fetchCarriers({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map((option: string) => ({
                  value: option,
                  label: option,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>
      <FiltersSidebar.Section title='Hub'>
        <Controller
          name='hubs'
          control={control}
          render={({ field }) => (
            <MultiSelect
              name={field.name}
              isSearchable
              async
              value={field.value}
              onChange={({ value }) => field.onChange(value)}
              fetch={({ value }) => fetchHubsSummary({ value })}
              fetchOnFocus={() => fetchHubsSummary({ value: null })}
              fetchedOptionsFormat={(options) =>
                options.map(({ name, token }: { name: string; token: string }) => ({
                  value: token,
                  label: name,
                }))
              }
            />
          )}
        />
      </FiltersSidebar.Section>
    </FiltersSidebar>
  )
}

export default AddressFilters
