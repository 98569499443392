import { createAsyncThunk } from '@reduxjs/toolkit'

import { InternalClient } from 'services/api/clients'

import type { RootState } from 'services/store/store'
import type { Locales } from 'views/locales/types'

// eslint-disable-next-line import/prefer-default-export
export const fetchLocales = createAsyncThunk<Locales, undefined, { state: RootState }>(
  'locales/fetchLocales',
  async () => InternalClient.get('/locales').then((r) => r.data)
)
