import { useSelector } from 'react-redux'

import useCurrentUser from 'views/iam/hooks/use_current_user'

import { selectCurrentUserOrganizationLogo } from 'views/iam/slices/iamSlice'

const useCurrentOrganization = () => {
  const user = useCurrentUser()

  return {
    ...user.organization,
    isWhiteLabel: !!useSelector(selectCurrentUserOrganizationLogo),
  }
}

export default useCurrentOrganization
