import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Form from 'components/form'
import Grid from 'components/grid'
import InputCheckbox from 'components/input_checkbox'
import NotifMessage from 'components/notif_message'
import Input from 'components/input'
import Select from 'components/select'

import {
  TEST_ID_BOOKING_FORM_ASK_RATE_CONFIRMATION,
  ARIA_LABEL_BOOKING_FORM_RATE_PROPOSAL_CURRENCY,
  TEST_ID_BOOKING_FORM_RATE_PROPOSAL_AMOUNT,
} from 'tests/e2e/test_ids'

import useStaticLocales from 'views/locales/hooks/use_static_locales'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const RateConfirmation: FC<BookingFormProps> = ({ filters }) => {
  const { rateConfirmationFilter, rateAmountFilter, rateCurrencyFilter } = filters
  const { t } = useTranslation()
  const { features } = useOrganizationCan()

  const { fromStaticToSelectOptions } = useStaticLocales()

  const orgaWithLegacyWorkflow = features(WITH_BOOKING_OLD_WORKFLOW)

  return (
    <Form.Group title={t('bookings.rateConfirmation.title')}>
      <Grid columns={2} columnsTablet={1}>
        <Grid.Row>
          <Grid.Column>
            <InputCheckbox
              text={t('bookings.rateConfirmation.askFor')}
              name={rateConfirmationFilter.name}
              checked={rateConfirmationFilter.value}
              onChange={rateConfirmationFilter.onChange}
              disabled={rateConfirmationFilter.isDisabled}
              testId={TEST_ID_BOOKING_FORM_ASK_RATE_CONFIRMATION}
            />
            <NotifMessage
              type='tip'
              text={t(
                `bookings.tooltips.${
                  orgaWithLegacyWorkflow ? 'rateConfirmationLegacy' : 'rateConfirmation'
                }`
              )}
            />
          </Grid.Column>

          <Grid.Column>
            <Input
              label={t('bookings.rateConfirmation.forwarderRateProposal')}
              type='number'
              min={0}
              step='0.01'
              placeholder={t('bookings.merchandise.amount')}
              value={rateAmountFilter.value}
              onChange={rateAmountFilter.onChange}
              name={rateAmountFilter.name}
              disabled={rateAmountFilter.isDisabled}
              testId={TEST_ID_BOOKING_FORM_RATE_PROPOSAL_AMOUNT}
            />

            <Select
              placeholder={t('bookings.merchandise.currency')}
              options={fromStaticToSelectOptions('currencies')}
              value={rateCurrencyFilter.value}
              onChange={rateCurrencyFilter.onChange}
              name={rateCurrencyFilter.name}
              isDisabled={rateCurrencyFilter.isDisabled}
              ariaLabel={ARIA_LABEL_BOOKING_FORM_RATE_PROPOSAL_CURRENCY}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default RateConfirmation
