import React, { FC, useContext, useMemo } from 'react'

import { SHIPMENT_VIEW_CONDENSED } from 'constants/shipments'

import ShipmentViewContext from 'views/shipments/contexts/shipment_view_context'

import ShipmentListItemSkeleton from 'components/shipment_list_item/skeleton'
import StyledShipmentList from 'components/shipment_list/style'

export interface ShipmentListSkeletonProps {
  count?: number
}

const ShipmentListSkeleton: FC<ShipmentListSkeletonProps> = ({ count } = { count: 5 }) => {
  const { view } = useContext(ShipmentViewContext)
  const isCondensed = useMemo(() => view === SHIPMENT_VIEW_CONDENSED, [view])

  return (
    <StyledShipmentList $condensed={isCondensed}>
      {Array.from(Array(count).keys()).map((number) => (
        <ShipmentListItemSkeleton key={`shipment-list-skeleton-${number}`} />
      ))}
    </StyledShipmentList>
  )
}

export default ShipmentListSkeleton
