import styled, { css } from 'styled-components'

import { tagSmall, tagMedium } from 'styles/utils/texts'

import { ReactComponent as CrossIcon } from 'assets/icons/close.svg'

import type { TagColor, TagSize } from 'components/tag'

export const StyledSpan = styled.span``

export const StyledCrossButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  margin-right: -5px;
`

export const StyledCrossIcon = styled(CrossIcon)`
  width: 8px;
  height: 8px;
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledTag = styled.div<{ $highlighted?: boolean; $color: TagColor; $size: TagSize }>`
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    let tagBgColor = null
    let spanColor = null
    let tagBorderColor = null

    if (props.$color === 'white') {
      if (props.$highlighted === false) {
        tagBgColor = 'transparent'
        spanColor = 'white'
        tagBorderColor = 'white'
      } else if (props.$highlighted === true) {
        tagBgColor = 'white'
        spanColor = props.theme.textLight
      }
    } else if (props.theme[props.$color]) {
      if (props.$highlighted === false) {
        tagBgColor = props.theme[`${props.$color}10`]
        spanColor = props.theme[props.$color]
      } else if (props.$highlighted === true) {
        tagBgColor = props.theme[props.$color]
        spanColor = 'white'
      }
    }

    let tagPadding = null
    let tagMinWidth = null
    let spanStyle = null

    if (props.$size === 'small') {
      tagPadding = '3px 8px'
      spanStyle = tagSmall
    } else if (props.$size === 'medium') {
      tagPadding = '5px 10px'
      spanStyle = tagSmall
    } else if (props.$size === 'large') {
      tagPadding = '8px 10px'
      spanStyle = tagMedium
      tagMinWidth = '70px'
    }

    return css`
      ${tagBgColor && `background-color: ${tagBgColor}`};
      ${tagBorderColor && `border: 1px solid ${tagBorderColor}`};
      ${tagPadding && `padding: ${tagPadding}`};
      ${tagMinWidth && `min-width: ${tagMinWidth}`};

      ${StyledSpan} {
        ${spanStyle}
        color: ${spanColor};
      }
    `
  }}
`
