import { createAsyncThunk } from '@reduxjs/toolkit'

import { Country } from 'views/atlas/types/country'
import { Tvalue } from 'components/select'
import { RootState } from 'services/store/store'
import useUrlParams from 'services/api/hooks/use_url_params'
import { selectCurrentUser } from 'views/iam/slices/iamSlice'
import { AtlasClient } from 'services/api/clients'
import onError from 'services/api/error'

const fetchCountries = createAsyncThunk<Country[], { value: Tvalue }, { state: RootState }>(
  'atlas/countries/fetchCountries',
  async ({ value }: { value: Tvalue }, thunkAPI) => {
    const { getState } = thunkAPI
    const url = useUrlParams('/countries', { q: value })
    const user = selectCurrentUser(getState())

    return AtlasClient.get(url, { headers: { Authorization: `Bearer ${user.accessToken}` } })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
  }
)

export default fetchCountries
