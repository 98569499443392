import { useCallback, useContext } from 'react'

import mixpanel from 'mixpanel-browser'

import { isLocal } from 'services/helpers/environment'
import TrackerContext from 'services/analytics/contexts/tracker'
import { User } from 'views/iam/types'

const useTracker = () => {
  const { isInit, setIsInit, userCoordinate } = useContext(TrackerContext)

  const init = () => {
    if (isInit) return
    if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
        debug: isLocal,
        ignore_dnt: isLocal,
      })
      setIsInit(true)
    }
  }

  const track = useCallback(
    (action, params = {}) => {
      if (isLocal) {
        // eslint-disable-next-line no-console
        console.info('track', action, params)
      }
      if (!isInit) return
      mixpanel.track(action, params)
    },
    [isInit]
  )
  const setUserSession = (user: User) => {
    if (!isInit) return
    const {
      roles: Roles,
      organization: { name: Organization },
      company: Company,
      profile: { email: Email, firstName, lastName, id },
    } = user
    mixpanel.identify(`${id}`)
    mixpanel.people.set({
      Email,
      'First name': firstName,
      'Last name': lastName,
      Organization,
      Company,
      Roles,
    })
  }

  return {
    init,
    track,
    setUserSession,
    userCoordinate,
  }
}

export default useTracker
