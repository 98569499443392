import styled from 'styled-components'

import Input from 'components/input'
import Textarea from 'components/textarea'
import InputTags from 'components/input_tags'

import { desktop } from 'styles/utils/breakpoints'

export const StyledShareFormInputTags = styled(InputTags)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`

export const StyledShareFormInput = styled(Input)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`

export const StyledShareFormTextarea = styled(Textarea)`
  width: 100%;
  margin-bottom: 20px;

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
`
