import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

const BlockDocumentsSkeleton: FC = () => (
  <ContentLoader speed={2} viewBox='0 0 100 30' backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
    <rect x='0' y='2' rx='3' ry='3' width='100' height='28' />
  </ContentLoader>
)

export default BlockDocumentsSkeleton
