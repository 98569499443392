import styled from 'styled-components'

import { StyledButton } from 'components/button/style'
import { easeQuartOut } from 'styles/utils/ease'
import { text14Regular } from 'styles/utils/texts'
import { desktop, desktopLarge } from 'styles/utils/breakpoints'

export const StyledNetworkListItem = styled.li`
  border-bottom: 1px solid ${(props) => props.theme.softGrayLight};
  padding: 15px 65px 15px 15px;
  transition: background 0.5s ${easeQuartOut};

  &:last-child {
    border-bottom: 0;
  }

  @media ${desktop.down} {
    position: relative;

    ${StyledButton} {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  @media ${desktop.up} {
    display: flex;
    align-items: center;
    padding: 16px 14px 12px 28px;
    overflow: hidden;

    ${StyledButton} {
      margin-left: 20px;
      opacity: 0;
      transform: translateX(20px);
      transition: transform 0s ${easeQuartOut} 0.5s, opacity 0.5s ${easeQuartOut};
    }

    &:hover {
      background: ${(props) => props.theme.primary10};

      ${StyledButton} {
        opacity: 1;
        transform: translateX(0px);
        transition: transform 0.5s ${easeQuartOut}, opacity 0.5s ${easeQuartOut};
      }
    }
  }
`
export const StyledNetworkListItemTitle = styled.div`
  ${text14Regular}
  padding-right: 30px;
  align-self: flex-start;
  color: ${(props) => props.theme.textDark};

  @media ${desktop.down} {
    width: 100%;
    margin-bottom: 5px;
  }

  @media ${desktop.up} {
    min-width: 150px;
  }

  @media ${desktopLarge.up} {
    min-width: 250px;
  }
`

export const StyledNetworkListItemText = styled.div`
  ${text14Regular};
  width: 100%;

  span {
    display: block;
  }
`
