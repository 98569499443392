import React, { FC } from 'react'

import useReferences, { Reference } from 'components/shipment_list_item/hooks/use_references'

import useComputedShipmentOrganization from 'components/shipment_list_item/hooks/use_computed_shipment_organization'

const Organization: FC = () => {
  const references: Reference[] = [useComputedShipmentOrganization()]

  const { References } = useReferences({ references })
  return <References />
}

export default Organization
