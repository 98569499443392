import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  StyledForm,
  StyledLoginFormInput,
  StyledLoginFormButton,
  StyledLoginFormFormTitle,
  StyledLoginFormError,
  StyledLoginFormActions,
  StyledLoginFormLink,
} from 'components/forgot_password_form/style'
import { passwordRecoveryRequest } from 'views/iam/slices/iamSlice'
import { addNotification } from 'views/notifications/slice'
import { routeSignIn } from 'services/helpers/routes'
import { RecoveryPasswordData, recoveryPasswordSchema } from 'components/forgot_password_form/types'
import useAppDispatch from 'services/hooks/use_app_dispatch'

const ForgotPasswordForm = () => {
  const [apiError, setApiError] = useState<string | null>(null)
  const [disabled, setDisabled] = useState<boolean>(false)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<RecoveryPasswordData>({
    resolver: yupResolver(recoveryPasswordSchema),
    defaultValues: { email: '' },
  })

  const submit = (data: RecoveryPasswordData) => {
    setDisabled(true)
    setApiError(null)
    dispatch(passwordRecoveryRequest(data))
      .unwrap()
      .then(({ message }) => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('iam.signIn.form.reset'),
            text: message,
          })
        )
      })
      .catch((err) => {
        if (err.code !== 500) {
          setApiError(t('iam.signIn.errors.multiTenantAccount'))
          return
        }

        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
      .finally(() => setDisabled(false))
  }

  return (
    <StyledForm onSubmit={handleSubmit(submit)} noValidate>
      <StyledLoginFormFormTitle>{t('iam.signIn.recoveryTitle')}</StyledLoginFormFormTitle>
      <StyledLoginFormInput
        label={t('iam.signIn.form.email')}
        type='text'
        id='email'
        required
        error={errors.email?.message ? t(errors.email?.message) : undefined}
        {...register('email')}
      />
      {apiError && <StyledLoginFormError type='alert' text={apiError} fluid />}
      <StyledLoginFormButton
        text={t('iam.signIn.form.reset')}
        variant='highlight'
        type='submit'
        disabled={disabled}
      />
      <StyledLoginFormActions>
        <StyledLoginFormLink as={Link} to={routeSignIn()}>
          {t('iam.signIn.form.footer.loginLink')}
        </StyledLoginFormLink>
      </StyledLoginFormActions>
    </StyledForm>
  )
}

export default ForgotPasswordForm
