import React, { FC } from 'react'

import useReferences from 'components/shipment_list_item/hooks/use_references'

import useComputedUserReferences from 'components/shipment_list_item/hooks/use_computed_user_references'

const References: FC = () => {
  const references = useComputedUserReferences()

  const { References: RefsComponent } = useReferences({ references })
  return <RefsComponent />
}

export default References
