import styled from 'styled-components'

import { StyledUploadDropzoneIcon } from 'components/upload_dropzone/style'
import { StyledCheckboxPicto } from 'components/checkbox_picto/style'

import { tablet } from 'styles/utils/breakpoints'
import { StyledFormGroup } from 'components/form/style'
import { StyledBlockDocuments } from 'components/block_documents/style'
import { StyledRow } from 'components/grid/style'
import { StyledInputLabel } from 'components/input/style'
import { buttonSmall } from 'styles/utils/texts'
import Input from 'components/input'
import StyledTabPanel from 'components/tabs/tab_panel/style'

export const StyledFormUploadDoc = styled.div``

export const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${StyledCheckboxPicto} {
    margin-right: 18px;
    @media ${tablet.down} {
      margin-right: 12px;
    }
  }
`

export const StyledForm = styled.div`
  ${StyledFormGroup} {
    margin-bottom: 0;
  }
`

export const StyledPageBookingDetailTabs = styled.div`
  margin-top: 20px;
  ${StyledUploadDropzoneIcon} {
    display: none;
  }
  ${StyledTabPanel} {
    margin-top: 15px;
  }
`

export const StyledInput = styled.div`
  max-width: 325px;
`

export const StyledSelect = styled.div`
  max-width: 325px;
`

export const StyledGrid = styled.div`
  margin-top: 15px;
`

export const StyledInputCustomRef = styled(Input)`
  padding: 10px 5px 0 5px;
  width: 100%;
`

export const StyledSelectCustomRef = styled.div`
  padding: 10px 5px;
  width: 100%;
`
export const StyledCustomRefWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0 0;
`
export const StyledBlockItemContentWrapper = styled.div`
  padding: 15px 10px 0px 10px;
`

export const StyledBookingFormDocuments = styled.div`
  ${StyledBlockDocuments} {
    margin: 0 0 10px 0;
  }
`

export const StyledGridReferences = styled.div`
  ${StyledRow}:not(:last-child) {
    margin-bottom: 0;
  }
`

export const StyledGridContainer = styled.div`
  padding-bottom: 16px;
`

export const StyledRoutingTransitStepTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  width: 100%;
`

export const StyledLabelTransitStepType = styled.span`
  ${buttonSmall}
  font-size: 14px;
  font-weight: 400;
`

export const StyledSwitchInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const StyledRoutingSwitchInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledRoutingColumnHeader = styled(StyledInputLabel)``

export const StyledAddMerchandiseItemWrapper = styled.div`
  min-height: 60px;
  display: flex;
  justify-content: center;
`

export const StyledAddMerchandisePackageWrapper = styled(StyledAddMerchandiseItemWrapper)`
  margin-top: 1em;
`

export const StyledRemoveContainerRequestButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledHazardousGoodsWrapper = styled.div`
  display: flex;
`

export const StyledHazardousGoodsItemInput = styled(Input)`
  width: calc(100% / 2);
  margin-right: 18px;
  label {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const StyledRemovePackageRequestButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledMerchandiseTypeSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledMerchandiseTypeSwitchInformationSpan = styled.span`
  margin-bottom: 10px;
  margin-right: 15px;
  font-size: 14px;
`

export const StyledAddNewContainerRequestWrapper = styled.div`
  display: flex;
  align-items: center;
  justifiy-content: center;
`

export const StyledRemoveMerchandiseItemWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
`

export const StyledAddMerchandiseDetailsWrapper = styled.div`
  padding: 15px;
`
