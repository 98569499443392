import { Comparer, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import DateHelper from 'services/helpers/date_helper'

import { STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'

import { isPresent } from 'services/helpers/values'

import {
  createCustomKpi,
  deleteCustomKpi,
  fetchCustomKpis,
  updateCustomKpi,
} from 'features/custom_kpis/services/custom_kpi_service'

import type { Status } from 'constants/api'

import type { RootState } from 'services/store/store'
import type { CustomKpi } from 'features/custom_kpis/types/types'

const sortByLastUpdatedDesc: Comparer<CustomKpi> = (a, b) =>
  new DateHelper(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1

const customKpisAdapter = createEntityAdapter<CustomKpi>({
  sortComparer: sortByLastUpdatedDesc,
})
const initialState = customKpisAdapter.getInitialState<{
  selectedCustomKpiId?: number
  status: Status
}>({
  selectedCustomKpiId: undefined,
  status: STATUS_PENDING,
})

const customKpisSlice = createSlice({
  name: 'customKpis',
  initialState,
  reducers: {
    setSelectedCustomKpiId: (state, action) => {
      state.selectedCustomKpiId = action.payload.id
    },
    unsetSelectedCustomKpiId: (state) => {
      state.selectedCustomKpiId = undefined
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomKpis.fulfilled, (state, action) => {
      customKpisAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchCustomKpis.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchCustomKpis.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(createCustomKpi.fulfilled, (state, action) => {
      customKpisAdapter.addOne(state, action.payload)
    })
    builder.addCase(updateCustomKpi.fulfilled, (state, action) => {
      customKpisAdapter.updateOne(state, action.payload)
    })
    builder.addCase(deleteCustomKpi.fulfilled, customKpisAdapter.removeOne)
  },
})

export const { setSelectedCustomKpiId, unsetSelectedCustomKpiId } = customKpisSlice.actions
export const selectSelectedCustomKpiId = (state: RootState) => state.customKpis.selectedCustomKpiId

export const { selectById, selectAll: selectCustomKpis } = customKpisAdapter.getSelectors(
  (state: RootState) => state.customKpis
)

export const selectCustomKpisStatus = (state: RootState) => state.customKpis.status

export const selectCustomKpi = (state: RootState, id: number | undefined) =>
  isPresent(id) ? selectById(state, id) : undefined
export default customKpisSlice.reducer
