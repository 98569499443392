import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Form from 'components/form'

import { IFilter } from 'services/hooks/use_filter'
import { BookingMerchandiseProduct } from 'views/booking/slices/types'
import {
  StyledHazardousGoodsItemInput,
  StyledHazardousGoodsWrapper,
  StyledRemoveMerchandiseItemWrapper,
} from 'views/booking/components/form/style'
import Button from 'components/button'
import Grid from 'components/grid'
import Input from 'components/input'
import { BOOKING_FIELD_VALIDATIONS } from 'constants/bookings'
import {
  TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE,
  getTestIdForMerchandiseProduct,
  getTestIdForMerchandiseProductCurrencyCode,
  getTestIdForMerchandiseProductMaximalTemperature,
} from 'tests/e2e/test_ids'
import Textarea from 'components/textarea'
import Select from 'components/select'
import useStaticLocales from 'views/locales/hooks/use_static_locales'

const MerchandiseTotalDetails: FC<{
  merchandiseFilter: IFilter<'custom'>
  withMultipleDetails: boolean
}> = ({
  merchandiseFilter: filter,
  withMultipleDetails,
}: {
  merchandiseFilter: IFilter<'custom'>
  withMultipleDetails: boolean
}) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const fromStaticToSelectOptions = (path: string) =>
    Object.entries(s(path)).map(([key, value]) => ({ value: key, label: value }))

  return filter.value.total.products.map((_product: BookingMerchandiseProduct, index: number) => (
    <Form.Insert
      subtitle={`${t('bookings.merchandise.merchandiseDetails')} ${
        withMultipleDetails ? `#${index + 1}` : ''
      }`.trim()}
    >
      {index !== 0 && (
        <StyledRemoveMerchandiseItemWrapper>
          <Button
            variant='icon'
            icon='trash'
            disabled={filter.isDisabled}
            onClick={() => {
              filter.onChange((state: any) => {
                state.total.products.splice(index, 1)
              })
            }}
          />
        </StyledRemoveMerchandiseItemWrapper>
      )}
      <Grid columns={3}>
        <Grid.Row testId={getTestIdForMerchandiseProduct(index)}>
          <Grid.Column>
            <Input
              type='number'
              label={t('bookings.merchandise.weight')}
              placeholder='kg'
              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
              step='0.01'
              name={`merchandiseWeight-${index}`}
              value={filter.value.total.products[index].weight?.value}
              error={filter.error?.total?.products[index]?.weight}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].weight) state.total.products[index].weight = {}

                  state.total.products[index].weight.value = value
                })
              }
              disabled={filter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              type='number'
              label={t('bookings.merchandise.volume')}
              placeholder='cbm'
              step='0.01'
              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.VOLUME}
              name={`merchandiseVolume-${index}`}
              value={filter.value.total.products[index].volume?.value}
              error={filter.error?.total?.products[index]?.volume}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].volume) state.total.products[index].volume = {}

                  state.total.products[index].volume.value = value
                })
              }
              disabled={filter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              label={t('bookings.merchandise.numberOfPackages')}
              placeholder={t('bookings.merchandise.numberOfPackages')}
              type='number'
              step='1'
              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.NUMBER_OF_PACKAGES}
              name={`merchandisePackageNumber-${index}`}
              value={filter.value.total.products[index].packageNumber}
              error={filter.error?.total?.products[index]?.packageNumber}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  state.total.products[index].packageNumber = value
                })
              }
              disabled={filter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <Textarea
              label={t('bookings.merchandise.productDescription')}
              placeholder={t('bookings.merchandise.yourDescription')}
              name={`merchandiseDescription-${index}`}
              rows={4}
              compact
              value={filter.value.total.products[index].productDescription}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  state.total.products[index].productDescription = value
                })
              }
              disabled={filter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              type='number'
              step='0.01'
              min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.AMOUNT}
              label={t('bookings.merchandise.commercialValue')}
              placeholder={t('bookings.merchandise.amount')}
              name={`merchandiseAmount-${index}`}
              value={filter.value.total.products[index].commercialValue?.amount}
              error={filter.error?.total?.products[index]?.commercialValueAmount}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].commercialValue)
                    state.total.products[index].commercialValue = {}
                  state.total.products[index].commercialValue.amount = value
                })
              }
              disabled={filter.isDisabled}
            />
            <Select
              placeholder={t('bookings.merchandise.currency')}
              options={fromStaticToSelectOptions('currencies')}
              name={`merchandiseCurrencyCode-${index}`}
              value={filter.value.total.products[index].commercialValue?.currencyCode}
              error={filter.error?.total?.products[index]?.commercialValueCurrencyCode}
              onChange={({ value }: { value: any }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].commercialValue)
                    state.total.products[index].commercialValue = {}
                  state.total.products[index].commercialValue.currencyCode = value
                })
              }
              testId={getTestIdForMerchandiseProductCurrencyCode(index)}
              isDisabled={filter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <Input
              label={t('bookings.merchandise.controlledTemperatures.title')}
              placeholder={t('bookings.merchandise.controlledTemperatures.minimal')}
              type='number'
              step='0.01'
              testId={TEST_ID_BOOKING_FORM_MINIMAL_TEMPERATURE}
              name={`merchandiseTemperatureMinimal-${index}`}
              value={filter.value.total.products[index].controlledTemperatures?.min}
              disabled={filter.isDisabled}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].controlledTemperatures)
                    state.total.products[index].controlledTemperatures = {}
                  state.total.products[index].controlledTemperatures.min = value
                })
              }
            />
            <Input
              placeholder={t('bookings.merchandise.controlledTemperatures.maximal')}
              type='number'
              step='0.01'
              testId={getTestIdForMerchandiseProductMaximalTemperature(index)}
              name={`merchandiseTemperatureMaximal-${index}`}
              value={filter.value.total.products[index].controlledTemperatures?.max}
              disabled={filter.isDisabled}
              onChange={({ target: { value } }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].controlledTemperatures)
                    state.total.products[index].controlledTemperatures = {}
                  state.total.products[index].controlledTemperatures.max = value
                })
              }
            />
          </Grid.Column>
          <Grid.Column>
            <Select
              label={t('bookings.merchandise.product.hazardousGoods.hazardousClass')}
              placeholder={t('actions.select')}
              options={fromStaticToSelectOptions('hazardousGoods')}
              name={`merchandiseHazardousClass-${index}`}
              value={filter.value.total.products[index].hazardousGoods?.hazardousClass}
              onChange={({ value }: { value: any }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].hazardousGoods)
                    state.total.products[index].hazardousGoods = {}
                  state.total.products[index].hazardousGoods.hazardousClass = value
                })
              }
              isDisabled={filter.isDisabled}
              isClearable
            />
          </Grid.Column>
          <Grid.Column>
            <Select
              label={t('bookings.merchandise.product.hazardousGoods.packingGroup')}
              placeholder={t('actions.select')}
              options={fromStaticToSelectOptions('packingGroups')}
              name={`merchandiseHazardousPackingGroup-${index}`}
              value={filter.value.total.products[index].hazardousGoods?.packingGroup}
              onChange={({ value }: { value: any }) =>
                filter.onChange((state: any) => {
                  if (!state.total.products[index].hazardousGoods)
                    state.total.products[index].hazardousGoods = {}
                  state.total.products[index].hazardousGoods.packingGroup = value
                })
              }
              isDisabled={filter.isDisabled}
              isClearable
            />
          </Grid.Column>
          <Grid.Column>
            <StyledHazardousGoodsWrapper>
              <StyledHazardousGoodsItemInput
                label={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                placeholder={t('bookings.merchandise.product.hazardousGoods.unNumber')}
                name={`merchandiseHazardousUnNumber-${index}`}
                value={filter.value.total.products[index].hazardousGoods?.unNumber}
                disabled={filter.isDisabled}
                onChange={({ target: { value } }) =>
                  filter.onChange((state: any) => {
                    if (!state.total.products[index].hazardousGoods)
                      state.total.products[index].hazardousGoods = {}
                    state.total.products[index].hazardousGoods.unNumber = value
                  })
                }
              />
              <StyledHazardousGoodsItemInput
                label={t('bookings.merchandise.product.hazardousGoods.weight')}
                placeholder='kg'
                type='number'
                step='0.01'
                min={BOOKING_FIELD_VALIDATIONS.MINIMAL_VALUES.WEIGHT}
                name={`merchandiseHazardousWeight-${index}`}
                value={filter.value.total.products[index].hazardousGoods?.weight?.value}
                disabled={filter.isDisabled}
                error={filter.error?.total?.products[index]?.hazardousWeight}
                onChange={({ target: { value } }) =>
                  filter.onChange((state: any) => {
                    if (!state.total.products[index].hazardousGoods)
                      state.total.products[index].hazardousGoods = {}

                    if (!state.total.products[index].hazardousGoods.weight)
                      state.total.products[index].hazardousGoods.weight = {}

                    state.total.products[index].hazardousGoods.weight.value = value
                  })
                }
              />
            </StyledHazardousGoodsWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Insert>
  ))
}

export default MerchandiseTotalDetails
