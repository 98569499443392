import styled, { css } from 'styled-components'

import { text16SemiBold, textSmallDescription, text15Regular } from 'styles/utils/texts'
import { borderRadiusRound } from 'styles/utils/border_radius'
import StyledIcon from 'components/icon/style'
import { easeQuartOut } from 'styles/utils/ease'
import {
  STEP_STATUS_CURRENT,
  STEP_STATUS_FUTURE,
  STEP_STATUS_PAST,
  STEP_STATUSES,
} from 'constants/shipments'

// Milestone

export const StyledMilestoneHeadIcon = styled.div`
  position: absolute;
  top: 6px;
  left: -20px;
  fill: ${({ theme }) => theme.primary};
  transition: fill 0.5s ${easeQuartOut};
`

export const StyledMilestoneHeadArrow = styled.div`
  flex: 0 0 24px;
  margin-left: auto;
  fill: ${({ theme }) => theme.mediumGray};
  transition: fill 0.5s ${easeQuartOut};
`

export const StyledMilestoneHead = styled.div<{
  $checked: boolean
  $hasHiddenDetails?: boolean
  $open: boolean
}>`
  width: 100%;
  ${text15Regular}
  position: relative;
  display: flex;
  align-items: flex-start;
  transition: color 0.5s ${easeQuartOut};

  ${(props) =>
    props.$checked &&
    css`
      ${StyledMilestoneHeadIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}

  ${(props) =>
    !props.$checked &&
    css`
      ${StyledMilestoneHeadIcon} {
        fill: ${({ theme }) => theme.mediumGray50};
      }
    `}

  ${(props) =>
    props.$hasHiddenDetails &&
    css`
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.primary};

        ${StyledMilestoneHeadIcon}, ${StyledMilestoneHeadArrow} {
          fill: ${({ theme }) => theme.primary};
        }
      }
    `}

    ${(props) =>
    props.$open &&
    css`
      ${StyledMilestoneHeadArrow} {
        transform: scaleY(-1);
      }
    `}
`

export const StyledMilestoneDetails = styled.div``

export const StyledMilestoneDetail = styled.div<{ $withNewTime?: boolean; $primary?: boolean }>`
  ${textSmallDescription};
  color: ${({ theme }) => theme.textLight};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;

  ${({ $withNewTime, theme }) => $withNewTime && `text-decoration: line-through ${theme.primary}`}
  ${({ $primary, theme }) => $primary && `color: ${theme.primary}`}
`

export const StyledMilestoneMetadata = styled.div<{ $withNewTime?: boolean; $primary?: boolean }>`
  ${textSmallDescription};
  color: ${({ theme }) => theme.textLight};
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;

  ${StyledIcon} {
    margin-right: 7px;
    flex-shrink: 0;
    fill: ${({ theme }) => theme.mediumGray};
  }

  ${({ $withNewTime, theme }) => $withNewTime && `text-decoration: line-through ${theme.primary}`}
  ${({ $primary, theme }) => $primary && `color: ${theme.primary}`}
`

export const StyledMilestoneAdminEditInfo = styled.span`
  color: ${({ theme }) => theme.primary};
`

export const StyledTagDiffTime = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
`

export const StyledMilestoneDetailsHead = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledMilestoneHiddenDetails = styled.div<{ $visible: boolean }>`
  display: none;

  ${StyledMilestoneDetail} {
    margin-top: 4px;
  }

  ${StyledMilestoneDetail}:not(:last-child) {
    margin-bottom: 4px;
  }

  ${(props) =>
    props.$visible &&
    css`
      display: block;
    `}
`

export const StyledMilestone = styled.div`
  padding: 5px 0 5px 20px;
  width: 100%;
  position: relative;
`

export const StyledUpdateTimeButton = styled.div`
  margin-top: 5px;
`

// Step

export const StyledStepHead = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 100%;
`

export const StyledStepIcon = styled.div`
  width: 30px;
  height: 30px;
  ${borderRadiusRound}
  margin-right: 22px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;

  &::before {
    content: '';
    display: block;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    position: absolute;
    top: -8px;
    left: -8px;
    background: ${({ theme }) => theme.primary25};
    border-radius: 100%;
    z-index: -10;
    opacity: 0;
  }

  ${StyledIcon} {
    position: relative;
    z-index: 80;
    width: 16px;
    fill: ${({ theme }) => theme.white};
  }
`

export const StyledStepName = styled.div`
  ${text16SemiBold}
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const StyledStepFlag = styled.div`
  margin-left: 10px;
`

export const StyledStepMilestones = styled.div`
  padding-left: 50px;
`

export const StyledStepProgress = styled.div`
  width: 4px;
  position: absolute;
  top: 15px;
  left: 13px;
  height: calc(100% - 5px);
  z-index: -1;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 4px;
    top: 0;
    left: 0;
  }

  &::before {
    box-sizing: content-box;
    border-left: 4px dotted ${({ theme }) => theme.mediumGray};
  }

  &::after {
    background-color: ${({ theme }) => theme.primary};
    transform-origin: 0 0;
  }

  ${StyledIcon} {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -13px;
    margin-top: -13px;
    width: 25px;
    height: 25px;
    z-index: 80;
    fill: ${({ theme }) => theme.primary};

    &::before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -16px;
      margin-top: -16px;
      z-index: -1;
      background: ${({ theme }) => theme.white};
    }
  }
`

export const StyledStep = styled.div<{
  $status: typeof STEP_STATUSES[number]
  $inTransit: boolean
}>`
  color: ${({ theme }) => theme.textDark};
  position: relative;
  padding-bottom: 10px;
  min-height: 50px;
  z-index: 1;

  &:last-child {
    ${StyledStepProgress} {
      display: none;
    }
  }

  ${(props) =>
    props.$status === STEP_STATUS_PAST &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.primary};
      }
      ${StyledStepProgress} {
        z-index: 20;
        &::after {
          transform: scaleY(1);
        }
      }
    `}

  ${(props) =>
    props.$status === STEP_STATUS_CURRENT &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.primary};

        &::before {
          opacity: 1;
        }
      }
      ${StyledStepProgress} {
        &::after {
          transform: scaleY(0);
        }
      }
    `}

  ${(props) =>
    props.$status === STEP_STATUS_PAST &&
    props.$inTransit &&
    css`
      min-height: 100px;
      ${StyledStepProgress} {
        &::after {
          transform: scaleY(0.5);
        }
        ${StyledIcon} {
          display: block;
        }
      }
    `}


  ${(props) =>
    props.$status === STEP_STATUS_FUTURE &&
    css`
      ${StyledStepIcon} {
        background-color: ${props.theme.mediumGray};
      }
      ${StyledStepProgress} {
        &::after {
          transform: scaleY(0);
        }
      }
    `}
`

// Timeline

export const StyledTimeline = styled.div<{ $stretched?: boolean }>`
  padding: 20px 15px 20px 22px;
  ${StyledMilestoneHead} {
    max-width: ${(props) => (props.$stretched ? '400px' : '185px')};
  }
`
