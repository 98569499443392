import { css } from 'styled-components'

export const borderRadiusXSmall = css`
  border-radius: 8px;
`

export const borderRadiusSmall = css`
  border-radius: 12px;
`

export const borderRadiusMedium = css`
  border-radius: 24px;
`

export const borderRadiusRound = css`
  border-radius: 50%;
`
