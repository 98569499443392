import styled from 'styled-components'

import { buttonSmall } from 'styles/utils/texts'
import StyledIcon from 'components/icon/style'

export const StyledInputCheckboxIcon = styled(StyledIcon)`
  pointer-events: none;
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.white};
  padding: 5px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.white};
  border: 2px solid ${({ theme }) => theme.softGray};

  svg {
    width: 10px;
    height: 8px;
  }
`

export const StyledInputCheckboxInput = styled.input<{ $asTag: boolean }>`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: ${({ $asTag }) => ($asTag ? '100%' : '20px')};
  cursor: pointer;

  &:checked {
    & + ${StyledInputCheckboxIcon} {
      background-color: ${({ theme }) => theme.primary};
      border: 2px solid ${({ theme }) => theme.primary};
    }
  }

  &:disabled {
    & + ${StyledInputCheckboxIcon} {
      background-color: ${({ theme }) => theme.disabled};
      border: 2px solid ${({ theme }) => theme.disabled};
    }
    pointer-events: none;

    &:not(:checked) {
      & + ${StyledInputCheckboxIcon} {
        fill: ${({ theme }) => theme.disabled};
      }
    }
  }
`
export const StyledInputCheckboxLabel = styled.label`
  ${buttonSmall};
  color: ${({ theme }) => theme.textLight};
  margin-left: 30px;
`

export const StyledInputCheckbox = styled.div`
  position: relative;
  display: flex;
`
