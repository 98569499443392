import React, { FC } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import Icon from 'components/icon'
import {
  StyledStepHead,
  StyledStep,
  StyledStepName,
  StyledStepIcon,
  StyledStepMilestones,
  StyledStepProgress,
} from 'components/timeline/style'
import TimelineMilestone from 'components/timeline/timeline_milestone'

import { STEP_STATUSES, STEP_STATUS_INACTIVE } from 'constants/shipments'

interface TimelineBookingConfirmationStepProps {
  status?: typeof STEP_STATUSES[number]
  children?: React.ReactNode
}

const TimelineBookingConfirmationStep: FC<TimelineBookingConfirmationStepProps> = ({
  status = STEP_STATUS_INACTIVE,
  children,
}) => {
  const { t } = useTranslation()
  return (
    <StyledStep $status={status} $inTransit={false}>
      <StyledStepHead>
        <StyledStepIcon>
          <Icon name='calendar' />
        </StyledStepIcon>
        <StyledStepName>{t('timeline.bookingConfirmation.title')}</StyledStepName>
      </StyledStepHead>
      <StyledStepMilestones>{children}</StyledStepMilestones>
      <StyledStepProgress />
    </StyledStep>
  )
}

TimelineBookingConfirmationStep.propTypes = {
  /** Status */
  status: PropTypes.oneOf(STEP_STATUSES),
  /** Children */
  children: validateChildrenOfTypes([TimelineMilestone], false),
}

TimelineBookingConfirmationStep.defaultProps = {
  status: STEP_STATUS_INACTIVE,
  children: undefined,
}

export default TimelineBookingConfirmationStep
