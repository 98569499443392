import styled from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { shadow } from 'styles/utils/shadow'

const StyledBookingList = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 18px;
  padding-right: 18px;
  ${shadow};
  ${borderRadiusMedium};
  z-index: 1;
  background-color: #fff;
  margin-bottom: 30px;
`

export default StyledBookingList
