export const STEP_TYPE_EMPTY_PICKUP = 'empty_pickup'
export const STEP_TYPE_SEA_PICKUP = 'sea_pickup'
export const STEP_TYPE_SEA_POL = 'pol'
export const STEP_TYPE_SEA_POD = 'pod'
export const STEP_TYPE_SEA_TRANSSHIPMENT = 'transshipment'
export const STEP_TYPE_SEA_DELIVERY = 'sea_delivery'
export const STEP_TYPE_SEA_EMPTY_RETURN = 'empty_return'
export const STEP_TYPE_AIR_ORIGIN = 'airport_origin'
export const STEP_TYPE_AIR_DESTINATION = 'airport_destination'
export const STEP_TYPE_AIR_STOPOVER = 'stopover'
export const STEP_TYPE_RAIL_ORIGIN = 'rail_origin'
export const STEP_TYPE_RAIL_DESTINATION = 'rail_destination'
export const STEP_TYPE_ROAD_ORIGIN = 'road_origin'
export const STEP_TYPE_ROAD_DESTINATION = 'road_destination'
export const STEP_TYPE_PARCEL_ORIGIN = 'parcel_origin'
export const STEP_TYPE_PARCEL_STOPOVER = 'sorting_center'
export const STEP_TYPE_PARCEL_DESTINATION = 'parcel_destination'

export const SEA_STEP_TYPES = [
  STEP_TYPE_EMPTY_PICKUP,
  STEP_TYPE_SEA_PICKUP,
  STEP_TYPE_SEA_POL,
  STEP_TYPE_SEA_TRANSSHIPMENT,
  STEP_TYPE_SEA_POD,
  STEP_TYPE_SEA_DELIVERY,
  STEP_TYPE_SEA_EMPTY_RETURN,
] as const

export const AIR_STEP_TYPES = [
  STEP_TYPE_AIR_ORIGIN,
  STEP_TYPE_AIR_STOPOVER,
  STEP_TYPE_AIR_DESTINATION,
] as const

export const RAIL_STEP_TYPES = [STEP_TYPE_RAIL_ORIGIN, STEP_TYPE_RAIL_DESTINATION] as const

export const ROAD_STEP_TYPES = [STEP_TYPE_ROAD_ORIGIN, STEP_TYPE_ROAD_DESTINATION] as const

export const PARCEL_STEP_TYPES = [
  STEP_TYPE_PARCEL_ORIGIN,
  STEP_TYPE_PARCEL_STOPOVER,
  STEP_TYPE_PARCEL_DESTINATION,
] as const

export const STOPOVER_TYPES = [
  STEP_TYPE_AIR_STOPOVER,
  STEP_TYPE_PARCEL_STOPOVER,
  STEP_TYPE_SEA_TRANSSHIPMENT,
] as const

export type SeaStepTypes = typeof SEA_STEP_TYPES[number]
export type AirStepTypes = typeof AIR_STEP_TYPES[number]
export type RailStepTypes = typeof RAIL_STEP_TYPES[number]
export type RoadStepTypes = typeof ROAD_STEP_TYPES[number]
export type ParcelStepTypes = typeof PARCEL_STEP_TYPES[number]

export type StopoverType = typeof STOPOVER_TYPES[number]
export type StepType =
  | typeof SEA_STEP_TYPES[number]
  | typeof AIR_STEP_TYPES[number]
  | typeof RAIL_STEP_TYPES[number]
  | typeof ROAD_STEP_TYPES[number]
  | typeof PARCEL_STEP_TYPES[number]

export interface StopoverMetadata {
  index: number
}

export interface TransshipmentMetadata {
  vesselId?: number
  index: number
  voyage?: string
  arrivalVesselId?: string
}

export interface SeaPolMetadata {
  vesselImo?: number
  voyageNumber?: string
}

export type StepMetadata = StopoverMetadata | SeaPolMetadata | TransshipmentMetadata
