import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED, Status } from 'constants/api'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useUrlParams from 'services/api/hooks/use_url_params'

import type { Version } from 'views/audit_trail/types'
import type { RootState } from 'services/store/store'

export const fetchVersions = createAsyncThunk(
  'versions/fetchVersions',
  async (
    { shipmentToken, filters }: { shipmentToken: string; filters: Record<string, unknown> },
    thunkAPI
  ) => {
    const url = useUrlParams(
      `/shipments/${shipmentToken}/audit_trail`,
      {},
      { escapedParams: filters }
    )
    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ totalCount, versions }) => ({ totalCount, versions }))
      .catch(onError(thunkAPI))
  }
)

export const fetchSectionTypeOptions = createAsyncThunk(
  'versions/fetchSectionTypeOptions',
  async (shipmentToken: string, thunkAPI) =>
    InternalClient.get(`/shipments/${shipmentToken}/audit_trail/section_types`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchSegmentIndicesOptions = createAsyncThunk(
  'versions/fetchSegmentIndicesOptions',
  async (shipmentToken: string, thunkAPI) =>
    InternalClient.get(`/shipments/${shipmentToken}/audit_trail/segment_indices`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchActorOptions = createAsyncThunk(
  'versions/fetchActorOptions',
  async (shipmentToken: string, thunkAPI) =>
    InternalClient.get(`/shipments/${shipmentToken}/audit_trail/actors`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchStepTypeOptions = createAsyncThunk(
  'versions/fetchStepTypeOptions',
  async (shipmentToken: string, thunkAPI) =>
    InternalClient.get(`/shipments/${shipmentToken}/audit_trail/step_types`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

export const fetchMilestoneOptions = createAsyncThunk(
  'versions/fetchMilestoneOptions',
  async (shipmentToken: string, thunkAPI) =>
    InternalClient.get(`/shipments/${shipmentToken}/audit_trail/milestones`)
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)

const versionsAdapter = createEntityAdapter<Version>({
  selectId: (version) => version.id,
})
const initialState = versionsAdapter.getInitialState<{
  status: Status
  totalCount: number
}>({
  status: STATUS_PENDING,
  totalCount: 0,
})

const versionsSlice = createSlice({
  name: 'versions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVersions.fulfilled, (state, action) => {
      state.totalCount = action.payload.totalCount
      versionsAdapter.setAll(state, action.payload.versions)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchVersions.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchVersions.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
  },
})

export const { selectAll: selectVersions } = versionsAdapter.getSelectors(
  (state: RootState) => state.versions
)
export const selectVersionsStatus = (state: RootState) => state.versions.status
export const selectVersionsTotalCount = (state: RootState) => state.versions.totalCount

export default versionsSlice.reducer
