import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useActiveValues from 'views/shipments/hooks/use_active_values'

import { removeAll, saveActiveFilters } from 'views/shipments/slice'

const useActiveFilters = ({ categories }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { saveActiveValues } = useActiveValues({ categories })

  const activeFilters = categories.reduce((acc, c) => {
    Object.assign(acc, c.queryParams)
    return acc
  }, {})

  const save = () => {
    dispatch(removeAll())
    dispatch(saveActiveFilters({ activeFilters }))
    saveActiveValues()
  }
  const goToShipments = () => history.push('/shipments')

  return {
    save,
    goToShipments,
  }
}

export default useActiveFilters
