import { MilestoneType } from 'types/milestones'

import { MILESTONES_ARRIVAL_TYPES, MILESTONES_DEPARTURE_TYPES } from 'constants/milestones'

type MilestoneComputedType = 'other' | 'departure' | 'arrival'

interface MilestonesParams {
  isDepartureOrArrival: boolean
  milestoneType: MilestoneComputedType
}

const buildMilestoneParams = (type: MilestoneType | undefined): MilestonesParams => {
  const isDeparture = MILESTONES_DEPARTURE_TYPES.some((t) => type === t)

  const isArrival = MILESTONES_ARRIVAL_TYPES.some((t) => type === t)

  const isDepartureOrArrival = isArrival || isDeparture

  let milestoneType: MilestoneComputedType = 'other'

  if (isDeparture) {
    milestoneType = 'departure'
  } else if (isArrival) {
    milestoneType = 'arrival'
  }

  return { isDepartureOrArrival, milestoneType }
}

export default buildMilestoneParams
