import styled, { css } from 'styled-components'

import StyledIcon from 'components/icon/style'
import { textSmallDescription } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledAccordionItem = styled.div``

export const StyledAccordionItemHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const StyledAccordionItemContent = styled.div`
  overflow: hidden;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`

export const StyledAccordionItemHeadingArrow = styled(StyledIcon)`
  margin-left: 15px;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
`

export const StyledAccordionItemHeadingMore = styled(StyledIcon)`
  width: 12px;
  right: 12px;
  position: relative;
  fill: ${(props) => props.theme.mediumGray};
`

export const StyledHeading = styled.div<{ $small?: boolean }>`
  ${({ $small }) =>
    !$small &&
    css`
      flex-grow: 1;
      min-width: 0;
    `}

  ${({ $small }) =>
    $small &&
    css`
      ${textSmallDescription};
      color: ${({ theme }) => theme.textLight};
      padding: 15px 0;
      width: 100%;
      transition: color 0.5s ${easeQuartOut};

      &:hover {
        color: ${({ theme }) => theme.primary};
      }
    `}
`
