import styled from 'styled-components'

import { text14Regular } from 'styles/utils/texts'
import { mobileSmall } from 'styles/utils/breakpoints'
import StyledIcon from 'components/icon/style'

export const Timeline = styled.div`
  ${text14Regular};
  color: ${({ theme }) => theme.textDark};
  display: flex;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ${mobileSmall.down} {
      white-space: normal;
      overflow: visible;
      width: 100%;
    }
  }

  ${StyledIcon} {
    flex: none;
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.primary};
  }
`

const S = {
  Timeline,
}

export default S
