import styled from 'styled-components'

import { text15Regular, textSmallDescription } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'
import StyledIcon from 'components/icon/style'
import { Any } from 'services/helpers/types'

// Saved Templates

export const StyledSavedTemplatesItem = styled.div<Any>`
  display: flex;
  width: 100%;
`

export const StyledSavedTemplatesTitle = styled.div`
  ${text15Regular}
  color: ${({ theme }) => theme.textDark};
`

export const StyledSavedTemplatesArrow = styled.div`
  transform: rotate(-90deg);
  ${StyledIcon} {
    fill: ${({ theme }) => theme.mediumGray};
  }
  &:hover {
    ${StyledIcon} {
      fill: ${({ theme }) => theme.primary};
    }
  }
  display: flex;
  justify-content: center;
  margin-left: auto;
  width: 44px;
  height: 44px;
`

export const StyledSavedTemplatesSection = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  padding: 15px 20px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const StyledSavedTemplatesEdit = styled.button`
  ${textSmallDescription}
  color:  ${({ theme }) => theme.textLight};
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transition: color 0.5s ${easeQuartOut};

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`

export const StyledTemplatesEditModeButton = styled.div`
  display: flex;
  margin-left: auto;
`
