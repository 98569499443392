import React, { FC, useEffect, useMemo } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useSelector } from 'react-redux'

import setUpDocumentHead from 'services/helpers/custom_domain_handler'

import ThemeProvider from 'styles/theme_provider'
import GlobalStyle from 'styles/global'

import { fetchLocales } from 'views/locales/slice'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { loadStaticTranslations } from 'views/locales/i18n'

import ErrorLogger from 'services/error/logger'
import useTracker from 'services/analytics/hooks/use_tracker'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useBehaviorTracker from 'services/analytics/hooks/use_behavior_tracker'
import useOnce from 'services/hooks/use_once'

import { CLASQUIN_ORGANISATION } from 'constants/global'
import { APP_TYPE_CLASQUIN, APP_TYPE_SHARED, APP_TYPE_STANDALONE } from 'constants/app_type'

import StandaloneApp from 'app/standalone_app'
import ClasquinApp from 'app/clasquin_app'
import SharedApp from 'app/shared_app'
import AppContext from 'app/contexts/app_context'
import { setApplicationType, selectApplicationType } from 'app/slice'

import ModalContextProvider from 'components/modal/context_provider'
import GlobalNotifications from 'components/global_notifications'

import { ROUTE_SHARED } from 'services/helpers/routes'
import { isPresent } from 'services/helpers/values'
import useRefreshTokenInterval from 'services/hooks/use_refresh_token_interval'

import type { AppTypes } from 'constants/app_type'

const App: FC = () => {
  const appType = useSelector(selectApplicationType)
  const dispatch = useAppDispatch()
  const user = useCurrentUser()
  const tracker = useTracker()
  const behaviorTracker = useBehaviorTracker()
  const location = useLocation()
  useRefreshTokenInterval()

  useEffect(() => {
    let newType: AppTypes = APP_TYPE_STANDALONE

    const { embedded, organization } = queryString.parse(location.search)
    const isEmbeddedByClasquin = embedded && organization === CLASQUIN_ORGANISATION
    const isSharedApp = location.pathname.indexOf(ROUTE_SHARED) === 0
    if (isEmbeddedByClasquin) {
      newType = APP_TYPE_CLASQUIN
    } else if (isSharedApp) {
      newType = APP_TYPE_SHARED
    }
    if (!isPresent(appType)) {
      dispatch(setApplicationType({ type: newType }))
    }
  }, [dispatch, location, appType])

  const isStandalone = useMemo(() => appType === APP_TYPE_STANDALONE, [appType])
  const isClasquin = useMemo(() => appType === APP_TYPE_CLASQUIN, [appType])
  const isShared = useMemo(() => appType === APP_TYPE_SHARED, [appType])
  const isEmbedded = useMemo(() => window.self !== window.top, [])

  useOnce(() => {
    setUpDocumentHead()
    tracker.init()
    behaviorTracker.init()
  })

  useEffect(() => {
    dispatch(fetchLocales()).unwrap().then(loadStaticTranslations)
  }, [dispatch])

  useEffect(() => {
    if (user.isSignedIn && !isShared) {
      const { id, firstName, lastName, email } = user.profile
      behaviorTracker.identify({
        id,
        email,
        firstName,
        lastName,
        organization: user.organization.name,
        company: user.company?.name,
      })
      ErrorLogger.registerUserData({
        id,
        firstName,
        email,
        company: user.company,
        roles: user.roles,
      })
      tracker.setUserSession(user)
    }
    // eslint-disable-next-line
  }, [user])

  const appContextValue = useMemo(
    () => ({
      type: appType,
      isStandalone,
      isClasquin,
      isShared,
      isEmbedded,
    }),
    [appType, isStandalone, isClasquin, isShared, isEmbedded]
  )

  return (
    <AppContext.Provider value={appContextValue}>
      <ThemeProvider>
        <ModalContextProvider>
          <GlobalStyle />
          <GlobalNotifications />
          {isClasquin && <ClasquinApp />}
          {!isClasquin && (
            <Switch>
              {isShared && <Route path={ROUTE_SHARED} component={SharedApp} />}
              {isStandalone && <Route path='*' component={StandaloneApp} />}
            </Switch>
          )}
        </ModalContextProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

export default App
