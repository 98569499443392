import styled from 'styled-components'

import NotifMessage from 'components/notif_message'

const UserActivationWarning = styled.div`
  margin-bottom: 20px;
`

const UserSsoNoCredentialsWarning = styled(NotifMessage)`
  margin: 20px 0;
`

const S = {
  UserActivationWarning,
  UserSsoNoCredentialsWarning,
}

export default S
