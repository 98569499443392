import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
  fetchTrustedRoutes,
  selectSubscriptionID,
  selectTrustedRoutesStatus,
  setStatusPoolingRunning,
  setStatusTimeout,
  startNewSubscription,
  subscribeTrustedRoutes,
} from 'views/trusted_routes/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import {
  SUBSCRIPTION_FULFILLED,
  TRUSTED_ROUTES_POOLING_RUNNING,
} from 'views/trusted_routes/types/status'
import { SubscribeTrustedRoutesData } from 'views/trusted_routes/components/search_bar/types'

const INTERVAL_BETWEEN_POOLING = 10 * 1000
const MAX_POOLING_DURATION = 3 * 60 * 1000

const useSearchTrustedRoutes = () => {
  const dispatch = useAppDispatch()
  const status = useSelector(selectTrustedRoutesStatus)
  const subscriptionID = useSelector(selectSubscriptionID)

  const abortControllerRef = useRef<AbortController | null>(null)

  // Loop to progressively collect trusted routes until jobs are completed in the backend
  useEffect(() => {
    if (!subscriptionID) return
    if (status === SUBSCRIPTION_FULFILLED) {
      dispatch(setStatusPoolingRunning())
      return
    }
    if (status !== TRUSTED_ROUTES_POOLING_RUNNING) return

    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    const abortController = new AbortController()
    abortControllerRef.current = abortController

    dispatch(fetchTrustedRoutes({ subscriptionID, signal: abortController.signal }))
    const interval = setInterval(
      () => dispatch(fetchTrustedRoutes({ subscriptionID, signal: abortController.signal })),
      INTERVAL_BETWEEN_POOLING
    )
    const timeout = setTimeout(() => {
      clearInterval(interval)
      dispatch(setStatusTimeout())
    }, MAX_POOLING_DURATION)

    // eslint-disable-next-line
    return () => {
      clearInterval(interval)
      clearTimeout(timeout)
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionID, status])

  const subscribe = (data: SubscribeTrustedRoutesData) => {
    dispatch(
      startNewSubscription({
        departure: data.departure,
        arrival: data.arrival,
        fromDate: data.fromDate,
        withRates: data.withRates,
      })
    )
    return dispatch(
      subscribeTrustedRoutes({
        departure: data.departure.value,
        arrival: data.arrival.value,
        fromDate: data.fromDate,
        withRates: data.withRates,
      })
    ).unwrap()
  }
  return { subscribe, status }
}

export default useSearchTrustedRoutes
