import styled from 'styled-components'

const StyledAboveModal = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  inset: 0;
  > * {
    pointer-events: all;
  }
`

export default StyledAboveModal
