import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import BlockList from 'components/block_list'
import TextList from 'components/text_list'
import TextListItem from 'components/text_list/text_list_item'
import Placeholder from 'components/placeholder'
import TextListItemSkeleton from 'components/text_list/skeleton'

import { References } from 'views/shipment/models'
import ShipmentContext from 'views/shipment/context'
import useShipment from 'views/shipment/hooks/use_shipment'

const SharedReferences = () => {
  const { t } = useTranslation()
  const { id } = useContext(ShipmentContext)
  const [shipment, status] = useShipment({ id })
  const references = new References(shipment)

  return (
    <>
      <BlockList>
        <BlockList.Title>{t('shipments.product.title')}</BlockList.Title>
        <Placeholder ready={status.ready} customPlaceholder={<TextListItemSkeleton />}>
          <TextList>
            <TextListItem
              title={t('shipments.product.grossWeight')}
              text={references.product.grossWeight}
              key='text-list-item-weight'
            />
            <TextListItem
              title={t('shipments.product.volume')}
              text={references.product.volume}
              key='text-list-item-volume'
            />
            <TextListItem
              title={t('shipments.product.packageNumber')}
              text={references.product.packageNumber}
              key='text-list-item-package-number'
            />
            <TextListItem
              title={t('shipments.product.description')}
              text={references.product.description}
              variant='description'
              key='text-list-item-description'
            />
          </TextList>
        </Placeholder>
      </BlockList>
      <BlockList>
        <BlockList.Title>{t('shipments.references.title')}</BlockList.Title>
        <TextList>
          <TextListItem
            title={t('shipments.references.client')}
            text={references.references.client}
            key='text-list-item-client-reference'
          />
          <TextListItem
            title={t('shipments.references.shipment')}
            text={references.references.shipment}
            key='text-list-item-shipment-reference'
          />
          <TextListItem
            title={t('shipments.references.forwarder')}
            text={references.references.forwarder}
            key='text-list-item-forwarder-reference'
          />
          <TextListItem
            title={t('shipments.references.clientBookingNumber')}
            text={references.references.clientBookingNumber}
            key='text-list-item-client-booking-number'
          />
        </TextList>
      </BlockList>
    </>
  )
}

SharedReferences.propTypes = {}

SharedReferences.defaultProps = {}

export default SharedReferences
