import styled from 'styled-components'

import Button from 'components/button'

export const StyledCodeBlockContainer = styled.div`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.softGray};
  overflow-y: hidden;
  height: 100%;
  width: 40%;
`

export const StyledCodeBlock = styled.pre`
  padding: 10px;
  height: 90%;
  overflow-y: hidden;
`

export const StyledPathModalContainer = styled.div`
  display: flex;
  gap: 5px;
  height: 95%;
`

export const StyledMapContainer = styled.div`
  width: 100%;
`

export const StyledTextArea = styled.textarea`
  width: 100%;
  background-color: transparent;
  border: none;
  box-sizing: none;
  color: ${({ theme }) => theme.textDark};
  font-size: 14px;
  outline: none;
  height: 90%;
`

export const StyledErrorMessage = styled.div`
  padding: 10px;
  text-align: center;
  color: ${({ theme }) => theme.error};
  height: 10px;
`

export const StyledButtonContainer = styled.div`
  width: 100%;
  height: 5%;
`

export const StyledBeautifyButton = styled(Button)`
  position: sticky;
  left: 500px;
`
