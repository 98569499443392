import styled from 'styled-components'

const StyledCustomFilters = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  position: relative;
`

export default StyledCustomFilters
