import styled, { css } from 'styled-components'
import { ReactSVG } from 'react-svg'

const StyledFlag = styled(ReactSVG)<{ $width: number; $height: number }>`
  position: relative;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${(props) =>
    props.$width &&
    css`
      width: ${props.$width}px;
    `}

  ${(props) =>
    props.$height &&
    css`
      height: ${props.$height}px;
    `}
`

export default StyledFlag
