import React from 'react'
import PropTypes from 'prop-types'

import {
  STEP_STATUSES,
  STEP_STATUS_CURRENT,
  STEP_STATUS_INACTIVE,
  VEHICLE_TYPES,
} from 'constants/shipments'
import ShipmentTimelineStep from 'components/shipment_timeline/shipment_timeline_step'

const Step = ({ step, status, inTransit, currentVehicleType }) =>
  step ? (
    <ShipmentTimelineStep
      status={status}
      inTransit={status === STEP_STATUS_CURRENT && inTransit}
      locationName={step.location.name}
      locationCode={step.location.countryCode}
      facilityType={step.facilityType}
      currentVehicleType={currentVehicleType}
      estimatedTime={step.estimatedTime}
      actualTime={step.actualTime}
    />
  ) : (
    <ShipmentTimelineStep status={status} inTransit={false} />
  )

Step.propTypes = {
  /** A step */
  step: PropTypes.shape(),
  /** Status */
  status: PropTypes.oneOf(STEP_STATUSES),
  /** In transit */
  inTransit: PropTypes.bool,
  /** Vehicle type */
  currentVehicleType: PropTypes.oneOf(VEHICLE_TYPES),
}
Step.defaultProps = {
  step: undefined,
  status: STEP_STATUS_INACTIVE,
  inTransit: false,
  currentVehicleType: undefined,
}

export default Step
