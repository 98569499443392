import { isPresent } from 'services/helpers/values'

type DomainName = 'trace.seafrigo.com' | 'trace-dev.seafrigo.com' | 'plus.tradenav.net' | 'wakeo'

interface CustomDomain {
  domainName: DomainName
  basePath: string
  title: string
}

const DEFAULT_DOMAIN: CustomDomain = {
  domainName: 'wakeo',
  basePath: process.env.PUBLIC_URL,
  title: 'wakeo',
}

const CUSTOM_DOMAINS: CustomDomain[] = [
  {
    domainName: 'trace.seafrigo.com',
    basePath: `${process.env.PUBLIC_URL}/seafrigo`,
    title: 'Seafrigo',
  },
  {
    domainName: 'trace-dev.seafrigo.com',
    basePath: `${process.env.PUBLIC_URL}/seafrigo`,
    title: 'Seafrigo',
  },
  {
    domainName: 'plus.tradenav.net',
    basePath: `${process.env.PUBLIC_URL}/santova`,
    title: 'Santova',
  },
]

const PATHS = {
  favicon: 'favicon.ico',
  favicon32: 'favicon-32x32.png',
  favicon16: 'favicon-16x16.png',
  appleTouchIcon: 'logo192.png',
  manifest: 'manifest.json',
  maskIcon: 'safari-pinned-tab.svg',
  msApplicationConfig: 'browserconfig.xml',
}

const setUpDocumentHead = () => {
  const hostName = window.location.hostname

  const customDomain = CUSTOM_DOMAINS.find((cd) => cd.domainName === hostName) ?? DEFAULT_DOMAIN

  setUpTitle(customDomain)
  setUpCustomIcons(customDomain)
  setUpMSApplicationConfig(customDomain)
}

const setUpCustomIcons = (customDomain: CustomDomain) => {
  const favicon = document.getElementById('rel-favicon-id') as HTMLLinkElement | null

  if (isPresent(favicon)) favicon.href = [customDomain.basePath, PATHS.favicon].join('/')

  const favicon32 = document.getElementById('rel-favicon-32-id') as HTMLLinkElement | null

  if (isPresent(favicon32)) favicon32.href = [customDomain.basePath, PATHS.favicon32].join('/')

  const favicon16 = document.getElementById('rel-favicon-16-id') as HTMLLinkElement | null

  if (isPresent(favicon16)) favicon16.href = [customDomain.basePath, PATHS.favicon16].join('/')

  const appleTouchIcon = document.getElementById(
    'rel-apple-touch-icon-id'
  ) as HTMLLinkElement | null

  if (isPresent(appleTouchIcon))
    appleTouchIcon.href = [customDomain.basePath, PATHS.appleTouchIcon].join('/')

  const manifest = document.getElementById('rel-manifest-id') as HTMLLinkElement | null

  if (isPresent(manifest)) manifest.href = [customDomain.basePath, PATHS.manifest].join('/')

  const maskIcon = document.getElementById('rel-mask-icon-id') as HTMLLinkElement | null

  if (!isPresent(maskIcon)) return

  maskIcon.href = [customDomain.basePath, PATHS.maskIcon].join('/')
}

const setUpMSApplicationConfig = (customDomain: CustomDomain) => {
  const msApplicationConfig = document.getElementById(
    'meta-msapplication-config-id'
  ) as HTMLMetaElement | null

  if (!isPresent(msApplicationConfig)) return

  msApplicationConfig.content = [customDomain.basePath, PATHS.msApplicationConfig].join('/')
}

const setUpTitle = (customDomain: CustomDomain) => {
  const title = document.getElementById('wakeo-page-title-id') as HTMLTitleElement | null

  if (!isPresent(title)) return

  title.text = customDomain.title
}

export default setUpDocumentHead
