import { Category, Leg, Rate, TransportEnum } from 'views/trusted_routes/types/trusted_route'

export const formatRateType = (rate: Rate): string => {
  if (rate.category === Category.SPOT) {
    return 'Spot'
  }
  if (rate.nac) {
    return 'NAC'
  }
  if (rate.commodity) {
    return rate.commodity
  }
  return 'FAK'
}

interface TrustedRouteMetadata {
  service?: string | null
  voyage?: string | null
  vesselImo?: string | null
  vesselName?: string | null
}

const sanitizeMetadata = (metadata: string | null): string => {
  if (metadata === 'TBA') return ''
  return metadata || ''
}

export const hasMetadata = (leg: Leg): boolean =>
  [
    TransportEnum.VESSEL,
    TransportEnum.BARGE,
    TransportEnum.FEEDER,
    TransportEnum.FEEDER_BARGE,
  ].includes(leg.type)

export const formatMetadata = (leg: Leg): TrustedRouteMetadata => ({
  vesselName: sanitizeMetadata(leg.vesselName),
  vesselImo: leg.vesselImo?.toString(),
  service: leg.service,
  voyage: sanitizeMetadata(leg.voyage),
})

/**
 * prevents hours from being displayed in milestones
 */
export const formatDateForMilestone = (date: string): string => `${date}T00:00:00Z`
