import React from 'react'
import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import { StyledTextList } from 'components/text_list/style'
import TextListItem from 'components/text_list/text_list_item'

const TextList = ({ children, className }) => (
  <StyledTextList className={className}>{children}</StyledTextList>
)

TextList.propTypes = {
  /** Children */
  children: validateChildrenOfTypes([TextListItem]),
  /** className */
  className: PropTypes.string,
}

TextList.defaultProps = {
  children: undefined,
  className: undefined,
}

export default TextList
