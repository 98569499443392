import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { capitalize } from 'services/helpers/values'

import BlockContainerItem from 'components/block_container/block_container_item'
import { StyledBlockContainerWrapper } from 'components/block_container/style'
import BlockContainerAdd from 'components/block_container/block_container_add'

import { BlockContainerProps } from 'components/block_container/types'
import { anyChildren, numberInferiorEqualTo } from 'services/helpers/prop_types'
import { uuid4 } from 'services/helpers/uuid'

export const BlockContext = createContext({ index: 0 })

// TODO: REFACTOR
// This implementation creates repeatable children.
// The index is passed via a context, consumable inside <BlockContainer/>.
// This is difficult to use and maintain.

const BlockContainer = ({
  title,
  children,
  columns,
  size,
  btnSize,
  onAdd,
  onRemove,
  initialBlocksCount = 1,
  maxBlocks = 9999,
  minBlocks = 0,
  disabled = false,
  testId,
  addBlockTestId,
  itemGender,
}: BlockContainerProps) => {
  const newElement = () => ({ key: uuid4() })

  const [blocks, setBlocks] = useState([newElement()])

  useEffect(() => {
    if (initialBlocksCount < blocks.length) {
      const newBlocks = [...Array(initialBlocksCount)].map(newElement)
      setBlocks(newBlocks)
    } else if (initialBlocksCount > blocks.length) {
      const newBlocks = [...Array(initialBlocksCount - blocks.length)].map(newElement)
      setBlocks((prev) => [...prev, ...newBlocks])
    }
    // Do not depend on blocks.length as it would sync to the initialBlocksCount value when
    // the user tries to add or remove blocks
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBlocksCount])

  const addBlock = (index: number) => {
    onAdd(index)
    setBlocks((prevBlocks) => [...prevBlocks, newElement()])
  }

  const removeBlock = (key: string, blockToRemoveIndex: number) => {
    onRemove(blockToRemoveIndex)

    const newBlocks = [...blocks]
    const index = newBlocks.findIndex((newBlock) => newBlock.key === key)

    if (newBlocks.length < 1) return
    if (index < 0) return

    newBlocks.splice(index, 1)
    setBlocks(newBlocks)
  }

  const itemTestId = (index: number) => `${title}-item-${index}`

  return (
    <StyledBlockContainerWrapper $centered={columns === 1} data-testid={testId}>
      {blocks.map((block, index) => (
        <BlockContext.Provider value={{ index }}>
          <BlockContainerItem
            title={capitalize(title)}
            key={block.key}
            index={index}
            columns={columns}
            size={size}
            length={blocks.length}
            minLength={minBlocks}
            removeBlock={() => removeBlock(block.key, index)}
            disabled={disabled}
            testId={itemTestId(index)}
          >
            {children}
          </BlockContainerItem>
        </BlockContext.Provider>
      ))}
      {blocks.length < maxBlocks && !disabled && (
        <BlockContainerAdd
          addBlock={() => addBlock(blocks.length)}
          columns={columns}
          btnSize={btnSize}
          title={title}
          testId={addBlockTestId}
          itemGender={itemGender}
        />
      )}
    </StyledBlockContainerWrapper>
  )
}

BlockContainer.propTypes = {
  /** title */
  title: PropTypes.string,
  /** children */
  children: anyChildren,
  /** columns */
  columns: numberInferiorEqualTo(4, false),
  /** size */
  size: numberInferiorEqualTo(4, false),
  /** btnSize */
  btnSize: numberInferiorEqualTo(2, false),
  /** onAdd */
  onAdd: PropTypes.func,
  /** onRemove */
  onRemove: PropTypes.func,
  /** initialBlocksCount */
  initialBlocksCount: PropTypes.number,
  /** maxBlocks */
  maxBlocks: PropTypes.number,
  /** minBlocks */
  minBlocks: PropTypes.number,
  /** disabled */
  disabled: PropTypes.bool,
  /** testId */
  testId: PropTypes.string,
  /** addBlockTestId */
  addBlockTestId: PropTypes.string,
  itemGender: PropTypes.string,
}

BlockContainer.defaultProps = {
  title: undefined,
  children: undefined,
  size: undefined,
  btnSize: undefined,
  columns: undefined,
  onAdd: Function.prototype,
  onRemove: Function.prototype,
  initialBlocksCount: 1,
  maxBlocks: 9999,
  minBlocks: 0,
  disabled: false,
  testId: undefined,
  addBlockTestId: undefined,
  itemGender: undefined,
}

export default BlockContainer
