import React, { FC, useContext } from 'react'

import ShipmentContext from 'components/shipment_list_item/context'
import { isPresent } from 'services/helpers/values'

import OngoingAlertIcon from 'components/ongoing-alert/ongoing-alert-icon'

const OngoingAlert: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const { ongoingAlert } = shipment

  return isPresent(ongoingAlert) ? <OngoingAlertIcon ongoingAlert={ongoingAlert} /> : null
}

export default OngoingAlert
