import React from 'react'

import PropTypes from 'prop-types'

import { validateChildrenOfTypes } from 'services/helpers/prop_types'

import { StyledTimeline } from 'components/timeline/style'
import TimelineStepComponent from 'components/timeline/timeline_step'

export { default as TimelineStep } from 'components/timeline/timeline_step'
export { default as TimelineMilestone } from 'components/timeline/timeline_milestone'

const Timeline = ({ stretched, children }) => (
  <StyledTimeline $stretched={stretched}>{children}</StyledTimeline>
)

Timeline.propTypes = {
  stretched: PropTypes.bool,
  /** Children */
  children: validateChildrenOfTypes([TimelineStepComponent]),
}

Timeline.defaultProps = {
  stretched: undefined,
  children: undefined,
}

export default Timeline
