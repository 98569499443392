import styled, { css } from 'styled-components'

import { text14Regular, textSmallDescription } from 'styles/utils/texts'
import { borderRadiusSmall } from 'styles/utils/border_radius'

export const StyledDateInputContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`

export const StyledDateTimeSelectWrap = styled.div<{ $disabled: boolean }>`
  cursor: pointer;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`
export const StyledDatePickerContainer = styled.div``

export const StyledDateInput = styled.input`
  padding: 13px 20px 13px 55px;
  background: ${({ theme }) => theme.bgInput30};
  color: ${({ theme }) => theme.textDark};
  ${borderRadiusSmall};
  ${text14Regular};

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }
  &:hover {
    cursor: default;
  }
`
export const StyledDatePickerLabel = styled.label`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    text-overflow: initial;
    overflow: visible;
  }
`
export const StyledLabelRequiredIcon = styled.span`
  color: ${({ theme }) => theme.error};
`

export const StyledDatePicker = styled.div``

export const StyledSelectDatePicker = styled.div<{ $disabled?: boolean }>`
  .Select {
    &__control {
      background-color: ${({ theme, $disabled }) => ($disabled ? theme.disabled : theme.bgInput30)};
    }
  }
`
