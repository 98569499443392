import styled from 'styled-components'

import { text14Regular } from 'styles/utils/texts'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledMenuItem = styled.li`
  position: relative;
  width: 100%;
  height: 100%;

  &:not(:last-child)::after {
    content: '';
    display: block;
    height: 1px;
    left: 12px;
    bottom: 0;
    width: calc(100% - 24px);
    background-color: ${(props) => props.theme.lightGray};
    position: absolute;
  }
`

export const StyledMenuItemLink = styled.div<{ $active: boolean }>`
  color: ${(props) => (props.$active ? props.theme.primary : props.theme.textLight)};
  ${text14Regular};
  white-space: nowrap;
  display: block;
  padding: 15px 20px;
  transition: color 0.5s ${easeQuartOut};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.textDark};
  }
`
