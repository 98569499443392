import styled, { css } from 'styled-components'

import { text14Regular, listItem13, textSmallDescription } from 'styles/utils/texts'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { easeQuartOut } from 'styles/utils/ease'

export const StyledInputWrapper = styled.div`
  display: flex;
  position: relative;
  background: ${({ theme }) => theme.bgInput30};
  width: 100%;

  ${borderRadiusSmall};

  &:focus {
    border-color: ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.white};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.textLight};
  }
`

export const StyledInputLabel = styled.label`
  color: ${({ theme }) => theme.textLight};
  ${textSmallDescription};
  margin-bottom: 5px;
`

export const StyledInputLabelRequiredIcon = styled.span`
  color: ${({ theme }) => theme.error};
`
export const StyledInputButton = styled.div``

export const StyledInputInput = styled.input`
  flex: 1;
  padding: 13px 20px;
  color: ${({ theme }) => theme.textDark};
  transition: border 0.5s ${easeQuartOut}, background 0.5s ${easeQuartOut};

  ${text14Regular};

  ::placeholder {
    color: ${({ theme }) => theme.mediumGray};
  }
`

export const StyledInput = styled.div<{
  $hasButton: boolean
  $hasError: boolean
  $hasIcon: boolean
}>`
  position: relative;

  ${(props) =>
    props.$hasButton &&
    css`
      ${StyledInputWrapper} {
        z-index: 1;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          right: 12px;
          left: 12px;
          width: calc(100% - 24px);
          height: 100%;
          background: ${({ theme }) => theme.bgInput30};
          z-index: -1;
        }
      }

      ${StyledInputInput} {
        border-radius: 12px 0 0 12px;

        &::after {
          content: '';
          display: block;
          position: absolute;
          right: -10px;
          width: 10px;
        }
      }
    `}

  ${(props) =>
    props.$hasError &&
    css`
      ${StyledInputInput} {
        border-color: ${({ theme }) => theme.error};
      }
    `}

    ${(props) =>
    props.$hasIcon &&
    css`
      ${StyledInputInput} {
        padding-left: 48px;
      }
    `}
`

export const StyledInputPasswordButton = styled.button`
  position: absolute;
  right: 15px;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    fill: ${({ theme }) => theme.mediumGray};
    transition: fill 0.5s ${easeQuartOut};
  }

  &:hover {
    svg {
      fill: ${({ theme }) => theme.primary};
    }
  }
`

export const StyledInputError = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.error};
  ${listItem13};
  position: relative;
  top: 7px;
  width: 100%;

  svg {
    fill: ${({ theme }) => theme.error};
    margin-right: 9px;
    flex: none;
    width: 15px;
    height: 14px;
  }
`

export const StyledInputIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -12px;
  fill: ${({ theme }) => theme.mediumGray};
`
