import React, { useContext } from 'react'

import useBooking from 'views/booking/hooks/use_booking'

import InformationMerchandiseContainers from 'views/booking/components/information_merchandise_containers'
import InformationMerchandisePackages from 'views/booking/components/information_merchandise_packages'
import InformationMerchandiseTotal from 'views/booking/components/information_merchandise_total'
import InformationMerchandisePackageLegacy from 'views/booking/components/information_merchandise_package_legacy'
import InformationMerchandisePackageTotal from 'views/booking/components/information_merchandise_package_total'

import {
  MERCHANDISE_TYPE_CONTAINER,
  MERCHANDISE_TYPE_PACKAGE,
  MERCHANDISE_TYPE_PACKAGE_TOTAL,
  MERCHANDISE_TYPE_TOTAL,
} from 'constants/bookings'

import BookingContext from 'views/booking/contexts/booking_context'

const InformationMerchandise = () => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)

  const { legacyBooking } = booking!
  return (
    <>
      {booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_CONTAINER && (
        <InformationMerchandiseContainers merchandise={booking.merchandise} />
      )}
      {booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_PACKAGE &&
        (legacyBooking ? (
          <InformationMerchandisePackageLegacy merchandise={booking.merchandise} />
        ) : (
          <InformationMerchandisePackages merchandise={booking.merchandise} />
        ))}
      {booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_TOTAL && (
        <InformationMerchandiseTotal merchandise={booking.merchandise} />
      )}
      {booking?.merchandise?.merchandiseType === MERCHANDISE_TYPE_PACKAGE_TOTAL && (
        <InformationMerchandisePackageTotal merchandise={booking.merchandise} />
      )}
    </>
  )
}

export default InformationMerchandise
