import { object, string, number, InferType, mixed, array, tuple } from 'yup'

export enum PathQuality {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NO_DATA = 'no_data',
}

const pointSchema = object({
  coordinates: tuple([number().required(), number().required()]).required(),
  type: string().required(),
})

const lineString = object({
  coordinates: array()
    .of(tuple([number().required(), number().required()]).required())
    .required(),
  type: mixed<'LineString'>().oneOf(['LineString']).required(),
})

const polygonSchema = object({
  coordinates: array()
    .of(
      array()
        .of(tuple([number().required(), number().required()]).required())
        .required()
    )
    .required(),
  type: mixed<'Polygon'>().oneOf(['Polygon']).required(),
})

const pathSchema = object({
  departureGeometryId: number().required(),
  arrivalGeometryId: number().required(),
  path: lineString.required(),
})

const portInfoSchema = object({
  id: number().required(),
  centroid: pointSchema.required(),
  geometry: polygonSchema.nullable().defined(),
  name: string().nullable().defined(),
})

const legInfoSchema = object({
  departureLocode: string().required(),
  arrivalLocode: string().required(),
  pathQuality: mixed().oneOf(Object.values(PathQuality)).required(),
  paths: array().of(pathSchema).nullable().defined(),
})
export const mapInfoSchema = object({
  ports: array().of(portInfoSchema).required(),
  legs: array().of(legInfoSchema).required(),
})

export type MapInfo = InferType<typeof mapInfoSchema>
