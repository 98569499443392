import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import {
  StyledBlockContainerAddBlock,
  StyledButton,
  StyledButtonText,
} from 'components/block_container/style'

import Icon from 'components/icon'
import { numberInferiorEqualTo } from 'services/helpers/prop_types'
import { BlockContainerProps } from 'components/block_container/types'

type AdditionnalProps = {
  addBlock: () => void
}
export type BlockContainerDuplicateProps = Pick<
  BlockContainerProps,
  'columns' | 'btnSize' | 'title' | 'testId' | 'itemGender'
>

type BlockContainerAddProps = BlockContainerDuplicateProps & AdditionnalProps

const BlockContainerAdd = ({
  addBlock,
  columns,
  btnSize,
  title,
  testId,
  itemGender,
}: BlockContainerAddProps) => {
  const { t } = useTranslation()
  return (
    <StyledBlockContainerAddBlock $columns={columns} $btnSize={btnSize}>
      <StyledButton onClick={addBlock} type='button' data-testid={testId}>
        <Icon name='plus_outline' />
        <StyledButtonText>
          {t('actions.addNew', { context: itemGender ?? 'male' })} {title}
        </StyledButtonText>
      </StyledButton>
    </StyledBlockContainerAddBlock>
  )
}

BlockContainerAdd.propTypes = {
  /** title */
  title: PropTypes.string,
  /** itemGender */
  itemGender: PropTypes.string,
  /** addBlock */
  addBlock: PropTypes.func,
  /** columns */
  columns: numberInferiorEqualTo(4, false),
  /** btnSize */
  btnSize: numberInferiorEqualTo(2, false),
  /** testId */
  testId: PropTypes.string,
}

BlockContainerAdd.defaultProps = {
  title: undefined,
  itemGender: undefined,
  addBlock: undefined,
  btnSize: undefined,
  columns: undefined,
  testId: undefined,
}
export default BlockContainerAdd
