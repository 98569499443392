import React from 'react'

import { useTranslation } from 'react-i18next'

import { useTheme } from 'styled-components'

import S from 'features/users/components/user_list/item/style'
import HoverTooltip from 'components/hover_tooltip'
import { ROLE_READ, USER_ACTIVATE, USER_COMPANY_READ, USER_READ } from 'constants/permissions'
import {
  getTestIdForUserActivated,
  getTestIdForUserCompany,
  getTestIdForUserEmail,
  getTestIdForUserFullname,
  getTestIdForUserOrganization,
  getTestIdForUserRoles,
  getTestIdForUserSettingsButton,
} from 'tests/e2e/test_ids'
import Icon from 'components/icon'
import SwitchInput from 'components/switch_input'
import Button from 'components/button'
import { User } from 'features/users/types/user'
import useUserCan from 'views/iam/hooks/use_user_can'

interface UserItemProps {
  user: User
  toggleUserActivation: (user: User) => void
  openUserSettings: (user: User) => void
  index: number
}

const UserItem: React.FC<UserItemProps> = ({
  user,
  index,
  toggleUserActivation,
  openUserSettings,
}) => {
  const userRoles = user.roles?.map((r) => r[0]).join(', ')
  const userCan = useUserCan()
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <S.User key={`user-${user.id}`}>
      <S.Column key={`user-name-${user.id}`}>
        <HoverTooltip
          delayed
          ellipsis
          content={`${t('users.legend.name')} - ${user.firstName} ${user.lastName}${
            userCan(USER_READ) ? ` (${user.id})` : ''
          }
                      `}
          testId={getTestIdForUserFullname(index)}
        >
          {`${user.firstName} ${user.lastName}${userCan(USER_READ) ? ` (${user.id})` : ''}`}
        </HoverTooltip>
      </S.Column>
      <S.Column key={`user-email-${user.id}`}>
        <HoverTooltip
          delayed
          ellipsis
          content={`${t('users.legend.email')} - ${user.email}`}
          testId={getTestIdForUserEmail(index)}
        >
          {user.email}
        </HoverTooltip>
      </S.Column>
      {userCan(USER_READ) && (
        <S.Column key={`user-organization-${user.id}`}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <HoverTooltip
              delayed
              ellipsis
              content={`${t('users.legend.organization')} - ${user.organizationName} (${
                user.organizationId
              })`}
              testId={getTestIdForUserOrganization(index)}
            >
              {user.organizationName} ({user.organizationId})
            </HoverTooltip>
            <HoverTooltip
              // TODO: Refactor using IconTooltip component
              delayed
              content={user.hasSso ? t('users.sso.enabled') : t('users.sso.disabled')}
            >
              <Icon
                name='key'
                fill={user.hasSso ? theme.primary : 'transparent'}
                stroke={user.hasSso ? theme.primary : theme.mediumGray}
                width={15}
                height={15}
              />
            </HoverTooltip>
          </div>
        </S.Column>
      )}
      {userCan(USER_COMPANY_READ) && (
        <S.Column key={`user-company-${user.id}`}>
          {user.companyName && (
            <HoverTooltip
              delayed
              ellipsis
              content={`${t('users.legend.company')} - ${user.companyName} (${user.companyId})`}
              testId={getTestIdForUserCompany(index)}
            >
              {user.companyName} ({user.companyId})
            </HoverTooltip>
          )}
        </S.Column>
      )}
      {userCan(ROLE_READ) && (
        <S.Column key={`user-roles-${user.id}`}>
          <HoverTooltip
            delayed
            ellipsis
            content={`${t('users.legend.roles')} - ${userRoles}`}
            testId={getTestIdForUserRoles(index)}
          >
            {userRoles}
          </HoverTooltip>
        </S.Column>
      )}
      <S.Column key={`user-activation-${user.id}`} $centered>
        <SwitchInput
          disabled={!userCan(USER_ACTIVATE)}
          leftLabel='OFF'
          rightLabel='ON'
          name='activation'
          rightOptionChecked={user.active}
          onClick={() => toggleUserActivation(user)}
          variant='outline'
          testId={getTestIdForUserActivated(index)}
        />
      </S.Column>
      <S.Column key={`user-actions-${user.id}`} $centered $small>
        <Button
          variant='smallIcon'
          icon='cog'
          testId={getTestIdForUserSettingsButton(index)}
          onClick={() => {
            openUserSettings(user)
          }}
        />
      </S.Column>
    </S.User>
  )
}

export default UserItem
