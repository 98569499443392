import { isPresent } from 'services/helpers/values'
import useCurrentUser from 'views/iam/hooks/use_current_user'

import { nullCompany } from 'views/iam/slices/iamSlice'

import type { Capacity, Company } from 'types/companies'

const useCurrentCompany = (): {
  company: Company
  ownsCapacity: (capacity: Capacity) => boolean
} => {
  const user = useCurrentUser()
  const company = (user.company || nullCompany) as Company
  return {
    company,
    ownsCapacity: (capacity: Capacity) => isPresent(company.capacities.find((c) => c === capacity)),
  }
}

export default useCurrentCompany
