import { InferType, mixed, object, string } from 'yup'

import { LOCALES, SupportedLanguage } from 'constants/locales'

export const languageSchema = object({
  language: object({
    value: mixed<SupportedLanguage>().oneOf(LOCALES).required(),
    label: string().required(),
    slot: mixed(),
  }),
}).required()
export type LanguageData = InferType<typeof languageSchema>
