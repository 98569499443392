import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'

import { STATUS_FULFILLED, STATUS_PENDING, STATUS_REJECTED } from 'constants/api'

import { fetchOngoingShipments } from 'features/shipments/services/vehicle_service'

import { fetchModalShipmentsByVehicleId } from 'features/shipments/services/shipment_service'

import type { Status } from 'constants/api'

import type { RootState } from 'services/store/store'
import type { Vehicle } from 'features/shipments/types/vehicle'

const dashboardAdapter = createEntityAdapter<Vehicle>()

const initialState = dashboardAdapter.getInitialState<{ status: Status; vehicles: Vehicle[] }>({
  status: STATUS_PENDING,
  vehicles: [],
})

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOngoingShipments.fulfilled, (state, action) => {
      dashboardAdapter.setAll(state, action.payload)
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchOngoingShipments.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchOngoingShipments.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(fetchModalShipmentsByVehicleId.fulfilled, (state, action) => {
      dashboardAdapter.updateOne(state, {
        id: action.meta.arg.vehicleId,
        changes: { shipments: action.payload },
      })
    })
  },
})
export const { selectAll: selectOngoingShipments, selectById: selectDashboardByVehicleById } =
  dashboardAdapter.getSelectors((state: RootState) => state.dashboard)

export const selectDashboardMapShipmentsByVehicleId = ({ id }: { id: string }) =>
  createSelector(
    (state: RootState) => selectDashboardByVehicleById(state, id),
    (vehicle) => vehicle?.shipments || []
  )

export const selectOngoingShipmentsStatus = (state: RootState) => state.dashboard.status
export default dashboardSlice.reducer
