export const BREAKPOINTS = {
  mobileSmall: 370,
  mobile: 480,
  tablet: 768,
  desktop: 1024,
  desktopLarge: 1440,
}

export const mobileSmall = {
  up: `(min-width: ${BREAKPOINTS.mobileSmall}px)`,
  down: `(max-width: ${BREAKPOINTS.mobileSmall - 1}px)`,
}

export const mobile = {
  up: `(min-width: ${BREAKPOINTS.mobile}px)`,
  down: `(max-width: ${BREAKPOINTS.mobile - 1}px)`,
}

export const tablet = {
  up: `(min-width: ${BREAKPOINTS.tablet}px)`,
  down: `(max-width: ${BREAKPOINTS.tablet - 1}px)`,
}

export const desktop = {
  up: `(min-width: ${BREAKPOINTS.desktop}px)`,
  down: `(max-width: ${BREAKPOINTS.desktop - 1}px)`,
}

export const desktopLarge = {
  up: `(min-width: ${BREAKPOINTS.desktopLarge}px)`,
  down: `(max-width: ${BREAKPOINTS.desktopLarge - 1}px)`,
}
