import useFilter from 'services/hooks/use_filter'

const useFilters = () => {
  const sectionFilter = useFilter({ name: 'section', type: 'select' })
  const segmentFilter = useFilter({ name: 'segmentIndex', type: 'select' })
  const actorFilter = useFilter({ name: 'source', type: 'select' })
  const stepFilter = useFilter({ name: 'stepType', type: 'select' })
  const milestoneFilter = useFilter({ name: 'milestoneType', type: 'select' })

  return {
    sectionFilter,
    segmentFilter,
    actorFilter,
    stepFilter,
    milestoneFilter,
  }
}

export default useFilters
