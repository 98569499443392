import { useHistory, useLocation } from 'react-router-dom'

import useOnce from 'services/hooks/use_once'
import useModal from 'components/modal/hooks/use_modal'

interface UseNavigation {
  isAlerts: boolean
}

// Will open settings modal on alerts tab _once_ when url hash is 'alerts'
const useNavigation = (): UseNavigation => {
  const { pathname, hash } = useLocation()
  const history = useHistory()
  const { setOpen } = useModal('settings')
  const isAlerts = hash === '#alerts'
  useOnce(() => {
    if (isAlerts) {
      setOpen(true)
      history.replace(pathname)
    }
  })

  return {
    isAlerts,
  }
}

export default useNavigation
