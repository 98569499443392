import { useEffect, useState, useCallback, useContext } from 'react'

import { FormContext } from 'services/providers/form_provider'

const useForm = ({ categories, onApply = () => {} }) => {
  const queryParams = categories.reduce((acc, c) => {
    Object.assign(acc, c.queryParams)
    return acc
  }, {})
  const editedQueryParams = categories.reduce((acc, c) => {
    Object.assign(acc, c.editedQueryParams)
    return acc
  }, {})
  const reset = () => categories.forEach((c) => c.resetFilters())
  const isEdited = categories.some((c) => c.isEdited)
  const isValid = categories.every((c) => c.isValid)
  const [willApply, setWillApply] = useState(false)
  const toggleWillApply = useCallback(() => setWillApply(!willApply), [setWillApply, willApply])

  useEffect(() => {
    if (willApply) {
      toggleWillApply()
      onApply()
    }
  }, [willApply, onApply, toggleWillApply])

  const { setFilters, setCategories, setQueryParams } = useContext(FormContext)

  // TODO: Refactor. Changes in categories are hard to properly track
  // and this useEffect triggers unnecessary updates
  useEffect(
    () => {
      setFilters(categories.map((c) => c.filters).flat())
      setCategories(categories)
      setQueryParams(queryParams)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ...categories
        .map((c) => c.filters)
        .flat()
        .map((f) => f.value),
    ]
  )

  return {
    apply: toggleWillApply,
    reset,
    queryParams,
    editedQueryParams,
    isEdited,
    isValid,
  }
}

export default useForm
