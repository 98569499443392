import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Sidebar from 'components/sidebar'
import Button from 'components/button'
import Icon from 'components/icon'
import S from 'components/filters_sidebar/style'
import AccordionItem from 'components/accordion_item'
import { TEST_ID_BOOKING_FILTERS_APPLY, TEST_ID_SIDE_BAR_CLEAR_BUTTON } from 'tests/e2e/test_ids'

interface FiltersSidebarComp {
  Section: typeof Section
}

interface FiltersSidebarProps {
  onClear: () => void
  onApply: () => void
  isClearDisabled?: boolean
  isApplyDisabled?: boolean
  header?: ReactElement
}

// TODO: REFACTOR
// header prop is poorly designed. It should be a compound component, but FiltersSidebar is
// not really designed for it
const FiltersSidebar: FC<FiltersSidebarProps> & FiltersSidebarComp = ({
  // Proptypes is not inferred correctly because of FC extension
  /* eslint-disable react/prop-types */
  onClear,
  onApply,
  header,
  isClearDisabled = false,
  isApplyDisabled = false,
  children,
  /* eslint-enable react/prop-types */
}) => {
  const { t } = useTranslation()

  return (
    <Sidebar>
      <Sidebar.Header>
        <S.Header>
          <S.HeaderIcon as={Icon} name='settings' />
          <Sidebar.Title>{t('filtersSidebar.title')}</Sidebar.Title>
        </S.Header>
        {header}
      </Sidebar.Header>
      <Sidebar.Content>
        <S.Content>{children}</S.Content>
      </Sidebar.Content>
      <Sidebar.Footer>
        <S.Actions>
          <S.Action
            testId={TEST_ID_SIDE_BAR_CLEAR_BUTTON}
            as={Button}
            text={t('actions.clear')}
            variant='clear'
            onClick={onClear}
            disabled={isClearDisabled}
          />
          <S.Action
            as={Button}
            text={t('actions.apply')}
            variant='highlight'
            onClick={onApply}
            disabled={isApplyDisabled}
            testId={TEST_ID_BOOKING_FILTERS_APPLY}
          />
        </S.Actions>
      </Sidebar.Footer>
    </Sidebar>
  )
}
interface SectionProps {
  title?: string
  defaultOpened?: boolean
  testId?: string
}
const Section: FC<SectionProps> = ({ children, title, defaultOpened = false, testId }) => (
  <S.Section data-testid={testId}>
    <AccordionItem indicator='more' heading={title} defaultOpened={defaultOpened} small>
      {children}
    </AccordionItem>
  </S.Section>
)
FiltersSidebar.Section = Section

export default FiltersSidebar
