import { createSlice, createAsyncThunk, createEntityAdapter, Comparer } from '@reduxjs/toolkit'

import { Tvalue } from 'components/select'

import { STATUS_PENDING, STATUS_FULFILLED, STATUS_REJECTED } from 'constants/api'
import { COMPANY_TYPE_CLIENT } from 'constants/companies'

import { InternalClient } from 'services/api/clients'
import onError from 'services/api/error'
import useUrlParams from 'services/api/hooks/use_url_params'
import DateHelper from 'services/helpers/date_helper'

import type { Status } from 'constants/api'
import type { Company } from 'views/network/companies/types'
import type { RootState } from 'services/store/store'

const NETWORK_COMPANIES_PER = 100

export const fetchNetworkCompanies = createAsyncThunk(
  'networkCompanies/fetchNetworkCompanies',
  async ({ page, searchValue }: { page: number; searchValue: string }, thunkAPI) => {
    const url = useUrlParams(
      '/network/companies',
      {
        page,
        per: NETWORK_COMPANIES_PER,
      },
      { escapedParams: { 'q[name_cont]': searchValue } }
    )
    return InternalClient.get(url)
      .then((r) => r.data)
      .then(({ items }) => items)
      .catch(onError(thunkAPI))
  }
)

export interface CompanyProps {
  address?: string
  city?: string
  countryId?: Tvalue
  lat?: number
  lng?: number
  name?: string
  capacities?: string[]
}

export const updateNetworkCompany = createAsyncThunk(
  'networkCompanies/updateNetworkCompany',
  async ({ id, changes }: { id: string; changes: CompanyProps }, thunkAPI) =>
    InternalClient.patch(`/network/companies/${id}`, changes)
      .then((r) => r.data)
      .then((company) => ({ id: company.id, changes: company }))
      .catch(onError(thunkAPI))
)
export const createNetworkCompany = createAsyncThunk(
  'networkCompanies/createNetworkCompany',
  async ({ company }: { company: CompanyProps }, thunkAPI) =>
    InternalClient.post('/network/companies', { companyType: COMPANY_TYPE_CLIENT, ...company })
      .then((r) => r.data)
      .catch(onError(thunkAPI))
)
const sortByUpdatedAtDesc: Comparer<Company> = (a, b) =>
  new DateHelper(a.updatedAt).isBefore(b.updatedAt) ? 1 : -1
const networkCompaniesAdapter = createEntityAdapter<Company>({
  sortComparer: sortByUpdatedAtDesc,
})
const initialState = networkCompaniesAdapter.getInitialState<{ status: Status }>({
  status: STATUS_PENDING,
})

const networkCompaniesSlice = createSlice({
  name: 'networkCompanies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNetworkCompanies.fulfilled, (state, action) => {
      if (action.meta.arg.page <= 1) {
        networkCompaniesAdapter.setAll(state, action.payload)
      } else {
        networkCompaniesAdapter.setMany(state, action.payload)
      }
      state.status = STATUS_FULFILLED
    })
    builder.addCase(fetchNetworkCompanies.pending, (state) => {
      state.status = STATUS_PENDING
    })
    builder.addCase(fetchNetworkCompanies.rejected, (state) => {
      state.status = STATUS_REJECTED
    })
    builder.addCase(updateNetworkCompany.fulfilled, networkCompaniesAdapter.updateOne)
    builder.addCase(createNetworkCompany.fulfilled, networkCompaniesAdapter.addOne)
  },
})
export const { selectAll: selectNetworkCompanies, selectById: selectNetworkCompany } =
  networkCompaniesAdapter.getSelectors((state: RootState) => state.networkCompanies)
export const selectNetworkCompaniesStatus = (state: RootState) => state.networkCompanies.status
export default networkCompaniesSlice.reducer
