import queryString from 'query-string'

import { isAnyArray, toSnakeCaseKeys } from 'services/helpers/values'

interface QueryParam {
  [key: string]: any
}

interface Options {
  escapedParams?: QueryParam
}

const useUrlParams = (url: string, params: QueryParam, options: Options = {}): string => {
  const isValueObject = (value: any) =>
    typeof value === 'object' && !Array.isArray(value) && value !== null

  const hasParamsNestedObjects = (p: QueryParam) => isValueObject(p) && isAnyArray(Object.values(p))

  const stringifyNestedObjects = (parameters: QueryParam, applyToSnakeCase: boolean) => {
    const paramValue = applyToSnakeCase ? toSnakeCaseKeys(parameters) : parameters
    return Object.entries(paramValue).reduce(
      (acc, [k, v]) => ({ ...acc, [k]: isValueObject(v) ? JSON.stringify(v) : v }),
      {}
    )
  }
  const queryParams = hasParamsNestedObjects(params) ? stringifyNestedObjects(params, true) : params

  const escapedParams = options.escapedParams || {}

  const queryEscapedParams = hasParamsNestedObjects(escapedParams)
    ? stringifyNestedObjects(escapedParams, false)
    : escapedParams

  return queryString.stringifyUrl(
    { url, query: { ...queryParams, ...queryEscapedParams } },
    { arrayFormat: 'bracket' }
  )
}

export default useUrlParams
