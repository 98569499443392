import styled from 'styled-components'

import { h2 } from 'styles/utils/texts'

export const sidebarWidth = '390px'
export const sidebarPadding = '20px'

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: ${sidebarWidth};
  height: 100%;
}
`
export const Header = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  max-height: 40%;
`

export const Title = styled.h2`
  ${h2}
`

export const Content = styled.div`
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
`

export const Footer = styled.div`
  padding: ${sidebarPadding};
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.03);
  background: ${({ theme }) => theme.white};
`

const S = {
  Sidebar,
  Title,
  Header,
  Content,
  Footer,
}

export default S
