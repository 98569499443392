import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Controller, useForm } from 'react-hook-form'

import { isPresent, toSnakeCaseKeys } from 'services/helpers/values'

import {
  CompanyProps,
  createNetworkCompany,
  selectNetworkCompany,
  updateNetworkCompany,
} from 'views/network/companies/slice'
import { fetchCountryOptions } from 'views/select_options/slice'
import { addNotification } from 'views/notifications/slice'

import Select, { SelectValue } from 'components/select'
import Input from 'components/input'
import Button from 'components/button'
import Grid from 'components/grid'
import Form from 'components/form'
import {
  StyledCompanyForm,
  StyledCompanyFormButton,
  StyledCompanyFormSubtitle,
  StyledCompanyFormTitle,
} from 'components/company_form/style'
import IconTooltip from 'components/icon_tooltip'

import useAppDispatch from 'services/hooks/use_app_dispatch'
import { StyledForm } from 'components/form/style'
import {
  TEST_ID_NETWORK_COMPANY_FORM_ADDRESS_INPUT,
  TEST_ID_NETWORK_COMPANY_FORM_CAPACITIES_SELECT,
  TEST_ID_NETWORK_COMPANY_FORM_CITY_INPUT,
  TEST_ID_NETWORK_COMPANY_FORM_COUNTRY_SELECT,
  TEST_ID_NETWORK_COMPANY_FORM_LATITUDE_INPUT,
  TEST_ID_NETWORK_COMPANY_FORM_LONGITUDE_INPUT,
  TEST_ID_NETWORK_COMPANY_FORM_NAME_INPUT,
} from 'tests/e2e/test_ids'
import useStaticLocales from 'views/locales/hooks/use_static_locales'
import MultiSelect from 'components/multiselect'

interface CompanyFormProps {
  id: string
  capacities: SelectValue[]
  address: {
    address: string
    city: string
    country: SelectValue
    lat: number
    lng: number
  }
  name: string
  updatedAt: string
}

const LATITUDE_LIMITATIONS = { min: -90, max: 90 }
const LONGITUDE_LIMITATIONS = { min: -180, max: 180 }

const CompanyForm = ({
  onClose,
  id: companyId,
}: {
  onClose: () => void | undefined
  id: string | null
}) => {
  const isEdit = isPresent(companyId)
  const currentCompany = useSelector((state: any) => selectNetworkCompany(state, companyId ?? ''))
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { s } = useStaticLocales()
  const capacities = toSnakeCaseKeys(s('capacities'))

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, dirtyFields, isValid },
  } = useForm<CompanyFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      id: currentCompany?.id,
      capacities:
        currentCompany?.capacities.map((capacity) => ({
          value: capacity,
          label: capacities[capacity],
        })) || [],
      address: {
        address: currentCompany?.address?.address,
        city: currentCompany?.address?.city,
        country: currentCompany?.address.country
          ? {
              value: currentCompany?.address.country,
              label: currentCompany?.address.country,
            }
          : undefined,
        lat: currentCompany?.address?.lat,
        lng: currentCompany?.address?.lng,
      },
      name: currentCompany?.name,
    },
  })

  const availableCapacities = Object.entries(capacities)
    .map(([key, value]: [string, any]) => ({ value: key, label: value }))
    .filter(
      (c) =>
        !watch('capacities')
          .map((cap) => cap.toString())
          .includes(c.value)
    )

  const updateCompany = ({ id, changes }: { id: string; changes: CompanyProps }) => {
    dispatch(
      updateNetworkCompany({
        id,
        changes,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('network.companies.actions.update'),
            text: t('network.companies.actions.updateSuccess', { name: changes.name }),
          })
        )
        if (onClose) onClose()
      })
      .catch(() =>
        addNotification({
          type: 'alert',
          title: t('errors.notification.title'),
          text: t('errors.notification.content'),
        })
      )
  }

  const createCompany = (data: CompanyFormProps) => {
    dispatch(
      createNetworkCompany({
        company: {
          name: data.name,
          address: data.address.address,
          city: data.address.city,
          countryId: data.address.country.value,
          lat: data.address.lat,
          lng: data.address.lng,
          capacities: data.capacities.map((c) => c.value?.toString() ?? ''),
        },
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          addNotification({
            type: 'success',
            title: t('network.companies.actions.create'),
            text: t('network.companies.actions.creationSuccess', { name: data.name }),
          })
        )
        if (onClose) onClose()
      })
      .catch(() =>
        addNotification({
          type: 'alert',
          title: t('errors.notification.title'),
          text: t('errors.notification.content'),
        })
      )
  }

  const onSubmit = (data: CompanyFormProps): void => {
    if (isEdit) {
      const companyChanges = Object.fromEntries(
        Object.entries({
          name: dirtyFields.name ? data.name : undefined,
          address: dirtyFields.address?.address ? data.address.address : undefined,
          city: dirtyFields.address?.city ? data.address.city : undefined,
          countryId: dirtyFields.address?.country ? data.address.country.value : undefined,
          lat: dirtyFields.address?.lat ? data.address.lat : undefined,
          lng: dirtyFields.address?.lng ? data.address.lng : undefined,
          capacities: dirtyFields.capacities
            ? data.capacities.map((c) => c.value?.toString() ?? '')
            : undefined,
        }).filter(([, v]) => isPresent(v))
      )

      updateCompany({ id: data.id, changes: companyChanges })
    } else createCompany(data)
  }

  return (
    <StyledCompanyForm>
      <StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledCompanyFormTitle>
          {isEdit ? t('network.companies.editTitle') : t('network.companies.createTitle')}{' '}
          {t('network.companies.company')}
        </StyledCompanyFormTitle>
        <Form.Group>
          <StyledCompanyFormSubtitle>{t('network.companies.details')}</StyledCompanyFormSubtitle>
          <Grid columns={2} columnsTablet={2} columnsMobile={1}>
            <Grid.Row>
              <Grid.Column>
                <Input
                  label={t('network.companies.fields.name')}
                  type='text'
                  id='name'
                  {...register('name', { required: true })}
                  error={errors.name?.type ? t(`errors.validation.${errors.name.type}`) : undefined}
                  required
                  testId={TEST_ID_NETWORK_COMPANY_FORM_NAME_INPUT}
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  label={t('network.companies.fields.city')}
                  type='text'
                  id='city'
                  {...register('address.city', { required: true })}
                  error={
                    errors.address?.city?.type
                      ? t(`errors.validation.${errors.address.city.type}`)
                      : undefined
                  }
                  required
                  testId={TEST_ID_NETWORK_COMPANY_FORM_CITY_INPUT}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Input
                  label={t('network.companies.fields.address')}
                  type='text'
                  id='address'
                  {...register('address.address', { required: true })}
                  error={
                    errors.address?.address?.type
                      ? t(`errors.validation.${errors.address.address.type}`)
                      : undefined
                  }
                  required
                  testId={TEST_ID_NETWORK_COMPANY_FORM_ADDRESS_INPUT}
                />
              </Grid.Column>
              <Grid.Column>
                <Controller
                  name='address.country'
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      label={t('network.companies.fields.country')}
                      name='country'
                      isSearchable
                      value={field.value}
                      error={
                        errors.address?.country?.type
                          ? t(`errors.validation.${errors.address.country.type}`)
                          : undefined
                      }
                      onChange={({ value }) => field.onChange(value)}
                      async
                      fetch={({ value }) => fetchCountryOptions({ search: value })}
                      fetchOnFocus={() => fetchCountryOptions({ search: null })}
                      required
                      testId={TEST_ID_NETWORK_COMPANY_FORM_COUNTRY_SELECT}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <StyledCompanyFormSubtitle>
            {t('network.companies.coordinates.title')}
            <IconTooltip
              size='large'
              placement='right'
              content={t('network.companies.coordinates.tips')}
            />
          </StyledCompanyFormSubtitle>
          <Grid columns={2} columnsTablet={2} columnsMobile={1}>
            <Grid.Row>
              <Grid.Column>
                <Input
                  label={t('network.companies.fields.latitude')}
                  type='number'
                  id='latitude'
                  {...register('address.lat', {
                    validate: {
                      betweenLatitudeLimitations: (lat) =>
                        (LATITUDE_LIMITATIONS.min <= lat && lat <= LATITUDE_LIMITATIONS.max) ||
                        t('errors.validation.betweenLatitudeLimitations'),
                    },
                  })}
                  error={errors.address?.lat?.message}
                  testId={TEST_ID_NETWORK_COMPANY_FORM_LATITUDE_INPUT}
                />
              </Grid.Column>
              <Grid.Column>
                <Input
                  label={t('network.companies.fields.longitude')}
                  type='number'
                  id='longitude'
                  {...register('address.lng', {
                    validate: {
                      betweenLongitudeLimitations: (lng) =>
                        (LONGITUDE_LIMITATIONS.min <= lng && lng <= LONGITUDE_LIMITATIONS.max) ||
                        t('errors.validation.betweenLongitudeLimitations'),
                    },
                  })}
                  error={errors.address?.lng?.message}
                  testId={TEST_ID_NETWORK_COMPANY_FORM_LONGITUDE_INPUT}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <StyledCompanyFormSubtitle>
            {t('network.companies.capacities.title')}
          </StyledCompanyFormSubtitle>
          <Grid columns={1} columnsTablet={1} columnsMobile={1}>
            <Grid.Row>
              <Grid.Column>
                <Controller
                  name='capacities'
                  control={control}
                  render={({ field }) => (
                    <MultiSelect
                      placeholder={t('network.companies.capacities.title')}
                      name={field.name}
                      isSearchable
                      value={field.value}
                      onChange={({ value }) => field.onChange(value)}
                      options={availableCapacities}
                      testId={TEST_ID_NETWORK_COMPANY_FORM_CAPACITIES_SELECT}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Group>
        <StyledCompanyFormButton>
          <Button
            text={t('actions.cancel')}
            variant='clear'
            onClick={() => (onClose ? onClose() : null)}
          />
          <Button
            disabled={!isValid || !isDirty}
            text={
              isEdit
                ? t('network.companies.actions.updateCta')
                : t('network.companies.actions.createCta')
            }
            variant='highlight'
            type='submit'
          />
        </StyledCompanyFormButton>
      </StyledForm>
    </StyledCompanyForm>
  )
}

export default CompanyForm
