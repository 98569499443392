import styled from 'styled-components'

export const StyledModalDescription = styled.div`
  padding-bottom: 30px;
`

export const StyledModalDatepickers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
