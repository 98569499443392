import React from 'react'

import { defaultTheme } from 'styles/theme'
// eslint-disable-next-line import/no-named-default
import { default as Logo } from 'assets/logos/logo.svg'

const LegacyBrowsers = () => (
  <div
    style={{
      background: defaultTheme.primary,
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '500px',
        padding: '40px 60px 60px',
        background: '#fff',
        borderRadius: '24px',
      }}
    >
      <div>
        <h1 style={{ marginBottom: '20px' }}>
          <img src={Logo} alt='logo' />{' '}
          <span
            style={{ fontFamily: 'Sora', fontWeight: 700, fontStyle: 'normal', color: '#0D4B5C' }}
          >
            wakeo
          </span>
        </h1>
      </div>
      <div>
        <p
          style={{
            marginTop: '18px',
            textAlign: 'center',
            fontFamily: 'Sora',
          }}
        >
          We are sorry, but your browser does not allow{' '}
          <span
            style={{ fontFamily: 'Sora', fontWeight: 700, fontStyle: 'normal', color: '#0D4B5C' }}
          >
            wakeo
          </span>{' '}
          app to work properly.
        </p>
        <p
          style={{
            alignItems: 'center',
            marginTop: '18px',
            textAlign: 'center',
            fontFamily: 'Sora',
          }}
        >
          You can update your browser or download a compatible browser{' '}
          <a
            href='https://updatemybrowser.org'
            target='_blank'
            rel='noreferrer'
            style={{
              color: defaultTheme.primary,
              textDecoration: 'none',
              fontWeight: 600,
            }}
          >
            here
          </a>
          .
        </p>
      </div>
    </div>
  </div>
)

export default LegacyBrowsers
