import React from 'react'
import { Transition as ReactTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import { anyChildren } from 'services/helpers/prop_types'

import StyledTransition from 'components/transition/style'

export const TRANSITION_TYPES = ['fade-in']

const Transition = ({ in: inTransition, duration, type, children }) => (
  <ReactTransition appear in={inTransition} timeout={duration}>
    {(state) => (
      <StyledTransition $state={state} $type={type}>
        {children}
      </StyledTransition>
    )}
  </ReactTransition>
)

Transition.propTypes = {
  /** Start enter animation if true, else start exit animation */
  in: PropTypes.bool,
  /** Animation steps duration */
  duration: PropTypes.number,
  /** Animation type */
  type: PropTypes.oneOf(TRANSITION_TYPES),
  /** Children */
  children: anyChildren,
}

Transition.defaultProps = {
  in: false,
  duration: 0,
  type: 'fade-in',
  children: undefined,
}

export default Transition
