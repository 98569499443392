import React, { useContext } from 'react'

import { StyledBlockDocuments } from 'views/booking/components/show/style'
import { StyledBlockDocumentsItem } from 'components/block_documents/style'
import BlockDocuments from 'components/block_documents'
import BlockDocumentsItem from 'components/block_documents/block_documents_item'
import BookingNewContext from 'views/booking/contexts/booking_new_context'
import ModalUploadDocument from 'components/modal_upload_document'
import { StyledBookingFormDocuments } from 'views/booking/components/form/style'
import DocumentContext from 'views/documents/contexts/document_context'
import { TEST_ID_BOOKING_FORM_SELECT_DOCUMENT_TYPE } from 'tests/e2e/test_ids'

const BookingFormDocumentsModal = () => {
  const { uploadedFiles, setUploadedFiles } = useContext(BookingNewContext)

  const updateUploadedFiles = (uploads) => setUploadedFiles([...uploadedFiles, ...uploads])

  const deleteDocument = (fileId) =>
    setUploadedFiles(uploadedFiles.filter(({ id }) => id !== fileId))

  return (
    <DocumentContext.Provider value={{ canUpload: true }}>
      <StyledBookingFormDocuments>
        <ModalUploadDocument
          onSuccessUpload={updateUploadedFiles}
          deferUpload
          selectTestId={TEST_ID_BOOKING_FORM_SELECT_DOCUMENT_TYPE}
        />
        <StyledBlockDocuments as={BlockDocuments}>
          {uploadedFiles.map((document) => {
            const {
              id: did,
              nativeFile,
              fileType: { label: type },
            } = document

            return (
              <StyledBlockDocumentsItem
                as={BlockDocumentsItem}
                key={`document-${did}`}
                name={nativeFile.name}
                type={type}
                createdAt={new Date()}
                canDelete
                onDelete={() => deleteDocument(did)}
                link={URL.createObjectURL(nativeFile)}
              />
            )
          })}
        </StyledBlockDocuments>
      </StyledBookingFormDocuments>
    </DocumentContext.Provider>
  )
}

export default BookingFormDocumentsModal
