import React, { FC } from 'react'

import AccordionItem from 'components/accordion_item'
import Icon, { IconType } from 'components/icon'
import {
  StyledFormAccordion,
  StyledFormAccordionHead,
  StyledFormAccordionHeadIcon,
  StyledFormAccordionHeadTitle,
  StyledFormAccordionContent,
} from 'components/form/style'

interface FormAccordionProps {
  icon: IconType
  name?: string
  defaultOpened?: boolean
}

const Accordion: FC<FormAccordionProps> = ({ icon, name, children, defaultOpened = false }) => (
  <StyledFormAccordion
    as={AccordionItem}
    defaultOpened={defaultOpened}
    heading={
      <StyledFormAccordionHead>
        <StyledFormAccordionHeadIcon as={Icon} name={icon} />
        <StyledFormAccordionHeadTitle>{name}</StyledFormAccordionHeadTitle>
      </StyledFormAccordionHead>
    }
  >
    <StyledFormAccordionContent>{children}</StyledFormAccordionContent>
  </StyledFormAccordion>
)

export default Accordion
