import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentContext from 'components/shipment_list_item/context'
import useReferences from 'components/shipment_list_item/hooks/use_references'
import { StyledShipmentListItemRef } from 'components/shipment_list_item/style'

import useCurrentUser from 'views/iam/hooks/use_current_user'

import type { Reference } from 'components/shipment_list_item/hooks/use_references'

const Parties: FC = () => {
  const { shipment } = useContext(ShipmentContext)
  const {
    parties: { shipper, forwarder, consignor, consignee },
  } = shipment
  const {
    profile: { settings },
  } = useCurrentUser()
  const { t } = useTranslation()
  const defaultValue = 'n/a'
  const references: Reference[] = [
    {
      Component: StyledShipmentListItemRef,
      value: shipper || defaultValue,
      show: settings.shipmentIndexDisplayShipper,
      name: 'shipper',
      tooltipName: t('shipments.parties.shipper'),
    },
    {
      Component: StyledShipmentListItemRef,
      value: forwarder || defaultValue,
      show: settings.shipmentIndexDisplayForwarder,
      name: 'forwarder',
      tooltipName: t('shipments.parties.forwarder'),
    },
    {
      Component: StyledShipmentListItemRef,

      value: consignor || defaultValue,
      show: settings.shipmentIndexDisplayConsignor,
      name: 'consignor',
      tooltipName: t('shipments.parties.consignor'),
    },
    {
      Component: StyledShipmentListItemRef,

      value: consignee || defaultValue,
      show: settings.shipmentIndexDisplayConsignee,
      name: 'consignee',
      tooltipName: t('shipments.parties.consignee'),
    },
  ]

  const { References } = useReferences({ references })
  return <References />
}

export default Parties
