export const degToRad = (value: number): number => value * (Math.PI / 180)
export const radToDeg = (value: number): number => value / (Math.PI / 180)
export const mean = (arr: number[]): number => arr.reduce((p, c) => p + c, 0) / arr.length

/**
 * The result of the % operator is a truncated modulo. This function produces a result that corresponds to the mathematical definition.
 * @example
 * 5%2 // returns 1
 * euclideanModulo(5, 2) // returns 1
 * @example
 * -5%2 // returns -1
 * euclideanModulo(-5, 2) // returns 1
 */
export const euclideanModulo = (dividend: number, divisor: number): number =>
  ((dividend % divisor) + divisor) % divisor
