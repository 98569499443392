import React, { FC } from 'react'

import { StyledFormGroup, StyledFormGroupTitle } from 'components/form/style'

interface FormGroupProps {
  title?: string
}

const Group: FC<FormGroupProps> = ({ children, title }) => (
  <StyledFormGroup>
    {title && <StyledFormGroupTitle>{title}</StyledFormGroupTitle>}
    {children}
  </StyledFormGroup>
)

export default Group
