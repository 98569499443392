import styled from 'styled-components'

import { StyledBoxShadow } from 'components/box_shadow/style'
import {
  StyledAccordionItemHeading,
  StyledAccordionItemHeadingArrow,
} from 'components/accordion_item/style'
import StyledIcon from 'components/icon/style'

import { text16Regular, textSmallDescription } from 'styles/utils/texts'
import { tablet } from 'styles/utils/breakpoints'

/**
 *
 * Item
 *
 */
export const StyledBlockTransportationItem = styled.div<{ $isOpen?: boolean }>`
  ${StyledAccordionItemHeading} {
    border-bottom: 1px solid
      ${(props) => (props.$isOpen ? props.theme.softGrayLight : 'transparent')};
  }
  ${StyledAccordionItemHeadingArrow} {
    margin-right: 15px;
  }
`

/**
 *
 * Item heading
 *
 */
export const StyledBlockTransportationItemHeading = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0 15px 15px;
`

export const StyledBlockTransportationItemHeadingIcon = styled(StyledIcon)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.primary};
  flex-shrink: 0;
`

export const StyledBlockTransportationItemHeadingPlaces = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 20px;
`

export const StyledBlockTransportationItemHeadingPlace = styled.span<{ $isFrom?: boolean }>`
  ${text16Regular};
  color: ${(props) => (props.$isFrom ? props.theme.textDark : props.theme.textDark)};
`

export const StyledBlockTransportationItemHeadingArrow = styled(StyledIcon)`
  width: 24px;
  height: 24px;
  fill: ${(props) => props.theme.mediumGray};
  margin: 0 10px;
  flex-shrink: 0;
`

/**
 *
 * Item content
 *
 */

export const StyledBlockTransportationItemContentWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 20px;
`

export const StyledBlockTransportationItemContent = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 10px;

  @media ${tablet.up} {
    padding: 0 60px;
  }
`

export const StyledBlockTransportationItemContentDataWrapper = styled.div`
  display: flex;
`

export const StyledBlockTransportationItemContentDataName = styled.span`
  ${textSmallDescription};
  flex: 0 0 42%;
  color: ${(props) => props.theme.textLight};

  @media ${tablet.up} {
    flex: 0 0 200px;
  }
`

export const StyledBlockTransportationItemContentDataGroupValue = styled.div``

export const StyledBlockTransportationItemContentDataValue = styled.span`
  display: flex;
  flex-grow: 1;
  margin-left: 15px;
  ${textSmallDescription};
  color: ${(props) => props.theme.textDark};
  gap: 10px;
`

/**
 *
 * Main
 *
 */
export const StyledBlockTransportation = styled(StyledBoxShadow)`
  ${StyledBlockTransportationItem}:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.softGrayLight};
  }
`

const HeadingSubscriptionIntegrationStatus = styled.div`
  margin-left: 15px;
`
export const S = { HeadingSubscriptionIntegrationStatus }
