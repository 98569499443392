import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import useBookingRole from 'views/booking/hooks/use_booking_role'

import NotifMessage from 'components/notif_message'
import Icon from 'components/icon'
import useBooking from 'views/booking/hooks/use_booking'
import useBookingWorkflow from 'views/booking/hooks/use_booking_workflow'

import {
  StyledGroupInsert,
  StyledGroupInsertHead,
  StyledPageBookingDetailStatus,
  StyledPageBookingDetailStatusArrow,
  StyledPageBookingDetailStatusName,
  StyledPageBookingDetailStatusTitle,
} from 'views/booking/style'
import { StyledFormInsertTitle } from 'components/form/style'
import { toCamelCase } from 'services/helpers/values'
import BookingContext from 'views/booking/contexts/booking_context'
import { BOOKING_STATUSES } from 'constants/bookings'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'
import { WITH_BOOKING_OLD_WORKFLOW } from 'constants/organization_features'

const StatusBookingDetails = () => {
  const { token } = useContext(BookingContext)
  const { t } = useTranslation()
  const { features } = useOrganizationCan()

  const [booking] = useBooking(token)
  const { isForwarderActionTurn, isShipperActionTurn, isUserActionTurn } = useBookingWorkflow(token)
  const { isShipper, isForwarder, role: bookingRole } = useBookingRole()
  const {
    status,
    transportType,
    forwarder: { name: forwarderName },
    shipper: { name: shipperName },
  } = booking

  let notifKey = `bookings.notifications.${bookingRole}.${toCamelCase(status)}`
  const notifyKeyContext = features(WITH_BOOKING_OLD_WORKFLOW) ? 'legacy' : null

  if (status === BOOKING_STATUSES.CONFIRMED) {
    notifKey += `.${transportType}`
  }

  return (
    <StyledGroupInsert>
      <>
        <StyledGroupInsertHead>
          <StyledFormInsertTitle>{t('bookings.status.status')}</StyledFormInsertTitle>
        </StyledGroupInsertHead>
        <StyledPageBookingDetailStatus>
          <StyledPageBookingDetailStatusTitle $current={isShipperActionTurn}>
            <Icon name='network' />
            <StyledPageBookingDetailStatusName>
              {isShipper ? t('iam.you') : shipperName}
            </StyledPageBookingDetailStatusName>
          </StyledPageBookingDetailStatusTitle>
          <StyledPageBookingDetailStatusArrow as={Icon} name='arrow_right_outline' />
          <StyledPageBookingDetailStatusTitle $current={isForwarderActionTurn}>
            <Icon name='network' />
            <StyledPageBookingDetailStatusName>
              {isForwarder ? t('iam.you') : forwarderName}
            </StyledPageBookingDetailStatusName>
          </StyledPageBookingDetailStatusTitle>
        </StyledPageBookingDetailStatus>
      </>

      <NotifMessage
        title={isUserActionTurn ? t('bookings.tags.actionRequired') : ''}
        type={isUserActionTurn ? 'info' : 'minorInfo'}
        text={t(notifKey, { context: notifyKeyContext })}
      />
    </StyledGroupInsert>
  )
}

export default StatusBookingDetails
