import styled, { createGlobalStyle, css } from 'styled-components'

import { easeQuartOut } from 'styles/utils/ease'
import { listItem13, text14Regular } from 'styles/utils/texts'
import { desktop } from 'styles/utils/breakpoints'
import { borderRadiusSmall } from 'styles/utils/border_radius'

export const StyledInputTags = styled.div<{ $hasError: boolean }>`
  --tag-bg: ${({ theme }) => theme.primary10};
  --tag-hover: ${({ theme }) => theme.primary10};
  --tag-text-color: ${({ theme }) => theme.primary};
  --tag-remove-btn-color: ${({ theme }) => theme.mediumGray};
  --tag-remove-btn-color--hover: ${({ theme }) => theme.textDark};
  --tag-invalid-color: ${({ theme }) => theme.error};
  --tags-disabled-bg: ${({ theme }) => theme.disabled};
  --tag-invalid-bg: ${({ theme }) => theme.error10};
  --tags-hover-border-color: transparent;
  --tag-remove-bg: var(--tag-hover);
  --tag-pad: 0.55em 1em;
  --tag-inset-shadow-size: 1.5em;
  --tag--max-width: calc(100%);
  --tag-remove-btn-bg--hover: transparent;
  --placeholder-color: ${({ theme }) => theme.mediumGray};
  --placeholder-color-focus: ${({ theme }) => theme.mediumGray};

  width: 100%;
  border: 1px solid ${({ theme }) => theme.bgInput30};
  transition: border 0.5s ${easeQuartOut}, background 0.5s ${easeQuartOut};
  ${borderRadiusSmall};
  background: ${({ theme }) => theme.bgInput30};
  margin-bottom: 20px;

  & > .tagify__tag {
    max-width: calc(100% - 10px);

    & > div {
      max-width: calc(100% - 15px);
    }
  }

  @media ${desktop.up} {
    margin-bottom: 25px;
  }
  &.tagify {
    ${text14Regular}

    &__tag {
      max-width: calc(100% - 10px);

      > div {
        overflow: hidden;
      }

      div:before {
        ${borderRadiusSmall};
      }
      &-text {
        display: block;
        ${listItem13}
      }
      &.tagify {
        &--notAllowed {
          .tagify {
            &__tag-text {
              color: var(--tag-invalid-color);
            }
          }
        }
      }
    }
    &__input {
      ${text14Regular}
      padding:13px 20px;
      margin: 0;
      &:before {
        padding-top: 3px;
        position: relative;
      }
    }
    &--focus {
      border-color: ${({ $hasError, theme }) => ($hasError ? theme.error : theme.primary)};
      background: ${({ theme }) => theme.white};
      .tagify {
        &__input {
          &:before {
            position: absolute;
          }
        }
      }
    }
  }
  &.tagify[disabled] {
    filter: none;
    opacity: 1;
    --tag-text-color: ${({ theme }) => theme.textLight};
    .tagify__tag__removeBtn {
      display: none;
    }
  }
`
export const GlobalStyle = createGlobalStyle`
.tagify {
  &__dropdown {
    &__wrapper {
      border: 1px solid ${({ theme }) => theme.bgInput30};
      ${borderRadiusSmall};
    }
    &__item {
      ${text14Regular}
      color: ${({ theme }) => theme.textLight};
      background-color: ${({ theme }) => theme.white};
      border-bottom: 1px solid #efefef;
      padding: 13px 10px 13px 15px;
      margin: 0 5px;
      width: calc(100% - 10px);
      transition: color 0.5s ${easeQuartOut};
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;

      &:last-child {
        border-bottom: 0px;
      }

      &:hover {
        background-color: ${({ theme }) => theme.white};
        color: ${({ theme }) => theme.textDark};
      }
    }
  }
}
`

export const StyledInput = styled.div<{ $hasError?: boolean; $hidePlaceholderWithTag: boolean }>`
  position: relative;
  border-color: ${({ $hasError, theme }) => ($hasError ? theme.error : theme.primary)};

  ${($hidePlaceholderWithTag) =>
    $hidePlaceholderWithTag &&
    css`
      .tagify__tag + .tagify__input::before {
        display: none;
      }
    `}
`

export const StyledInputTagsError = styled.div`
  display: flex;
  justify-content: flex-end;
  color: ${({ theme }) => theme.error};
  ${listItem13}
  position: relative;
  top: 7px;
  height: 0;
  width: 100%;

  svg {
    fill: ${({ theme }) => theme.error};
    margin-right: 9px;
    flex: none;
    width: 15px;
    height: 14px;
  }
`
