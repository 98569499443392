import React, { useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components'

import { fetchMapInfo } from 'views/trusted_routes/slice'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import { TrustedRoute } from 'views/trusted_routes/types/trusted_route'
import { MapInfo, PathQuality } from 'views/trusted_routes/types/map_info'
import useMap from 'components/map/hooks/use_map'
import { CoordinateModel } from 'components/map/models'
import { isNull, isPresent, uniqArray } from 'services/helpers/values'
import S from 'views/trusted_routes/components/item/style'
import { mean, degToRad, radToDeg, euclideanModulo } from 'services/helpers/maths'

interface InternalMapProps {
  mapInfo: MapInfo
}

const extractMainPortIds = (mapInfo: MapInfo): number[] => {
  const mainPortIds: number[] = []
  mapInfo.legs.forEach((leg) => {
    if (leg.paths) {
      mainPortIds.push(leg.paths[0].departureGeometryId)
      mainPortIds.push(leg.paths.slice(-1)[0].arrivalGeometryId)
    }
  })
  return uniqArray(mainPortIds)
}

/**
 * Extracts the bounding coordinates from the given map information, including port geometries and leg paths, to define a bounding box for the map area.
 * We can't use useBounds directly because the ends of the different paths don't have the same modulo and don't follow each other exactly.
 * For example, the ends of the longitudes could be (170, 190) and the following paths (-169, -130).
 */
const extractBounds = (mapInfo: MapInfo): CoordinateModel[] => {
  const latitudes = mapInfo.ports.map((portInfo) => portInfo.centroid.coordinates[1])
  const longitudes = mapInfo.ports.map((portInfo) => portInfo.centroid.coordinates[0])
  mapInfo.legs.forEach((leg) => {
    if (isPresent(leg.paths)) {
      leg.paths?.forEach((path) => {
        path.path.coordinates.forEach((coordinate) => {
          longitudes.push(coordinate[0])
        })
      })
    }
  })
  const radLongs = longitudes.map((longitude) => degToRad(longitude))
  const sinRadLongs = radLongs.map((long) => Math.sin(long))
  const cosRadLongs = radLongs.map((long) => Math.cos(long))
  const phi0 = radToDeg(Math.atan2(mean(sinRadLongs), mean(cosRadLongs)))
  const normLongs = longitudes.map(
    (longitude) => euclideanModulo(longitude - phi0 + 180, 360) - 180
  )
  const phi1 = Math.min(...normLongs) + phi0
  const phi2 = Math.max(...normLongs) + phi0
  return [
    new CoordinateModel(Math.min(...latitudes).toString(), phi1.toString()),
    new CoordinateModel(Math.max(...latitudes).toString(), phi2.toString()),
  ]
}

const InternalMap: React.FC<InternalMapProps> = ({ mapInfo }) => {
  const theme = useTheme()
  const mainPortIds = useMemo(() => extractMainPortIds(mapInfo), [mapInfo])
  const bounds = useMemo(() => extractBounds(mapInfo), [mapInfo])

  const { Map, addLineString, addMarker, addGeometry, mapProps, loaded } = useMap({
    bounds,
    displayToggleFullscreen: false,
    onClusterClick: () => {},
    onVehicleClick: () => {},
  })

  useEffect(() => {
    if (!loaded) return

    // we add the intermediate ports first so that they appear below pol/pod/transshipments
    mapInfo.ports
      .filter((portInfo) => !mainPortIds.includes(portInfo.id))
      .forEach((portInfo) => {
        addMarker({
          type: 'port',
          coordinate: new CoordinateModel(
            portInfo.centroid.coordinates[1].toString(),
            portInfo.centroid.coordinates[0].toString()
          ),
          tooltipText: portInfo.name,
          color: theme.primary50,
          size: 'small',
        })
      })

    mapInfo.ports
      .filter((portInfo) => mainPortIds.includes(portInfo.id))
      .forEach((portInfo) => {
        addMarker({
          type: 'port',
          coordinate: new CoordinateModel(
            portInfo.centroid.coordinates[1].toString(),
            portInfo.centroid.coordinates[0].toString()
          ),
          tooltipText: portInfo.name,
          color: theme.primary,
          size: 'medium',
        })
      })

    mapInfo.ports.forEach((portInfo) => {
      if (portInfo.geometry) {
        addGeometry(`port${portInfo.id}`, portInfo.geometry)
      }
    })
    mapInfo.legs.forEach((legInfo) => {
      legInfo.paths?.forEach((path) => {
        if (path.path) {
          addLineString(
            `path${path.departureGeometryId}_${path.arrivalGeometryId}`,
            path.path,
            legInfo.pathQuality === PathQuality.LOW
          )
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])
  return <S.Map as={Map} {...mapProps} />
}

interface TrustedRouteMapProps {
  trustedRoute: TrustedRoute
}

const TrustedRouteMap: React.FC<TrustedRouteMapProps> = ({ trustedRoute }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isNull(trustedRoute.mapInfo)) {
      dispatch(fetchMapInfo({ token: trustedRoute.token }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trustedRoute.mapInfo])

  return (
    <S.MapContainer>
      {trustedRoute.mapInfo && <InternalMap mapInfo={trustedRoute.mapInfo} />}
    </S.MapContainer>
  )
}

export default TrustedRouteMap
