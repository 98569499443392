import styled from 'styled-components'

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  width: 100%;
  padding-top: 10px;
`

const VersionState = styled.div`
  overflow-wrap: break-word;
`

const VersionStateKey = styled.span`
  color: ${({ theme }) => theme.textLight};
`

const VersionStateSubValue = styled.div`
  padding-left: 16px;
`

const S = {
  Header,
  VersionState,
  VersionStateKey,
  VersionStateSubValue,
}

export default S
