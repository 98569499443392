import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { StyledInput } from 'views/booking/components/form/style'
import { fetchIncotermOptions } from 'views/select_options/slice'
import Grid from 'components/grid'
import Form from 'components/form'
import Input from 'components/input'
import SearchSelect from 'components/search_select'

import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const Incoterms: FC<BookingFormProps> = ({ filters }) => {
  const { incotermsFilter, incotermsLocationFilter } = filters
  const { t } = useTranslation()

  return (
    <Form.Group title={t('bookings.incoterms.title')}>
      <Grid columns={3}>
        <Grid.Row>
          <Grid.Column>
            <StyledInput
              as={SearchSelect}
              label={t('bookings.incoterms.title')}
              action={fetchIncotermOptions}
              name={incotermsFilter.name}
              onChange={incotermsFilter.onChange}
              value={incotermsFilter.value}
              required={!incotermsFilter.isDisabled}
              isDisabled={incotermsFilter.isDisabled}
            />
          </Grid.Column>
          <Grid.Column>
            <StyledInput
              as={Input}
              label={t('bookings.incoterms.incotermsLocation')}
              placeholder={t('bookings.location')}
              name={incotermsLocationFilter.name}
              value={incotermsLocationFilter.value}
              onChange={incotermsLocationFilter.onChange}
              disabled={incotermsLocationFilter.isDisabled}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Group>
  )
}

export default Incoterms
