import React, { FC, useContext, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { TRANSPORT_TYPE_SEA } from 'constants/shipments'

import InputDatepicker from 'components/input_datepicker'
import Select, { OnChangeFnProps } from 'components/select'
import BlockContainer, { BlockContext } from 'components/block_container'
import Form from 'components/form'
import Grid from 'components/grid'

import { WITH_FRONT_BOOKING_CUT_OFF_DATES } from 'constants/organization_features'

import BookingContext from 'views/booking/contexts/booking_context'
import useBooking from 'views/booking/hooks/use_booking'
import useBookingRole from 'views/booking/hooks/use_booking_role'
import {
  StyledCustomRefWrapper,
  StyledSelectCustomRef,
  StyledInputCustomRef,
} from 'views/booking/components/form/style'
import useOrganizationCan from 'views/iam/hooks/use_organization_can'

import {
  getTestIdForCustomFieldName,
  getTestIdForCustomFieldReference,
  TEST_ID_BOOKING_FORM_VESSEL_CUT_OFF,
  TEST_ID_BOOKING_FORM_VGM_CUT_OFF,
} from 'tests/e2e/test_ids'

import { isPresent, isAnyArray } from 'services/helpers/values'

import type { BookingCustomReference } from 'views/booking/slices/types'
import type { BookingFormProps } from 'views/booking/components/form/hooks/use_booking_form'

const Miscellaneous: FC<BookingFormProps> = ({ filters, isCreate }) => {
  const { token } = useContext(BookingContext)
  const [booking] = useBooking(token)
  const { t } = useTranslation()
  const { isForwarder } = useBookingRole()
  const { features } = useOrganizationCan()
  const { availableCustomFieldKeys } = booking || {}
  const { vgmCutOffDateFilter, vesselCutOffDateFilter, customFieldsFilter, transportTypeFilter } =
    filters

  const isCurrentTransportTypeSea = useMemo(
    () => transportTypeFilter.value === TRANSPORT_TYPE_SEA,
    [transportTypeFilter]
  )
  const displayCustomFields = useMemo(
    () =>
      !isCreate &&
      ((isForwarder && isAnyArray(availableCustomFieldKeys)) ||
        isAnyArray(customFieldsFilter.value)),
    [isCreate, isForwarder, availableCustomFieldKeys, customFieldsFilter.value]
  )
  const displayCutOffDates = useMemo(
    () => features(WITH_FRONT_BOOKING_CUT_OFF_DATES) && isCurrentTransportTypeSea,
    [features, isCurrentTransportTypeSea]
  )
  const displayMiscellaneous = useMemo(
    () => displayCustomFields || displayCutOffDates,
    [displayCustomFields, displayCutOffDates]
  )

  const customFieldsSelected = useMemo(
    () => customFieldsFilter.value.map((customField: BookingCustomReference) => customField.key),
    [customFieldsFilter]
  )

  const onAddCustomField = (index: number) => {
    customFieldsFilter.onChange((state: Array<BookingCustomReference>) => {
      state[index] = {} as BookingCustomReference
    })
  }
  const onRemoveCustomField = (index: number) => {
    customFieldsFilter.onChange((state: Array<BookingCustomReference>) => {
      state.splice(index, 1)
    })
  }
  const onCustomFieldSelectChange =
    (index: number) =>
    ({ value }: OnChangeFnProps) => {
      customFieldsFilter.onChange((state: Array<BookingCustomReference>) => {
        state[index].key = value.value as string
      })
    }
  const onCustomFieldInputChange =
    (index: number) =>
    ({ value }: { value: string }) => {
      customFieldsFilter.onChange((state: Array<BookingCustomReference>) => {
        state[index].value = value
      })
    }

  return (
    <>
      {displayMiscellaneous && (
        <Form.Group title={t('bookings.miscellaneous.title')}>
          <Grid columns={3}>
            {displayCutOffDates && (
              <Grid.Row>
                <Grid.Column>
                  <InputDatepicker
                    label={t('bookings.miscellaneous.vgmCutOffDate')}
                    withPortal
                    name={vgmCutOffDateFilter.name}
                    onChange={vgmCutOffDateFilter.onChange}
                    startDate={vgmCutOffDateFilter.value}
                    disabled={vgmCutOffDateFilter.isDisabled}
                    testId={TEST_ID_BOOKING_FORM_VGM_CUT_OFF}
                  />
                </Grid.Column>
                <Grid.Column>
                  <InputDatepicker
                    label={t('bookings.miscellaneous.vesselCutOffDate')}
                    withPortal
                    name={vesselCutOffDateFilter.name}
                    onChange={vesselCutOffDateFilter.onChange}
                    startDate={vesselCutOffDateFilter.value}
                    disabled={vesselCutOffDateFilter.isDisabled}
                    testId={TEST_ID_BOOKING_FORM_VESSEL_CUT_OFF}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {displayCustomFields && (
              <BlockContainer
                title={t('bookings.miscellaneous.customField')}
                columns={3}
                size={1}
                btnSize={1}
                initialBlocksCount={customFieldsFilter.value.length}
                maxBlocks={availableCustomFieldKeys?.length}
                onAdd={onAddCustomField}
                onRemove={onRemoveCustomField}
                disabled={customFieldsFilter.isDisabled}
              >
                <BlockContext.Consumer>
                  {({ index }) => (
                    <StyledCustomRefWrapper>
                      <StyledSelectCustomRef
                        as={Select}
                        name={customFieldsFilter.name}
                        value={
                          isPresent(customFieldsFilter.value[index]?.key)
                            ? {
                                value: customFieldsFilter.value[index].key,
                                label: customFieldsFilter.value[index].key,
                              }
                            : null
                        }
                        options={availableCustomFieldKeys
                          ?.filter((fieldName) => !customFieldsSelected.includes(fieldName))
                          .map((fieldName) => ({
                            value: fieldName,
                            label: fieldName,
                          }))}
                        onChange={onCustomFieldSelectChange(index)}
                        isDisabled={customFieldsFilter.isDisabled}
                        testId={getTestIdForCustomFieldName(index)}
                      />
                      <StyledInputCustomRef
                        name={customFieldsFilter.name}
                        value={customFieldsFilter.value[index]?.value}
                        onChange={({ target }) => onCustomFieldInputChange(index)(target)}
                        disabled={customFieldsFilter.isDisabled}
                        testId={getTestIdForCustomFieldReference(index)}
                      />
                    </StyledCustomRefWrapper>
                  )}
                </BlockContext.Consumer>
              </BlockContainer>
            )}
          </Grid>
        </Form.Group>
      )}
    </>
  )
}

export default Miscellaneous
