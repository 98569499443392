import styled from 'styled-components'

const StyledCompaniesSearchInput = styled.div`
  display: flex;
  position: relative;
  min-width: 350px;
  margin-left: auto;
`

export default StyledCompaniesSearchInput
