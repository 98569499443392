import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import useStaticLocales from 'views/locales/hooks/use_static_locales'

import {
  StyledFormEditGroupLabel,
  StyledFormEditGroupValue,
  StyledFormEditGroupItem,
  StyledMerchandisePackagesContainer,
  StyledMerchandisePackageItem,
  StyledMerchandisePackageProductDetails,
  StyledFormEditGroup,
  StyledFormEditGroupItems,
  StyledFormEditGroupTitle,
} from 'views/booking/components/show/style'
import {
  AnyBookingMerchandise,
  BookingMerchandiseContainer,
  BookingMerchandiseContainerContent,
} from 'views/booking/slices/types'
import ValueWithFallback from 'views/booking/components/value_with_fallback'
import useMerchandiseInformationDisplay from 'views/booking/hooks/use_merchandise_information_display'
import Table from 'components/table'
import { isPresent } from 'services/helpers/values'
import InformationMerchandiseContentHeader from 'views/booking/components/information_merchandise_content_header'

const InformationMerchandiseContainers = ({
  merchandise,
}: {
  merchandise: AnyBookingMerchandise
}) => {
  const { s } = useStaticLocales()
  const { t } = useTranslation()

  const containerTypes = s('containerTypes')
  const { content: containers, shippingMode } = merchandise as BookingMerchandiseContainer

  const [containerExpansions, setContainerExpansions] = useState<boolean[]>(
    // eslint-disable-next-line
    containers?.map((_c) => false) ?? []
  )

  const {
    currencyDisplay,
    emptyDisplay,
    hazardousGoodsDisplay,
    temperatureDisplay,
    commercialValueDisplay,
  } = useMerchandiseInformationDisplay()

  const getContainerTotalWeight = (container: BookingMerchandiseContainerContent) =>
    container.products?.reduce((total, product) => total + (product.weight?.value ?? 0), 0)
  const getContainerTotalVolume = (container: BookingMerchandiseContainerContent) =>
    container.products?.reduce((total, product) => total + (product.volume?.value ?? 0), 0)
  const getContainerTotalPackage = (container: BookingMerchandiseContainerContent) =>
    container.products?.reduce((total, product) => total + product.packageNumber, 0) ?? 0
  const getContainerTotalAmount = (container: BookingMerchandiseContainerContent) =>
    container.products?.reduce(
      (total, product) => total + (product.commercialValue?.amount ?? 0),
      0
    )

  const hasAnyControlledTemperatures = (container: BookingMerchandiseContainerContent) =>
    container?.products?.find(
      (product) =>
        isPresent(product.controlledTemperatures?.min) ||
        isPresent(product.controlledTemperatures?.max)
    ) !== undefined

  const hasAnyHazardousGoods = (container: BookingMerchandiseContainerContent) =>
    container?.products?.find(
      (product) =>
        isPresent(product.hazardousGoods?.hazardousClass) ||
        isPresent(product.hazardousGoods?.packingGroup) ||
        isPresent(product.hazardousGoods?.unNumber) ||
        isPresent(product.hazardousGoods?.weight?.value)
    ) !== undefined

  return (
    <>
      <StyledFormEditGroup>
        <StyledMerchandisePackagesContainer>
          <StyledMerchandisePackageItem>
            <StyledFormEditGroupTitle>{t('bookings.merchandise.title')}</StyledFormEditGroupTitle>
            <StyledFormEditGroupItems>
              <StyledFormEditGroupItem>
                <StyledFormEditGroupLabel>
                  {t('bookings.merchandise.shippingMode')}
                </StyledFormEditGroupLabel>
                <StyledFormEditGroupValue>
                  <ValueWithFallback value={shippingMode} />
                </StyledFormEditGroupValue>
              </StyledFormEditGroupItem>
            </StyledFormEditGroupItems>
          </StyledMerchandisePackageItem>
          {containers?.map((container, index: number) => (
            <>
              <StyledMerchandisePackageItem>
                <StyledFormEditGroupTitle />
                <StyledFormEditGroupItems>
                  <StyledMerchandisePackageItem>
                    {index === 0 ? (
                      <StyledFormEditGroupLabel>
                        {t('bookings.merchandise.content')}
                      </StyledFormEditGroupLabel>
                    ) : (
                      <StyledFormEditGroupLabel />
                    )}
                    <InformationMerchandiseContentHeader
                      index={index}
                      icon='container'
                      type={containerTypes[container.containerType as any]}
                      quantity={container.quantity}
                      weight={{ value: getContainerTotalWeight(container) }}
                      volume={{ value: getContainerTotalVolume(container) }}
                      packageNumber={getContainerTotalPackage(container)}
                      amount={getContainerTotalAmount(container)}
                      currency={currencyDisplay(
                        container.products?.[0]?.commercialValue?.currencyCode
                      )}
                      hasAnyHazardousGoods={hasAnyHazardousGoods(container)}
                      hasAnyControlledTemperatures={hasAnyControlledTemperatures(container)}
                      withAction={(container?.products?.length ?? 0) > 0}
                      expanded={containerExpansions[index]}
                      onClick={() =>
                        setContainerExpansions(
                          // eslint-disable-next-line
                          containerExpansions.map((_p, i) => {
                            if (i === index) {
                              return !containerExpansions[i]
                            }

                            return containerExpansions[i]
                          })
                        )
                      }
                    />
                  </StyledMerchandisePackageItem>
                </StyledFormEditGroupItems>
              </StyledMerchandisePackageItem>
              {containerExpansions[index] && (
                <StyledMerchandisePackageProductDetails>
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.description')} (
                        {t('bookings.merchandise.perContainer').toLowerCase()})
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.weight')} (kg)
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.volume.short')} (m³)
                      </Table.HeadCell>
                      <Table.HeadCell>{t('bookings.merchandise.packages')}</Table.HeadCell>
                      <Table.HeadCell>{t('bookings.merchandise.product.value')}</Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.temperatures.short')}
                      </Table.HeadCell>
                      <Table.HeadCell>
                        {t('bookings.merchandise.product.hazardousGoods.title')}
                      </Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      {container?.products?.map((product, productIndex: number) => (
                        <Table.Row odd={productIndex % 2 === 1} key={`product-${productIndex + 1}`}>
                          <Table.Cell>{product.productDescription ?? emptyDisplay}</Table.Cell>
                          <Table.Cell>
                            {product.weight?.value?.toLocaleString() ?? emptyDisplay}
                          </Table.Cell>
                          <Table.Cell>
                            {product.volume?.value?.toLocaleString() ?? emptyDisplay}
                          </Table.Cell>
                          <Table.Cell>{product.packageNumber ?? emptyDisplay}</Table.Cell>
                          <Table.Cell>{commercialValueDisplay(product.commercialValue)}</Table.Cell>
                          <Table.Cell>
                            <div>
                              {temperatureDisplay(product.controlledTemperatures?.min, 'Min.')}
                            </div>
                            <div>
                              {temperatureDisplay(product.controlledTemperatures?.max, 'Max.')}
                            </div>
                          </Table.Cell>
                          <Table.Cell>{hazardousGoodsDisplay(product.hazardousGoods)}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </StyledMerchandisePackageProductDetails>
              )}
            </>
          ))}
        </StyledMerchandisePackagesContainer>
      </StyledFormEditGroup>
    </>
  )
}

export default InformationMerchandiseContainers
