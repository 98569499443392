import { isMacOs, isFirefox, isIE } from 'react-device-detect'
import { css } from 'styled-components'

export default css`
  /* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

  ${!isMacOs &&
  !isFirefox &&
  !isIE &&
  css`
    body,
    * {
      /* total width */
      ::-webkit-scrollbar {
        background-color: #fff;
        width: 16px;
      }

      /* background of the scrollbar except button or resizer */
      ::-webkit-scrollbar-track {
        background-color: #fff;
      }
      ::-webkit-scrollbar-track:hover {
        background-color: #f4f4f4;
      }

      /* scrollbar itself */
      ::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 5px solid #fff;
      }

      ::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
        border: 4px solid #f4f4f4;
      }
    }
  `}
`
