import styled, { css } from 'styled-components'

import { shadowMedium } from 'styles/utils/shadow'
import { listItem13, tagMedium } from 'styles/utils/texts'
import { borderRadiusSmall } from 'styles/utils/border_radius'
import { tablet } from 'styles/utils/breakpoints'

import StyledIcon from 'components/icon/style'
import StyledLogo from 'components/logo/style'

import type { NotificationTypes } from 'components/notif_message'

export const StyledItem = styled.div<{
  $type: NotificationTypes
  $padded?: boolean
  $fluid?: boolean
}>`
  position: relative;
  display: flex;
  padding: 15px 17px;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  max-width: 600px;
  gap: 10px;

  @media ${tablet.down} {
    width: 100%;
  }
  ${borderRadiusSmall}
  ${(props) =>
    props.$fluid
      ? css`
          width: auto;
        `
      : css`
          width: fit-content;
        `}
  ${(props) =>
    props.$type === 'success' &&
    css`
      background: ${({ theme }) => theme.success5};
      ${StyledSpan} {
        color: ${({ theme }) => theme.success};
      }
      ${StyledItemIcon} {
        fill: ${({ theme }) => theme.success};
      }
    `}  
  ${(props) =>
    props.$type === 'info' &&
    css`
      background: ${({ theme }) => theme.warn5};
      ${StyledSpan} {
        color: ${({ theme }) => theme.warn};
      }
      ${StyledItemIcon} {
        fill: ${({ theme }) => theme.warn};
      }
    `}

    ${(props) =>
    (props.$type === 'minorInfo' || props.$type === 'loading') &&
    css`
      background: ${({ theme }) => theme.primary5};
      ${StyledSpan} {
        color: ${({ theme }) => theme.primary};
      }
      ${StyledItemIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
  ${(props) =>
    props.$type === 'alert' &&
    css`
      background: ${({ theme }) => theme.error5};
      ${StyledSpan} {
        color: ${({ theme }) => theme.error};
      }
      ${StyledItemIcon} {
        fill: ${({ theme }) => theme.error};
      }
    `}
  ${(props) =>
    props.$type === 'save' &&
    css`
      background: ${({ theme }) => theme.white};
      ${shadowMedium}
      ${StyledSpan} {
        color: ${({ theme }) => theme.textDark};
      }
      ${StyledItemIcon} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
  ${(props) =>
    props.$type === 'tip' &&
    css`
      background: ${({ theme }) => theme.tip10};
      ${StyledSpan} {
        color: ${({ theme }) => theme.textDark};
      }
      ${StyledItemLogo} {
        fill: ${({ theme }) => theme.primary};
      }
    `}
    ${({ $padded }) => $padded && 'margin: 1em;'}
`

export const StyledContent = styled.p`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StyledSpan = styled.span`
  ${listItem13}
`

export const StyledText = styled.span`
  ${tagMedium}
  color: ${({ theme }) => theme.textLight};
  text-align: left;
  white-space: pre-wrap;
`

export const StyledItemIcon = styled(StyledIcon)`
  flex-shrink: 0;
  flex-basis: 25px;
  width: 35px;
`
export const StyledItemLogo = styled(StyledLogo)`
  flex-shrink: 0;
  flex-basis: 25px;
  width: 35px;
`

export const StyledItemCloseIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 25px;
  padding: 5px;
  :hover {
    cursor: pointer;
  }
  svg {
    fill: ${({ theme }) => theme.mediumGray};
  }
`
