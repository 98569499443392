import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { acceptBooking, confirmBooking } from 'views/booking/slices/booking_slice'
import { StyledAcceptCheckboxWrapper } from 'views/booking/components/show/style'

import InputCheckbox from 'components/input_checkbox'
import ModalContent from 'views/booking/components/action_modal_content'
import { BOOKING_EXCHANGE_ACTION_KEYS } from 'constants/bookings'

const AcceptingModalContent = ({ actionKey, setAction }) => {
  const { t } = useTranslation()
  const [checked, setChecked] = useState(false)

  return (
    <ModalContent actionKey={actionKey}>
      <StyledAcceptCheckboxWrapper>
        <InputCheckbox
          id={BOOKING_EXCHANGE_ACTION_KEYS.ACCEPT_BOOKING}
          text={t(`bookings.exchangeModal.${actionKey}.extraContent`)}
          onChange={() => {
            if (!checked) {
              setAction(() => confirmBooking)
              setChecked(true)
              return
            }
            setAction(() => acceptBooking)
            setChecked(false)
          }}
          checked={checked}
        />
      </StyledAcceptCheckboxWrapper>
    </ModalContent>
  )
}

AcceptingModalContent.propTypes = {
  /** actionKey */
  actionKey: PropTypes.string,
  /** setAction */
  setAction: PropTypes.func,
}

AcceptingModalContent.defaultProps = {
  actionKey: undefined,
  setAction: Function.prototype,
}

export default AcceptingModalContent
