import i18next from 'i18next'

import {
  TRANSPORT_TYPE_ROAD,
  TRANSPORT_TYPE_SEA,
  TRANSPORT_TYPE_AIR,
  TRANSPORT_TYPE_RAIL,
  TRANSPORT_TYPE_PARCEL,
  MARINE_TRAFFIC_URL,
  FLIGHT_AWARE_URL,
} from 'constants/shipments'
import TransportTypeTranslation from 'services/api/translations/transport_type_translation'
import DateHelper from 'services/helpers/date_helper'
import {
  isNull,
  compactObject,
  isPresent,
  isAnyArray,
  isInputPresent,
  toCamelCase,
} from 'services/helpers/values'
import i18n from 'views/locales/i18n'

export class LegFactory {
  constructor(apiSegment) {
    this.leg = apiSegment
  }

  make = () => {
    const transportType = new TransportTypeTranslation().fromInt(this.leg.transportType)
    return {
      [TRANSPORT_TYPE_ROAD]: new RoadLeg(this.leg),
      [TRANSPORT_TYPE_SEA]: new SeaLeg(this.leg),
      [TRANSPORT_TYPE_AIR]: new AirLeg(this.leg),
      [TRANSPORT_TYPE_RAIL]: new RailLeg(this.leg),
      [TRANSPORT_TYPE_PARCEL]: new ParcelLeg(this.leg),
    }[transportType]
  }
}

class Leg {
  constructor(leg) {
    this.leg = leg
  }

  get id() {
    return this.leg.id
  }

  get segmentInfoId() {
    return this.leg.segmentInfoId
  }

  get current() {
    return this.leg.current
  }

  get links() {
    return compactObject(
      Object.fromEntries(
        Object.entries(this.data).map(([k, v]) => [
          k,
          this.leg.attributes.carrierLinks?.[k]?.replace('{{{ref}}}', v),
        ])
      )
    )
  }

  findSegmentInfo = (segmentInfos) =>
    segmentInfos.find(({ id: siId }) => siId === this.segmentInfoId)

  translateShippingMode = (shippingMode) =>
    i18n.t(`static.shippingModes.${shippingMode}`, { defaultValue: '' })

  translateTrackingIdType = (trackingIdType) =>
    i18n.t(`static.trackingIdTypes.${trackingIdType}`, { defaultValue: '' })
}

class RoadLeg extends Leg {
  transportType = TRANSPORT_TYPE_ROAD

  get data() {
    return {
      trackingIdType: this.translateTrackingIdType(toCamelCase(this.leg.attributes.trackingIdType)),
      trackingIdValue: this.leg.attributes.trackingIdValue,
      reference: this.leg.attributes.reference,
      cmrNumber: this.leg.attributes.cmrNumber,
      truckReference: this.leg.attributes.truckReference,
      carrier: this.leg.attributes.carrier,
      incoterms: this.leg.attributes.incoterms,
      shippingMode: this.translateShippingMode(this.leg.attributes.shippingMode),
    }
  }

  get extraData() {
    return {
      forwarder: this.leg.attributes.partner,
      forwarderReference: this.leg.attributes.refForwarder,
      grossWeight: this.leg.attributes.weight,
      volume: this.leg.attributes.volume,
      packageNumber: this.leg.attributes.packageNumber,
    }
  }
}

class SeaLeg extends Leg {
  transportType = TRANSPORT_TYPE_SEA

  get data() {
    return {
      connectivityResource: this.leg.attributes.connectivityResource,
      containerNumber: this.leg.attributes.containerNumber,
      masterBl: this.leg.attributes.masterBl,
      bookingNumber: this.leg.attributes.bookingNumber,
      containerType: this.leg.attributes.containerType,
      carrier: this.leg.attributes.carrier,
      vessel: isPresent(this.leg.attributes.vessel)
        ? `${this.leg.attributes.vessel} (${this.leg.attributes.imoNumber})`
        : null,
      incoterms: this.leg.attributes.incoterms,
      shippingMode: this.translateShippingMode(this.leg.attributes.shippingMode),
    }
  }

  get extraData() {
    return {
      houseBl: this.leg.attributes.houseBl,
      forwarder: this.leg.attributes.partner,
      forwarderReference: this.leg.attributes.refForwarder,
      grossWeight: this.leg.attributes.weight,
      volume: this.leg.attributes.volume,
      packageNumber: this.leg.attributes.packageNumber,
    }
  }

  connectivityMonitoringLink() {
    if (isNull(this.leg.attributes.connectivityResource)) {
      return null
    }

    return process.env.REACT_APP_SLAPP_MONITORING_URL.replace(
      'trackingId',
      this.leg.attributes.connectivityResource
    )
  }

  get links() {
    return {
      ...super.links,
      vessel: isPresent(this.leg.attributes.imoNumber)
        ? `${MARINE_TRAFFIC_URL}${this.leg.attributes.imoNumber}`
        : null,
      connectivityResource: this.connectivityMonitoringLink(),
    }
  }
}

class AirLeg extends Leg {
  transportType = TRANSPORT_TYPE_AIR

  get data() {
    return {
      masterAwb: this.leg.attributes.masterAwb,
      houseAwb: this.leg.attributes.houseAwb,
      carrier: this.leg.attributes.carrier,
      flightNumbers: this.leg.attributes.flightNumbers,
      incoterms: this.leg.attributes.incoterms,
    }
  }

  get extraData() {
    return {
      forwarder: this.leg.attributes.partner,
      forwarderReference: this.leg.attributes.refForwarder,
      grossWeight: this.leg.attributes.weight,
      volume: this.leg.attributes.volume,
      packageNumber: this.leg.attributes.packageNumber,
    }
  }

  get links() {
    const { masterAwb } = this.data

    let masterAwbLink = null

    if (isInputPresent(this.leg.attributes.masterAwb)) {
      const [airLinePrefix, serialNumber] = masterAwb.split('-')
      masterAwbLink = this.leg.attributes.carrierLinks?.masterAwb
        ?.replace('{{{{ref}}}}', masterAwb)
        ?.replace('{{{{airline_prefix}}}}', airLinePrefix)
        ?.replace('{{{{serial_number}}}}', serialNumber)
    }

    return {
      ...super.links,
      masterAwb: masterAwbLink,
      flightNumbers: isAnyArray(this.leg.attributes.flightNumbers)
        ? Object.fromEntries(
            this.leg.attributes.flightNumbers.map((flightNumber) => [
              flightNumber,
              `${FLIGHT_AWARE_URL}${flightNumber}`,
            ])
          )
        : null,
    }
  }
}

class RailLeg extends Leg {
  transportType = TRANSPORT_TYPE_RAIL

  get data() {
    return {
      cimNumber: this.leg.attributes.cimNumber,
      trainReference: this.leg.attributes.trainReference,
      carrier: this.leg.attributes.carrier,
    }
  }

  get extraData() {
    return {
      forwarder: this.leg.attributes.partner,
      forwarderReference: this.leg.attributes.refForwarder,
      grossWeight: this.leg.attributes.weight,
      volume: this.leg.attributes.volume,
      packageNumber: this.leg.attributes.packageNumber,
    }
  }
}

class ParcelLeg extends Leg {
  transportType = TRANSPORT_TYPE_PARCEL

  get data() {
    return {
      connectivityResource: this.leg.attributes.connectivityResource,
      trackingNumber: this.leg.attributes.trackingNumber,
      serviceName: this.leg.attributes.serviceName,
      carrier: this.leg.attributes.carrier,
      packageNumber: this.leg.attributes.packageNumber,
      grossWeight: this.leg.attributes.weight,
    }
  }

  get extraData() {
    return {
      volume: this.leg.attributes.volume,
      forwarder: this.leg.attributes.partner,
      forwarderReference: this.leg.attributes.refForwarder,
    }
  }

  connectivityMonitoringLink() {
    if (isNull(this.leg.attributes.connectivityResource)) {
      return null
    }

    return process.env.REACT_APP_PARCEL_APP_MONITORING_URL.replace(
      'trackingId',
      this.leg.attributes.connectivityResource
    )
  }

  get links() {
    return {
      ...super.links,
      connectivityResource: this.connectivityMonitoringLink(),
    }
  }
}

export class References {
  constructor(apiShipment) {
    this.shipment = apiShipment || {}
  }

  get product() {
    return {
      grossWeight: this.shipment.weight,
      volume: this.shipment.volume,
      packageNumber: this.shipment.packageNumber,
      description: this.shipment.commodities,
    }
  }

  get references() {
    return {
      client: this.shipment.refClient,
      shipment: this.shipment.ref,
      forwarder: this.shipment.refForwarder,
      clientBookingNumber: this.shipment.clientBookingNumber,
      bookingReferences: this.shipment.bookingReferences,
    }
  }

  get parties() {
    return {
      consignor: this.shipment.consignor,
      consignee: this.shipment.finalClient,
      forwarder: this.shipment.partner,
      shipper: this.shipment.client,
      owner: this.shipment.owner,
    }
  }
}

export class Metadata {
  ICONS = {
    receivedFrom: 'network',
    deliveryTo: 'network',
    flightNumber: 'plane',
    planned: 'time_clock',
    etd: 'time_clock',
    eta: 'time_clock',
    destination: 'airport',
    vessel: 'vessel',
    service: 'service',
    voyage: 'filesheet',
  }

  FORMATS = {
    flightNumber: (value) => value,
    planned: (value) =>
      `${i18next.t('timeline.metadata.planned')} ${new DateHelper(value).toLocale({
        hours: true,
      })}`,
    etd: (value) => `ETD ${new DateHelper(value).toLocale()}`,
    eta: (value) => `ETA ${new DateHelper(value).toLocale()}`,
    receivedFrom: (value) => `${i18next.t('timeline.metadata.receivedFrom')} ${value}`,
    deliveryTo: (value) => `${i18next.t('timeline.metadata.deliveryTo')} ${value}`,
    destination: (value) => `${i18next.t('timeline.metadata.to')} ${value}`,
    vessel: (value) => value,
    service: (value) => `Service: ${value}`,
    voyage: (value) => `Voyage: ${value}`,
  }

  constructor(apiMilestoneMetadata) {
    this.metadata = {
      receivedFrom: apiMilestoneMetadata.receivedFrom,
      deliveryTo: apiMilestoneMetadata.deliveryTo,
      flightNumber: apiMilestoneMetadata.flightNumber,
      planned: apiMilestoneMetadata.planned,
      etd: apiMilestoneMetadata.etd,
      eta: apiMilestoneMetadata.eta,
      destination: apiMilestoneMetadata.destination,
      vessel: isPresent(apiMilestoneMetadata.vesselImo)
        ? `${apiMilestoneMetadata.vesselName} (${apiMilestoneMetadata.vesselImo})`
        : apiMilestoneMetadata.vesselName || null,
      service: apiMilestoneMetadata.service,
      voyage: apiMilestoneMetadata.voyage,
    }
  }

  get translation() {
    return Object.keys(this.FORMATS)
      .filter((attribute) => isInputPresent(this.metadata[attribute]))
      .map((attribute) => ({
        attribute,
        text: this.FORMATS[attribute](this.metadata[attribute]),
        icon: this.ICONS[attribute],
      }))
  }
}
