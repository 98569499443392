// SharedShipment is a minimalist view of Shipment
// It therefore shares a lot of elements, such as components, styles, services

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Page from 'components/page'
import Button from 'components/button'

import useTracker from 'services/analytics/hooks/use_tracker'
import useOnce from 'services/hooks/use_once'

import ShipmentTimeline from 'views/shipment/components/timeline'
import ShipmentAlertList from 'views/shipment/components/alert_list'
import ShipmentQuickInfo from 'views/shipment/components/quick_info'
import ShipmentMap from 'views/shipment/components/map'
import SharedReferences from 'views/shipment/components/shared_references'
import SharedHeader from 'views/shipment/components/shared_header'

import ShipmentContext from 'views/shipment/context'
import {
  StyledShipment,
  StyledShipmentContent,
  StyledShipmentContentTabs,
  StyledShipmentInformationContent,
  StyledShipmentLeftCol,
  StyledShipmentMain,
  StyledShipmentStateSwitchButton,
  StyledShipmentWrap,
  ViewState,
} from 'views/shipment/style'
import { fetchSharedUser } from 'views/iam/slices/iamSlice'
import { fetchSharedShipment } from 'views/shipment/slice'
import { addNotification } from 'views/notifications/slice'
import ViewSwitcher from 'components/view_switcher'
import { Tab } from 'components/tabs'
import useAppDispatch from 'services/hooks/use_app_dispatch'

import { Alert } from 'views/shipment/types/shipment'
import useAlerts from 'views/shipment/hooks/use_alerts'

const VIEW_TIMELINE = 'timeline'
const VIEW_ALERTS = 'alerts'

type ViewType = typeof VIEW_TIMELINE | typeof VIEW_ALERTS

const SharedShipment = React.memo(() => {
  const [viewState, setViewState] = useState<ViewState>('content')
  const [currentView, setCurrentView] = useState<ViewType>(VIEW_TIMELINE)
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { track } = useTracker()
  const { token } = useParams<{ token: string }>()
  const [alerts, alertStatus] = useAlerts({ id: token })

  useOnce(() => {
    dispatch(fetchSharedShipment({ token }))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })

    dispatch(fetchSharedUser({ token }))
      .unwrap()
      .catch(() => {
        dispatch(
          addNotification({
            type: 'alert',
            title: t('errors.notification.title'),
            text: t('errors.notification.content'),
          })
        )
      })
  })

  useEffect(() => {
    if (alerts.some((alert: Alert) => alert.isOngoing)) {
      setCurrentView(VIEW_ALERTS)
    }
  }, [alertStatus.ready, alerts])

  return (
    <Page plain>
      <ShipmentContext.Provider value={{ id: token }}>
        <StyledShipment $viewState={viewState}>
          <StyledShipmentStateSwitchButton
            as={Button}
            onClick={() => setViewState(viewState === 'timeline' ? 'content' : 'timeline')}
            icon={viewState === 'timeline' ? 'filesheet' : 'time_clock'}
            rounded
          />
          {/* Left col */}
          <StyledShipmentLeftCol>
            <ShipmentQuickInfo />
            <ViewSwitcher
              variant={
                (alerts || []).some((alert: Alert) => alert.isOngoing) ? 'warning' : 'default'
              }
              label={`${t('actions.view')} ${
                currentView === VIEW_TIMELINE ? t('shipments.timeline') : t('alerts.list')
              }`}
              onSwitch={() => {
                const targetedView = currentView === VIEW_TIMELINE ? VIEW_ALERTS : VIEW_TIMELINE
                setCurrentView(targetedView)
                track(`Shipment / ${targetedView}`)
              }}
            />
            {currentView === VIEW_TIMELINE && <ShipmentTimeline />}
            {currentView === VIEW_ALERTS && (
              <ShipmentAlertList alerts={alerts} status={alertStatus} />
            )}
          </StyledShipmentLeftCol>
          {/* Main */}
          <StyledShipmentMain>
            <ShipmentMap />

            <StyledShipmentWrap>
              {/* Content */}
              <StyledShipmentContent>
                <SharedHeader />
                <StyledShipmentContentTabs>
                  <Tab id='information' label={t('shipments.information')} key='tab-information'>
                    <StyledShipmentInformationContent>
                      <SharedReferences />
                    </StyledShipmentInformationContent>
                  </Tab>
                </StyledShipmentContentTabs>
              </StyledShipmentContent>
            </StyledShipmentWrap>
          </StyledShipmentMain>
        </StyledShipment>
      </ShipmentContext.Provider>
    </Page>
  )
})

export default SharedShipment
