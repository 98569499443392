import React, { FC, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import useTracker from 'services/analytics/hooks/use_tracker'
import { isPresent } from 'services/helpers/values'

import {
  routeShipment,
  routeSharedShipment,
  routeSignIn,
  routeBooking,
} from 'services/helpers/routes'

const PageTracker: FC = ({ children }) => {
  const location = useLocation()
  const { track } = useTracker()
  const matchShipment = useRouteMatch(routeShipment(':id'))
  const matchSharedShipment = useRouteMatch(routeSharedShipment(':token'))
  const matchBooking = useRouteMatch(routeBooking(':id'))

  // Extend this array with future routes with ids that need to be renamed for tracking purpose
  const name = [
    matchShipment?.path,
    matchSharedShipment?.path,
    matchBooking?.path,
    location.pathname,
  ].filter((v) => isPresent(v))[0]

  useEffect(() => {
    if (!location.pathname.includes(routeSignIn())) track('Page visit', { name })
    // eslint-disable-next-line
  }, [location])

  return <>{children}</>
}
export default PageTracker
