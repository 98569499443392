import React, { useContext, useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Modal from 'components/modal'
import SearchListItem from 'components/search_list_item'
import SearchList from 'components/search_list'
import StyledModalSearch from 'components/modal_search/style'

import SearchContext from 'components/search/context'

import { fetchShipments } from 'views/shipments/slice'
import { addNotification } from 'views/notifications/slice'
import useFilters from 'views/shipments/hooks/use_filters'
import useActiveFilters from 'views/shipments/hooks/use_active_filters'
import useCategories from 'views/shipments/hooks/use_categories'

import { SEARCH_SOURCE } from 'constants/shipment_source'
import { MINIMUM_SEARCH_LENGTH } from 'components/search'
import {
  STEP_TYPE_PICKUP,
  STEP_TYPE_POL,
  STEP_TYPE_POD,
  STEP_TYPE_DELIVERY,
} from 'constants/shipments'

import { getTestIdForSearchItem } from 'tests/e2e/test_ids'

import useTracker from 'services/analytics/hooks/use_tracker'
import { routeShipment } from 'services/helpers/routes'
import useModal from 'components/modal/hooks/use_modal'
import useDebounce from 'services/hooks/use_debounce'

const ModalSearch = ({ onClose }) => {
  const PER = 30

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { searchValue } = useContext(SearchContext)
  const { opened, setOpen } = useModal('search')
  const filters = useFilters()
  const { arrayOfCategories } = useCategories({ filters })
  const { save: saveActiveFilters, goToShipments } = useActiveFilters({
    categories: arrayOfCategories,
  })
  const { track } = useTracker()

  const [viewMoreClick, setViewMoreClick] = useState(false)

  useEffect(() => {
    if (viewMoreClick) {
      setOpen(false)
      saveActiveFilters()
      setViewMoreClick(false)
      goToShipments()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMoreClick])

  const searchReference = useCallback(
    (reference) => {
      filters.referenceFilter.setValue(reference)
      setViewMoreClick(true)
    },
    [filters]
  )

  const [total, setTotal] = useState(0)
  const [foundShipments, setFoundShipments] = useState([])

  const fetch = useCallback(
    (search) =>
      dispatch(
        fetchShipments({
          filters: { reference_match: search },
          per: PER,
          page: 1,
          source: SEARCH_SOURCE,
        })
      )
        .unwrap()
        .then(({ totalCount, shipments }) => {
          setTotal(totalCount)
          setFoundShipments(shipments)
        })
        .catch(() =>
          dispatch(
            addNotification({
              type: 'alert',
              title: t('searchBar.searchErrorNotification.title'),
              text: t('searchBar.searchErrorNotification.text'),
            })
          )
        ),
    [dispatch, t]
  )

  const fetchDebounce = useDebounce(fetch)

  useEffect(() => {
    if (searchValue) {
      if (opened && searchValue.length >= MINIMUM_SEARCH_LENGTH) {
        fetchDebounce(searchValue)
      } else if (searchValue.length === 0) {
        setFoundShipments([])
      }
    }
  }, [searchValue, opened, fetchDebounce])

  const shipmentItems = useMemo(() => {
    const shipmentArrivalTimeAtStep = (step) =>
      step?.actualTime || step?.estimatedTime || step?.plannedTime

    return foundShipments.map((shipment, index) => {
      const from = (shipment.summary[STEP_TYPE_PICKUP] || shipment.summary[STEP_TYPE_POL] || {})
        .location
      const to = (shipment.summary[STEP_TYPE_DELIVERY] || shipment.summary[STEP_TYPE_POD] || {})
        .location
      return (
        <Link
          to={routeShipment(shipment.id)}
          onClick={() => {
            setOpen(false)
            track('Search', { detail: 'enter' })
          }}
          key={`search-list-item-link-${shipment.id}`}
        >
          <SearchListItem
            clientReference={shipment.clientReference}
            shipmentReference={shipment.shipmentReference}
            locationFrom={`${from?.name}, ${from?.countryCode}`}
            locationTo={`${to?.name}, ${to?.countryCode}`}
            time={
              shipmentArrivalTimeAtStep(shipment.summary.delivery) ||
              shipmentArrivalTimeAtStep(shipment.summary.pod)
            }
            testId={`${getTestIdForSearchItem(index)}`}
          />
        </Link>
      )
    })
  }, [foundShipments, setOpen, track])

  return (
    <Modal
      modalName='search'
      onClose={onClose}
      noPadding
      width='large'
      isClosable={false}
      shouldFocusAfterRender={false}
    >
      <Modal.Content>
        <StyledModalSearch>
          <SearchList
            category={t('shipments.title')}
            count={total}
            viewMore={total > foundShipments.length}
            onClickMore={() => {
              searchReference(searchValue)
            }}
          >
            {shipmentItems}
          </SearchList>
        </StyledModalSearch>
      </Modal.Content>
    </Modal>
  )
}

ModalSearch.propTypes = {
  /** onClose */
  onClose: PropTypes.func,
}

ModalSearch.defaultProps = {
  onClose: undefined,
}
export default ModalSearch
