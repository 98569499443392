import React, { FC } from 'react'

import { StyledTabNavItem, StyledTabNavItemButton } from 'components/tabs/tab_navigation_item/style'

interface TabNavigationItemProps {
  active?: boolean
  text?: string
  onClick?: () => void
  disabled?: boolean
  testId?: string
  dataComponent?: string
}

const TabNavigationItem: FC<TabNavigationItemProps> = ({
  active = false,
  text = '',
  onClick,
  disabled = false,
  testId,
  dataComponent,
}) => (
  <StyledTabNavItem active={active} onClick={onClick} data-component={dataComponent}>
    <StyledTabNavItemButton type='button' $disabled={disabled} data-testid={testId}>
      {text}
    </StyledTabNavItemButton>
  </StyledTabNavItem>
)

export default TabNavigationItem
