import styled from 'styled-components'

import { ReactComponent as MapPointer } from 'assets/icons/map_pointer.svg'

const StyledPointer = styled(MapPointer)<{ $bearing: number; $color: string }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0)
    rotate(calc(-148deg + 180deg + ${({ $bearing }) => $bearing}deg));
  width: 22px;
  height: 22px;
  path {
    fill: ${({ $color }) => $color};
  }
`

export default StyledPointer
