import { useMemo } from 'react'

import { isAnyArray, isPresent } from 'services/helpers/values'
import useOnce from 'services/hooks/use_once'
import { Tag } from 'views/shipments/hooks/use_tags'

import type { TAnyFilter, TAnyFilters } from 'services/hooks/use_filter'

// TODO: use as a return value of `useCategory`
export interface Category {
  name: string
  filters: TAnyFilters
  filtersHash: Record<string, TAnyFilter>
  tags: Tag[]
  resetFilters: () => void
  queryParams: QueryParams
  editedQueryParams: QueryParams
  isEdited: boolean
  isValid: boolean
  activeFilters: TAnyFilters
}
export type QueryParams = Record<
  string,
  string | number | boolean | null | unknown[] | Record<string, unknown>[] | Record<string, unknown>
>
interface ToTag {
  value: string
  names: string[]
  onDelete?: (filter: TAnyFilter) => void
}
interface IUseCategory {
  name: string
  toQueryParams?: (filters: TAnyFilters, filtersHash: { [name: string]: TAnyFilter }) => QueryParams
  toTags?: (filters: TAnyFilters) => ToTag[]
  filters: TAnyFilters
  isSemanticValid?: (filtersHash: { [name: string]: TAnyFilter }) => boolean
}
const useCategory = ({
  name,
  toQueryParams = () => ({}),
  toTags = () => [],
  filters,
  isSemanticValid = () => true,
}: IUseCategory): Category => {
  const findFilter = (filterName: string) => filters.find(({ name: n }) => filterName === n)
  const onTagDelete = (tagName: string) => findFilter(tagName)?.reset()
  const editedFilters = filters.filter((f) => f.isEdited)
  const activeFilters = filters.filter(({ isPresent: p }) => p)
  const tags = toTags(filters)
    .filter(({ names }) => names.some((n: string) => findFilter(n)?.isPresent))
    .map(({ value, names, onDelete }) => ({
      value,
      onDelete: () =>
        isPresent(onDelete)
          ? names.forEach((n) => onDelete(findFilter(n)))
          : names.forEach(onTagDelete),
    }))
  const resetFilters = () => filters.forEach((f) => f.reset())

  useOnce(() => filters.forEach(({ setCategoryName }) => setCategoryName(name)))
  const filtersHash = useMemo(
    () => filters.reduce((acc, filter) => ({ ...acc, [filter.name]: filter }), {}),
    [filters]
  )
  const editedFiltersHash = useMemo(
    () =>
      filters
        .filter((f) => f.isEdited)
        .reduce((acc, filter) => ({ ...acc, [filter.name]: filter }), {}),
    [filters]
  )
  const isValid = isSemanticValid(filtersHash) && filters.every((f) => f.isValid)

  return {
    name,
    tags,
    resetFilters,
    queryParams: toQueryParams(filters, filtersHash),
    editedQueryParams: isAnyArray(editedFilters)
      ? toQueryParams(editedFilters, editedFiltersHash)
      : {},
    isEdited: isAnyArray(editedFilters),
    isValid,
    filters,
    filtersHash,
    activeFilters,
  }
}

export default useCategory
