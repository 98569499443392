import { useTranslation } from 'react-i18next'

import DateHelper from 'services/helpers/date_helper'
import useCategory from 'services/hooks/use_category'
import useCurrentUser from 'views/iam/hooks/use_current_user'
import { isNull } from 'services/helpers/values'
import { MERCHANDISE_FCL, MERCHANDISE_LCL } from 'constants/bookings'

const useCategories = ({ filters: allFilters }) => {
  const { id: currentUserId } = useCurrentUser()
  const { t } = useTranslation()
  const {
    shipmentReferenceFilter,
    referenceFilter,
    partiesShipperFilter,
    partiesForwarderFilter,
    partiesConsignorFilter,
    partiesConsigneeFilter,
    transportModeSeaFilter,
    transportModeAirFilter,
    notificationActionRequiredFilter,
    notificationMyBookingsFilter,
    shippingModeFCLFilter,
    shippingModeLCLFilter,
    specifitiyControlledTemperatureFilter,
    specifityHazardousGoodsFilter,
    statusInReviewFilter,
    statusAcceptedFilter,
    statusCancelledFilter,
    statusDeclinedFilter,
    statusConfirmedFilter,
    statusCompletedFilter,
    routingOriginFilter,
    routingDestinationFilter,
    incotermsFilter,
    originDateFilter,
    destinationDateFilter,
  } = allFilters

  const notificationCategory = useCategory({
    name: 'notification',
    filters: [notificationMyBookingsFilter, notificationActionRequiredFilter],
    toQueryParams: (filters) => {
      if (filters.every(({ value }) => isNull(value))) return {}

      return filters
        .filter(({ value }) => value)
        .reduce((acc, { name }) => {
          if (name === notificationMyBookingsFilter.name) {
            return { ...acc, 'q[users_id_eq]': currentUserId }
          }
          return { ...acc, action_required: true }
        }, {})
    },
    toTags: (filters) =>
      filters
        .filter(({ value }) => value)
        .map(({ name }) => ({
          value: t(`bookings.tags.${name}`),
          names: [name],
        })),
  })

  const statusMap = {
    statusInReview: 'in_review',
    statusAccepted: 'accepted',
    statusConfirmed: 'confirmed',
    statusCancelled: 'cancelled',
    statusCompleted: 'completed',
    statusDeclined: 'declined',
  }

  const statusCategory = useCategory({
    name: 'status',
    filters: [
      statusAcceptedFilter,
      statusCancelledFilter,
      statusDeclinedFilter,
      statusConfirmedFilter,
      statusInReviewFilter,
      statusCompletedFilter,
    ],
    toQueryParams: (filters) => {
      if (filters.every(({ value }) => isNull(value))) return {}

      return {
        'q[status_in]': filters.filter((f) => f.isPresent).map(({ name }) => statusMap[name]),
      }
    },
    toTags: (filters) => [
      {
        value: filters
          .filter(({ value }) => value)
          .map(({ name }) => t(`bookings.tags.${name}`))
          .join(` ${t('operators.or')} `),
        names: filters.map(({ name }) => name),
      },
    ],
  })

  const referenceMap = {
    reference: 'reference_i_cont',
    shipmentReference: 'shipments_reference_eq',
  }

  const referenceCategory = useCategory({
    name: 'reference',
    filters: [referenceFilter, shipmentReferenceFilter],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce(
          (acc, { name, value }) => ({
            ...acc,
            [`q[${referenceMap[name]}]`]: value,
          }),
          {}
        ),
    toTags: (filters) =>
      filters.map(({ value, name }) => {
        if (name === 'shipmentReference') {
          return {
            value: `Shipment reference: ${value}`,
            names: [name],
          }
        }
        return {
          value,
          names: [name],
        }
      }),
  })

  const partiesMap = {
    partiesShipper: 'shipper',
    partiesForwarder: 'forwarder',
    partiesConsignor: 'consignor',
    partiesConsignee: 'consignee',
  }
  const partiesCategory = useCategory({
    name: 'parties',
    filters: [
      partiesForwarderFilter,
      partiesConsignorFilter,
      partiesConsigneeFilter,
      partiesShipperFilter,
    ],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce(
          (acc, { name, value }) => ({
            ...acc,
            [`q[${partiesMap[name]}_name_cont]`]: value?.value,
          }),
          {}
        ),
    toTags: (filters) => filters.map(({ value, name }) => ({ value: value?.value, names: [name] })),
  })

  const incotermsCategory = useCategory({
    name: 'incoterms',
    filters: [incotermsFilter],
    toQueryParams: (filters) => (filters[0].value ? { incoterms: filters[0].value?.value } : {}),
    toTags: (filters) => filters.map(({ value, name }) => ({ value: value?.value, names: [name] })),
  })

  const transportModesMap = {
    transportModeSea: 'sea',
    transportModeAir: 'air',
  }

  const transportModeCategory = useCategory({
    name: 'transportMode',
    filters: [transportModeSeaFilter, transportModeAirFilter],
    toQueryParams: (filters) => {
      if (filters.every(({ value }) => isNull(value))) return {}

      return {
        'q[transport_type_in]': filters
          .filter((f) => f.isPresent)
          .map(({ name }) => transportModesMap[name]),
      }
    },
    toTags: (filters) => [
      {
        value: filters
          .filter(({ value }) => value)
          .map(({ name }) => t(`bookings.tags.${name}`))
          .join(` ${t('operators.or')} `),
        names: filters.map(({ name }) => name),
      },
    ],
  })

  const routingMap = {
    origin: 'origin_address_id',
    destination: 'destination_address_id',
  }
  const routingCategory = useCategory({
    name: 'routing',
    filters: [routingOriginFilter, routingDestinationFilter],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce(
          (acc, { name, value }) => ({
            ...acc,
            [`q[${routingMap[name]}_eq]`]: value.value,
          }),
          {}
        ),
    toTags: (filters) => filters.map(({ value, name }) => ({ value: value?.label, names: [name] })),
  })

  const expectedDatesMap = {
    originDate: 'ptd',
    destinationDate: 'pta',
  }
  const expectedDatesCategory = useCategory({
    name: 'expectedDates',
    filters: [originDateFilter, destinationDateFilter],
    toQueryParams: (filters) =>
      filters
        .filter((f) => f.isPresent)
        .reduce(
          (acc, { name, value }) => ({
            ...acc,
            [`q[${expectedDatesMap[name]}_gteq]`]: new DateHelper(value.start).toDateOnly(),
            [`q[${expectedDatesMap[name]}_lteq]`]: new DateHelper(value.end).toDateOnly(),
          }),
          {}
        ),
    toTags: (filters) =>
      filters.map(({ value, name }) => ({
        value: [value.start, value.end]
          .filter((v) => v)
          .map((v) => new DateHelper(v).toLocale())
          .join(' - '),
        names: [name],
      })),
  })

  const shippingModeMapQueryValue = {
    shippingModeFCL: MERCHANDISE_FCL,
    shippingModeLCL: MERCHANDISE_LCL,
  }

  const shippingModeCategory = useCategory({
    name: 'shippingMode',
    filters: [shippingModeFCLFilter, shippingModeLCLFilter],
    toQueryParams: (filters) => {
      if (filters.every(({ value }) => isNull(value))) return {}

      return {
        'q[shipping_mode_in]': filters
          .filter((f) => f.isPresent)
          .reduce((acc, { name }) => [...acc, shippingModeMapQueryValue[name]], []),
      }
    },
    toTags: (filters) => [
      {
        value: filters
          .filter(({ value }) => value)
          .map(({ name }) => t(`bookings.tags.${name}`))
          .join(` ${t('operators.or')} `),
        names: filters.map(({ name }) => name),
      },
    ],
  })

  const specificityMap = {
    controlledTemperature: 'with_controlled_temperatures',
    hazardousGoods: 'with_hazardous_goods',
  }

  const specificityCategory = useCategory({
    name: 'specificity',
    filters: [specifitiyControlledTemperatureFilter, specifityHazardousGoodsFilter],
    toQueryParams: (filters) => {
      if (filters.every(({ value }) => isNull(value))) return {}

      return filters
        .filter(({ value }) => value)
        .reduce((acc, { name }) => ({ ...acc, [specificityMap[name]]: true }), {})
    },
    toTags: (filters) =>
      filters
        .filter(({ value }) => value)
        .map(({ name }) => ({ value: t(`bookings.tags.${name}`), names: [name] })),
  })

  const categories = {
    notificationCategory,
    statusCategory,
    referenceCategory,
    partiesCategory,
    transportModeCategory,
    shippingModeCategory,
    specificityCategory,
    expectedDatesCategory,
    incotermsCategory,
    routingCategory,
  }

  const arrayOfCategories = Object.values(categories)

  return {
    categories,
    arrayOfCategories,
  }
}

export default useCategories
