import React from 'react'

import Slider from 'rc-slider'
import { useTheme } from 'styled-components'

import S from 'components/input_slider/style'
import { isNull } from 'services/helpers/values'

type ConditionallyOmit<Condition extends boolean, T> = Condition extends true ? T : undefined

interface InputSliderProps<IsRange extends boolean> {
  min: number
  max: number
  value: (IsRange extends true ? [number, number] : number) | undefined
  onChange: (value: IsRange extends true ? [number, number] : number) => void
  range?: IsRange
  allowCross?: ConditionallyOmit<IsRange, boolean>
  color?: string
}

const InputSlider = <IsRange extends boolean = false>({
  min,
  max,
  value,
  onChange,
  range,
  allowCross,
  color,
}: InputSliderProps<IsRange>) => {
  const theme = useTheme()
  const rightValue = typeof value === 'number' ? null : value?.[1]
  const leftValue = typeof value === 'number' ? value : value?.[0]
  const sliderColor = color || theme.primary

  return (
    <S.Slider>
      {!isNull(leftValue) && <S.Value $position='left'>{leftValue}</S.Value>}
      <Slider
        range={range}
        min={min}
        max={max}
        value={value}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={onChange}
        allowCross={allowCross}
        railStyle={{ backgroundColor: '#E3E6EC', height: 8 }}
        trackStyle={{ backgroundColor: sliderColor, height: 8 }}
        handleStyle={{
          height: 20,
          width: 20,
          marginTop: -6,
          backgroundColor: sliderColor,
          opacity: 1,
          borderColor: sliderColor,
        }}
      />
      {!isNull(rightValue) && <S.Value $position='right'>{rightValue}</S.Value>}
    </S.Slider>
  )
}

export default InputSlider
