import React from 'react'
import PropTypes from 'prop-types'

import { validateChildrenOfTypes, numberInferiorEqualTo } from 'services/helpers/prop_types'

import Column from 'components/grid/grid_column'
import Row from 'components/grid/grid_row'
import GridContext from 'components/grid/context'
import { StyledGrid } from 'components/grid/style'

const Grid = ({ className, children, columns, columnsTablet, columnsMobile, condensed }) => (
  <GridContext.Provider value={columns}>
    <StyledGrid
      $columns={columns}
      $columnsTablet={columnsTablet}
      $columnsMobile={columnsMobile}
      $condensed={condensed}
      className={className}
    >
      {children}
    </StyledGrid>
  </GridContext.Provider>
)

Grid.Column = Column
Grid.Row = Row

Grid.propTypes = {
  /** className */
  className: PropTypes.string,
  /** children */
  children: validateChildrenOfTypes([Row]),
  /** columns */
  columns: numberInferiorEqualTo(16),
  /** columns tablet */
  columnsTablet: numberInferiorEqualTo(4, false),
  /** columns mobile */
  columnsMobile: numberInferiorEqualTo(4, false),
  /** condensed */
  condensed: PropTypes.bool,
}

Grid.defaultProps = {
  className: undefined,
  children: undefined,
  columns: 4,
  columnsTablet: 2,
  columnsMobile: 1,
  condensed: false,
}

export default Grid
