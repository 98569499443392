import styled, { css } from 'styled-components'

import { borderRadiusMedium } from 'styles/utils/border_radius'
import { text14SemiBold, text16SemiBold, tagSmall, text14Regular24 } from 'styles/utils/texts'
import { StyledInputCheckbox } from 'components/input_checkbox/style'
import { StyledInput } from 'components/input/style'
import { StyledTextarea } from 'components/textarea/style'
import { StyledSelect } from 'components/select/style'
import StyledIcon from 'components/icon/style'
import { StyledItem } from 'components/notif_message/style'
import { StyledCheckboxPicto } from 'components/checkbox_picto/style'
import { StyledDatePicker } from 'components/input_datepicker/style'
import { StyledAccordionItemHeading } from 'components/accordion_item/style'
import { tablet } from 'styles/utils/breakpoints'

export const StyledForm = styled.form`
  position: relative;
  color: ${({ theme }) => theme.textDark};
`
// Insert

export const StyledFormInsert = styled.div<{ $row: boolean }>`
  ${borderRadiusMedium}
  border: 1px solid ${({ theme }) => theme.lightGray};
  padding: 15px 35px 15px 35px;
  position: relative;
  margin-top: 25px;
  margin-bottom: 15px;
  @media ${tablet.down} {
    padding: 30px 15px 10px 15px;
    margin-bottom: 20px;
  }
  ${(props) =>
    props.$row &&
    css`
      display: flex;
      & > * {
        width: 100%;
        &:not(:last-child) {
          padding-right: 18px;
        }
      }
      @media ${tablet.down} {
        flex-direction: column;
      }
    `}
  & & {
    margin-bottom: 0;
  }
`

export const StyledFormInsertHead = styled.div`
  display: flex;
  position: absolute;
  width: auto;
  left: 25px;
  top: 0px;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.white};
  padding: 0 10px;
`

export const StyledFormInsertTitle = styled.span`
  ${text14SemiBold};
  color: ${({ theme }) => theme.primary};
  background: ${({ theme }) => theme.white};
`

export const StyledFormInsertSubtitle = styled.span`
  ${tagSmall};
  color: ${({ theme }) => theme.textLight};
  margin-left: 15px;
`

export const StyledFormInsertTrashButton = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.white};
`
// Group

export const StyledFormGroup = styled.div<{ $row?: boolean }>`
  margin-bottom: 15px;

  ${(props) =>
    props.$row &&
    css`
      display: flex;
      @media ${tablet.down} {
        flex-direction: column;
      }
    `}
  &:last-child {
    margin-bottom: 0;
  }

  ${StyledInputCheckbox}, ${StyledInput}, ${StyledSelect}, ${StyledDatePicker}, ${StyledCheckboxPicto}, ${StyledTextarea}, ${StyledItem} {
    margin-bottom: 10px;
  }
`

export const StyledFormGroupTitle = styled.p`
  ${text14Regular24}
  margin-bottom:10px;
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.lightGray};
    bottom: 10px;
    margin-left: 15px;
  }
`

// Accordion

export const StyledFormAccordion = styled.div`
  ${StyledAccordionItemHeading} {
    border-bottom: 1px solid ${(props) => props.theme.primary50};
  }
`

export const StyledFormAccordionContent = styled.div`
  padding: 20px 0 0px 0;
`

export const StyledFormAccordionHead = styled.div`
  display: flex;
  align-items: center;
  height: 45px;
  margin-top: 15px;
  padding-bottom: 15px;
  width: 100%;
`
export const StyledFormAccordionHeadTitle = styled.span`
  ${text16SemiBold};
  color: ${(props) => props.theme.primary};
  margin-left: 10px;
`
export const StyledFormAccordionHeadIcon = styled(StyledIcon)`
  height: 30px;
  width: 35px;
  fill: ${({ theme }) => theme.primary};
  margin-left: 7px;
`
