import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import useUserCan from 'views/iam/hooks/use_user_can'
import useBreakpoints from 'services/hooks/use_breakpoints'

import { isPresent } from 'services/helpers/values'

import Button from 'components/button'
import {
  StyledNetworkListItem,
  StyledNetworkListItemTitle,
  StyledNetworkListItemText,
} from 'components/network_list_item/style'
import {
  COMPANY_CLIENT_UPDATE,
  COMPANY_ORGANIZATION_UPDATE,
  COMPANY_UPDATE,
} from 'constants/permissions'
import { getTestIdForNetworkCompanyListItemInfo } from 'tests/e2e/test_ids'
import { Permission } from 'views/iam/types'

export interface NetworkListItemProps {
  name?: string
  city?: string
  address?: string
  country?: string
  onClick?: () => void
  itemIndex: number
}

const NetworkListItem: FC<NetworkListItemProps> = ({
  name,
  city,
  address,
  country,
  onClick,
  itemIndex,
}) => {
  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const cityCountry = [city, country].filter((v) => isPresent(v)).join(', ')
  const userCan = useUserCan()

  const userCanEditCompany =
    [COMPANY_CLIENT_UPDATE, COMPANY_UPDATE, COMPANY_ORGANIZATION_UPDATE].find((perm) =>
      userCan(perm as Permission)
    ) !== undefined

  return (
    <StyledNetworkListItem>
      <StyledNetworkListItemTitle
        data-testid={getTestIdForNetworkCompanyListItemInfo(itemIndex, 'name')}
      >
        {name}
      </StyledNetworkListItemTitle>
      <StyledNetworkListItemText
        data-testid={getTestIdForNetworkCompanyListItemInfo(itemIndex, 'details')}
      >
        {address && <span>{address}, </span>}
        {cityCountry}
      </StyledNetworkListItemText>
      {userCanEditCompany && (
        <Button
          testId={getTestIdForNetworkCompanyListItemInfo(itemIndex, 'button')}
          text={isDesktop ? t('actions.edit') : null}
          icon='pencil'
          variant='highlight'
          onClick={onClick}
        />
      )}
    </StyledNetworkListItem>
  )
}

export default NetworkListItem
