import styled, { css } from 'styled-components'
import { ReactSVG } from 'react-svg'

// ReactSVG library adds an extra div as a wrapper
// https://github.com/tanem/react-svg/issues/24
// It is not very efficient and forces to use a bad css selector, `> div` to style the svg
const StyledLogo = styled(ReactSVG)<{ $width?: number; $height?: number }>`
  svg {
    display: block;
    font-size: 0;
    line-height: 0;
    width: 100%;
    height: 100%;
  }
  ${(props) =>
    props.$width &&
    css`
      > div {
        width: ${props.$width}px;
      }
    `}

  ${(props) =>
    props.$height &&
    css`
      > div {
        height: ${props.$height}px;
      }
    `}
`

export default StyledLogo
