import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentContext from 'views/shipment/context'
import useShipment from 'views/shipment/hooks/use_shipment'
import ShipmentEmissions from 'views/shipment/components/emissions'
import ErrorNotification from 'views/errors/error_notification'
import { StyledShipmentContentHead, StyledShipmentContentHeadTitle } from 'views/shipment/style'

const SharedHeader = React.memo(() => {
  const { t } = useTranslation()
  const { id } = useContext(ShipmentContext)
  const [shipment, status] = useShipment({ id })

  let title = `${t('states.loading')}...`
  if (status.rejected) {
    title = t('states.error')
  } else if (status.fulfilled && shipment) {
    title = shipment.reference || t('shipments.shipmentTitle')
  }

  return (
    <StyledShipmentContentHead>
      <StyledShipmentContentHeadTitle>
        {title}
        {status.rejected && <ErrorNotification />}
        <ShipmentEmissions />
      </StyledShipmentContentHeadTitle>
    </StyledShipmentContentHead>
  )
})
SharedHeader.propTypes = {}

SharedHeader.defaultProps = {}

export default SharedHeader
