import React, { useState, useEffect, memo, useCallback, FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ModalHorizontal from 'components/modal_horizontal'
import CompanyForm from 'components/company_form'
import Button from 'components/button'
import NetworkListItem from 'components/network_list_item'
import NetworkList from 'components/network_list'
import {
  StyledModalNetworkTopBar,
  StyledModalNetworkContent,
  StyledModalNetworkList,
  StyledLoadMore,
} from 'components/modal_network/style'
import useModal from 'components/modal/hooks/use_modal'

import {
  fetchNetworkCompanies,
  selectNetworkCompanies,
  selectNetworkCompaniesStatus,
} from 'views/network/companies/slice'
import useUserCan from 'views/iam/hooks/use_user_can'

import {
  COMPANY_CLIENT_CREATE,
  COMPANY_CREATE,
  COMPANY_ORGANIZATION_CREATE,
} from 'constants/permissions'

import usePagination from 'services/api/hooks/use_pagination'
import useStatus from 'services/api/hooks/use_status'
import useShallowSelector from 'services/hooks/use_shallow_selector'

import useDebounce from 'services/hooks/use_debounce'

import Input from 'components/input'

import StyledCompaniesSearchInput from 'views/network/companies/style'

import {
  TEST_ID_NETWORK_LIST,
  TEST_ID_NETWORK_SEARCHBAR,
  TEST_ID_NETWORK_SEARCHBAR_CLEAR_BUTTON,
} from 'tests/e2e/test_ids'

const ModalNetwork: FC = memo(() => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [editCompanyId, setEditCompanyId] = useState<string | null>(null)
  const [addCompany, setAddCompany] = useState(false)
  const companies = useShallowSelector(selectNetworkCompanies)
  const status = useStatus(useSelector(selectNetworkCompaniesStatus))
  const { opened } = useModal('network')
  const { page, next } = usePagination()
  const userCan = useUserCan()

  const userCanCreateCompany = [
    COMPANY_CREATE,
    COMPANY_CLIENT_CREATE,
    COMPANY_ORGANIZATION_CREATE,
  ].some((perm) => userCan(perm))

  const [searchValue, setSearchValue] = useState('')

  const fetch = useCallback(
    (search) => {
      dispatch(fetchNetworkCompanies({ page, searchValue: search }))
    },
    [dispatch, page]
  )
  const debounce = useDebounce(fetch)

  useEffect(() => {
    if (opened) {
      debounce(searchValue)
    }
  }, [opened, dispatch, debounce, searchValue])

  const onCloseForm = useCallback(() => {
    setEditCompanyId(null)
    setAddCompany(false)
  }, [])

  return (
    <ModalHorizontal title={t('network.title')} size='large' modalName='network'>
      {!addCompany && !editCompanyId && (
        <StyledModalNetworkContent>
          <StyledModalNetworkTopBar>
            {userCanCreateCompany && (
              <Button
                text={`${t('network.companies.createTitle')} ${t('network.companies.company')}`}
                variant='highlight'
                icon='plus_outline'
                onClick={() => setAddCompany(true)}
              />
            )}
            <StyledCompaniesSearchInput>
              <Input
                type='text'
                onChange={(event) => setSearchValue(event.target.value)}
                value={searchValue}
                button={{
                  testId: TEST_ID_NETWORK_SEARCHBAR_CLEAR_BUTTON,
                  icon: 'close',
                  variant: 'minimalIcon',
                  onClick: () => setSearchValue(''),
                }}
                placeholder={t('network.companies.search')}
                testId={TEST_ID_NETWORK_SEARCHBAR}
              />
            </StyledCompaniesSearchInput>
          </StyledModalNetworkTopBar>
          <StyledModalNetworkList>
            <NetworkList testId={TEST_ID_NETWORK_LIST}>
              {companies.map(({ id, name, address: { address, city, country } }, index) => (
                <NetworkListItem
                  key={`network-company-${id}`}
                  name={name}
                  city={city}
                  address={address}
                  country={country}
                  onClick={() => setEditCompanyId(id)}
                  itemIndex={index}
                />
              ))}
            </NetworkList>
            {status.ready && (
              <StyledLoadMore as={Button} onClick={() => next()} text={t('actions.loadMore')} />
            )}
          </StyledModalNetworkList>
        </StyledModalNetworkContent>
      )}
      {(addCompany || editCompanyId) && <CompanyForm id={editCompanyId} onClose={onCloseForm} />}
    </ModalHorizontal>
  )
})

ModalNetwork.propTypes = {}

ModalNetwork.defaultProps = {}

export default ModalNetwork
