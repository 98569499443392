import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ClipLoader from 'react-spinners/ClipLoader'
import { useTheme } from 'styled-components'

import Page from 'components/page'
import StyledPage from 'components/page/style'
import ClusterFilters from 'views/atlas/clusters/components/filters'
import S from 'views/atlas/style'
import {
  fetchClusters,
  selectClusters,
  selectClustersActiveFilters,
  selectClustersStatus,
  selectClustersTotalCount,
  removeAllClusters,
} from 'views/atlas/slices/cluster'
import useStatus from 'services/api/hooks/use_status'
import usePagination from 'services/api/hooks/use_pagination'
import useAppDispatch from 'services/hooks/use_app_dispatch'
import useTriggerOnViewport from 'services/hooks/use_trigger_on_viewport'
import Button from 'components/button'
import useModal from 'components/modal/hooks/use_modal'
import ClusterForm from 'views/atlas/clusters/components/cluster_form'
import Table from 'components/table'
import { Cluster } from 'views/atlas/types/cluster'
import NotifMessage from 'components/notif_message'

const ClusterIndex = () => {
  const dispatch = useAppDispatch()
  const { page, next, reset } = usePagination()
  const { t } = useTranslation()
  const clusters = useSelector(selectClusters)
  const totalCount = useSelector(selectClustersTotalCount)
  const activeFilters = useSelector(selectClustersActiveFilters)
  const fetchingStatus = useStatus(useSelector(selectClustersStatus))
  const { opened, setOpen } = useModal('clusterForm')
  const theme = useTheme()
  const [editCluster, setEditCluster] = useState<null | Cluster>(null)

  useEffect(() => {
    resetAllClusters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeFilters)])

  const resetAllClusters = () => {
    dispatch(removeAllClusters())
    triggerFetchClusters(1)
    reset()
  }

  const triggerFetchClusters = (p: number) => {
    dispatch(
      fetchClusters({
        search: activeFilters.search,
        countries: activeFilters.countries,
        states: activeFilters.states,
        page: p,
      })
    )
  }

  const nextPage = () => {
    triggerFetchClusters(page + 1)
    next()
  }

  const ref = useTriggerOnViewport<HTMLDivElement>(nextPage)

  return (
    <Page sidebar={<ClusterFilters />}>
      <S.Header>
        <StyledPage.PageTitle>Cluster</StyledPage.PageTitle>
        <S.Text>{t('atlas.clusterCounter', { count: totalCount })}</S.Text>
        <Button
          text={t('atlas.actions.newCluster')}
          icon='plus_outline'
          variant='highlight'
          onClick={() => {
            setEditCluster(null)
            setOpen(true)
          }}
          type='button'
        />
      </S.Header>
      <div>
        {clusters.length > 0 && (
          <Table>
            <Table.Head>
              <Table.HeadCell>name</Table.HeadCell>
              <Table.HeadCell>nameAliases</Table.HeadCell>
              <Table.HeadCell>county</Table.HeadCell>
              <Table.HeadCell>state</Table.HeadCell>
              <Table.HeadCell>country</Table.HeadCell>
              <Table.HeadCell>timezone</Table.HeadCell>
              <Table.HeadCell />
            </Table.Head>
            <Table.Body>
              {clusters.map((cluster, index) => (
                <Table.Row odd={index % 2 === 1} alignedTop key={cluster.token}>
                  <Table.Cell>{cluster.name}</Table.Cell>
                  <Table.Cell>{cluster.nameAliases.join(', ') || '_'}</Table.Cell>
                  <Table.Cell>{cluster.county || '_'}</Table.Cell>
                  <Table.Cell>{cluster.state?.name || '_'}</Table.Cell>
                  <Table.Cell>{cluster.country.name}</Table.Cell>
                  <Table.Cell>{cluster.timezone}</Table.Cell>
                  <Table.Cell>
                    <Button
                      type='button'
                      icon='pencil'
                      variant='verySmallIcon'
                      onClick={() => {
                        setEditCluster(cluster)
                        setOpen(true)
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
        {fetchingStatus.ready && clusters.length < totalCount && <div ref={ref} />}
        {fetchingStatus.pending && (
          <S.Loader>
            <ClipLoader loading size={20} aria-label='Loading Spinner' color={theme.primary} />
          </S.Loader>
        )}
        {fetchingStatus.ready && clusters.length === 0 && (
          <NotifMessage
            type='info'
            title={t('atlas.noResults.title')}
            text={t('atlas.noResults.text')}
          />
        )}
      </div>
      {opened && <ClusterForm callbackAfterSave={resetAllClusters} cluster={editCluster} />}
    </Page>
  )
}

export default ClusterIndex
