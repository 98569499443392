import React, { useState, FC, ReactElement, PropsWithChildren } from 'react'

import {
  StyledDropdown,
  StyledMenu,
  StyledArrow,
  StyledNavigationOption,
  StyledNavigationOptionLink,
  StyledTitle,
} from 'components/navigation_dropdown_item/style'
import { StyledItem, StyledItemLink } from 'components/navigation_item/style'
import Icon from 'components/icon'

type NavigationDropdownItemOptionType = ReactElement<
  PropsWithChildren<NavigationDropdownOptionProps>
>

interface NavigationDropdownItemProps {
  options: NavigationDropdownItemOptionType[]
  active: boolean
  variant: 'light' | 'dark'
  text: string
}

interface NavigationDropdownOptionProps {
  active: boolean
  text: string
  to: string
}

export const NavigationDropdownOption: FC<NavigationDropdownOptionProps> = ({
  active,
  text,
  to,
}) => (
  <StyledNavigationOptionLink to={to} $active={active}>
    {text}
  </StyledNavigationOptionLink>
)

const NavigationDropdownItem: FC<NavigationDropdownItemProps> = (props) => {
  const { text, active, variant, options } = props
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen(!open)
  }

  return (
    <StyledDropdown onMouseEnter={handleOpen} onMouseLeave={handleClose} onClick={handleToggle}>
      <StyledTitle>
        <StyledItem $active={active} $variant={variant}>
          <StyledItemLink as='span'>
            {text}
            <StyledArrow as={Icon} $active={active} name='arrow_bottom_outline' />
          </StyledItemLink>
        </StyledItem>
      </StyledTitle>

      {open && (
        <StyledMenu>
          {options.map((option) => (
            <StyledNavigationOption onClick={handleClose}>{option}</StyledNavigationOption>
          ))}
        </StyledMenu>
      )}
    </StyledDropdown>
  )
}

export default NavigationDropdownItem
