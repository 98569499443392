import React, { createContext } from 'react'

interface FiltersContextInterface {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const FiltersContext = createContext<FiltersContextInterface>({
  visible: false,
  setVisible: () => {},
})

export default FiltersContext
