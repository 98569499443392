import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import Transition from 'components/transition'
import NotifMessage from 'components/notif_message'

import { removeNotification, selectAllNotifications } from 'views/notifications/slice'

import StyledGlobalNotification from 'components/global_notifications/style'

import useShallowSelector from 'services/hooks/use_shallow_selector'

const NOTIFICATION_DURATION = 3000
const TRANSITION_DURATION = 300
const NOTIFICATIONS_LIMIT = 3

const GlobalNotifications: FC = () => {
  const notifications = useShallowSelector(selectAllNotifications)
  const dispatch = useDispatch()
  const notificationsToRender = useMemo(
    () => notifications.slice(-NOTIFICATIONS_LIMIT),
    [notifications]
  )
  const notificationsToRemove = useMemo(
    () => notifications.slice(0, -NOTIFICATIONS_LIMIT),
    [notifications]
  )
  const [inTransitions, setInTransitions] = useState(notificationsToRender.map(({ id }) => id))

  useEffect(() => {
    setInTransitions(notificationsToRender.map(({ id }) => id))
  }, [notificationsToRender])

  useEffect(() => {
    notificationsToRemove.map(({ id }) => dispatch(removeNotification(id)))
  }, [notificationsToRemove, dispatch])

  const remove = useCallback(
    (id) => {
      setInTransitions((prevTransitions) => prevTransitions.filter((i) => id !== i))
      setTimeout(() => {
        dispatch(removeNotification(id))
      }, TRANSITION_DURATION)
    },
    [dispatch]
  )

  useEffect(() => {
    inTransitions.forEach((id) =>
      setTimeout(() => {
        remove(id)
      }, NOTIFICATION_DURATION)
    )
  }, [inTransitions, remove])

  return (
    <StyledGlobalNotification>
      {notificationsToRender.map(({ type, title, text, id }) => (
        <Transition
          in={inTransitions.includes(id)}
          type='fade-in'
          key={`global-notification-${id}`}
        >
          <NotifMessage
            type={type}
            title={title}
            text={text}
            close
            onClose={() => {
              if (inTransitions.includes(id)) {
                remove(id)
              }
            }}
          />
        </Transition>
      ))}
    </StyledGlobalNotification>
  )
}
export default GlobalNotifications
