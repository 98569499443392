import useStatus from 'services/api/hooks/use_status'
import useAppSelector from 'services/hooks/use_app_selector'

import { selectBookingStatus, selectBooking } from 'views/booking/slices/booking_slice'
import { Booking } from 'views/booking/slices/types'

type IBooking = [
  Booking | undefined,
  Record<'fulfilled' | 'pending' | 'rejected' | 'ready', boolean>
]
const useBooking = (id: string): IBooking => {
  const booking = useAppSelector((state) => selectBooking(state, id))
  const status = useStatus(useAppSelector((state) => selectBookingStatus(state)))
  return [booking, status]
}

export default useBooking
