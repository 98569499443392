import React from 'react'

import Icon from 'components/icon'
import {
  StyledQuickInfo,
  StyledQuickInfoContent,
  StyledQuickInfoIcon,
} from 'components/quick_info/style'

const QuickInfo: React.FC = ({ children }) => (
  <StyledQuickInfo>
    <StyledQuickInfoIcon as={Icon} name='optimization_timer' />
    <StyledQuickInfoContent>{children}</StyledQuickInfoContent>
  </StyledQuickInfo>
)

export default QuickInfo
