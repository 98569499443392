import React, { ReactNode, useMemo } from 'react'

import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'

import useCurrentUser from 'views/iam/hooks/use_current_user'
import { DefaultTheme, computeTheme, defaultTheme } from 'styles/theme'
import { UserTheme } from 'views/iam/types'
import { isEmptyObject } from 'services/helpers/values'

interface ThemeProviderProps {
  children: ReactNode
}

const translateUserTheme = (userTheme: UserTheme): Omit<DefaultTheme, 'tip' | 'success'> => ({
  primary: userTheme.primary,
  secondary: userTheme.secondary,
  warn: userTheme.warn,
  error: userTheme.alert,
})

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  const user = useCurrentUser()

  const theme = useMemo(() => {
    let t = defaultTheme
    if (!isEmptyObject(user.organization.theme)) {
      t = { ...defaultTheme, ...translateUserTheme(user.organization.theme) }
    }
    return t
  }, [user])

  return (
    <StyledComponentThemeProvider
      theme={computeTheme({
        theme,
      })}
    >
      {children}
    </StyledComponentThemeProvider>
  )
}

export default React.memo(ThemeProvider)
