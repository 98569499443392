import styled, { css } from 'styled-components'

import StyledIcon from 'components/icon/style'
import { easeQuartOut } from 'styles/utils/ease'
import { tablet } from 'styles/utils/breakpoints'
import { StyledSearch } from 'components/search/style'
import { StyledMenuDropdown } from 'components/menu/style'
import { zIndexHeader } from 'styles/utils/z_index'
import { HeaderVariant } from 'components/header/types'

export const headerHeight = '86px'
export const headerHeightMobile = '66px'

const StyledButtonBurgerIcon = styled.div`
  fill: ${({ theme }) => theme.mediumGray};
  width: 17px;
  position: absolute;
  transition: transform 0.5s ${easeQuartOut};
`

export const StyledButtonBurgerOpen = styled(StyledButtonBurgerIcon)`
  transform: scale(1);
  transition-delay: 0.15s;
`

export const StyledButtonBurgerClose = styled(StyledButtonBurgerIcon)`
  transform: scale(0) rotate(-45deg);
`

export const StyledButtonBurger = styled.div<{ $navOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  margin-left: 10px;

  @media ${tablet.up} {
    display: none;
  }

  ${(props) =>
    props.$navOpen &&
    css`
      ${StyledButtonBurgerClose} {
        transform: scale(1) rotate(0deg);
        transition-delay: 0.15s;
      }
      ${StyledButtonBurgerOpen} {
        transform: scale(0) rotate(45deg);
        transition-delay: 0s;
      }
    `}
`

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const StyledLeftBlock = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const StyledRightBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  right: 0;
`

export const StyledSearchWrapper = styled.div`
  margin-right: 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  flex: none;

  @media ${tablet.up} {
    margin-right: 20px;
  }

  ${StyledSearch} {
    position: absolute;
    right: 0;
  }
`

export const StyledNavigation = styled.div<{ $hidden?: boolean }>`
  transition: opacity 0.5s ${easeQuartOut};

  @media ${tablet.up} {
    ${(props) =>
      props.$hidden &&
      css`
        pointer-events: none;
        opacity: 0;
      `}
  }
`

export const StyledButtonNetworkIcon = styled.div`
  fill: ${({ theme }) => theme.mediumGray};
`

export const StyledButtonNetwork = styled.div`
  margin-right: 18px;
  width: 35px;
  height: 35px;

  @media ${tablet.up} {
    margin-right: 30px;
  }
`
export const StyledButtonProfile = styled.div`
  position: relative;
  flex: none;

  ${StyledMenuDropdown} {
    top: 50px;
    left: auto;
    right: 0;
    white-space: nowrap;
  }
`

export const StyledHeader = styled.header<{ $variant: HeaderVariant }>`
  position: fixed;
  z-index: ${zIndexHeader};
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 18px;
  display: flex;
  align-items: center;
  height: ${headerHeightMobile};
  box-shadow: inset 0px -1px 0px #e4e4e4;

  @media ${tablet.up} {
    height: ${headerHeight};
  }

  ${(props) => {
    if (props.$variant === 'dark') {
      return css`
        background: ${props.theme.primary};

        ${StyledButtonBurgerOpen}, ${StyledButtonBurgerClose} {
          fill: ${({ theme }) => theme.white};
        }

        ${StyledButtonNetwork} {
          ${StyledIcon} {
            fill: ${({ theme }) => theme.white};
          }

          &:hover {
            ${StyledIcon} {
              fill: ${({ theme }) => theme.primary50};
            }
          }
        }
      `
    }

    return css`
      background: white;
    `
  }}
`
